import { properCase, RecordType } from '@petconsole/pure-base';
import { Api, EntityReaction } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import { getCreatorReactToEntity } from '../creators';

interface ReactToEntityThunkProps<T extends RecordType = RecordType> {
  sliceName: string;
  sliverName?: string;
  api: Api<T>;
  reaction?: EntityReaction;
}

export const reactToEntityThunk = <T extends RecordType = RecordType>({ sliceName, sliverName, api, reaction }: ReactToEntityThunkProps<T>) => {
  const creator = getCreatorReactToEntity<T>({ sliceName, sliverName, api, reaction });

  return appThunk(`${sliceName}/reactToEntity${properCase(sliverName || sliceName)}`, creator);
};
