import { RecordType } from '@petconsole/pure-base';
import { AnyAsyncThunk, SelectById, Slice } from '../../types';
import {
  useOurDispatchAction,
  useOurDispatchValue,
  useOurFetchEntity,
  useOurSelectAction,
  useOurSelectById,
} from '../hooks';

const sliceSliverHooks = <T extends RecordType = RecordType>(slice: Slice<T>, sliverName: string) => {
  const sliver = slice.sliver[sliverName] || {};
  const { action, name, flag, thunk } = slice;
  const {
    fetchById,
    fetchByValue,
    fetchesById,
    fetchesByValue,
    fetchManyCreator,
    fetchOneCreator,
    proper,
    pluralProper,
    selectors,
  } = sliver;
  const { fetchEntityByUrlOrId } = thunk;
  const { hasNextPrev, hasUrlName } = flag;
  const hooks: RecordType = {};

  if (fetchOneCreator)
    hooks[`useOurFetch${proper}`] = () =>
      fetchByValue
        ? useOurDispatchValue(fetchOneCreator)
        : fetchById
        ? useOurFetchEntity(name, fetchOneCreator)
        : useOurDispatchAction(fetchOneCreator);

  if (fetchManyCreator)
    hooks[`useOurFetch${pluralProper}`] = () =>
      fetchesById
        ? useOurFetchEntity(name, fetchManyCreator)
        : fetchesByValue
        ? useOurDispatchValue(fetchManyCreator)
        : useOurDispatchAction(fetchManyCreator);

  hooks[`useOurSelect${proper}`] = (id: string) => useOurSelectById(selectors.selectById, id);
  hooks[`useOurSelect${pluralProper}`] = () => useOurSelectAction(selectors.selectAll);
  hooks[`useOurSelect${proper}Ids`] = () => useOurSelectAction(selectors.selectIds);
  hooks[`useOurSelect${proper}ReadError`] = () => useOurSelectAction(selectors.readError);
  hooks[`useOurSelect${proper}ReadStatus`] = () => useOurSelectAction(selectors.readStatus);
  hooks[`useOurSelect${proper}ReadAllError`] = () => useOurSelectAction(selectors.readAllError);
  hooks[`useOurSelect${proper}ReadAllStatus`] = () => useOurSelectAction(selectors.readAllStatus);
  hooks[`useOurSelect${proper}HasMore`] = () => useOurSelectAction(selectors.hasMore);
  hooks[`useOurSelect${proper}WriteError`] = () => useOurSelectAction(selectors.writeError);
  hooks[`useOurSelect${proper}WriteStatus`] = () => useOurSelectAction(selectors.writeStatus);
  hooks[`useOurReset${proper}`] = () => useOurDispatchAction(action[`reset${proper}State`]);

  if (hasNextPrev) {
    hooks[`useOurSelectNext${proper}`] = (id: string) => useOurSelectById(selectors.selectNext as SelectById, id);
    hooks[`useOurSelectPrev${proper}`] = (id: string) => useOurSelectById(selectors.selectPrev as SelectById, id);
  }

  if (hasUrlName && sliverName === name) {
    hooks[`useOurSelect${proper}ByUrlOrId`] = (value: string) =>
      useOurSelectById(selectors.selectByUrlOrId as SelectById, value);
    hooks[`useOurFetch${proper}ByUrlOrId`] = () => useOurDispatchValue(fetchEntityByUrlOrId as AnyAsyncThunk);
  }

  return hooks;
};

export default sliceSliverHooks;
