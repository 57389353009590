// noinspection ES6PreferShortImport

import { createSlice } from '@reduxjs/toolkit';
import { RecordType, newId } from '@petconsole/pure-base';
import { useOurDispatchValue } from '../../../helpers/hooks';
import useOurSelectAction from '../../../helpers/hooks/useOurSelectAction';
import { AnyAsyncThunk, StateType } from '../../../types';

export interface LoggerMessage {
  key: string;
  message: string;
}

const sliceName = 'logger';

const slice = {
  name: sliceName,
};

const initialState = {
  messages: [],
};

export const loggerSlice = createSlice({
  name: slice.name,
  initialState,
  reducers: {
    loggerError: (state: StateType, { payload }) => {
      state.messages = [...state.messages, { message: payload.message, key: newId() }];
    },
    loggerRemove: (state: StateType, { payload }) => {
      state.messages = state.messages.filter((message: RecordType) => message.key !== payload);
    },
  },
});

export default loggerSlice.reducer;

const { actions } = loggerSlice;
const selectLoggerMessages = (state: StateType) => state.logger.messages;

export const loggerError = actions.loggerError;
export const loggerRemove = actions.loggerRemove;
// export const useOurLoggerError = () => useOurDispatchValue(<AnyAsyncThunk>(<unknown>loggerError));
export const useOurLoggerRemove = () => useOurDispatchValue(<AnyAsyncThunk>(<unknown>loggerRemove));
export const useOurSelectLoggerMessages = () => useOurSelectAction(selectLoggerMessages);
