import { Grid, GridSize } from '@mui/material';
import OurText, { OurTextProps } from './OurText';

interface OurGridTextProps extends OurTextProps {
  xs?: boolean | GridSize;
  sm?: boolean | GridSize;
  md?: boolean | GridSize;
  lg?: boolean | GridSize;
  xl?: boolean | GridSize;
}

const OurGridText = ({ xs, sm, md, lg, xl, ...rest }: OurGridTextProps) => {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <OurText {...rest} />
    </Grid>
  );
};

export default OurGridText;
