import { ratingApiEntity } from '@petconsole/pure-fe-api';
import { EntityName, ourEntityNameToType } from '@petconsole/pure-shared';
import { apiSlice } from '../../api/apiSlice';
import { ThunkApi } from '../../types';
import getStateSliceRatings from '../misc/getStateSliceRatings';
import setEntityRatingCreator from './ourSetEntityRatingCreator';

export interface UpdateRatingProps {
  entityName: EntityName;
  id: string;
  rating: number;
}

const ourUpdateRating = async ({ entityName, id, rating }: UpdateRatingProps, { getState, dispatch }: ThunkApi) => {
  const type = ourEntityNameToType(entityName);
  const entityRatings = getStateSliceRatings({ getState, sliceName: entityName });
  const entityRating = entityRatings[id];
  const { ratingId } = entityRating;

  // Optimistic change to UI assuming the following backend updates will succeed
  const setRatingCreator = setEntityRatingCreator({ entityName });

  // Dispatching a simple action (non thunk) returns { type, payload }
  dispatch(setRatingCreator({ ...entityRating, rating }));

  const newRating = { ...entityRating, rating };
  const { create, update } = ratingApiEntity.api;

  const updated = await (ratingId ? update(ratingId, newRating) : create(newRating));

  // After the update, invalidate the get<Entity Type>Query tag so the data is re-fetched for the UI
  dispatch(apiSlice.util.invalidateTags([{ type, id }]));

  return updated;
};

export default ourUpdateRating;
