import { EntityName, EntityRecord } from '@petconsole/pure-shared';
import { AppState } from '../../store';
import { StateSlices, StateSliver } from '../../types';

const ourStateSliverData = <T extends EntityRecord>(state: AppState, slice: string | EntityName, sliver?: string | EntityName) => {
  const slices = state as StateSlices;

  // If sliver not provided, assume the main sliver is requested
  return slices[slice][sliver || slice] as StateSliver<T>;
};

export default ourStateSliverData;
