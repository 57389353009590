import { useMemo } from 'react';
import useOurSelectByValue from './useOurSelectByValue';
import selectsPropertyByValue from '../selectors/selectsPropertyByValue';

interface UseOurSelectsPropertyByValueProps {
  sliceName: string;
  sliverName: string;
  propertyName: string;
  valueName: string;
  value: string;
}

const useOurSelectsPropertyByValue = ({
  sliceName,
  sliverName,
  propertyName,
  valueName,
  value,
}: UseOurSelectsPropertyByValueProps) => {
  const selector = useMemo(
    () =>
      selectsPropertyByValue({
        sliceName,
        sliverName,
        propertyName,
        valueName,
        value,
      }),
    [propertyName, value],
  );

  return useOurSelectByValue<unknown, string>(selector, value);
};

export default useOurSelectsPropertyByValue;
