import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { EntityFormik, ExtendedFormik, SetState } from '@petconsole/pure-shared';
import OutlinedGrid from '../../outlinedGrid/OutlinedGrid';
import OurFormikErrors from './OurFormikErrors';

interface OurOutlinedFormikErrorsProps {
  formik: EntityFormik | ExtendedFormik;
  submitting: boolean;
  setSubmitting: SetState<boolean>;
}

const OurOutlinedFormikErrors = ({ formik, submitting, setSubmitting }: OurOutlinedFormikErrorsProps) => {
  const { isReadOnly, isSubmitting, errors } = formik;

  useEffect(() => {
    if (!submitting && isSubmitting) setSubmitting(true);
  }, [isSubmitting, submitting, setSubmitting]);

  if (isReadOnly || !submitting || !Object.values(errors).length) return null;

  return (
    <OutlinedGrid id="error-outline" label="Validation Errors">
      <Grid item xs={12}>
        <OurFormikErrors errors={errors} heading={false} />
      </Grid>
    </OutlinedGrid>
  );
};

OurOutlinedFormikErrors.whyDidYouRender = true;

export default OurOutlinedFormikErrors;
