import { ChangeEventHandler, ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { StandardTextFieldProps } from '@mui/material';
import { RecordType, camelToWords } from '@petconsole/pure-base';
import { ExtendedFormik } from '@petconsole/pure-shared';
import { TextFieldVariant } from '../../../types';
import getFormikFieldProps from './getFormikFieldProps';
import getFormikValue from './getFormikValue';

export interface FieldProps extends RecordType {
  id?: string;
  name?: string;
  label?: string | null;
  value?: unknown;
  onChange?: ChangeEventHandler;
  error?: boolean;
  helperText?: ReactNode;
  fullWidth?: boolean;
  InputProps: RecordType,
  variant: TextFieldVariant,
  autoFocus?: boolean;
}

export interface GetFieldProps extends Omit<StandardTextFieldProps, 'variant'> {
  formik?: ExtendedFormik;
  variant?: TextFieldVariant;
  label?: string | null;
  caps?: boolean;
  darkMode?: boolean;
  readOnly?: boolean;
}

const getFieldProps = ({
  formik,
  id,
  name,
  label,
  value,
  onChange,
  helperText,
  fullWidth = true,
  autoComplete,
  caps,
  darkMode,
  readOnly,
  ...rest
}: GetFieldProps): FieldProps => {
  const restProps = rest;
  const formikValue = getFormikValue({ formik, id });
  const initialValue = value != null ? value : (formikValue != null && formikValue) || '';
  const { InputProps, helperText: formikHelperText } = getFormikFieldProps({
    formik,
    id,
    inputProps: restProps.InputProps,
  });

  InputProps.autoComplete = autoComplete || 'off';

  if (readOnly === true) InputProps.readOnly = true;

  const canEdit = readOnly !== true && (!formik || !formik.isReadOnly);
  const hasValue = Boolean(initialValue || initialValue === 0);
  const variant =
    restProps && restProps.variant ? restProps.variant : (canEdit && 'filled') || 'standard';

  // Disable underline if read only AND the property has a value
  if (!canEdit && hasValue && variant !== 'outlined') InputProps.disableUnderline = true;

  if (canEdit && variant === 'outlined')
    InputProps.sx = {
      backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.06)',
    };

  const idParts = (id || '').split('.');

  const returnProps: FieldProps = {
    id,
    name: name || id,
    label: label !== undefined ? label : camelToWords(idParts[idParts.length - 1]),
    value: initialValue,
    onChange: onChange || (formik && formik.handleChange),
    error: !!(formikHelperText || helperText || ''),
    helperText: (helperText || formikHelperText || '') as ReactNode,
    fullWidth,
    // variant: darkMode ? 'standard' : 'filled',
    InputProps,
    ...restProps,
    variant,
    autoFocus: restProps.autoFocus && !isMobile,
  };

  if (caps) {
    const inputProps = (returnProps.inputProps || {}) as RecordType;

    inputProps.style = { ...inputProps?.style as RecordType, textTransform: 'uppercase' };

    returnProps.inputProps = inputProps;
  }

  return returnProps;
};

export default getFieldProps;
