import { MaybeNull, RecordType } from '@petconsole/pure-base';
import {
  Api,
  PclHeaderRecord,
  pclHeaderEntity,
  MaybePclHeaderRecord,
  GetListResults,
  ApiEntity,
} from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = PclHeaderRecord;
type ListReturns = Promise<GetListResults<ApiRecord>>;

const apiPath = '/pclHeaders';
const { getByValue, getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getByEntityType: (entityType: string) => Promise<MaybeNull<T>>;
  getListBySite: (site: string) => ListReturns;
}

export type PclHeaderApi = Api<ApiRecord> & CustomApi;

export const pclHeaderApi: PclHeaderApi = {
  ...common,
  getByValue,
  getListByValue,
  getByEntityType: (entityType) => getByValue('entityType', entityType) as Promise<MaybePclHeaderRecord>,
  getListBySite: (site: string) => getListByValue('site', site),
};

export const pclHeaderApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...pclHeaderEntity, api: pclHeaderApi };
