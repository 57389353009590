import { Grid } from '@mui/material';
import OurCardImage from '../cards/OurCardImage';
import useOurWidth from '../../hooks/useOurWidth';

interface OurCenterImageProps {
  image: string;
  alt?: string;
  width?: string;
  maxWidth?: string;
  padTop?: number;
  padBottom?: number;
}

const OurCenterImage = ({
  image,
  alt = 'image',
  padTop = 0.5,
  padBottom = 0.5,
  width,
  maxWidth = '100%',
}: OurCenterImageProps) => {
  const gridWidth = useOurWidth();
  const centerWidth = width ? width : gridWidth === 'xs' ? '100%' : gridWidth === 'sm' ? '75%' : '50%';
  return (
    <Grid container item xs={12} justifyContent="center">
      <OurCardImage image={image} alt={alt} sx={{ pt: padTop, pb: padBottom, maxWidth, width: centerWidth }} />
    </Grid>
  );
};

OurCenterImage.whyDidYouRender = true;

export default OurCenterImage;
