import { PropsWithChildren, useContext } from 'react';
import { AppBar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OurAppContext } from '../../../context';

interface OurAppMenuBarProps extends PropsWithChildren {
  drawerWidth: number;
}

const OurAppMenuBar = ({ drawerWidth, children }: OurAppMenuBarProps) => {
  const { palette, transitions, zIndex } = useTheme();
  const { duration, easing } = transitions;
  const { enteringScreen, leavingScreen } = duration;
  const { menuOpen } = useContext(OurAppContext);

  const sx = {
    appBar: {
      borderBottom: `solid 3px ${palette.secondary.main}`, // needed for scrolling content
      tzIndex: zIndex.drawer + 1,
      transition: transitions.create(['width', 'margin'], {
        easing: easing.sharp,
        duration: leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: transitions.create(['width', 'margin'], {
        easing: easing.sharp,
        duration: enteringScreen,
      }),
    },
  };

  return (
    <AppBar position="fixed" enableColorOnDark sx={{ ...sx.appBar, ...(menuOpen && sx.appBarShift) }}>
      {children}
    </AppBar>
  );
};

OurAppMenuBar.whyDidYouRender = true;

export default OurAppMenuBar;
