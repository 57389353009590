import { isMobile, isTablet, withOrientationChange } from 'react-device-detect';
import { ListItem, ListItemText, MenuItem } from '@mui/material';
import useOurWidth from '../../../hooks/useOurWidth';

interface AdminMenuItemProps {
  isLandscape?: boolean; // inserted by withOrientationChange
  isPortrait?: boolean; // inserted by withOrientationChange
}

const AdminMenuItem = ({ isLandscape, isPortrait }: AdminMenuItemProps) => {
  const width = useOurWidth();
  const info = `${width} ${(isTablet && 'Tablet') || (isMobile && 'Phone') || 'Browser'} ${
    (isLandscape && 'Landscape') || (isPortrait && 'Portrait') || ''
  }`;

  return (
    <MenuItem divider disabled component={ListItem}>
      <ListItemText secondary={info} />
    </MenuItem>
  );
};

AdminMenuItem.whyDidYouRender = true;

export default withOrientationChange(AdminMenuItem);
