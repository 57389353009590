import { RecordType } from '@petconsole/pure-base';
import { OurLazyLoad } from '@petconsole/pure-fe-mui';

const LazyOurPclTierCancel = (props: RecordType) => <OurLazyLoad load={() => import('./PclTierCancel')} {...props} />;
export { LazyOurPclTierCancel as PclTierCancel };

const LazyOurPclTierSubscribe = (props: RecordType) => <OurLazyLoad load={() => import('./PclTierSubscribe')} {...props} />;
export { LazyOurPclTierSubscribe as PclTierSubscribe };

const LazyOurPclTierSuccess = (props: RecordType) => <OurLazyLoad load={() => import('./PclTierSuccess')} {...props} />;
export { LazyOurPclTierSuccess as PclTierSuccess };
