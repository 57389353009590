import { Avatar, AvatarProps, Skeleton } from '@mui/material';
import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import { useGetMemberByName } from '@petconsole/pure-fe-state';
import { MemberRecord } from '@petconsole/pure-shared';

interface OurMemberAvatarProps extends AvatarProps {
  memberName?: string;
  name?: string;
  image?: string;
}

/*
  Provide memberName OR image. Image will override name and is useful for previews.
  Note: name is obsolete - use memberName
 */
const OurMemberAvatar = ({ memberName = '', name = '', image, onClick, sx = {}, ...rest }: OurMemberAvatarProps) => {
  const { data, isSuccess } = useGetMemberByName(memberName || name);
  const { avatar = '' } = (isSuccess && data ? data : {}) as MemberRecord;
  const src = image || ourMemberImageUrl(avatar);

  if (!src)
    return (
      <Skeleton variant="circular">
        <Avatar {...rest} />
      </Skeleton>
    );

  return (
    <Avatar
      sx={{ cursor: onClick ? 'pointer' : 'default', ...sx }}
      aria-label="member profile avatar"
      src={src}
      onClick={onClick}
      {...rest}
    >
      ?
    </Avatar>
  );
};

OurMemberAvatar.whyDidYouRender = true;

export default OurMemberAvatar;
