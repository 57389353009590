import { Telegram } from '@mui/icons-material';
import OurSendMailIcon from '../icons/OurSendMailIcon';
import ActionButton, { ActionButtonProps } from './ActionButton';

export interface OurSendButtonProps extends Omit<ActionButtonProps, 'tip' | 'Icon'> {
  tip?: ActionButtonProps['tip'];
  sent?: boolean;
}

const OurSendButton = ({ tip = 'Send', sent, ...rest }: OurSendButtonProps) => (
  <ActionButton tip={tip} Icon={sent ? Telegram : OurSendMailIcon} {...rest} />
);

OurSendButton.whyDidYouRender = true;

export default OurSendButton;
