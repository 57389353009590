import { ReactNode } from 'react';
import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone';
import { Container, Typography } from '@mui/material';

interface PhotoZoneContainerProps {
  leadText?: ReactNode;
  accept?: Accept;
  onDrop: DropzoneOptions['onDrop'];
  onReject: DropzoneOptions['onDropRejected'];
}

const PhotoZoneContainer = ({
  leadText,
  accept = { 'image/*': ['.jpeg', '.png'] },
  onDrop,
  onReject,
}: PhotoZoneContainerProps) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    onDropRejected: onReject,
    accept,
    maxFiles: 1,
    multiple: false,
  });

  return (
    <Container
      disableGutters
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        p: 2.5,
        border: 'dashed 1px',
        borderRadius: '2px',
        outline: 'none',
      }}
      {...getRootProps()}
    >
      {leadText}
      <input {...getInputProps()} />
      <Typography variant="body2" paragraph>
        {isDragActive && isDragAccept && 'Drop the files here...'}
        {isDragActive && isDragReject && 'File type not accepted'}
        {!isDragActive && "Drag 'n' drop an image file here, or click to select a file"}
      </Typography>
    </Container>
  );
};

PhotoZoneContainer.whyDidYouRender = true;

export default PhotoZoneContainer;
