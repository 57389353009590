import { skipToken } from '@reduxjs/toolkit/query';
import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { ApiEntity, ApiUseGetQuery } from '@petconsole/pure-shared';

interface UseGetEntityProps<T extends RecordType = RecordType> {
  apiEntity: ApiEntity<T>;
  id: string | typeof skipToken;
  queryHook: ApiUseGetQuery<T>;
}

const useGetEntity = <T extends RecordType = RecordType>({ apiEntity, id, queryHook }: UseGetEntityProps<T>) => {
  const queryResults = queryHook(id || skipToken);

  return { [apiEntity.name]: queryResults.data as MaybeNull<T> | undefined, ...queryResults } as ReturnType<ApiUseGetQuery>;
};

export default useGetEntity;
