import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  GetListOptions,
  GetListResults,
  ShopItemOptionTemplateRecord,
  shopItemOptionTemplateEntity,
  ApiEntity,
} from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = ShopItemOptionTemplateRecord;
type ListReturns = Promise<GetListResults<ApiRecord>>;

const apiPath = '/shopItems/optionTemplates';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi extends RecordType {
  getListByShop: (shopId: string, options?: GetListOptions) => ListReturns;
}

export type ShopItemOptionTemplateApi = Api<ApiRecord> & CustomApi;

export const shopItemOptionTemplateApi: ShopItemOptionTemplateApi = {
  ...common,
  getListByValue,
  getListByShop: (shopId: string, options) => getListByValue('shopId', shopId, options),
};

export const shopItemOptionTemplateApiEntity: ApiEntity<ApiRecord, CustomApi> /* : ApiEntity<ApiRecord> */ = {
  ...shopItemOptionTemplateEntity,
  api: shopItemOptionTemplateApi,
};
