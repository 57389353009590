// noinspection ES6PreferShortImport

import { MaybeNull, MaybeString, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity } from '../../types';

export interface Comment {
  type: DataEntityType;
  commentId: string;
  commentOnId: string;
  threadId: string;
  replyId: string;
  commentOnType: string;
  memberId: string;
  text: string;
  imageKey: MaybeString;
  reactionCount: number;
  commentCount: number;
  editedAt: MaybeString;
  createdAt: MaybeString;
  updatedAt: MaybeString;
}

export const commentEntity = entityAttributes({
  name: entityName.comment,
  attributes: {
    category: entityCategory.item,
    columns: { crud: false, images: false, time: true },
    loadLimit: 5,
    memberOwned: true,
    nameField: 'text',
    restrictGet: false,
  },
});

const { date, id, id2, nullable, number, strim, type } = schema;
export const commentSchema = entitySchema<Comment>(commentEntity, {
  commentOnId: id2().required(),
  threadId: id(), // gets set automatically
  replyId: id(),
  commentOnType: type(),
  memberId: id(true),
  text: strim(),
  imageKey: nullable(), // limit 1 image per comment/reply
  reactionCount: number(),
  commentCount: number(), // includes reply counts
  editedAt: date(),
});

export const commentDefaults: Partial<Comment> = {
  commentOnId: '',
  threadId: '',
  replyId: '',
  commentOnType: '',
  memberId: '',
  text: '',
  imageKey: '',
  reactionCount: 0,
  commentCount: 0,
  editedAt: null,
};

export interface CommentRecord extends Comment, RecordType {}

export type MaybeCommentRecord = MaybeNull<CommentRecord>;
export type CommentRecords = CommentRecord[];

export const initialComment: InitialEntity<Comment, CommentRecord> = (overrides = {}) =>
  initialEntity<Comment>(commentEntity, { ...commentDefaults, ...overrides }) as CommentRecord;
