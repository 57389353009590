import { Grid } from '@mui/material';
import OurButton, { OurButtonProps } from './OurButton';

export interface OurGridButtonProps extends OurButtonProps {
  width?: string | number;
  py?: number;
  item?: boolean
}

const OurGridButton = ({ width = '100%', py = 1, item = true, ...rest }: OurGridButtonProps) => (
  <Grid container item={item} id="button-container" justifyContent="center" py={py}>
    <Grid item width={width}>
      <OurButton {...rest} />
    </Grid>
  </Grid>
);

OurGridButton.whyDidYouRender = true;

export default OurGridButton;
