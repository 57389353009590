import { Favorite, FavoriteBorder } from '@mui/icons-material';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

export interface OurLikeButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  liked: boolean;
}

const OurLikeButton = ({ liked, onClick, ...rest }: OurLikeButtonProps) => (
  <OurIconButton
    tip="Like"
    color={liked ? 'secondary' : 'primary'}
    onClick={onClick}
    {...(!onClick && { disableRipple: true, style: { ...rest.style, cursor: 'default' } })}
    Icon={liked ? Favorite : FavoriteBorder}
    {...rest}
  />
);

OurLikeButton.whyDidYouRender = true;

export default OurLikeButton;
