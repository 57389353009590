import { RecordType } from '@petconsole/pure-base';
import { Api, EntityRecord } from '@petconsole/pure-shared';
import { NextAction, ThunkApi } from '../../types';

const payloadCreatorCreateEntity =
  <T extends RecordType = RecordType>(slice: string, idName: string, api: Api<T>, nextAction?: NextAction, callbacks?: (() => unknown)[]) =>
  async ({ [slice]: entity }: RecordType, { dispatch }: ThunkApi) => {
    const created = (await api.create(entity as T)) as EntityRecord;

    if (nextAction) dispatch(nextAction({ [idName]: created[idName] }));

    if (callbacks) callbacks.forEach((callback) => callback());

    return created;
  };

export default payloadCreatorCreateEntity;
