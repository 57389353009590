// noinspection ES6PreferShortImport

import { startOfDay } from 'date-fns';
import { defaultGoogleAddress, MaybeDate, MaybeNull, newId, RecordType } from '@petconsole/pure-base';
import { genderType, site } from '../../constants';
import { entityCategory, entityName, entityTabName, entityType } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { InitialEntity, LostFoundPet, MaybeImage } from '../../types';

interface FoundPet extends LostFoundPet {
  foundPetId: string;
  foundDate: MaybeDate;
  banner: MaybeImage;
}

const { General, Details, Avatar, Banner, Photos, Comments, Resolve } = entityTabName;
export const foundPetEntity = entityAttributes({
  name: entityName.foundPet,
  attributes: {
    addField: 'title',
    addInstructions:
      'Please enter a title, and the location where you found the pet. Please be sure to choose one of the suggestions Google makes when you start typing the address or city name.',
    addLabel: 'Title/Caption',
    addressLabel: 'Found City/Address',
    category: entityCategory.pet,
    columns: { address: true, member: true, crud: false, time: true },
    loadLimit: 20,
    maxImages: 4,
    memberOwned: true,
    nameField: 'petType',
    site: site.petConsole,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Resolve],
  },
});

const { avatar, boolean, date, email, id, gender, image, images, phone, strim, triple, type } = schema;
const { googleAddressColumns, memberColumns } = schema;

export const foundPetSchema = entitySchema<FoundPet>(foundPetEntity, {
  foundPetId: id(true),
  type: type(entityType.FoundPet),
  title: strim().required('A title/caption is required'),
  petName: strim(),
  petType: strim().default('Other'),
  petSubType: strim(),
  breed: strim(),
  size: strim(),
  color: strim(),
  markings: strim(),
  gender: gender(),
  fixed: triple(null),
  microchip: strim(),
  tattoo: strim(),
  avatar: avatar(),
  banner: image(),
  images: images(),
  createdAt: date(),
  updatedAt: date(),
  foundDate: date(),
  other: strim(),
  contactName: strim(),
  phoneNumber: phone(),
  email: email(),
  resolved: boolean(),
  resolvedAt: date(),
  returned: boolean(),
  siteHelped: boolean(),
  resolution: strim(),
  ...memberColumns(),
  ...googleAddressColumns(),
});

export const foundPetDefaults: Partial<FoundPet> = {
  foundPetId: newId(),
  type: entityType.FoundPet,
  title: '',
  petName: '',
  petType: 'Other',
  petSubType: '',
  breed: '',
  size: '',
  color: '',
  markings: '',
  gender: genderType.Other,
  fixed: null,
  microchip: '',
  tattoo: '',
  avatar: '',
  banner: null,
  images: [],
  createdAt: null,
  updatedAt: null,
  foundDate: startOfDay(new Date()).toISOString() as unknown as Date,
  other: '',
  contactName: '',
  phoneNumber: '',
  email: '',
  resolved: false,
  resolvedAt: null,
  returned: false,
  siteHelped: false,
  resolution: '',
  memberName: '',
  address: defaultGoogleAddress(),
};

export interface FoundPetRecord extends FoundPet, RecordType {}

export type MaybeFoundPetRecord = MaybeNull<FoundPetRecord>;
export type FoundPetRecords = FoundPetRecord[];

export const initialFoundPet: InitialEntity<FoundPet, FoundPetRecord> = (overrides = {}) =>
  initialEntity<FoundPet>(foundPetEntity, { ...foundPetDefaults, ...overrides }) as FoundPetRecord;
