import { BaseQueryFn, MutationDefinition, type MutationResultSelectorResult, skipToken } from '@reduxjs/toolkit/query';
import { UseMutationStateResult } from '@reduxjs/toolkit/src/query/react/buildHooks';
import { MaybeNull } from '@petconsole/pure-base';
import { cartApiEntity } from '@petconsole/pure-fe-api';
import { ApiBaseQueryArg, ApiUseGetQuery, CartRecord, CreateMethod, UpdateMethod } from '@petconsole/pure-shared';
import {
  useCreateCartMutation,
  useGetCartByShopQuery,
  useGetCartQuery,
  useUpdateCartMutation,
} from '../../../apiSlice';
import useCreateEntity from '../../../helpers/useCreateEntity';
import useGetEntity from '../../../helpers/useGetEntity';
import useGetEntityBy from '../../../helpers/useGetEntityBy';
import useUpdateEntity from '../../../helpers/useUpdateEntity';

const apiEntity = cartApiEntity;
type ApiRecord = CartRecord;
type CreateEntity = { createCart: CreateMethod<ApiRecord> };
type UpdateEntity = { updateCart: UpdateMethod<ApiRecord> };

type CreateMutationDefinition = MutationDefinition<
  CartRecord,
  BaseQueryFn<ApiBaseQueryArg<CartRecord>>,
  string,
  CartRecord,
  string
>;

type UpdateMutationDefinition = MutationDefinition<
  { id: string; body: CartRecord },
  BaseQueryFn<ApiBaseQueryArg<CartRecord>>,
  string,
  CartRecord,
  string
>;

type UseCreateCart = () => UseMutationStateResult<
  CreateMutationDefinition,
  MutationResultSelectorResult<CreateMutationDefinition>
> &
  CreateEntity;

type UseGetCart = ({ cartId }: { cartId: string }) => ReturnType<ApiUseGetQuery> & {
  cart?: MaybeNull<ApiRecord>;
};
type UseGetCartByShop = ({ shopId }: { shopId: string }) => ReturnType<ApiUseGetQuery> & {
  cart?: MaybeNull<ApiRecord>;
};

type UseUpdateCart = () => UseMutationStateResult<
  UpdateMutationDefinition,
  MutationResultSelectorResult<UpdateMutationDefinition>
> &
  UpdateEntity;

export const useCreateCart: UseCreateCart = () =>
  useCreateEntity<ApiRecord, CreateEntity>({ apiEntity, mutationHook: useCreateCartMutation });
export const useGetCart: UseGetCart = ({ cartId }) =>
  useGetEntity({ apiEntity, id: cartId || skipToken, queryHook: useGetCartQuery });
export const useGetCartByShop: UseGetCartByShop = ({ shopId }) =>
  useGetEntityBy({
    apiEntity,
    id: shopId || skipToken,
    queryHook: useGetCartByShopQuery,
  });
export const useUpdateCart: UseUpdateCart = () =>
  useUpdateEntity<ApiRecord, UpdateEntity>({
    apiEntity,
    mutationHook: useUpdateCartMutation,
  });
