import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useOurCognitoCache } from '@petconsole/pure-fe-amplify';
import { changeSiteApiEntity } from '@petconsole/pure-fe-api';
import { useOurSearchGet } from '@petconsole/pure-fe-utils';
import { ChangeSiteRecord } from '@petconsole/pure-shared';

const useOurEnterSite = () => {
  const { id: changeSiteId } = useParams();
  const { redirect = '' } = useOurSearchGet('redirect');
  const { clearItems, setItems } = useOurCognitoCache();

  const { api } = changeSiteApiEntity;
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current) return undefined;

    effectRan.current = true;

    // noinspection SpellCheckingInspection
    if (changeSiteId === 'noid') {
      clearItems().then(() => window.location.replace(redirect));
    } else
      api.get(changeSiteId as string).then((data) => {
        if (!data) throw new Error('Data not found for changing site');

        setItems((data as ChangeSiteRecord).storage)
          // .then(() => authFetchAuthSession())
          // .then(() => authCheck({ event: 'changeSite' }))
          // .then(() => authSignInWithRedirect({ provider: 'Google' }))
          // .then(() => authGetCurrentUser())
          // .then(({ success, user}) => {
          //   if (success && user && user.userId && user.username) return authSignOut();
          //
          //   return Promise.resolve();
          // })
          // .then(() => authSignInWithRedirect({ provider: 'Facebook' }))
          .then(() => window.location.replace(redirect));
      }).catch((reason) => {
        console.log('Getting change site data failed:', reason);

        window.location.replace(redirect);
      });
  }, [changeSiteId, redirect, clearItems, setItems, api]);
};

export default useOurEnterSite;
