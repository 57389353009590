import { mailHeaderApiEntity } from '@petconsole/pure-fe-api';
import { MailHeaderRecord } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchValue } from '../../../helpers';
import { BaseSliceOptions, DeleteHook, OurEntityHooks, RecordType, RequiredSliverByHooks } from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { fetchMailHeadersPayloadCreator } from './fetchMailHeaders';
import { removeMailHeaderReducers, removeMailHeaderThunk } from './removeMailHeader';

type ApiEntityRecord = MailHeaderRecord;

const apiEntity = mailHeaderApiEntity;
const { name: sliceName, idName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  selectId: (header) => header[idName] as string,
  comparer: ({ createdAt: a }: RecordType, { createdAt: b }: RecordType) => b?.localeCompare(a),
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      fetchesByValue: true,
      payloadCreator: null,
      payloadCreators: fetchMailHeadersPayloadCreator,
      sliceReducers: [removeMailHeaderReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { sliverByHooks } = sliceHookTypes;

export interface OurMailHeaderHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  mailHeaderHooks: RequiredSliverByHooks;
  customHooks: { useRemoveMailHeader: DeleteHook };
}

export const ourMailHeaderHooks: OurMailHeaderHooks = {
  entity: apiEntity,
  mailHeaderHooks: sliverByHooks(apiEntity, '', hooks) as RequiredSliverByHooks,
  customHooks: { useRemoveMailHeader: () => useOurDispatchValue(removeMailHeaderThunk) },
};

export default reducer;
