import { Box, Rating, Typography } from '@mui/material';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { RatingValues } from '@petconsole/pure-shared';
import useOurDarkMode from '../../../hooks/useOurDarkMode';
import averageRating from './averageRating';
import totalRaters from './totalRaters';

export interface OurAverageRatingProps {
  ratings: RatingValues;
  legend?: string;
  precision?: number;
  dim?: boolean;
}

const OurAverageRating = ({ ratings = [], legend = 'Rating', precision = 0.5, dim = false }: OurAverageRatingProps) => {
  const { palette } = useTheme();
  const darkMode = useOurDarkMode();
  const color = darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)';
  const rating = averageRating(ratings);
  const raters = totalRaters(ratings);

  return (
    <Box>
      {legend && (
        <Typography variant="caption" component="legend" {...(dim && { color })}>
          {legend}: {rating} ({raters} ratings)
        </Typography>
      )}
      <Rating
        aria-label="average-rating"
        value={rating}
        precision={precision}
        icon={<Favorite fontSize="inherit" />}
        emptyIcon={<FavoriteBorder fontSize="inherit" />}
        style={{ color: palette.secondary.main }}
        readOnly
      />
    </Box>
  );
};

OurAverageRating.whyDidYouRender = true;

export default OurAverageRating;
