import { Typography } from '@mui/material';
import useOurExitSite from './useOurExitSite';

const OurExitSite = () => {
  useOurExitSite();

  return <Typography>Changing site. Please wait...</Typography>;
};

OurExitSite.whyDidYouRender = true;

export default OurExitSite;
