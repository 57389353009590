import { PayloadAction } from '@reduxjs/toolkit';
import { RecordType, RecordTypes } from '@petconsole/pure-base';
import { ourPutImages, shopItemVariantApiEntity } from '@petconsole/pure-fe-api';
import { Image, ImageLog, Images, ShopItemVariantRecords } from '@petconsole/pure-shared';
import { ourSaveStatus } from '../../../constants';
import { writeReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick, StateSliver } from '../../../types';

const { api, name: sliceName, proper } = shopItemVariantApiEntity;
const typePrefix = `${sliceName}/save${proper}s`;

interface saveShopItemVariantsPayloadCreatorProps {
  shopItemId: string;
  variants: RecordTypes;
  replace: boolean;
  log: ImageLog;
}

export const saveShopItemVariantsPayloadCreator = async ({
  shopItemId,
  variants,
  replace,
  log,
}: saveShopItemVariantsPayloadCreatorProps) => {
  const images: Images = [];

  // Make a copy of the variants without the thumbnail image data, and a list of changed images
  const tempVariants = variants.map((variant) => {
    // Exclude src from the thumbnail
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { src, changed, ...thumbnail } = (variant.thumbnail || {}) as Image;

    if (changed) images.push(variant.thumbnail as Image);

    return { ...variant, thumbnail };
  });

  // Save the variants and changed images
  const [newVariants] = await Promise.all([
    api.upsertList(shopItemId, tempVariants, replace),
    ourPutImages({ images, log }),
  ]);

  // Re-apply the previous image data where it is still available
  newVariants.forEach((newVariant: RecordType) => {
    const { shopItemVariantId: id, thumbnail: newThumbnail } = newVariant;

    if (newThumbnail) {
      const found = variants.find((variant) => variant.shopItemVariantId === id);
      const oldThumbnail = found?.thumbnail as Image;

      if ((newThumbnail as Image).s3Key === oldThumbnail?.s3Key) (newThumbnail as Image).src = oldThumbnail.src;
    }
  });

  return newVariants;
};

export const saveShopItemVariantsThunk = appThunk(typePrefix, saveShopItemVariantsPayloadCreator);

export const saveShopItemVariantsReducers = (adapter: EntityAdapterWithPick) => {
  const reducers = writeReducers(saveShopItemVariantsThunk, sliceName, adapter);

  const fulfilled = (state: StateSliver, action: PayloadAction<ShopItemVariantRecords>) => {
    const errors = action.payload.filter((item) => item.error);

    if (errors.length === 0) {
      state.writeStatus = ourSaveStatus.succeeded;

      adapter.setAll(state, action.payload);

      return;
    }

    // const successes = action.payload.filter((item: RecordType) => !item.error);

    state.writeStatus = ourSaveStatus.failed;
    state.writeError = errors.map((error: RecordType) => error.message).join('/n');

    throw new Error(`saveShopItemVariants error: ${state.writeError}`);

    // adapter.setAll(<EntityState<RecordType>>state, successes);
  };

  return { ...reducers, [`${typePrefix}/fulfilled`]: fulfilled };
};
