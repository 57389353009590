import { RecordType } from '@petconsole/pure-base';
import { useOurDispatchById, useOurDispatchEntity, useOurFetchEntity, useOurSelectById } from '../hooks';
import { AnyAsyncThunk, SliceSelectors, SliceThunk, ThunkRecordFromRecord } from '../../types';

const sliceCrudHooks = (sliceName: string, entityType: string, selectors: SliceSelectors, thunks: SliceThunk) => {
  const hooks: RecordType = {};

  if (thunks.createEntity)
    hooks[`useOurCreate${entityType}`] = () => useOurDispatchEntity(sliceName, thunks.createEntity as AnyAsyncThunk);

  if (thunks.updateEntity)
    hooks[`useOurUpdate${entityType}`] = () => useOurDispatchEntity(sliceName, thunks.updateEntity as AnyAsyncThunk);

  if (thunks.fetchEntity)
    hooks[`useOurFetch${entityType}`] = () => useOurDispatchById(sliceName, thunks.fetchEntity as ThunkRecordFromRecord);

  if (thunks.deleteEntity)
    hooks[`useOurDelete${entityType}`] = () => useOurFetchEntity(sliceName, thunks.deleteEntity as AnyAsyncThunk);

  if (selectors) hooks[`useOurSelect${entityType}`] = (id: string) => useOurSelectById(selectors.selectById, id);

  return hooks;
};

export default sliceCrudHooks;
