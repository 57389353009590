import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { authFetchAuthSession } from '@petconsole/pure-fe-amplify';
import { ourUserHooks, useCurrentMember, WrappedResult } from '@petconsole/pure-fe-state';

const useIsMember = () => {
  const { email } = useCurrentMember();
  const fetchCurrentMember = ourUserHooks.customHooks.useFetchCurrentMember();

  // TODO: Prevent multiple fetches
  const isMember = useCallback(async () => {
    const { session } = await authFetchAuthSession();

    if (!session?.credentials) return false;

    if (email && session.tokens?.idToken?.payload.email === email) return true;

    const fetchResult = await fetchCurrentMember();
    const currentMember = unwrapResult(fetchResult as WrappedResult);

    return !!currentMember;
  }, [email, fetchCurrentMember]);

  return { isMember };
};

export default useIsMember;
