import { ourPutImage } from '@petconsole/pure-fe-api';
import { ImageKey, Log } from '@petconsole/pure-shared';

interface SaveAvatarProps {
  avatarPath?: string;
  avatarSrc?: string;
  adding?: boolean;
  avatarChanged?: boolean;
  avatarKey?: ImageKey;
  log?: Log;
}

const saveAvatar = async ({ avatarPath, avatarSrc, adding, avatarChanged, avatarKey, log }: SaveAvatarProps) => {
  if (avatarKey && (adding || avatarChanged)) {
    if (!log) throw new Error('saveAvatar: log is required');

    await ourPutImage({ s3Key: avatarPath as string, src: avatarSrc as string, log: log as Log });
  }
};

export default saveAvatar;
