import { RecordType, RecordTypes } from '@petconsole/pure-base';
import { ourPickProperties } from '@petconsole/pure-fe-utils';
import { ourFetchStatus } from '../../constants';
import { ThunkPayloadAction, ThunkFulfilledMethod, ThunkReducersType, AnyAsyncThunk } from '../../types';
import thunkPendingReducer from './thunkPendingReducer';
import thunkRejectedReducer from './thunkRejectedReducer';

const fetchReducers: ThunkReducersType = (fetchMethod, stateProperty, adapter) => {
  const { pending, fulfilled, rejected } = fetchMethod as AnyAsyncThunk;

  const fulfilledReducer: ThunkFulfilledMethod = (topState, action: ThunkPayloadAction) => {
    const state = stateProperty ? topState[stateProperty] : topState;
    const payload = action.payload as RecordType;

    state.nextKey = payload && payload.items ? payload.nextKey : null;
    state.readStatus = ourFetchStatus.succeeded;

    if (payload?.clear) adapter?.removeAll(state);

    if (adapter) {
      const { pickProperties } = adapter as unknown as RecordType;

      if (pickProperties) {
        if (payload.items)
          adapter.upsertMany(
            state,
            (payload.items as RecordTypes).map((item: RecordType) =>
              ourPickProperties(item, pickProperties as string[]),
            ),
          );
        else adapter.upsertOne(state, ourPickProperties(payload, pickProperties as string[]));
      } else if (payload) {
        if (payload.items) adapter.upsertMany(state, payload.items as RecordTypes);
        else adapter.upsertOne(state, payload);
      }
    }
  };

  return {
    [String(pending)]: thunkPendingReducer(stateProperty),
    [String(fulfilled)]: fulfilledReducer,
    [String(rejected)]: thunkRejectedReducer(stateProperty),
  };
};

export default fetchReducers;
