import { GetListResults } from '@petconsole/pure-shared';
import { AppDispatch } from '../../store';
import { NextAction } from '../../types/creatorTypes';

interface DispatchListItemsNextActionProps {
  data: GetListResults;
  idName: string;
  dispatch: AppDispatch;
  nextAction?: NextAction;
}

const dispatchListItemsNextAction = ({ data, idName, dispatch, nextAction }: DispatchListItemsNextActionProps) => {
  if (nextAction) {
    data.items.forEach(({ [idName]: id }) => dispatch(nextAction({ [idName]: id })));
  }

  return data;
};

export default dispatchListItemsNextAction;
