// noinspection ES6PreferShortImport

import { ChangeEvent } from 'react';
import 'react-phone-number-input/style.css';
import ReactPhoneInput, { Props, Value } from 'react-phone-number-input';
import { DefaultInputComponentProps } from 'react-phone-number-input/index';
import { RecordType } from '@petconsole/pure-base';
import { ourCountryA2 } from '@petconsole/pure-shared';
import getFieldProps from '../../components/inputs/helpers/getFieldProps';
import { OurTextProps } from '../../components/inputs/OurText';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import PhoneInput, { PhoneInputProps } from '../react-hook-form/PhoneInput';
import OurPhoneInput from './OurPhoneInput';

export interface OurPhoneNumberProps<T extends RecordType = RecordType> extends OurTextProps<T> {
  id?: string;
}

const OurPhoneNumber = <T extends RecordType = RecordType>(props: OurPhoneNumberProps<T>) => {
  const darkMode = useOurDarkMode();

  if (props.form) return <PhoneInput {...props as PhoneInputProps<T>} />;

  const fieldProps = getFieldProps({ ...props, darkMode, id: props.id || 'phoneNumber' });
  const { id, onChange, InputProps } = fieldProps;
  const { readOnly } = InputProps;
  const onPhoneChange = onChange
    ? (value?: Value) => onChange({ target: { id, value } } as unknown as ChangeEvent<Element>)
    : undefined;

  const phoneInputProps = {
    ...fieldProps,
    ...(readOnly ? { readOnly: true, countrySelectProps: { disabled: true } } : {}),
    ...(onPhoneChange ? { onChange: onPhoneChange } : {}),
    inputComponent: OurPhoneInput,
    countryOptionsOrder: [ourCountryA2.CA, ourCountryA2.US, '|', '...'],
    defaultCountry: ourCountryA2.CA,
  } as Readonly<Props<DefaultInputComponentProps>>;

  return <ReactPhoneInput {...phoneInputProps} />;
};

OurPhoneNumber.whyDidYouRender = true;

export default OurPhoneNumber;
