import { MouseEvent, MouseEventHandler } from 'react';
import { ReactionHooks } from '@petconsole/pure-fe-state';
import { EntityRecord, MaybeNull, ReactionRecord } from '@petconsole/pure-shared';

export interface EntityCardReaction {
  hasLikes: boolean;
  likeCount: number;
  liked: boolean;
  onLike?: MouseEventHandler,
}

interface UseEntityCardReaction<T extends EntityRecord = EntityRecord> {
  reactionHooks?: ReactionHooks;
  cardId: string;
  entityRecord?: MaybeNull<T>;
}

const useEntityCardReaction = <T extends EntityRecord = EntityRecord>({ reactionHooks , cardId, entityRecord }: UseEntityCardReaction<T>) => {
  const entityReaction = reactionHooks ? reactionHooks.useSelect(cardId) as ReactionRecord : null;
  const toggleLiked = reactionHooks ? reactionHooks.useToggleLike() : undefined;

  const onLike = (event: MouseEvent) => {
    event.preventDefault();
    toggleLiked && toggleLiked(cardId);
  };

  const reaction: EntityCardReaction = {
    hasLikes: !!reactionHooks,
    likeCount: entityRecord?.likeCount || 0,
    liked: entityReaction?.liked || false,
    onLike: toggleLiked ? onLike : undefined,
  };

  return { reaction };
};

export default useEntityCardReaction;
