import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OurDateField, { OurDateProps } from './OurDateField';

interface DateGridItemProps extends OurDateProps, RegularBreakpoints {
  gridProps?: GridProps;
}

const DateGridItem = ({ gridProps, xs = 12, sm, md, lg, xl, ...rest }: DateGridItemProps) => {
  return (
    <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
      <OurDateField {...rest} />
    </Grid>
  );
};

DateGridItem.whyDidYouRender = true;

export default DateGridItem;
