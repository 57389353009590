import { PropsWithChildren, ReactNode, useState } from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OurBackHeadings, OurMarkdownHelp, OurTitleHeading, OurToolbarProps } from '@petconsole/pure-fe-mui';
import { HelpProps } from '@petconsole/pure-shared';
import MyShopAppBar from './MyShopAppBar';
import { myShopDrawerWidth } from '../constants';
import MyShopDrawer from './MyShopDrawer';
import MyShopMenuItems from './MyShopMenuItems';

interface MyShopContainerProps extends PropsWithChildren {
  superTitle: string;
  title: string;
  rightHeading: ReactNode;
  help?: typeof OurMarkdownHelp | null;
  helpProps?: Partial<HelpProps>;
  toolbarProps?: Partial<OurToolbarProps>;
}

const MyShopContainer = ({
  superTitle,
  title,
  rightHeading,
  help = OurMarkdownHelp,
  helpProps,
  toolbarProps,
  children,
}: MyShopContainerProps) => {
  const { spacing, transitions } = useTheme();

  const [open, setOpen] = useState(false);

  const openTransition = transitions.create(['width', 'margin'], {
    easing: transitions.easing.sharp,
    duration: transitions.duration.leavingScreen,
  });
  const closeTransition = transitions.create(['width', 'margin'], {
    easing: transitions.easing.sharp,
    duration: transitions.duration.enteringScreen,
  });
  const transitionSx = {
    ...(open && { transition: openTransition, marginRight: `${myShopDrawerWidth}px` }),
    ...(!open && { transition: closeTransition, marginRight: spacing(7) }),
  };

  return (
    <Grid container id="my-shop-container" sx={transitionSx}>
      <MyShopAppBar open={open} setOpen={setOpen} openTransition={openTransition} />
      <Grid container component="main" id="under-app-bar-container">
        <OurBackHeadings
          heading={<OurTitleHeading title={superTitle} heading={title} />}
          help={help}
          helpProps={helpProps}
          right={rightHeading}
          toolbarProps={toolbarProps}
        />
        {children}
      </Grid>
      {/* Note: When drawer is anchored right, content must be before the drawer */}
      <MyShopDrawer open={open} setOpen={setOpen}>
        <MyShopMenuItems />
      </MyShopDrawer>
    </Grid>
  );
};

MyShopContainer.whyDidYouRender = true;

export default MyShopContainer;
