import { setPropertyValue } from '../misc';
import { GetPropertyValue, propertyReducerType } from '../../types';

const togglePropertyReducer: propertyReducerType =
  (propertyName, parentName = '') =>
  (state) => {
    const getValue = ((value: boolean) => !value) as GetPropertyValue;

    setPropertyValue({ state, propertyName, parentName, getValue });
  };

export default togglePropertyReducer;
