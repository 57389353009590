import { CardMedia, SxProps, Theme } from '@mui/material';

interface OurCardImageProps {
  image: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  sx?: SxProps<Theme>;
}

// img component requires height to be specified
const OurCardImage = ({ image, alt, width, height, sx }: OurCardImageProps) => (
  <CardMedia
    component="img"
    width={width || '100%'}
    height={height || 'auto'}
    image={image}
    alt={alt}
    {...(sx && { sx })}
  />
);

OurCardImage.whyDidYouRender = true;

export default OurCardImage;
