import { RecordType } from '@petconsole/pure-base';
import { Api } from '@petconsole/pure-shared';
import { NextAction, ThunkApi } from '../../types';
import dispatchListItemsNextAction from '../misc/dispatchListItemsNextAction';
import fetchParams from '../misc/fetchParams';

const payloadCreatorListWithFetch =
  <T extends RecordType = RecordType>(
    idName: string,
    api: Api<T>,
    slice: string,
    sliver: string,
    nextAction?: NextAction,
  ) =>
  async (_: unknown, { getState, dispatch }: ThunkApi) =>
    api
      .getList(fetchParams(getState, slice, sliver))
      .then((data) => dispatchListItemsNextAction({ data, idName, dispatch, nextAction }));

export default payloadCreatorListWithFetch;
