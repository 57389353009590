import { Controller, Path, UseFormReturn } from 'react-hook-form';
import ReactPhoneInput, { Props } from 'react-phone-number-input';
import { DefaultInputComponentProps, Value } from 'react-phone-number-input/index';
import { RecordType } from '@petconsole/pure-base';
import { ourCountryA2 } from '@petconsole/pure-shared';
import OurTooltip from '../../components/misc/OurTooltip';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import OurPhoneInput from '../phoneNumber/OurPhoneInput';
import getInputProps, { GetInputProps } from './helpers/getInputProps';

export interface PhoneInputProps<T extends RecordType = RecordType> extends GetInputProps<T> {
  form: UseFormReturn<T>;
  tip?: string;
}

const PhoneInput = <T extends RecordType = RecordType>({ form, tip, ...rest }: PhoneInputProps<T>) => {
  const darkMode = useOurDarkMode();

  const id = rest.id || ('phoneNumber' as Path<T>);

  return (
    <OurTooltip tip={tip}>
      <Controller
        control={form.control}
        name={id}
        // rules={ required, min, max, minLength, maxLength, pattern, validate }
        render={({
          field: { onChange, onBlur, value, ref /* , disabled */ },
          fieldState: { isTouched, error: fieldError /* invalid, isDirty, */ },
          // formState: {
          //   isDirty,
          //   dirtyFields,
          //   touchedFields,
          //   defaultValues,
          //   isSubmitted,
          //   isSubmitSuccessful,
          //   isSubmitting,
          //   isLoading,
          //   submitCount,
          //   isValid,
          //   isValidating,
          //   errors,
          // },
        }) => {
          // console.log(rest.id, value);
          const props = getInputProps({
            ...rest,
            id,
            darkMode,
            onChange,
            onBlur,
            value: (rest.value ?? value) as Value,
            ref,
            isTouched,
            fieldError,
          });

          const onPhoneChange = (newValue?: Value) => {
            onChange({ target: { id, value: newValue || '' } });
          };

          return (
            <ReactPhoneInput
              {...props}
              ref={ref as Props<DefaultInputComponentProps>['ref']}
              inputComponent={OurPhoneInput}
              countryOptionsOrder={[ourCountryA2.CA, ourCountryA2.US, '|', '...']}
              defaultCountry={ourCountryA2.CA}
              value={props.value as Value}
              onChange={onPhoneChange}
            />
          );
        }}
      />
    </OurTooltip>
  );
};

export default PhoneInput;
