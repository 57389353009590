import useOurGridCardCenterSizes from '../hooks/useOurGridCardCenterSizes';
import { MaybeBreakpoints } from '../types';

export interface UseGridMarginsProps {
  marginColumns?: boolean;
  margins?: MaybeBreakpoints;
  leftMargins?: MaybeBreakpoints;
  rightMargins?: MaybeBreakpoints;
}

const useGridMargins = ({
  marginColumns,
  margins = { md: 1, lg: 2, xl: 3 },
  leftMargins,
  rightMargins,
}: UseGridMarginsProps) => {
  const leftGridMargins = leftMargins || margins;
  const rightGridMargins = rightMargins || margins;
  const { centerSizes } = useOurGridCardCenterSizes({ marginColumns, leftMargins, margins, rightMargins });

  return { leftGridMargins, centerSizes, rightGridMargins };
};

export default useGridMargins;
