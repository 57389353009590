import { RecordType } from '@petconsole/pure-base';
import { pclTierPriceApiEntity } from '@petconsole/pure-fe-api';
import { fetchReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick } from '../../../types';

const { api, name: sliceName, proper } = pclTierPriceApiEntity;

export const fetchPclTierPriceByPclTierAndEffectiveThunk = appThunk(
  `${sliceName}/fetch${proper}sByPclTierAndEffective`,
  async ({ pclTierId, effectiveDate }: RecordType) =>
    api.getByPclTierAndEffective(pclTierId as string, effectiveDate as string),
);

export const fetchPclTierPriceByPclTierAndEffectiveReducers = (adapter: EntityAdapterWithPick) =>
  fetchReducers(fetchPclTierPriceByPclTierAndEffectiveThunk, sliceName, adapter);
