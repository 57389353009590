import { RecordType } from '@petconsole/pure-base';
import { AnyAsyncThunk, ThunkFulfilledMethod, ThunkReducersType } from '../../types';

const fetchReactionReducers: ThunkReducersType = (fetchMethod, stateProperty) => {
  const { fulfilled } = fetchMethod as AnyAsyncThunk;

  const fulfilledReducer: ThunkFulfilledMethod = (state, action) => {
    const payload = action.payload as RecordType;

    state[stateProperty].reactions[payload.reactionToId as string] = payload;
  };

  return {
    // [pending]: thunkPendingReducer(stateProperty),
    [String(fulfilled)]: fulfilledReducer,
    // [rejected]: thunkRejectedReducer(stateProperty),
  };
};

export default fetchReactionReducers;
