import { friendApiEntity } from '@petconsole/pure-fe-api';
import { writeReducers } from '../../helpers';
import { appThunk } from '../../store/appThunk';
import { EntityAdapterWithPick } from '../../types';

const { api, name: sliceName, proper } = friendApiEntity;

export const pauseFriendshipThunk = appThunk(`${sliceName}/pause${proper}ship`, async (name: string) =>
  api.pause(name),
);

export const resumeFriendshipThunk = appThunk(`${sliceName}/resume${proper}ship`, async (name: string) =>
  api.resume(name),
);

export const pauseFriendshipReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(pauseFriendshipThunk, sliceName, adapter);

export const resumeFriendshipReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(resumeFriendshipThunk, sliceName, adapter);
