import { post } from 'aws-amplify/api';
import { RecordType } from '@petconsole/pure-base';
import { restApiName } from '../constants';
import { PostInput, PostInputProps, RestApiOptionsBase } from '../types';
import restCatch from './restCatch';

export interface RestPostInput extends Omit<PostInput, 'apiName' | 'options'> {
  apiName?: string;
  options?: Omit<PostInput['options'], 'body'> & { body?: RecordType };
}

export type PostResult = RecordType;
export type RestPost = (props: RestPostInput) => Promise<PostResult>;

export const attemptPost = ({ apiName, path, options }: PostInputProps) => post({ apiName, path, options: options as RestApiOptionsBase });

const restPost: RestPost = async ({ apiName = restApiName, path, options }) => {
  try {
    const result = attemptPost({ apiName, path, options });
    const response = await result.response;
    const body = response.body;

    if (response.headers['content-type'] === 'application/json') return (body.json as () => Promise<PostResult>)();

    // return body.text();
  } catch (error) {
    restCatch('restPost', error);
  }

  throw new Error(`restPost received unexpected results from ${path}`)
};

export default restPost;
