// Return a new array. Insert into array of arrays when finding an array where condition is true
export type InsertIntoArraysProps<T = unknown> = (
  array: unknown[],
  value: unknown,
  condition: (item: unknown) => boolean,
  insertBefore: boolean,
) => T[];

const ourInsertIntoArray: InsertIntoArraysProps = (array, value, condition, insertBefore) => {
  const newItems = [] as unknown[];

  array.forEach((item) => {
    const insert = condition(item);

    if (insert && insertBefore) newItems.push(value);

    newItems.push(item);

    if (insert && !insertBefore) newItems.push(value);
  });

  return newItems;
};

export default ourInsertIntoArray;
