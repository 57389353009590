import { Box, LinearProgress } from '@mui/material';

const OurLinearProgress = () => (
  <Box mt={2} width="100%" sx={{ '& > * + *': { marginTop: 2 } }}>
    <LinearProgress />
  </Box>
);

OurLinearProgress.whyDidYouRender = true;

export default OurLinearProgress;
