import { Share, ShareOutlined } from '@mui/icons-material';
import ActionButton, { ActionButtonProps } from './ActionButton';

export interface OurShareButtonProps extends Omit<ActionButtonProps, 'tip' | 'Icon'> {
  tip?: ActionButtonProps['tip'];
  shared?: boolean;
}

const OurShareButton = ({ tip="Share", shared, ...rest }: OurShareButtonProps) => (
  <ActionButton tip={tip} Icon={shared ? Share : ShareOutlined}  {...rest} />
);

OurShareButton.whyDidYouRender = true;

export default OurShareButton;
