import { PropsWithChildren } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import OurMarginGridCard from '../components/cards/OurMarginGridCard';
import OurBackHeadings from '../components/headings/OurBackHeadings';
import OurMediaImage from '../components/images/OurMediaImage';
import { commonMedia } from '../constants';

// noinspection SpellCheckingInspection
const OurPricingPage = ({ children }: PropsWithChildren) => (
  <OurMarginGridCard>
    <OurBackHeadings heading="Pricing" />
    <Grid item xs={12}>
      <OurMediaImage image={commonMedia.pricing} alt="Pricing image" />
      <Box display="flex" flexDirection="column" rowGap={1}>
        <Typography variant="h6" align="center">
          <strong>Member Pricing</strong>
        </Typography>
        <Typography variant="body1">TL;DR: Don't worry about it.</Typography>
        <Typography>
          Seriously, we will always have a free tier. This site won't be successful without a great community. We think
          a great community should be accessible to all. That means FREE.
        </Typography>
        <Typography>
          On the other hand, we are a for-profit business and we need to have revenue to keep the lights on, and pay
          some wages to help grow this site and consistently improve and add to the features available.
        </Typography>
        <Typography>
          You can expect to see some ads before long. That shouldn't come as too much of a surprise, and it's pretty
          common on most sites these days. We also expect to add one or more paid tiers down the road.
        </Typography>
        <Typography>
          The difference though, is we want you to be confident that you're getting value for your money. We have some
          thoughts on how to ensure this, but we really want to do some more research, and get more feedback from our
          members before we implement anything specific.
        </Typography>
        <Typography>While we figure this stuff out, we would love it if you could help by:</Typography>
        <ul>
          <li>
            <Typography>sharing the site, and inviting your friends and family to join</Typography>
          </li>
          <li>
            <Typography>giving us feedback on the site:</Typography>
            <ul>
              <li>
                <Typography>What features do you like? What are we doing well?</Typography>
              </li>
              <li>
                <Typography>Are there features you don't like? Is there something we could do better?</Typography>
              </li>
              <li>
                <Typography>Is there something missing, or a great feature we could add?</Typography>
              </li>
              <li>
                <Typography>What features are high value and you think members would be willing to pay for?</Typography>
              </li>
            </ul>
          </li>
        </ul>
        {children}
      </Box>
    </Grid>
  </OurMarginGridCard>
);

OurPricingPage.whyDidYouRender = true;

export default OurPricingPage;
