import { LocalLibrary } from '@mui/icons-material';
import { List } from '@mui/material';
import { webPath } from '@petconsole/pure-shared';
import OurAppMenuItem from './OurAppMenuItem';

const OurBlogMenuItem = () => {
  return (
    <List disablePadding>
      {/*{renderMenuItem(ForumsIcon, 'Forums', webPath.forum)}*/}
      <OurAppMenuItem item={[LocalLibrary, 'Blog', webPath.blog]} />
    </List>
  );
};

OurBlogMenuItem.whyDidYouRender = true;

export default OurBlogMenuItem;
