import { useCallback, useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Grid, Typography } from '@mui/material';
import { MaybeNull } from '@petconsole/pure-base';
import { SetState } from '@petconsole/pure-shared';
import OurTypoText from '../../components/misc/OurTypoText';
import AvatarDrop from './AvatarDrop';
import AvatarView from './AvatarView';

interface AvatarViewEditProps {
  heading?: string;
  avatar: string;
  setAvatar: SetState<string>;
  setChanged: SetState<boolean>;
  setEditor: SetState<MaybeNull<AvatarEditor>>;
  readOnly: boolean;
  viewWidth?: number;
  editWidth?: number;
}

const AvatarViewEdit = ({
  heading = 'Avatar Photo',
  avatar,
  setAvatar,
  setChanged,
  setEditor,
  readOnly,
  viewWidth,
  editWidth,
}: AvatarViewEditProps) => {
  // Local copy of editor, so we can handle cleanup when unmounting
  const [localEditor, setLocalEditor] = useState<AvatarEditor>();

  useEffect(() => {
    // On unmount, capture the editor's contents
    return () => {
      // WARNING: Attempting to destructure rather than immediately getting image, broke this
      if (localEditor && !readOnly) {
        // setAvatar(localEditor.getImageScaledToCanvas().toDataURL());
        const image = localEditor.getImageScaledToCanvas();
        const dataUrl = image.toDataURL();

        setAvatar(dataUrl);
      }
    };
  }, [localEditor, readOnly, setAvatar]);

  const onSetAvatar = useCallback(
    (value: AvatarEditor) => {
      setEditor(value);

      // Only allow setting, not clearing
      if (!value) return;

      setLocalEditor(value);
    },
    [setEditor],
  ) as SetState<MaybeNull<AvatarEditor>>;

  return (
    <Grid item id="avatar-view-edit" display="flex" width="100%" justifyContent="center">
      {heading && <OurTypoText typeProps={{ variant: 'h6', paragraph: true }} text={heading} />}
      {readOnly && <AvatarView {...{ avatar, width: viewWidth }} />}
      {!readOnly && <AvatarDrop {...{ avatar, setAvatar, setChanged, setEditor: onSetAvatar, width: editWidth }} />}
      {readOnly && !avatar && <Typography pt={1}>No avatar added</Typography>}
    </Grid>
  );
};

AvatarViewEdit.whyDidYouRender = true;

export default AvatarViewEdit;
