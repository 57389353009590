import { EntityRecord } from '@petconsole/pure-shared';
import { createAppSelector } from '../../store/storeHooks';
import ourStateSliverData from '../state/ourStateSliverData';
import selectsPropertyByValueOuterSelector from './selectsPropertyByValueOuterSelector';

interface SelectsByPropertyNameAndValueProps {
  sliceName: string;
  sliverName: string;
  propertyName: string;
  valueName: string;
  value: string;
}

const selectsPropertyByValue = ({
  sliceName,
  sliverName,
  propertyName,
  valueName,
  value,
}: SelectsByPropertyNameAndValueProps) => {
  const outerSelector = selectsPropertyByValueOuterSelector({ propertyName, valueName, value });

  return createAppSelector(
    [(state) => ourStateSliverData<EntityRecord>(state, sliceName, sliverName)],
    outerSelector,
  );
};

export default selectsPropertyByValue;
