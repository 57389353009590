import { MouseEventHandler } from 'react';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import OurButton from '../buttons/OurButton';
import OurLinearProgress from '../misc/OurLinearProgress';
import OurDraggableDialog from './OurDraggableDialog';

interface OurConfirmDeleteDialogProps {
  isOpen: boolean;
  onCancel: MouseEventHandler;
  onConfirm: MouseEventHandler;
  title?: string;
  text?: string;
  busy?: boolean;
}

const OurConfirmDeleteDialog = ({ isOpen, onCancel, onConfirm, title, text, busy }: OurConfirmDeleteDialogProps) => {
  return (
    <OurDraggableDialog title={title || 'Confirm Delete'} isOpen={isOpen} onClose={onCancel}>
      <DialogContent>
        <DialogContentText
          paddingTop={1}
          id="delete-dialog-description"
          color="inherit"
          variant="body1"
        >
          {text || 'Are you sure you want to delete this? It may already be in use.'}
        </DialogContentText>
        {busy && <OurLinearProgress />}
      </DialogContent>
      <DialogActions>
        <OurButton label="Cancel" onClick={onCancel} color="secondary" disabled={busy} autoFocus />
        <OurButton label="Delete" onClick={onConfirm} disabled={busy} />
      </DialogActions>
    </OurDraggableDialog>
  );
};

OurConfirmDeleteDialog.whyDidYouRender = true;

export default OurConfirmDeleteDialog;
