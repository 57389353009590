import { postEntity } from '@petconsole/pure-shared';
import { appThunk } from '../../../store/appThunk';
import {
  fetchByTopicPostsFromPayloadCreator,
  fetchReplyPostsSincePayloadCreator,
} from './fetchByTopicPostsPayloadCreators';

const { name: sliceName, proper } = postEntity;

export const fetchByTopicPostsFromThunk =
  appThunk(
    `${sliceName}/fetchByTopic${proper}sFrom`,
    fetchByTopicPostsFromPayloadCreator,
  );

export const fetchReplyPostsSinceThunk = appThunk(
  `${sliceName}/fetchReply${proper}sSince`,
  fetchReplyPostsSincePayloadCreator,
);
