import { Grid } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import { ourKeysInclude } from '@petconsole/pure-fe-utils';
import { EntityAttributes, ExtendedFormik } from '@petconsole/pure-shared';
import OurLikeCountButton from '../components/buttons/OurLikeCountButton';
import { EntityCardReaction } from '../components/cards/ourEntityCards/helpers/useEntityCardReaction';
import OurInteger from '../components/inputs/numbers/OurInteger';
import OurText from '../components/inputs/OurText';
import OurTextGridItem from '../components/inputs/OurTextGridItem';
import OurAverageRating from '../components/misc/averageRating/OurAverageRating';

export interface OurListEntityGeneralProps {
  entity: EntityAttributes;
  formik: ExtendedFormik;
  isEditing: boolean;
  isOwner: boolean;
  readOnly: boolean;
  reaction?: EntityCardReaction;
  generalProps?: RecordType;
}

// TODO: Enable onLike when we can prevent invalid adjustments
const OurListEntityGeneral = ({
  entity,
  formik,
  readOnly,
  reaction = {} as EntityCardReaction,
}: OurListEntityGeneralProps) => {
  const { category } = entity;
  const { rating, reaction: hasReaction } = entity.columns;
  const { ratings } = formik?.values || {};
  const { hasLikes, likeCount, liked/* , onLike */ } = reaction;
  const taglinePlaceholder = `Your ${category}'${category.endsWith('s') ? '' : 's'} tagline/catchphrase...`;
  const aboutUsPlaceholder = `What would you like others to know about your ${category}?`;

  const established = 'established';
  const hours = 'hours';
  const hasEstablished = ourKeysInclude(formik.initialValues, established);
  const hasHours = ourKeysInclude(formik.initialValues, hours);

  return (
    <Grid container item spacing={1}>
      <Grid container item spacing={1}>
        <Grid item xs={12} sm={6}>
          {hasEstablished && <OurInteger formik={formik} id={established} format="####" mask="#" thousandsGroupStyle="none" thousandSeparator={false} readOnly={readOnly} />}
        </Grid>
        <Grid item xs={12} sm={6}>
          {rating && <OurAverageRating ratings={ratings} />}
          {hasReaction && hasLikes && (
            <OurLikeCountButton likes={likeCount} liked={liked} onClick={undefined /* onLike */} />
          )}
        </Grid>
      </Grid>
      {hasHours && <OurTextGridItem formik={formik} id={hours} readOnly={readOnly} />}
      <OurTextGridItem formik={formik} id="tagline" placeholder={taglinePlaceholder} readOnly={readOnly} />
      <OurText
        formik={formik}
        id="aboutUs"
        variant="outlined"
        placeholder={aboutUsPlaceholder}
        multiline
        minRows={3}
        maxRows={50}
        readOnly={readOnly}
        sx={{ mt: 1, ml: 1 }}
      />
    </Grid>
  );
};

OurListEntityGeneral.whyDidYouRender = true;

export default OurListEntityGeneral;
