// noinspection ES6PreferShortImport

import { PropsWithChildren, useContext } from 'react';
import { Menu } from '@mui/icons-material';
import { Toolbar } from '@mui/material';
import { OurAppContext } from '../../../context/OurAppContext';
import useOurWidth from '../../../hooks/useOurWidth';
import OurIconButton from '../../buttons/OurIconButton';

const OurAppMenuToolBar = ({ children }: PropsWithChildren) => {
  const { menuOpen, setMenuOpen } = useContext(OurAppContext);
  const width = useOurWidth();

  return (
    <Toolbar variant="dense" sx={{ px: width === 'xs' ? 1 : 2 }}>
      {!menuOpen && (
        <OurIconButton
          tip="Menu"
          aria="open drawer"
          Icon={Menu}
          color="inherit"
          onClick={() => setMenuOpen(true)}
          edge="start"
          sx={{ justifyContent: 'flex-start', ...(menuOpen ? { display: 'none' } : {}) }}
        />
      )}
      {children}
    </Toolbar>
  );
};

OurAppMenuToolBar.whyDidYouRender = true;

export default OurAppMenuToolBar;
