import { Button } from '@mui/material';
import { ourButtonBorderRadius } from '../../constants';
import OurHeadingChildren from './OurHeadingChildren';
import OurHeadingLink, { OurHeadingLinkProps } from './OurHeadingLink';

export type OurHeadingProps = OurHeadingLinkProps;

const OurHeading = ({ linkTo, children, ...rest }: OurHeadingProps) => {
  if (!linkTo) return <OurHeadingChildren children={children} {...rest} />;

  if (typeof children !== 'string') return <OurHeadingLink linkTo={linkTo} children={children} {...rest} />;

  return (
    <Button fullWidth sx={{ textTransform: 'none', borderRadius: ourButtonBorderRadius }}>
      <OurHeadingLink linkTo={linkTo} children={children} {...rest} />
    </Button>
  );
};

OurHeading.whyDidYouRender = true;

export default OurHeading;
