import { nowToIso, RecordType } from '@petconsole/pure-base';
import { reactionApiEntity } from '@petconsole/pure-fe-api';
import { Api, EntityName, entityReaction, ourEntityNameToType } from '@petconsole/pure-shared';
import { apiSlice } from '../../api/apiSlice';
import { ThunkApi } from '../../types';
import getStateSliceReactions from '../misc/getStateSliceReactions';
import setEntityReactionCreator from './ourSetEntityReactionCreator';
import ourReactToEntityThunk from './ourReactToEntityThunk';

export interface ToggleLikedProps<T extends RecordType = RecordType> {
  entityName: EntityName;
  api: Api<T>;
  id: string;
}

const ourToggleLiked = async <T extends RecordType = RecordType>({ entityName, api, id }: ToggleLikedProps<T>, { getState, dispatch }: ThunkApi) => {
  const type = ourEntityNameToType(entityName);
  const reaction = getStateSliceReactions({ getState, sliceName: entityName })[id];
  const { liked, reactionId } = reaction;

  // Optimistic change to UI assuming the following backend updates will succeed
  const setReactionCreator = setEntityReactionCreator({ entityName });

  // Dispatching a simple action (non thunk) returns { type, payload }
  dispatch(setReactionCreator({ ...reaction, liked: !liked }));

  const newReaction = { ...reaction, liked: !liked, likedAt: nowToIso() };
  const { create, update } = reactionApiEntity.api;

  // TODO: Update Reaction record via Redux api vs using ourApiEntity
  const reactToThunk = ourReactToEntityThunk<T>({ entityName });
  const reactToArg = { entityName, api, id, reaction: liked ? entityReaction.unlike : entityReaction.like };

  const [updated] = await Promise.all([
    reactionId ? update(reactionId, newReaction) : create(newReaction),
    // Note: writeReducers will be called with the main sliver rather than the sliver the member is on
    dispatch(reactToThunk(reactToArg)),
  ]);

  // After the update, invalidate the get<Entity Type>Query tag so the data is re-fetched for the UI
  dispatch(apiSlice.util.invalidateTags([{ type, id }]));

  // If we wanted to, we could adjust the likeCount on each sliver containing the id
  // const setEntityActionType = `${entityName}/set${type}Entity`;
  // const setEntity = createAction<RecordType>(setEntityActionType);
  // dispatch(setEntity({ id, data: reactToResult.payload }));

  return updated;
};

export default ourToggleLiked;
