import { CardContent, Grid, /* Skeleton, */ Typography } from '@mui/material';
import { newId } from '@petconsole/pure-base';
import { LayoutGrid, LayoutImageCell, LayoutTextCell } from '@petconsole/pure-shared';
import OurMemberImage from '../images/OurMemberImage';

interface OurPostCardLayoutProps {
  layout?: LayoutGrid;
}

const OurPostCardLayout = ({ layout /* , isVisible */ }: OurPostCardLayoutProps) => {
  if (!layout || !layout[0]) return null;

  const renderText = (text: string, key: number) => (
    <CardContent key={key} style={{ whiteSpace: 'pre-line' }} sx={{ p: 0.625, '&:last-child': { pb: 0.625 } }}>
      <Typography>{text}</Typography>
    </CardContent>
  );

  return layout.map((row1) => (
    <Grid container key={`r1-${newId()}`}>
      <Grid item xs={12} display="flex">
        {row1.map((col1) => (
          <Grid item xs={col1[0].width} key={`c1-${newId()}`} display="flex" flexDirection="column">
            {col1.map(({ type, ...layoutCell }, rowIndex2) => {
              if (type === 'text') return renderText((layoutCell as LayoutTextCell).text, rowIndex2);

              if (type === 'image') {
                // TODO: Make sure we make the skeleton the same size as the image will be
                // if (!isVisible) return <Skeleton key={`r2-${ourNewId()}`} variant="rectangular" />;

                const { s3Key = '', src = '', title = '' } = layoutCell as LayoutImageCell;

                return <OurMemberImage key={`r2-${newId()}`} image={s3Key || src} alt={title} />;
              }

              return renderText('', rowIndex2);
            })}
          </Grid>
        ))}
      </Grid>
    </Grid>
  ));
};

OurPostCardLayout.whyDidYouRender = true;

export default OurPostCardLayout;
