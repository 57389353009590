import { LinkedIn } from '@mui/icons-material';
import OurExternalIcon from '../icons/OurExternalIcon';

interface OurLinkedInIconProps {
  href?: string;
  account?: string;
}

const OurLinkedInIcon = ({ href, account = 'petconsole' }: OurLinkedInIconProps) => (
  <OurExternalIcon icon={<LinkedIn />} href={href || `https://www.linkedin.com/company/${account}`} aria="LinkedIn" />
);

OurLinkedInIcon.whyDidYouRender = true;

export default OurLinkedInIcon;
