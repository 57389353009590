import { EntityRecord, SetBoolean, SetState } from '@petconsole/pure-shared';

interface AfterEntitySaveProps {
  saved: EntityRecord;
  adding?: boolean;
  captureAvatar?: boolean;
  avatarChanged?: boolean;
  setAvatarChanged?: SetState<boolean>;
  avatarSrc?: string;
  setAvatarImage?: SetState<string>;
  setAdding?: SetState<boolean>;
  setEditing?: SetState<boolean>;
  setCanEdit?: SetState<boolean>;
  setSubmitting?: SetState<boolean>;
  setIsSubmitting?: SetBoolean;
  setValues?: SetState<EntityRecord>;
}

const afterEntitySave = ({
  saved,
  adding,
  captureAvatar,
  avatarChanged,
  setAvatarChanged,
  avatarSrc,
  setAvatarImage,
  setAdding,
  setEditing,
  setCanEdit,
  setSubmitting,
  setIsSubmitting,
  setValues,
}: AfterEntitySaveProps) => {
  if (setAvatarImage && (adding || avatarChanged) && captureAvatar) setAvatarImage(avatarSrc as string);
  if (avatarChanged && setAvatarChanged) setAvatarChanged(false);

  if (adding) {
    if (setAdding) setAdding(false);
    if (setCanEdit) setCanEdit(true);
  } else if (setEditing) setEditing(false);

  if (setIsSubmitting) setIsSubmitting(false); // Tell Formik we are done
  if (setSubmitting) setSubmitting(false); // Stop displaying Formik errors
  if (setValues) setValues(saved as EntityRecord);
};

export default afterEntitySave;
