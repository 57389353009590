import { EntityName } from '@petconsole/pure-shared';
import { GetAppState } from '../../store';
import { StateReactionSliver, StateSlices } from '../../types';

interface GetStateSliceReactionsProps {
  getState: GetAppState,
  sliceName: string | EntityName;
}
const getStateSliceReactions = ({ getState, sliceName }: GetStateSliceReactionsProps) => {
  const slices = getState() as StateSlices;
  const sliver = slices[sliceName][sliceName] as StateReactionSliver;

  return sliver.reactions;
};

export default getStateSliceReactions;
