// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, MaybeNull, TimeColumns } from '../../types';

export interface Notification extends TimeColumns {
  type: DataEntityType;
  notificationId: string;
  memberId: string;
  notificationType: string;
  content: string; // HTML
  target: string; // on click on notification, go to this path
}

export const notificationEntity = entityAttributes({
  name: entityName.notification,
  attributes: {
    category: entityCategory.item,
    columns: { images: false, crud: false, time: true },
    loadLimit: 10,
    nameField: undefined,
  },
});

const { id, strim } = schema;
export const notificationSchema = entitySchema<Notification>(notificationEntity, {
  memberId: id(true),
  notificationType: strim(), // constants
  content: strim(), // HTML
  target: strim(), // on click on notification, go to this path
});

export const notificationDefaults: Partial<Notification> = {
  memberId: '',
  notificationType: '',
  content: '',
  target: '',
};

export interface NotificationRecord extends Notification, RecordType {}

export type MaybeNotificationRecord = MaybeNull<NotificationRecord>;
export type NotificationRecords = NotificationRecord[];

export const initialNotification: InitialEntity<Notification, NotificationRecord> = (overrides = {}) =>
  initialEntity<Notification>(notificationEntity, { ...notificationDefaults, ...overrides }) as NotificationRecord;
