import { postApiEntity } from '@petconsole/pure-fe-api';
import fetchParams from '../../../helpers/misc/fetchParams';
import { GetAppState } from '../../../store';

const { api, name: slice } = postApiEntity;

interface FetchPostsBetweenProps {
  sliver: string;
  params: [topicId: string, threadId?: string, startDate?: string, endDate?: string];
  getState: GetAppState;
  scanForward?: boolean;
}

const fetchPostsBetween = async ({ sliver, params, getState, scanForward = true }: FetchPostsBetweenProps) =>
  await api.getListByTopicAndThreadAndFeedBetween(...params, {
    ...fetchParams(getState, slice, sliver),
    scanForward,
  });

export default fetchPostsBetween;
