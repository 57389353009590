import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { OurSlideButtons } from '@petconsole/pure-fe-mui';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { useOurNavigate, useOurSearchGet } from '@petconsole/pure-fe-utils';
import {
  ourCountryA2,
  ourCurrencyCode,
  PclTierPricingObject,
  PclTierRecord,
  PclTierRecords,
} from '@petconsole/pure-shared';
import LighterText from './LighterText';
import PclTier from './PclTier';

interface OnPclTierSubscribeProps {
  pclTierId: string;
  interval: string;
  currency: string;
}

export type OnPclTierSubscribe = (props: OnPclTierSubscribeProps) => void;

interface PclTierPriceTableProps {
  entityName?: string;
  avatar?: string;
  pclTiers: PclTierRecords;
  onSubscribe: OnPclTierSubscribe;
  alignDescription?: boolean;
}

const PclTierPriceTable = ({
  entityName,
  avatar,
  pclTiers,
  onSubscribe,
  alignDescription = true,
}: PclTierPriceTableProps) => {
  const { setParam } = useOurNavigate();
  const { breakpoints } = useTheme();
  const { member } = useCurrentMember();
  const md = useMediaQuery(breakpoints.up('md'));

  const priceObj = pclTiers[0].price as PclTierPricingObject;
  const currencies = Object.keys(priceObj);
  const intervals = currencies.length > 0 ? Object.keys(priceObj[currencies[0]]) : [];
  const defaultCurrency =
    member?.country && member.country !== ourCountryA2.CA && currencies.includes(ourCurrencyCode.USD)
      ? ourCurrencyCode.USD
      : ourCurrencyCode.CAD;
  const defaultInterval = intervals.length > 0 ? intervals[0] : '';
  const { currency = defaultCurrency, interval = defaultInterval } = useOurSearchGet('currency', 'interval');

  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const descriptionIds = useMemo(() => pclTiers.map(({ tier }) => `${tier}-tier-description`), [pclTiers]);
  const hasAnEmptyDescription = !!pclTiers.find(({ description }) => !description);

  useEffect(() => {
    if (descriptionHeight !== 0 && !alignDescription) setDescriptionHeight(0);
  }, [descriptionHeight, alignDescription]);

  useLayoutEffect(() => {
    if (hasAnEmptyDescription) return;

    let maxHeight = 0;

    descriptionIds.forEach((id) => {
      maxHeight = Math.max(maxHeight, document.getElementById(id)?.clientHeight || 0);
    });

    setDescriptionHeight(maxHeight);
  }, [hasAnEmptyDescription, alignDescription, descriptionIds]);

  const onSetCurrency = (right: boolean) => setParam('currency', right ? ourCurrencyCode.USD : ourCurrencyCode.CAD);
  const onSetInterval = (right: boolean) => setParam('interval', right ? intervals[1] : intervals[0]);

  const renderTier = (pclTier: PclTierRecord, highlight: boolean, descriptionId: string) => (
    <PclTier
      key={`tier-${pclTier.tier}`}
      pclTier={pclTier}
      entityName={entityName}
      avatar={avatar}
      descriptionId={descriptionId}
      descriptionHeight={descriptionHeight}
      currency={currency}
      interval={interval}
      onSubscribe={onSubscribe}
      highlight={highlight}
    />
  );

  return (
    <Grid container>
      <Grid container justifyContent="center" paddingBottom={2}>
        <OurSlideButtons
          leftLabel={ourCurrencyCode.CAD.substring(0, 2)}
          rightLabel={ourCurrencyCode.USD.substring(0, 2)}
          right={currency === ourCurrencyCode.USD}
          setRight={onSetCurrency}
        />
      </Grid>
      <Grid container justifyContent="center" paddingBottom={2}>
        <OurSlideButtons
          leftLabel={`${intervals[0]}ly`}
          rightLabel={`${intervals[1]}ly`}
          right={interval === intervals[1]}
          setRight={onSetInterval}
        />
      </Grid>
      <Grid
        container
        {...(md
          ? { flexDirection: 'row', justifyContent: 'center' }
          : { flexDirection: 'column', alignContent: 'center' })}
      >
        {pclTiers.map((pclTier, i) => renderTier(pclTier, i === 1, descriptionIds[i]))}
      </Grid>
      <Grid container justifyContent="center">
        <LighterText text="* Any applicable taxes are extra" />
      </Grid>
    </Grid>
  );
};

PclTierPriceTable.whyDidYouRender = true;

export default PclTierPriceTable;
