import { RecordType } from '@petconsole/pure-base';
import { ApiEntity } from '@petconsole/pure-shared';
import { ApiUseUpdateMutation } from '../../types';

interface UseUpdateEntityProps<T extends RecordType = RecordType> {
  apiEntity: ApiEntity<T>;
  mutationHook: ApiUseUpdateMutation<T>;
}

const useUpdateEntity = <T extends RecordType = RecordType, R = RecordType>({
  apiEntity,
  mutationHook,
}: UseUpdateEntityProps<T>) => {
  // Note: This separation is primarily to facilitate easier testing and mocking
  const [updateEntityTrigger, mutationResults] = mutationHook();

  const updateEntity = (id: string, data: T) => updateEntityTrigger({ id, body: data }).unwrap() as Promise<T>;

  return { [`update${apiEntity.proper}`]: updateEntity, ...mutationResults } as typeof mutationResults & R;
};

export default useUpdateEntity;
