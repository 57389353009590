import { get } from 'aws-amplify/api';
import { GetResult } from '@petconsole/pure-shared';
import { restApiName } from '../constants';
import { GetInput } from '../types';
import restCatch from './restCatch';

export interface RestGetInput extends Omit<GetInput, 'apiName'> {
  apiName?: string;
}

export type RestGet = (props: RestGetInput) => Promise<GetResult>;

export const attemptGet = ({ apiName, path, options }: GetInput) => get({ apiName, path, options });

const restGet: RestGet = async ({ apiName = restApiName, path, options }) => {
  try {
    const result = attemptGet({ apiName, path, options });
    const response = await result.response;
    const body = response.body;

    if (response.headers['content-type'] === 'application/json') return (body.json as () => Promise<GetResult>)();

    // return body.text();
  } catch (error) {
    restCatch('restGet', error);
  }

  throw new Error(`restGet received unexpected results from ${path}`);
};

export default restGet;
