import { RecordType } from '@petconsole/pure-base';
import { busBuilderApiEntity } from '@petconsole/pure-fe-api';
import { BusBuilderRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = BusBuilderRecord;

const apiEntity = busBuilderApiEntity;
const { name: busBuilder, plural: busBuilders } = apiEntity;
const busBuilderAdmin = `${busBuilder}Admin`;

const Tab = { busBuilderAdmin, busBuilder, busBuilders } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  busBuilderAdmin: { default: '' },
  busBuilder: { default: '' },
  busBuilders: { default: 'By City' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, citySearchHooks, propertyHooks, ratingHooks, tabHooks } = sliceHookTypes;

export interface OurBusBuilderHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  busBuilderHooks: RequiredCrudSliverHooks;
  cityBusBuilderHooks: RequiredSliverHooks;
  newestBusBuilderHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourBusBuilderHooks: OurBusBuilderHooks = {
  entity: apiEntity,
  busBuilderHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityBusBuilderHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestBusBuilderHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  ratingHooks: ratingHooks(apiEntity, hooks),
  tabHooks: tabHooks(Object.keys(Tab), hooks) as TabHooks,
};

export default reducer;
