import { Edit } from '@mui/icons-material';
import { Icon } from '@petconsole/pure-shared';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

export interface OurEditButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  tip?: string;
  Icon?: Icon;
}

const OurEditButton = ({ tip = 'Edit', Icon = Edit, ...rest }: OurEditButtonProps) => (
  <OurIconButton {...rest} tip={tip} Icon={Icon} />
);

OurEditButton.whyDidYouRender = true;

export default OurEditButton;
