// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { site } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import {
  AddressColumns,
  ContactColumns,
  DataEntityType,
  DefaultColumns,
  InitialEntity,
  RatingColumns,
} from '../../types';
import { schema } from '../../entity/schema';

export interface DogPark extends AddressColumns, ContactColumns, DefaultColumns, RatingColumns {
  type: DataEntityType;
  dogParkId: string;
  parkName: string;
  description: string;
  designated: boolean;
  free: boolean;
  offLeash: boolean;
  fenced: boolean;
  parking: boolean;
  washrooms: boolean;
  smallDogArea: boolean;
  kids: boolean;
  generalNotes: string;
  petVaccination: string;
  covidPolicy: string;
  petLimits: string;
  membership: string;
  hours: string;
  rates: string;
  rules: string;
  surfaceType: string;
  maintenance: string;
  detailNotes: string;
  wasteBags: boolean;
  wasteBins: boolean;
  water: boolean;
  shade: boolean;
  seating: boolean;
  fountain: boolean;
  pool: boolean;
  washStation: boolean;
  indoor: boolean;
  beverages: boolean;
  equipment: string;
  dayCare: string;
  grooming: string;
  training: string;
  petTaxi: string;
  featureNotes: string;
  doubleGates: boolean;
  multipleEntries: boolean;
  cornerFree: boolean;
  lighting: boolean;
  video: boolean;
  staffed: boolean;
  firstAid: boolean;
  safetyNotes: string;
}

const { General, Details, Features, Safety, Photos, Comments, Preview } = entityTabName;
export const dogParkEntity = entityAttributes({
  name: entityName.dogPark,
  attributes: {
    category: entityCategory.place,
    columns: { address: true, contact: true, rating: true },
    loadLimit: 20,
    maxImages: 12,
    nameField: 'parkName',
    site: site.petConsole,
    shared: true,
    tabs: [General, Details, Features, Safety, Photos, Comments, Preview],
  },
});

const { boolean, strim } = schema;
export const dogParkSchema = entitySchema<DogPark>(dogParkEntity, {
  parkName: strim().required('Park name is required'),
  description: strim(), // multi-line, free-text
  designated: boolean(true),
  free: boolean(true),
  offLeash: boolean(true),
  fenced: boolean(true),
  parking: boolean(true),
  washrooms: boolean(),
  smallDogArea: boolean(),
  kids: boolean(true),
  generalNotes: strim(),
  petVaccination: strim(),
  covidPolicy: strim(),
  petLimits: strim(),
  membership: strim(),
  hours: strim(), // multi-line, free-text
  rates: strim(), // multi-line, free-text
  rules: strim(), // multi-line, free-text
  surfaceType: strim(), // grass, dirt
  maintenance: strim(), // multi-line, free-text,
  detailNotes: strim(),
  wasteBags: boolean(true),
  wasteBins: boolean(true),
  water: boolean(true),
  shade: boolean(true),
  seating: boolean(true),
  fountain: boolean(),
  pool: boolean(),
  washStation: boolean(),
  indoor: boolean(),
  beverages: boolean(),
  equipment: strim(), // multi-line, free-text, obstacles, etc.
  dayCare: strim(),
  grooming: strim(),
  training: strim(),
  petTaxi: strim(),
  featureNotes: strim(),
  doubleGates: boolean(true),
  multipleEntries: boolean(true),
  cornerFree: boolean(),
  lighting: boolean(true),
  video: boolean(),
  staffed: boolean(),
  firstAid: boolean(),
  safetyNotes: strim(),
});

export const dogParkDefaults: Partial<DogPark> = {
  parkName: '',
  description: '',
  designated: true,
  free: true,
  offLeash: true,
  fenced: true,
  parking: true,
  washrooms: false,
  smallDogArea: false,
  kids: true,
  generalNotes: '',
  petVaccination: '',
  covidPolicy: '',
  petLimits: '',
  membership: '',
  hours: '',
  rates: '',
  rules: '',
  surfaceType: '',
  maintenance: '',
  detailNotes: '',
  wasteBags: true,
  wasteBins: true,
  water: true,
  shade: true,
  seating: true,
  fountain: false,
  pool: false,
  washStation: false,
  indoor: false,
  beverages: false,
  equipment: '',
  dayCare: '',
  grooming: '',
  training: '',
  petTaxi: '',
  featureNotes: '',
  doubleGates: true,
  multipleEntries: true,
  cornerFree: false,
  lighting: true,
  video: false,
  staffed: false,
  firstAid: false,
  safetyNotes: '',
};

export interface DogParkRecord extends DogPark, RecordType {}

export type MaybeDogParkRecord = MaybeNull<DogParkRecord>;
export type DogParkRecords = DogParkRecord[];

export const initialDogPark: InitialEntity<DogPark, DogParkRecord> = (overrides = {}) =>
  initialEntity<DogPark>(dogParkEntity, { ...dogParkDefaults, ...overrides }) as DogParkRecord;
