import { ElementType } from 'react';
import { Container, useTheme } from '@mui/material';
import { ourAppBorderWidth } from '../../../constants';

interface OurContainedRoutesProps {
  Routes: ElementType;
}

const OurContainedRoutes = ({ Routes }: OurContainedRoutesProps) => {
  const theme = useTheme();
  const border = `solid ${ourAppBorderWidth} ${theme.palette.secondary.main}`;

  return (
    <Container sx={{ borderTop: border, borderBottom: border }} maxWidth={false} disableGutters>
      <Routes />
    </Container>
  );
};

OurContainedRoutes.whyDidYouRender = true;

export default OurContainedRoutes;
