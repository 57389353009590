import { ReactElement } from 'react';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurToolbarButtonProps extends OurIconButtonProps {
  mode: boolean;
  button?: ReactElement;
}

const OurToolbarButton = ({ mode, onClick, disabled, button, size = 'small', ...rest }: OurToolbarButtonProps) => {
  if (!mode) return null;

  if (button) return button;

  if (!(onClick || disabled)) return null;

  return <OurIconButton {...rest} disabled={disabled || !onClick} onClick={onClick} size={size} />;
};

OurToolbarButton.whyDidYouRender = true;

export default OurToolbarButton;
