import { getCurrentUser } from 'aws-amplify/auth';

export const attemptGetCurrentUser = () => getCurrentUser();

const authGetCurrentUser = async () => {
  try {
    const result = await attemptGetCurrentUser();

    return { success: true, user: result, email: result.signInDetails?.loginId };
  } catch (e) {
    if (!(e instanceof Error)) {
      // TODO: Log to rollbar
      return { success: false, reason: 'Unknown error' };
    }

    return { success: false, reason: e.message };
  }
};

export default authGetCurrentUser;
