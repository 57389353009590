import { PropsWithChildren } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider, Drawer as MuiDrawer, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SetBoolean } from '@petconsole/pure-shared';
import { myShopAppBarHeight, myShopDrawerWidth } from '../constants';

// Note: Attempts to use the previous open/closed mixins were giving my typing errors. For now, I've chosen to live with
// the duplicate settings for the drawer and drawer paper
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => {
    const { enteringScreen, leavingScreen } = theme.transitions.duration;
    const duration = open ? enteringScreen : leavingScreen;
    const width = open ? myShopDrawerWidth : `calc(${theme.spacing(7)} + 1px)`;

    return {
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      // Following setting are the same for the drawer paper
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration,
      }),
      width,
      overflowX: 'hidden',
      marginTop: myShopAppBarHeight,
      // Drawer paper settings are the same as the above ones for the drawer
      '& .MuiDrawer-paper': {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration,
        }),
        width,
        overflowX: 'hidden',
        marginTop: myShopAppBarHeight,
      },
    };
  },
);

const DrawerHeader = styled('div')(({ theme }) => ({
  height: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
}));

interface MyShopDrawerProps {
  open: boolean;
  setOpen: SetBoolean;
}

const MyShopDrawer = ({ open, setOpen, children }: PropsWithChildren<MyShopDrawerProps>) => {
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={open}
      sx={{ position: 'fixed', top: 0, right: 0 }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {children}
      <Divider />
    </Drawer>
  );
};

export default MyShopDrawer;
