import { forwardRef, useEffect } from 'react';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import OurText, { OurTextProps } from '../inputs/OurText';
import './OurGoogleInputClass.scss';

// NOTE: This extra file is necessary to prevent re-renders and losing input focus
const OurGoogleInput = (props: OurTextProps, ref: OurTextProps['inputRef']) => {
  const darkMode = useOurDarkMode();
  const darkClassName = 'our-pac-dark-mode';
  const lightClassName = 'our-pac-light-mode';

  useEffect(() => {
    document.body.classList.add(darkMode ? darkClassName : lightClassName);
    document.body.classList.remove(darkMode ? lightClassName : darkClassName);
  }, [darkMode]);

  return <OurText {...props} inputRef={ref} />;
};

export default forwardRef(OurGoogleInput);
