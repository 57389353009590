import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { RequiredCrudSliverHooks, RequiredUrlOrIdHooks } from '@petconsole/pure-fe-state';
import { useOurSafeEffect, UseOurSafeEffectProps } from '@petconsole/pure-fe-utils';
import { EntityRecord, entityType, WebPath, webPath } from '@petconsole/pure-shared';
import { UseMaintReadProps } from '../types';

const useMaintRead = <T extends RecordType = RecordType>({
  entity,
  hooks,
  idParam,
  stateIds,
}: UseMaintReadProps<T>) => {
  const { [idParam || 'id']: idOrUrl = '' } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [id, setId] = useState('');
  const [lastIdOrUrl, setLastIdOrUrl] = useState('');

  const { idName, name: entityName, plural, type } = entity;
  const path = webPath[plural as WebPath];

  const readHooks =
    (hooks.urlOrIdHooks as RequiredUrlOrIdHooks) || (hooks[`${entityName}Hooks`] as RequiredCrudSliverHooks);

  const { useFetch, useSelect } = readHooks;
  const data = useSelect(id) as MaybeNull<T>;
  const fetch = useFetch() as (urlOrId: string) => Promise<PayloadAction<MaybeNull<T>>>;

  const ids = useMemo(() => (stateIds ? stateIds : state?.ids ? state?.ids : []) as string[], [stateIds, state]);
  const idIndex = ids.indexOf(id);
  const prevId = idIndex > 0 ? ids[idIndex - 1] : '';
  const nextId = idIndex >= 0 && idIndex < ids.length ? ids[idIndex + 1] : '';

  const onPrev = useCallback(() => navigate(`${path}/${prevId}`, { state: { ids } }), [navigate, prevId, ids, path]);
  const onNext = useCallback(() => navigate(`${path}/${nextId}`, { state: { ids } }), [navigate, nextId, ids, path]);

  useOurSafeEffect({
    action: fetch as UseOurSafeEffectProps['action'],
    args: [idOrUrl],
    process: (result) => {
      if (!result) return;

      setId((result as EntityRecord)[idName] as string);
      setLastIdOrUrl(idOrUrl);
    },
    skip: (!!data && idOrUrl === lastIdOrUrl) || (!idOrUrl && type === entityType.MailMessage),
    unwrap: unwrapResult as UseOurSafeEffectProps['unwrap'],
  });

  return { data, id, prevId, nextId, reRead: () => fetch(id), ...(ids.length > 0 && { onPrev, onNext }) };
};

export default useMaintRead;
