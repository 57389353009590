import { Grid, GridProps, Typography } from '@mui/material';
import { Address } from '@petconsole/pure-shared';

interface OurDisplayAddressProps extends GridProps {
  address: Address;
}

const OurDisplayAddress = ({ address, ...rest }: OurDisplayAddressProps) => {
  const { address1, address2, city, province, postal, country } = address;

  return (
    <Grid item xs={12} {...rest}>
      <Typography>{address1}</Typography>
      <Typography>{address2}</Typography>
      <Typography>{[city, province].filter((x) => !!x).join(', ')}</Typography>
      <Typography>{[country, postal].filter((x) => !!x).join(', ')}</Typography>
    </Grid>
  );
};

OurDisplayAddress.whyDidYouRender = true;

export default OurDisplayAddress;
