import { RecordType, properCase } from '@petconsole/pure-base';
import { BaseSliceSliverOption, Slice, SliceSliver } from '../../types';

const sliceAddSlivers = <T extends RecordType = RecordType>(slice: Slice<T>, customSlivers?: BaseSliceSliverOption<T>[]) => {
  const { flag } = slice;
  const { hasCitySliver, hasMySliver, hasNewestSliver, hasOldestSliver } = flag;
  const slivers: string[] = [];
  const sliver: Record<string, SliceSliver> = {};

  const addSliver = (prefix = '', options: BaseSliceSliverOption<T> = {} as BaseSliceSliverOption<T>) => {
    const {
      extraState,
      comparer,
      fetchById,
      fetchByValue,
      fetchesById,
      fetchesByValue,
      payloadCreator,
      payloadCreators,
      extraReducers,
      sliceReducers,
    } = options;

    const name = prefix ? `${prefix}${slice.proper}` : slice.name;
    const plural = prefix ? `${prefix}${slice.pluralProper}` : slice.plural;

    const tempSliver: RecordType = {
      name,
      plural,
      proper: properCase(name),
      pluralProper: properCase(plural),
      defaultState: { limit: slice.api.limit },
      comparer: comparer || false,
      fetchById: true,
      fetchByValue: false,
      fetchesById: false,
      fetchesByValue: false,
    };

    if (extraState)
      tempSliver.defaultState = {
        ...(<RecordType>tempSliver.defaultState),
        ...(<RecordType>extraState),
      };
    if (fetchById !== undefined) tempSliver.fetchById = fetchById;
    if (fetchByValue !== undefined) tempSliver.fetchByValue = fetchByValue;
    if (fetchesById !== undefined) tempSliver.fetchesById = fetchesById;
    if (fetchesByValue !== undefined) tempSliver.fetchesByValue = fetchesByValue;
    if (payloadCreator !== undefined) tempSliver.payloadCreator = payloadCreator;
    if (payloadCreators !== undefined) tempSliver.payloadCreators = payloadCreators;
    if (extraReducers) tempSliver.extraReducers = extraReducers;
    if (sliceReducers) tempSliver.sliceReducers = sliceReducers;

    if (sliver[name]) sliver[name] = { ...sliver[name], ...tempSliver };
    else {
      slivers.push(name);
      // We know we don't have all the properties yet, so we assert the type here
      sliver[name] = <SliceSliver>(<unknown>tempSliver);
    }
  };

  const findCustomSliver = (prefix: string) =>
    customSlivers
      ? customSlivers.find((sliver: BaseSliceSliverOption<T>) => sliver.prefix === prefix)
      : undefined;

  // we always need an entity sliver, don't we?
  addSliver('', findCustomSliver(slice.name));

  // If we have a city sliver, make sure it is in position 1 in the slivers array
  if (hasCitySliver) addSliver('city', findCustomSliver('city)'));
  if (hasMySliver) addSliver('my', findCustomSliver('my)'));
  if (hasNewestSliver) addSliver('newest', findCustomSliver('newest)'));
  if (hasOldestSliver) addSliver('oldest', findCustomSliver('oldest)'));

  if (customSlivers) {
    const filteredSlivers = customSlivers.filter(
      ({ prefix }: BaseSliceSliverOption<T>) =>
        prefix !== slice.name &&
        (prefix !== 'city' || !hasCitySliver) &&
        (prefix !== 'city' || !hasCitySliver) &&
        (prefix !== 'my' || !hasMySliver) &&
        (prefix !== 'newest' || !hasNewestSliver) &&
        (prefix !== 'oldest' || !hasOldestSliver),
    );

    filteredSlivers.forEach((sliver: BaseSliceSliverOption<T>) => {
      const { prefix } = sliver;

      if (prefix) addSliver(prefix, sliver);
      else throw new Error('Custom sliver is missing prefix');
    });
  }

  return { sliver, slivers };
};

export default sliceAddSlivers;
