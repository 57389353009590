import { useCallback } from 'react';
import useOurError from '../../error/useOurError';
import useDoWork from '../../misc/doWork/useDoWork';
import saveEntity, { SaveEntityProps } from './saveEntity';
import useSaveEntityCatch from './useSaveEntityCatch';
import { EntityAttributes } from '@petconsole/pure-shared';

interface UseOurSaveEntityProps {
  entity: EntityAttributes;
}

const useOurSaveEntity = ({ entity }: UseOurSaveEntityProps) => {
  const { doWork } = useDoWork();
  const { log } = useOurError();
  const { saveEntityCatch } = useSaveEntityCatch();

  const ourSaveEntity = useCallback(
    (props: Partial<SaveEntityProps>) =>
      saveEntity({ entity, ...props, doWork, log, saveEntityCatch } as SaveEntityProps),
    [doWork, entity, log, saveEntityCatch],
  );

  return { ourSaveEntity };
};

export default useOurSaveEntity;
