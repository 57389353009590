import { ElementType } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { newId } from '@petconsole/pure-base';
import { webPath } from '@petconsole/pure-shared';
import useOurAuthenticated from '../../../auth/useOurAuthenticated';

const useOurRoutes = () => {
  const { authenticated } = useOurAuthenticated();
  const { pathname, search } = useLocation();
  const { home, logout } = webPath;

  const routeTo = (path: string, Element: ElementType) => <Route key={newId()} path={path} element={<Element as ReactElement />} />;
  const authed = (path: string) => <Route key={newId()} path={path} element={<Navigate to={home} replace />} />;
  const login = (path: string) => (
    <Route
      key={newId()}
      path={path}
      element={
        <Navigate to={pathname === logout ? home : `/?redirect=${pathname}${search}`} replace />
      }
    />
  );

  const authRoute = (path: string, Element: ElementType) => (authenticated ? routeTo(path, Element) : login(path));
  const unauthRoute = (path: string, Element: ElementType) => (!authenticated ? routeTo(path, Element) : authed(path));

  return { authRoute, unauthRoute, routeTo };
};

export default useOurRoutes;
