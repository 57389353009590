import reactEnvVar from '../reactEnvVar';
import { ResourcesConfig } from 'aws-amplify';
import { OAuthConfig } from '@aws-amplify/core';

const apiEndpointName = 'web-api';
const defaultIndexName = 'None';
const {
  apiGatewayRegion,
  apiGatewayWebApi,
  cognitoAppClientId,
  cognitoDomain,
  cognitoIdentityPoolId,
  cognitoResponseType,
  cognitoRedirectSignIn,
  cognitoRedirectSignOut,
  // cognitoRegion,
  cognitoScope,
  cognitoUserPoolId,
  geoMapName = 'geoMapName',
  geoMapStyle,
  geoMapRegion,
  s3Bucket,
  s3Region,
} = reactEnvVar;

const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      // mandatorySignIn: false,
      // region: cognitoRegion,
      userPoolId: cognitoUserPoolId,
      userPoolClientId: cognitoAppClientId,
      identityPoolId: cognitoIdentityPoolId,
      allowGuestAccess: true,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: cognitoDomain,
          scopes: cognitoScope.split(' '),
          redirectSignIn: [cognitoRedirectSignIn],
          redirectSignOut: [cognitoRedirectSignOut],
          responseType: cognitoResponseType as OAuthConfig['responseType'],
        },
      },
    },
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   // domain: '.pcl-dev.com',
    //   domain: cognitoCookieDomain,
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 1,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   // sameSite: 'strict' | 'lax',
    //   sameSite: 'lax',
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },
  },
  Geo: {
    LocationService: {
      maps: {
        items: {
          [`${geoMapName}`]: {
            // REQUIRED - Amazon Location Service Map resource name
            style: geoMapStyle, // REQUIRED - String representing the style of map resource
          },
        },
        default: geoMapName, // REQUIRED - Amazon Location Service Map resource name to set as default
      },
      searchIndices: {
        items: [defaultIndexName], // REQUIRED - Amazon Location Service Place Index name
        default: defaultIndexName, // REQUIRED - Amazon Location Service Place Index name to set as default
      },
      region: geoMapRegion, // REQUIRED - Amazon Location Service Region
    },
  },
  Storage: { S3: { region: s3Region, bucket: s3Bucket } },
  API: { REST: { [apiEndpointName]: { endpoint: apiGatewayWebApi, region: apiGatewayRegion } } },
};

export default amplifyConfig;
