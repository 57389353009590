import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, MenuItem } from '@mui/material';
import { SetState } from '@petconsole/pure-shared';
import { MemberAvatarMenuItem } from '../../../types';

interface MemberMenuItemProps {
  menuItem: MemberAvatarMenuItem;
  setMenuOpen: SetState<boolean>;
}

const MemberMenuItem = ({ menuItem, setMenuOpen }: MemberMenuItemProps) => {
  const navigate = useNavigate();

  const [text, path, divider = false, count = null] = menuItem;

  const handlePathClick = (event: MouseEvent) => {
    event.preventDefault();
    setMenuOpen(false);
    navigate(path);
  };

  if (typeof count !== 'number')
    return (
      <MenuItem divider={divider} onClick={handlePathClick}>
        {text}
      </MenuItem>
    );

  return (
    <MenuItem divider={divider} onClick={handlePathClick}>
      <Badge
        sx={{ '& .MuiBadge-badge': { right: -24, top: 6 } }}
        badgeContent={count}
        color="secondary"
        showZero={false}
      >
        {text}
      </Badge>
    </MenuItem>
  );
};

MemberMenuItem.whyDidYouRender = true;

export default MemberMenuItem;
