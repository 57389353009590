import { PayloadAction } from '@reduxjs/toolkit';
import { RecordType, RecordTypes } from '@petconsole/pure-base';
import { ourPutImages, shopItemVariantCodeApiEntity } from '@petconsole/pure-fe-api';
import { Image, ImageLog, Images } from '@petconsole/pure-shared';
import { ourSaveStatus } from '../../../constants';
import { writeReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick, StateSliver } from '../../../types';

const { api, name: sliceName, proper } = shopItemVariantCodeApiEntity;
const typePrefix = `${sliceName}/save${proper}s`;

interface saveShopItemVariantCodesPayloadCreatorProps {
  shopItemId: string;
  variantCodes: RecordTypes;
  log: ImageLog;
}

export const saveShopItemVariantCodesPayloadCreator = async ({
  shopItemId,
  variantCodes,
  log,
}: saveShopItemVariantCodesPayloadCreatorProps) => {
  const images: Images = [];

  // Make a copy of the variantCodes without the thumbnail image data, and a list of changed images
  const tempVariantCodes = variantCodes.map((variantCode) => {
    // Exclude src from the thumbnail
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { src, changed, ...thumbnail } = (variantCode.thumbnail || {}) as Image;

    if (changed) images.push(variantCode.thumbnail as Image);

    return { ...variantCode, thumbnail };
  });

  // Save the variantCodes and changed images
  const [newVariantCodes] = await Promise.all([
    api.upsertList(shopItemId, tempVariantCodes),
    ourPutImages({ images, log }),
  ]);

  // Re-apply the previous image data where it is still available
  newVariantCodes.forEach((newVariantCode: RecordType) => {
    const { shopItemVariantCodeId: id, thumbnail: newThumbnail } = newVariantCode;

    if (newThumbnail) {
      const found = variantCodes.find((variantCode) => variantCode.shopItemVariantCodeId === id);
      const oldThumbnail = found?.thumbnail as Image;

      if ((newThumbnail as Image).s3Key === oldThumbnail?.s3Key) (newThumbnail as Image).src = oldThumbnail.src;
    }
  });

  return newVariantCodes;
};

export const saveShopItemVariantCodesThunk = appThunk(typePrefix, saveShopItemVariantCodesPayloadCreator);

export const saveShopItemVariantCodesReducers = (adapter: EntityAdapterWithPick) => {
  const reducers = writeReducers(saveShopItemVariantCodesThunk, sliceName, adapter);

  const fulfilled = (state: StateSliver, action: PayloadAction<RecordTypes>) => {
    const errors = action.payload.filter((item: RecordType) => item.error);

    if (errors.length === 0) {
      state.writeStatus = ourSaveStatus.succeeded;

      adapter.setAll(state, action.payload);

      return;
    }

    // const successes = action.payload.filter((item: RecordType) => !item.error);

    state.writeStatus = ourSaveStatus.failed;
    state.writeError = errors.map((error: RecordType) => error.message).join('/n');

    throw new Error(`saveShopItemVariantCodes error: ${state.writeError}`);

    // adapter.setAll(<EntityState<RecordType>>state, successes);
  };

  return { ...reducers, [`${typePrefix}/fulfilled`]: fulfilled };
};
