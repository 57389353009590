import { MouseEvent, MouseEventHandler, useState } from 'react';
import OurConfirmDeleteDialog from '../components/dialogs/OurConfirmDeleteDialog';

interface ConfirmDeleteProps {
  text?: string;
  onConfirm: MouseEventHandler;
}

const useOurConfirmDeleteDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onCancel = (event: MouseEvent) => {
    event.preventDefault();
    setIsOpen(false);
  };

  const onConfirmed = (event: MouseEvent, onConfirm: MouseEventHandler) => {
    event.preventDefault();
    setIsOpen(false);
    onConfirm(event);
  };

  const ConfirmDelete = ({ text = 'Delete this?', onConfirm }: ConfirmDeleteProps) => {
    if (!isOpen) return null;

    return (
      <OurConfirmDeleteDialog
        text={text}
        isOpen={isOpen}
        onCancel={onCancel}
        onConfirm={(e) => onConfirmed(e, onConfirm)}
      />
    );
  };

  return { ConfirmDelete, setConfirmDeleteOpen: setIsOpen };
};

export default useOurConfirmDeleteDialog;
