import { useCallback, useContext } from 'react';
import { MaybeRedirectState } from '@petconsole/pure-shared';
import { ourRedirectContext, ourSetRedirectActionType } from './OurRedirectContext';

const useOurRedirect = () => {
  const { state, dispatch } = useContext(ourRedirectContext);

  const setRedirect = useCallback(
    async (redirect: MaybeRedirectState) => dispatch({ type: ourSetRedirectActionType, payload: { redirect } }),
    [dispatch],
  );

  return { redirect: state.redirect, setRedirect };
};

export default useOurRedirect;
