import { JSX, memo, MemoExoticComponent, MouseEvent, useState } from 'react';
import { useOurNavigate } from '@petconsole/pure-fe-utils';
import { EntityAttributes, EntityRecord, webPath } from '@petconsole/pure-shared';
import { default as ourGetEntityTitle } from '../../../entity/helpers/ourGetEntityTitle';
import IsThisYours from './IsThisYours';
import SubscribeDialog, { ClickPricingProps } from './SubscribeDialog';

export interface IsYoursProps<T extends EntityRecord = EntityRecord> {
  entityRecord: T;
  disabled?: boolean;
}

export type IsYoursType<T extends EntityRecord = EntityRecord> = MemoExoticComponent<(props: IsYoursProps<T>) => JSX.Element>;

interface UseIsThisYoursProps {
  entity: EntityAttributes;
}

const useIsThisYours = <T extends EntityRecord = EntityRecord>({ entity }: UseIsThisYoursProps) => {
  const { navigate, pathname } = useOurNavigate();

  const { type: entityType, idName, name: entityName, nameField } = entity;

  // Subscribe dialog will get opened when there are pricing props
  const [clickPricingProps, setClickPricingProps] = useState(undefined as ClickPricingProps | undefined);

  // Set the pricing props to trigger the subscribe dialog
  const onClickYours = (event: MouseEvent, entityRecord: T) => {
    event.preventDefault();

    const entityId = entityRecord[idName] as string;
    const businessName = ourGetEntityTitle<T>({ entityRecord, nameField });

    setClickPricingProps({ entityId, businessName });
  };

  // Get the pricing props and use them to direct to the pricing page
  const onClickPricing = (event: MouseEvent) => {
    event.preventDefault();

    const { entityId, businessName } = clickPricingProps as ClickPricingProps;

    // TODO: Should include default currency and interval
    const params = new URLSearchParams({
      item: entityName,
      type: entityType,
      id: entityId,
      name: businessName,
      source: pathname,
    });

    navigate({ pathname: webPath.pclTierPrices, search: params.toString() });
  };

  // Memoized version only needs the current entity and can be used on each entity (ie: a card or maintenance screen)
  const MemoizedIsYours = memo(({ entityRecord, disabled }: IsYoursProps<T>) => {
    const onClick = (event: MouseEvent) => onClickYours(event, entityRecord);

    return <IsThisYours entity={entity} onClick={onClick} disabled={disabled} />;
  });

  // Memoized version is self-contained and can be used on a listing page or maintenance page
  const MemoizedSubscribeDialog = memo(() => {
    return (
      <SubscribeDialog
        onClickPricing={onClickPricing}
        clickPricingProps={clickPricingProps}
        setClickPricingProps={setClickPricingProps}
      />
    );
  });

  return { IsYours: MemoizedIsYours, Subscribe: MemoizedSubscribeDialog };
};

export default useIsThisYours;
