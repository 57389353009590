// noinspection ES6PreferShortImport

import OurTypoTextItem from '../components/misc/OurTypoTextItem';
import { MaintPhotosProps } from './types';

const MaintPhotos = ({ entity, images, Photos }: MaintPhotosProps) => {
  const heading = `${images?.length ?? 0} of ${entity.maxImages} photos`;

  return (
    <>
      <OurTypoTextItem center text={heading} />
      {Photos && <Photos />}
    </>
  );
};

MaintPhotos.whyDidYouRender = true;

export default MaintPhotos;
