import { RecordType } from '@petconsole/pure-base';
import { dogParkApiEntity } from '@petconsole/pure-fe-api';
import { DogParkRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = DogParkRecord;

const apiEntity = dogParkApiEntity;
const { name: sliceName, plural } = apiEntity;
const dogPark = sliceName;
const dogParks = plural;
const dogParkAdmin = `${sliceName}Admin`;

const Tab = { dogParkAdmin, dogPark, dogParks } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  dogParkAdmin: { default: '' },
  dogPark: { default: '' },
  dogParks: { default: '' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  sortProperty: 'parkName',
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, ratingHooks, tabHooks } = sliceHookTypes;

export interface OurDogParkHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  dogParkHooks: RequiredCrudSliverHooks;
  cityDogParkHooks: RequiredSliverHooks;
  newestDogParkHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourDogParkHooks: OurDogParkHooks = {
  entity: apiEntity,
  dogParkHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityDogParkHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestDogParkHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  ratingHooks: ratingHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
