import { RecordType } from '@petconsole/pure-base';
import { blogApiEntity } from '@petconsole/pure-fe-api';
import { BlogRecord } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../../../types';
import { createOne, getOne, updateOne } from '../../../helpers';

type ApiRecord = BlogRecord;

interface BlogEndpointsProps<T extends RecordType = ApiRecord> {
  builder: ApiBuilder<T>;
}

const blogEndpoints = ({ builder }: BlogEndpointsProps) => {
  const { api, proper: tag } = blogApiEntity;

  return {
    createBlog: createOne(builder, api, tag),
    getBlog: getOne(builder, api, tag),
    // getBlogsByShop: getListBy(builder, api.getListByShop, tag),
    updateBlog: updateOne(builder, api.update, tag),
  };
};

export default blogEndpoints;
