import { EditMessage, EditMessages, editMessageType } from '@petconsole/pure-shared';

const sortByEditMessageType = (messages: EditMessages) => {
  const { error, hint, info, tip, warning } = editMessageType;
  const sortOrder = [error, warning, tip, hint, info];

  const sorted = messages.slice(0);

  sorted.sort(({ type: typeA }: EditMessage, { type: typeB }: EditMessage)=> {
    if (typeA === typeB) return 0;

    return sortOrder.indexOf(typeA) - sortOrder.indexOf(typeB); // lowest value = highest priority
  })

  return sorted;
};

export default sortByEditMessageType;