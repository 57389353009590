import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  ApiEntity,
  MaybeStripeAccountRecord,
  stripeAccountEntity,
  StripeAccountRecord,
} from '@petconsole/pure-shared';
import { commonApi, get, update } from './common';

type ApiRecord = StripeAccountRecord;

const apiPath = '/stripe/stripeAccounts';

interface CustomApi extends RecordType {
  getByShop: (shopId: string) => Promise<MaybeStripeAccountRecord>;
  updateStripeStatus: (stripeId: string) => Promise<ApiRecord>;
}

export type StripeAccountApi = Api<ApiRecord> & CustomApi;

export const stripeAccountApi: StripeAccountApi = {
  ...commonApi<ApiRecord>({ apiPath }),
  getByShop: (shopId) => get(`${apiPath}/shop/${shopId}`) as Promise<MaybeStripeAccountRecord>,
  updateStripeStatus: (stripeId) =>
    update(`${apiPath}/updateStripeStatus/${stripeId}`, { stripeId }) as Promise<StripeAccountRecord>,
};

export const stripeAccountApiEntity: ApiEntity<ApiRecord, CustomApi> = {
  ...stripeAccountEntity,
  api: stripeAccountApi,
};
