// noinspection ES6PreferShortImport

import { Dispatch, useCallback, useContext, useEffect } from 'react';
import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { getLocalItem, setLocalItem } from '@petconsole/pure-fe-amplify';
import { ourDefaultDarkModeItemKey } from '../constants';
import { ourDarkModeActionType, ourDarkModeContext } from '../context/OurDarkModeContext';

interface UseOurManageDarkModeProps {
  memberId?: string;
  canInitialize?: boolean;
}

const useOurManageDarkMode = ({ memberId, canInitialize }: UseOurManageDarkModeProps = {}) => {
  const context = useContext(ourDarkModeContext);
  const state = context.state as RecordType;
  const dispatch = context.dispatch as Dispatch<{ type: string; payload: RecordType }>;
  const darkMode = state.darkMode as MaybeNull<boolean>;

  // Member settings have priority, with browser setting as fallback
  const currentKey = memberId ? `${memberId}:darkMode` : ourDefaultDarkModeItemKey;

  const dispatchDarkMode = useCallback(
    (itemKey: string, newDarkMode: boolean) => {
      // Update dark mode context to give visibility for theme setting
      dispatch({ type: ourDarkModeActionType, payload: { itemKey, darkMode: newDarkMode } });
    },
    [dispatch],
  );

  const setDarkMode = useCallback(
    (key: string, isDark: boolean) => {
      dispatchDarkMode(key, isDark);

      const expiration = new Date(2199, 1, 1);

      setLocalItem(key, isDark, { expires: expiration.getTime() });

      // If we set the member key, set the default key to the same value
      if (key !== ourDefaultDarkModeItemKey)
        setLocalItem(ourDefaultDarkModeItemKey, isDark, { expires: expiration.getTime() });
    },
    [dispatchDarkMode],
  );

  const initializeDarkMode = useCallback(async () => {
    let isDark = await getLocalItem(currentKey);

    if (isDark === null) {
      isDark = false;

      // Update local storage for next time
      setDarkMode(currentKey, isDark);
    } else dispatchDarkMode(currentKey, isDark);

    return isDark;
  }, [currentKey, setDarkMode, dispatchDarkMode]);

  // We expect OurThemeProvider to trigger the initialization of DarkModeContextProvider
  // We'll also reset on login/out
  useEffect(() => {
    if (!canInitialize) return;

    initializeDarkMode().then();
  }, [memberId, canInitialize, initializeDarkMode, dispatch]);

  const toggleDarkMode = useCallback(async () => {
    const isDark = await getLocalItem(currentKey);

    setDarkMode(currentKey, !isDark);
  }, [currentKey, setDarkMode]);

  return { darkMode, toggleDarkMode };
};

export default useOurManageDarkMode;
