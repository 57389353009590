import { useEffect } from 'react';
import { useOurLog } from '@petconsole/pure-fe-utils';
import useOurSnacks from '../hooks/useOurSnacks';

interface UseReportErrorProps {
  error?: string | object | null;
  activity: string;
  id?: string;
  warn?: boolean;
  log?: boolean;
}

const useReportError = ({ error, activity, id = '', warn = true, log = true }: UseReportErrorProps) => {
  const { warn: warning } = useOurSnacks();
  const { log: report } = useOurLog();

  useEffect(() => {
    if (!error) return;

    if (warn) warning(`${activity} failed.`);
    if (!log) return;

    const errorText = typeof error === 'string' ? error : JSON.stringify(error);

    report(`Error ${activity.toLowerCase()}${id ? ` ${id}` : ''}: ${errorText}`).then();
  }, [activity, id, error, log, warn, warning, report]);

  return error;
};

export default useReportError;
