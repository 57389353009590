import { properCase } from '@petconsole/pure-base';
import { shopItemApiEntity } from '@petconsole/pure-fe-api';
import { fetchParams, fetchReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { ExtraReducers, ThunkApi } from '../../../types';

const { api, name: sliceName, proper } = shopItemApiEntity;
const byShopItemName = `by${proper}`;
const myShopItemName = `my${proper}`;
const byShopItemTypePrefix = `${sliceName}/fetch${properCase(byShopItemName)}s`;
const myShopItemTypePrefix = `${sliceName}/fetch${properCase(myShopItemName)}s`;

export const byShopItemsByShopPayloadCreator = async (shopId: string, { getState }: ThunkApi) =>
  api.getListByShopAndAvailable(shopId, fetchParams(getState, sliceName, byShopItemName));

const myShopItemsByShopPayloadCreator = async (shopId: string, { getState }: ThunkApi) =>
  api.getListByShop(shopId, fetchParams(getState, sliceName, myShopItemName));

export const byShopItemsByShopThunk = appThunk(byShopItemTypePrefix, byShopItemsByShopPayloadCreator);

export const myShopItemsByShopThunk = appThunk(myShopItemTypePrefix, myShopItemsByShopPayloadCreator);

export const myShopItemsByShopReducers: ExtraReducers = (adapter) =>
  fetchReducers(myShopItemsByShopThunk, myShopItemName, adapter);
