// noinspection ES6PreferShortImport

import { MaybeNull, MaybeString, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../../constants';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { DataEntityType, DefaultColumns, InitialEntity, LayoutGrid, ReactionColumns } from '../../../types';

export interface Feed extends DefaultColumns, ReactionColumns {
  type: DataEntityType;
  feedId: string;
  defId: string;
  feedAt: string;
  layout: LayoutGrid;
  text: string;
  editedAt: MaybeString;
  commentsLocked: boolean;
}

export const feedEntity = entityAttributes({
  name: entityName.feed,
  attributes: {
    category: entityCategory.post,
    columns: { reaction: true },
    loadLimit: 10,
    maxImages: 4,
    nameField: 'text',
    site: anySite.anySite,
  },
});

const { array, boolean, date, id, strim } = schema;
export const feedSchema = entitySchema<Feed>(feedEntity, {
  defId: id(true),
  feedAt: date(),
  layout: array(),
  text: strim(),
  editedAt: date(),
  commentsLocked: boolean(),
});

export const feedDefaults: Partial<Feed> = {
  defId: '',
  feedAt: '',
  layout: [[[]]],
  text: '',
  editedAt: null,
  commentsLocked: false,
};

export interface FeedRecord extends Feed, RecordType {}

export type MaybeFeedRecord = MaybeNull<FeedRecord>;
export type FeedRecords = FeedRecord[];

export const initialFeed: InitialEntity<Feed, FeedRecord> = (overrides = {}) =>
  initialEntity<Feed>(feedEntity, { ...feedDefaults, ...overrides }) as FeedRecord;
