/**
 * A `<Router>` for use in web browsers. Provides the cleanest URLs.
 */
import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { Router } from 'react-router-dom';
import { BrowserHistory, createBrowserHistory, Listener } from 'history';
import Rollbar from 'rollbar';
import { historyContext } from '@rollbar/react';

interface OurBrowserRouterProps extends PropsWithChildren {
  rollbar: Rollbar;
}

const OurBrowserRouter = ({ rollbar, children }: OurBrowserRouterProps) => {
  const historyRef = useRef<BrowserHistory>();

  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory();
  }

  const history = historyRef.current;
  const { action, location, listen } = history;
  const [state, setState] = useState({ action, location });

  useLayoutEffect(() => listen(setState), [listen]);
  // @ts-expect-error - historyContext expects an args param
  useLayoutEffect(() => listen(historyContext(rollbar) as unknown as Listener), [listen, rollbar]);

  return (
    <Router location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  );
};

OurBrowserRouter.whyDidYouRender = true;

export default OurBrowserRouter;
