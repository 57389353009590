import { RecordType } from '@petconsole/pure-base';
import { reactionApi, ReactionApi } from '@petconsole/pure-fe-api';
import { ReactionRecord } from '@petconsole/pure-shared';
import { ThunkApi } from '../../types';

const payloadCreatorUpdateReaction =
  (sliceName: string, _idName: string, api: ReactionApi = reactionApi) =>
  async ({ reaction: changes }: RecordType, { getState }: ThunkApi) => {
    // try {
    const { create, update, getByReactionTo } = api;
    const { reactionToId: id } = changes as ReactionRecord;
    const { [sliceName]: entity } = (getState() as RecordType)[sliceName] as RecordType;
    const reaction = await getByReactionTo(id);

    return reaction
      ? await update(reaction.reactionId, { ...reaction, ...(changes as ReactionRecord) })
      : await create({
          ...(((entity as RecordType).reactions as RecordType)[id] as ReactionRecord),
          ...(changes as ReactionRecord),
        });
    // } catch (e) {
    //   if (e instanceof Error) {
    //     // TODO: Determine why this causes an error
    //     //     TypeError: (0 , import_helpers.getReadWriteStateSelectors) is not a function
    //     //       51 |     [`select${pascalPropertyName}ReadError`]: readError,
    //     //       52 |     [`select${pascalPropertyName}HasMore`]: hasMore,
    //     //     > 53 |   } = getReadWriteStateSelectors(sliceName, slicePropertyName);
    //     //          |       ^
    //     //       54 |
    //     //       55 |   // @ts-ignore
    //     //       56 |   const singleFetchReducers = fetchReducers(fetch, slicePropertyName, adapter);
    //     //       at storeSliver (src/slivers/storeSliver.ts:53:7)
    //     // dispatch(snackConflictCheck({ message: e.message })).then();
    //     // dispatch(loggerError({ message: e.message })).then();
    //   }
    //   throw e;
    // }
  };

export default payloadCreatorUpdateReaction;
