import { GoogleSavedAddress, RecordType } from '@petconsole/pure-base';
import { memo, useState } from 'react';
import OurDirectionsButton from '../../components/buttons/OurDirectionsButton';
import MaintMap from '../MaintMap';
import { UseMaintDirectionsProps } from '../types';

const useMaintDirections = <T extends RecordType = RecordType>({
  entity,
  data,
  directions,
  readOnly,
}: UseMaintDirectionsProps<T>) => {
  const [mapOpen, setMapOpen] = useState(false);

  const DirectionsButton = memo(() => (
    <OurDirectionsButton isOpen={mapOpen} setIsOpen={setMapOpen} disabled={!readOnly} />
  ));

  if (!entity.columns.address || !directions) return { DirectionsButton: undefined, DirectionsMap: undefined };

  const title = (data?.[entity.addField] ?? '') as string;
  const { description = '', latitude = 0, longitude = 0 } = (data?.address || {}) as GoogleSavedAddress; // Null if address is cleared
  const streetAddress = (description || '').split(',')[0];

  const DirectionsMap = memo(() => {
    if (!mapOpen || !readOnly) return null;

    return <MaintMap {...{ title, streetAddress, latitude, longitude }} />;
  });

  return { DirectionsButton, DirectionsMap };
};

export default useMaintDirections;
