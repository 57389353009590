import { PropsWithChildren, useEffect } from 'react';
import { reactAppHtmlAppName, reactAppRootPath } from '@petconsole/pure-shared';

interface OurErrorDisplayProps extends PropsWithChildren {
  error: unknown;
  resetError: unknown;
}

// NOTE: This display will only appear on an error occurring outside OurAppErrorBoundary in OurApp

const OurErrorDisplay = ({ error, resetError }: OurErrorDisplayProps) => {
  useEffect(() => {
    console.log('OurErrorDisplay error:', error);
    console.log('OurErrorDisplay resetError:', resetError);
  }, [error, resetError]);

  return (
    <div>
      <p>
        An unexpected error occurred. We apologize for the inconvenience. We have automatically
        reported the error, so we can work towards preventing it from occurring again.
      </p>
      <p>
        In the meantime, please try refreshing your browser, or return to{' '}
        <a href={reactAppRootPath}>{reactAppHtmlAppName}</a>
      </p>
    </div>
  );
};

export default OurErrorDisplay;
