import { Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RecordType } from '@petconsole/pure-shared';
import OurLink, { OurLinkProps } from './OurLink';

interface OurTextLinkProps extends OurLinkProps {
  to: string;
  text?: string;
  readOnly?: boolean;
  state?: RecordType;
  rest?: RecordType;
}

const OurTextLink = ({ to, text, readOnly, state, ...rest }: OurTextLinkProps) => {
  const { mode, primary } = useTheme().palette;

  if (readOnly) {
    return (
      <Typography component="span" color={mode === 'dark' ? primary.light : 'primary'}>
        {text || <Skeleton />}
      </Typography>
    );
  }

  return (
    <OurLink to={to} state={state} {...rest}>
      <Typography component="span">{text || <Skeleton />}</Typography>
    </OurLink>
  );
};

export default OurTextLink;