import { GetListOptions, GetListResults } from '@petconsole/pure-shared';
import { ListByValuePayloadCreator } from '../../types';
import dispatchListItemsNextAction from '../misc/dispatchListItemsNextAction';
import fetchParams from '../misc/fetchParams';

const payloadCreatorListByValue: ListByValuePayloadCreator =
  (slice, sliver, idName, api, method, nextAction) =>
  async (value, { getState, dispatch }) =>
    (api[method] as (value: unknown, options?: GetListOptions) => Promise<GetListResults>)(
      value,
      fetchParams(getState, slice, sliver),
    ).then((data) => dispatchListItemsNextAction({ data, idName, dispatch, nextAction }));

export default payloadCreatorListByValue;
