import { RecordType } from '@petconsole/pure-base';
import { Api, PclTierRecord, pclTierEntity, GetListOptions, GetListResults, ApiEntity } from '@petconsole/pure-shared';
import { commonApi, post } from './common';

type ApiRecord = PclTierRecord;
type ListReturns = Promise<GetListResults<ApiRecord>>;

const apiPath = '/pclTiers';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getListByPclService: (pclServiceId: string, options: GetListOptions) => ListReturns;
  createStripe: (pclTierId: string) => Promise<T>;
  updateStripe: (pclTierId: string) => Promise<T>;
}

export type PclTierApi = Api<ApiRecord> & CustomApi;

export const pclTierApi: PclTierApi = {
  ...common,
  getListByValue,
  getListByPclService: (pclServiceId: string, options) => getListByValue('serviceId', pclServiceId, options),
  createStripe: (pclTierId) => post(`${apiPath}/createStripe/${pclTierId}`, {}) as Promise<ApiRecord>,
  updateStripe: (pclTierId) => post(`${apiPath}/updateStripe/${pclTierId}`, {}) as Promise<ApiRecord>,
};

export const pclTierApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...pclTierEntity, api: pclTierApi };
