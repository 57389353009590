export const myShopDrawerWidth = 165;
export const myShopAppBarHeight = 52;
export const myShopItemVariantName1 = 'variantName1';
export const myShopItemVariantName2 = 'variantName2';
export const myShopItemVariantsName = 'variants';
export const myShopItemVariantCodesName = 'variantCodes';
export const myShopItemVariantCodesName1 = 'variantCodes1';
export const myShopItemVariantCodesName2 = 'variantCodes2';

// export const siteMedia = {
//   headers: { logout: `${webPath.media}/headers/logout-20220623-1.png` },
// };
