import AvatarEditor from 'react-avatar-editor';
import { EntityRecord, ImageKey } from '@petconsole/pure-shared';

interface GetAvatarInfoProps {
  id: string;
  values: Partial<EntityRecord>;
  adding?: boolean;
  avatarImage?: string;
  avatarChanged?: boolean;
  captureAvatar?: boolean;
  avatarKey?: ImageKey;
  avatarEditor?: AvatarEditor;
}

const getAvatarInfo = ({
  id,
  values,
  adding,
  avatarImage,
  avatarChanged,
  captureAvatar,
  avatarKey,
  avatarEditor,
}: GetAvatarInfoProps) => {
  let avatar = (values.avatar || '') as string;
  let src = avatarImage || '';

  if (avatarKey && (adding || avatarChanged)) {
    if (captureAvatar) {
      if (!(avatarEditor instanceof AvatarEditor)) {
        console.log('avatarEditor', avatarEditor);
        throw new Error('getAvatarInfo: Invalid editor instance');
      }

      src = avatarEditor.getImageScaledToCanvas().toDataURL();
    }

    avatar = avatarKey({ id, src });
  }

  return { avatarPath: avatar, avatarSrc: src };
};

export default getAvatarInfo;
