import { useCallback, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { DropzoneRootProps } from 'react-dropzone';
import { MaybeNull } from '@petconsole/pure-base';
import { SetState } from '@petconsole/pure-shared';
import { useTheme } from '@mui/material/styles';

export interface AvatarEditProps {
  image: File | string;
  width?: number;
  borderRadius?: number;
  scale: number;
  rotate: number;
  getRootProps: <P extends DropzoneRootProps>(props?: P | undefined) => P;
  setEditor: SetState<MaybeNull<AvatarEditor>>;
  setChanged: SetState<boolean>;
}

const AvatarEdit = ({
  image,
  width = 25,
  borderRadius,
  scale,
  rotate,
  getRootProps,
  setEditor,
  setChanged,
}: AvatarEditProps) => {
  const { spacing } = useTheme();
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });

  const changePosition = useCallback((pos: { x: number; y: number }) => {
    setPosition(pos);
    setChanged(true);
  }, [setChanged]);

  const length = Number(spacing(width).replace('px', ''));

  return (
    <AvatarEditor
      scale={scale}
      position={position}
      rotate={rotate}
      width={length}
      height={length}
      image={image}
      className="editor-canvas"
      {...getRootProps({ style: { width: length, height: length } })}
      ref={setEditor}
      onPositionChange={changePosition}
      color={[0, 105, 177, 0.6]}
      borderRadius={borderRadius == null ? length / 2 : borderRadius}
      // crossOrigin="anonymous"
      // onLoadFailure={() => logCallback('onLoadFailed')}
      // onLoadSuccess={() => logCallback('onLoadSuccess')}
      // onImageReady={() => logCallback('onImageReady')}
    />
  );
};

AvatarEdit.whyDidYouRender = true;

export default AvatarEdit;
