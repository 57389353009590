import { RecordType } from '@petconsole/pure-base';
import { ListByCityPayloadCreator, ListPayloadCreator, NextAction, Slice } from '../../types';
import {
  payloadCreatorCityWithFetch,
  payloadCreatorListWithFetch,
  payloadCreatorMyListWithFetch,
  payloadCreatorNewestWithFetch,
  payloadCreatorOldestWithFetch,
  payloadCreatorWithFetch,
} from '../payloadCreators';

const sliceAddCreators = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  const { idName, thunk } = slice;
  const { fetchRating, fetchReaction } = thunk;
  const fetchCreator = fetchRating || fetchReaction;
  const { entity: api } = slice.api;
  const slivers = { ...slice.sliver };

  const getPayloadCreators = (creator: ListPayloadCreator<T> | ListByCityPayloadCreator<T>, sliverName: string) =>
    creator(idName, api, slice.name, sliverName, fetchCreator as NextAction | undefined);

  slice.slivers.forEach((sliverName) => {
    const sliver = slivers[sliverName];

    switch (sliverName) {
      case slice.name:
        if (sliver.payloadCreator === undefined)
          sliver.payloadCreator = payloadCreatorWithFetch(idName, api, fetchCreator as NextAction | undefined);
        if (sliver.payloadCreators === undefined)
          sliver.payloadCreators = getPayloadCreators(
            <ListPayloadCreator<T>>payloadCreatorListWithFetch<T>,
            sliverName,
          );
        break;

      case `my${slice.proper}`:
        if (sliver.payloadCreators === undefined)
          sliver.payloadCreators = getPayloadCreators(
            payloadCreatorMyListWithFetch as ListPayloadCreator<T>,
            sliverName,
          );
        break;

      case `city${slice.proper}`:
        if (sliver.payloadCreators === undefined)
          sliver.payloadCreators = getPayloadCreators(payloadCreatorCityWithFetch as ListPayloadCreator<T>, sliverName);
        break;

      case `newest${slice.proper}`:
        if (sliver.payloadCreators === undefined)
          sliver.payloadCreators = getPayloadCreators(
            payloadCreatorNewestWithFetch as ListPayloadCreator<T>,
            sliverName,
          );
        break;

      case `oldest${slice.proper}`:
        if (sliver.payloadCreators === undefined)
          sliver.payloadCreators = getPayloadCreators(
            payloadCreatorOldestWithFetch as ListPayloadCreator<T>,
            sliverName,
          );
        break;

      default:
    }
  });

  return slivers;
};

export default sliceAddCreators;
