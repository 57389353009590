import { useCurrentMember } from '@petconsole/pure-fe-state';
import { MemberRecord, webPath } from '@petconsole/pure-shared';
import { MemberAvatarMenuItems } from '../../../types';

const useDefaultAppMemberMenuItems = () => {
  const { member } = useCurrentMember();
  const { newMessages = 0, newNotifications = 0 } = (member || {}) as MemberRecord;

  const defaultMenuItems: MemberAvatarMenuItems = [
    ['My Profile', webPath.profile, true],
    ['My Friends', webPath.myFriends],
    ['My Mail', webPath.mailMessages, false, newMessages],
    ['My Notifications', webPath.myNotifications, false, newNotifications],
    ['My Orders', webPath.myOrders],
    ['My Shops', webPath.myShops, true],
    ['Logout', webPath.logout],
  ];

  return defaultMenuItems;
};

export default useDefaultAppMemberMenuItems;
