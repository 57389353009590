// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity, MaybeNull } from '../../types';

export interface PetType extends CrudColumns {
  type: DataEntityType;
  petType: string;
}

export const petTypeEntity = entityAttributes({
  name: entityName.petType,
  attributes: {
    addInstructions: 'Please enter the name of the pet type to add.',
    addLabel: 'Pet Type',
    category: entityCategory.item,
    columns: { images: false, json: false },
    loadLimit: 20,
    nameField: 'petType',
    ownerIfEditing: true,
    site: anySite.petConsole,
    tabs: [],
  },
});

const { strim } = schema;
export const petTypeSchema = entitySchema<PetType>(petTypeEntity, {
  petType: strim(),
});

export const petTypeDefaults: Partial<PetType> = {
  petType: '',
};

export interface PetTypeRecord extends PetType, RecordType {
  petType: string;
}

export type MaybePetTypeRecord = MaybeNull<PetTypeRecord>;
export type PetTypeRecords = PetTypeRecord[];

export const initialPetType: InitialEntity<PetType, PetTypeRecord> = (overrides = {}) =>
  initialEntity<PetType>(petTypeEntity, { ...petTypeDefaults, ...overrides }) as PetTypeRecord;
