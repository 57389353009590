import camelToWords from './camelToWords';

const properToWords = (str = '') => {
  if (!str) return '';

  const camelCase = `${str.charAt(0).toLowerCase()}${str.slice(1)}`;

  return camelToWords(camelCase);
};

export default properToWords;
