import { RecordType } from '@petconsole/pure-base';
import { mailFolderApiEntity } from '@petconsole/pure-fe-api';
import { MailFolderRecord, mailFolderType } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurSelectAction } from '../../../helpers';
import {
  BaseSliceOptions,
  OurEntityHooks,
  RequiredSliverHooks,
  SelectByHook,
  SelectHook,
  SelectSetHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { selectCurrentFolder, selectIdByMailFolderType } from './mailFolderSelectors';

type ApiEntityRecord = MailFolderRecord;

const apiEntity = mailFolderApiEntity;
const { api } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  comparer: ({ order: a }: ApiEntityRecord, { order: b }: ApiEntityRecord) => a.localeCompare(b),
  payloadCreators: async () => api.getAll(),
  tabs: undefined,
  sliceProperties: [{ suffix: 'CurrentFolderId', default: null }],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { sliverHooks } = sliceHookTypes;

export interface OurMailFolderHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  mailFolderHooks: RequiredSliverHooks;
  customHooks: {
    currentFolderId: SelectSetHooks<string>;
    useSelectCurrentFolder: SelectHook<ApiEntityRecord | undefined>;
    useSelectInboxId: SelectByHook<string | undefined>;
    useSelectSentId: SelectByHook<string | undefined>;
    useSelectTrashId: SelectByHook<string | undefined>;
  };
}

export const ourMailFolderHooks: OurMailFolderHooks = {
  entity: apiEntity,
  mailFolderHooks: sliverHooks(apiEntity, '', hooks) as RequiredSliverHooks,
  customHooks: {
    currentFolderId: {
      useSelect:
        hooks.useOurSelectMailFolderCurrentFolderId as OurMailFolderHooks['customHooks']['currentFolderId']['useSelect'],
      useSet:
        hooks.useOurSetMailFolderCurrentFolderId as OurMailFolderHooks['customHooks']['currentFolderId']['useSet'],
    },
    useSelectCurrentFolder: () => useOurSelectAction(selectCurrentFolder),
    useSelectInboxId: () => useOurSelectAction(selectIdByMailFolderType(mailFolderType.Inbox)),
    useSelectSentId: () => useOurSelectAction(selectIdByMailFolderType(mailFolderType.Sent)),
    useSelectTrashId: () => useOurSelectAction(selectIdByMailFolderType(mailFolderType.Trash)),
  },
};

export default reducer;
