import { useOurRedirectPath } from '@petconsole/pure-fe-utils';
import OurTextLink from './OurTextLink';

interface OurRedirectLinkProps {
  path: string;
  text?: string;
}

const OurRedirectLink = ({ path, text }: OurRedirectLinkProps) => {
  const redirectPath = useOurRedirectPath({ path });

  return <OurTextLink to={redirectPath} text={text} />;
};

OurRedirectLink.whyDidYouRender = true;

export default OurRedirectLink;
