import { RecordType } from '@petconsole/pure-base';
import { commentApiEntity } from '@petconsole/pure-fe-api';
import { ApiEntity, CommentRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../helpers';
import { BaseSliceOptions, FetchesSelectsHooks, OurEntityHooks, RequiredCrudSliverHooks } from '../../types';
import BaseEntitySlice from '../base/BaseEntitySlice';

type ApiEntityRecord = CommentRecord;

const apiEntity = commentApiEntity;
const { name: sliceName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  selectId: ({ threadId, replyId }: RecordType) => [threadId, replyId].join('#'),
  comparer: (
    { threadId: threadIdA = '', replyId: replyIdA = '' },
    { threadId: threadIdB = '', replyId: replyIdB = '' },
  ) => threadIdA.localeCompare(threadIdB) || replyIdA.localeCompare(replyIdB),
  payloadCreator: null,
  fetchManyByProperty: { propertyName: 'commentOnId', apiMethod: 'getListByCommentOnId' },
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      payloadCreator: null,
      payloadCreators: null,
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks } = sliceHookTypes;

export interface OurCommentHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  commentHooks: RequiredCrudSliverHooks;
  customHooks: {
    byCommentOnId: FetchesSelectsHooks;
  };
}

export const ourCommentHooks: OurCommentHooks = {
  entity: apiEntity as unknown as ApiEntity<CommentRecord>,
  commentHooks: crudHooks<ApiEntityRecord>(apiEntity, hooks) as RequiredCrudSliverHooks,
  customHooks: {
    byCommentOnId: {
      useFetches: hooks.useOurFetchCommentsByCommentOnId as FetchesSelectsHooks['useFetches'],
      useSelects: hooks.useOurSelectCommentsByCommentOnId as FetchesSelectsHooks['useSelects'],
    },
  },
};

export default reducer;
