import { blogApiEntity, blogDefApiEntity } from '@petconsole/pure-fe-api';
import { BlogDefRecord, reactAppApiSite } from '@petconsole/pure-shared';
import { writeReducers } from '../../helpers';
import { appThunk } from '../../store/appThunk';
import { EntityAdapterWithPick, RecordType } from '../../types';

const { api, name: sliceName, proper } = blogApiEntity;
const blogDefApi = blogDefApiEntity.api;
const typePrefix = `${sliceName}/create${proper}Post`;

export const createBlogPostPayloadCreator = async ({ name, blog }: RecordType) => {
  const blogDef = (await blogDefApi.getByName(name || reactAppApiSite)) as BlogDefRecord;

  return (await api.create({...blog, defId: blogDef.blogDefId}));
};

export const createBlogPostThunk = appThunk(typePrefix, createBlogPostPayloadCreator);

export const createBlogPostReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(createBlogPostThunk, sliceName, adapter);
