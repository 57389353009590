import { postEntity } from '@petconsole/pure-shared';
import { appThunk } from '../../../store/appThunk';
import { fetchByTopicPostsToPayloadCreator } from './fetchByTopicPostsPayloadCreators';

const { name: slice, pluralProper } = postEntity;

const fetchByTopicPostsToThunk = appThunk(
  `${slice}/fetchByTopic${pluralProper}To`,
  fetchByTopicPostsToPayloadCreator,
);

export default fetchByTopicPostsToThunk;
