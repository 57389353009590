import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  ApiEntity,
  GetListOptions,
  GetListResults,
  notificationEntity,
  NotificationRecord,
} from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = NotificationRecord;

const apiPath = '/notifications';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getListByMember: (memberId: string, options: GetListOptions) => Promise<GetListResults<T>>;
}

export type NotificationApi = Api<ApiRecord> & CustomApi;

export const notificationApi: NotificationApi = {
  ...common,
  getListByValue,
  // getListByMember may be deprecated by getMyList
  getListByMember: (memberId = '', options) => getListByValue('memberId', memberId, options),
};

export const notificationApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...notificationEntity, api: notificationApi };
