import { useOurRoutes } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import { ContactUs, Credits, OurNotFound, Privacy, Team, Terms } from '../../general';

const useGeneralRoutes = () => {
  const { authRoute, routeTo } = useOurRoutes();

  return [
    routeTo(webPath.contact, ContactUs),
    authRoute(webPath.credits, Credits),
    routeTo(webPath.notFound, OurNotFound),
    routeTo(webPath.privacy, Privacy),
    routeTo(webPath.team, Team),
    routeTo(webPath.terms, Terms),
  ];
};

export default useGeneralRoutes;
