// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import { genderType, site } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { BasePet, CrudColumns, DataEntityType, InitialEntity, MaybeImage, MaybeNull, MemberColumns } from '../../types';

interface Pet extends Omit<BasePet, 'createdAt' | 'updatedAt'>, CrudColumns, MemberColumns {
  type: DataEntityType;
  petId: string;
  birthDate: string | null;
  country: string;
  province: string;
  city: string;
  banner: MaybeImage;
  occupation: string;
  tagline: string;
  aboutMe: string;
}

const { General, Details, Avatar, Banner, Photos, Preview } = entityTabName;
export const petEntity = entityAttributes({
  name: entityName.pet,
  attributes: {
    addInstructions: "Please enter your pet's name",
    category: entityCategory.pet,
    columns: { json: false, member: true },
    loadLimit: 20,
    maxImages: 12,
    memberOwned: true,
    nameField: `${entityName.pet}Name`,
    site: site.petConsole,
    tabs: [General, Details, Avatar, Banner, Photos, Preview],
  },
});

const { image, nullable, strim, string, triple } = schema;
export const petSchema = entitySchema<Pet>(petEntity, {
  petName: strim().required(),
  petType: strim().default('Other'),
  petSubType: nullable(),
  gender: strim().default('Other'),
  fixed: triple(null),
  birthDate: nullable(),
  microchip: strim(),
  tattoo: strim(),
  country: string(2), // read-only - ISO 2 char
  province: strim(), // read-only
  city: strim(), // read-only
  banner: image(),
  occupation: strim(),
  tagline: strim(),
  aboutMe: strim(),
  avatar: strim(),
});

export const petDefaults: Partial<Pet> = {
  petName: '',
  petType: '',
  petSubType: null,
  gender: genderType.Other,
  fixed: null,
  birthDate: null,
  microchip: '',
  tattoo: '',
  country: '', // read-only - ISO 2 char
  province: '', // read-only
  city: '', // read-only
  banner: null,
  occupation: '',
  tagline: '',
  aboutMe: '',
  avatar: '',
};

export interface PetRecord extends Pet, RecordType {}

export type MaybePetRecord = MaybeNull<PetRecord>;
export type PetRecords = PetRecord[];

export const initialPet: InitialEntity<Pet, PetRecord> = (overrides = {}) =>
  initialEntity<Pet>(petEntity, { ...petDefaults, ...overrides }) as PetRecord;
