import { CancelOutlined } from '@mui/icons-material';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurCancelButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  tip?: OurIconButtonProps['tip'];
}

const OurCancelButton = (props: OurCancelButtonProps) => (
  <OurIconButton Icon={CancelOutlined} tip="Cancel" {...props} />
);

OurCancelButton.whyDidYouRender = true;

export default OurCancelButton;
