// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity } from '../../../types';

// (99 levels * 5 digits) + 98 separators
export const maxLevels = 99;
export const digitsPerLevel = 5;
export const maxHierarchyLength = maxLevels * digitsPerLevel + (maxLevels - 1);

export interface Forum extends CrudColumns {
  type: DataEntityType;
  forumId: string;
  defId: string;
  hierarchy: string;
  name: string;
  description: string;
  private: boolean;
  locked: boolean;
  forumCount: number;
  topicCount: number;
  postCount: number;
  replyCount: number;
  json: RecordType;
}

export const forumEntity = entityAttributes({
  name: entityName.forum,
  attributes: {
    category: entityCategory.item,
    columns: { images: false },
    loadLimit: 0,
    maxImages: 4,
    test: { changes: { description: 'any text' } },
  },
});

const { boolean, id, number, strim, string } = schema;
export const forumSchema = entitySchema<Forum>(forumEntity, {
  defId: id(true),
  hierarchy: string(maxHierarchyLength),
  name: strim(), // forum name
  description: strim(),
  private: boolean(),
  locked: boolean(),
  forumCount: number(), // sub-forums
  topicCount: number(), // sub-topics
  postCount: number(), // update this immediately or as a scheduled process?
  replyCount: number(), // update this immediately or as a scheduled process?
});

export const forumDefaults: Partial<Forum> = {
  defId: '',
  hierarchy: '',
  name: '',
  description: '',
  private: false,
  locked: false,
  forumCount: 0,
  topicCount: 0,
  postCount: 0,
  replyCount: 0,
};

export interface ForumRecord extends Forum, RecordType {}

export type MaybeForumRecord = MaybeNull<ForumRecord>;
export type ForumRecords = ForumRecord[];

export const initialForum: InitialEntity<Forum, ForumRecord> = (overrides = {}) =>
  initialEntity<Forum>(forumEntity, { ...forumDefaults, ...overrides }) as ForumRecord;
