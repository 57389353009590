import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { reactAppHtmlAppName } from '@petconsole/pure-shared';
import OurJoinButton from './OurJoinButton';
import OurLoginButton from './OurLoginButton';

const OurSiteButtons = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Grid container justifyContent="center" pb={2}>
      <Grid item xs={6} sm={5} pl={1} pr={1}>
        <OurJoinButton size={xs ? 'small' : 'large'} label={`Join ${reactAppHtmlAppName}`} />
      </Grid>
      <Grid item xs={6} sm={5} pl={1} pr={1}>
        <OurLoginButton size={xs ? 'small' : 'large'} label="Member Login" />
      </Grid>
    </Grid>
  );
};

OurSiteButtons.whyDidYouRender = true;

export default OurSiteButtons;
