import { AuthError, signIn, SignInInput, SignInOutput } from 'aws-amplify/auth';
import authGetCurrentUser from './authGetCurrentUser';
import authSignOut from './authSignOut';

export const attemptSignIn = ({ username, password }: SignInInput) => signIn({ username, password });

interface AuthSignInProps {
  email: string;
  password: string;
  authCheck?: ({ event }?: { event?: string }) => Promise<void>;
}

const authSignIn = async ({ email, password, authCheck }: AuthSignInProps) => {
  try {
    const result: SignInOutput = await attemptSignIn({ username: email, password });
    const { signInStep } = result.nextStep;

    return { success: result.isSignedIn, next: signInStep };
  } catch (e) {
    if (e instanceof AuthError) {
      if (e.name === 'UserAlreadyAuthenticatedException') {
        // "recoverySuggestion": "Call signOut before calling signIn again."
        const { success, email: currentEmail } = await authGetCurrentUser();

        if (success) {
          if (currentEmail === email) {
            if (authCheck) await authCheck();

            return { success: false, reason: 'Already signed in. Please refresh browser' };
          } else {
            await authSignOut();

            return { success: false, reason: 'Signing out previous member. Please try signing in again.' };
          }
        }
      }
    }

    if (!(e instanceof Error)) {
      // TODO: Log to rollbar
      return { success: false, reason: 'Unknown error' };
    }

    return { success: false, reason: e.message };
  }
};

export default authSignIn;
