import { ChevronRight } from '@mui/icons-material';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurNextButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  tip?: OurIconButtonProps['tip'];
  Icon?: OurIconButtonProps['Icon'];
}

const OurNextButton = ({
  tip = 'Close',
  Icon = () => <ChevronRight />,
  fontSize = 'large',
  ...rest
}: OurNextButtonProps) => {
  return <OurIconButton tip={tip} Icon={Icon} fontSize={fontSize} {...rest} />;
};

OurNextButton.whyDidYouRender = true;

export default OurNextButton;
