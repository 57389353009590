import { useOurRoutes } from '@petconsole/pure-fe-mui';
import { Shop, Shops } from '@petconsole/pure-fe-shops';
import { webPath } from '@petconsole/pure-shared';
import { Blog } from '../../blog';
import { Feed } from '../../feed';
import { Friend, Friends } from '../../friend';
import { Forums, ForumTopic } from '../../forum';
import { MailFolders, MailMessage, MailMessages, MailNewMessage } from '../../mail';
import { Members } from '../../members';
import { Notifications } from '../../notification';
import { Profile } from '../../profile';
import { ProfileWizard } from '../../profileWizard';

const useCommonRoutes = () => {
  const { authRoute, routeTo } = useOurRoutes();

  return [
    routeTo(`${webPath.blog}/:id`, Blog),
    routeTo(webPath.blog, Blog),
    authRoute(webPath.feed, Feed),
    authRoute(webPath.forum, Forums),
    authRoute(`${webPath.forumPost}/:id/:postId`, ForumTopic),
    authRoute(`${webPath.forumTopic}/:id`, ForumTopic),
    authRoute(`${webPath.forum}/:id`, Forums),
    authRoute(webPath.myFriends, Friends),
    authRoute(`${webPath.myFriends}/:name`, Friend),
    authRoute(webPath.mailFolders, MailFolders),
    authRoute(webPath.mailMessages, MailMessages),
    authRoute(`${webPath.mailMessage}/:id`, MailMessage),
    authRoute(`${webPath.mailMessages}/:id`, MailMessages),
    authRoute(webPath.mailNewMessage, MailNewMessage),
    authRoute(webPath.members, Members),
    authRoute(webPath.myNotifications, Notifications),
    authRoute(webPath.profile, Profile),
    authRoute(webPath.profileWizard, ProfileWizard),
    authRoute(webPath.shops, Shops),
    authRoute(`${webPath.shops}/:id`, Shop),
  ];
};

export default useCommonRoutes;
