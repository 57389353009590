// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import {
  DataEntityType,
  InitialEntity,
  MemberSubscribeColumns,
  OwnerColumns,
  ReactionBusinessColumns,
} from '../../types';

interface Van extends ReactionBusinessColumns, MemberSubscribeColumns, OwnerColumns {
  type: DataEntityType;
  vanId: string;
  vanName: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const vanEntity = entityAttributes({
  name: entityName.van,
  attributes: {
    category: entityCategory.van,
    columns: { address: true, business: true, member: true, reaction: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    memberOwned: true,
    nameField: `${entityName.van}Name`,
    site: anySite.vanConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const vanSchema = entitySchema<Van>(vanEntity, {
  vanName: strim().required('Van name is required'),
});

export const vanDefaults: Partial<Van> = {
  vanName: '',
};

export interface VanRecord extends Van, RecordType {}

export type MaybeVanRecord = MaybeNull<VanRecord>;
export type VanRecords = VanRecord[];

export const initialVan: InitialEntity<Van, VanRecord> = (overrides = {}) =>
  initialEntity<Van>(vanEntity, { ...vanDefaults, ...overrides }) as VanRecord;
