import { CityLocation, MaybeRecordType, RecordType } from '@petconsole/pure-base';
import {
  Api,
  GetListOptions,
  GetListResults,
  MaybeMemberRecord,
  MemberRecord,
  memberEntity,
  profileSteps, ApiEntity,
} from '@petconsole/pure-shared';
import {
  commonApi,
  createdBetweenParams,
  fullPath,
  get,
  limitParams,
  locationParams,
  optionParams,
  pathParam,
  post,
  update,
} from './common';

type ApiRecord = MemberRecord;

const apiPath = '/members';
const idPath = (id: string) => `${apiPath}/${id}`;
const { getByValue, getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

const excludePending = <T extends RecordType = MemberRecord>({ items: members, ...rest }: GetListResults<T>) => ({
  items: members.filter((member: T) => (member.profileStep as number) >= profileSteps && !!member.memberName),
  ...rest,
});

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getCurrent: () => Promise<MaybeMemberRecord>;
  getCognitoUser: (email: string) => Promise<MaybeRecordType>;
  getListMemberNameStarts: (memberName: string, options?: GetListOptions) => Promise<GetListResults<T>>;
  getListNewest: (includePending?: boolean, options?: GetListOptions) => Promise<GetListResults<T>>;
  getListOldest: (includePending?: boolean, options?: GetListOptions) => Promise<GetListResults<T>>;
  getListPending: (options?: GetListOptions) => Promise<GetListResults<T>>;
  getPasswordPolicy: () => Promise<MaybeRecordType>;
  getAvatarByName: (name: string) => Promise<MaybeRecordType>;
  updateNotifications: () => Promise<MaybeRecordType>;
  validMemberName: (memberName: string) => Promise<MaybeRecordType>;
}

export type MemberApi = Api<ApiRecord> & CustomApi;

export const memberApi: MemberApi = {
  ...common,
  getByValue,
  getListByValue,
  getCurrent: () => get(`${apiPath}/current`) as Promise<MaybeMemberRecord>,
  getCognitoUser: (email) => get(`${apiPath}/getCognitoUser/${email}`) as Promise<MaybeRecordType>,
  getByName: (name: string) => getByValue('name', name),
  getListCreatedBetween: async ({ fromCreatedAt = '', toCreatedAt = '' }, options, includePending = false) => {
    const data = (await get(
      fullPath(apiPath, `${createdBetweenParams(fromCreatedAt, toCreatedAt)}${optionParams(options)}`),
    )) as GetListResults<MemberRecord>;

    return includePending ? data : excludePending(data);
  },
  getListMemberNameStarts: async (memberName, options) => getListByValue('nameStarts', memberName, options),
  getListNewest: (includePending = false, { limit, nextKey } = {}) =>
    get(
      fullPath(
        apiPath,
        `${pathParam('newest', true)}${pathParam('pending', includePending)}${limitParams(limit, nextKey)}`,
      ),
    ) as Promise<GetListResults<MemberRecord>>,
  getListOldest: (includePending = false, { limit, nextKey } = {}) =>
    get(
      fullPath(
        apiPath,
        `${pathParam('oldest', true)}${pathParam('pending', includePending)}${limitParams(limit, nextKey)}`,
      ),
    ) as Promise<GetListResults<MemberRecord>>,
  getListPending: async (options = {}) => getListByValue('pending', String(true), options),
  getListByCity: async ({ city, province, country }: CityLocation, { limit, nextKey } = {}, includePending = false) => {
    const data = (await get(
      fullPath(apiPath, `${locationParams({ city, province, country })}${limitParams(limit, nextKey)}`),
    )) as GetListResults<MemberRecord>;

    return includePending ? data : excludePending(data);
  },
  getPasswordPolicy: () => get(`${apiPath}/getPasswordPolicy`) as Promise<MaybeRecordType>,
  getAvatarByName: async (name) => {
    const member = await get(fullPath(`${apiPath}/getBy`, pathParam('name', name)));
    const { memberName, avatar } = (member as MemberRecord) || {};

    return member ? { memberName, avatar } : member;
  },
  update: (id: string, body: RecordType) => update(idPath(id), { ...(body || {}), memberId: id }) as Promise<MemberRecord>,
  updateNotifications: async () => post(`${apiPath}/updateNotifications`, {}),
  validMemberName: (memberName) =>
    get(fullPath(`${apiPath}/validMemberName`, pathParam('name', memberName))) as Promise<MaybeRecordType>,
};

export const memberApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...memberEntity, api: memberApi };
