import { Grid } from '@mui/material';
import { MaybeNull } from '@petconsole/pure-base';
import { MaybeBreakpoints, ReturnsReactNode } from '../../types';

interface OurGridCardMarginContentProps {
  margin?: MaybeBreakpoints;
  content?: MaybeNull<ReturnsReactNode>;
}

const OurGridCardMarginContent = ({ margin, content }: OurGridCardMarginContentProps) => {
  if (!margin) return null;

  return (
    <Grid item id="margin-content" display="flex" width="100%" {...margin}>
      {content && content()}
    </Grid>
  );
};

OurGridCardMarginContent.whyDidYouRender = true;

export default OurGridCardMarginContent;
