import { PropsWithChildren } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useOurManageDarkMode from '../../../hooks/useOurManageDarkMode';

interface OurThemeProviderProps extends PropsWithChildren {
  lightTheme: object;
  darkTheme: object;
}

const OurThemeProvider = ({ darkTheme, lightTheme, children }: OurThemeProviderProps) => {
  const { darkMode } = useOurManageDarkMode({ canInitialize: true });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

OurThemeProvider.whyDidYouRender = true;

export default OurThemeProvider;
