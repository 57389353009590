import { useMemo } from 'react';
import { ShopItemVariantCodeRecords } from '@petconsole/pure-shared';
import useOurSelectByValue from '../../../helpers/hooks/useOurSelectByValue';
import { SelectByShopItemAndVariantOptionProps } from '../../../types';
import selectsByShopItemAndOption from './selectsByShopItemAndOption';

const useSelectShopItemVariantCodesByShopItemAndVariantOption = ({
  shopItemId,
  variantOption,
}: SelectByShopItemAndVariantOptionProps) => {
  const selector = useMemo(
    () => selectsByShopItemAndOption({ shopItemId, variantOption }),
    [shopItemId, variantOption],
  );

  return useOurSelectByValue<ShopItemVariantCodeRecords, string>(selector, shopItemId);
};

export default useSelectShopItemVariantCodesByShopItemAndVariantOption;
