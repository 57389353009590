import { useCallback } from 'react';
import { useAppDispatch } from '../../store/storeHooks';
import { AnyAsyncThunk } from '../../types';

const useOurDispatchEntity = (_entityType: string, action: AnyAsyncThunk) => {
  const dispatch = useAppDispatch();

  const callback = (data: unknown) => dispatch(action(data));

  return useCallback(callback, []);
};

export default useOurDispatchEntity;
