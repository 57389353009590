import { memo, useEffect } from 'react';
import { Grid } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import OutlinedGrid from '../../components/outlinedGrid/OutlinedGrid';
import FormErrors from '../../inputs/react-hook-form/FormErrors';
import { UseMaintErrorsProps } from '../types';

const useMaintErrors = <T extends RecordType = RecordType>({
  isSubmitting,
  errors,
  readOnly,
  submitting,
  setSubmitting,
}: UseMaintErrorsProps<T>) => {
  useEffect(() => {
    if (!submitting && isSubmitting) setSubmitting(true);
  }, [isSubmitting, submitting, setSubmitting]);

  const MaintErrors = memo(() => {
    if (readOnly || !submitting || !Object.values(errors).length) return null;

    return (
      <OutlinedGrid id="error-outline" label="Validation Errors">
        <Grid item xs={12}>
          <FormErrors errors={errors} heading={false} />
        </Grid>
      </OutlinedGrid>
    );
  });

  return { MaintErrors };
};

export default useMaintErrors;
