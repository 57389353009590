import { ListItem, ListItemText, MenuItem } from '@mui/material';
import { newId } from '@petconsole/pure-base';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { MemberRecord, SetState } from '@petconsole/pure-shared';
import useOurCurrentAdmin from '../../../hooks/useOurCurrentAdmin';
import { MemberAvatarMenuItems } from '../../../types';
import MemberMenuItem from './MemberMenuItem';
import AdminMenuItem from './AdminMenuItem';

interface MemberMenuItemsProps {
  setMenuOpen: SetState<boolean>;
  avatarMenuItems: MemberAvatarMenuItems;
}

const MemberMenuItems = ({
  setMenuOpen,
  avatarMenuItems,
}: MemberMenuItemsProps) => {
  const { member } = useCurrentMember();
  const { admin } = useOurCurrentAdmin();
  const { memberName, email } = member as MemberRecord;

  return (
    <>
      <MenuItem divider={!admin} disabled component={ListItem}>
        <ListItemText primary={memberName || ''} secondary={email} />
      </MenuItem>
      {admin && <AdminMenuItem />}
      {avatarMenuItems.map((item) => (
        <MemberMenuItem key={newId()} menuItem={item} setMenuOpen={setMenuOpen} />
      ))}
    </>
  );
};

MemberMenuItems.whyDidYouRender = true;

export default MemberMenuItems;
