import { properCase } from '@petconsole/pure-base';
import { RatingRecord } from '@petconsole/pure-shared';
import { AppState } from '../../store';
import { ActionProps, StateRatingSliver } from '../../types';

const setRatingReducer = (sliverName: string) => {
  const capped = properCase(sliverName);

  return {
    [`set${capped}Rating`]: {
      reducer(state: AppState, { payload }: ActionProps) {
        const { ratedId } = payload as RatingRecord;

        (state[sliverName as keyof AppState] as StateRatingSliver).rating.ratings[ratedId] = payload as RatingRecord;
      },
    },
  };
};

export default setRatingReducer;
