import { ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';
import { newId } from '@petconsole/pure-base';
import OurMarginGridCard from '../components/cards/OurMarginGridCard';
import OurBackHeadings from '../components/headings/OurBackHeadings';
import OurMediaImage from '../components/images/OurMediaImage';
import { siteMemberName } from '../constants';

interface OurWelcomePageProps {
  image: string;
  linkItems: ReactNode[];
}

const OurWelcomePage = ({ image, linkItems }: OurWelcomePageProps) => (
  <OurMarginGridCard>
    <OurBackHeadings heading="Welcome" />
    <Grid item xs={12}>
      <OurMediaImage image={image} alt="Welcome image" />
      <Typography paragraph>
        Thanks for joining us on {siteMemberName}. We hope you enjoy your visit and encourage you to visit often.
      </Typography>
      <Typography paragraph>
        To get around the site, you can open the main menu by clicking on the button in the top left corner. You can
        also use the shortcuts menu available by clicking on your avatar (profile photo) in the top right corner.
      </Typography>
      <Typography>To get going quickly, you can visit one of the following links:</Typography>
      <Grid item xs={12}>
        <ul>
          <Grid container direction="column" rowGap={1}>
            {linkItems.map((linkItem) => (
              <li key={`link-item-${newId()}`}>{linkItem}</li>
            ))}
          </Grid>
        </ul>
      </Grid>
    </Grid>
  </OurMarginGridCard>
);

OurWelcomePage.whyDidYouRender = true;

export default OurWelcomePage;
