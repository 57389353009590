import { Grid } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OutlinedGrid, { OutlinedGridProps } from './OutlinedGrid';

interface OutlinedGridItemProps extends OutlinedGridProps, RegularBreakpoints {}

const OutlinedGridItem = ({ xs = 12, sm, md, lg, xl, ...rest }: OutlinedGridItemProps) => {
  return (
    <Grid item {...{ xs, sm, md, lg, xl }}>
      <OutlinedGrid {...rest} />
    </Grid>
  );
};

OutlinedGridItem.whyDidYouRender = true;

export default OutlinedGridItem;
