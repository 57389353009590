import { ListByCityPayloadCreator } from '../../types';
import dispatchListItemsNextAction from '../misc/dispatchListItemsNextAction';
import fetchParams from '../misc/fetchParams';

const payloadCreatorCityWithFetch: ListByCityPayloadCreator =
  (idName, api, slice, sliver, nextAction) =>
  async ({ city, province, country }, { getState, dispatch }) =>
    api
      .getListByCity({ city, province, country }, fetchParams(getState, slice, sliver))
      .then((data) => dispatchListItemsNextAction({ data, idName, dispatch, nextAction }));

export default payloadCreatorCityWithFetch;
