import { RecordType } from '@petconsole/pure-base';
import { Api, EntityName } from '@petconsole/pure-shared';
import { useAppDispatch } from '../../store/storeHooks';
import ourToggleLikedThunk from './ourToggleLikedThunk';

interface UseOurToggleLikedProps<T extends RecordType = RecordType> {
  entityName: EntityName;
  api: Api<T>;
}

const useOurToggleLiked = <T extends RecordType = RecordType>({ entityName, api }: UseOurToggleLikedProps<T>) => {
  const dispatch = useAppDispatch();

  const toggleLikedThunk = ourToggleLikedThunk<T>({ entityName });

  return (id: string) => dispatch(toggleLikedThunk({ entityName, api, id }));
};

export default useOurToggleLiked;
