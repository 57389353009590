import { RecordType } from '@petconsole/pure-base';
import { EntityName, ourEntityNameToType } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import ourToggleLiked from './ourToggleLiked';

interface OurToggleLikedThunkProps {
  entityName: EntityName;
}

const ourToggleLikedThunk = <T extends RecordType = RecordType>({ entityName }: OurToggleLikedThunkProps) => {
  const type = ourEntityNameToType(entityName);

  return appThunk(`${entityName}/toggleLike${type}`, ourToggleLiked<T>);
};

export default ourToggleLikedThunk;