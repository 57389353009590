import { RatingValues } from '@petconsole/pure-shared';
import totalRaters from './totalRaters';

const averageRating = (ratings: RatingValues) => {
  const totalRating = ratings.reduce((sum, value, index) => sum + value * ((index + 1) * 0.5), 0);

  const raters = totalRaters(ratings);

  return raters ? Math.floor((totalRating / raters) * 10) / 10 : 0;
};

export default averageRating;
