import { Box } from '@mui/material';
import { IsYoursType } from '../../misc/isThisYours';
import { EntityRecord } from '@petconsole/pure-shared';

interface EntityCardYoursProps<T extends EntityRecord = EntityRecord> {
  entityRecord: T;
  disabled?: boolean;
  IsYours: IsYoursType<T>;
}

const EntityCardYours = <T extends EntityRecord = EntityRecord>({ entityRecord, disabled, IsYours }: EntityCardYoursProps<T>) => {
  return (
    <Box display="flex" justifyContent="center">
      <IsYours entityRecord={entityRecord} disabled={disabled} />
    </Box>
  );
};

EntityCardYours.whyDidYouRender = true;

export default EntityCardYours;
