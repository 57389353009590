import { CityLocation, RecordType } from '@petconsole/pure-base';
import { ourFetchStatus, ourSaveStatus } from '../../constants';
import { AnyAsyncThunk, Slice, StateType, ThunkFulfilledMethod } from '../../types';
import thunkPendingReducer from './thunkPendingReducer';
import thunkRejectedReducer from './thunkRejectedReducer';

type CreateEntityReducers = <T extends RecordType = RecordType>(
  slice: Slice<T>,
  { pending, fulfilled, rejected }: AnyAsyncThunk,
) => RecordType;

const createEntityReducers: CreateEntityReducers = (slice, { pending, fulfilled, rejected }) => {
  const { name: sliceName, proper } = slice;
  const statusProperty = 'writeStatus';

  const fulfilledReducer: ThunkFulfilledMethod<StateType, RecordType> = (state, action) => {
    const payload = action.payload;

    state[sliceName][statusProperty] = ourSaveStatus.succeeded;

    if (payload) {
      slice.slivers.forEach((sliverName) => {
        if (sliverName !== sliceName && state[sliverName].readStatus !== ourFetchStatus.succeeded)
          return;

        if (sliverName === `city${proper}`) {
          const { [`${sliceName}CitySearchLocation`]: citySearchLocation } = state;

          if (!citySearchLocation) return;

          const { city, province, country } = citySearchLocation;

          if (
            (payload as CityLocation).city?.toLowerCase() !== city.toLowerCase() ||
            (payload as CityLocation).province?.toLowerCase() !== province.toLowerCase() ||
            (payload as CityLocation).country?.toLowerCase() !== country.toLowerCase()
          )
            return;
        }

        const { adapter } = slice.sliver[sliverName];

        adapter.upsertOne(state[sliverName], payload);
      });
    }

    if (slice.option.afterCreate) slice.option.afterCreate(state, action);
  };

  return {
    [String(pending)]: thunkPendingReducer(sliceName, statusProperty),
    [String(fulfilled)]: fulfilledReducer,
    [String(rejected)]: thunkRejectedReducer(sliceName, statusProperty),
  };
};

export default createEntityReducers;
