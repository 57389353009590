import { OurTikTokIcon as LibTikTokIcon } from '@petconsole/pure-fe-icon';
import OurExternalIcon from '../icons/OurExternalIcon';

interface OurTikTokIconProps {
  href?: string;
  account?: string;
}

const OurTikTokIcon = ({ href, account = 'petconsole' }: OurTikTokIconProps) => (
  <OurExternalIcon icon={<LibTikTokIcon />} href={href || `https://www.tiktok.com/@${account}`} aria="Tik Tok" />
);

OurTikTokIcon.whyDidYouRender = true;

export default OurTikTokIcon;
