import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import { MemberRecord } from '@petconsole/pure-shared';
import { MemberAvatar } from '../../../types';
import BluePerson from './BluePerson.svg';

type MemberToAvatarType = (member: MemberRecord) => MemberAvatar;

const memberToAvatar: MemberToAvatarType = (member) => {
  if (!member) return {};

  const { memberName, fullName, email, avatar } = member;
  const text = ((memberName || fullName || email) as string).substring(0, 1);

  return { text, src: avatar ? ourMemberImageUrl(avatar) : BluePerson };
};

export default memberToAvatar;
