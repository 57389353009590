import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  GetListOptions,
  GetListResults,
  MailHeaderRecord,
  mailHeaderEntity,
  ApiEntity,
} from '@petconsole/pure-shared';
import { commonApi } from '../common';

type ApiRecord = MailHeaderRecord;

const apiPath = '/mail/headers';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getListByRecipientFolder: (folderId: string, options: GetListOptions) => Promise<GetListResults<T>>;
  getListBySenderFolder: (folderId: string, options: GetListOptions) => Promise<GetListResults<T>>;
}

export type MailHeaderApi = Api<ApiRecord> & CustomApi;

export const mailHeaderApi: MailHeaderApi = {
  ...common,
  getListByValue,
  getListByRecipientFolder: (folderId, options) => getListByValue('recipientFolderId', folderId, options),
  getListBySenderFolder: async (folderId, options) => getListByValue('senderFolderId', folderId, options),
};

export const mailHeaderApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...mailHeaderEntity, api: mailHeaderApi };
