import { initialReadWriteState } from '../sliceHelpers';
import { StateType } from '../../types';

const resetReadNext = (stateProperty: string) => ({
  reducer(state: Record<string, StateType>) {
    state[stateProperty].nextKey = initialReadWriteState.nextKey;
  },
});

export default resetReadNext;
