import { RecordType } from '@petconsole/pure-base';
import { apiMethodType, EntityType, GetListByIdMethod, GetListResults } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../types';

const getListBy = <T extends RecordType = RecordType>(builder: ApiBuilder<T>, method: GetListByIdMethod<T>, tag: EntityType, extraOptions?: RecordType) =>
  builder.query<GetListResults<T>, string>({
    query: (id) => ({ type: apiMethodType.getListById, method, id }),
    providesTags: [tag],
    extraOptions,
  });

export default getListBy;
