import { CardContent } from '@mui/material';
import OurCount from '../misc/OurCount';

interface OurPostCardCountsProps {
  likes?: number;
  comments?: number;
  replies?: number;
  sends?: number;
  shares?: number;
}

const OurPostCardCounts = ({ likes, comments, replies, sends, shares }: OurPostCardCountsProps) => (
  <CardContent
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      pt: 1,
      pl: 1,
      pr: 0.5,
      pb: 0,
      mb: -0.5,
    }}
  >
    <OurCount count={likes} name="Like" single="like" plural="likes" />
    {comments !== undefined ? (
      <OurCount count={comments} name="Comment" single="comment" plural="comments" />
    ) : (
      <OurCount count={replies} name="Reply" single="reply" plural="replies" />
    )}
    <span />
    <OurCount count={sends} name="Send" single="sent" plural="sent" />
    <OurCount count={shares} name="Share" single="share" plural="shares" />
  </CardContent>
);

OurPostCardCounts.whyDidYouRender = true;

export default OurPostCardCounts;
