import { useCallback } from 'react';
import { webPath } from '@petconsole/pure-shared';
import useOurNavigate from '../router/useOurNavigate';
import useOurRedirectPath from './useOurRedirectPath';
import useOurLog from '../log/useOurLog';

interface UseOurOnClickRedirectProps {
  path: string;
}

const useOurOnClickRedirect = ({ path }: UseOurOnClickRedirectProps) => {
  const { log } = useOurLog()
  const redirectPath = useOurRedirectPath({ path });
  const { navigate, pathname, search } = useOurNavigate();

  if (path !== webPath.join && path !== webPath.login) throw log(new Error(`Invalid OnClickRedirect path ${path}`));

  const onClick = useCallback (() => {
    if (pathname === webPath.logout) {
      navigate(path);
      return;
    }

    const searchParams = new URLSearchParams({ redirectTo: pathname, ...(search && { redirectWith: search }) });

    navigate({ pathname: path, search: searchParams.toString() });
  }, [redirectPath, navigate, path, pathname, search]);

  return { onClick };
};

export default useOurOnClickRedirect;
