import { forumApiEntity } from '@petconsole/pure-fe-api';
import { ForumRecord } from '@petconsole/pure-shared';
import { fetchReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick, RecordType, ThunkApi } from '../../../types';

const { api, name: sliceName, proper } = forumApiEntity;
const toForumEntityName = `toForum${proper}`;

export const fetchToForumParent = (forum: RecordType, state: RecordType, dispatch: ThunkApi['dispatch']) => {
  const parts = forum.hierarchy.split('-');

  if (parts.length > 1) dispatch(fetchToForumForumsToHierarchyThunk(parts.slice(0, -1).join('-'))).then();

  // Avoid returning a different object that might trigger refreshes
  return state.toForumForum.entities[forum.forumId] || forum;
};

export const fetchToForumForumsToHierarchyThunk = appThunk(
  `${sliceName}/fetchToForum${proper}sToHierarchy`,
  async (hierarchy: string, { getState, dispatch }) => {
    const forum = (await api.getByHierarchy(hierarchy)) as ForumRecord;

    const { forum: state } = getState();

    return fetchToForumParent(forum, state, dispatch);
  },
);

export const fetchToForumForumsToHierarchyReducers = (adapter: EntityAdapterWithPick) =>
  fetchReducers(fetchToForumForumsToHierarchyThunk, toForumEntityName, adapter);
