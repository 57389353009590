// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import { defaultAppRate, defaultOrderNumberPattern, ourDefaultCurrencyCode, site } from '../../../constants';
import { entityCategory, entityName } from '../../../entity/constants';
import entityAttributes from '../../../entity/entityAttributes';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import {
  DataEntityType,
  InitialEntity,
  MaybeNull,
  OurCurrencyCode,
  RatingBusinessColumns,
  ShopShipRates,
  SubscribeColumns,
} from '../../../types';

export interface Shop extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  shopId: string;
  site: string;
  siteId: string;
  shopName: string;
  legalName: string;
  shortName: string;
  businessType: string;
  shipRates: ShopShipRates;
  published: boolean;
  appRate: number;
  nextOrderNumber: number;
  orderNumberPattern: string;
  currency: OurCurrencyCode;
}

export const shopEntity = entityAttributes({
  name: entityName.shop,
  attributes: {
    category: entityCategory.shop,
    columns: { address: true, business: true, rating: true, site: true, socials: true, subscribe: true },
    loadLimit: 25,
    maxImages: 8,
    nameField: 'shopName',
    // memberOwned: true,
    subscribable: true,
    test: { invalidData: () => ({ legalName: {} }), validData: () => ({ legalName: 'New Name' }) },
  },
});

const { boolean, currency, id, number, oneOf, schemaObject, strim } = schema;
export const shopSchema = entitySchema<Shop>(shopEntity, {
  shopName: strim().required(),
  legalName: strim(), // if applicable
  shortName: strim(), // if applicable
  businessType: strim(), // retail, service, org, dog park, free-form
  shipRates: schemaObject({
    free: boolean(),
    minimum: number(),
    base: number(),
    item: number(),
  }),
  site: oneOf({ ...site, admin: 'admin' }, site.petConsole),
  siteId: id(), // is populated on save
  published: boolean(),
  appRate: number(defaultAppRate),
  nextOrderNumber: number(1),
  orderNumberPattern: strim().required().default(defaultOrderNumberPattern),
  currency: currency(),
  // Later?: GST Number
});

export const shopDefaults: Partial<Shop> = {
  site: '',
  siteId: '',
  shopName: '',
  legalName: '',
  shortName: '',
  businessType: '',
  shipRates: { free: false, minimum: 0, base: 0, item: 0 },
  published: false,
  nextOrderNumber: 1,
  orderNumberPattern: defaultOrderNumberPattern,
  currency: ourDefaultCurrencyCode,
};

export interface ShopRecord extends Shop, RecordType {}

export type MaybeShopRecord = MaybeNull<ShopRecord>;
export type ShopRecords = ShopRecord[];

export const initialShop: InitialEntity<Shop, ShopRecord> = (overrides = {}) =>
  initialEntity<Shop>(shopEntity, { ...shopDefaults, ...overrides }) as ShopRecord;
