import { List } from '@mui/material';
import { OurLogoutIcon } from '@petconsole/pure-fe-icon';
import { webPath } from '@petconsole/pure-shared';
import OurAppMenuItem from './OurAppMenuItem';

const OurLogoutMenuItem = () => {
  return (
    <List disablePadding>
      <OurAppMenuItem item={[OurLogoutIcon, 'Logout', webPath.logout]} />
    </List>
  );
};

OurLogoutMenuItem.whyDidYouRender = true;

export default OurLogoutMenuItem;
