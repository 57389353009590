import { RecordType } from '@petconsole/pure-base';
import { Api, EntityName, EntityType } from '@petconsole/pure-shared';
import { Slice } from '../../types';
import ourToggleLikedThunk from '../reactions/ourToggleLikedThunk';
import { fetchReactionThunk, updateReactionThunk } from '../thunks';
import { reactToEntityThunk } from '../thunks/reactToEntityThunk';

export const sliceReactionThunks = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  const { name, proper, idName, api } = slice;

  return {
    fetchReaction: fetchReactionThunk(name, proper as EntityType, idName, api.reaction),
    updateReaction: updateReactionThunk(name, proper, idName, api.reaction),
    reactToEntity: reactToEntityThunk({ sliceName: name, api: api.entity as Api }),
    toggleLike: ourToggleLikedThunk({ entityName: name as EntityName }),
  };
};
