import { useCallback } from 'react';
import { Api, EntityReaction } from '@petconsole/pure-shared';
import { useAppDispatch } from '../../store/storeHooks';
import { reactToEntityThunk } from '../thunks/reactToEntityThunk';
import { RecordType } from '@petconsole/pure-base';

export const useDispatchReactToEntity = <T extends RecordType = RecordType>(sliceName: string, api: Api<T>, reaction?: EntityReaction) => {
  const dispatch = useAppDispatch();
  const reactToEntity = reactToEntityThunk({ sliceName, api, reaction });
  const callback = (id: string) => dispatch(reactToEntity({ id }));

  return useCallback(callback, []);
};
