import { ReactNode, useCallback, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { MaybeNull } from '@petconsole/pure-base';
import { HelpProps } from '@petconsole/pure-shared';
import OurBackButton from '../buttons/OurBackButton';
import OurHelpButton from '../buttons/OurHelpButton';
import { OurMarkdownHelp } from '../dialogs';
import OurHeadings, { OurHeadingsProps } from './OurHeadings';

interface OurBackHeadingsProps extends Omit<OurHeadingsProps, 'contents'> {
  gridItem?: boolean;
  heading: ReactNode;
  right?: ReactNode;
  help?: MaybeNull<typeof OurMarkdownHelp>;
  helpProps?: Partial<HelpProps>;
}

const OurBackHeadings = ({
  gridItem = true,
  heading,
  right = null,
  help,
  helpProps,
  ...rest
}: OurBackHeadingsProps) => {
  const [helpOpen, setHelpOpen] = useState(false);

  const onClick = useCallback(() => setHelpOpen(true), []);

  const leftHeading = (
    <Box display="flex">
      <OurBackButton />
      {(help) && <OurHelpButton onClick={onClick} />}
    </Box>
  );

  const helper = () => {
    if (!help || !helpProps) return null;

    const { title = '', content = '' } = helpProps;
    const Help = help;

    return <Help title={title} content={content} isOpen={helpOpen} setOpen={setHelpOpen} />;
  };

  return (
    <>
      {gridItem ? (
        <Grid id="grid-item-headings" item xs={12}>
          <OurHeadings contents={[leftHeading, heading, right]} {...rest} />
        </Grid>
      ) : (
        <OurHeadings contents={[leftHeading, heading, right]} {...rest} />
      )}
      {helpOpen && helper()}
    </>
  );
};

OurBackHeadings.whyDidYouRender = true;

export default OurBackHeadings;
