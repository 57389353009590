import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  ApiEntity,
  GetListOptions,
  GetListResults,
  orderLineEntity,
  OrderLineRecord,
} from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = OrderLineRecord;

const apiPath = '/orders/orderLines';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getListByOrder: (orderId: string, options?: GetListOptions) => Promise<GetListResults<T>>;
}

export type OrderLineApi = Api<ApiRecord> & CustomApi;

export const orderLineApi: OrderLineApi = {
  ...common,
  getListByValue,
  getListByOrder: (orderId, options) => getListByValue('orderId', orderId, options),
};

export const orderLineApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...orderLineEntity, api: orderLineApi };
