import { ChangeEventHandler } from 'react';
import { SxProps } from '@mui/material';
import { ExtendedFormik, OnBlur, OurSelectOption } from '@petconsole/pure-shared';
import { OurSelectOptionObject } from '../../../types';
import OurText from '../OurText';

export interface OurSelectProps {
  selectOptions: Array<string | OurSelectOptionObject>;
  readOnly?: boolean;
  formik?: ExtendedFormik;
  id: string;
  label?: string;
  onChange?: ChangeEventHandler<{ value: string | OurSelectOption}>;
  value?: string;
  required?: boolean;
  onBlur?: OnBlur;
  sx?: SxProps;
}

const OurSelect = ({ selectOptions, ...rest }: OurSelectProps) => {
  const { readOnly, formik = {} as ExtendedFormik } = rest;

  // Returning OurText is a workaround as MUI's select mode doesn't seem to support readOnly
  if (readOnly || formik.isReadOnly) {
    if (typeof selectOptions[0] === 'object') {
      const value = rest.value || formik.values?.[rest.id] ;
      const name = (selectOptions as OurSelectOptionObject[]).find((option) => option.value === value)?.name || '';

      return <OurText {...rest} value={name} />;
    } else return <OurText {...rest} />;
  }

  const options = selectOptions.map((option) => {
    if (typeof option === 'object') {
      const { name, value } = option;

      return (
        <option key={value.toString()} value={value.toString()}>
          {name}
        </option>
      );
    }

    return (
      <option key={option} value={option}>
        {option}
      </option>
    );
  });

  return (
    <OurText select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }} {...rest}>
      {options}
    </OurText>
  );
};

export default OurSelect;
