import { RecordType } from '@petconsole/pure-base';
import slicePropertyHooks from './slicePropertyHooks';

const sliceSuffixHooks = (sliceName: string, suffix: string, actions: RecordType) => {
  const propertyName = `${sliceName}${suffix}`;

  return slicePropertyHooks(sliceName, propertyName, actions);
};

export default sliceSuffixHooks;
