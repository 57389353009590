import LazyLoad from '../../lazy/LazyLoad';

const OurMarkdownHelp = LazyLoad(() => import('./MarkdownHelp'));

export { OurMarkdownHelp };

export * from './OurAddEntityDialog';
export { default as OurAvatarDialog } from './OurAvatarDialog';
export { type OurAvatarDialogOnOkProps } from './OurAvatarDialog';
export { default as OurConfirmDeleteDialog } from './OurConfirmDeleteDialog';
export { default as OurDraggableDialog } from './OurDraggableDialog';
export { default as OurOkDialog } from './OurOkDialog';
