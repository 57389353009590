import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { Api, EntityType } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../types';

const getOne = <T extends RecordType = RecordType>(builder: ApiBuilder<T>, api: Api<T>, tag: EntityType) =>
  builder.query<MaybeNull<T>, string>({
    query: (id) => ({ method: api.get, id }),
    providesTags: (_result, _error, id) => [tag, { type: tag, id }],
  });

export default getOne;
