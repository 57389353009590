import { RecordType } from '@petconsole/pure-base';
import { AnyAsyncThunk, OtherReducersBuilder, Slice } from '../../types';
import { addReducerCases } from '../reducers';
import {
  createEntityReducers,
  deleteEntityReducers,
  fetchReactionReducers,
  fetchReducers,
  ratingFetchReducersById,
  ratingWriteReducers,
  updateEntityReducers,
  writeReactionReducers,
  writeReducers,
} from '../thunkReducers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sliceAddReducerCases = <T extends RecordType = RecordType>(builder: OtherReducersBuilder, slice: Slice<T>) => {
  const { name, flag, option, slivers, sliver, thunk } = slice;
  const { createEntity, deleteEntity, updateEntity, fetchEntityByUrlOrId } = thunk;
  const { fetchRating, updateRating } = thunk;
  const { fetchReaction, reactToEntity, toggleLike, updateReaction } = thunk;
  const { fetchOneByProperty: fetchOne, fetchManyByProperty: fetchMany } = option;
  const sliceAdapter = sliver[name].adapter;

  slivers.forEach((key) => {
    const { fetchOneReducers, fetchManyReducers, extraReducers, sliceReducers, adapter } =
      sliver[key];

    if (fetchOneReducers) addReducerCases(builder, fetchOneReducers);
    if (fetchManyReducers) addReducerCases(builder, fetchManyReducers);

    if (extraReducers) {
      extraReducers.forEach((reducers) => addReducerCases(builder, reducers(adapter)));
    }

    if (sliceReducers) {
      sliceReducers.forEach((reducers) => addReducerCases(builder, reducers(slice)));
    }
  });

  if (createEntity) addReducerCases(builder, createEntityReducers(slice, createEntity));
  if (deleteEntity) addReducerCases(builder, deleteEntityReducers(slice, deleteEntity));
  if (updateEntity) addReducerCases(builder, updateEntityReducers(slice, updateEntity));
  if (fetchRating) addReducerCases(builder, ratingFetchReducersById(fetchRating, name));
  if (updateRating) addReducerCases(builder, ratingWriteReducers(updateRating, name));
  if (fetchReaction) addReducerCases(builder, fetchReactionReducers(fetchReaction, name));
  if (reactToEntity) addReducerCases(builder, writeReducers(reactToEntity, name, sliceAdapter));
  if (toggleLike) addReducerCases(builder, writeReactionReducers(toggleLike, name));
  if (updateReaction) addReducerCases(builder, writeReactionReducers(updateReaction, name));
  if (fetchOne)
    addReducerCases(builder, fetchReducers(<AnyAsyncThunk>fetchOne.thunk, name, sliceAdapter));
  if (fetchMany)
    addReducerCases(builder, fetchReducers(<AnyAsyncThunk>fetchMany.thunk, name, sliceAdapter));
  if (flag.hasUrlName)
    addReducerCases(
      builder,
      fetchReducers(<AnyAsyncThunk>fetchEntityByUrlOrId, name, sliceAdapter),
    );
};

export default sliceAddReducerCases;
