import { memo } from 'react';
import { RecordType } from '@petconsole/pure-base';
import { RequiredCrudSliverHooks } from '@petconsole/pure-fe-state';
import useOurAddListEntity from '../../entity/helpers/useOurAddListEntity';
import useOurOnOpen from '../../hooks/useOurOnOpen';
import { UseMaintAddProps } from '../types';

const useMaintAdd = <T extends RecordType = RecordType>({ entity, hooks, add, Add, addProps }: UseMaintAddProps<T>) => {
  const { isOpen, setIsOpen, onOpen } = useOurOnOpen();

  const crudHooks = hooks[`${entity.name}Hooks`] as RequiredCrudSliverHooks;
  const { AddListEntity } = useOurAddListEntity<T>({ entity, crudHooks, addProps: addProps || undefined });

  const AddEntity = memo(() => {
    if (Add) return <Add isOpen={isOpen} setIsOpen={setIsOpen} />;
    if (add) return <AddListEntity isOpen={isOpen} setIsOpen={setIsOpen} />;

    return null;
  });

  return { Add: AddEntity, addIsOpen: isOpen, setAddIsOpen: setIsOpen, onAdd: onOpen };
};

export default useMaintAdd;
