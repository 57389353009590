import { ComponentType, ElementType, memo, MutableRefObject, PropsWithChildren, ReactElement } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { RecordType } from '@petconsole/pure-base';
import {
  EditMessages,
  EntityAttributes,
  EntityFormik,
  ExtendedFormik,
  Images,
  ReturnsVoid,
  SetState,
} from '@petconsole/pure-shared';
import OurEditMessages from '../../components/misc/editMessage/OurEditMessages';
import useEditMessages from '../../components/misc/editMessage/useEditMessages';
import OurOutlinedFormikErrors from '../../components/misc/formik/OurOutlinedFormikErrors';
import useOurGridCardCenterSizes from '../../hooks/useOurGridCardCenterSizes';
import { MaybeBreakpoints } from '../../types';
import EntityMaintenanceContent from './EntityMaintenanceContent';
import EntityMaintenanceMargin from './EntityMaintenanceMargin';
import EntityMaintenanceHeadings from './EntityMaintenanceHeadings';

interface EntityMaintenanceCardProps<T extends RecordType = RecordType> extends PropsWithChildren {
  entity: EntityAttributes;
  formik: EntityFormik<T> | ExtendedFormik;
  padding?: number;
  marginColumns?: boolean;
  margins?: MaybeBreakpoints;
  leftMargins?: MaybeBreakpoints;
  rightMargins?: MaybeBreakpoints;
  LeftContent?: ComponentType;
  RightContent?: ComponentType;
  helpTitle?: string;
  helpContent?: string;
  Heading?: ComponentType;
  Toolbar: ComponentType;
  submitting: boolean;
  setSubmitting: SetState<boolean>;
  editMessages?: EditMessages;
  Main: ElementType;
  Add?: ComponentType;
  addIsOpen: boolean;
  setAddIsOpen: SetState<boolean>;
  owned?: boolean;
  readOnly?: boolean;
  directions?: boolean;
  initializeState?: ReturnsVoid;
  addressInput: string;
  setAddressInput: SetState<string>;
  avatarEditor: MutableRefObject<AvatarEditor | undefined>;
  avatar: string;
  setAvatar: SetState<string>;
  setAvatarChanged: SetState<boolean>;
  images: Images;
  setImages: SetState<Images>;
  imageZone: ReactElement;
  busy?: boolean;
  setBusy: SetState<boolean>;
}

const EntityMaintenanceCard = <T extends RecordType = RecordType>({
  entity,
  formik,
  padding,
  marginColumns,
  margins = { md: 1, lg: 2, xl: 3 },
  leftMargins,
  rightMargins,
  LeftContent,
  RightContent,
  helpTitle,
  helpContent,
  Heading,
  Toolbar,
  submitting,
  setSubmitting,
  editMessages,
  Main,
  Add,
  addIsOpen,
  setAddIsOpen,
  owned,
  readOnly,
  directions,
  initializeState,
  addressInput,
  setAddressInput,
  avatarEditor,
  avatar,
  setAvatar,
  setAvatarChanged,
  images,
  setImages,
  imageZone,
  busy,
  setBusy,
  children,
}: EntityMaintenanceCardProps<T>) => {
  const { dirty, isSubmitting, handleSubmit: onSubmit } = formik;

  const { editMessages: editingMessages } = useEditMessages({
    isEditing: !readOnly,
    isDirty: dirty,
    isSaving: isSubmitting,
    messages: editMessages,
  });

  const leftGridMargins = leftMargins || margins;
  const rightGridMargins = rightMargins || margins;

  const { centerSizes } = useOurGridCardCenterSizes({
    marginColumns,
    leftMargins,
    margins,
    rightMargins,
  });

  const Headings = memo(() => (
    <EntityMaintenanceHeadings helpTitle={helpTitle} helpContent={helpContent} Heading={Heading} Toolbar={Toolbar} />
  ));

  const EntityErrorMessages = memo(() => (
    <OurOutlinedFormikErrors formik={formik as ExtendedFormik} submitting={submitting} setSubmitting={setSubmitting} />
  ));

  const EntityEditMessages = memo(() => <OurEditMessages messages={editingMessages} />);

  const AddDialog = memo(() => (Add ? <Add /> : null));

  return (
    <Grid container>
      {leftGridMargins && <EntityMaintenanceMargin margin={leftGridMargins} Content={LeftContent} />}
      <EntityMaintenanceContent
        entity={entity}
        formik={formik as ExtendedFormik}
        padding={padding}
        initializeState={initializeState as ReturnsVoid}
        addressInput={addressInput}
        setAddressInput={setAddressInput}
        avatarEditor={avatarEditor}
        avatar={avatar}
        setAvatar={setAvatar}
        setAvatarChanged={setAvatarChanged}
        images={images}
        setImages={setImages}
        imageZone={imageZone}
        owned={owned}
        readOnly={readOnly}
        directions={directions}
        Headings={Heading ? Headings : Heading}
        Main={Main}
        EntityErrorMessages={EntityErrorMessages}
        EntityEditMessages={EntityEditMessages}
        onSubmit={onSubmit}
        busy={busy}
        setBusy={setBusy}
        setAddIsOpen={setAddIsOpen}
        breakpoints={centerSizes}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
      </EntityMaintenanceContent>
      {rightGridMargins && <EntityMaintenanceMargin margin={rightGridMargins} Content={RightContent} />}
      {addIsOpen && <AddDialog />}
    </Grid>
  );
};

EntityMaintenanceCard.whyDidYouRender = true;

export default EntityMaintenanceCard;
