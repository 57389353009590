// same configuration you would create for the Rollbar.js SDK
const ourRollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_STAGE === 'prod' ? 'production' : process.env.REACT_APP_STAGE,
  // async: false,
  captureUncaught: true,
  captureUnhandledRejections: true,
  // verbose: true,
};

export default ourRollbarConfig;
