import React, { MouseEvent, MouseEventHandler, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Accept } from 'react-dropzone';
import { DialogActions, DialogContent, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OurAvatarEdit from '../avatar/OurAvatarEdit';
import OurButton from '../buttons/OurButton';
import OurDraggableDialog from './OurDraggableDialog';

export interface OurAvatarDialogOnOkProps {
  e: MouseEvent<HTMLButtonElement>;
  src: string;
  changed: boolean;
}

interface OurAvatarDialogProps {
  isOpen: boolean;
  title?: string;
  header?: string;
  image: string;
  width?: number;
  borderRadius?: number;
  height?: number;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onOk: (props: OurAvatarDialogOnOkProps) => void;
  accept?: Accept;
}

const OurAvatarDialog = ({
  isOpen,
  title = 'Avatar Editor',
  header,
  image,
  width = 200,
  height = 200,
  borderRadius,
  onCancel,
  onOk,
  accept = { 'image/*': ['.jpeg', '.png'] },
}: OurAvatarDialogProps) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const [editor, setEditor] = useState(null as unknown);
  const [changed, setChanged] = useState(false);

  const onCancelClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onCancel(event);
  };

  const onOkClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onOk({
      e: event,
      src: changed && editor instanceof AvatarEditor ? editor.getImageScaledToCanvas().toDataURL() : image,
      changed,
    });
  };

  return (
    <OurDraggableDialog title={title} isOpen={isOpen} onClose={onCancel} draggable={!xs}>
      <DialogContent>
        <Grid container spacing={2} margin={0} justifyContent="center">
          {header && <Typography>{header}</Typography>}
          <Grid container pt={2}>
            <OurAvatarEdit
              avatar={image}
              setEditor={setEditor}
              setChanged={setChanged}
              width={width}
              height={height}
              borderRadius={borderRadius}
              accept={accept}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <OurButton label="Cancel" color="secondary" onClick={onCancelClick} autoFocus />
        <OurButton label="Ok" onClick={onOkClick} />
      </DialogActions>
    </OurDraggableDialog>
  );
};

export default OurAvatarDialog;
