import doWork from './doWork';
import doWorkCatch from './doWorkCatch';
import useOurSnacks from '../../hooks/useOurSnacks';
import useOurError from '../../error/useOurError';

const useDoWork = () => {
  const { warn } = useOurSnacks();
  const { log } = useOurError();

  const workCatch = (e: unknown, activity: string) => doWorkCatch({ e, activity, warn, log });

  return { doWork, workCatch }
};

export default useDoWork;
