import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

const useOurSnacks = () => {
  const { enqueueSnackbar } = useSnackbar();

  const error = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  const info = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'info' });
    },
    [enqueueSnackbar],
  );

  const success = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar],
  );

  const warn = useCallback(
    (message: string, logged = true) => {
      enqueueSnackbar(logged ? `${message} Error is being logged and reported.` : message, {
        variant: 'warning',
      });
    },
    [enqueueSnackbar],
  );

  const warnDataChanged = useCallback(
    () => warn('Data changed since you read it. Refresh and try again', false),
    [warn],
  );

  const warning = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'warning' });
    },
    [enqueueSnackbar],
  );

  const warnWithConflictCheck = useCallback(
    (e: unknown) => {
      const message = e instanceof Error ? e.message : '';

      if (message.includes('409')) warnDataChanged();
      else warn(message);
    },
    [warnDataChanged, warn],
  );

  return { error, info, success, warn, warnDataChanged, warning, warnWithConflictCheck };
};

export default useOurSnacks;
