import { RatingHooks } from '@petconsole/pure-fe-state';
import { defaultRatingValues, EntityRecord, RatingValues } from '@petconsole/pure-shared';
import useOurAuthenticated from '../../../../auth/useOurAuthenticated';

interface UseEntityCardRatingProps<T extends EntityRecord = EntityRecord> {
  ratingHooks?: RatingHooks;
  preview?: boolean;
  displaySamples?: boolean;
  sample: T;
  cardId: string;
  entityRecord: T;
}

const useEntityCardRating = <T extends EntityRecord = EntityRecord>({
  ratingHooks,
  preview,
  displaySamples,
  sample,
  cardId,
  entityRecord,
}: UseEntityCardRatingProps<T>) => {
  const { authenticated } = useOurAuthenticated();
  const entityRating = ratingHooks ? ratingHooks.useSelect(cardId) : null;
  const updateRating = ratingHooks ? ratingHooks.useUpdate() : null;

  const rating = {
    show: !!ratingHooks,
    ratings: displaySamples ? (sample.ratings as RatingValues) : entityRecord.ratings || defaultRatingValues,
    yourRating: displaySamples ? (sample.rating as number) : entityRating?.rating || 0,
    update: updateRating,
    id: cardId,
    readOnly: preview || !authenticated,
  };

  return { rating };
};

export default useEntityCardRating;
