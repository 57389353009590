import { MouseEventHandler } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';

interface MyShopMenuItemProps {
  Icon: null | typeof SvgIcon;
  text: string;
  onClick?: MouseEventHandler;
}

const MyShopMenuItem = ({ Icon, text, onClick }: MyShopMenuItemProps) => {
  return (
    <ListItemButton onClick={onClick}>
      {Icon && (
        <ListItemIcon sx={{ minWidth: 48 }}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

MyShopMenuItem.whyDidYouRender = true;

export default MyShopMenuItem;
