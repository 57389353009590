import { Skeleton, Typography } from '@mui/material';
import { PetRecord } from '@petconsole/pure-shared';

interface EntityCardPetTitleProps {
  pet: PetRecord;
}

const EntityCardPetTitle = ({ pet }: EntityCardPetTitleProps) => {
  const { petName = '', gender, petType } = pet || {};
  const sex = ['Male', 'Female'].includes(gender) ? gender : '';
  const type = petType === 'Other' ? '' : petType;
  const petDescription = `${sex} ${type || 'Other'}`.trim();

  return (
    <>
      <Typography color="inherit" component="span">
        {petName || <Skeleton />}
      </Typography>
      {petName && (
        <Typography variant="body2" color="textPrimary" component="span">
          {` (${petDescription})`}
        </Typography>
      )}
    </>
  );
};

EntityCardPetTitle.whyDidYouRender = true;

export default EntityCardPetTitle;
