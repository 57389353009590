// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName, entityType } from '../../../entity/constants';
import entityAttributes from '../../../entity/entityAttributes';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { InitialEntity } from '../../../types';
import { mailContentDefaults, MailContentRecord, mailContentSchema } from './mailContent';
import { mailHeaderDefaults, MailHeaderRecord, mailHeaderSchema } from './mailHeader';

export interface MailMessage extends MailHeaderRecord, MailContentRecord {}

export const mailMessageEntity = entityAttributes({
  name: entityName.mailMessage,
  attributes: {
    category: entityCategory.item,
    columns: { crud: false, images: false, json: false },
    idName: 'messageId',
    maxImages: 2,
    nameField: undefined,
    ownerIfEditing: true,
    test: { invalidData: () => ({ subject: {} }), validData: () => ({ subject: 'New subject' }) },
  },
});

const { type } = schema;
export const mailMessageSchema = entitySchema<MailMessage>(mailMessageEntity, {
  ...mailHeaderSchema,
  ...mailContentSchema,
  type: type(mailMessageEntity.type)
});

export const mailMessageDefaults: Partial<MailMessage> = {
  ...mailHeaderDefaults,
  ...mailContentDefaults,
  type: entityType.MailMessage,
};

export interface MailMessageRecord extends MailMessage, RecordType {}

export type MaybeMailMessageRecord = MaybeNull<MailMessageRecord>;
export type MailMessageRecords = MailMessageRecord[];

export const initialMailMessage: InitialEntity<MailMessage, MailMessageRecord> = (overrides = {}) =>
  initialEntity<MailMessage>(mailMessageEntity, { ...mailMessageDefaults, ...overrides }) as MailMessageRecord;
