import { SxProps } from '@mui/material';
import { webPath } from '@petconsole/pure-shared';
import OurCardImage from '../cards/OurCardImage';

interface OurMediaImageProps {
  image: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  sx?: SxProps;
}

const OurMediaImage = ({ image, alt, width, height, sx }: OurMediaImageProps) => (
  <OurCardImage
    image={image.startsWith('http') ? image : `${webPath.media}/${image}`}
    alt={alt}
    width={width}
    height={height}
    sx={sx}
  />
);

OurMediaImage.whyDidYouRender = true;

export default OurMediaImage;
