import { ElementType, useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { useOurAuthenticating, useOurAuthHandler } from '@petconsole/pure-fe-amplify';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { useOurLogMember, useOurLoginRedirect } from '@petconsole/pure-fe-utils';
import useOurAuthCheck from '../../../auth/useOurAuthCheck';
import useOurAuthenticated from '../../../auth/useOurAuthenticated';
import OurErrorBoundary from '../../../error/OurErrorBoundary';
import { MemberAvatarMenuItems } from '../../../types';
import OurFooter from '../../misc/footer/OurFooter';
import OurAppContent from './OurAppContent';

interface OurAppProps {
  AppRoutes: ElementType;
  GuestMenuItems?: ElementType;
  MemberMenuItems?: ElementType;
  avatarMenuItems?: MemberAvatarMenuItems;
  includeFooter?: boolean;
}

const OurApp = ({ GuestMenuItems, MemberMenuItems, avatarMenuItems, AppRoutes, includeFooter = true }: OurAppProps) => {
  const { memberId = '' } = useCurrentMember();
  const { authCheck } = useOurAuthCheck();
  const { authenticated } = useOurAuthenticated();
  const { authenticating } = useOurAuthenticating({ authCheck });

  const [errorRefresh, setErrorRefresh] = useState(false);

  useOurLogMember({ memberId });
  useOurAuthHandler({ authCheck });
  useOurLoginRedirect({ authenticated });

  useEffect(() => {
    if (errorRefresh) window.location.reload();
  }, [errorRefresh]);

  return (
    // Using Paper to ensure color gets set
    <OurErrorBoundary setErrorRefresh={setErrorRefresh}>
      {!authenticating && (
        <Grid container component={Paper} elevation={0} flexDirection="column">
          <OurAppContent
            AppRoutes={AppRoutes}
            GuestMenuItems={GuestMenuItems}
            MemberMenuItems={MemberMenuItems}
            avatarMenuItems={avatarMenuItems}
          />
          {includeFooter && <OurFooter />}
        </Grid>
      )}
    </OurErrorBoundary>
  );
};

OurApp.whyDidYouRender = true;

export default OurApp;
