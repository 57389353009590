import { RecordType } from '@petconsole/pure-base';
import { orderApiEntity } from '@petconsole/pure-fe-api';
import { OrderRecord } from '@petconsole/pure-shared';
import { fetchParams, sliceHookTypes, useOurDispatchValue } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  FetchByHook,
  OurEntityHooks,
  RequiredCrudSliverHooks,
  RequiredSliverByHooks,
  RequiredSliverHooks,
  TabValueHooks, ThunkApi,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import {
  fetchNextMyShopOrderReducers,
  fetchNextMyShopOrderThunk,
  fetchPrevMyShopOrderReducers,
  fetchPrevMyShopOrderThunk,
} from './fetchNextPrev';

type ApiEntityRecord = OrderRecord;

const apiEntity = orderApiEntity;
const { api, name: sliceName, proper, pluralProper } = apiEntity;
const myShop = 'myShop';
const myShopName = `${myShop}${proper}`;

const orderAdmin = `${sliceName}Admin`;
const myOrder = `my${proper}`;
const myOrders = `my${pluralProper}`;
const myShopOrder = `${myShop}${proper}`;
const myShopOrders = `${myShop}${pluralProper}`;

const Tab = { orderAdmin, myOrder, myOrders, myShopOrder, myShopOrders } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  orderAdmin: { default: 'Newest' },
  myOrder: { default: 'Summary' },
  myOrders: { default: 'My Orders' },
  myShopOrder: { default: 'Summary' },
  myShopOrders: { default: 'General' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: true,
  hasMySliver: true,
  hasNextPrev: true,
  hasDefaultProperties: true,
  comparer: ({ createdAt: a }: ApiEntityRecord, { createdAt: b }: ApiEntityRecord) => b?.localeCompare(a),
  tabs,
  slivers: [
    {
      prefix: myShop,
      payloadCreator: async ({ orderId }: ApiEntityRecord) => api.get(orderId),
      fetchesByValue: true,
      payloadCreators: async (shopId: string, { getState }: ThunkApi) =>
        api.getListByShop(shopId, fetchParams(getState, sliceName, myShopName)),
      extraReducers: [fetchNextMyShopOrderReducers, fetchPrevMyShopOrderReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, tabHooks } = sliceHookTypes;

export interface OurOrderHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  orderHooks: RequiredCrudSliverHooks;
  myOrderHooks: RequiredSliverHooks;
  myShopOrderHooks: RequiredSliverByHooks;
  newestOrderHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  tabHooks: TabHooks;
  customHooks: {
    myShopOrder: {
      useFetchNext: FetchByHook;
      useFetchPrev: FetchByHook;
    };
  };
}

export const ourOrderHooks: OurOrderHooks = {
  entity: apiEntity,
  orderHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  myOrderHooks: sliverHooks(apiEntity, 'My', hooks) as RequiredSliverHooks,
  myShopOrderHooks: sliverHooks(apiEntity, myShop, hooks) as RequiredSliverByHooks,
  newestOrderHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  propertyHooks: propertyHooks(apiEntity, hooks),
  tabHooks: tabHooks(Object.keys(Tab), hooks) as TabHooks,
  customHooks: {
    myShopOrder: {
      useFetchNext: () => useOurDispatchValue(fetchNextMyShopOrderThunk),
      useFetchPrev: () => useOurDispatchValue(fetchPrevMyShopOrderThunk),
    },
  },
};

export default reducer;
