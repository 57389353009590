import { RequiredCrudSliverHooks } from '@petconsole/pure-fe-state';
import { EntityAttributes, EntityRecord } from '@petconsole/pure-shared';
import OurAddEntity, { OurAddEntityProps } from '../components/misc/OurAddEntity';

export interface OurAddListEntityProps<T extends EntityRecord = EntityRecord>
  extends Omit<OurAddEntityProps<T>, 'create' | 'entity'> {
  path: string;
  entity: EntityAttributes;
  crudHooks: RequiredCrudSliverHooks;
}

const OurAddListEntity = <T extends EntityRecord = EntityRecord>({
  entity,
  crudHooks,
  addAddress,
  instructions,
  label,
  ...rest
}: OurAddListEntityProps<T>) => {
  const create = crudHooks.useCreate();

  const { addField, addInstructions, addLabel, addTitle, columns, name: entityName } = entity;
  const instruct = instructions || addInstructions;

  return (
    <OurAddEntity<T>
      entity={entityName}
      instructions={instruct}
      property={addField}
      addAddress={addAddress != null ? addAddress : columns.address}
      addressLabel="City/Address"
      label={label || addLabel}
      title={addTitle}
      create={(data) => create({ [entityName]: data }) as Promise<EntityRecord>}
      {...rest}
    />
  );
};

OurAddListEntity.whyDidYouRender = true;

export default OurAddListEntity;
