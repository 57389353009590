import { useEffect, useRef } from 'react';
import { ReturnsVoid } from '@petconsole/pure-shared';

export interface UseOurSafeEffectProps {
  action: (...args: unknown[]) => Promise<unknown>;
  args?: unknown[];
  process?: (result: unknown) => void;
  skip?: boolean;
  setState?: ReturnsVoid;
  unwrap?: (result: unknown) => unknown;
  final?: ReturnsVoid;
}

const useOurSafeEffect = ({ action, args = [], process, skip, setState, unwrap, final }: UseOurSafeEffectProps) => {
  const runningEffect = useRef(false);

  useEffect(() => {
    if (skip || runningEffect.current) return;

    runningEffect.current = true;

    if (setState) setState();

    action(...args)
      .then((response) => {
        const result = unwrap ? unwrap(response) : response;

        if (process) process(result);
      })
      .finally(() => {
        runningEffect.current = false;

        if (final) final();
      });
  }, [action, args, process, skip, setState, unwrap, final]);
};

export default useOurSafeEffect;
