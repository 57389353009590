import { ElementType } from 'react';
import { ourDefaultGuestDrawerWidth } from '../../../constants';
import OurAppMenu from '../appMenu/OurAppMenu';
import GuestMenuBar from './GuestMenuBar';

interface OurGuestAppProps {
  MenuItems: ElementType;
  drawerWidth?: number;
}

const OurGuestApp = ({ MenuItems, drawerWidth = ourDefaultGuestDrawerWidth }: OurGuestAppProps) => (
  <OurAppMenu MenuBar={GuestMenuBar} MenuItems={MenuItems} drawerWidth={drawerWidth} />
);

OurGuestApp.whyDidYouRender = true;

export default OurGuestApp;
