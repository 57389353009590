import { ReactNode } from 'react';
import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import { EntityFormik } from '@petconsole/pure-shared';
import useOurGridCardCenterSizes from '../../hooks/useOurGridCardCenterSizes';
import OurGridCardCenterContent from './OurGridCardCenterContent';
import OurGridCardMarginContent from './OurGridCardMarginContent';
import { OurFormGrid } from '../grids';
import OurBusyBackdrop from '../misc/OurBusyBackdrop';

interface OurMarginGridCardProps extends GridProps {
  busy?: boolean;
  raised?: boolean;
  padding?: number;
  marginColumns?: boolean;
  margins?: RegularBreakpoints;
  leftMargins?: RegularBreakpoints;
  rightMargins?: RegularBreakpoints;
  leftContent?: () => ReactNode;
  rightContent?: () => ReactNode;
  formik?: EntityFormik;
  children: ReactNode;
}

const OurMarginGridCard = ({
  busy,
  raised = true,
  padding = 1,
  marginColumns,
  margins,
  leftMargins,
  rightMargins,
  leftContent,
  rightContent,
  formik,
  children,
  ...rest
}: OurMarginGridCardProps) => {
  const mainMargins = margins || { xs: false, sm: 1, md: 2, lg: 3, xl: 4 };
  const { centerSizes } = useOurGridCardCenterSizes({
    marginColumns,
    leftMargins,
    margins: mainMargins,
    rightMargins,
  });

  return (
    <Grid container>
      <OurGridCardMarginContent margin={leftMargins || mainMargins} content={leftContent} />
      <Grid item id="center-content" width="100%" {...(centerSizes)}>
        <OurGridCardCenterContent raised={raised} padding={padding} {...rest}>
          {formik && (
            <OurFormGrid formik={formik} offset={-2}>
              {children}
            </OurFormGrid>
          )}
          {!formik && children}
        </OurGridCardCenterContent>
        {busy !== undefined && <OurBusyBackdrop busy={busy} />}
      </Grid>
      <OurGridCardMarginContent margin={rightMargins || mainMargins} content={rightContent} />
    </Grid>
  );
};

OurMarginGridCard.whyDidYouRender = true;

export default OurMarginGridCard;