import { useOurOnClickRedirect } from '@petconsole/pure-fe-utils';
import { reactAppHtmlAppName, webPath } from '@petconsole/pure-shared';
import { ourJoinButtonColor } from '../../constants';
import useOurWidth from '../../hooks/useOurWidth';
import OurButton, { OurButtonProps } from './OurButton';

interface OurJoinButtonProps extends Omit<OurButtonProps, 'label'> {
  label?: string;
}

const OurJoinButton = (props: OurJoinButtonProps) => {
  const width = useOurWidth();
  const { onClick } = useOurOnClickRedirect({ path: webPath.join });

  const label = props.label || `${width === 'xs' ? 'Join' : `Join ${reactAppHtmlAppName}`}`;

  return <OurButton {...props}  onClick={onClick} sx={{ backgroundColor: ourJoinButtonColor }} label={label} />;
};

OurJoinButton.whyDidYouRender = true;

export default OurJoinButton;
