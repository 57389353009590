import { RecordOfNumber } from '@petconsole/pure-base';

interface HttpStatusCode extends RecordOfNumber {
  OK: number;
  created: number;
  noContent: number;
  badRequest: number;
  forbidden: number;
  notFound: number;
  conflict: number;
  internalServerError: number;
}

export const httpStatusCode: HttpStatusCode = {
  OK: 200,
  created: 201,
  noContent: 204,
  badRequest: 400,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  internalServerError: 500,
};
