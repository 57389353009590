import { RecordType } from '@petconsole/pure-base';
import { ExtendedFormik } from '@petconsole/pure-shared';
import useOurGooglePlaces from '../../hooks/useOurGooglePlaces';
import OurText from '../inputs/OurText';
import OurGooglePlaces, { OurGooglePlacesProps } from './OurGooglePlaces';

export type OurGoogleAddressProps<T extends RecordType = RecordType> = Omit<OurGooglePlacesProps<T>, 'googlePlaces'>;

const OurGoogleAddress = <T extends RecordType = RecordType>({ inputValue, setInputValue, ...rest }: OurGoogleAddressProps<T>) => {
  const googlePlaces = useOurGooglePlaces();
  const { readOnly, formik = {} } = rest;
  const { isReadOnly } = formik  as ExtendedFormik

  // Returning OurText is a workaround as MUI's AutoComplete doesn't support readOnly
  if (readOnly || isReadOnly) return <OurText<T> value={inputValue} {...rest} />;

  return (
    <OurGooglePlaces<T>
      helperText={undefined}
      googlePlaces={googlePlaces}
      inputValue={inputValue}
      setInputValue={setInputValue}
      placeholder="Start typing a city name or address"
      {...rest}
    />
  );
}

OurGoogleAddress.whyDidYouRender = true;

export default OurGoogleAddress;
