// noinspection ES6PreferShortImport

import { MaybeNull, RecordType, newId } from '@petconsole/pure-base';
import { ourCurrencyCode, ourDefaultCurrencyCode } from '../../constants';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { AddressColumns, DataEntityType, InitialEntity, OurCurrencyCode, TimeColumns } from '../../types';

export interface PclInvoice extends AddressColumns, TimeColumns {
  type: DataEntityType;
  pclInvoiceId: string;
  memberId: string;
  pclCustomerId: string;
  pclSubscriptionId?: string;
  invoiceNumber: string;
  currency: OurCurrencyCode;
  discount: number;
  subtotal: number;
  tax: number;
  total: number;
  amountDue: number;
  amountPaid: number;
  amountOwing: number;
  json: RecordType;
}

export const pclInvoiceEntity = entityAttributes({
  name: entityName.pclInvoice,
  attributes: {
    category: entityCategory.item,
    columns: { address: true, crud: false, images: false, time: true },
    nameField: undefined,
    test: { invalidData: () => ({ invoiceNumber: {} }), validData: () => ({ invoiceNumber: newId() }) },
  },
});

const { id, number, oneOf, strim } = schema;
export const pclInvoiceSchema = entitySchema<PclInvoice>(pclInvoiceEntity, {
  memberId: id(true),
  pclCustomerId: id(true),
  pclSubscriptionId: id(),
  invoiceNumber: strim().required(),
  currency: oneOf(ourCurrencyCode, ourDefaultCurrencyCode),
  discount: number(), // cents
  subtotal: number(), // cents
  tax: number(),
  total: number(),
  amountDue: number(), // cents
  amountPaid: number(),
  amountOwing: number(),
});

export const pclInvoiceDefaults: Partial<PclInvoice> = {
  memberId: '',
  pclCustomerId: '',
  invoiceNumber: '',
  pclInvoiceId: '',
  pclSubscriptionId: '',
  currency: ourDefaultCurrencyCode,
  discount: 0,
  subtotal: 0,
  tax: 0,
  total: 0,
  amountDue: 0,
  amountPaid: 0,
  amountOwing: 0,
};

export interface PclInvoiceRecord extends PclInvoice, RecordType {}

export type MaybePclInvoiceRecord = MaybeNull<PclInvoiceRecord>;
export type PclInvoiceRecords = PclInvoiceRecord[];

export const initialPclInvoice: InitialEntity<PclInvoice, PclInvoiceRecord> = (overrides = {}) =>
  initialEntity<PclInvoice>(pclInvoiceEntity, { ...pclInvoiceDefaults, ...overrides }) as PclInvoiceRecord;
