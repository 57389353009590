import { Grid, Typography } from '@mui/material';
import { properCase } from '@petconsole/pure-base';
import { EntityAttributes, Image, ImageKey, SetState } from '@petconsole/pure-shared';
import useOurPhotoZone from '../images/useOurPhotoZone';
import OurPhotoZoneViewer from '../images/OurPhotoZoneViewer';

interface OurBannerZoneProps {
  entity: EntityAttributes | string;
  id: string;
  imageKey: ImageKey;
  banner?: Image;
  setBanner: SetState<Image>;
  canEdit?: boolean;
}

const OurBannerZone = ({ entity, id, imageKey, banner, setBanner, canEdit }: OurBannerZoneProps) => {
  const title = `${typeof entity === 'string' ? properCase(entity) : entity.proper} Banner`;
  const { photoZone } = useOurPhotoZone({
    id,
    title,
    leadText: 'Add a banner image, ideally 1080 * 608 (9:16 aspect ratio)',
    imageKey,
    canEdit,
    photo: banner,
    setPhoto: setBanner,
  });

  return (
    <Grid container item spacing={1} justifyContent="center">
      <OurPhotoZoneViewer photoZone={photoZone} />
      {!banner?.s3Key && !canEdit && <Typography>No banner added</Typography>}
    </Grid>
  );
};

OurBannerZone.whyDidYouRender = true;

export default OurBannerZone;
