// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { defaultStripeTaxCode, ourUomQuantity, priceIntervals } from '../../constants';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity } from '../../types';

export interface PclProduct extends CrudColumns {
  type: DataEntityType;
  pclProductId: string;
  available: boolean;
  pclServiceId?: string;
  pclServiceOrder: number;
  priceInterval: string;
  productCode: string;
  name: string;
  statementDescription: string;
  description: string;
  avatar: string;
  taxCode: string;
  quantityUom: string;
  features: string;
  active: boolean;
  json: RecordType;
}

export const pclProductEntity = entityAttributes({
  name: entityName.pclProduct,
  attributes: {
    adminOnly: { create: true, delete: true },
    category: entityCategory.item,
    columns: { active: true, images: false },
    restrictGet: false,
  },
});

const { boolean, caps, id, number, oneOf, strim } = schema;
export const pclProductSchema = entitySchema<PclProduct>(pclProductEntity, {
  available: boolean(true),
  pclServiceId: id(),
  pclServiceOrder: number(),
  priceInterval: oneOf(priceIntervals, priceIntervals.none),
  productCode: caps().required(),
  name: strim().required(),
  statementDescription: strim(), // short name or code
  description: strim(),
  avatar: strim(),
  taxCode: strim().default(defaultStripeTaxCode),
  quantityUom: oneOf(ourUomQuantity, ourUomQuantity.EA),
  features: strim(),
});

export const pclProductDefaults: Partial<PclProduct> = {
  available: true,
  pclServiceId: '',
  pclServiceOrder: 0,
  priceInterval: '',
  productCode: '',
  name: '',
  statementDescription: '',
  description: '',
  avatar: '',
  taxCode: defaultStripeTaxCode,
  quantityUom: ourUomQuantity.EA,
  features: '',
};

export interface PclProductRecord extends PclProduct, RecordType {}

export type MaybePclProductRecord = MaybeNull<PclProductRecord>;
export type PclProductRecords = PclProductRecord[];

export const initialPclProduct: InitialEntity<PclProduct, PclProductRecord> = (overrides = {}) =>
  initialEntity<PclProduct>(pclProductEntity, { ...pclProductDefaults, ...overrides }) as PclProductRecord;
