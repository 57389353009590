import { RecordType } from '@petconsole/pure-base';

interface OurGetEntityTitleProps<T extends RecordType = RecordType> {
  entityRecord: T;
  nameField: string;
  title?: string;
  titleField?: string | string[];
}

const ourGetEntityTitle = <T extends RecordType = RecordType>({ entityRecord, nameField, title, titleField }: OurGetEntityTitleProps<T>) => {
  const titleFields =
    typeof titleField === 'string' ? [titleField] : Array.isArray(titleField) ? titleField : undefined;

  if (titleFields)
    return titleFields.reduce((previousValue, currentValue) => previousValue || (entityRecord[currentValue] as string), '');

  return title || (nameField && entityRecord[nameField] as string) || '';
};

export default ourGetEntityTitle;
