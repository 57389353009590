import { skipToken } from '@reduxjs/toolkit/query';
import { cartItemApiEntity } from '@petconsole/pure-fe-api';
import { CartItemRecord, CartItemRecords } from '@petconsole/pure-shared';
import { ApiUseGetListQuery } from '../../../../types';
import {
  useCreateCartItemMutation,
  useGetCartItemQuery,
  useGetCartItemsByCartQuery,
  useUpdateCartItemMutation,
} from '../../../apiSlice';
import useCreateEntity from '../../../helpers/useCreateEntity';
import useGetEntityItemsBy from '../../../helpers/useGetEntityItemsBy';
import useUpdateEntity from '../../../helpers/useUpdateEntity';

type ApiRecord = CartItemRecord;
const apiEntity = cartItemApiEntity;

export const useCreateCartItem = () =>
  useCreateEntity<ApiRecord>({ apiEntity, mutationHook: useCreateCartItemMutation });
export const useGetCartItem = ({ cartItemId }: { cartItemId: string }) => useGetCartItemQuery(cartItemId || skipToken);
export const useGetCartItemsByCart = ({ cartId }: { cartId: string }) =>
  useGetEntityItemsBy<ApiRecord>({
    apiEntity,
    value: cartId,
    queryHook: useGetCartItemsByCartQuery,
  }) as ReturnType<ApiUseGetListQuery> & { cartItems: CartItemRecords };
export const useUpdateCartItem = () =>
  useUpdateEntity<ApiRecord>({ apiEntity, mutationHook: useUpdateCartItemMutation });
