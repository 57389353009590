import { MutableRefObject, useCallback, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { ourGetImage } from '@petconsole/pure-fe-api';
import useOurError from '../../error/useOurError';

interface UseOurAvatarEditorProps {
  avatarEditor: MutableRefObject<AvatarEditor | undefined>;
  currentAvatar?: string;
  defaultAvatar?: string;
}

const useOurAvatarEditor = ({ avatarEditor, currentAvatar, defaultAvatar }: UseOurAvatarEditorProps) => {
  const { log } = useOurError();

  const [avatar, setAvatar] = useState(defaultAvatar || '');
  const [avatarChanged, setAvatarChanged] = useState(false);
  const setAvatarEditor = useCallback((editor: AvatarEditor) => (avatarEditor.current = editor), [avatarEditor]);

  const getAvatar = useCallback(
    () =>
      ourGetImage({
        s3Key: currentAvatar || '',
        log,
      })
        .then((image) => setAvatar((image || defaultAvatar || '') as string))
        .then(() => setAvatarChanged(false)),
    [currentAvatar, defaultAvatar, log],
  );

  return {
    // OurAvatarEditor,
    avatar,
    setAvatar,
    getAvatar,
    avatarChanged,
    setAvatarChanged,
    setAvatarEditor,
  };
};

export default useOurAvatarEditor;
