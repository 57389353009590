import React, { memo } from 'react';
import { RecordType } from '@petconsole/pure-base';
import EntityMaintenanceHeadings from '../entity/components/EntityMaintenanceHeadings';
import useMaintAdd from './helpers/useMaintAdd';
import useMaintHeader from './helpers/useMaintHeader';
import { MaintHeaderProps } from './types';

const MaintHeader = <T extends RecordType = RecordType>({
  entity,
  hooks,
  helpTitle,
  help,
  add,
  Add,
  addProps,
  ...rest
}: MaintHeaderProps<T>) => {
  // Add
  const addResult = useMaintAdd({ entity, hooks, add, Add, addProps });
  const { Add: AddEntity, addIsOpen, /* setAddIsOpen, */ onAdd } = addResult;
  const AddDialog = memo(() => (AddEntity ? <AddEntity /> : null));

  const headerProps = { entity, add, onAdd, ...rest };
  const { Heading, Toolbar } = useMaintHeader(headerProps);

  return (
    <>
      <EntityMaintenanceHeadings
        helpTitle={helpTitle ?? undefined}
        helpContent={help ?? undefined}
        Heading={Heading}
        Toolbar={Toolbar}
      />
      {addIsOpen && <AddDialog />}
    </>
  );
};

MaintHeader.whyDidYouRender = true;

export default MaintHeader;
