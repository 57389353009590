import { PropsWithChildren } from 'react';
import { EntityAppProps } from '@petconsole/pure-shared';
import { EntityAppContextProvider } from './EntityAppContext';
import SetEntityAppContext from './SetEntityAppContext';

const EntityAppWrapper = ({
  entity,
  hooks,
  queryHook,
  schema,
  values,
  help,
  children,
}: PropsWithChildren<EntityAppProps>) => {
  return (
    <EntityAppContextProvider>
      <SetEntityAppContext {...{ entity, hooks, queryHook, schema, values, help }} />
      {children}
    </EntityAppContextProvider>
  );
};

EntityAppWrapper.whyDidYouRender = true;

export default EntityAppWrapper;
