import { RecordType } from '@petconsole/pure-base';
import { placeApiEntity } from '@petconsole/pure-fe-api';
import { PlaceRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = PlaceRecord;

const apiEntity = placeApiEntity;
const { name: sliceName, plural } = apiEntity;
const place = sliceName;
const places = plural;
const placeAdmin = `${sliceName}Admin`;

const Tab = { placeAdmin, place, places } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  placeAdmin: { default: '' },
  place: { default: 'Details' },
  places: { default: 'By City' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  sortProperty: 'practiceName',
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, ratingHooks, tabHooks } = sliceHookTypes;

export interface OurPlaceHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  placeHooks: RequiredCrudSliverHooks;
  cityPlaceHooks: RequiredSliverHooks;
  newestPlaceHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourPlaceHooks: OurPlaceHooks = {
  entity: apiEntity,
  placeHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityPlaceHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestPlaceHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  ratingHooks: ratingHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
