import { MouseEventHandler, ReactNode, useEffect, useRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import { MaybeString } from '@petconsole/pure-base';
import { ourFetchStatus } from '@petconsole/pure-fe-state';
import { ReturnsVoid } from '@petconsole/pure-shared';
import { ourDefaultDataRefreshMinutes } from '../../constants';
import useReportError from '../../error/useReportError';
import OurButton from '../buttons/OurButton';
import OurLinearProgress from './OurLinearProgress';

interface OurFetchStatusControlProps {
  id?: string;
  hasMore: boolean;
  readError: MaybeString;
  readStatus: string;
  fetch: MouseEventHandler;
  reset?: ReturnsVoid;
  refresh?: ReturnsVoid;
  activity?: string;
  endOfResults?: ReactNode;
  moreLabel?: string;
  resetMinutes?: number;
  warning?: boolean;
}

const OurFetchStatusControl = ({
  id = '',
  hasMore,
  readError,
  readStatus,
  fetch,
  reset,
  refresh,
  activity = 'Reading data',
  endOfResults = 'End Of Results',
  moreLabel = 'More',
  resetMinutes = ourDefaultDataRefreshMinutes,
  warning = true,
}: OurFetchStatusControlProps) => {
  const error = useReportError({ error: readError, activity, id, warn: warning }) as ReactNode;

  const resetOrRefresh = reset || refresh;
  const mils = (minutes: number) => minutes * 60 * 1000;
  const intervalMils = useRef<number>(resetOrRefresh ? mils(resetMinutes) : 0);
  const intervalId = useRef<number | NodeJS.Timeout>();

  useEffect(() => {
    if (!intervalMils.current) return;

    if (intervalId.current) clearInterval(intervalId.current);

    intervalId.current = setInterval(resetOrRefresh as ReturnsVoid, intervalMils.current);

    return function cleanup() {
      clearInterval(intervalId.current);
    };
  }, [resetOrRefresh]);

  return (
    <Grid container item id="fetch-status-grid" justifyContent="center">
      {readStatus === ourFetchStatus.loading && <OurLinearProgress />}
      {readStatus === ourFetchStatus.failed && <div>{error}</div>}
      {readStatus === ourFetchStatus.succeeded &&
        (hasMore ? (
          <Box id="button-box" display="flex" width="50%">
            <OurButton label={moreLabel} onClick={fetch} startIcon={<CloudDownload />} />
          </Box>
        ) : (
          endOfResults && <Typography>{endOfResults}</Typography>
        ))}
    </Grid>
  );
};

OurFetchStatusControl.whyDidYouRender = true;

export default OurFetchStatusControl;
