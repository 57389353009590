import { ReactNode } from 'react';
import { Grid, Typography } from '@mui/material';
import { Images } from '@petconsole/pure-shared';

interface OurPhotosZoneProps {
  images?: Images;
  imageZone: ReactNode;
}

const OurPhotosZone = ({ images, imageZone }: OurPhotosZoneProps) => {
  return (
    <Grid container item spacing={1} justifyContent="center">
      {imageZone}
      {!images || (images.length < 1 && <Typography>No photos added</Typography>)}
    </Grid>
  );
};

OurPhotosZone.whyDidYouRender = true;

export default OurPhotosZone;
