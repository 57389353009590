// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface PetWalker extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  petWalkerId: string;
  name: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const petWalkerEntity = entityAttributes({
  name: entityName.petWalker,
  attributes: {
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    site: anySite.petConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const petWalkerSchema = entitySchema<PetWalker>(petWalkerEntity, {
  name: strim().required('Business name is required'),
});

export const petWalkerDefaults: Partial<PetWalker> = {
  name: '',
};

export interface PetWalkerRecord extends PetWalker, RecordType {}

export type MaybePetWalkerRecord = MaybeNull<PetWalkerRecord>;
export type PetWalkerRecords = PetWalkerRecord[];

export const initialPetWalker: InitialEntity<PetWalker, PetWalkerRecord> = (overrides = {}) =>
  initialEntity<PetWalker>(petWalkerEntity, { ...petWalkerDefaults, ...overrides }) as PetWalkerRecord;
