// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface Place extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  placeId: string;
  name: string;
  placeType: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const placeEntity = entityAttributes({
  name: entityName.place,
  attributes: {
    category: entityCategory.place,
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    // nameField: 'placeName',
    maxImages: 8,
    shared: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const placeSchema = entitySchema<Place>(placeEntity, {
  name: strim().required('Place name is required'),
  placeType: strim(),
});

export const placeDefaults: Partial<Place> = {
  name: '',
  placeType: '',
};

export interface PlaceRecord extends Place, RecordType {}

export type MaybePlaceRecord = MaybeNull<PlaceRecord>;
export type PlaceRecords = PlaceRecord[];

export const initialPlace: InitialEntity<Place, PlaceRecord> = (overrides = {}) =>
  initialEntity<Place>(placeEntity, { ...placeDefaults, ...overrides }) as PlaceRecord;
