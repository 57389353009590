import { memo } from 'react';
import { EntityAttributes, entityType } from '@petconsole/pure-shared';
import useOurImageZone from '../../components/images/useOurImageZone';
import OurPhotosZone from '../../components/images/OurPhotosZone';

export interface UseOurPhotosProps {
  entity: EntityAttributes;
  id: string;
  editing: boolean;
  owner: boolean;
}

const useOurPhotos = ({ entity, id, editing, owner }: UseOurPhotosProps) => {
  const { imageKey, maxImages, shared, type } = entity;

  const { images, setImages, imageZone } = useOurImageZone({
    maxFiles: maxImages,
    canEdit: () => editing && (owner || shared),
    getKey: (src) => imageKey({ id: type === entityType.MailMessage ? '%id%' : id, src }),
  });

  const Photos = memo(() => <OurPhotosZone images={images} imageZone={imageZone} />);

  return { images, setImages, imageZone, Photos };
};

export default useOurPhotos;
