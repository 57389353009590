import { del } from 'aws-amplify/api';
import { RecordType } from '@petconsole/pure-base';
import { restApiName } from '../constants';
import { DeleteInput, DeleteInputProps, RestApiOptionsBase, RestApiResponse, RestInputOptions } from '../types';
import restCatch from './restCatch';

export interface RestDeleteInput extends Omit<DeleteInput, 'apiName'> { apiName?: string }
export type DeleteResult<T extends RecordType = RecordType> = T;
export type RestDel<T extends RecordType = RecordType> = (props: RestDeleteInput) => Promise<DeleteResult<T>>;

export const attemptDel = ({ apiName, path, options }: DeleteInputProps) => del({ apiName, path, options: options as RestApiOptionsBase });

const restDel: RestDel = async ({ apiName = restApiName, path, options }) => {
  try {
    const result = attemptDel({ apiName, path, options: options as RestInputOptions });
    const response = await result.response as RestApiResponse;
    const body = response.body;

    if (response.headers['content-type'] === 'application/json') return (body.json as () => Promise<DeleteResult>)();

    // return body.text();
  } catch (error) {
    restCatch('restDel', error);
  }

  throw new Error(`restDel received unexpected results from ${path}`)
};

export default restDel;
