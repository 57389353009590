import { Grid, GridProps } from '@mui/material';
import OurTypoText, { OurTypoTextProps } from './OurTypoText';

interface OurTypoTextItemProps extends OurTypoTextProps {
  gridProps?: GridProps;
  left?: boolean;
  right?: boolean;
  center?: boolean;
}

const OurTypoTextItem = ({ gridProps, left, right, center, ...rest }: OurTypoTextItemProps) => {
  const textAlign = left ? 'left' : right ? 'right' : center ? 'center' : '';

  return (
    <Grid item xs={12} {...(textAlign ? { textAlign } : {})} {...gridProps}>
      <OurTypoText {...rest} />
    </Grid>
  );
};

OurTypoTextItem.whyDidYouRender = true;

export default OurTypoTextItem;
