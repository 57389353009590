import { Facebook } from '@mui/icons-material';
import OurExternalIcon from '../icons/OurExternalIcon';

interface OurFacebookIconProps {
  href?: string;
  account?: string;
}

const OurFacebookIcon = ({ href, account = 'petconsole' }: OurFacebookIconProps) => (
  <OurExternalIcon icon={<Facebook />} href={href || `https://www.facebook.com/${account}`} aria="Facebook" />
);

OurFacebookIcon.whyDidYouRender = true;

export default OurFacebookIcon;
