import { skipToken } from '@reduxjs/toolkit/query';
import { pclServiceApiEntity } from '@petconsole/pure-fe-api';
import { PclServiceRecord } from '@petconsole/pure-shared';
import {
  useCreatePclServiceMutation,
  useGetPclServiceByServiceCodeQuery,
  useGetPclServiceQuery,
  useUpdatePclServiceMutation,
} from '../../../apiSlice';
import useCreateEntity from '../../../helpers/useCreateEntity';
import useUpdateEntity from '../../../helpers/useUpdateEntity';

const apiEntity = pclServiceApiEntity;
type ApiRecord = PclServiceRecord;

export const useCreatePclService = () =>
  useCreateEntity<ApiRecord>({ apiEntity, mutationHook: useCreatePclServiceMutation });
export const useGetPclService = ({ pclServiceId }: { pclServiceId: string }) =>
  useGetPclServiceQuery(pclServiceId || skipToken);
export const useGetPclServiceByServiceCode = ({ serviceCode }: { serviceCode: string }) =>
  useGetPclServiceByServiceCodeQuery(serviceCode || skipToken);
export const useUpdatePclService = () =>
  useUpdateEntity<ApiRecord>({ apiEntity, mutationHook: useUpdatePclServiceMutation });
