import { Grid } from '@mui/material';
import { EntityAttributes, ExtendedFormik } from '@petconsole/pure-shared';
import OurContactItems from '../../components/inputs/OurContactItems';
import OurSocialMediaItems from '../../components/inputs/OurSocialMediaItems';

export interface ListEntityDetailsProps {
  entity: EntityAttributes;
  formik: ExtendedFormik;
  isEditing: boolean;
  isOwner: boolean;
  readOnly: boolean;
}

const ListEntityDetails = ({ entity, formik, isOwner, readOnly }: ListEntityDetailsProps) => {
  const { columns } = entity;

  return (
    <Grid container item spacing={1} mt={-0.5}>
      <Grid item xs={12} sm={6}>
        <OurContactItems formik={formik} isOwner={isOwner} readOnly={readOnly} />
      </Grid>
      {columns.socials && (
        <Grid item xs={12} sm={6}>
          <OurSocialMediaItems formik={formik} readOnly={readOnly} />
        </Grid>
      )}
    </Grid>
  );
};

ListEntityDetails.whyDidYouRender = true;

export default ListEntityDetails;
