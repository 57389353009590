import { ChangeEventHandler } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { ourButtonBorderRadius } from '../../constants';

interface OurFileButtonProps extends ButtonProps {
  label: string;
  accept?: string;
  onImage?: ChangeEventHandler<HTMLInputElement>;
}

const OurFileButton = ({ label, accept = 'image/*', onImage, sx = {}, ...rest }: OurFileButtonProps) => (
  <Button
    variant="contained"
    color="primary"
    component="label"
    sx={{ borderRadius: ourButtonBorderRadius, ...sx }}
    {...rest}
  >
    {label}
    <input accept={accept} type="file" hidden onChange={onImage} />
  </Button>
);

OurFileButton.whyDidYouRender = true;

export default OurFileButton;
