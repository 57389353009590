import { RecordType } from '@petconsole/pure-base';
import { Api, changeSiteEntity, ChangeSiteRecord } from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = ChangeSiteRecord;

const apiPath = '/changeSites';
const { create, ...common } = commonApi<ApiRecord>({ apiPath });

interface SaveItemsProps {
  site: string;
  items: RecordType;
}

export const saveItems = async ({ site, items }: SaveItemsProps) => {
  const { changeSiteId } = (await create({ site: site.toLowerCase(), storage: items })) as ChangeSiteRecord;

  return changeSiteId as string;
};

export interface ChangeSiteApi<T extends RecordType = ApiRecord> extends Api<T> {
  saveItems: (props: SaveItemsProps) => Promise<string>;
}

export const changeSiteApi: ChangeSiteApi = {
  ...common,
  create,
  saveItems,
};

export const changeSiteApiEntity /* : ApiEntity<ApiRecord> */ = { ...changeSiteEntity, api: changeSiteApi };
