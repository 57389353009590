// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { ourCurrencyCode, priceIntervals } from '../../constants';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, TimeColumns } from '../../types';

export interface PclSubscription extends TimeColumns {
  type: DataEntityType;
  pclSubscriptionId: string;
  memberId: string;
  pclCustomerId: string;
  pclTierPriceId: string;
  description: string;
  entityName: string;
  entityType: string;
  entityId: string;
  interval: string;
  currency: string;
  active: boolean;
  json: {
    stripeCustomer: {
      customerId: string;
      customerName: string;
    };
    stripeSubscription: {
      billingCycleAnchor: number;
      trialEnd: number | string | null;
      cancelAt: number | string | null;
      trialStart: number | string | null;
      description: string;
      discount: number | null;
      daysUntilDue: number | null;
      defaultPaymentMethodId: string;
      canceledAt: number | string | null;
      latestInvoiceId: string;
      endedAt: number | null;
      cancelAtPeriodEnd: false;
      subscriptionStatus: string;
      currency: string;
      subscriptionId: string;
      lines: [
        {
          amount: number;
          subscriptionItemId: string;
          productId: string;
          trialPeriodDays: number | null;
          interval: string;
          currency: string;
          priceId: string;
          pclTierPriceId: string;
        },
      ];
      startDate: number;
    };
    stripeCheckoutSession: {
      total: number;
      termsAccepted: string;
      subtotal: number;
      checkoutSessionId: string;
      discount: number;
      currency: string;
      tax: number;
      checkoutStatus: string;
      paymentStatus: string;
    };
  };
}

export const pclSubscriptionEntity = entityAttributes({
  name: entityName.pclSubscription,
  attributes: {
    category: entityCategory.item,
    columns: { active: true, crud: false, images: false, time: true },
    nameField: undefined,
    test: { invalidData: () => ({ interval: {} }), validData: () => ({ interval: 'Month' }) },
  },
});

const { id, oneOf, strim, type } = schema;
export const pclSubscriptionSchema = entitySchema<PclSubscription>(pclSubscriptionEntity, {
  memberId: id(true),
  pclCustomerId: id(true),
  pclTierPriceId: id(true),
  description: strim(),
  entityName: strim(),
  entityType: type(),
  entityId: id(true),
  interval: oneOf(priceIntervals, priceIntervals.none),
  currency: oneOf(ourCurrencyCode),
});

export const pclSubscriptionDefaults: Partial<PclSubscription> = {
  memberId: '',
  pclCustomerId: '',
  pclTierPriceId: '',
  description: '',
  entityName: '',
  entityType: '',
  entityId: '',
  interval: '',
  currency: '',
};

export interface PclSubscriptionRecord extends PclSubscription, RecordType {}

export type MaybePclSubscriptionRecord = MaybeNull<PclSubscriptionRecord>;
export type PclSubscriptionRecords = PclSubscriptionRecord[];

export const initialPclSubscription: InitialEntity<PclSubscription, PclSubscriptionRecord> = (overrides = {}) =>
  initialEntity<PclSubscription>(pclSubscriptionEntity, { ...pclSubscriptionDefaults, ...overrides }) as PclSubscriptionRecord;
