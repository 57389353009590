import { MouseEventHandler } from 'react';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { IsOpenProps, removeProperties } from '@petconsole/pure-shared';
import OurEditButton, { OurEditButtonProps } from './OurEditButton';
import OurOptionsButton, { OurOptionsButtonProps } from './OurOptionsButton';

interface OurEditOrOptionsButtonProps extends IsOpenProps, OurEditButtonProps, OurOptionsButtonProps {
  owner?: string;
  onEdit?: MouseEventHandler;
}

const OurEditOrOptionsButton = ({ owner, onEdit, ...rest }: OurEditOrOptionsButtonProps) => {
  const { memberName } = useCurrentMember();

  if (owner && owner === memberName) {
    const editProps = removeProperties(rest, ['isOpen', 'setIsOpen']);

    return <OurEditButton {...editProps} onClick={onEdit} />;
  }

  return <OurOptionsButton {...rest} />;
};

OurEditOrOptionsButton.whyDidYouRender = true;

export default OurEditOrOptionsButton;
