import { createSlice } from '@reduxjs/toolkit';
import { setPropertyReducer } from '../../../helpers';
import slicePropertyHooks from '../../../helpers/slices/slicePropertyHooks';

const slice = {
  name: 'memberMenu',
};

const initialState = {
  memberMenuCommunityMenuOpen: false,
  memberMenuMarketplaceMenuOpen: false,
  memberMenuListingsMenuOpen: false,
  memberMenuMyMenuOpen: false,
  memberMenuGeneralMenuOpen: false,
  memberMenuSitesMenuOpen: false,
  memberMenuAdminMenuOpen: false,
};

export const memberMenuSlice = createSlice({
  name: slice.name,
  initialState,
  reducers: {
    setMemberMenuCommunityMenuOpen: setPropertyReducer('memberMenuCommunityMenuOpen'),
    setMemberMenuMarketplaceMenuOpen: setPropertyReducer('memberMenuMarketplaceMenuOpen'),
    setMemberMenuListingsMenuOpen: setPropertyReducer('memberMenuListingsMenuOpen'),
    setMemberMenuMyMenuOpen: setPropertyReducer('memberMenuMyMenuOpen'),
    setMemberMenuGeneralMenuOpen: setPropertyReducer('memberMenuGeneralMenuOpen'),
    setMemberMenuSitesMenuOpen: setPropertyReducer('memberMenuSitesMenuOpen'),
    setMemberMenuAdminMenuOpen: setPropertyReducer('memberMenuAdminMenuOpen'),
  },
});

const { actions } = memberMenuSlice;

export const { useOurSelectMemberMenuCommunityMenuOpen, useOurSetMemberMenuCommunityMenuOpen } = slicePropertyHooks(
  slice.name,
  'memberMenuCommunityMenuOpen',
  actions,
);
export const { useOurSelectMemberMenuMarketplaceMenuOpen, useOurSetMemberMenuMarketplaceMenuOpen } = slicePropertyHooks(
  slice.name,
  'memberMenuMarketplaceMenuOpen',
  actions,
);
export const { useOurSelectMemberMenuListingsMenuOpen, useOurSetMemberMenuListingsMenuOpen } = slicePropertyHooks(
  slice.name,
  'memberMenuListingsMenuOpen',
  actions,
);
export const { useOurSelectMemberMenuMyMenuOpen, useOurSetMemberMenuMyMenuOpen } = slicePropertyHooks(
  slice.name,
  'memberMenuMyMenuOpen',
  actions,
);
export const { useOurSelectMemberMenuGeneralMenuOpen, useOurSetMemberMenuGeneralMenuOpen } = slicePropertyHooks(
  slice.name,
  'memberMenuGeneralMenuOpen',
  actions,
);
export const { useOurSelectMemberMenuSitesMenuOpen, useOurSetMemberMenuSitesMenuOpen } = slicePropertyHooks(
  slice.name,
  'memberMenuSitesMenuOpen',
  actions,
);
export const { useOurSelectMemberMenuAdminMenuOpen, useOurSetMemberMenuAdminMenuOpen } = slicePropertyHooks(
  slice.name,
  'memberMenuAdminMenuOpen',
  actions,
);

export default memberMenuSlice.reducer;
