import { dateToString, MaybeNull, RecordType } from '@petconsole/pure-base';
import { Api, ApiEntity, MaybePclServiceRecord, pclServiceEntity, PclServiceRecord } from '@petconsole/pure-shared';
import { commonApi, fullPath, optionalParam, post } from './common';

type ApiRecord = PclServiceRecord;

const apiPath = '/pclServices';
const { getByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  createProducts: (id: string) => Promise<MaybeNull<T[]>>;
  createPclTiers: (id: string, effectiveDate?: string | null) => Promise<T[]>;
  getByServiceCode: (serviceCode: string) => Promise<MaybeNull<T>>;
}

export type PclServiceApi = Api<ApiRecord> & CustomApi;

export const pclServiceApi: PclServiceApi = {
  ...common,
  getByValue,
  createProducts: (id) => post(`${apiPath}/createProducts/${id}`, {}) as Promise<MaybeNull<ApiRecord[]>>,
  createPclTiers: (id, effectiveDate = dateToString(new Date())) =>
    post(
      fullPath(`${apiPath}/createTiers/${id}`, optionalParam('effectiveDate', effectiveDate)),
      {},
    ) as unknown as Promise<ApiRecord[]>,
  getByServiceCode: (serviceCode) => getByValue('serviceCode', serviceCode) as Promise<MaybePclServiceRecord>,
};

export const pclServiceApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...pclServiceEntity, api: pclServiceApi };
