import { useState } from 'react';
import { camelToWords } from '@petconsole/pure-base';
import { DeleteEntityByIdMethod, IsOpenProps, ReturnsUnknown } from '@petconsole/pure-shared';
import useDoWork from '../../../misc/doWork/useDoWork';
import OurConfirmDeleteDialog from '../../dialogs/OurConfirmDeleteDialog';

interface OurDeleteEntityProps extends IsOpenProps {
  entityText: string;
  title?: string;
  prompt?: string;
  onDelete: DeleteEntityByIdMethod;
}

const OurDeleteEntity = ({
  entityText = 'entity',
  title = 'Delete Entity',
  prompt = 'Are you sure you want to delete this entity?',
  isOpen,
  setIsOpen,
  onDelete,
}: OurDeleteEntityProps) => {
  const { doWork, workCatch } = useDoWork();

  const capitalizedEntity = camelToWords(entityText);
  const lowercaseEntity = capitalizedEntity.toLowerCase();
  const heading = title.replace('Entity', capitalizedEntity);
  const question = prompt.replace('entity', lowercaseEntity);
  const [busy, setBusy] = useState(false);

  const onDeleteConfirm = async () =>
    doWork({
      tryCallback: onDelete as ReturnsUnknown,
      catchCallback: (e: unknown) => workCatch(e, `deleting ${lowercaseEntity}`),
      setBusy: (isBusy: boolean) => isBusy && setBusy(isBusy), // dialog auto-closes after confirm
    });

  if (!isOpen) return null;

  return (
    <OurConfirmDeleteDialog
      title={heading}
      text={question}
      isOpen={isOpen}
      onCancel={() => setIsOpen(false)}
      onConfirm={onDeleteConfirm}
      busy={busy}
    />
  );
};

OurDeleteEntity.whyDidYouRender = true;

export default OurDeleteEntity;
