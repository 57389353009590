// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { site } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DefaultColumns, EntityType, Images, InitialEntity } from '../../types';
import { testEntity } from '../testDef';

export interface PclHeader extends DefaultColumns {
  type: EntityType;
  pclHeaderId: string;
  site: string;
  entityType: EntityType;
  topText: string;
  bottomText: string;
}

const { Preview } = entityTabName;
export const pclHeaderEntity = entityAttributes({
  name: entityName.pclHeader,
  attributes: {
    addField: 'entityType',
    addLabel: 'Listing Type',
    addInstructions: 'Please choose the listing type and site to add a header for',
    addTitle: 'Add New PCL Header',
    adminOnly: { create: true },
    category: entityCategory.person,
    nameField: 'entityType',
    ownerIfEditing: true,
    restrictGet: false,
    tabs: [Preview],
    test: { changes: { 'topText': 'New text' }, invalidChanges: { 'topText': {} }}
  },
});

const {oneOf, strim} = schema;
export const pclHeaderSchema = entitySchema<PclHeader>(pclHeaderEntity, {
  site: oneOf(site),
  entityType: strim().required(),
  topText: strim(),
  bottomText: strim(),
});

export const pclHeaderDefaults: Partial<PclHeader> = {
  site: '',
  entityType: testEntity.type,
  topText: '',
  bottomText: '',
  images: [{}, {}, {}, {}] as Images,
};

export interface PclHeaderRecord extends PclHeader, RecordType {}

export type MaybePclHeaderRecord = MaybeNull<PclHeaderRecord>;
export type PclHeaderRecords = PclHeaderRecord[];

export const initialPclHeader: InitialEntity<PclHeader, PclHeaderRecord> = (overrides = {}) =>
  initialEntity<PclHeader>(pclHeaderEntity, { ...pclHeaderDefaults, ...overrides }) as PclHeaderRecord;
