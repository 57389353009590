import { AppMenuItems } from '../../../types';

interface OurCombinedAppMenuItemsProps {
  items: AppMenuItems;
  add?: AppMenuItems;
  exclude?: string[];
}

const ourCombinedAppMenuItems = ({ items, add = [], exclude = [] }: OurCombinedAppMenuItemsProps) => {
  return items.filter((item) => !exclude.includes(item[2])).concat(add);
};

export default ourCombinedAppMenuItems;