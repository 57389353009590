import { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleSavedAddress, RecordType } from '@petconsole/pure-base';
import { ourAddMemberImageUrls, ourMemberImageUrl } from '@petconsole/pure-fe-api';
import { ourAdaptedData } from '@petconsole/pure-fe-utils';
import { Image, Images, MaybeImage } from '@petconsole/pure-shared';
import { UseMaintStateProps } from '../types';

const useMaintState = <T extends RecordType = RecordType>({
  form,
  data,
  customData,
  getCustomData,
  getAvatar,
  setBanner,
  setImages,
  setFormValues,
  setEditing,
  customCancel,
}: UseMaintStateProps<T>) => {
  const addressValue = ((data?.address && (data.address as GoogleSavedAddress).description) || '') as string;

  const [addressInput, setAddressInput] = useState(addressValue);
  const [initialAddress, setInitialAddress] = useState(addressValue);
  const [initialBanner, setInitialBanner] = useState<Image>();
  const [initialImages, setInitialImages] = useState<Images>([]);

  const adaptedData = useMemo(() => ourAdaptedData({ ...data, ...customData }), [data, customData]);

  const initializeState = useCallback(() => {
    if (getAvatar) getAvatar();
    if (setBanner) {
      const dataBanner = { ...data?.banner as MaybeImage } as Image;

      if (dataBanner?.s3Key) dataBanner.src = ourMemberImageUrl(dataBanner.s3Key);

      const optionalBanner = dataBanner?.src ? dataBanner : undefined;

      setInitialBanner(optionalBanner);
      setBanner(optionalBanner);
    }
    if (setImages) {
      const images = ourAddMemberImageUrls({ images: (data?.images || []) as Images });
      setImages(images);
      setInitialImages(images);
    }
    setInitialAddress(addressValue);
    setAddressInput(addressValue);
    setFormValues((values) => ({ ...values, ...adaptedData, ...(getCustomData && getCustomData(data as T)) }));
  }, [getAvatar, setBanner, setImages, addressValue, setFormValues, data, adaptedData, getCustomData]);

  useEffect(() => {
    if (!data) return;

    initializeState();
  }, [data, initializeState]);

  const onCancel = useCallback(() => {
    setEditing(false);
    form.reset();
    initializeState();

    if (customCancel) customCancel();
  }, [form, initializeState, setEditing, customCancel]);

  return { addressInput, setAddressInput, initialAddress, initialBanner, initialImages, onCancel };
};

export default useMaintState;
