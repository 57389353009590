// noinspection ES6PreferShortImport

import { MaybeNull, newId, RecordOfString, RecordType } from '@petconsole/pure-base';
import { entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity } from '../../types';

interface ChangeSite {
  type: DataEntityType;
  changeSiteId: string;
  memberId: string;
  site: string;
  storage: RecordOfString;
}

export const changeSiteEntity = entityAttributes({
  name: entityName.changeSite,
  attributes: {
    columns: { crud: false, images: false, time: true },
    restrictGet: false,
    tabs: [],
    test: { invalidData: () => ({ site: {} }), validData: () => ({ site: `unittest-${newId()}` }) },
  },
});

const { id, json, site: schemaSite } = schema;
export const changeSiteSchema = entitySchema<ChangeSite>(changeSiteEntity, {
  memberId: id(true),
  site: schemaSite(),
  storage: json(),
});

export const changeSiteDefaults: Partial<ChangeSite> = {
  memberId: '',
  site: '',
  storage: {},
};

export interface ChangeSiteRecord extends ChangeSite, RecordType {}

export type MaybeChangeSiteRecord = MaybeNull<ChangeSiteRecord>;
export type ChangeSiteRecords = ChangeSiteRecord[];

export const initialChangeSite: InitialEntity<ChangeSite, ChangeSiteRecord> = (overrides = {}) =>
  initialEntity<ChangeSite>(changeSiteEntity, { ...changeSiteDefaults, ...overrides }) as ChangeSiteRecord;
