import { RecordType } from '@petconsole/pure-base';
import { Api, CityStatRecord, GetListOptions, GetListResults, cityStatEntity } from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = CityStatRecord;

const apiPath = '/cityStats';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

export interface CityStatApi<T extends RecordType = ApiRecord> extends Api<T> {
  getListByMembers: (options: GetListOptions) => Promise<GetListResults<T>>;
}

export const cityStatApi: CityStatApi = {
  ...common,
  getListByValue,
  getListByMembers: (options) => getListByValue('byMembers', '', options),
};

export const cityStatApiEntity /* : ApiEntity<ApiRecord> */ = { ...cityStatEntity, api: cityStatApi };
