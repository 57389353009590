import { MouseEventHandler } from 'react';
import { CardHeader, Skeleton, Typography } from '@mui/material';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { ourIsoToWeekDisplayDateTime } from '@petconsole/pure-fe-utils';
import { SetState } from '@petconsole/pure-shared';
import OurMemberAvatar from '../avatar/OurMemberAvatar';
import OurOptionsButton from '../buttons/OurOptionsButton';

interface OurPostCardHeaderProps {
  author?: string;
  createdAt?: string;
  onAuthor?: MouseEventHandler;
  onAvatar?: MouseEventHandler;
  optionsOpen: boolean;
  setOptionsOpen: SetState<boolean>;
}

const OurPostCardHeader = ({
  author,
  createdAt,
  onAuthor,
  onAvatar,
  optionsOpen,
  setOptionsOpen,
}: OurPostCardHeaderProps) => {
  const { memberName } = useCurrentMember();

  if (!memberName) return null;

  return (
    <CardHeader
      sx={{ pt: 1, pb: 0 }}
      avatar={<OurMemberAvatar name={author} onClick={onAvatar} />}
      title={
        <Typography
          color={onAuthor ? 'primary' : 'inherit'}
          component="span"
          onClick={onAuthor}
          sx={{ cursor: onAuthor ? 'pointer' : 'default' }}
        >
          {author || <Skeleton />}
        </Typography>
      }
      subheader={createdAt ? ourIsoToWeekDisplayDateTime(createdAt) : <Skeleton />}
      action={<OurOptionsButton isOpen={optionsOpen} setIsOpen={setOptionsOpen} />}
    />
  );
};

OurPostCardHeader.whyDidYouRender = true;

export default OurPostCardHeader;
