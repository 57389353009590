import { ChangeEvent } from 'react';
import { NumericFormat, NumericFormatProps, PatternFormatProps } from 'react-number-format';
import { OnValueChange } from 'react-number-format/types/types';
import getFormikValue from '../helpers/getFormikValue';
import OurText, { OurTextProps } from '../OurText';

export interface OurNumberProps
  extends Omit<OurTextProps, 'type'>,
    Pick<PatternFormatProps, 'mask'>,
    Pick<
      NumericFormatProps,
      'allowLeadingZeros' | 'allowNegative' | 'decimalSeparator' | 'thousandSeparator' | 'thousandsGroupStyle'
    > {
  decimals?: number;
  fixed?: boolean;
  format?: string;
  defaultValue?: NumericFormatProps['defaultValue'];
}

const OurNumber = ({
  decimals,
  fixed,
  allowLeadingZeros = true,
  allowNegative = false,
  thousandSeparator = true,
  onBlur,
  ...rest
}: OurNumberProps) => {
  const { name, id = '', formik, value: initialValue } = rest;
  const formikValue = formik ? getFormikValue({ formik, id }) : 0;
  const value = initialValue != null ? initialValue : formikValue != null ? formikValue : 0;
  const onChange = rest.onChange || (formik && formik.handleChange);

  const onValueChange: OnValueChange = (values) => {
    if (onChange)
      onChange({
        target: { name: name || id || '', value: values.formattedValue },
      } as unknown as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
  };

  return (
    <NumericFormat
      parentInput="NumericFormat" // can pass any property defined in customInput's interface
      value={value}
      customInput={OurText}
      onValueChange={onValueChange}
      thousandSeparator={thousandSeparator}
      decimalSeparator="."
      decimalScale={decimals}
      fixedDecimalScale={fixed}
      allowLeadingZeros={allowLeadingZeros}
      allowNegative={allowNegative}
      {...rest}
    />
  );
};

OurNumber.whyDidYouRender = true;

export default OurNumber;
