import { ElementType } from 'react';
import { MemberAvatarMenuItems } from '../../../types';
import OurAppMenuBar from './OurAppMenuBar';
import OurAppMenuDrawer from './OurAppMenuDrawer';

interface OurAppMenuProps {
  MenuBar: ElementType;
  MenuItems: ElementType;
  avatarMenuItems?: MemberAvatarMenuItems;
  drawerWidth: number;
}

const OurAppMenu = ({ MenuBar, MenuItems, avatarMenuItems, drawerWidth }: OurAppMenuProps) => {
  return (
    <>
      <OurAppMenuBar drawerWidth={drawerWidth}>
        <MenuBar avatarMenuItems={avatarMenuItems} />
      </OurAppMenuBar>
      <OurAppMenuDrawer drawerWidth={drawerWidth}>
        <MenuItems />
      </OurAppMenuDrawer>
    </>
  );
};

OurAppMenu.whyDidYouRender = true;

export default OurAppMenu;
