import { nowToIso } from '@petconsole/pure-base';
import { Image, MemberRecord } from '@petconsole/pure-shared';
import { ThunkFulfilledMethod } from '../../../types';
import memberToAvatar from './memberToAvatar';
import { UserExtraState } from './userSlice';

export const afterUserFulfilled: ThunkFulfilledMethod<{ user: UserExtraState }, MemberRecord> = (
  { user },
  { payload: member },
) => {
  user.lastFetch = nowToIso();

  if (member && JSON.stringify(member) !== JSON.stringify(user.member)) {
    if (member.avatar !== user.member?.avatar) user.avatar = memberToAvatar(member) as Image;

    user.member = member;
  }
};
