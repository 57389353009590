import { RecordType } from '@petconsole/pure-base';
import OurEntityPreview from '../entity/OurEntityPreview';
import { MaintPreviewProps } from './types';

const MaintPreview = <T extends RecordType = RecordType>({
  form,
  image,
  banner,
  photos,
  ...rest
}: MaintPreviewProps<T>) => {
  const entityData = form.getValues();

  return (
    <OurEntityPreview<T>
      {...rest}
      entityData={entityData}
      previewAvatar={image ?? undefined}
      previewBanner={banner ?? undefined}
      previewPhotos={photos ?? undefined}
    />
  );
};

MaintPreview.whyDidYouRender = true;

export default MaintPreview;
