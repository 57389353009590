import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone';
import { Container, Typography } from '@mui/material';

interface ImageZoneContainerProps {
  leadText?: string;
  maxFiles?: number;
  accept?: Accept;
  onDrop: DropzoneOptions['onDrop'];
  onRejected: DropzoneOptions['onDropRejected'];
}

const ImageZoneContainer = ({
  leadText,
  maxFiles = 1,
  accept = { 'image/*': ['.jpeg', '.png'] },
  onDrop,
  onRejected,
}: ImageZoneContainerProps) => {
  const multiple = maxFiles > 1;
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    onDropRejected: onRejected,
    accept,
    maxFiles,
    multiple,
    //TODO: Determine whether the following is needed. Review Sue's issue and https://react-dropzone.js.org/
    // noClick: true,
  });

  return (
    <Container
      disableGutters
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        p: 2.5,
        border: 'dashed 1px',
        borderRadius: '2px',
        outline: 'none',
      }}
      {...getRootProps()}
    >
      {leadText}
      {!leadText && maxFiles > 1 && (
        <Typography variant="body2" paragraph>
          {`You can add up to ${maxFiles} images`}
        </Typography>
      )}
      <input {...getInputProps()} />
      <Typography variant="body2" paragraph>
        {isDragActive && isDragAccept && `Drop the file${multiple ? 's' : ''} here...`}
        {isDragActive && isDragReject && 'File type not accepted'}
        {!isDragActive && "Drag 'n' drop an image file here, or click to select a file"}
      </Typography>
    </Container>
  );
};

ImageZoneContainer.whyDidYouRender = true;

export default ImageZoneContainer;
