import { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { ourCommentHooks } from '@petconsole/pure-fe-state';
import { RecordType } from '@petconsole/pure-shared';
import OurBusyBackdrop from '../components/misc/OurBusyBackdrop';
import OurFetchStatusControl from '../components/misc/OurFetchStatusControl';
import useComments from './helpers/useComments';
import useSaveComment from './helpers/useSaveComment';
import AddComment from './AddComment';
import CommentItem from './CommentItem';

interface OurCommentOnProps {
  commentOnId: string;
  commentOnType: string;
  heading?: string;
  locked?: boolean;
  onComment?: () => unknown;
  sizes?: RecordType;
  allowResets?: boolean;
}

const OurCommentOn = ({
  commentOnId,
  commentOnType,
  heading = 'Comments',
  locked,
  onComment,
  sizes = { xs: 12, sm: 10, md: 6 },
  allowResets = true,
}: OurCommentOnProps) => {
  const [busy, setBusy] = useState(false);

  const { comments, fetch, reset } = useComments({ commentOnId });
  const { saveComment } = useSaveComment({
    commentOnId,
    commentOnType,
    onComment,
    setBusy,
  });
  // ?? We need to disable resets on some comments, or they will behave badly on feed, blog, etc.
  const { commentHooks } = ourCommentHooks;
  const readError = commentHooks.useSelectReadError();
  const readStatus = commentHooks.useSelectReadStatus();
  const hasMore = commentHooks.useSelectHasMore();

  return (
    <Grid container item id="comment-on-container" justifyContent="center" textAlign="left">
      <Grid item {...sizes}>
        {heading && (
          <Typography variant={'body2'} align="center">
            <strong>{`${heading}${locked ? ' (Locked)' : ''}`}</strong>
          </Typography>
        )}
        <AddComment saveComment={saveComment} locked={locked} />
        <Grid container id="comments-container-grid" spacing={1} justifyContent="center">
          {comments.map((comment) => (
            <Grid item xs={12} key={`${comment.commentOnId}:${comment.commentId}:${comment.replyId}`}>
              <CommentItem comment={comment} saveComment={saveComment} locked={locked} />
            </Grid>
          ))}
        </Grid>
        <OurFetchStatusControl
          id={commentOnId}
          fetch={fetch}
          readError={readError}
          readStatus={readStatus}
          hasMore={hasMore}
          activity="Reading comments"
          endOfResults="End Of Comments"
          {...(allowResets && { reset })}
          resetMinutes={0}
        />
        <OurBusyBackdrop busy={busy} />
      </Grid>
    </Grid>
  );
};

OurCommentOn.whyDidYouRender = true;

export default OurCommentOn;
