import { useState } from 'react';

const useOurOnOpen = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  return { isOpen, setIsOpen, onOpen, onClose };
};

export default useOurOnOpen;
