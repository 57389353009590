import { PropsWithChildren, ReactNode } from 'react';
import { OurLinearProgress, OurMarginGridCard, OurToolbarProps } from '@petconsole/pure-fe-mui';
import { HelpProps } from '@petconsole/pure-shared';
import useMyShop from '../myShop/useMyShop';
import MyShopContainer from './MyShopContainer';

interface MyShopAppProps extends PropsWithChildren {
  title: string;
  helpProps?: Partial<HelpProps>;
  rightHeading?: ReactNode;
  toolbarProps?: OurToolbarProps;
  busy?: boolean;
}

const MyShopApp = ({ title, helpProps, rightHeading = '', toolbarProps, busy, children }: MyShopAppProps) => {
  const { superTitle, errorData, isLoading, isSuccess, isError } = useMyShop();

  return (
    <OurMarginGridCard
      busy={busy}
      leftMargins={{ lg: 2, xl: 3 }}
      rightMargins={{}}
      marginColumns={true} // need consistent margins whether on mobile or tablet
      padding={0.5}
    >
      <MyShopContainer
        superTitle={superTitle}
        title={title}
        rightHeading={rightHeading}
        helpProps={helpProps}
        toolbarProps={toolbarProps}
      >
        {isLoading && <OurLinearProgress />}
        {isError && <div>{JSON.stringify(errorData)}</div>}
        {isSuccess && children}
      </MyShopContainer>
    </OurMarginGridCard>
  );
};

MyShopApp.whyDidYouRender = true;

export default MyShopApp;
