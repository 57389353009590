// noinspection ES6PreferShortImport

import { MaybeNull, newId, RecordType } from '@petconsole/pure-base';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { DataEntityType, Images, InitialEntity, TimeColumns } from '../../../types';

export interface MailContent extends TimeColumns {
  type: DataEntityType;
  messageId: string;
  content: string;
  images: Images;
  json: RecordType;
}

export const mailContentEntity = entityAttributes({
  name: entityName.mailContent,
  attributes: {
    category: entityCategory.item,
    columns: { crud: false, time: true },
    idName: 'messageId',
    nameField: undefined,
    test: { invalidData: () => ({ content: {} }), validData: () => ({ content: 'New Content' }) },
  },
});

const { id, images, json, strim } = schema;
export const mailContentSchema = entitySchema<MailContent>(mailContentEntity, {
  messageId: id(true),
  content: strim().required('Message is required'),
  images: images(),
  json: json(),
});

export const mailContentDefaults: Partial<MailContent> = {
  messageId: newId(),
  content: '',
  images: [],
  json: {},
};

export interface MailContentRecord extends MailContent, RecordType {}

export type MaybeMailContentRecord = MaybeNull<MailContentRecord>;
export type MailContentRecords = MailContentRecord[];

export const initialMailContent: InitialEntity<MailContent, MailContentRecord> = (overrides = {}) =>
  initialEntity<MailContent>(mailContentEntity, { ...mailContentDefaults, ...overrides }) as MailContentRecord;
