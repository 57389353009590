import { ReactNode } from 'react';
import { Grid } from '@mui/material';
import { ApiUseGetQuery, OurEntityHooks } from '@petconsole/pure-fe-state';
import { EntityRecord, entityType } from '@petconsole/pure-shared';
import { ourDefaultMaxCardWidth } from '../../../constants';
import { EntityCardType, EntityCardsType } from '../../../types';
import { useIsThisYours } from '../../misc/isThisYours';
import OurEntityCard from './OurEntityCard';

interface OurEntityCardsProps<T extends EntityRecord = EntityRecord> {
  hooks: OurEntityHooks<T>;
  getEntityQueryHook: ApiUseGetQuery;
  subscribable?: boolean;
  entities: T[];
  ids: string[];
  defaultAvatar?: string;
  titleField?: string | string[];
  entityPath?: string;
  EntityCard?: EntityCardType;
  EntityCards?: EntityCardsType;
  byCity?: boolean;
  infoField?: string;
  headerOptions?: ReactNode;
}

const OurEntityCards = <T extends EntityRecord = EntityRecord>({
  hooks,
  getEntityQueryHook,
  subscribable,
  entities,
  ids,
  defaultAvatar,
  titleField,
  entityPath,
  byCity,
  infoField,
  headerOptions,
  EntityCard,
  EntityCards,
}: OurEntityCardsProps<T>) => {
  const { entity } = hooks;

  const { selectIdName, type } = entity;

  const { IsYours, Subscribe } = useIsThisYours<T>({ entity });

  // TODO: These should become obsolete
  if (EntityCards && type === entityType.Order) return <EntityCards entities={entities} />;

  return (
    <Grid container id="entity-cards-container" justifyContent="center" rowGap={2} pb={1}>
      {entities.map((entityRecord) => {
        const { [selectIdName]: id } = entityRecord;

        if (EntityCard)
          return (
            <EntityCard
              key={id as string}
              {...{ [selectIdName]: id as string, [entity.name]: entityRecord }}
              cardSx={{ maxWidth: ourDefaultMaxCardWidth }}
            />
          );

        return (
          <OurEntityCard<T>
            key={id as string}
            hooks={hooks}
            getEntityQueryHook={getEntityQueryHook}
            subscribable={subscribable}
            entityId={id as string}
            entityIds={ids}
            defaultAvatar={defaultAvatar}
            titleField={titleField}
            entityPath={entityPath}
            byCity={byCity}
            infoField={infoField}
            headerOptions={headerOptions}
            IsYours={IsYours}
            cardSx={{ maxWidth: ourDefaultMaxCardWidth }}
          />
        );
      })}
      <Subscribe />
    </Grid>
  );
};

OurEntityCards.whyDidYouRender = true;

export default OurEntityCards;
