import { RecordType } from '@petconsole/pure-base';
import { Api, MaybeRatingRecord, RatingRecord, ratingEntity, ApiEntity } from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = RatingRecord;

const apiPath = '/ratings';
const { getByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi extends RecordType {
  getByRated: (ratedId: string) => Promise<MaybeRatingRecord>;
}

export type RatingApi = Api<RatingRecord> & CustomApi;

export const ratingApi: RatingApi = {
  ...common,
  getByValue,
  getByRated: (ratedId) => getByValue('ratedId', ratedId) as Promise<MaybeRatingRecord>,
};

export const ratingApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...ratingEntity, api: ratingApi };
