import { ReactNode } from 'react';
import { CardContent, Collapse, Divider, List, Typography } from '@mui/material';

interface OurEntityCardOptionsProps {
  isOpen: boolean;
  heading?: string;
  children?: ReactNode;
}

const OurEntityCardOptions = ({ isOpen, heading = 'Options', children = null }: OurEntityCardOptionsProps) => {
  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <CardContent sx={{ p: [1, 2, 0], '&:last-child': { p: 0 } }}>
        <Divider sx={{ pt: 1, mb: 0.5 }} />
        <Typography variant="h6" gutterBottom align="center">
          {heading}
        </Typography>
        <List disablePadding component="nav" aria-label="options list">
          {children}
          <Divider variant="middle" />
        </List>
      </CardContent>
    </Collapse>
  );
};

OurEntityCardOptions.whyDidYouRender = true;

export default OurEntityCardOptions