import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';

interface OurExternalLinkProps {
  label: string | ReactNode;
  href: string;
}

const OurExternalLink = ({label, href}: OurExternalLinkProps) => {
  const {mode, primary} = useTheme().palette;
  const color = mode === 'dark' ? primary.light : primary.main;

  return (
    <a rel="noopener noreferrer" target="_blank" style={{color}} href={href}>
      {label}
    </a>
  );
};

OurExternalLink.whyDidYouRender = true;

export default OurExternalLink;
