import { Box, Grid, Typography } from '@mui/material';
import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import {
  OurAvatarView, ourBackgroundColor,
  OurButton,
  ourButtonBorderRadius,
  OurCheckBulletPoint,
  useOurDarkMode,
} from '@petconsole/pure-fe-mui';
import { PclTierPricingObject, PclTierRecord } from '@petconsole/pure-shared';
import LighterText from './LighterText';
import { OnPclTierSubscribe } from './PclTierPriceTable';

interface PclTierProps {
  pclTier: PclTierRecord;
  entityName?: string;
  avatar?: string;
  descriptionId: string;
  descriptionHeight: number;
  highlight?: boolean;
  currency: string;
  interval: string;
  onSubscribe: OnPclTierSubscribe;
}

const PclTier = ({
  pclTier,
  entityName,
  avatar,
  descriptionId,
  descriptionHeight,
  highlight,
  currency,
  interval,
  onSubscribe,
}: PclTierProps) => {
  const darkMode = useOurDarkMode();

  const backColor = ourBackgroundColor({ darkMode, darkAlpha: 0.09, lightAlpha: 0.09 });
  const highlightProps = {
    border: '1px solid rgb(255,255,255,0.50)',
    borderRadius: ourButtonBorderRadius,
    sx: { backgroundColor: backColor },
  };

  const { pclTierId } = pclTier;
  const image = avatar || ourMemberImageUrl(pclTier.avatar);
  const { available, name, description, features } = pclTier;
  const price = interval ? Number((pclTier.price as PclTierPricingObject)[currency][interval] || 0) : 0;
  const formattedPrice = price % 100 === 0 ? price / 100 : (price / 100).toFixed(2);

  return (
    <Box
      width={300}
      padding={2}
      marginBottom={1}
      justifyContent="center"
      {...(highlight && highlightProps)}
    >
      <Grid container justifyContent="center">
        <Grid container justifyContent="center">
          <OurAvatarView image={image} width={15} height={15} />
        </Grid>
        {entityName && (
          <Grid container justifyContent="center">
            <Typography variant="body1">{entityName}</Typography>
          </Grid>
        )}
        <Grid container justifyContent="center">
          <Typography variant="h6" paragraph>
            {name}
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography
            id={descriptionId}
            variant="body2"
            paragraph
            style={{
              opacity: 0.9,
              whiteSpace: 'pre-line',
              ...(descriptionHeight && { height: descriptionHeight }),
            }}
          >
            {description}
          </Typography>
        </Grid>
        <Grid container columnGap={1} paddingBottom={2} justifyContent="center">
          <Typography variant="h4">
            <strong>
              {currency.substring(0, 2)}${formattedPrice}
            </strong>
          </Typography>
          <Box>
            <LighterText text="per" />
            <LighterText text={`${interval.toLowerCase()}*`} />
          </Box>
        </Grid>
        <Grid container paddingBottom={2} justifyContent="center">
          <OurButton
            label={available ? 'Subscribe' : 'Coming Soon'}
            fullWidth={false}
            sx={{ width: '90%' }}
            onClick={() => onSubscribe({ pclTierId, interval, currency })}
            disabled={!available}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <LighterText text="Features include:" paragraph />
        {features &&
          features.split('\n').map((feature) => (
            <Grid container key={feature}>
              <Grid item xs={1}>
                <OurCheckBulletPoint />
              </Grid>
              <Grid item key={feature} xs={11}>
                <LighterText text={feature} />
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

PclTier.whyDidYouRender = true;

export default PclTier;
