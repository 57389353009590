import { RecordType } from '@petconsole/pure-base';
import { ApiEntity } from '@petconsole/pure-shared';
import { ApiUseCreateMutation } from '../../types';

interface UseCreateEntityProps<T extends RecordType = RecordType> {
  apiEntity: ApiEntity<T>;
  mutationHook: ApiUseCreateMutation<T>;
}

const useCreateEntity = <T extends RecordType = RecordType, R = RecordType>({
  apiEntity,
  mutationHook,
}: UseCreateEntityProps<T>) => {
  // Note: This separation is primarily to facilitate easier testing and mocking
  const [createEntityTrigger, mutationResults] = mutationHook();

  const createEntity = (data: T) => createEntityTrigger(data).unwrap() as Promise<T>;

  return { [`create${apiEntity.proper}`]: createEntity, ...mutationResults } as typeof mutationResults & R;
};

export default useCreateEntity;
