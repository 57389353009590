import { CartRecord } from '@petconsole/pure-shared';
import { useUpdateCartTotalMutation } from '../../../apiSlice';

interface UseUpdateCartTotalProps {
  cartId: string;
}

const useUpdateCartTotal = ({ cartId }: UseUpdateCartTotalProps) => {
  const [updateTotal, mutationResults] = useUpdateCartTotalMutation();

  const updateCartTotal = () => updateTotal({ cartId } as CartRecord).unwrap() as Promise<CartRecord>;

  return { updateCartTotal, ...mutationResults } as typeof mutationResults & { updateCartTotal: () => Promise<CartRecord> };
};

export default useUpdateCartTotal;
