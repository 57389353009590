import { RecordType } from '@petconsole/pure-base';

export const defaultListEntityFormValues = {
  name: '',
  ownerName: '',
  address: '',
  ratings: [],
  contactName: '',
  phoneNumber: '',
  email: '',
  website: '',
  domainName: '',
  urlName: '',
  ownerId: '',
  tagline: '',
  aboutUs: '',
  facebook: '',
  instagram: '',
  linkedIn: '',
  tikTok: '',
  twitter: '',
  youTube: '',
} as RecordType;
