import { Grid } from '@mui/material';
import { OurLazyLocationMap } from '../misc/maps';
import { MaintMapProps } from './types';

const MaintMap = ({ title = '', streetAddress = '', latitude, longitude }: MaintMapProps) => {
  return (
    <Grid item xs={12} sx={{ mt: -1, pr: 1, pb: 0.5 }}>
      <OurLazyLocationMap title={title} address={streetAddress} latitude={latitude} longitude={longitude} />
    </Grid>
  );
};

MaintMap.whyDidYouRender = true;

export default MaintMap;
