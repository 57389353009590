import { RecordType } from '@petconsole/pure-base';
import { NextAction, ThunkApi } from '../../types';
import { Api } from '@petconsole/pure-shared';

const payloadCreatorWithFetch =
  <T extends RecordType = RecordType>(idName: string, api: Api<T>, nextAction?: NextAction) =>
  async ({ [idName]: id }: RecordType, { dispatch }: ThunkApi) => {
    if (nextAction) dispatch(nextAction({ [idName]: id }));

    return (await (idName === 'memberName' ? api.getByName(id as string) : api.get(id as string))) as RecordType | null;
  };

export default payloadCreatorWithFetch;
