import { Directions, DirectionsOff } from '@mui/icons-material';
import ToggleButton, { ToggleButtonProps } from './ToggleButton';

interface OurDirectionsButtonProps extends Omit<ToggleButtonProps, 'OpenIcon' | 'CloseIcon' | 'tip'> {
  tip?: ToggleButtonProps['tip'];
}

const OurDirectionsButton = (props: OurDirectionsButtonProps) => (
  <ToggleButton
    {...props}
    tip="Directions"
    OpenIcon={Directions}
    CloseIcon={DirectionsOff}
  />
);

OurDirectionsButton.whyDidYouRender = true;

export default OurDirectionsButton;
