import { MouseEventHandler } from 'react';
import { Badge } from '@mui/material';
import MemberAvatar from './MemberAvatar';

interface MemberAvatarWithBadgeProps {
  badgeContent: number;
  onClick: MouseEventHandler;
}

const MemberAvatarWithBadge = ({ badgeContent, onClick }: MemberAvatarWithBadgeProps) => {
  return (
    <Badge badgeContent={badgeContent} color="secondary" showZero={false} sx={{ right: -3, top: 6 }}>
      <MemberAvatar onClick={onClick} />
    </Badge>
  );
};

MemberAvatarWithBadge.whyDidYouRender = true;

export default MemberAvatarWithBadge;
