import { MouseEventHandler } from 'react';
import { Avatar, Typography } from '@mui/material';
import { useCurrentMember } from '@petconsole/pure-fe-state';

interface MenuAvatarProps {
  onClick: MouseEventHandler;
}

const MemberAvatar = ({ onClick }: MenuAvatarProps) => {
  const { avatar } = useCurrentMember();

  return (
    <Avatar role="button" src={avatar.src} sx={{ cursor: 'pointer' }} onClick={onClick}>
      {avatar.text && <Typography variant="h4">{avatar.text}</Typography>}
    </Avatar>
  );
};

MemberAvatar.whyDidYouRender = true;

export default MemberAvatar;
