import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

const OutlinedFieldset = ({ children }: PropsWithChildren) => {
  return (
    <Box
      component="fieldset"
      aria-hidden
      sx={{
        textAlign: 'left',
        position: 'absolute',
        bottom: 0,
        right: 0,
        top: -5,
        left: 0,
        margin: 0,
        padding: '0 8px',
        pointerEvents: 'none',
        borderRadius: 'inherit',
        borderStyle: 'solid',
        borderWidth: 1,
        overflow: 'hidden',
        minWidth: '0%',
      }}
    >
      {children}
    </Box>
  );
};

OutlinedFieldset.whyDidYouRender = true;

export default OutlinedFieldset;
