import { ourFetchStatus, ourSaveStatus } from '../../constants';
import { ThunkPendingMethod } from '../../types';
import thunkReducerValues from './thunkReducerValues';

const thunkPendingReducer = (
  stateProperty: string,
  statusProperty = 'readStatus',
  idProperty = '',
) => {
  const errorProperty = statusProperty.replace('Status', 'Error');

  const pendingReducer: ThunkPendingMethod = (state, action = {}) => {
    const { target, id } = thunkReducerValues(state, stateProperty, action, idProperty);
    const status = statusProperty.startsWith('write')
      ? ourSaveStatus.saving
      : ourFetchStatus.loading;

    // In the case of updating ratings for an entity, we expect to have a "ratings" object, the status property starts
    // with "write", and the id property is the entity id, ie: if we are dealing with the ratings for a vet, the id
    // property will be vetId. All writes will update the same error and status properties. rather than by id
    const isRatingWrite =
      target?.ratings && statusProperty.startsWith('write') && idProperty === `${stateProperty}Id`;

    if (idProperty && id && !isRatingWrite) {
      target[errorProperty][id] = null;
      target[statusProperty][id] = status;
    } else {
      target[errorProperty] = null;
      target[statusProperty] = status;
    }

    // In the case of updating ratings for an entity, we expect to have a "rating" object in the "meta" data, and can
    // use it to optimistically update the state
    if (id && isRatingWrite) target.ratings[id].rating = action?.meta?.arg?.rating;
  };

  return pendingReducer;
};

export default thunkPendingReducer;
