import { skipToken } from '@reduxjs/toolkit/query';
import { shopItemOptionTemplateApiEntity } from '@petconsole/pure-fe-api';
import { ShopItemOptionTemplateRecord, ShopItemOptionTemplateRecords } from '@petconsole/pure-shared';
import { ApiUseGetListQuery } from '../../../../types';
import {
  useCreateShopItemOptionTemplateMutation,
  useGetShopItemOptionTemplateQuery,
  useGetShopItemOptionTemplatesByShopQuery,
  useUpdateShopItemOptionTemplateMutation,
} from '../../../apiSlice';
import useCreateEntity from '../../../helpers/useCreateEntity';
import useGetEntityItemsBy from '../../../helpers/useGetEntityItemsBy';
import useUpdateEntity from '../../../helpers/useUpdateEntity';

const apiEntity = shopItemOptionTemplateApiEntity;
type ApiRecord = ShopItemOptionTemplateRecord;

export const useCreateShopItemOptionTemplate = () =>
  useCreateEntity<ApiRecord>({
    apiEntity,
    mutationHook: useCreateShopItemOptionTemplateMutation,
  });
export const useGetShopItemOptionTemplate = ({ shopItemOptionTemplateId }: { shopItemOptionTemplateId: string }) =>
  useGetShopItemOptionTemplateQuery(shopItemOptionTemplateId || skipToken);
export const useGetShopItemOptionTemplatesByShop = ({ shopId }: { shopId: string }) =>
  useGetEntityItemsBy({
    apiEntity,
    value: shopId,
    queryHook: useGetShopItemOptionTemplatesByShopQuery,
  }) as ReturnType<ApiUseGetListQuery> & { shopItemOptionTemplates: ShopItemOptionTemplateRecords };
export const useUpdateShopItemOptionTemplate = () =>
  useUpdateEntity({
    apiEntity,
    mutationHook: useUpdateShopItemOptionTemplateMutation,
  });
