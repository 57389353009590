import { CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface OurEntityBannerImageProps {
  parentComponentId: string;
  image: string;
  alt: string;
}

const OurEntityBannerImage = ({ parentComponentId, image, alt }: OurEntityBannerImageProps) => {
  const theme = useTheme();

  const padding = 4;
  const themeSpacing = Number(theme.spacing().replace('px', ''));
  const width = document.getElementById(parentComponentId)?.clientWidth;
  const height = width ? Math.ceil(((width - padding * 2 * themeSpacing) * 9) / 16) : 'auto';

  return (
    <CardMedia
      component="img"
      image={image}
      alt={alt}
      width={width}
      height={height}
      sx={{ zIndex: 1, position: 'relative', px: padding }}
    />
  );
};

OurEntityBannerImage.whyDidYouRender = true;

export default OurEntityBannerImage;
