/**
 * This was based on a modified version of MUI's NotchedOutline internal component.
 * Specifically, a label and notched are assumed
 */
import { ReactNode } from 'react';
import OutlinedFieldset from './OutlinedFieldset';
import OutlinedLegend from './OutlinedLegend';

interface NotchedOutlineProps {
  label: ReactNode;
}

const NotchedOutline = ({ label }: NotchedOutlineProps) => {
  return (
    <OutlinedFieldset>
      <OutlinedLegend>
        <span>{label}</span>
      </OutlinedLegend>
    </OutlinedFieldset>
  );
};

NotchedOutline.whyDidYouRender = true;

export default NotchedOutline;
