import { ourFetchStatus, ourSaveStatus } from '../constants';
import { InitialRatingState, InitialReactionState, InitialReadWriteState, InitialWriteState } from '../types';

const initialWriteState: InitialWriteState = { writeError: null, writeStatus: ourSaveStatus.idle };

export const initialReadWriteState: InitialReadWriteState = {
  nextKey: null,
  readError: null,
  readStatus: ourFetchStatus.idle,
  readAllError: null,
  readAllStatus: ourFetchStatus.idle,
  ...initialWriteState,
};

export const initialRatingState: InitialRatingState = {
  rating: { ratings: {}, readError: {}, readStatus: {}, ...initialWriteState },
};

export const initialReactionState: InitialReactionState = { reactions: {} };
