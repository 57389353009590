import { PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { ourDefaultMaxCardWidth } from '../../constants';

const OurCardsContainer = ({ children }: PropsWithChildren) => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid container maxWidth={ourDefaultMaxCardWidth} direction="column" alignItems="center" rowGap={2}>
        {children}
      </Grid>
    </Grid>
  );
};

OurCardsContainer.whyDidYouRender = true;

export default OurCardsContainer;
