import { RecordType } from '@petconsole/pure-base';
import OutlinedGridContent from '../components/outlinedGrid/OutlinedGridContent';
import useMaintMainContent from './helpers/useMaintMainContent';
import MaintMain from './MaintMain';
import { MaintMainContentProps } from './types';

const MaintMainContent = <T extends RecordType = RecordType>({
  Main: CustomMain,
  mainOutline,
  mainLabel,
  mainSpacing,
  mainSx,
  mainProps,
  ...rest
}: MaintMainContentProps<T>) => {
  const { entity, data, directions, readOnly, isOwned } = rest;
  const { typeWords } = entity;

  const contentProps = { entity, data, directions, readOnly, isOwned };
  const { IsYoursGridItem, IsYoursDialog, DirectionsButton, DirectionsMap } = useMaintMainContent<T>(contentProps);
  const Main = CustomMain ?? MaintMain;

  return (
    <OutlinedGridContent
      id="main-outline"
      outline={mainOutline ?? true}
      label={mainLabel ?? typeWords}
      spacing={mainSpacing ?? 1}
      sx={mainSx ? mainSx : { pr: 0, pb: 0.5 }}
    >
      {IsYoursGridItem && <IsYoursGridItem />}
      <Main {...rest} DirectionsButton={DirectionsButton} {...mainProps} />
      {DirectionsMap && <DirectionsMap />}
      {IsYoursDialog && <IsYoursDialog />}
    </OutlinedGridContent>
  );
};

MaintMainContent.whyDidYouRender = true;

export default MaintMainContent;
