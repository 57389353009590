import { useCallback, useEffect, useMemo, useState } from 'react';
import { RecordType } from '@petconsole/pure-base';
import { ourAddMemberImageUrls } from '@petconsole/pure-fe-api';
import { ourAdaptedData } from '@petconsole/pure-fe-utils';
import { ExtendedFormik, Images, MaybeEntityRecord, ReturnsVoid, SetState } from '@petconsole/pure-shared';

interface UseOurListEntityStateProps {
  formik: ExtendedFormik;
  data: MaybeEntityRecord;
  customData?: RecordType;
  getCustomData?: () => RecordType;
  getAvatar?: () => Promise<void>;
  getBanner?: ReturnsVoid;
  setImages?: SetState<Images>;
  setFormValues: SetState<RecordType>;
  setEditing: SetState<boolean>;
  customCancel?: ReturnsVoid;
}

const useOurListEntityState = ({
  formik,
  data,
  customData,
  getCustomData,
  getAvatar,
  getBanner,
  setImages,
  setFormValues,
  setEditing,
  customCancel,
}: UseOurListEntityStateProps) => {
  const [addressInput, setAddressInput] = useState(data?.address?.description || '');

  const adaptedData = useMemo(() => ourAdaptedData({ ...data, ...customData }), [data, customData]);

  const initializeState = useCallback(() => {
    if (getAvatar) getAvatar().then();
    if (getBanner) getBanner();
    if (setImages) setImages(ourAddMemberImageUrls({ images: data?.images || [] }));
    setAddressInput(data?.address?.description || '');
    setFormValues((values) => ({
      ...values,
      ...adaptedData,
      ...(getCustomData && getCustomData()),
    }));
  }, [data, adaptedData, getCustomData, getAvatar, getBanner, setImages, setFormValues]);

  useEffect(() => {
    if (!data) return;

    initializeState();
  }, [data, initializeState]);

  const onCancel = useCallback(() => {
    setEditing(false);
    formik.resetForm();
    initializeState();

    if (customCancel) customCancel();
  }, [formik, initializeState, setEditing, customCancel]);

  return { initializeState, addressInput, setAddressInput, onCancel };
};

export default useOurListEntityState;
