// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface Sitter extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  sitterId: string;
  sitterName: string;
  appointments: string;
  hours: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const sitterEntity = entityAttributes({
  name: entityName.sitter,
  attributes: {
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    nameField: 'sitterName',
    site: anySite.petConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim, url } = schema;
export const sitterSchema = entitySchema<Sitter>(sitterEntity, {
  sitterName: strim().required('Sitter name is required'),
  appointments: url(),
  hours: strim(), // multi-line, free-text
});

export const sitterDefaults: Partial<Sitter> = {
  sitterName: '',
  appointments: '',
  hours: '',
};

export interface SitterRecord extends Sitter, RecordType {}

export type MaybeSitterRecord = MaybeNull<SitterRecord>;
export type SitterRecords = SitterRecord[];

export const initialSitter: InitialEntity<Sitter, SitterRecord> = (overrides = {}) =>
  initialEntity<Sitter>(sitterEntity, { ...sitterDefaults, ...overrides }) as SitterRecord;
