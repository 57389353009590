import { KeyboardEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import { EntityFormik, ExtendedFormik, OnBlur } from '@petconsole/pure-shared';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import TextInput, { TextInputProps } from '../../inputs/react-hook-form/TextInput';
import { TextFieldVariant } from '../../types';
import OurTooltip from '../misc/OurTooltip';
import getFieldProps from './helpers/getFieldProps';

export interface OurTextProps<T extends RecordType = RecordType> extends Omit<StandardTextFieldProps, 'variant'> {
  form?: UseFormReturn<T>;
  formik?: EntityFormik | ExtendedFormik;
  variant?: TextFieldVariant;
  tip?: string;
  label?: string | null;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => unknown;
  onBlur?: OnBlur;
  caps?: boolean;
  readOnly?: boolean;
  allowLastPass?: boolean;
  parentInput?: 'NumericFormat';
}

const OurText = <T extends RecordType = RecordType>(props: OurTextProps<T>) => {
  const { tip, allowLastPass, parentInput, value, ...rest } = props;

  const darkMode = useOurDarkMode();

  if (props.form) return <TextInput<T> {...props as TextInputProps<T>} />;

  // react-number-format is usually providing value "0.00". Should probably always ignore value if using Formik
  const fieldProps = getFieldProps({
    darkMode,
    // value: rest.formik && parentInput === 'NumericFormat' ? undefined : value,
    value,
    ...rest,
  });
  const { inputProps: newInputProps = {} } = fieldProps;

  // We generally don't want LastPass interfering with our input fields
  if (!allowLastPass)
    fieldProps.inputProps = { ...(newInputProps as RecordType), 'data-lpignore': true, 'data-1p-ignore': true };

  if (!tip) return <TextField {...fieldProps} />;

  return (
    <OurTooltip tip={tip}>
      <TextField {...fieldProps} />
    </OurTooltip>
  );
};

export default OurText;
