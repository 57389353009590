import { ourAdaptedData, ourUnadaptedData } from '@petconsole/pure-fe-utils';
import { EntityRecord, Image, Images } from '@petconsole/pure-shared';

interface SaveEntityProps {
  values: Partial<EntityRecord>;
  avatarPath?: string;
  images?: Images;
  save: (value: Partial<EntityRecord>) => Promise<EntityRecord>;
}

const saveEntityRecord = async ({ values, avatarPath, images = [], save }: SaveEntityProps) => {
  const { src, ...parsedThumbnail } = (values?.thumbnail || {}) as Image;

  // TODO: Images should be optional
  const parsedImages = images.map(({ imageId, title, s3Key, contentType }) => ({
    imageId,
    title,
    s3Key,
    contentType,
  }));

  const saved = await save({
    ...ourUnadaptedData(values),
    avatar: avatarPath, // TODO: avatar should be optional
    images: parsedImages, // TODO: Images should be optional
    ...(values.thumbnail ? { thumbnail: parsedThumbnail } : {}),
  });

  return { ...ourAdaptedData(saved), ...(values.thumbnail ? { thumbnail: values.thumbnail } : {}) } as EntityRecord;
};

export default saveEntityRecord;
