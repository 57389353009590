import { apiSlice } from '../api';
import { ourBlogReducer } from './blog';
import { ourBusBuilderReducer, ourBusReducer, ourBusRepairReducer, ourBusSupplyReducer } from './busConsole';
import { ourCartItemReducer, ourCartReducer } from './cart';
import { ourCityStatReducer } from './cityStat';
import { ourCommentReducer } from './comment';
import { ourFeedReducer } from './feed';
import { ourForumReducer, ourPostReducer, ourTopicReducer } from './forum';
import { ourFriendReducer } from './friend';
import { ourMailFolderReducer, ourMailHeaderReducer, ourMailMessageReducer } from './mail';
import { ourMemberReducer } from './member';
import { ourNotificationReducer } from './notification';
import { ourOrderLineReducer, ourOrderReducer } from './order';
import { ourPclHeaderReducer, ourPclServiceReducer, ourPclTierPriceReducer, ourPclTierReducer } from './pcl';
import {
  ourBusinessReducer,
  ourDogParkReducer,
  ourFeatureReducer,
  ourFoundPetReducer,
  ourGroomerReducer,
  ourLostPetReducer,
  ourPetFoodReducer,
  ourPetReducer,
  ourPetTrainerReducer,
  ourPetTypeReducer,
  ourPetWalkerReducer,
  ourPetWorkplaceReducer,
  ourPlaceReducer,
  ourSitterReducer,
  ourVetReducer,
} from './petConsole';
import {
  ourShopItemOptionTemplateReducer,
  ourShopItemReducer,
  ourShopItemVariantCodeReducer,
  ourShopItemVariantReducer,
  ourShopReducer,
} from './shop';
import {
  // ourAdminMenuReducer,
  ourGuestMenuReducer,
  ourLoggerReducer,
  ourMemberMenuReducer,
  ourSiteReducer,
  ourSnackReducer,
  ourUserReducer,
} from './system';
import { ourVanBuilderReducer, ourVanReducer, ourVanRepairReducer, ourVanSupplyReducer } from './vanConsole';

const consoleReducers = {
  blog: ourBlogReducer,
  cart: ourCartReducer,
  cartItem: ourCartItemReducer,
  cityStat: ourCityStatReducer,
  comment: ourCommentReducer,
  feed: ourFeedReducer,
  forum: ourForumReducer,
  friend: ourFriendReducer,
  guestMenu: ourGuestMenuReducer,
  logger: ourLoggerReducer,
  mailFolder: ourMailFolderReducer,
  mailHeader: ourMailHeaderReducer,
  mailMessage: ourMailMessageReducer,
  member: ourMemberReducer,
  memberMenu: ourMemberMenuReducer,
  notification: ourNotificationReducer,
  order: ourOrderReducer,
  orderLine: ourOrderLineReducer,
  pclHeader: ourPclHeaderReducer,
  pclService: ourPclServiceReducer,
  pclTier: ourPclTierReducer,
  pclTierPrice: ourPclTierPriceReducer,
  post: ourPostReducer,
  shop: ourShopReducer,
  shopItem: ourShopItemReducer,
  shopItemOptionTemplate: ourShopItemOptionTemplateReducer,
  shopItemVariant: ourShopItemVariantReducer,
  shopItemVariantCode: ourShopItemVariantCodeReducer,
  site: ourSiteReducer,
  snack: ourSnackReducer,
  topic: ourTopicReducer,
  user: ourUserReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
};

export const busConsoleReducers = {
  bus: ourBusReducer,
  busBuilder: ourBusBuilderReducer,
  busRepair: ourBusRepairReducer,
  busSupply: ourBusSupplyReducer,
  ...consoleReducers,
};

export const petConsoleReducers = {
  business: ourBusinessReducer,
  dogPark: ourDogParkReducer,
  feature: ourFeatureReducer,
  foundPet: ourFoundPetReducer,
  groomer: ourGroomerReducer,
  lostPet: ourLostPetReducer,
  pet: ourPetReducer,
  petFood: ourPetFoodReducer,
  petTrainer: ourPetTrainerReducer,
  petType: ourPetTypeReducer,
  petWalker: ourPetWalkerReducer,
  petWorkplace: ourPetWorkplaceReducer,
  place: ourPlaceReducer,
  sitter: ourSitterReducer,
  vet: ourVetReducer,
  ...consoleReducers,
};

export const vanConsoleReducers = {
  vanBuilder: ourVanBuilderReducer,
  vanRepair: ourVanRepairReducer,
  vanSupply: ourVanSupplyReducer,
  van: ourVanReducer,
  ...consoleReducers,
};

// export const adminReducers = {
//   adminMenu: ourAdminMenuReducer,
//   ...busConsoleReducers,
//   ...petConsoleReducers,
//   ...vanConsoleReducers,
// };

// export const adminRootReducer = combineReducers(adminReducers);
// export const busConsoleRootReducer = combineReducers(busConsoleReducers);
// export const petConsoleRootReducer = combineReducers(petConsoleReducers);
// export const vanConsoleRootReducer = combineReducers(vanConsoleReducers);
