import { useOurResetUserState, useOurSelectUserAuthenticated } from '@petconsole/pure-fe-state';

const useOurAuthenticated = () => {
  const authenticated = useOurSelectUserAuthenticated();
  const resetUserState = useOurResetUserState();

  return { authenticated, unAuthenticate: resetUserState };
};

export default useOurAuthenticated;
