import { ApiUseGetQuery } from '@petconsole/pure-fe-state';
import { EntityAttributes } from '@petconsole/pure-shared';
import { OurCityEntityTabPageProps } from '../OurCityEntityTabPage';

export interface OurCityEntityTabPropsProps
  extends Omit<OurCityEntityTabPageProps, 'getEntityQueryHook' | 'entityPath' | 'helpContent'> {
  entity: EntityAttributes;
  queryHook: ApiUseGetQuery;
  help?: string;
}

const ourCityEntityTabProps = ({ entity, queryHook, help = '', ...rest }: OurCityEntityTabPropsProps) => {
  const { path, subscribable } = entity;

  const props = {
    getEntityQueryHook: queryHook,
    entityPath: path,
    helpContent: help,
    subscribable,
    header: true,
    guestMode: true,
    ...rest,
  };

  return { props };
};

export default ourCityEntityTabProps;
