import AvatarEditor from 'react-avatar-editor';
import { ourPutImage } from '@petconsole/pure-fe-api';
import {
  EntityAttributes,
  EntityRecord,
  Image,
  ImageKey,
  Images,
  Log,
  SetBoolean,
  SetState,
} from '@petconsole/pure-shared';
import doWorkMethod from '../../misc/doWork/doWork';
import afterEntitySave from './afterEntitySave';
import getAvatarInfo from './getAvatarInfo';
import saveAvatar from './saveAvatar';
import saveEntityRecord from './saveEntityRecord';
import saveNewImages from './saveNewImages';
import saveThumbnail from './saveThumbnail';

export interface SaveEntityProps {
  entity: EntityAttributes;
  id: string;
  values: Partial<EntityRecord>;
  setValues: SetState<EntityRecord>;
  images?: Images;
  avatarKey?: ImageKey;
  avatarImage?: string;
  setAvatarImage?: SetState<string>;
  captureAvatar?: boolean;
  avatarChanged?: boolean;
  setAvatarChanged?: SetState<boolean>;
  avatarEditor?: AvatarEditor;
  bannerFrom?: () => Partial<Image>;
  saveBanner?: () => ReturnType<typeof ourPutImage>;
  adding?: boolean;
  setAdding?: SetState<boolean>;
  setEditing: SetState<boolean>;
  setCanEdit?: SetState<boolean>;
  setSubmitting: SetState<boolean>;
  setIsSubmitting?: SetBoolean;
  setBusy: SetState<boolean>;
  save: (value: Partial<EntityRecord>) => Promise<EntityRecord>;
  doWork: typeof doWorkMethod;
  log: Log;
  saveEntityCatch: (e: unknown, setIsSubmitting?: SetBoolean) => void;
}

const saveEntity = async ({
  entity,
  id,
  values,
  setValues,
  images = [],
  avatarKey,
  avatarImage,
  setAvatarImage,
  captureAvatar,
  avatarChanged,
  setAvatarChanged,
  avatarEditor,
  bannerFrom,
  saveBanner,
  adding,
  setAdding,
  setEditing,
  setCanEdit,
  setSubmitting,
  setIsSubmitting,
  setBusy,
  save,
  doWork,
  log,
  saveEntityCatch,
}: SaveEntityProps) =>
  (await doWork({
    tryCallback: async () => {
      const { avatarPath, avatarSrc } = getAvatarInfo({
        id,
        values,
        adding,
        avatarImage,
        avatarChanged,
        captureAvatar,
        avatarKey,
        avatarEditor,
      });
      const thumbnail = values?.thumbnail as Image | undefined;
      const updates = [] as Promise<void | EntityRecord>[];
      const safeImages = images.map(({ s3Key, ...image }) => ({
        ...image,
        s3Key: s3Key.replace('%id%', values[entity.idName] as string),
      }));

      updates.push(
        saveEntityRecord({
          values: { ...values, ...(bannerFrom ? { banner: bannerFrom() as Image } : {}) },
          avatarPath,
          images: safeImages,
          save,
        }),
      );
      updates.push(saveNewImages({ images: safeImages, log }));
      updates.push(saveAvatar({ avatarPath, avatarSrc, adding, avatarChanged, avatarKey, log }));
      updates.push(saveThumbnail({ thumbnail, log }));

      if (saveBanner)
        updates.push(
          saveBanner().then(() => {
            return;
          }),
        );

      const [saved] = await Promise.all(updates);

      afterEntitySave({
        saved: saved as EntityRecord,
        adding,
        captureAvatar,
        avatarChanged,
        setAvatarChanged,
        avatarSrc,
        setAvatarImage,
        setAdding,
        setEditing,
        setCanEdit,
        setSubmitting,
        setIsSubmitting,
        setValues,
      });

      return saved;
    },
    catchCallback: (e) => saveEntityCatch(e, setIsSubmitting),
    setBusy,
  })) as Promise<EntityRecord>;

export default saveEntity;
