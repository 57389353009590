import { ElementType } from 'react';
import { EntityTabName, EntityTab } from '@petconsole/pure-shared';
import { UseMaintTabsProps } from '../types';

const useMaintTabs = ({
  tabElements,
  tabProps,
  tabNames,
  tabDefinitions,
  tabHooks,
  setTabValue: customSetTabValue,
  isOwner,
}: UseMaintTabsProps) => {
  const defaultSetTabValue = tabHooks.useSet();
  const setTabValue = customSetTabValue || defaultSetTabValue;

  const tempTabs = {} as Record<EntityTabName, EntityTab>;

  const outLined = (name: EntityTabName, isOutlined = true) => tabDefinitions[name]?.outline ?? isOutlined;

  const addTab = (name: EntityTabName) => {
    const Tab = tabElements[name] as ElementType;
    const tabName = tabDefinitions[name]?.name || name;

    if (tabDefinitions[name]?.ownerOnly && !isOwner) return;

    tempTabs[name] = [tabName, <Tab /* {...baseProps} */ {...tabProps[name]} />, outLined(name)];
  };

  // Add tabs - not sorted by tabNames yet
  Object.keys(tabElements).forEach((key) => addTab(key as EntityTabName));

  const tabs = Object.keys(tabNames).map((name) => tempTabs[name as EntityTabName]);

  return { tabs, setTabValue };
};

export default useMaintTabs;
