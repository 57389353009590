import { createAsyncThunk } from '@reduxjs/toolkit';
import { mailHeaderEntity, MailHeaderRecord } from '@petconsole/pure-shared';
import { deleteEntityReducers } from '../../../helpers';
import { Slice, SliceReducers } from '../../../types';

const { name: sliceName, proper } = mailHeaderEntity;
const typePrefix = `${sliceName}/remove${proper}`;

export const removeMailHeaderPayloadCreator = async (messageId: string) =>
  Promise.resolve({ messageId });

export const removeMailHeaderThunk = createAsyncThunk(typePrefix, removeMailHeaderPayloadCreator);

export const removeMailHeaderReducers: SliceReducers<MailHeaderRecord> = (slice: Slice<MailHeaderRecord>) =>
  deleteEntityReducers<MailHeaderRecord>(slice, removeMailHeaderThunk);
