// noinspection ES6PreferShortImport

import { MutableRefObject, ReactElement, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Grid } from '@mui/material';
import { EntityAttributes, ExtendedFormik, Images, ReturnsVoid, SetState } from '@petconsole/pure-shared';
import OurDirectionsButton from '../components/buttons/OurDirectionsButton';
import OurGoogleAddress from '../components/google/OurGoogleAddress';
import OurText from '../components/inputs/OurText';
import useIsThisYours from '../components/misc/isThisYours/useIsThisYours';
import OutlinedGridContent from '../components/outlinedGrid/OutlinedGridContent';
import { OurLazyLocationMap } from '../misc/maps';

export interface OurListEntityMainProps {
  entity: EntityAttributes;
  formik: ExtendedFormik;
  initializeState?: ReturnsVoid;
  addressInput: string;
  setAddressInput: SetState<string>;
  avatarEditor?: MutableRefObject<AvatarEditor | undefined>,
  avatar?: string;
  setAvatar?: SetState<string>;
  setAvatarChanged?: SetState<boolean>;
  images: Images;
  setImages: SetState<Images>;
  imageZone: ReactElement;
  isOwned?: boolean;
  isOwner: boolean;
  isEditing: boolean;
  readOnly?: boolean;
  setBusy?: SetState<boolean>;
  setAddIsOpen?: SetState<boolean>;
  directions?: boolean;
}

const OurListEntityMain = ({
  entity,
  formik,
  addressInput,
  setAddressInput,
  isOwned,
  readOnly,
  directions = true,
}: OurListEntityMainProps) => {
  const { addField, addLabel, columns, nameField, typeWords, subscribable } = entity;
  const { IsYours, Subscribe } = useIsThisYours({ entity });

  const { isReadOnly } = formik;
  const { [nameField]: name, [addField]: title, address } = formik.values;
  const { description, latitude, longitude } = address || {}; // Null if address is cleared
  const streetAddress = (description || '').split(',')[0];

  const [mapOpen, setMapOpen] = useState(false);

  return (
    <OutlinedGridContent id="main-outline" label={typeWords} spacing={1} sx={{ pr: 0, pb: 0.5 }}>
      {subscribable && formik.isReadOnly && !isOwned && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <IsYours entityRecord={formik.values} />
        </Grid>
      )}
      <Grid item xs={12} display="flex" columnGap={1}>
        <OurText formik={formik} id={addField} label={addLabel} readOnly={readOnly} required />
        {directions && <OurDirectionsButton isOpen={mapOpen} setIsOpen={setMapOpen} disabled={!isReadOnly} />}
      </Grid>
      {columns.address && (
        <Grid item xs={12} sm={8} md={9}>
          <OurGoogleAddress
            formik={formik}
            id="address"
            label="Address/City"
            inputValue={addressInput}
            setInputValue={setAddressInput}
            readOnly={readOnly}
            required
          />
        </Grid>
      )}
      {mapOpen && isReadOnly && (
        <Grid item xs={12} sx={{ mt: -1, pr: 1, pb: 0.5 }}>
          <OurLazyLocationMap title={title || name} address={streetAddress} latitude={latitude} longitude={longitude} />
        </Grid>
      )}
      <Subscribe />
    </OutlinedGridContent>
  );
};

OurListEntityMain.whyDidYouRender = true;

export default OurListEntityMain;
