import { cartApi } from '@petconsole/pure-fe-api';
import { useAdjustCartItemsMutation } from '../../../apiSlice';

interface AdjustCartItemsProps {
  shopItemId: string;
  shopItemVariantId: string;
  adjustment: number;
}

interface UseAdjustCartItemsProps {
  cartId: string;
}

const useAdjustCartItems = ({ cartId }: UseAdjustCartItemsProps) => {
  const [adjustItems, mutationResults] = useAdjustCartItemsMutation();

  const adjustCartItems = ({ shopItemId, shopItemVariantId = '', adjustment = 1 }: AdjustCartItemsProps) =>
    adjustItems({ cartId, shopItemId, shopItemVariantId, adjustment }).unwrap() as ReturnType<
      typeof cartApi.adjustItems
    >;

  return { adjustCartItems, ...mutationResults };
};

export default useAdjustCartItems;
