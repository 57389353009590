// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { EntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface PetWorkplace extends RatingBusinessColumns, SubscribeColumns {
  type: EntityType;
  petWorkplaceId: string;
  name: string;
  businessType: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const petWorkplaceEntity = entityAttributes({
  name: entityName.petWorkplace,
  attributes: {
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    shared: true,
    site: anySite.petConsole,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const petWorkplaceSchema = entitySchema<PetWorkplace>(petWorkplaceEntity, {
  name: strim().required('Workplace name is required'),
  businessType: strim(),
});

export const petWorkplaceDefaults: Partial<PetWorkplace> = {
  name: '',
  businessType: '',
};

export interface PetWorkplaceRecord extends PetWorkplace, RecordType {}

export type MaybePetWorkplaceRecord = MaybeNull<PetWorkplaceRecord>;
export type PetWorkplaceRecords = PetWorkplaceRecord[];

export const initialPetWorkplace: InitialEntity<PetWorkplace, PetWorkplaceRecord> = (overrides = {}) =>
  initialEntity<PetWorkplace>(petWorkplaceEntity, { ...petWorkplaceDefaults, ...overrides }) as PetWorkplaceRecord;
