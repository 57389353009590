import { MouseEventHandler } from 'react';
import { CloudDownload } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import useReportError from '../../error/useReportError';
import OurGridButton from '../buttons/OurGridButton';
import OurLinearProgress from './OurLinearProgress';

interface OurFetchStatusControlProps {
  activity?: string;
  id?: string;
  fetch: MouseEventHandler;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  hasMore: boolean;
  error?: string | object | null;
  endOfResults?: string;
  moreLabel?: string;
  warn?: boolean;
}

const OurFetchMore = ({
  activity = 'Reading data',
  id = '',
  fetch,
  isLoading,
  isSuccess,
  isError,
  hasMore,
  error,
  endOfResults = 'End Of Results',
  moreLabel = 'More',
  warn = true,
}: OurFetchStatusControlProps) => {
  useReportError({ error, activity, id, warn });

  const errorText = typeof error === 'string' ? error : JSON.stringify(error);

  return (
    <Grid item xs={12} width="100%" justifyContent="center">
      {isLoading && <OurLinearProgress />}
      {isError && <div>{errorText}</div>}
      {isSuccess && hasMore && (
        <OurGridButton width="50%" label={moreLabel} onClick={fetch} startIcon={<CloudDownload />} />
      )}
      {isSuccess && !hasMore && endOfResults && <Typography align="center">{endOfResults}</Typography>}
    </Grid>
  );
};

OurFetchMore.whyDidYouRender = true;

export default OurFetchMore;
