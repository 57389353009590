// When changing palette colors, keep in mind the following places where color codes are being used:
// App.js - redBorders

import { inputLabelClasses } from '@mui/material/InputLabel';
// import { tabClasses } from '@mui/material/Tab';

const defaultBackground = '#303030';
const paperBackground = '#303030';
const lightPrimary = '#489FDF'; // Pantone: 2171 C
const mainPrimary = '#0069b1'; // Pantone: 2196 C
const darkPrimary = '#1D4F91'; // Pantone: 7686 C
const lightSecondary = '#F9423A'; // Pantone: Warm Red C
const mainSecondary = '#d22730'; // Pantone: 1795 C
const darkSecondary = '#C10016'; // Pantone: 3517 C
const lightError = '#ff6238';
const mainError = '#eb3505';
const darkError = '#dd2c00';

// const primary = '#0069b1'; // Allports (tools.picart.com)
// const secondary = '#d22730'; // Cardinal
// const accent1 = '#9201B7'; // Purple or #8700BE Purple
export const mainAccent1 = '#8700BE'; // Purple or #9201B7 Purple
// const accent2 = '#F26681'; // Froly
// const accent3 = '#F2D766'; // Cream Can

const palette = {
  primary: { light: lightPrimary, main: mainPrimary, dark: darkPrimary },
  secondary: { light: lightSecondary, main: mainSecondary, dark: darkSecondary },
  error: { light: lightError, main: mainError, dark: darkError },
};

const components = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        [`&.${inputLabelClasses.focused}`]: {
          color: palette.primary.light,
          fontSize: '1.1rem',
          fontWeight: 900,
        },
      },
    },
  },
  MuiLink: { defaultProps: { underline: 'hover' } }, // v5 = always
  MuiPaper: { styleOverrides: { root: { backgroundImage: 'unset' } } }, // v5 changed behavior
  // MuiTab: {
  //   styleOverrides: {
  //     root: ({ theme }) => ({
  //       width: 80,
  //       minWidth: 80,
  //       textTransform: 'none',
  //       fontSize: '1rem',
  //       [`&.${tabClasses.selected}`]: {
  //         color: 'white',
  //         backgroundColor: theme.palette.primary.main,
  //         borderRadius: theme.spacing(1.5, 1.5, 0, 0),
  //       },
  //       [`&:hover`]: {
  //         color: 'inherit',
  //         backgroundColor: 'rgba(0, 105, 177, 0.25)',
  //         borderRadius: theme.spacing(1.5, 1.5, 0, 0),
  //       },
  //     }),
  //   },
  // },
  MuiTextField: { defaultProps: { variant: 'standard' } }, // v5 = outlined
  // MuiTypography: {
  //   defaultProps: {
  //     variantMapping: {
  //       lobsterTwoH5: 'h5',
  //       lobsterTwoH6: 'h6',
  //     },
  //   },
  // },
};

export const pclColor = {
  defaultBackground,
  paperBackground,
  lightPrimary,
  mainPrimary,
  darkPrimary,
  lightSecondary,
  mainSecondary,
  darkSecondary,
  mainAccent1,
  lightError,
  mainError,
  darkError,
};

const lobsterTwo = '"Lobster Two", cursive';

export const ourLightThemeOptions = { components, palette: { ...palette, mode: 'light' } };

export const ourDarkThemeOptions = {
  components: { ...components },
  palette: {
    ...palette,
    background: { paper: paperBackground, default: defaultBackground },
    mode: 'dark',
  },
  // typography: { lobsterTwo: fontFamilyLobsterTwo },
  typography: {
    lobsterTwo: {
      fontFamily: lobsterTwo,
      fontStyle: 'italic',
    },
  },
  // typography: {
  //   lobsterTwoH5: {
  //     fontFamily: fontFamilyLobsterTwo,
  //     fontStyle: 'italic',
  //     fontWeight: 700,
  //   },
  //   lobsterTwoH6: {
  //     fontFamily: fontFamilyLobsterTwo,
  //     fontStyle: 'italic',
  //     fontWeight: 700,
  //   },
  // },
};

// noinspection UnnecessaryLocalVariableJS
const PclColor = pclColor;

export default PclColor;
