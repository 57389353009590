import {
  AccountCircle,
  Assignment,
  Groups,
  Inbox,
  Notifications,
  SettingsAccessibility,
  Store,
} from '@mui/icons-material';
import {
  SelectHook,
  SetHook,
  useOurSelectMemberMenuMyMenuOpen,
  useOurSetMemberMenuMyMenuOpen,
} from '@petconsole/pure-fe-state';
import { IsOpenProps, SetState, webPath } from '@petconsole/pure-shared';
import { AppMenuItems } from '../../../types';
import OurAppMenuCollapsable from './OurAppMenuCollapsable';
import ourCombinedAppMenuItems from './ourCombinedAppMenuItems';

interface OurMyMenuProps extends Partial<IsOpenProps> {
  add?: AppMenuItems;
  exclude?: string[];
}

const OurMyMenu = ({ isOpen, setIsOpen, add, exclude }: OurMyMenuProps) => {
  const menuItemsOpen = (useOurSelectMemberMenuMyMenuOpen as SelectHook<boolean>)();
  const setMenuItemsOpen = (useOurSetMemberMenuMyMenuOpen as SetHook<boolean>)();

  const menuItems = [
    [AccountCircle, 'Profile', webPath.profile],
    [Groups, 'Friends', webPath.myFriends],
    [Inbox, 'Mail', webPath.mailMessages],
    [Notifications, 'Notifications', webPath.myNotifications],
    [Assignment, 'Orders', webPath.myOrders],
    [Store, 'Shops', webPath.myShops],
  ] as AppMenuItems;

  const open = isOpen != null ? isOpen : menuItemsOpen;
  const setOpen = setIsOpen != null ? setIsOpen : setMenuItemsOpen as SetState<boolean>;
  const items = ourCombinedAppMenuItems({ items: menuItems, add, exclude });

  return (
    <OurAppMenuCollapsable
      open={open}
      setOpen={setOpen}
      Icon={SettingsAccessibility}
      label="My"
      items={items}
      divider={true}
    />
  );
};

OurMyMenu.whyDidYouRender = true;

export default OurMyMenu;
