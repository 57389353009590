// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { CrudColumns, GetPartialData, InitialEntity, PclTierPricingObject } from '../../types';

export interface PclTier extends CrudColumns {
  pclTierId: string;
  pclServiceId: string;
  tier: string;
  displayOrder: number;
  available: boolean;
  avatar: string;
  name: string;
  description: string;
  features: string;
  json: RecordType;
}

export const pclTierEntity = entityAttributes({
  name: entityName.pclTier,
  attributes: {
    adminOnly: { create: true, delete: true, update: true },
    category: entityCategory.item,
    columns: { images: false },
    loadLimit: 25,
    test: { invalidData: (() => ({ name: {} })) as GetPartialData, validData: () => ({ name: 'New Name' }) },
  },
});

const { boolean, id, number, strim } = schema;
export const pclTierSchema = entitySchema<PclTier>(pclTierEntity, {
  pclServiceId: id(),
  tier: strim().required(),
  displayOrder: number(1),
  available: boolean(true),
  avatar: strim(),
  name: strim().required(),
  description: strim(),
  features: strim(),
});

export const pclTierDefaults: Partial<PclTier> = {
  pclServiceId: '',
  tier: '',
  displayOrder: 0,
  available: true,
  avatar: '',
  name: '',
  description: '',
  features: '',
};

export interface PclTierRecord extends PclTier, RecordType {}

export type MaybePclTierRecord = MaybeNull<PclTierRecord>;
export type PclTierRecords = PclTierRecord[];

export interface PclTierWithPrice extends PclTierRecord {
  price: PclTierPricingObject;
}

export type PclTierWithPrices = PclTierWithPrice[];

export const initialPclTier: InitialEntity<PclTier, PclTierRecord> = (overrides = {}) =>
  initialEntity<PclTier>(pclTierEntity, { ...pclTierDefaults, ...overrides }) as PclTierRecord;
