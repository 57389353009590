import { useLayoutEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import OurText, { OurTextProps } from '../inputs/OurText';

interface OurMoreLessTextProps extends OurTextProps {
  value?: string;
}

const OurMoreLessText = ({ value = '', ...rest }: OurMoreLessTextProps) => {
  const [overflow, setOverflow] = useState(false);
  const [lessButton, setLessButton] = useState(false);
  const toggleLessButton = () => setLessButton(!lessButton);
  const textRef = useRef<HTMLElement>();

  // Determines if the passed element is overflowing its bounds, vertically or horizontally.
  // Will temporarily modify the "overflow" style to detect this if necessary.
  // Based on: https://stackoverflow.com/questions/143815/determine-if-an-html-elements-content-overflows
  const textOverflows = (ref: typeof textRef) => {
    const el = ref.current;

    if (!el?.style) return false;

    const curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

    const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
  };

  useLayoutEffect(() => {
    setOverflow(value.includes('\n') || textOverflows(textRef));
  }, [value]);

  const { InputProps } = rest;

  return (
    <OurText
      value={!overflow || lessButton ? value : value.split('\n')[0]}
      {...rest}
      inputRef={textRef}
      sx={{ overflow: 'hidden' }}
      readOnly
      multiline={lessButton}
      InputProps={{
        ...(InputProps || {}),
        endAdornment: (
          <Button
            size="small"
            onClick={toggleLessButton}
            sx={{ textTransform: 'none', color: 'inherit', ...(!overflow && { display: 'none' }) }}
          >
            {lessButton ? '...less' : '...more'}
          </Button>
        ),
      }}
    />
  );
};

OurMoreLessText.whyDidYouRender = true;

export default OurMoreLessText;
