import { cartApiEntity } from '@petconsole/pure-fe-api';
import { CartRecord } from '@petconsole/pure-shared';
import { appThunk } from '../../../store/appThunk';
import { StateType } from '../../../types';

const { api, name: sliceName, proper } = cartApiEntity;
const cartTypePrefix = `${sliceName}/fetch${proper}`;

const cartByShopPayloadCreator = async (shopId: string) => api.getByShop(shopId);

export const cartByShopThunk = appThunk(cartTypePrefix, cartByShopPayloadCreator);

export const selectCartByShop = (state: StateType, shopId: string) => {
  const cart = Object.values(state[sliceName][sliceName].entities).find(
    (entity) => (entity as CartRecord).shopId === shopId,
  );

  return cart || null;
};
