import { useCallback } from 'react';
import { CityLocation, RecordType } from '@petconsole/pure-base';
import { useAppDispatch } from '../../store/storeHooks';
import { AnyAsyncThunk } from '../../types';

export const useOurDispatchValue = (action: AnyAsyncThunk) => {
  const dispatch = useAppDispatch();

  return useCallback((value: string | boolean | RecordType | CityLocation) => dispatch(action(value) ), []);
};
