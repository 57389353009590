import useOurSearchParams from './useOurSearchParams';

const useOurSearchGet = (...params: string[]): Record<string, string | undefined> => {
  const searchParams = useOurSearchParams();

  return params.reduce((result, param) => {
    const value = searchParams.get(param);

    // Want to default values returned so return undefined vs null
    return { ...result, [param]: value === null ? undefined : value };
  }, {});
};

export default useOurSearchGet;
