import { ReactNode } from 'react';
import { Grid } from '@mui/material';
import { RecordTypes, newId } from '@petconsole/pure-base';
import OurToolbar, { OurToolbarProps } from '../misc/OurToolbar';
import OurHeading from './OurHeading';

export interface OurHeadingsProps {
  spacing?: number;
  widths?: Array<number>;
  contents?: Array<string | ReactNode>;
  headingProps?: RecordTypes;
  toolbarProps?: Partial<OurToolbarProps>;
}

const OurHeadings = ({ spacing = 2, widths = [3, 6, 3], contents = [], headingProps = [], toolbarProps }: OurHeadingsProps) => (
  <Grid container id="outer-grid-headings-container" spacing={spacing} display="flex" alignItems="center">
    {widths.map((width, index) => (
      <Grid
        item
        key={`headings-${newId()}`}
        xs={width}
        {...(widths.length > 1 && index === 0 ? { align: 'left' } : {})}
        {...(widths.length > 1 && index === widths.length - 1 ? { align: 'right' } : {})}
      >
        {contents[index] === 'toolbar' ? (
          <OurToolbar {...toolbarProps} />
        ) : typeof contents[index] === 'string' ? (
          <OurHeading
            {...(widths.length > 1 && index === 0 ? { align: 'left' } : {})}
            {...(widths.length > 1 && index === widths.length - 1 ? { align: 'right' } : {})}
            {...(headingProps.length > index && headingProps[index] ? headingProps[index] : {})}
          >
            {contents[index]}
          </OurHeading>
        ) : (
          contents[index]
        )}
      </Grid>
    ))}
  </Grid>
);

OurHeadings.whyDidYouRender = true;

export default OurHeadings;
