import { properCase, RecordType } from '@petconsole/pure-base';
import { ApiEntity } from '@petconsole/pure-shared';
import {
  CitySearchHooks,
  CrudSliverByHooks,
  CrudSliverHooks,
  RatingHooks,
  ReactionHooks,
  SliceDefaultPropertyHooks,
  SliceTabValueHooks,
  SliverByHooks,
  SliverHooks,
  UrlOrIdHooks,
} from '../types';

const sliverHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, prefix: string, hooks: RecordType) => {
  const { proper, pluralProper } = entity;
  const properPrefix = properCase(prefix);
  const selectNextHook = hooks[`useOurSelectNext${properPrefix}${proper}`];
  const selectPrevHook = hooks[`useOurSelectPrev${properPrefix}${proper}`];

  return <SliverHooks>{
    useFetch: hooks[`useOurFetch${properPrefix}${proper}`],
    useFetches: hooks[`useOurFetch${properPrefix}${pluralProper}`],
    useSelect: hooks[`useOurSelect${properPrefix}${proper}`],
    useSelects: hooks[`useOurSelect${properPrefix}${pluralProper}`],
    useSelectIds: hooks[`useOurSelect${properPrefix}${proper}Ids`],
    useSelectReadError: hooks[`useOurSelect${properPrefix}${proper}ReadError`],
    useSelectReadStatus: hooks[`useOurSelect${properPrefix}${proper}ReadStatus`],
    useSelectReadAllError: hooks[`useOurSelect${properPrefix}${proper}ReadAllError`],
    useSelectReadAllStatus: hooks[`useOurSelect${properPrefix}${proper}ReadAllStatus`],
    useSelectHasMore: hooks[`useOurSelect${properPrefix}${proper}HasMore`],
    useSelectWriteError: hooks[`useOurSelect${properPrefix}${proper}WriteError`],
    useSelectWriteStatus: hooks[`useOurSelect${properPrefix}${proper}WriteStatus`],
    useReset: hooks[`useOurReset${properPrefix}${proper}`],
    ...(selectNextHook ? { useSelectNext: selectNextHook } : {}),
    ...(selectPrevHook ? { useSelectPrev: selectPrevHook } : {}),
  };
};

const sliverByHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, prefix: string, hooks: RecordType) => {
  const { pluralProper } = entity;

  return <SliverByHooks>{
    ...sliverHooks(entity, prefix, hooks),
    useFetchesBy: hooks[`useOurFetch${pluralProper}By`],
    useSelectsBy: hooks[`useOurSelect${pluralProper}By`],
  };
};

const crudSliverHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, hooks: RecordType) => {
  const { proper } = entity;

  return <CrudSliverHooks>{
    useCreate: hooks[`useOurCreate${proper}`],
    useUpdate: hooks[`useOurUpdate${proper}`],
    useDelete: hooks[`useOurDelete${proper}`],
    ...sliverHooks(entity, '', hooks),
  };
};

const crudSliverByHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, hooks: RecordType) => {
  const { proper } = entity;

  return <CrudSliverByHooks>{
    useCreate: hooks[`useOurCreate${proper}`],
    useUpdate: hooks[`useOurUpdate${proper}`],
    useDelete: hooks[`useOurDelete${proper}`],
    ...sliverByHooks(entity, '', hooks),
  };
};

const urlOrIdHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, hooks: RecordType) => {
  const { proper } = entity;

  return <UrlOrIdHooks>{
    useFetch: hooks[`useOurFetch${proper}ByUrlOrId`],
    useSelect: hooks[`useOurSelect${proper}ByUrlOrId`],
  };
};

const citySearchHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, hooks: RecordType) => {
  const { proper } = entity;

  return <CitySearchHooks>{
    useSelectDefaulted: hooks[`useOurSelect${proper}CitySearchDefaulted`],
    useSelectInput: hooks[`useOurSelect${proper}CitySearchInput`],
    useSelectLocation: hooks[`useOurSelect${proper}CitySearchLocation`],
    useSelectChange: hooks[`useOurSelect${proper}CityChangeLocation`],
    useSetDefaulted: hooks[`useOurSet${proper}CitySearchDefaulted`],
    useSetInput: hooks[`useOurSet${proper}CitySearchInput`],
    useSetLocation: hooks[`useOurSet${proper}CitySearchLocation`],
    useSetChange: hooks[`useOurSet${proper}CityChangeLocation`],
  };
};

const tabValueHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, hooks: RecordType) => {
  const { proper, pluralProper } = entity;

  return <SliceTabValueHooks>{
    useSelectEntity: hooks[`useOurSelect${proper}TabValue`],
    useSelectMyEntity: hooks[`useOurSelectMy${proper}TabValue`],
    useSelectEntities: hooks[`useOurSelect${pluralProper}TabValue`],
    useSelectMyEntities: hooks[`useOurSelectMy${pluralProper}TabValue`],
    useSelectAdmin: hooks[`useOurSelect${proper}AdminTabValue`],
    useSetEntity: hooks[`useOurSet${proper}TabValue`],
    useSetMyEntity: hooks[`useOurSetMy${proper}TabValue`],
    useSetEntities: hooks[`useOurSet${pluralProper}TabValue`],
    useSetMyEntities: hooks[`useOurSetMy${pluralProper}TabValue`],
    useSetAdmin: hooks[`useOurSet${proper}AdminTabValue`],
  };
};

const tabPropertyHooks = (prefix: string, hooks: RecordType) => {
  const proper = properCase(prefix);

  return {
    useSelect: hooks[`useOurSelect${proper}TabValue`],
    useSet: hooks[`useOurSet${proper}TabValue`],
  };
};

const tabHooks = (keys: string[], hooks: RecordType) =>
  keys.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: tabPropertyHooks(currentValue, hooks),
    }),
    {},
  );

const propertyHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, hooks: RecordType) => {
  const { proper } = entity;

  return <SliceDefaultPropertyHooks>{
    useSelectEditing: hooks[`useOurSelect${proper}Editing`],
    useSelectSubmitting: hooks[`useOurSelect${proper}Submitting`],
    useSelectAddressInput: hooks[`useOurSelect${proper}AddressInput`],
    useSetEditing: hooks[`useOurSet${proper}Editing`],
    useSetSubmitting: hooks[`useOurSet${proper}Submitting`],
    useSetAddressInput: hooks[`useOurSet${proper}AddressInput`],
  };
};

const ratingHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, hooks: RecordType) => {
  const { proper } = entity;

  return <RatingHooks>{
    useSelect: hooks[`useOurSelect${proper}Rating`],
    useFetch: hooks[`useOurFetch${proper}Rating`],
    useUpdate: hooks[`useOurUpdate${proper}Rating`],
  };
};

const reactionHooks = <T extends RecordType = RecordType>(entity: ApiEntity<T>, hooks: RecordType) => {
  const { proper } = entity;

  return <ReactionHooks>{
    useSelect: hooks[`useOurSelect${proper}Reaction`],
    useFetch: hooks[`useOurFetch${proper}Reaction`],
    useUpdate: hooks[`useOurUpdate${proper}Reaction`],
    useCommentedOn: hooks[`useOurCommentedOn${proper}`],
    useSent: hooks[`useOurSent${proper}`],
    useShared: hooks[`useOurShared${proper}`],
    useToggleLike: hooks[`useOurToggleLike${proper}`],
  };
};

export const sliceHookTypes = {
  crudSliver: crudSliverHooks,
  crudHooks: crudSliverHooks,
  crudByHooks: crudSliverByHooks,
  sliver: sliverHooks,
  sliverHooks,
  sliverByHooks,
  urlOrId: urlOrIdHooks,
  urlOrIdHooks,
  tabValue: tabValueHooks, // deprecate this one
  tabValueHooks,
  tabPropertyHooks, // deprecate the export of this one
  tabHooks,
  citySearch: citySearchHooks,
  citySearchHooks,
  property: propertyHooks,
  propertyHooks,
  rating: ratingHooks,
  ratingHooks,
  reaction: reactionHooks,
  reactionHooks,
};
