import { RecordType } from '@petconsole/pure-base';
import { Api, apiMethodType, EntityType } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../types';

const createOne = <T extends RecordType = RecordType>(builder: ApiBuilder<T>, api: Api<T>, tag: EntityType) =>
  builder.mutation<T, T>({
    query: (body) => ({ type: apiMethodType.create, method: api.create, body }),
    invalidatesTags: (_result, _error, body) => [
      tag,
      ...(body[`${tag}Id`] ? [{ type: tag, id: body[`${tag}Id`] as string }] : []),
    ],
  });

export default createOne;
