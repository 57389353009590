import { ElementType } from 'react';
import { useOurProfileWizardRedirect } from '@petconsole/pure-fe-utils';
import { ourDefaultMemberDrawerWidth } from '../../../constants';
import useOurCurrentMemberRefresh from '../../../hooks/useOurCurrentMemberRefresh';
import { MemberAvatarMenuItems } from '../../../types';
import OurAppMenu from '../appMenu/OurAppMenu';
import MemberMenuBar from './MemberMenuBar';

interface OurMemberAppProps {
  MenuItems: ElementType;
  avatarMenuItems: MemberAvatarMenuItems;
  drawerWidth?: number;
}

const OurMemberApp = ({ MenuItems, avatarMenuItems, drawerWidth = ourDefaultMemberDrawerWidth }: OurMemberAppProps) => {
  const { member } = useOurCurrentMemberRefresh();

  useOurProfileWizardRedirect({ member});

  return (
    <OurAppMenu
      MenuBar={MemberMenuBar}
      MenuItems={MenuItems}
      avatarMenuItems={avatarMenuItems}
      drawerWidth={drawerWidth}
    />
  );
};

OurMemberApp.whyDidYouRender = true;

export default OurMemberApp;
