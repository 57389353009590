import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OurDecimal, { OurDecimalProps } from './OurDecimal';

interface OurDecimalGridItemProps extends OurDecimalProps, RegularBreakpoints {
  gridProps?: GridProps;
}

const OurDecimalGridItem = ({ gridProps, xs = 12, sm, md, lg, xl, ...rest }: OurDecimalGridItemProps) => {
  return (
    <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
      <OurDecimal {...rest} />
    </Grid>
  );
};

OurDecimalGridItem.whyDidYouRender = true;

export default OurDecimalGridItem;
