import { CartAdjustItemsProps, CartAdjustItemsResult, cartApi, cartItemApiEntity } from '@petconsole/pure-fe-api';
import { apiMethodType, CartItemRecord, dataEntityType, PostMethod } from '@petconsole/pure-shared';
import { ApiBuilder, RecordType } from '../../../../types';
import { createOne, getListBy, getOne, updateOne } from '../../../helpers';

interface EntityEndpointsProps<T extends RecordType = CartItemRecord> {
  builder: ApiBuilder<T>;
}

const cartItemEndpoints = ({ builder }: EntityEndpointsProps) => {
  const { api, proper: tag } = cartItemApiEntity;

  return {
    adjustCartItems: (builder as unknown as ApiBuilder).mutation<CartAdjustItemsResult, CartAdjustItemsProps>({
      query: (input) => ({ type: apiMethodType.post, method: cartApi.adjustItems as unknown as PostMethod, input }),
      invalidatesTags: (_result, _error, { cartId }) => [
        dataEntityType.Cart,
        { type: dataEntityType.Cart, id: cartId },
        tag,
      ],
    }),
    createCartItem: createOne(builder, api, tag),
    getCartItem: getOne(builder, api, tag),
    getCartItemsByCart: getListBy(builder, api.getListByCart, tag),
    updateCartItem: updateOne(builder, api.update, tag),
  };
};

export default cartItemEndpoints;
