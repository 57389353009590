import { RecordType } from '@petconsole/pure-base';
import { storageGet } from '@petconsole/pure-fe-amplify';
import { ourBlobToDataUrl } from '@petconsole/pure-fe-utils';
import { Log } from '@petconsole/pure-shared';

interface ourGetImageProps {
  s3Key: string;
  log: Log;
  config?: RecordType;
}

// download: true returns object data rather than pre-signed URL
const ourGetImage = async ({ s3Key, log, config = {} }: ourGetImageProps) => {
  if (!s3Key) return null;
  // if (!s3Key) throw new Error('ourGetImage missing key');

  return storageGet({ key: s3Key, options: config })
    .then(async (storageResult) => {
      if (typeof storageResult !== 'object') return storageResult;

      const { body, contentType } = storageResult;

      if (body instanceof Blob) {
        const blob = await body.blob();

        return ourBlobToDataUrl({ blob, log });
      }

      if (contentType === 'image/png') return body.text();

      // if (typeof body === 'string') return body;

      const blob = await body.blob();

      return ourBlobToDataUrl({ blob, log });
    })
    .catch((e) => {
      log(`ourGetImage error on ${s3Key}:`, e);

      return null;
      // const message = e instanceof Error ? e.message : String(e);

      // throw new Error(`ourGetImage error: ${message}`);
    });
};

export default ourGetImage;
