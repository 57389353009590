import { ourUomLengths, ourUomQuantities, ourUomType, OurUomType, ourUomWeights } from '@petconsole/pure-shared';
import OurSelect, { OurSelectProps } from './OurSelect';

export interface OurUomProps extends Omit<OurSelectProps, 'selectOptions'> {
  type: OurUomType;
}

const OurUom = ({ type, ...rest }: OurUomProps) => {
  const { Length, Quantity, Weight } = ourUomType;

  let selectOptions = [] as string[];

  switch (type) {
    case Length:
      selectOptions = [...ourUomLengths];
      break;
    case Quantity:
      selectOptions = [...ourUomQuantities];
      break;
    case Weight:
      selectOptions = [...ourUomWeights];
      break;
    default:
      throw new Error(`Invalid UOM Type ${type}`);
  }

  return <OurSelect {...rest} selectOptions={selectOptions} />;
};

OurUom.whyDidYouRender = true;

export default OurUom;
