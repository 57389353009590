import { RecordType } from '@petconsole/pure-base';
import { Api, ApiEntity, GetListOptions, GetListResults, vanEntity, VanRecord } from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = VanRecord;

const apiPath = '/vans';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getListByOwner: ({ ownerId }: { ownerId: string }, options?: GetListOptions) => Promise<GetListResults<T>>;
  getListByVanName: ({ vanName }: { vanName: string }, options?: GetListOptions) => Promise<GetListResults<T>>;
}

export type VanApi = Api<ApiRecord> & CustomApi;

export const vanApi: VanApi = {
  ...common,
  getListByValue,
  // getListByOwner may be deprecated by getMyList
  getListByOwner: ({ ownerId }, options) => getListByValue('ownerId', ownerId, options),
  getListByVanName: ({ vanName }, options) => getListByValue('vanName', vanName, options),
};

export const vanApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...vanEntity, api: vanApi };
