import { useCallback, useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Grid, Typography } from '@mui/material';
import { SetState } from '@petconsole/pure-shared';
import OurAvatarEdit, { MaybeAvatarEditor } from './OurAvatarEdit';
import OurAvatarView from './OurAvatarView';

interface OurAvatarViewEditProps {
  heading?: string;
  avatar?: string;
  avatarImage?: string; // deprecate - use avatar
  setAvatar?: SetState<string>;
  setAvatarImage?: SetState<string>; // deprecate - use setAvatar
  setAvatarChanged: SetState<boolean>;
  setAvatarEditor: (editor: AvatarEditor) => AvatarEditor;
  readOnly: boolean;
  width?: number;
  height?: number;
}

const OurAvatarViewEdit = ({
  heading = 'Profile Photo',
  avatar,
  avatarImage,
  setAvatar,
  setAvatarImage,
  setAvatarChanged,
  setAvatarEditor,
  readOnly,
  width = 25,
  height = 25,
}: OurAvatarViewEditProps) => {
  const [editor, setEditor] = useState(undefined as AvatarEditor | undefined);

  useEffect(() => {
    return () => {
      // WARNING: Attempting to destructure rather than immediately getting image, broke this
      if (editor) {
        if (setAvatar) setAvatar(editor.getImageScaledToCanvas().toDataURL());
        else if (setAvatarImage) setAvatarImage(editor.getImageScaledToCanvas().toDataURL());
      }
    };
  }, [setAvatar, setAvatarImage, editor]);

  const onSetAvatar = useCallback(
    (value: AvatarEditor) => {
      setEditor(value);
      setAvatarEditor(value);
    },
    [setAvatarEditor],
  );

  return (
    <Grid container direction="column" alignItems="center">
      {heading && (
        <Typography variant="h6" paragraph>
          {heading}
        </Typography>
      )}
      {readOnly ? (
        <OurAvatarView image={avatar || avatarImage} width={width} height={height} />
      ) : (
        (avatar || !!avatarImage) && (
          <OurAvatarEdit
            avatar={avatar || avatarImage || ''}
            setChanged={setAvatarChanged}
            setEditor={onSetAvatar as SetState<MaybeAvatarEditor>}
          />
        )
      )}
      {readOnly && !avatar && !avatarImage && <Typography pt={1}>No avatar added</Typography>}
    </Grid>
  );
};

OurAvatarViewEdit.whyDidYouRender = true;

export default OurAvatarViewEdit;
