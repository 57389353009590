import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { RecordType } from '@petconsole/pure-base';
import { Api } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import { FetchByUrlOrIdPayloadCreator, NextAction } from '../../types';
import payloadCreatorByUrlOrIdWithFetch from '../payloadCreators/payloadCreatorByUrlOrIdWithFetch';

interface FetchEntityByUrlOrIdThunkProps<T extends RecordType = RecordType> {
  sliceName: string;
  entityType: string;
  idName: string;
  api: Api<T>;
  fetch?: NextAction;
}

const fetchEntityByUrlOrIdThunk = <Returned extends RecordType, ThunkArg>({
  sliceName,
  entityType,
  idName,
  api,
  fetch,
}: FetchEntityByUrlOrIdThunkProps<Returned>) =>
  appThunk(
    `${sliceName}/fetch${entityType}ByUrlOrId`,
    (payloadCreatorByUrlOrIdWithFetch as FetchByUrlOrIdPayloadCreator<Returned>)(idName, api, fetch) as AsyncThunkPayloadCreator<Returned, ThunkArg>,
  );

export default fetchEntityByUrlOrIdThunk;
