// noinspection ES6PreferShortImport

import { MaybeNull, MaybeString, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity } from '../../types';

export interface Topic extends CrudColumns {
  type: DataEntityType;
  topicId: string;
  forumId: string;
  order: number;
  memberId: string;
  title: string;
  description: string;
  private: boolean;
  locked: boolean;
  postCount: number;
  replyCount: number;
  firstPostAt: MaybeString;
  firstPostId: string;
  lastPostAt: MaybeString;
  lastPostId: string;
}

export const topicEntity = entityAttributes({
  name: entityName.topic,
  attributes: {
    category: entityCategory.item,
    columns: { images: false, json: true },
    loadLimit: 20,
    nameField: undefined,
    test: { invalidData: () => ({ title: {} }), validData: () => ({ title: 'New Text' }) },
  },
});

const { boolean, date, id, number, strim } = schema;
export const topicSchema = entitySchema<Topic>(topicEntity, {
  forumId: id(true), // parent
  order: number(0),
  memberId: id(true), // same as createdId, but easier to think about
  title: strim(), // topic title
  description: strim(),
  private: boolean(),
  locked: boolean(),
  postCount: number(0),
  replyCount: number(0),
  firstPostAt: date(), // post.feedAt
  firstPostId: id(),
  lastPostAt: date(), // post.feedAt
  lastPostId: id(),
});

export const topicDefaults: Partial<Topic> = {
  forumId: '',
  order: 0,
  memberId: '',
  title: '',
  description: '',
  private: false,
  locked: false,
  postCount: 0,
  replyCount: 0,
  firstPostAt: null,
  firstPostId: '',
  lastPostAt: null,
  lastPostId: '',
};

export interface TopicRecord extends Topic, RecordType {}

export type MaybeTopicRecord = MaybeNull<TopicRecord>;
export type TopicRecords = TopicRecord[];

export const initialTopic: InitialEntity<Topic, TopicRecord> = (overrides = {}) =>
  initialEntity<Topic>(topicEntity, { ...topicDefaults, ...overrides }) as TopicRecord;
