import { SxProps } from '@mui/material';
import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import OurCardImage from '../cards/OurCardImage';

interface OurMemberImageProps {
  image: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  isPrivate?: boolean;
  sx?: SxProps;
}

const OurMemberImage = ({ image, isPrivate = false, ...rest }: OurMemberImageProps) => (
  <OurCardImage image={ourMemberImageUrl(image, isPrivate)} {...rest} />
);

OurMemberImage.whyDidYouRender = true;

export default OurMemberImage;
