import { NavigateNext } from '@mui/icons-material';
import OurIconLink, { OurIconLinkProps } from './OurIconLink';

interface OurNextLinkProps extends Omit<OurIconLinkProps, 'tip' | 'Icon'> {
  tip?: OurIconLinkProps['tip'];
  Icon?: OurIconLinkProps['Icon'];
}

const OurNextLink = ({ tip = 'Next', Icon = NavigateNext, fontSize = 'large', ...rest }: OurNextLinkProps) => (
  <OurIconLink tip={tip} Icon={Icon} fontSize={fontSize} {...rest} />
);

OurNextLink.whyDidYouRender = true;

export default OurNextLink;
