import { RecordType } from '@petconsole/pure-base';
import { Api, GetListOptions, GetListResults, PetRecord, petEntity, ApiEntity } from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = PetRecord;

const apiPath = '/pets';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getListByType: (type: string, options?: GetListOptions) => Promise<GetListResults<T>>;
}

export type PetApi = Api<ApiRecord> & CustomApi;

export const petApi: PetApi = {
  ...common,
  getListByValue,
  getListByType: async (type, options) => getListByValue('type', type, options),
};

export const petApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...petEntity, api: petApi };
