// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import { FormikEntityPreviewProps } from '../types';
import OurEntityPreview from './OurEntityPreview';
import { OurEntityPreviewProps } from './OurEntityPreview';

export type BasicEntityPreviewProps<T extends RecordType = RecordType> = FormikEntityPreviewProps & Omit<OurEntityPreviewProps<T>, 'entityData'>;

const BasicEntityPreview = <T extends RecordType = RecordType>({ formik, image, banner, photos, ...rest }: BasicEntityPreviewProps<T>) => (
  <OurEntityPreview<T>
    {...rest}
    entityData={formik.values as T}
    previewAvatar={image}
    previewBanner={banner}
    previewPhotos={photos}
  />
);

BasicEntityPreview.whyDidYouRender = true;

export default BasicEntityPreview;
