import OurNumber, { OurNumberProps } from './OurNumber';

export type OurIntegerProps = Omit<OurNumberProps, 'decimalSeparator' | 'decimals' | 'fixed'>;

const OurInteger = ({
  allowLeadingZeros = false,
  allowNegative = false,
  thousandSeparator = true,
  ...rest
}: OurIntegerProps) => {
  return (
    <OurNumber
      decimals={0}
      allowLeadingZeros={allowLeadingZeros}
      allowNegative={allowNegative}
      thousandSeparator={thousandSeparator}
      {...rest}
    />
  );
};

OurInteger.whyDidYouRender = true;

export default OurInteger;
