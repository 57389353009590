import { ChangeEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import getInputProps, { GetInputProps } from './helpers/getInputProps';

export interface CheckboxInputProps<T extends RecordType = RecordType>
  extends Omit<GetInputProps<T>, 'darkMode' | 'isTouched'> {
  form: UseFormReturn<T>;
}

const CheckboxInput = <T extends RecordType = RecordType>({ form, ...rest }: CheckboxInputProps<T>) => {
  const darkMode = useOurDarkMode();

  const { label, InputProps } = getInputProps({ ...rest } as GetInputProps<T>);
  const readOnly = InputProps?.readOnly ?? false;

  return (
    <Controller
      control={form.control}
      name={rest.id}
      // rules={ required, min, max, minLength, maxLength, pattern, validate }
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched, error: fieldError } }) => {
        const props = getInputProps({
          ...rest,
          darkMode,
          onChange,
          onBlur,
          value: rest.value ?? value,
          ref,
          isTouched,
          fieldError,
        });
        delete props.error;
        delete props.helperText;
        delete props.fullWidth;
        delete props.InputProps;

        const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
          if (!readOnly) onChange(e, checked);
        };

        return (
          <FormControlLabel
            control={
              <Checkbox
                {...(props as CheckboxProps)}
                ref={props.ref as CheckboxProps['ref']}
                value={Boolean(props.value)}
                checked={Boolean(props.value)}
                onChange={onCheckboxChange}
                readOnly={readOnly}
                disabled={props.disabled ?? false}
                disableRipple={readOnly}
                color="primary"
                sx={{ cursor: readOnly || props.disabled ? 'default' : 'pointer' }}
              />
            }
            label={label as string | undefined}
            sx={{ cursor: readOnly || rest.disabled ? 'default' : 'pointer', mr: 0 }}
          />
        );
      }}
    />
  );
};

export default CheckboxInput;
