import { properCase } from '@petconsole/pure-base';
import { GetListByIdMethod, GetListResults, GetMethod } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import { RecordType, Slice, ThunkApi } from '../../types';
import fetchParams from '../misc/fetchParams';

const sliceFetchByPropertyThunks = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  const { name: sliceName, proper, pluralProper, idName, option, thunk, api } = slice;
  const { fetchRating, fetchReaction } = thunk;
  const actionCreator = fetchRating || fetchReaction;
  const { fetchOneByProperty: fetchOne, fetchManyByProperty: fetchMany } = option;
  const thunks: RecordType = {};

  if (fetchOne) {
    const { propertyName, apiMethod, callback } = fetchOne;
    // creator copied from payloadCreatorWithFetch
    thunks.fetchOneThunk = appThunk(
      `${sliceName}/fetch${proper}By${properCase(propertyName)}`,
      async (value: string, { dispatch }) => {
        const fetchApi = api.entity;
        const fetchMethod = fetchApi[apiMethod] as GetMethod<T>;
        const data = await fetchMethod(value) ;

        if (data && callback) callback(data);

        // actionCreator is optional
        if (data && actionCreator) dispatch(actionCreator({ [idName]: data[idName] }));

        return data;
      },
    );
  }

  if (fetchMany) {
    const { propertyName, apiMethod, callback } = fetchMany;
    // creator copied from payloadCreatorListWithFetch
    thunks.fetchManyThunk = appThunk(
      `${sliceName}/fetch${pluralProper}By${properCase(propertyName)}`,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      async (value: string, { getState, dispatch }: ThunkApi) => {
        const fetchApi = api.entity;
        const fetchMethod = fetchApi[apiMethod] as GetListByIdMethod<T>;

        return fetchMethod(value, fetchParams(getState, sliceName, sliceName)).then(
          (data: GetListResults<T>) => {
            if (data && callback) callback(data);

            if (!actionCreator) return data;

            data.items.forEach(({ [idName]: id }: RecordType) =>
              dispatch(actionCreator({ [idName]: id })),
            );

            return data;
          },
        );
      },
    );
  }

  return thunks;
};

export default sliceFetchByPropertyThunks;
