// noinspection ES6PreferShortImport

import { MaybeNull, RecordType, nowToString } from '@petconsole/pure-base';
import { ourCurrencyCode, priceIntervals } from '../../constants';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity, PclTierPriceRates } from '../../types';

export interface PclTierPrice extends CrudColumns {
  type: DataEntityType;
  pclTierPriceId: string;
  pclServiceId: string;
  pclTierId: string;
  effectiveDate: string;
  prices: PclTierPriceRates;
  json: RecordType;
}

export const pclTierPriceEntity = entityAttributes({
  name: entityName.pclTierPrice,
  attributes: {
    adminOnly: { create: true, delete: true, update: true },
    category: entityCategory.item,
    columns: { images: false },
    loadLimit: 25,
    restrictGet: true,
    test: { invalidData: () => ({ effectiveDate: {} }), validData: () => ({ effectiveDate: nowToString() }) },
  },
});

const { arrayOf, id, number, oneOf, strim } = schema;
export const pclTierPriceSchema = entitySchema<PclTierPrice>(pclTierPriceEntity, {
  pclServiceId: id(true),
  pclTierId: id(true),
  effectiveDate: strim().required(),
  prices: arrayOf({
    currency: oneOf(ourCurrencyCode, ourCurrencyCode.CAD).required(),
    interval: oneOf(priceIntervals, priceIntervals.month).required(),
    price: number(), // cents
  }),
});

export const pclTierPriceDefaults: Partial<PclTierPrice> = {
  pclServiceId: '',
  pclTierId: '',
  effectiveDate: '',
  prices: [],
};

export interface PclTierPriceRecord extends PclTierPrice, RecordType {}

export type MaybePclTierPriceRecord = MaybeNull<PclTierPriceRecord>;
export type PclTierPriceRecords = PclTierPriceRecord[];

export const initialPclTierPrice: InitialEntity<PclTierPrice, PclTierPriceRecord> = (overrides = {}) =>
  initialEntity<PclTierPrice>(pclTierPriceEntity, { ...pclTierPriceDefaults, ...overrides }) as PclTierPriceRecord;
