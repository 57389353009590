// noinspection ES6PreferShortImport

import { MaybeNull, RecordType, newId } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, GetPartialData, InitialEntity } from '../../types';

interface NextNumber {
  type: DataEntityType;
  nextNumberId: string;
  name: string;
  lastNumber: number;
  nextNumber: number;
  increment: number;
}

export const nextNumberEntity = entityAttributes({
  name: entityName.nextNumber,
  attributes: {
    category: entityCategory.item,
    columns: { crud: false, images: false, json: false, time: true },
    tabs: [],
    test: {
      invalidData: (() => ({ name: {} })) as GetPartialData,
      validData: () => ({ name: `UNIT-TEST-${newId()}` }),
    },
  },
});

const { number, strim } = schema;
export const nextNumberSchema = entitySchema<NextNumber>(nextNumberEntity, {
  name: strim().required('Name name is required'),
  lastNumber: number(0),
  nextNumber: number(1),
  increment: number(1),
});

export const nextNumberDefaults: Partial<NextNumber> = {
  name: '',
  lastNumber: 0,
  nextNumber: 1,
  increment: 1,
};

export interface NextNumberRecord extends NextNumber, RecordType {}

export type MaybeNextNumberRecord = MaybeNull<NextNumberRecord>;
export type NextNumberRecords = NextNumberRecord[];

export const initialNextNumber: InitialEntity<NextNumber, NextNumberRecord> = (overrides = {}) =>
  initialEntity<NextNumber>(nextNumberEntity, { ...nextNumberDefaults, ...overrides }) as NextNumberRecord;
