import { skipToken } from '@reduxjs/toolkit/query';
import { ApiUseGetQuery } from '@petconsole/pure-fe-state';
import { EntityRecord } from '@petconsole/pure-shared';

interface UseEntityCardEntityProps<T extends EntityRecord = EntityRecord> {
  getEntityQueryHook?: ApiUseGetQuery;
  entityId?: string;
  entityData?: T;
  idName: string;
}

const useEntityCardEntity = <T extends EntityRecord = EntityRecord>({
  getEntityQueryHook,
  entityId,
  entityData,
  idName,
}: UseEntityCardEntityProps<T>) => {
  let entityRecord = {} as T;

  if (entityData) entityRecord = entityData;
  else if (getEntityQueryHook) {
    const { data, isSuccess } = getEntityQueryHook(entityId || skipToken);

    if (isSuccess && data) entityRecord = data as T;
  }

  const cardId = entityId || entityRecord?.[idName] as string || '';

  return { cardId, entityRecord };
};

export default useEntityCardEntity;
