import { AccountCircle } from '@mui/icons-material';
import { webPath } from '@petconsole/pure-shared';
import OurAppMenuItem from './OurAppMenuItem';

const OurJoinMenuItem = () => {
  return <OurAppMenuItem item={[AccountCircle, 'Join', webPath.join]} />;
};

OurJoinMenuItem.whyDidYouRender = true;

export default OurJoinMenuItem;
