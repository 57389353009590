import { Grid, GridProps } from '@mui/material';
import { ourUomType } from '@petconsole/pure-shared';
import OurUom, { OurUomProps } from './OurUom';

interface OurSelectWeightUomProps extends Omit<OurUomProps, 'type'> {
  grid?: boolean;
  gridProps?: GridProps;
}

const OurSelectWeightUom = ({ grid, gridProps, label = 'Weight UOM', ...rest }: OurSelectWeightUomProps) => {
  const ourUomProps = { type: ourUomType.Weight, label: label, ...rest };

  if (!grid && !gridProps) return <OurUom {...ourUomProps} />;

  return (
    <Grid item xs={12} {...gridProps}>
      <OurUom {...ourUomProps} />
    </Grid>
  );
};

OurSelectWeightUom.whyDidYouRender = true;

export default OurSelectWeightUom;
