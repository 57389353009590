import { KeyIsValue, KeyString, reactAppHtmlAppName, webPath } from '@petconsole/pure-shared';
import {
  OurBlueBus,
  OurBlueBusBuilder,
  OurBlueBusRepair,
  OurBlueBusSupply,
  OurBlueDogTrainer,
  OurBlueDogWalker,
  OurBlueGroomer,
  OurBluePaw,
  OurBluePetBowl,
  OurBluePetPlace,
  OurBlueShop,
  OurBlueSitter,
  OurBlueVan,
  OurBlueVanBuilder,
  OurBlueVanRepair,
  OurBlueVanSupply,
  OurBlueVet,
} from './components/svg';
import { AppType, DefaultAvatar } from './types';

const { join, login, forgotPassword, verifyCode } = webPath;

export const ourAuthPaths = [join, login, forgotPassword, verifyCode];

export const ourAppBorderWidth = '3px';
export const ourAppMarginTop = 6; // 6 theme spacing units
export const ourButtonBorderRadius = '30px';
export const ourDefaultLightModeBorderColor = 'rgb(192, 192, 192)';
export const ourDefaultDarkModeBorderColor = 'rgba(255, 255, 255, 0.23)';
export const ourDefaultDarkModeItemKey = 'default:darkMode';
export const ourDefaultDataRefreshMinutes = 15;
export const ourDefaultGuestDrawerWidth = 190;
// noinspection SpellCheckingInspection
export const ourDefaultImageThumbnail =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMjEgMTlWNWMwLTEuMS0uOS0yLTItMkg1Yy0xLjEgMC0yIC45LTIgMnYxNGMwIDEuMS45IDIgMiAyaDE0YzEuMSAwIDItLjkgMi0yek04LjUgMTMuNWwyLjUgMy4wMUwxNC41IDEybDQuNSA2SDVsMy41LTQuNXoiLz4KPC9nPgo8L3N2Zz4K';
export const ourDefaultLocationItemKey = 'default:defaultLocation';
export const ourDefaultMaxCardWidth = '600px';
export const ourDefaultMaxTextRows = 50;
export const ourDefaultMemberDrawerWidth = 200;
export const ourDefaultMenuItemIconWidth = '40px';
export const ourDefaultTabWidth = 80;
export const ourDefaultTabWidthListings = 100;
export const ourJoinButtonColor = '#8700BE'; // Purple or #9201B7 Purple

export const commonMedia = {
  aboutUs: `${webPath.commonMedia}/headers/about-us-20220623-1.jpg`,
  contactUs: `${webPath.commonMedia}/headers/contact-us-20220623-1.jpg`,
  faq: `${webPath.commonMedia}/headers/faq-20220623-1.jpg`,
  pricing: `${webPath.commonMedia}/headers/pricing-20220623-1.png`,
  privacy: `${webPath.commonMedia}/headers/privacy-20220623-1.jpg`,
  teamMikeAndSue: `${webPath.commonMedia}/team/mike-and-sue-20220625-1.png`,
  teamMilo: `${webPath.commonMedia}/team/milo-20220625-1.png`,
  teamShadow: `${webPath.commonMedia}/team/shadow-20220625-1.png`,
  teamTreacle: `${webPath.commonMedia}/team/treacle-20220625-1.png`,
  terms: `${webPath.commonMedia}/headers/terms-20220623-1.jpg`,
};

export const siteMemberName = reactAppHtmlAppName;
export const siteParentName = 'Pet Console';
export const siteMember = siteMemberName && siteMemberName !== siteParentName;
export const siteMemberText = (siteMember && `${siteMemberName} is a member of the ${siteParentName} family`) || '';

export const appTypes = ['Auth', 'Guest', 'Member'] as const;
export const appType: KeyIsValue<AppType> = {
  Auth: 'Auth',
  Guest: 'Guest',
  Member: 'Member',
} as const;

export const defaultAvatars = [
  'bus',
  'busBuilder',
  'busRepair',
  'busSupply',
  'foundPet',
  'groomer',
  'lostPet',
  'pet',
  'petFood',
  'petTrainer',
  'petWalker',
  'petWorkplace',
  'place',
  'shop',
  'sitter',
  'van',
  'vanBuilder',
  'vanRepair',
  'vanSupply',
  'vet',
] as const;
export const defaultAvatar: KeyString<DefaultAvatar> = {
  bus: OurBlueBus,
  busBuilder: OurBlueBusBuilder,
  busRepair: OurBlueBusRepair,
  busSupply: OurBlueBusSupply,
  foundPet: OurBluePaw,
  groomer: OurBlueGroomer,
  pet: OurBluePaw,
  lostPet: OurBluePaw,
  petFood: OurBluePetBowl,
  petTrainer: OurBlueDogTrainer,
  petWalker: OurBlueDogWalker,
  petWorkplace: OurBluePetPlace,
  place: OurBluePetPlace,
  shop: OurBlueShop,
  sitter: OurBlueSitter,
  van: OurBlueVan,
  vanBuilder: OurBlueVanBuilder,
  vanRepair: OurBlueVanRepair,
  vanSupply: OurBlueVanSupply,
  vet: OurBlueVet,
} as const;
