import { Menu, MenuProps } from '@mui/material';
import { SetState } from '@petconsole/pure-shared';
import { MemberAvatarMenuItems } from '../../../types';
import MemberMenuItems from './MemberMenuItems';

interface AppMemberMenuProps {
  menuOpen: boolean;
  setMenuOpen: SetState<boolean>;
  menuAnchorEl: MenuProps['anchorEl'];
  avatarMenuItems: MemberAvatarMenuItems;
}

const AppMemberMenu = ({
  menuOpen,
  setMenuOpen,
  menuAnchorEl,
  avatarMenuItems,
}: AppMemberMenuProps) => {
  return (
    <Menu
      id="avatar-menu"
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
      anchorEl={menuAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MemberMenuItems setMenuOpen={setMenuOpen} avatarMenuItems={avatarMenuItems} />
    </Menu>
  );
};

AppMemberMenu.whyDidYouRender = true;

export default AppMemberMenu;
