// noinspection ES6PreferShortImport

import { startOfDay } from 'date-fns';
import { defaultGoogleAddress, MaybeDate, MaybeNull, MaybeNumber, newId, RecordType } from '@petconsole/pure-base';
import { genderType, site } from '../../constants';
import { entityCategory, entityName, entityTabName, entityType } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import { schema } from '../../entity/schema';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { InitialEntity, LostFoundPet, MaybeImage } from '../../types';

interface LostPet extends LostFoundPet {
  lostPetId: string;
  lastSeenDate: MaybeDate;
  age: MaybeNumber;
  banner: MaybeImage;
}

const { General, Details, Avatar, Banner, Photos, Comments, Resolve } = entityTabName;
export const lostPetEntity = entityAttributes({
  name: entityName.lostPet,
  attributes: {
    addInstructions:
      "Please enter the pet's name and the location where they were last seen. Please be sure to choose one of the suggestions Google makes when you start typing the address or city name.",
    addLabel: "Pet's Name",
    addressLabel: 'Last Seen City/Address',
    category: entityCategory.pet,
    columns: { address: true, member: true, crud: false, time: true },
    loadLimit: 20,
    maxImages: 4,
    memberOwned: true,
    nameField: 'petName',
    site: site.petConsole,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Resolve],
  },
});

const { avatar, boolean, date, email, id, gender, image, images, number, phone, strim, triple, type } = schema;
const { googleAddressColumns, memberColumns } = schema;

export const lostPetSchema = entitySchema<LostPet>(lostPetEntity, {
  lostPetId: id(true),
  type: type(entityType.LostPet),
  title: strim().required('A title/caption is required'),
  petName: strim(),
  petType: strim().default('Other'),
  petSubType: strim(),
  breed: strim(),
  size: strim(),
  color: strim(),
  markings: strim(),
  gender: gender(),
  fixed: triple(null),
  age: number().nullable().default(null),
  microchip: strim(),
  tattoo: strim(),
  avatar: avatar(),
  banner: image(),
  images: images(),
  createdAt: date(),
  updatedAt: date(),
  lastSeenDate: date(),
  other: strim(),
  contactName: strim(),
  phoneNumber: phone(),
  email: email(),
  resolved: boolean(),
  resolvedAt: date(),
  returned: boolean(),
  siteHelped: boolean(),
  resolution: strim(),
  ...memberColumns(),
  ...googleAddressColumns(),
});

export const lostPetDefaults: Partial<LostPet> = {
  lostPetId: newId(),
  type: entityType.LostPet,
  title: '',
  petName: '',
  petType: 'Other',
  petSubType: '',
  breed: '',
  size: '',
  color: '',
  markings: '',
  gender: genderType.Other,
  fixed: null,
  age: null,
  microchip: '',
  tattoo: '',
  avatar: '',
  banner: null,
  images: [],
  createdAt: null,
  updatedAt: null,
  lastSeenDate: startOfDay(new Date()).toISOString() as unknown as Date,
  other: '',
  contactName: '',
  phoneNumber: '',
  email: '',
  resolved: false,
  resolvedAt: null,
  returned: false,
  siteHelped: false,
  resolution: '',
  memberName: '',
  address: defaultGoogleAddress(),
};

export interface LostPetRecord extends LostPet, RecordType {}

export type MaybeLostPetRecord = MaybeNull<LostPetRecord>;
export type LostPetRecords = LostPetRecord[];

export const initialLostPet: InitialEntity<LostPet, LostPetRecord> = (overrides = {}) =>
  initialEntity<LostPet>(lostPetEntity, { ...lostPetDefaults, ...overrides }) as LostPetRecord;
