import { Typography } from '@mui/material';
import useOurEnterSite from './useOurEnterSite';

const OurEnterSite = () => {
  useOurEnterSite();

  // TODO: Add an image
  return <Typography>Changing site. Please wait...</Typography>;
};

OurEnterSite.whyDidYouRender = true;

export default OurEnterSite;
