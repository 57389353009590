import { RecordType } from '@petconsole/pure-base';
import { BaseSliceSliceProperty, Slice } from '../../types';

export const sliceInitialState = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  const { name: sliceName, flag, option, proper, plural, pluralProper } = slice;
  const { hasMySliver, hasCitySearch, hasDefaultProperties, hasTabValues } = flag;
  const { tabs, sliceTabs, sliceProperties } = option;
  let initialState: RecordType = {};

  slice.slivers.forEach((sliverName) => {
    initialState[sliverName] = slice.sliver[sliverName].initialState();
  });

  initialState = {
    ...initialState,
    ...(hasDefaultProperties && {
      [`${sliceName}Editing`]: false,
      [`${sliceName}Submitting`]: false,
      [`${sliceName}AddressInput`]: '',
    }),
    ...(tabs &&
      Object.keys(tabs).reduce(
        (previousValue, tab) => ({ ...previousValue, [`${tab}TabValue`]: tabs[tab].default }),
        {},
      )),
    ...(sliceTabs &&
      sliceTabs.reduce(
        (previousValue, tab) => ({ ...previousValue, [`${tab.prefix}TabValue`]: tab.default }),
        {},
      )),
    ...(!tabs &&
      !sliceTabs &&
      hasTabValues && {
        [`${sliceName}TabValue`]: option.entityTabValue,
        [`${plural}TabValue`]: option.entitiesTabValue,
        [`${sliceName}AdminTabValue`]: option.adminTabValue,
        ...(hasMySliver && {
          [`my${pluralProper}TabValue`]: `My ${pluralProper}`,
          [`my${proper}TabValue`]: option.myEntityTabValue,
        }),
      }),
    ...(hasCitySearch && {
      [`${sliceName}CitySearchInput`]: '',
      [`${sliceName}CitySearchDefaulted`]: false,
      [`${sliceName}CitySearchLocation`]: null,
      [`${sliceName}CityChangeLocation`]: null,
    }),
    ...(sliceProperties &&
      sliceProperties.reduce(
        (previousValue, sliceProperty: BaseSliceSliceProperty) => ({
          ...previousValue,
          [`${sliceName}${sliceProperty.suffix}`]: sliceProperty.default,
        }),
        {},
      )),
  };

  return initialState;
};
