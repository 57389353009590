import { memo, useState } from 'react';
import { Path, Resolver, useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RecordType } from '@petconsole/pure-base';
import { EntityTabName, MaybeImage, RecordOfTabValueHooks, SetState } from '@petconsole/pure-shared';
import { defaultAvatar } from '../../constants';
import { defaultListEntityFormValues } from '../../entity/constants';
import useOurBannerImage from '../../entity/helpers/useOurBannerImage';
import useOurOnEditListEntity from '../../entity/helpers/useOurOnEditListEntity';
import useOurPhotos from '../../entity/helpers/useOurPhotos';
import useAvatarDrop from '../../inputs/avatar/useAvatarDrop';
import useAvatarEditor from '../../inputs/avatar/useAvatarEditor';
import { DefaultAvatar } from '../../types';
import MaintAddress from '../MaintAddress';
import { MaintAddressProps, UseMaintFormProps, UseMaintFormReturns } from '../types';
import useMaintDirty from './useMaintDirty';
import useMaintRead from './useMaintRead';
import useMaintState from './useMaintState';
import useMaintSubmit from './useMaintSubmit';

const useMaintForm = <T extends RecordType = RecordType>({
  entity,
  hooks,
  schema,
  values = {} as T,
  customData,
  getCustomData,
  idParam,
  stateIds,
  addressBreakpoints,
  tabHooks: customTabHooks,
  tabValues,
  tabValue: customTabValue,
  beforeSubmit,
  beforeSave,
  customCancel,
  customSave,
  afterSave,
  afterSubmit,
  viewOnly,
  startEditing,
}: UseMaintFormProps<T>): UseMaintFormReturns<T> => {
  const [formValues, setFormValues] = useState({ ...defaultListEntityFormValues, ...values } as T);

  const yupSchema = yup.object(schema);

  const form = useForm({
    values: formValues,
    defaultValues: formValues,
    resolver: yupResolver(yupSchema) as Resolver,
  }) as UseFormReturn<T>;

  // Read
  const readProps = { entity, hooks, idParam, stateIds };
  const readResult = useMaintRead<T>({ ...readProps });
  const { data, id, onPrev, onNext, prevId, nextId, reRead } = readResult;

  if (data && Object.hasOwn(data, 'banner')) form.register('banner' as Path<T>);
  if (entity.columns.images) form.register('images' as Path<T>);

  // Edit
  const editProps = { entity, data, viewOnly, startEditing };
  const editResult = useOurOnEditListEntity({ ...editProps });
  const {
    editing: isEditing,
    setEditing,
    owned: isOwned,
    owner: isOwner,
    guest: isGuest,
    canEdit,
    onEdit,
  } = editResult;
  const readOnly = !isEditing || (!isOwner && !entity.shared);

  // Avatar
  const { avatarEditor, setAvatarEditor } = useAvatarEditor();
  const { avatar, setAvatar, avatarChanged, setAvatarChanged, getAvatar } = useAvatarDrop({
    // currentAvatar: data?.avatar ? ourMemberImageUrl(data.avatar as string) : '',
    currentAvatar: (data?.avatar as string) || '',
    defaultAvatar: defaultAvatar[entity.name as DefaultAvatar],
  });

  // Banner
  const dataBanner = (data?.banner as MaybeImage) || undefined;
  const { banner, setBanner, saveBanner, bannerFrom } = useOurBannerImage({ image: dataBanner });

  // Images/Photos
  const imageProps = { entity, id, editing: isEditing, owner: isOwner };
  const { images, setImages, /* imageZone, */ Photos } = useOurPhotos({ ...imageProps });

  // State
  const baseStateProps = { form, data, customData, getCustomData, customCancel, setEditing, setFormValues };
  const imageStateProps = { getAvatar, setBanner, setImages };
  const stateResult = useMaintState<T>({ ...baseStateProps, ...imageStateProps });
  const { initialAddress, addressInput, setAddressInput, initialBanner, initialImages, onCancel } = stateResult;

  // Address
  const addressProps1 = { form, entity, readOnly, addressBreakpoints };
  const addressProps2 = { inputValue: addressInput, setInputValue: setAddressInput };
  const Address = memo((props: MaintAddressProps<T>) => (
    <MaintAddress<T> {...addressProps1} {...addressProps2} {...props} />
  ));

  // Tab values
  const tabHooks = customTabHooks || (hooks.tabHooks as RecordOfTabValueHooks)[tabValues || entity.name];
  const defaultTabValue = tabHooks.useSelect();
  const tabValue = (customTabValue || defaultTabValue) as EntityTabName;

  // Dirty
  const dirtyProps1 = { form, isEditing, addressInput, initialAddress, avatar, avatarChanged };
  const dirtyProps2 = { banner, initialBanner, images, initialImages };
  useMaintDirty<T>({ ...dirtyProps1, ...dirtyProps2 });

  // Submit
  const submitProps1 = { entity, form, hooks, setEditing, setFormValues: setFormValues as SetState<T>, tabValue };
  const submitProps2 = { avatar, setAvatar, avatarEditor, setAvatarChanged, bannerFrom, saveBanner, images };
  const submitProps3 = { beforeSubmit, beforeSave, customSave, afterSave, afterSubmit };
  const submitProps = { ...submitProps1, ...submitProps2, ...submitProps3 };
  const submitResult = useMaintSubmit<T>({ ...submitProps });
  const { onSubmit, submitting, setSubmitting, busy, setBusy } = submitResult;
  const onSave = () => form.handleSubmit(onSubmit)();

  const returns1 = { form, id, data, readOnly, isOwned, isOwner, isGuest, isEditing, canEdit, onEdit, onPrev, onNext }; // setEditing,
  const returns2 = { prevId, nextId, reRead, submitting, setSubmitting, onCancel, onSave, busy, setBusy, Address };
  const returns3 = { addressValue: addressInput, setAddressValue: setAddressInput, avatar, setAvatar };
  const returns4 = { setAvatarChanged, setAvatarEditor, banner, setBanner, images, Photos, tabValue, tabHooks }; // imageZone,
  return { ...returns1, ...returns2, ...returns3, ...returns4 };
};

export default useMaintForm;
