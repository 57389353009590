import { MouseEventHandler, PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@mui/material';

export interface OurHeadingChildrenProps extends TypographyProps, PropsWithChildren {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const OurHeadingChildren = ({
  variant = 'h6',
  align = 'center',
  onClick,
  children,
  ...rest
}: OurHeadingChildrenProps) => (
  <Typography variant={variant} align={align} onClick={onClick} {...rest}>
    <strong>{children}</strong>
  </Typography>
);

OurHeadingChildren.whyDidYouRender = true;

export default OurHeadingChildren;
