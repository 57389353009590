import { useMemo } from 'react';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { ObjectShape } from 'yup';
import { RecordType } from '@petconsole/pure-base';
import { ExtendedFormik, removeProperties, schema as dataSchema } from '@petconsole/pure-shared';

interface UseOurFormikProps {
  formValues: FormikValues;
  schema: ObjectShape;
  editing: boolean;
  onSubmit: (values: RecordType, formikHelpers: FormikHelpers<RecordType>) => void | Promise<unknown>;
}

const useOurFormik = ({ formValues, schema, editing, onSubmit }: UseOurFormikProps) => {
  const { object: schemaObject } = dataSchema;

  const validationSchema = useMemo(
    () => schemaObject(removeProperties(schema, ['createdId', 'updatedId'])),
    [schema, schemaObject],
  );

  const formik = useFormik({
    initialValues: formValues as FormikValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  }) as ExtendedFormik;

  formik.isReadOnly = !editing;

  return { formik };
};

export default useOurFormik;
