// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity, MaybeNull, OwnerColumns } from '../../../types';

export interface BlogDef extends CrudColumns, OwnerColumns {
  type: DataEntityType;
  blogDefId: string;
  urlName: string;
  json: RecordType;
}

export const blogDefEntity = entityAttributes({
  name: entityName.blogDef,
  attributes: {
    apiPath: '/blogs/blogDefs',
    category: entityCategory.item,
    nameField: 'urlName',
    columns: { images: false, owner: true },
    restrictGet: false,
  },
});

const { strim } = schema;
export const blogDefSchema = entitySchema<BlogDef>(blogDefEntity, {
  urlName: strim().required(),
});

export const blogDefDefaults: Partial<BlogDef> = {
  urlName: '',
};

export interface BlogDefRecord extends BlogDef, RecordType {}

export type MaybeBlogDefRecord = MaybeNull<BlogDefRecord>;
export type BlogDefRecords = BlogDefRecord[];

export const initialBlogDef: InitialEntity<BlogDef, BlogDefRecord> = (overrides = {}) =>
  initialEntity<BlogDef>(blogDefEntity, { ...blogDefDefaults, ...overrides }) as BlogDefRecord;
