import { Theme } from '@mui/material';
import Slide from './Slide';

interface LeftSlideProps {
  borderWidth: number;
  borderRadius: number;
  urls: string[];
}

const LeftSlide = ({ borderWidth, borderRadius, urls }: LeftSlideProps) => {
  const leftFilmRadius = ({ spacing }: Theme) => spacing(0, borderRadius, borderRadius, 0);

  return (
    <Slide
      index={0}
      width="12.5%"
      position="right"
      radius={leftFilmRadius}
      borderWidth={borderWidth}
      borderLeft={false}
      urls={urls}
    />
  );
};

LeftSlide.whyDidYouRender = true;

export default LeftSlide;