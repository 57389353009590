import { memo } from 'react';
import { Grid } from '@mui/material';
import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { EntityAttributes } from '@petconsole/pure-shared';
import useIsThisYours from './useIsThisYours';

export interface UseIsYoursProps<T extends RecordType = RecordType> {
  entity: EntityAttributes;
  data: MaybeNull<T>;
  readOnly?: boolean;
  isOwned?: boolean;
}

const useIsYours = <T extends RecordType = RecordType>({
  entity,
  data,
  readOnly = true,
  isOwned = false,
}: UseIsYoursProps<T>) => {
  const { IsYours, Subscribe } = useIsThisYours<T>({ entity });
  const { subscribable } = entity;

  const IsYoursGridItem = memo(() => {
    if (!data || !subscribable || !readOnly || isOwned) return null;

    return (
      <Grid item xs={12} display="flex" justifyContent="center">
        <IsYours entityRecord={data} />
      </Grid>
    );
  });

  return { IsYoursGridItem, IsYoursDialog: Subscribe };
};

export default useIsYours;
