import { ChangeEvent, useCallback } from 'react';
import { format } from 'date-fns';
import { UseFormReturn } from 'react-hook-form';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { MaybeDate, RecordType, stringToDate } from '@petconsole/pure-base';
import { ExtendedFormik } from '@petconsole/pure-shared';
import getFieldProps from '../../components/inputs/helpers/getFieldProps';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import DateInput, { DateInputProps } from '../react-hook-form/DateInput';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

export interface OurDateProps<T extends RecordType = RecordType> {
  form?: UseFormReturn<T>;
  formik?: ExtendedFormik;
  id?: string;
  label?: string;
  openTo?: string;
  minDate?: Date;
  maxDate?: Date;
  disableFuture?: boolean;
  disablePast?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
}

const OurDateField = <T extends RecordType = RecordType>({ formik, ...restProps }: OurDateProps<T>) => {
  // NOTE: We expect the date to be null or a valid Date object
  const darkMode = useOurDarkMode();

  const date = useCallback(
    (value: unknown) =>
      typeof value === 'object'
        ? (value as Date)
        : typeof value === 'string' && (value as string).length === 10
          ? stringToDate(value)
          : null,
    [],
  );

  if (restProps.form) return <DateInput {...(restProps as DateInputProps<T>)} />;

  const {
    // openTo,
    minDate = new Date('1900-01-01'),
    maxDate = new Date('2099-12-31'),
    // disableFuture = true, // Needs to be implemented
    // disablePast, // Needs to be implemented
    clearable = true,
  } = restProps;
  const { InputProps, ...fieldProps } = getFieldProps({ formik, ...restProps, darkMode });
  const { id = '', name = '', value, onChange } = fieldProps;
  const readOnly = (InputProps.readOnly || false) as boolean;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateField
        {...fieldProps}
        {...(clearable && !readOnly ? { clearable } : {})}
        InputProps={InputProps}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange ? (value) => onChange({ target: { id, value } } as unknown as ChangeEvent) : undefined}
        {...(readOnly ? { readOnly: Boolean(readOnly) } : {})}
        value={date(value) as MaybeDate}
        onError={(reason) => {
          switch (reason) {
            case 'invalidDate':
              formik?.setFieldError(name, 'Invalid date format');
              break;

            case 'maxDate':
              formik?.setFieldError(name, `Date should not be after ${format(maxDate, 'P')}`);
              break;

            case 'minDate':
              formik?.setFieldError(name, `Date should not be before ${format(minDate, 'P')}`);
              break;

            // case "shouldDisableDate":
            //   // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
            //   formik?.setFieldError(name, getShouldDisableDateError(value));
            //   break;

            default:
              formik?.setErrors({ ...formik?.errors, [name]: undefined });
          }
        }}
      />
    </LocalizationProvider>
  );
};

OurDateField.whyDidYouRender = true;

export default OurDateField;
