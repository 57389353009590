import { RecordOfRecordType, RecordType } from '@petconsole/pure-base';
import { entityName, MailFolderRecord, MailFolderRecords } from '@petconsole/pure-shared';
import ourStateSliverData from '../../../helpers/state/ourStateSliverData';
import { AppState } from '../../../store';
import { StateSlice, StateSliver } from '../../../types/stateTypes';

const sliceName = entityName.mailFolder;

export const selectCurrentFolder = (state: RecordType) => {
  const { [`${sliceName}CurrentFolderId`]: currentFolderId, [sliceName]: sliver } =
    state[sliceName] as StateSlice;

  if (!currentFolderId) return undefined;

  return ((sliver as StateSliver).entities as RecordOfRecordType<MailFolderRecord>)[currentFolderId as string];
};

export const selectIdByMailFolderType = (mailFolderType: string) => (state: AppState) => {
  const entities = Object.values(ourStateSliverData(state, sliceName).entities) as MailFolderRecords;
  const found = entities.find((folder) => folder.mailFolderType === mailFolderType);

  return found?.mailFolderId;
};
