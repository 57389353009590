import { ourValidId } from '@petconsole/pure-shared';
import { SliceChildProps,  } from '../../types';
import selectEntityByKey from "./selectEntityByKey";
import selectEntityByString from "./selectEntityByString";

const selectEntityByUrlOrId = (sliver: SliceChildProps, value: string) => {
  if (!value) return undefined;

  if (ourValidId(value)) return selectEntityByKey(sliver, value);

  return selectEntityByString(sliver, 'urlName', value);
};

export default selectEntityByUrlOrId;
