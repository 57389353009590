import { RecordType } from '@petconsole/pure-base';
import { petFoodApiEntity } from '@petconsole/pure-fe-api';
import { PetFoodRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = PetFoodRecord;

const apiEntity = petFoodApiEntity;
const { name: sliceName, plural } = apiEntity;
const petFood = sliceName;
const petFoods = plural;
const petFoodAdmin = `${sliceName}Admin`;

const Tab = { petFoodAdmin, petFood, petFoods } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  petFoodAdmin: { default: '' },
  petFood: { default: '' },
  petFoods: { default: '' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, ratingHooks, tabHooks } = sliceHookTypes;

export interface OurPetFoodHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  petFoodHooks: RequiredCrudSliverHooks;
  cityPetFoodHooks: RequiredSliverHooks;
  newestPetFoodHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourPetFoodHooks: OurPetFoodHooks = {
  entity: apiEntity,
  petFoodHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityPetFoodHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestPetFoodHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  ratingHooks: ratingHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
