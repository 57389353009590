import { Api } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import { NextAction } from '../../types';
import payloadCreatorUpdateEntity from '../payloadCreators/payloadCreatorUpdateEntity';
import { RecordType } from '@petconsole/pure-base';

const updateEntityThunk = <T extends RecordType = RecordType>(sliceName: string, entityType: string, idName: string, api: Api<T>, fetch?: NextAction) =>
  appThunk(`${sliceName}/update${entityType}`, payloadCreatorUpdateEntity(sliceName, idName, api, fetch));

export default updateEntityThunk;
