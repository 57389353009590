import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { EntityType, GetMethod } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../types';

const getOneBy = <T extends RecordType = RecordType>(builder: ApiBuilder<T>, method: GetMethod<T>, tag: EntityType, extraOptions?: RecordType) =>
  builder.query<MaybeNull<T>, string>({
    query: (id) => ({ method, id }),
    providesTags: (_result, _error, id) => [tag, { type: tag, id }],
    extraOptions,
  });

export default getOneBy;
