import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OurDisplayDate, { OurDisplayDateProps } from './OurDisplayDate';

interface OurDisplayDateGridItemProps extends OurDisplayDateProps, RegularBreakpoints {
  gridProps?: GridProps;
}

const OurDisplayDateGridItem = ({ gridProps, xs = 12, sm, md, lg, xl, ...rest }: OurDisplayDateGridItemProps) => {
  return (
    <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
      <OurDisplayDate {...rest} />
    </Grid>
  );
};

OurDisplayDateGridItem.whyDidYouRender = true;

export default OurDisplayDateGridItem;
