import {
  Api,
  ApiEntity,
  blogEntity,
  BlogRecord,
  LayoutCell,
  LayoutGrid,
  UpdateMethod,
} from '@petconsole/pure-shared';
import { commonApi } from './common';
import { RecordType } from '@petconsole/pure-base';

type ApiRecord = BlogRecord;

const apiPath = '/blogs';
const { update, ...common } = commonApi<ApiRecord>({ apiPath });

type IterateLayout = (layout: LayoutGrid, callback: (cell: LayoutCell) => unknown) => void;

/**
 * Iterate and call the callback function for each cell.
 * @param layout 3 dimensional array
 * @param callback - function to call for each cell
 */
export const iterateLayout: IterateLayout = (layout, callback) => {
  if (!layout) return;

  layout.forEach((row) => {
    row.forEach((col) => {
      col.forEach((cell) => {
        callback(cell);
      });
    });
  });
};

type UpdateBlog = UpdateMethod<ApiRecord>;

export const updateBlog: UpdateBlog = async (id, body) => {
  const blog = { ...body } as BlogRecord;
  const imageSrc: string[] = [];

  iterateLayout(blog.layout, (cell: LayoutCell) => {
    if (cell.type === 'image') {
      imageSrc.push(cell.src || '');

      cell.src = '';
    }
  });

  // We are not using await as we don't want to force this into blocking anything
  // The caller can await if they want to
  return (update as UpdateBlog)(id, blog).then((updated) => {
    iterateLayout((updated as BlogRecord).layout as LayoutGrid, (cell: LayoutCell) => {
      if (cell.type === 'image') {
        cell.src = imageSrc.shift();
      }
    });

    return updated as BlogRecord;
  });
};

interface CustomApi extends RecordType {
  iterateLayout?: IterateLayout;
}

export type BlogApi = Api<BlogRecord> & CustomApi;

export const blogApi  = {
  ...common,
  iterateLayout,
  update: updateBlog,
} as BlogApi;

export const blogApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...blogEntity, api: blogApi };
