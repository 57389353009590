import { compareAsc } from 'date-fns';
// import { formatPhoneNumberIntl } from 'react-phone-number-input';
// import parsePhoneNumber from 'libphonenumber-js/core';
import { RecordType, stringToAge } from '@petconsole/pure-base';
import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import {
  dateFormat,
  ourDisplayDateSecondsToDate,
  ourDisplayDateToDate,
  ourIsoToDisplayDateSeconds,
  ourIsoToWeekDisplayDateSeconds,
  ourStringToDisplayDate,
  ourWeekDisplayDateSecondsToDate,
} from '@petconsole/pure-fe-utils';
import { RatingValues } from '@petconsole/pure-shared';
import useOurCurrencyFormat from '../../hooks/useOurCurrencyFormat';
import OurAvatar from '../avatar/OurAvatar';
import OurAverageRating from '../misc/averageRating/OurAverageRating';
import averageRating from '../misc/averageRating/averageRating';

// noinspection JSUnusedGlobalSymbols
const ourDataGridColumn = {
  age: (field: string) => ({
    field,
    width: 100,
    type: 'number',
    valueGetter: (value: string) => stringToAge(value),
  }),
  avatar: (field: string, textField: string, path: string | boolean, altImage: string) => ({
    field,
    width: 60,
    renderCell: ({ row }: { row: RecordType }) => {
      // Isolate the properties from the row
      const text = row[textField] as string | undefined;
      const avatar = row[field] as string | undefined;
      // If we have an avatar value, we'll have a fully qualified path to the image
      const image = avatar && path ? ourMemberImageUrl(avatar) : avatar;
      // If we didn't have an avatar value, we'll attempt to use the alternate image
      const src = image || altImage;

      return <OurAvatar src={src} text={text} />;
    },
  }),
  boolean: (field: string) => ({ field, type: 'boolean', width: 130 }),
  city: (field = 'city') => ({ field, width: 200 }),
  country: (field = 'country') => ({ field, width: 110 }),
  currency: (field: string, format: ReturnType<typeof useOurCurrencyFormat>) => ({
    field,
    width: 110,
    type: 'number',
    valueGetter: (value: number) => format(value),
    sortComparator: (a: string, b: string) => Number(a.split('$')[1]) - Number(b.split('$')[1]),
  }),
  displayDate: (field: string) => ({
    field,
    width: 110,
    valueGetter: (value: string) => (value ? ourStringToDisplayDate(value) : ''),
    sortComparator: (a: string, b: string) => {
      if (!a) return -1;
      if (!b) return 1;

      const aDate = ourDisplayDateToDate(a);
      const bDate = ourDisplayDateToDate(b);

      if (aDate === dateFormat.invalidDate) return -1;
      if (bDate === dateFormat.invalidDate) return 1;

      return compareAsc(aDate, bDate);
    },
  }),
  dayAndSeconds: (field: string) => ({
    field,
    width: 200,
    valueGetter: (value: string) => (value ? ourIsoToDisplayDateSeconds(value) : ''),
    sortComparator: (a: string, b: string) => {
      if (!a) return -1;
      if (!b) return 1;

      const aDate = ourDisplayDateSecondsToDate(a);
      const bDate = ourDisplayDateSecondsToDate(b);

      if (aDate === dateFormat.invalidDate) return -1;
      if (bDate === dateFormat.invalidDate) return 1;

      return compareAsc(aDate, bDate);
    },
  }),
  memberName: (field = 'memberName') => ({
    field,
    width: 175,
    sortComparator: (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()),
  }),
  percent: (field: string, fractionDigits = 2) => ({
    field,
    width: 100,
    type: 'number',
    valueGetter: (value: number) => `${(value * 100).toFixed(fractionDigits)}%`,
    sortComparator: (a: string, b: string) => Number(a.split('%')[0]) - Number(b.split('%')[0]),
  }),
  petName: (field = 'petName') => ({
    field,
    width: 160,
    sortComparator: (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()),
  }),
  // phone: (field = 'phoneNumber') => ({
  //   field,
  //   width: 180,
  //   // valueGetter: (value: string) => formatPhoneNumberIntl(value),
  //   valueGetter: (value: string) => parsePhoneNumber(value).format('INTERNATIONAL'),
  // }),
  province: (field = 'province') => ({ field, width: 115 }),
  rating: (field: string) => ({
    field,
    width: 160,
    valueGetter: (value: RatingValues) => averageRating(value),
    renderCell: ({ row }: { row: RecordType }) => <OurAverageRating ratings={row[field] as RatingValues} />,
  }),
  weekdayAndSeconds: (field: string) => ({
    field,
    width: 215,
    valueGetter: (value: string) => (value ? ourIsoToWeekDisplayDateSeconds(value) : ''),
    sortComparator: (a: string, b: string) => {
      if (!a) return -1;
      if (!b) return 1;

      const aDate = ourWeekDisplayDateSecondsToDate(a);
      const bDate = ourWeekDisplayDateSecondsToDate(b);

      if (aDate === dateFormat.invalidDate) return -1;
      if (bDate === dateFormat.invalidDate) return 1;

      return compareAsc(aDate, bDate);
    },
  }),
};

export default ourDataGridColumn;
