import { MouseEvent } from 'react';
import { Box, Switch, useTheme } from '@mui/material';
import { OurDarkModeIcon, OurLightModeIcon } from '@petconsole/pure-fe-icon';
import useOurManageDarkMode from '../../../hooks/useOurManageDarkMode';

interface OurThemeSwitchProps {
  memberId?: string;
}

const OurThemeSwitch = ({ memberId }: OurThemeSwitchProps) => {
  const { darkMode, toggleDarkMode } = useOurManageDarkMode({ memberId });
  const theme = useTheme();

  const onClick = (event: MouseEvent) => {
    event.preventDefault();
    toggleDarkMode();
  };

  return (
    <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
      Light
      <Switch
        id="theme-switch"
        icon={<OurLightModeIcon color={theme.palette.text.primary} />}
        checkedIcon={<OurDarkModeIcon color={theme.palette.text.primary} />}
        checked={darkMode || false}
        sx={{ '& .MuiSwitch-track': { backgroundColor: theme.palette.primary.main, opacity: 0.25 } }}
      />
      Dark
    </Box>
  );
};

OurThemeSwitch.whyDidYouRender = true;

export default OurThemeSwitch;
