import { RecordType } from '@petconsole/pure-base';
import { pclTierApiEntity } from '@petconsole/pure-fe-api';
import { PclTierRecord, PclTierRecords } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchEntity, useOurDispatchValue } from '../../../helpers';
import {
  BaseSliceOptions,
  CrudSliverHooks,
  FetchByIdHook,
  OurEntityHooks,
  SelectByHook,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { createPclTiersByPclServiceReducers, createPclTiersByPclServiceThunk } from './createPclTiersByPclService';
import { updatePclTierStripeReducers, updatePclTierStripeThunk } from './updatePclTierStripe';

type ApiEntityRecord = PclTierRecord;

const apiEntity = pclTierApiEntity;
const { name: sliceName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  comparer: ({ displayOrder: orderA }, { displayOrder: orderB }) => orderA - orderB,
  fetchManyByProperty: { propertyName: 'pclServiceId', apiMethod: 'getListByPclService' },
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      extraReducers: [createPclTiersByPclServiceReducers, updatePclTierStripeReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudSliver } = sliceHookTypes;

export interface OurPclTierHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  pclTierHooks: Required<CrudSliverHooks>;
  customHooks: {
    useFetchesByPclService: FetchByIdHook<PclTierRecords>;
    useSelectsByPclService: SelectByHook<PclTierRecords>;
    useCreatesByPclService: () => ReturnType<typeof useOurDispatchValue>;
    useUpdateStripe: () => ReturnType<typeof useOurDispatchEntity>;
  };
}

export const ourPclTierHooks: OurPclTierHooks = {
  entity: apiEntity,
  pclTierHooks: crudSliver(apiEntity, hooks) as Required<CrudSliverHooks>,
  customHooks: {
    useFetchesByPclService: hooks.useOurFetchPclTiersByPclServiceId as OurPclTierHooks['customHooks']['useFetchesByPclService'],
    useSelectsByPclService: hooks.useOurSelectPclTiersByPclServiceId as OurPclTierHooks['customHooks']['useSelectsByPclService'],
    useCreatesByPclService: () => useOurDispatchValue(createPclTiersByPclServiceThunk),
    useUpdateStripe: () => useOurDispatchEntity(sliceName, updatePclTierStripeThunk),
  },
};

export default reducer;
