import { MouseEventHandler } from 'react';
import { ZoomOut } from '@mui/icons-material';
import OurButton, { OurButtonProps } from './OurButton';

interface OurZoomOutTextButtonProps extends Omit<OurButtonProps, 'onClick'> {
  onClick: MouseEventHandler;
}

const OurZoomOutTextButton = ({ label = 'Zoom Out', ...rest }: OurZoomOutTextButtonProps) => (
  <OurButton {...rest} label={label} startIcon={<ZoomOut />} fullWidth={false} />
);

OurZoomOutTextButton.whyDidYouRender = true;

export default OurZoomOutTextButton;
