import { RecordType } from '@petconsole/pure-base';
import { apiMethodType, EntityType, PatchIdMethod } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../types';

const patchIdOne = <T extends RecordType = RecordType>(builder: ApiBuilder<T>, method: PatchIdMethod<T>, tag: EntityType) =>
  builder.mutation<T, string>({
    query: (id) => ({ type: apiMethodType.patchId, method, id }),
    invalidatesTags: (_result, _error, id) => [tag, { type: tag, id }],
  });

export default patchIdOne;
