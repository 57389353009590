import { PropsWithChildren, memo, ErrorInfo } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { SetState } from '@petconsole/pure-shared';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import useOurError from './useOurError';

interface OurErrorBoundaryProps extends PropsWithChildren {
  setErrorRefresh: SetState<boolean>;
}

const OurErrorBoundary = ({ setErrorRefresh, children }: OurErrorBoundaryProps) => {
  const { log } = useOurError();

  const FallbackComponent = memo((props: FallbackProps) => (
    <ErrorBoundaryFallback {...props} setErrorRefresh={setErrorRefresh} />
  ));

  const logError = (error: Error, info: ErrorInfo) => {
    log('OurErrorBoundary error:', error, 'info:', info).then();
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={logError}
      // onReset={(details) => {
      //   // Reset the state of your app so the error doesn't happen again
      // }}
    >
      {children}
    </ErrorBoundary>
  );
};

OurErrorBoundary.whyDidYouRender = true;

export default OurErrorBoundary;
