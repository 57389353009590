import OurIconButton, { OurIconButtonProps } from './OurIconButton';

export type ActionButtonProps = OurIconButtonProps;

const ActionButton = ({ color = 'primary', onClick, ...rest }: ActionButtonProps) => (
  <OurIconButton
    disabled={!onClick}
    color={color}
    onClick={onClick}
    {...rest}
  />
);

ActionButton.whyDidYouRender = true;

export default ActionButton;
