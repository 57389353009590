import { RecordType } from '@petconsole/pure-base';
import { ourFetchStatus } from '../../constants';
import { AnyAsyncThunk, StateType, ThunkFulfilledMethod, ThunkReducersType } from '../../types';
import thunkPendingReducer from './thunkPendingReducer';
import thunkRejectedReducer from './thunkRejectedReducer';

const ratingFetchReducersById: ThunkReducersType = (fetchMethod, stateProperty) => {
  const { pending, fulfilled, rejected } = fetchMethod as AnyAsyncThunk;
  const statusProperty = 'readStatus';
  const idProperty = `${stateProperty}Id`;
  const fulfilledReducer: ThunkFulfilledMethod<StateType, RecordType> = (topState, { payload }) => {
    const state = topState[stateProperty].rating;
    const rating = payload?.rating as RecordType;
    const ratedId = rating.ratedId as string;

    state.readError[ratedId] = null;
    state.readStatus[ratedId] = ourFetchStatus.succeeded;
    state.ratings[ratedId] = { ...state.ratings[ratedId], ...rating };
  };

  return {
    [String(pending)]: thunkPendingReducer(stateProperty, statusProperty, idProperty),
    [String(fulfilled)]: fulfilledReducer,
    [String(rejected)]: thunkRejectedReducer(stateProperty, statusProperty, idProperty),
  };
};

export default ratingFetchReducersById;
