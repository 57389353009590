import { MouseEventHandler } from 'react';
import { Avatar, Theme } from '@mui/material';
import { ourDefaultImageThumbnail } from '../../constants';

export interface OurThumbnailViewProps {
  image?: string;
  readOnly?: boolean;
  onClick?: MouseEventHandler;
}

const OurThumbnailView = ({ image, readOnly, onClick }: OurThumbnailViewProps) => {
  const onClickHandler = readOnly ? undefined : onClick;

  const avatarSx = {
    width: ({ spacing }: Theme) => spacing(7),
    height: ({ spacing }: Theme) => spacing(7),
    cursor: readOnly ? 'default' : 'pointer',
  };

  return (
    <Avatar
      alt="Thumbnail"
      src={image || ourDefaultImageThumbnail}
      variant="square"
      onClick={onClickHandler}
      sx={avatarSx}
    />
  );
};

export default OurThumbnailView;
