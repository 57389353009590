import { defaultGoogleAddress } from '@petconsole/pure-base';
import {
  Address,
  AdminOnly,
  DataEntityColumnOptions,
  EntityCategory,
  EntityName,
  EntityReaction,
  EntityTabName,
  EntityType,
  KeyIsValue,
  ShipTo,
} from '../types';

export const defaultAdminOnly: AdminOnly = {
  create: false,
  delete: false,
  update: false,
};

export const defaultColumns: Required<DataEntityColumnOptions> = {
  active: false,
  address: false,
  business: false,
  contact: false,
  crud: true,
  images: true,
  json: true,
  member: false,
  owner: false,
  rating: false,
  reaction: false,
  site: false,
  socials: false,
  subscribe: false,
  subscription: false,
  time: false,
};

export const entityCategories = [
  'bus',
  'business',
  'item',
  'order',
  'pet',
  'person',
  'place',
  'post',
  'practice',
  'shop',
  'van',
] as const;
export const entityCategory: KeyIsValue<EntityCategory> = {
  bus: 'bus',
  business: 'business',
  item: 'item',
  order: 'order',
  pet: 'pet',
  person: 'person',
  place: 'place',
  post: 'post',
  practice: 'practice',
  van: 'van',
  shop: 'shop',
} as const;

export const entityNames = [
  'blog',
  'blogDef',
  'bus',
  'busBuilder',
  'busRepair',
  'busSupply',
  'business',
  'cart',
  'cartItem',
  'changeSite',
  'cityStat',
  'comment',
  'dogPark',
  'feed',
  'feedDef',
  'forum',
  'forumDef',
  'foundPet',
  'friend',
  'groomer',
  'lostPet',
  'mailContent',
  'mailFolder',
  'mailHeader',
  'mailMessage',
  'member',
  'nextNumber',
  'notification',
  'order',
  'orderLine',
  'pclAlert',
  'pclCustomer',
  'pclHeader',
  'pclInvoice',
  'pclPrice',
  'pclProduct',
  'pclService',
  'pclSubscription',
  'pclTier',
  'pclTierPrice',
  'pet',
  'petFood',
  'petPhoto',
  'petTrainer',
  'petType',
  'petWalker',
  'petWorkplace',
  'place',
  'post',
  'rank',
  'rating',
  'reaction',
  'shop',
  'shopItem',
  'shopItemOptionTemplate',
  'shopItemVariant',
  'shopItemVariantCode',
  'site',
  'sitter',
  'stripe',
  'stripeAccount',
  'stripeTaxRate',
  'tax',
  'topic',
  'user',
  'van',
  'vanBuilder',
  'vanRepair',
  'vanSupply',
  'vet',
  'test',
] as const;
export const entityName: KeyIsValue<EntityName> = {
  blog: 'blog',
  blogDef: 'blogDef',
  bus: 'bus',
  busBuilder: 'busBuilder',
  busRepair: 'busRepair',
  busSupply: 'busSupply',
  business: 'business',
  cart: 'cart',
  cartItem: 'cartItem',
  changeSite: 'changeSite',
  cityStat: 'cityStat',
  comment: 'comment',
  dogPark: 'dogPark',
  feed: 'feed',
  feedDef: 'feedDef',
  forum: 'forum',
  forumDef: 'forumDef',
  foundPet: 'foundPet',
  friend: 'friend',
  groomer: 'groomer',
  lostPet: 'lostPet',
  mailContent: 'mailContent',
  mailFolder: 'mailFolder',
  mailHeader: 'mailHeader',
  mailMessage: 'mailMessage',
  member: 'member',
  nextNumber: 'nextNumber',
  notification: 'notification',
  order: 'order',
  orderLine: 'orderLine',
  pclAlert: 'pclAlert',
  pclCustomer: 'pclCustomer',
  pclHeader: 'pclHeader',
  pclInvoice: 'pclInvoice',
  pclPrice: 'pclPrice',
  pclProduct: 'pclProduct',
  pclService: 'pclService',
  pclSubscription: 'pclSubscription',
  pclTier: 'pclTier',
  pclTierPrice: 'pclTierPrice',
  pet: 'pet',
  petFood: 'petFood',
  petTrainer: 'petTrainer',
  petType: 'petType',
  petPhoto: 'petPhoto',
  petWalker: 'petWalker',
  petWorkplace: 'petWorkplace',
  place: 'place',
  post: 'post',
  rank: 'rank',
  rating: 'rating',
  reaction: 'reaction',
  shop: 'shop',
  shopItem: 'shopItem',
  shopItemOptionTemplate: 'shopItemOptionTemplate',
  shopItemVariant: 'shopItemVariant',
  shopItemVariantCode: 'shopItemVariantCode',
  site: 'site',
  sitter: 'sitter',
  stripe: 'stripe',
  stripeAccount: 'stripeAccount',
  stripeTaxRate: 'stripeTaxRate',
  tax: 'tax',
  topic: 'topic',
  van: 'van',
  user: 'user',
  vanBuilder: 'vanBuilder',
  vanRepair: 'vanRepair',
  vanSupply: 'vanSupply',
  vet: 'vet',
  test: 'test',
} as const;

export const entityTabNames = [
  // Default tabs
  'General',
  'Details',
  'Avatar',
  'Banner',
  'Photos',
  'Comments',
  'Preview',
  // Alternate tabs
  'Features',
  'Safety',
  'Settings',
  'Summary',
  'Values',
  'VariantOption1',
  'VariantOption2',
  'Variants',
  'Shipping',
  'Resolve',
] as const;
export const entityTabName: KeyIsValue<EntityTabName> = {
  // Default tabs
  General: 'General',
  Details: 'Details',
  Avatar: 'Avatar',
  Banner: 'Banner',
  Photos: 'Photos',
  Comments: 'Comments',
  Preview: 'Preview',
  // Alternate tabs
  Features: 'Features',
  Safety: 'Safety',
  Settings: 'Settings',
  Summary: 'Summary',
  Values: 'Values',
  VariantOption1: 'VariantOption1',
  VariantOption2: 'VariantOption2',
  Variants: 'Variants',
  Shipping: 'Shipping',
  Resolve: 'Resolve',
} as const;

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const defaultTabs = [General, Details, Avatar, Banner, Photos, Comments, Preview];

// TODO: Use a unit test to ensure that this contains only and all entries from entityNames
export const entityTypes = [
  'Blog',
  'BlogDef',
  'Bus',
  'BusBuilder',
  'BusRepair',
  'BusSupply',
  'Business',
  'Cart',
  'CartItem',
  'ChangeSite',
  'CityStat',
  'Comment',
  'DogPark',
  'Feed',
  'FeedDef',
  'Forum',
  'ForumDef',
  'FoundPet',
  'Friend',
  'Groomer',
  'LostPet',
  'MailContent',
  'MailFolder',
  'MailHeader',
  'MailMessage',
  'Member',
  'NextNumber',
  'Notification',
  'Order',
  'OrderLine',
  'PclAlert',
  'PclCustomer',
  'PclHeader',
  'PclInvoice',
  'PclPrice',
  'PclProduct',
  'PclService',
  'PclSubscription',
  'PclTier',
  'PclTierPrice',
  'Pet',
  'PetFood',
  'PetPhoto',
  'PetTrainer',
  'PetType',
  'PetWalker',
  'PetWorkplace',
  'Place',
  'Post',
  'Rank',
  'Rating',
  'Reaction',
  'Shop',
  'ShopItem',
  'ShopItemOptionTemplate',
  'ShopItemVariant',
  'ShopItemVariantCode',
  'Site',
  'Sitter',
  'Stripe',
  'StripeAccount',
  'StripeTaxRate',
  'Tax',
  'Topic',
  'User',
  'Van',
  'VanBuilder',
  'VanRepair',
  'VanSupply',
  'Vet',
  'Test',
] as const;
export const entityType: KeyIsValue<EntityType> = {
  Blog: 'Blog',
  BlogDef: 'BlogDef',
  Bus: 'Bus',
  BusBuilder: 'BusBuilder',
  BusRepair: 'BusRepair',
  BusSupply: 'BusSupply',
  Business: 'Business',
  Cart: 'Cart',
  CartItem: 'CartItem',
  ChangeSite: 'ChangeSite',
  CityStat: 'CityStat',
  Comment: 'Comment',
  DogPark: 'DogPark',
  Feed: 'Feed',
  FeedDef: 'FeedDef',
  Forum: 'Forum',
  ForumDef: 'ForumDef',
  FoundPet: 'FoundPet',
  Friend: 'Friend',
  Groomer: 'Groomer',
  LostPet: 'LostPet',
  MailContent: 'MailContent',
  MailFolder: 'MailFolder',
  MailHeader: 'MailHeader',
  MailMessage: 'MailMessage',
  Member: 'Member',
  NextNumber: 'NextNumber',
  Notification: 'Notification',
  Order: 'Order',
  OrderLine: 'OrderLine',
  PclAlert: 'PclAlert',
  PclCustomer: 'PclCustomer',
  PclHeader: 'PclHeader',
  PclInvoice: 'PclInvoice',
  PclPrice: 'PclPrice',
  PclProduct: 'PclProduct',
  PclService: 'PclService',
  PclSubscription: 'PclSubscription',
  PclTier: 'PclTier',
  PclTierPrice: 'PclTierPrice',
  Pet: 'Pet',
  PetFood: 'PetFood',
  PetPhoto: 'PetPhoto',
  PetTrainer: 'PetTrainer',
  PetType: 'PetType',
  PetWalker: 'PetWalker',
  PetWorkplace: 'PetWorkplace',
  Place: 'Place',
  Post: 'Post',
  Rank: 'Rank',
  Rating: 'Rating',
  Reaction: 'Reaction',
  Shop: 'Shop',
  ShopItem: 'ShopItem',
  ShopItemOptionTemplate: 'ShopItemOptionTemplate',
  ShopItemVariant: 'ShopItemVariant',
  ShopItemVariantCode: 'ShopItemVariantCode',
  Site: 'Site',
  Sitter: 'Sitter',
  Stripe: 'Stripe',
  StripeAccount: 'StripeAccount',
  StripeTaxRate: 'StripeTaxRate',
  Tax: 'Tax',
  Topic: 'Topic',
  Van: 'Van',
  User: 'User',
  VanBuilder: 'VanBuilder',
  VanRepair: 'VanRepair',
  VanSupply: 'VanSupply',
  Vet: 'Vet',
  Test: 'Test',
} as const;

export const initialAddressColumns = {
  address: defaultGoogleAddress(),
  country: '',
  province: '',
  postal: '',
  city: '',
  neighborhood: '',
};

export const initialBusinessColumns = {
  avatar: '',
  banner: null,
  tagline: '',
  aboutUs: '',
  domainName: '',
  urlName: '',
};

export const initialContactColumns = {
  contactName: '',
  phoneNumber: '',
  email: '',
  website: '',
};

export const initialCrudColumns = {
  createdBy: '',
  updatedBy: '',
  createdId: '',
  updatedId: '',
  createdAt: '',
  updatedAt: '',
};

export const initialMemberColumns = {
  memberId: '',
  memberName: '',
};

export const initialOwnerColumns = {
  ownerId: '',
  ownerName: '',
};

export const initialRatingColumns = {
  ratings: new Array(10).fill(0),
};

export const initialReactionColumns = {
  likeCount: 0,
  commentCount: 0,
  shareCount: 0,
};

export const initialSiteColumns = {
  site: '',
};

export const initialSocialColumns = {
  facebook: '',
  instagram: '',
  linkedIn: '',
  pinterest: '',
  tikTok: '',
  twitter: '',
  youTube: '',
};

export const initialSubscribeColumns = {
  ownerId: '',
  ownerName: '',
  pclSubscriptionId: '',
  pclTierLevel: 0,
};

export const initialSubscriptionColumns = {
  pclSubscriptionId: '',
  pclTierLevel: 0,
};

export const initialTimestampColumns = {
  createdAt: '',
  updatedAt: '',
};

export const defaultAddress = (overrides: Partial<Address> = {}) =>
  ({
    address1: '',
    address2: '',
    city: '',
    province: 'BC',
    postal: '',
    country: 'CA',
    ...overrides,
  }) as Address;

export const defaultShipTo = (overrides: Partial<ShipTo> = {}) =>
  ({
    ...defaultAddress(),
    name: '',
    ...overrides,
  }) as ShipTo;

// NOTE: Adding a new entity requires adding it to the constants below
export const dataEntityTypes = entityTypes;
export const dataEntityType = entityType;

export const entityReactions = ['like', 'unlike', 'commented', 'sent', 'shared'] as const;
export const entityReaction: KeyIsValue<EntityReaction> = {
  like: 'like',
  unlike: 'unlike',
  commented: 'commented',
  sent: 'sent',
  shared: 'shared',
} as const;
