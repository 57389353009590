import { postApiEntity } from '@petconsole/pure-fe-api';
import { PostRecord } from '@petconsole/pure-shared';
import {
  resetReadNext,
  sliceHookTypes,
  useOurDispatchAction,
  useOurDispatchValue,
  useOurSelectAction,
} from '../../../helpers';
import useOurDispatchEntity from '../../../helpers/hooks/useOurDispatchEntity';
import { AppState } from '../../../store';
import {
  AnyAsyncThunk,
  BaseSliceOptions,
  CreateHook,
  OurEntityHooks,
  ReactionHooks,
  RecordType,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  ResetSliverHook,
  SelectHook,
  StateSliver,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import {
  createByTopicPostReducers,
  createByTopicPostThunk,
  createReplyPostReducers,
  createReplyPostThunk,
  createSlicePostReducers,
  createSlicePostThunk,
} from './createPost';
import {
  fetchByTopicPostsFromReducers,
  fetchByTopicPostsToReducers,
  fetchReplyPostsSinceReducers,
} from './fetchByTopicPostsReducers';
import fetchByTopicPostsToThunk from './fetchByTopicPostsToThunk';
import { fetchByTopicPostsFromThunk, fetchReplyPostsSinceThunk } from './fetchByTopicPostsThunks';

type ApiEntityRecord = PostRecord;

const apiEntity = postApiEntity;
const { name: sliceName, idName, proper } = apiEntity;
const byTopic = 'byTopic';
const reply = 'reply';

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  hasReaction: true,
  canDelete: true,
  idName,
  comparer: ({ feedAt: a }, { feedAt: b }) => String(a).localeCompare(String(b)),
  reducers: { resetByTopicPostReadNext: resetReadNext(`${byTopic}${proper}`) },
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      payloadCreators: null,
      extraReducers: [createSlicePostReducers],
    },
    {
      prefix: byTopic,
      payloadCreators: null,
      extraReducers: [createByTopicPostReducers, fetchByTopicPostsFromReducers, fetchByTopicPostsToReducers],
    },
    {
      prefix: reply,
      payloadCreator: null,
      payloadCreators: null,
      extraReducers: [createReplyPostReducers, fetchReplyPostsSinceReducers],
    },
  ],
};
const { actions, hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, reactionHooks } = sliceHookTypes;

export const selectReplyPostsThreadId = (state: AppState) => {
  const { replyPost } = state.post as { replyPost: StateSliver };
  const { ids, entities } = replyPost;

  return ids.length > 0 ? entities[ids[0]]?.threadId : '';
};

export interface OurPostHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  postHooks: RequiredCrudSliverHooks;
  byTopicHooks: RequiredSliverHooks;
  replyHooks: RequiredSliverHooks;
  reactionHooks: ReactionHooks;
  customHooks: {
    useCreatePost: CreateHook;
    useCreateByTopicPost: CreateHook;
    useCreateReplyPost: CreateHook;
    useFetchByTopicPostsFrom: () => ReturnType<typeof useOurDispatchValue>;
    useFetchByTopicPostsTo: () => ReturnType<typeof useOurDispatchValue>;
    useFetchReplyPostsSince: () => ReturnType<typeof useOurDispatchValue>;
    useSelectRepliesThreadId: SelectHook;
    useResetByTopicReadNext: ResetSliverHook;
  };
}

export const ourPostHooks: OurPostHooks = {
  entity: apiEntity,
  postHooks: {
    ...crudHooks(apiEntity, hooks),
    useCreate: () => useOurDispatchEntity(sliceName, createSlicePostThunk),
  } as RequiredCrudSliverHooks,
  byTopicHooks: sliverHooks(apiEntity, byTopic, hooks) as RequiredSliverHooks,
  replyHooks: sliverHooks(apiEntity, reply, hooks) as RequiredSliverHooks,
  reactionHooks: reactionHooks(apiEntity, hooks),
  customHooks: {
    useCreatePost: () => useOurDispatchEntity(sliceName, createSlicePostThunk),
    useCreateByTopicPost: () => useOurDispatchEntity(sliceName, createByTopicPostThunk),
    useCreateReplyPost: () => useOurDispatchEntity(sliceName, createReplyPostThunk),
    useFetchByTopicPostsFrom: () => useOurDispatchValue(fetchByTopicPostsFromThunk),
    useFetchByTopicPostsTo: () => useOurDispatchValue(fetchByTopicPostsToThunk),
    useFetchReplyPostsSince: () => useOurDispatchValue(fetchReplyPostsSinceThunk),
    useSelectRepliesThreadId: () => useOurSelectAction(selectReplyPostsThreadId),
    useResetByTopicReadNext: () => useOurDispatchAction(actions.resetByTopicPostReadNext as AnyAsyncThunk),
  },
};

export default reducer;
