import { CardContent, Collapse } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import { ReturnsUnknown } from '@petconsole/pure-shared';
import OurCommentOn from './OurCommentOn';

interface OurCollapsibleCommentsProps {
  commentOnId: string;
  commentOnType: string;
  isOpen: boolean;
  locked?: boolean;
  onComment?: ReturnsUnknown;
  sizes?: RecordType;
  allowResets?: boolean;
}

const OurCollapsibleComments = ({
  commentOnId,
  commentOnType,
  isOpen,
  locked,
  onComment,
  sizes = { xs: 11, sm: 10 },
  allowResets = true,
}: OurCollapsibleCommentsProps) => (
  <Collapse in={isOpen} timeout="auto" unmountOnExit>
    <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
      <OurCommentOn
        commentOnId={commentOnId}
        commentOnType={commentOnType}
        locked={Boolean(locked)}
        onComment={onComment}
        sizes={sizes}
        allowResets={allowResets}
      />
    </CardContent>
  </Collapse>
);

OurCollapsibleComments.whyDidYouRender = true;

export default OurCollapsibleComments;
