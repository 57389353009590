// noinspection ES6PreferShortImport

import { entityName, FriendRecords } from '@petconsole/pure-shared';
import ourStateSliverData from '../../helpers/state/ourStateSliverData';
import { AppState } from '../../store/store';

const sliceName = entityName.friend;

export const selectByName = (state: AppState, name: string) => {
  const entities = Object.values(ourStateSliverData(state, sliceName).entities) as FriendRecords;

  return entities.find((friend) => friend.friendMemberName.toLowerCase() === name.toLowerCase());
};
