import { useCallback, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';

const useAvatarEditor = () => {
  const avatarEditor = useRef<AvatarEditor | undefined>();

  const setAvatarEditor = useCallback((editor: AvatarEditor) => {
    // For now, only set editor, but don't allow clearing it yet
    if (!editor) return;

    avatarEditor.current = editor;
  }, []);

  return { avatarEditor, setAvatarEditor };
};

export default useAvatarEditor;
