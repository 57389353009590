import { RecordType } from '@petconsole/pure-base';
import { AnyAsyncThunk, Slice } from '../../types';
import { updateRatingThunk } from '../thunks/updateRatingThunk';

export const sliceUpdateRatingThunk = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  if (!slice.flag.hasRating) return;

  const { name: sliceName, idName, thunk, api, sliver } = slice;

  return updateRatingThunk({
    sliceName,
    api: api.rating,
    idName,
    fetchEntity: sliver[sliceName].fetchOneCreator as AnyAsyncThunk,
    fetchRating: thunk.fetchRating as AnyAsyncThunk,
  });
};
