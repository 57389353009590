import { PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { CityLocation, MaybeString } from '@petconsole/pure-base';
import { FetchesByLocation } from '@petconsole/pure-fe-state';
import { ReturnsVoid, SetBoolean, SetState } from '@petconsole/pure-shared';
import useOurSetDefaultLocation from '../../hooks/useOurSetDefaultLocation';
import OurGoogleCity from '../google/OurGoogleCity';
import OurFetchStatusControl from '../misc/OurFetchStatusControl';
import useOurByCityDefault from './useOurByCityDefault';
import useOurByCityLocation from './useOurByCityLocation';

interface OurByCitySearchProps extends PropsWithChildren {
  activity: string;
  defaulted?: boolean;
  setDefaulted: SetBoolean;
  hasMore: boolean;
  readError: MaybeString;
  readStatus: string;
  city?: string;
  setCity: SetState<string>;
  location?: CityLocation;
  setLocation: SetState<CityLocation | undefined>;
  reset: ReturnsVoid;
  fetch: FetchesByLocation;
  moreLabel?: string;
  endOfResultsText?: string;
}

const OurByCitySearch = ({
  activity,
  defaulted,
  setDefaulted,
  hasMore,
  readError,
  readStatus,
  city,
  setCity,
  location,
  setLocation,
  reset,
  fetch,
  moreLabel = 'More',
  endOfResultsText = 'End Of Results',
  children,
}: OurByCitySearchProps) => {
  useOurByCityDefault({ defaulted, setDefaulted, setCity, location, setLocation /* , reset, fetch */ });
  useOurSetDefaultLocation({ location });

  const { fetchByLocation, refresh } = useOurByCityLocation({ location, reset, fetch });

  return (
    <Grid container>
      <Grid container justifyContent="center">
        <Grid item width="400px" maxWidth="100%">
          <OurGoogleCity city={city} setCity={setCity} setLocation={setLocation} />
        </Grid>
      </Grid>
      <Grid container py={1}>
        {children}
      </Grid>
      <OurFetchStatusControl
        hasMore={hasMore}
        readError={readError}
        readStatus={readStatus}
        fetch={fetchByLocation}
        refresh={refresh}
        activity={activity}
        endOfResults={endOfResultsText}
        moreLabel={moreLabel}
      />
    </Grid>
  );
};

OurByCitySearch.whyDidYouRender = true;

export default OurByCitySearch;
