// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName, entityTabName } from '../../../entity/constants';
import entityAttributes from '../../../entity/entityAttributes';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity, ValueItems } from '../../../types';

export interface ShopItemOptionTemplate extends CrudColumns {
  type: DataEntityType;
  shopItemOptionTemplateId: string;
  shopId: string;
  name: string;
  values: ValueItems;
}

const { Values } = entityTabName;
export const shopItemOptionTemplateEntity = entityAttributes({
  name: entityName.shopItemOptionTemplate,
  attributes: {
    addLabel: 'Template Name',
    addInstructions: 'Please enter the name of the template to add',
    addTitle: 'Add New Template',
    category: entityCategory.item,
    columns: { images: false, json: false },
    tabs: [Values],
  },
});

const { id, strim, valueItems } = schema;
export const shopItemOptionTemplateSchema = entitySchema<ShopItemOptionTemplate>(shopItemOptionTemplateEntity, {
  shopId: id(true),
  name: strim().required('Template name is required'),
  values: valueItems(),
});

export const shopItemOptionTemplateDefaults: Partial<ShopItemOptionTemplate> = {
  shopId: '',
  name: '',
  values: [],
};

export interface ShopItemOptionTemplateRecord extends ShopItemOptionTemplate, RecordType {}

export type MaybeShopItemOptionTemplateRecord = ShopItemOptionTemplateRecord | null;
export type ShopItemOptionTemplateRecords = ShopItemOptionTemplateRecord[];

export const initialShopItemOptionTemplate: InitialEntity<ShopItemOptionTemplate, ShopItemOptionTemplateRecord> = (overrides = {}) =>
  initialEntity<ShopItemOptionTemplate>(shopItemOptionTemplateEntity, { ...shopItemOptionTemplateDefaults, ...overrides }) as ShopItemOptionTemplateRecord;
