import { SliceChildProps } from '../../types';

const selectNextEntity = (sliver: SliceChildProps, idValue: string) => {
  const { ids, entities } = sliver;
  const index = ids.indexOf(idValue);

  return index >= 0 && index < ids.length - 1 ? entities[ids[index + 1]] : null;
};

export default selectNextEntity;
