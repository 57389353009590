// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity } from '../../types';

interface Site {
  type: DataEntityType;
  siteId: string;
  site: string;
  siteName: string;
  hasShops: boolean;
}

export const siteEntity = entityAttributes({
  name: entityName.site,
  attributes: {
    adminOnly: { create: true, update: true },
    category: entityCategory.item,
    columns: { images: false },
    nameField: `${entityName.site}Name`,
    restrictGet: false,
    tabs: [],
  },
});

const { boolean, site: schemaSite, strim } = schema;
export const siteSchema = entitySchema<Site>(siteEntity, {
  site: schemaSite(),
  siteName: strim().required('Site name is required'),
  hasShops: boolean(),
});

export const siteDefaults: Partial<Site> = {
  site: '',
  siteName: '',
  hasShops: false,
};

export interface SiteRecord extends Site, RecordType {}

export type MaybeSiteRecord = MaybeNull<SiteRecord>;
export type SiteRecords = SiteRecord[];

export const initialSite: InitialEntity<Site, SiteRecord> = (overrides = {}) =>
  initialEntity<Site>(siteEntity, { ...siteDefaults, ...overrides }) as SiteRecord;
