import { useCallback } from 'react';
import { camelCase } from '@petconsole/pure-base';
import { useAppDispatch } from '../../store/storeHooks';
import { ThunkRecordFromRecord } from '../../types';

const useOurDispatchById = (entityType: string, action: ThunkRecordFromRecord) => {
  const dispatch = useAppDispatch();
  const entityId = `${camelCase(entityType)}Id`;
  const callback = (id: string) => dispatch(action({ [entityId]: id }));

  return useCallback(callback, []);
};

export default useOurDispatchById;
