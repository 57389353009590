import { RecordType } from '@petconsole/pure-base';
import OurTabs from '../components/tabs/OurTabs';
import useMaintTabDefinitions from './helpers/useMaintTabDefinitions';
import useMaintTabs from './helpers/useMaintTabs';
import { MaintTabsProps } from './types';

const MaintTabs = <T extends RecordType = RecordType>({
  isOwner,
  tabHooks,
  tabValue,
  setTabValue: customSetTabValue,
  tabWidth,
  tabScroll,
  ...rest
}: MaintTabsProps<T>) => {
  const definitionProps = { ...rest, isOwner };
  const definitions = useMaintTabDefinitions(definitionProps);

  const tabsProps = { tabHooks, setTabValue: customSetTabValue, isOwner, ...definitions };
  const { tabs, setTabValue } = useMaintTabs(tabsProps);

  return <OurTabs {...{ tabs, tabValue, setTabValue, tabWidth: tabWidth ?? 100, scrollButtons: tabScroll ?? false }} />;
};

MaintTabs.whyDidYouRender = true;

export default MaintTabs;
