import { MutableRefObject } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { unwrapResult } from '@reduxjs/toolkit';
import { RecordType } from '@petconsole/pure-base';
import { RequiredCrudSliverHooks, WrappedResult } from '@petconsole/pure-fe-state';
import { EntityRecord, entityTabName, SetState } from '@petconsole/pure-shared';
import useOurSaveEntity from '../../data/ourSaveEntity/useOurSaveEntity';
import { UseMaintSaveProps } from '../types';

const useMaintSave = <T extends RecordType = RecordType>({
  entity,
  form,
  hooks,
  setEditing,
  setSubmitting,
  setBusy,
  setFormValues,
  tabValue,
  avatar,
  setAvatar,
  avatarEditor,
  setAvatarChanged,
  bannerFrom,
  saveBanner,
  images,
  beforeSave,
  customSave,
  afterSave,
}: UseMaintSaveProps<T>) => {
  const { ourSaveEntity } = useOurSaveEntity({ entity });

  const { name: entityName, idName, avatarKey } = entity;
  const crudHooks = hooks[`${entityName}Hooks`] as RequiredCrudSliverHooks;
  const update = crudHooks.useUpdate();

  const { formState } = form;
  const { dirtyFields } = formState;

  const setValues = ((value: EntityRecord) => {
    setFormValues((previous) => ({ ...previous, ...value }));
  }) as SetState<Partial<EntityRecord>>;

  const outerEditor =
    avatarEditor && Object.hasOwn(avatarEditor, 'current')
      ? ((avatarEditor as MutableRefObject<AvatarEditor | undefined>).current as AvatarEditor | undefined)
      : (avatarEditor as AvatarEditor | undefined);

  const outerImages = images;

  const save = (values: T) => {
    const editor =
      avatarEditor && Object.hasOwn(avatarEditor, 'current')
        ? ((avatarEditor as MutableRefObject<AvatarEditor | undefined>).current as AvatarEditor | undefined)
        : (avatarEditor as AvatarEditor | undefined);

    console.log('inner editor:', editor);
    console.log('outer editor:', outerEditor);

    console.log('inner images:', images);
    console.log('outer images:', outerImages);

    return ourSaveEntity({
      id: values[idName] as string,
      values,
      setValues,
      images: images ?? undefined,
      avatarKey,
      avatarImage: avatar ?? undefined,
      setAvatarImage: setAvatar ?? undefined,
      captureAvatar: tabValue === entityTabName.Avatar,
      // avatarChanged: avatarChanged ?? undefined,
      avatarChanged: ((dirtyFields as RecordType)?.avatar ?? undefined) as boolean | undefined,
      setAvatarChanged: setAvatarChanged ?? undefined,
      avatarEditor: editor,
      bannerFrom,
      saveBanner,
      setEditing,
      setSubmitting,
      setBusy,
      save: async (changes) => {
        const saveChanges = (beforeSave ? beforeSave(changes as T) : changes) as T;

        const saved = customSave
          ? await customSave(saveChanges)
          : await unwrapResult((await update({ [entityName]: saveChanges })) as WrappedResult);

        return afterSave ? await afterSave(saved) : saved;
      },
    });
  };

  return { save };
};

export default useMaintSave;
