// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface Groomer extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  name: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const groomerEntity = entityAttributes({
  name: entityName.groomer,
  attributes: {
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    site: anySite.petConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const groomerSchema = entitySchema<Groomer>(groomerEntity, {
  name: strim().required('Groomer name is required'),
});

export const groomerDefaults: Partial<Groomer> = {
  name: '',
};

export interface GroomerRecord extends Groomer, RecordType {}

export type MaybeGroomerRecord = MaybeNull<GroomerRecord>;
export type GroomerRecords = GroomerRecord[];

export const initialGroomer: InitialEntity<Groomer, GroomerRecord> = (overrides = {}) =>
  initialEntity<Groomer>(groomerEntity, { ...groomerDefaults, ...overrides }) as GroomerRecord;
