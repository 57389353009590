import { memo } from 'react';
import { RequiredCrudSliverHooks } from '@petconsole/pure-fe-state';
import { EntityRecord, IsOpenProps } from '@petconsole/pure-shared';
import OurAddListEntity, { OurAddListEntityProps } from '../OurAddListEntity';
import { OurListEntityAddProps } from '../types';

export interface UseOurAddListEntityProps<T extends EntityRecord = EntityRecord> extends Omit<OurAddListEntityProps, 'path' | 'isOpen' | 'setIsOpen'> {
  path?: string;
  addProps?: OurListEntityAddProps<T>,
}

const useOurAddListEntity = <T extends EntityRecord = EntityRecord>({ entity, crudHooks, addProps, ...rest }: UseOurAddListEntityProps<T>) => {
  const { path } = entity;

  const AddListEntity = memo((props: IsOpenProps) => (
    <OurAddListEntity
      {...props}
      entity={entity}
      crudHooks={crudHooks || ({} as RequiredCrudSliverHooks)}
      {...addProps}
      {...rest}
      path={addProps?.path || rest.path || path}
    />
  ));

  return { AddListEntity };
};

export default useOurAddListEntity;
