import { useCallback, useEffect, useRef } from 'react';
import { Hub } from 'aws-amplify/utils';
import { useOurLog, useOurRedirect } from '@petconsole/pure-fe-utils';
import { MaybeRedirectState } from '@petconsole/pure-shared';
import { AuthHubCapsule } from '../types';
import { AuthCheck } from './authCheck';

interface UseOurAuthHandlerProps {
  authCheck: (props: Pick<Parameters<AuthCheck>[0], 'event'>) => ReturnType<AuthCheck>;
}

const useOurAuthHandler = ({ authCheck }: UseOurAuthHandlerProps) => {
  const { setRedirect } = useOurRedirect();
 const { log } = useOurLog();

  const listener = useCallback(
    ({ payload: { event, data } }: AuthHubCapsule) => {

      switch (event) {
        // New simplified cases?:
        case 'customOAuthState':
          if (data) setRedirect(JSON.parse(data as string) as MaybeRedirectState).then();
          break;
        case 'signedIn':
          // Sample data:
          // {
          //   "username": "Google_111145220081153130982",
          //   "userId": "6ff4f8ae-28c8-4dd9-bfa4-ac9a70441ac3"
          // }
          // Sample data:
          // {
          //   "username": "322b6129-fa50-4061-9f18-334bf90c9132",
          //   "userId": "322b6129-fa50-4061-9f18-334bf90c9132",
          //   "signInDetails": {
          //       "loginId": "blackbeltwilbur@gmail.com",
          //       "authFlowType": "USER_SRP_AUTH"
          //   }
          // }
          authCheck({ event }).then();
          break;
        case 'signedOut':
          authCheck({ event }).then();
          break;
        case 'signInWithRedirect':
          break;
        case 'tokenRefresh': // Ignore these for now
          break;
        case 'tokenRefresh_failure': // Ignore these for now - usually token expired
          break;

        default:
          log(`A new auth event (${event}) has happened: `, data).then();
      }
    },[authCheck, setRedirect, log],
  );

  const listening = useRef(false);

  useEffect(() => {
    if (listening.current) return;

    listening.current = true;

    Hub.listen('auth', listener);
  }, []);
};

export default useOurAuthHandler;
