import { properCase } from '@petconsole/pure-base';
import { ReactionRecord } from '@petconsole/pure-shared';
import { ActionProps } from '../../types';
import { AppState } from '../../store';

const setReactionReducer = (sliverName: string) => {
  const capped = properCase(sliverName);

  return {
    [`set${capped}Reaction`]: {
      reducer(state: AppState, { payload }: ActionProps) {
        const { reactionToId } = payload as ReactionRecord;

        state[sliverName as keyof AppState].reactions[reactionToId] = payload;
      },
    },
  };
};

export default setReactionReducer;
