import OurExternalLink from '../../links/OurExternalLink';

interface OurUnsplashCreditProps {
  path?: string;
  author?: string;
  text?: string;
}

const OurUnsplashCredit = ({ path, author, text }: OurUnsplashCreditProps) => {
  const host = 'https://unsplash.com';
  const query = 'utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText';

  return (
    <span>
      {'Photo by '}
      <OurExternalLink href={`${host}/${author}?${query}`} label={text} />
      {' on '}
      <OurExternalLink href={`${host}/${path}?${query}`} label="Unsplash" />
    </span>
  );
};

OurUnsplashCredit.whyDidYouRender = true;

export default OurUnsplashCredit;
