import { busRepairApiEntity } from '@petconsole/pure-fe-api';
import { BusRepairRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RecordType,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = BusRepairRecord;

const apiEntity = busRepairApiEntity;
const { name: busRepair, plural: busRepairs } = apiEntity;
const busRepairAdmin = `${busRepair}Admin`;

const Tab = { busRepairAdmin, busRepair, busRepairs } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  busRepairAdmin: { default: '' },
  busRepair: { default: '' },
  busRepairs: { default: 'By City' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudSliver, sliver, citySearch, property, rating, tabHooks } = sliceHookTypes;

export interface OurBusRepairHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  busRepairHooks: RequiredCrudSliverHooks;
  cityBusRepairHooks: RequiredSliverHooks;
  newestBusRepairHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourBusRepairHooks: OurBusRepairHooks = {
  entity: apiEntity,
  busRepairHooks: crudSliver(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityBusRepairHooks: sliver(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestBusRepairHooks: sliver(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearch(apiEntity, hooks),
  propertyHooks: property(apiEntity, hooks),
  ratingHooks: rating(apiEntity, hooks),
  tabHooks: tabHooks(Object.keys(Tab), hooks) as TabHooks,
};

export default reducer;
