import { Help } from '@mui/icons-material';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

type OurHelpButtonProps = Omit<OurIconButtonProps, 'tip' | 'Icon'>;

const OurHelpButton = (props: OurHelpButtonProps) => <OurIconButton {...props} tip="Help" Icon={Help} />;

OurHelpButton.whyDidYouRender = true;

export default OurHelpButton;
