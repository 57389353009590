import { ElementType, MouseEventHandler, useMemo } from 'react';
import { RecordType } from '@petconsole/pure-base';
import { OurEntityHooks, TabValueHooks } from '@petconsole/pure-fe-state';
import {
  EntityAttributes,
  ExtendedFormik,
  Image,
  ImageKey,
  Images,
  RecordOfEntityTabDefinitions,
  ReturnsVoid,
  SetState,
  StringReturnsVoid,
} from '@petconsole/pure-shared';
import OurAvatarViewEdit from '../../components/avatar/OurAvatarViewEdit';
import { EntityCardReaction } from '../../components/cards/ourEntityCards/helpers/useEntityCardReaction';
import OurBannerZone from '../../components/misc/OurBannerZone';
import OurTabs from '../../components/tabs/OurTabs';
import { SetAvatarEditor } from '../../types';
import BasicEntityPreview, { BasicEntityPreviewProps } from '../BasicEntityPreview';
import ourListEntityTabs from './ourListEntityTabs';

interface UseOurListEntityTabsProps<T extends RecordType = RecordType> {
  entity: EntityAttributes;
  hooks: OurEntityHooks<T>;
  tabNames: RecordType;
  tabDefinitions?: RecordOfEntityTabDefinitions;
  tabHooks: TabValueHooks;
  tabValue?: string;
  setTabValue?: StringReturnsVoid;
  tabWidth?: number;
  scrollButtons?: boolean;
  formik: ExtendedFormik;
  id?: string;
  General?: ElementType;
  generalProps?: RecordType;
  Details?: ElementType;
  Photos?: ElementType;
  Comments?: ElementType;
  commentsHeading?: string;
  Preview?: ElementType;
  avatar?: string;
  setAvatar?: SetState<string>;
  setAvatarChanged?: SetState<boolean>;
  setAvatarEditor?: SetAvatarEditor;
  imageKey?: ImageKey;
  banner?: Image;
  photos?: Images;
  setBanner?: SetState<Image | undefined>;
  editing: boolean;
  owner: boolean;
  readOnly: boolean;
  guest: boolean;
  reaction?: EntityCardReaction;
  reRead?: ReturnsVoid;
}

const useOurListEntityTabs = <T extends RecordType = RecordType>({
  entity,
  hooks,
  tabNames,
  tabDefinitions,
  tabHooks,
  tabValue: customTabValue,
  setTabValue: customSetTabValue,
  tabWidth = 100,
  scrollButtons,
  formik,
  id = '',
  General,
  generalProps,
  Details,
  Photos,
  Comments,
  commentsHeading,
  Preview,
  avatar,
  setAvatar,
  setAvatarChanged,
  setAvatarEditor,
  imageKey,
  banner,
  photos,
  setBanner,
  editing,
  owner,
  readOnly,
  guest,
  reaction = {} as EntityCardReaction,
  reRead = () => {
    return;
  },
}: UseOurListEntityTabsProps<T>) => {
  const defaultTabValue = tabHooks.useSelect();
  const tabValue = customTabValue || defaultTabValue;

  const defaultSetTabValue = tabHooks.useSet();
  const setTabValue = customSetTabValue || defaultSetTabValue;

  const { category, path, shared, subscribable, type } = entity;

  const onLike: MouseEventHandler = (event) => {
    reaction?.onLike && reaction?.onLike(event);
    reRead();
  };
  const reactions: EntityCardReaction = { ...reaction, onLike: reaction?.onLike ? onLike : undefined };
  const { Features, Safety, Settings, Summary, Values, VariantOption1, VariantOption2, Variants, Shipping, Resolve } =
    tabDefinitions || {};
  const previewProps: BasicEntityPreviewProps<T> = {
    hooks,
    category,
    entityPath: path,
    subscribable,
    formik,
    image: avatar,
    banner,
    photos,
  };

  const tabs = ourListEntityTabs({
    owner,
    tabNames,
    tabDefinitions,
    ...(General && {
      GeneralTab: (
        <General
          entity={entity}
          formik={formik}
          isEditing={editing}
          isOwner={owner}
          readOnly={readOnly}
          reaction={reactions}
          generalProps={generalProps}
        />
      ),
    }),
    ...(Details && {
      DetailsTab: <Details entity={entity} formik={formik} isEditing={editing} isOwner={owner} readOnly={readOnly} />,
    }),
    ...(setAvatar && {
      AvatarTab: (
        <OurAvatarViewEdit
          avatar={avatar}
          setAvatar={setAvatar}
          setAvatarChanged={setAvatarChanged as SetState<boolean>}
          setAvatarEditor={setAvatarEditor as SetAvatarEditor}
          readOnly={!editing || (!owner && !shared)}
          heading=""
        />
      ),
    }),
    ...(imageKey && {
      BannerTab: (
        <OurBannerZone
          imageKey={imageKey}
          banner={banner}
          setBanner={setBanner as SetState<Image>}
          entity={type}
          id={id}
          canEdit={editing && (owner || shared)}
        />
      ),
    }),
    ...(Photos && { PhotosTab: <Photos /> }),
    ...(Comments && {
      CommentsTab: (
        <Comments commentOnId={id} commentOnType={type} locked={editing || guest} heading={commentsHeading} />
      ),
    }),
    ...(Preview
      ? { PreviewTab: <Preview {...previewProps} /> }
      : tabNames.Preview
        ? { PreviewTab: <BasicEntityPreview {...previewProps} /> }
        : {}),
    ...(Features && { FeaturesTab: <Features.TabElement formik={formik} /> }),
    ...(Safety && { SafetyTab: <Safety.TabElement formik={formik} /> }),
    ...(Settings && { SettingsTab: <Settings.TabElement formik={formik} /> }),
    ...(Summary && { SummaryTab: <Summary.TabElement formik={formik} /> }),
    ...(Values && { ValuesTab: <Values.TabElement formik={formik} /> }),
    ...(VariantOption1 && {
      VariantOption1Tab: <VariantOption1.TabElement formik={formik} />,
    }),
    ...(VariantOption2 && {
      VariantOption2Tab: <VariantOption2.TabElement formik={formik} />,
    }),
    ...(Variants && { VariantsTab: <Variants.TabElement formik={formik} /> }),
    ...(Shipping && { ShippingTab: <Shipping.TabElement formik={formik} /> }),
    ...(Resolve && {
      ResolveTab: <Resolve.TabElement entity={entity} formik={formik} isOwner={owner} readOnly={readOnly} />,
    }),
  });

  const EntityTabs = useMemo(
    () => (
      <OurTabs
        tabs={tabs}
        tabValue={tabValue}
        setTabValue={setTabValue}
        tabWidth={tabWidth}
        scrollButtons={scrollButtons}
      />
    ),
    [scrollButtons, setTabValue, tabValue, tabWidth, tabs],
  );

  return { EntityTabs };
};

export default useOurListEntityTabs;
