import { RecordType } from '@petconsole/pure-base';
import { lostPetApiEntity } from '@petconsole/pure-fe-api';
import { LostPetRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = LostPetRecord;

const apiEntity = lostPetApiEntity;
const { name: sliceName, plural } = apiEntity;
const lostPet = sliceName;
const lostPets = plural;
const lostPetAdmin = `${sliceName}Admin`;

const Tab = { lostPetAdmin, lostPet, lostPets } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  lostPetAdmin: { default: '' },
  lostPet: { default: '' },
  lostPets: { default: '' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  sortProperty: 'createdAt',
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, tabHooks } = sliceHookTypes;

export interface OurLostPetHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  lostPetHooks: RequiredCrudSliverHooks;
  cityLostPetHooks: RequiredSliverHooks;
  newestLostPetHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  tabHooks: TabHooks;
}

export const ourLostPetHooks: OurLostPetHooks = {
  entity: apiEntity,
  lostPetHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityLostPetHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestLostPetHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
