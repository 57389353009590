import { useRollbarPerson } from '@rollbar/react';

interface UseOurLogMemberProps {
  memberId: string;
}

const useOurLogMember = ({ memberId }: UseOurLogMemberProps) => {
  useRollbarPerson({ person: memberId });
};

export default useOurLogMember;
