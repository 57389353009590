// noinspection ES6PreferShortImport

import { MaybeNull, RecordType, MaybeString } from '@petconsole/pure-base';
import { postEventType } from '../../constants';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, DefaultColumns, InitialEntity, LayoutGrid, ReactionColumns } from '../../types';

export interface Post extends DefaultColumns, ReactionColumns {
  type: DataEntityType;
  postId: string;
  topicId: string;
  threadId?: string;
  feedAt: MaybeString;
  memberId: string; // same as createdId, but easier to think about
  public: boolean;
  title?: string;
  layout: LayoutGrid; // use if text = null and images.length = 0
  text: MaybeString;
  repliesLocked: boolean;
  replyCount: number;
  editedAt: MaybeString;
  reportedAt: MaybeString;
  lastReplyAt: MaybeString;
}

export const postEntity = entityAttributes({
  name: entityName.post,
  attributes: {
    category: entityCategory.post,
    columns: { reaction: true },
    eventType: postEventType,
    loadLimit: 5,
    maxImages: 4,
    nameField: undefined,
    test: { invalidData: () => ({ text: {} }), validData: () => ({ text: 'New Text' }) },
  },
});

const { array, boolean, date, id, number, text } = schema;
export const postSchema = entitySchema<Post>(postEntity, {
  topicId: id(true),
  threadId: id(),
  feedAt: date(),
  memberId: id(true), // same as createdId, but easier to think about
  public: boolean(true),
  title: text(),
  layout: array(), // use if text = null and images.length = 0
  text: text(true),
  repliesLocked: boolean(false),
  replyCount: number(),
  editedAt: date(),
  reportedAt: date(),
  lastReplyAt: date(),
});

export const postDefaults: Partial<Post> = {
  topicId: '',
  threadId: '',
  feedAt: null,
  memberId: '', // same as createdId, but easier to think about
  public: true,
  title: '',
  layout: [[[]]], // use if text = null and images.length = 0
  text: null,
  repliesLocked: false,
  replyCount: 0,
  editedAt: null,
  reportedAt: null,
  lastReplyAt: null,
};

export interface PostRecord extends Post, RecordType {}

export type MaybePostRecord = MaybeNull<PostRecord>;
export type PostRecords = PostRecord[];

export const initialPost: InitialEntity<Post, PostRecord> = (overrides = {}) =>
  initialEntity<Post>(postEntity, { ...postDefaults, ...overrides }) as PostRecord;
