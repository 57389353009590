// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import entityAttributes from '../../entity/entityAttributes';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import {
  DataEntityType,
  InitialEntity,
  RatingBusinessColumns,
  SubscribeColumns,
} from '../../types';

interface BusSupply extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  busSupplyId: string;
  name: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const busSupplyEntity = entityAttributes({
  name: entityName.busSupply,
  attributes: {
    category: entityCategory.business,
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    plural: `busSupplies`,
    restrictGet: false,
    site: anySite.busConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const busSupplySchema = entitySchema<BusSupply>(busSupplyEntity, {
  name: strim().required('Business name is required'),
});

export const busSupplyDefaults: Partial<BusSupply> = {
  name: '',
};

export interface BusSupplyRecord extends BusSupply, RecordType {}

export type MaybeBusSupplyRecord = MaybeNull<BusSupplyRecord>;
export type BusSupplyRecords = BusSupplyRecord[];

export const initialBusSupply: InitialEntity<BusSupply, BusSupplyRecord> = (overrides = {}) =>
  initialEntity<BusSupply>(busSupplyEntity, { ...busSupplyDefaults, ...overrides }) as BusSupplyRecord;
