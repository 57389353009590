import { forumApiEntity } from '@petconsole/pure-fe-api';
import { ForumRecord } from '@petconsole/pure-shared';
import { StateSlivers, ThunkApi } from '../../../types';
import { fetchToForumForumsToHierarchyThunk, fetchToForumParent } from './fetchToForum';

const { api } = forumApiEntity;

export const toForumForumPayloadCreators = async ({ forumId }: ForumRecord, { getState, dispatch }: ThunkApi) => {
  const state = getState();
  const forumSlice = state.forum as StateSlivers;
  const toForumForum = forumSlice.toForumForum;

  if (toForumForum.entities[forumId]) {
    const { hierarchy } = toForumForum.entities[forumId] as ForumRecord;

    dispatch(fetchToForumForumsToHierarchyThunk(hierarchy));

    return;
  }

  const forum = (await api.get(forumId)) as ForumRecord;

  return fetchToForumParent(forum, state, dispatch);
};
