// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, TimeColumns } from '../../types';

export interface Rating extends TimeColumns {
  type: DataEntityType;
  ratingId: string;
  ratedId: string;
  ratedType: string;
  memberId: string;
  rating: number;
}

export const ratingEntity = entityAttributes({
  name: entityName.rating,
  attributes: {
    category: entityCategory.item,
    columns: { crud: false, images: false, json: false, time: true },
    nameField: undefined,
    restrictGet: false,
    test: { invalidData: () => ({ ratedType: {} }), validData: () => ({ rating: 4.5 }) },
  },
});

const { id, number, type } = schema;
export const ratingSchema = entitySchema<Rating>(ratingEntity, {
  ratedId: id(true),
  ratedType: type(),
  memberId: id(true),
  rating: number(),
});

export const ratingDefaults: Partial<Rating> = {
  ratedId: '',
  ratedType: '',
  memberId: '',
  rating: 0,
};

export interface RatingRecord extends Rating, RecordType {}

export type MaybeRatingRecord = MaybeNull<RatingRecord>;
export type RatingRecords = RatingRecord[];
export type RecordOfRatingRecords = Record<string, RatingRecord>;

export const initialRating: InitialEntity<Rating, RatingRecord> = (overrides = {}) =>
  initialEntity<Rating>(ratingEntity, { ...ratingDefaults, ...overrides }) as RatingRecord;
