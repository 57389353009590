import { ApiUseGetQuery, OurEntityHooks } from '@petconsole/pure-fe-state';
import { EntityAttributes } from '@petconsole/pure-shared';
import { OurMyEntityTabPageProps } from '../OurMyEntityTabPage';

interface OurMyEntityTabProps
  extends Omit<OurMyEntityTabPageProps, 'getEntityQueryHook' | 'entityPath' | 'helpContent'> {
  entity: EntityAttributes;
  hooks: OurEntityHooks;
  queryHook: ApiUseGetQuery;
  help?: string;
}

const ourMyEntityTabProps = ({ entity, hooks, queryHook, help = '', ...rest }: OurMyEntityTabProps) => {
  const props = {
    hooks,
    getEntityQueryHook: queryHook,
    helpContent: help,
    header: true,
    ...rest,
  };

  return { props };
};

export default ourMyEntityTabProps;
