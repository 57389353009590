import { ReactNode } from 'react';
import { RecordType } from '@petconsole/pure-base';
import {
  EntityTab,
  entityTabName,
  EntityTabName,
  EntityTabs,
  RecordOfEntityTabDefinitions,
} from '@petconsole/pure-shared';

interface OurListEntityTabsProps {
  owner: boolean;
  tabNames: RecordType;
  tabDefinitions?: RecordOfEntityTabDefinitions;
  GeneralTab?: ReactNode;
  DetailsTab?: ReactNode;
  AvatarTab?: ReactNode;
  BannerTab?: ReactNode;
  PhotosTab?: ReactNode;
  CommentsTab?: ReactNode;
  PreviewTab?: ReactNode;
  FeaturesTab?: ReactNode;
  SafetyTab?: ReactNode;
  SettingsTab?: ReactNode;
  SummaryTab?: ReactNode;
  ValuesTab?: ReactNode;
  VariantOption1Tab?: ReactNode;
  VariantOption2Tab?: ReactNode;
  VariantsTab?: ReactNode;
  ShippingTab?: ReactNode;
  ResolveTab?: ReactNode;
}

const ourListEntityTabs = ({
  owner,
  tabNames,
  tabDefinitions = {},
  GeneralTab,
  DetailsTab,
  AvatarTab,
  BannerTab,
  PhotosTab,
  CommentsTab,
  PreviewTab,
  FeaturesTab,
  SafetyTab,
  SettingsTab,
  SummaryTab,
  ValuesTab,
  VariantOption1Tab,
  VariantOption2Tab,
  VariantsTab,
  ShippingTab,
  ResolveTab,
}: OurListEntityTabsProps) => {
  const { Features, Safety, VariantOption1, VariantOption2, Resolve, Summary, Values } = entityTabName;

  return Object.keys(tabNames).reduce((tabs, tabName): EntityTabs => {
    if (tabDefinitions[tabName as EntityTabName]?.ownerOnly && !owner) return tabs;

    if (tabName === entityTabName.General && GeneralTab)
      return [...tabs, [tabName, GeneralTab, tabDefinitions[tabName] ? tabDefinitions[tabName]?.outline : true]];
    if (tabName === entityTabName.Details && DetailsTab) return [...tabs, [tabName, DetailsTab, true]];
    if (tabName === entityTabName.Avatar && AvatarTab) return [...tabs, [tabName, AvatarTab, true]];
    if (tabName === entityTabName.Banner && BannerTab) return [...tabs, [tabName, BannerTab, true]];
    if (tabName === entityTabName.Photos && PhotosTab) return [...tabs, [tabName, PhotosTab, true]];
    if (tabName === entityTabName.Comments && CommentsTab) return [...tabs, [tabName, CommentsTab]];
    if (tabName === entityTabName.Preview && PreviewTab) return [...tabs, [tabName, PreviewTab, true]];
    if (tabName === Features && FeaturesTab)
      return [...tabs, [tabName, FeaturesTab, tabDefinitions[Features]?.outline]];
    if (tabName === Safety && SafetyTab) return [...tabs, [tabName, SafetyTab, tabDefinitions[Safety]?.outline]];
    if (tabName === entityTabName.Settings && SettingsTab) return [...tabs, [tabName, SettingsTab]];
    if (tabName === entityTabName.Summary && SummaryTab)
      return [...tabs, [tabName, SummaryTab, tabDefinitions[Summary]?.outline]];
    if (tabName === entityTabName.Values && ValuesTab)
      return [...tabs, [tabName, ValuesTab, tabDefinitions[Values]?.outline]];
    if (tabName === VariantOption1 && VariantOption1Tab)
      return [
        ...tabs,
        [tabDefinitions[VariantOption1]?.name, VariantOption1Tab, tabDefinitions[VariantOption1]?.outline] as EntityTab,
      ];
    if (tabName === VariantOption2 && VariantOption2Tab)
      return [
        ...tabs,
        [tabDefinitions[VariantOption2]?.name, VariantOption2Tab, tabDefinitions[VariantOption2]?.outline] as EntityTab,
      ];
    if (tabName === entityTabName.Variants && VariantsTab) return [...tabs, [tabName, VariantsTab, true]];
    if (tabName === entityTabName.Shipping && ShippingTab) return [...tabs, [tabName, ShippingTab, true]];
    if (tabName === Resolve && ResolveTab) return [...tabs, [tabName, ResolveTab, true]];

    return tabs;
  }, [] as EntityTabs);
};

export default ourListEntityTabs;
