import { RecordOfRecordType } from '@petconsole/pure-base';
import { SetPropertyValue } from '../../types';

const setPropertyValue = ({ state, propertyName, parentName = '', getValue }: SetPropertyValue) => {
  if (!parentName) state[propertyName] = getValue(state[propertyName]);
  else
    (state as RecordOfRecordType)[parentName][propertyName] = getValue(
      (state as RecordOfRecordType)[parentName][propertyName],
    );
};

export default setPropertyValue;
