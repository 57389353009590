import { Card, Grid, GridProps } from '@mui/material';

interface OurGridCardCenterContentProps extends GridProps {
  raised?: boolean;
}

const OurGridCardCenterContent = ({ raised = true, children, ...rest }: OurGridCardCenterContentProps) => (
  <Card id="margin-card" raised={raised}>
    <Grid container id="margin-grid-card" {...rest}>
      {children}
    </Grid>
  </Card>
);

OurGridCardCenterContent.whyDidYouRender = true;

export default OurGridCardCenterContent;
