import { isMobile } from 'react-device-detect';
import { FieldError, Path } from 'react-hook-form';
import { StandardTextFieldProps, TextFieldProps } from '@mui/material';
import { camelToWords, RecordType } from '@petconsole/pure-base';
import ourBackgroundColor from '../../../misc/ourBackgroundColor';
import { TextFieldVariant } from '../../../types';

export interface GetInputProps<T extends RecordType = RecordType> extends Omit<StandardTextFieldProps, 'variant'> {
  id: Path<T>;
  variant?: TextFieldVariant;
  darkMode: boolean;
  caps?: boolean;
  readOnly?: boolean;
  allowLastPass?: boolean;
  isTouched: boolean;
  fieldError?: FieldError;
}

const getInputProps = <T extends RecordType = RecordType>({
  id,
  name,
  label,
  value,
  helperText,
  fullWidth = true,
  darkMode,
  caps,
  readOnly,
  autoFocus,
  autoComplete,
  allowLastPass,
  InputProps,
  inputProps,
  isTouched,
  fieldError,
  ...rest
}: GetInputProps<T>): TextFieldProps => {
  const variant = rest?.variant || (!readOnly && 'filled') || 'standard';
  const initialValue = value ?? '';
  const hasValue = Boolean(initialValue || initialValue === 0);
  const backgroundColor = ourBackgroundColor({ darkMode, darkAlpha: 0.09, lightAlpha: 0.06 });
  const helpText = helperText ?? (isTouched ? fieldError?.message : undefined);

  return {
    ...rest,
    id,
    name: name || id,
    label: label !== undefined ? label : camelToWords((name || id || '').split('.').pop()),
    value: initialValue,
    error: Boolean(helpText),
    helperText: helpText,
    fullWidth,
    variant,
    autoFocus: autoFocus && !isMobile,
    InputProps: {
      ...InputProps,
      autoComplete: autoComplete || 'off',
      ...(readOnly && { readOnly: true }),
      // Disable underline if read only AND the property has a value
      ...(readOnly && hasValue && variant !== 'outlined' && { disableUnderline: true }),
      ...(!readOnly && variant === 'outlined' && { sx: { ...inputProps?.sx, backgroundColor } }),
    } as RecordType,
    inputProps: {
      ...inputProps,
      ...(caps && { style: { ...inputProps?.style, textTransform: 'uppercase' } }),
      // We generally don't want LastPass interfering with our input fields
      ...(!allowLastPass && { 'data-lpignore': true, 'data-1p-ignore': true }),
    },
  };
};

export default getInputProps;
