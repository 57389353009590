import { useMemo } from 'react';
import { OurCurrencyCode, ourDefaultCurrencyCode } from '@petconsole/pure-shared';

interface UseOurCurrencyFormatProps {
  currency?: OurCurrencyCode;
}

const useOurCurrencyFormat = ({ currency = ourDefaultCurrencyCode }: UseOurCurrencyFormatProps = {}) => {
  const currencyFormat = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
      }),
    [currency],
  );

  return currencyFormat.format;
};

export default useOurCurrencyFormat;
