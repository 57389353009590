import { useCallback } from 'react';
import { RecordType, camelCase } from '@petconsole/pure-base';
import { entityName, mailMessageEntity } from '@petconsole/pure-shared';
import { useAppDispatch } from '../../store/storeHooks';
import { AnyAsyncThunk } from '../../types';

const useOurFetchEntity = (entityType: string, action: AnyAsyncThunk) => {
  const dispatch = useAppDispatch();
  const entityId =
    entityType === 'member'
      ? 'memberName'
      : entityType === 'user'
        ? 'memberId'
        : entityType === entityName.mailMessage
          ? mailMessageEntity.idName
          : `${camelCase(entityType)}Id`;

  const callback = (id: string | RecordType) =>
    dispatch(action({ [entityId]: typeof id === 'string' ? id : id[entityId] }));

  return useCallback(callback, []);
};

export default useOurFetchEntity;
