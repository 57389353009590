import { RecordType } from '@petconsole/pure-base';
import { BuilderCaseReducer, OtherReducersBuilder } from '../../types';

const addReducerCases = (
  builder: OtherReducersBuilder,
  reducers: RecordType | undefined = {},
  match = /pending|fulfilled|rejected$/,
) => {
  Object.entries(reducers).forEach(([actionCreator, reducer]) => {
    if (!actionCreator.match(match)) return;

    try {
      builder.addCase(actionCreator, <BuilderCaseReducer>reducer);
    } catch (e) {
      console.log('addCase error on actionCreator:', actionCreator);
    }
  });
};

export default addReducerCases;
