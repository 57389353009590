import { Container, Typography } from '@mui/material';
import useOurAuthenticated from '../../auth/useOurAuthenticated';

interface OurTitleHeadingProps {
  title?: string;
  heading?: string;
  guestMode?: boolean;
}

const OurTitleHeading = ({ title, heading, guestMode }: OurTitleHeadingProps) => {
  const { authenticated } = useOurAuthenticated();
  const titleText = authenticated || !guestMode ? title : '(Read Only Guest Mode)';

  return (
    <Container sx={{ textAlign: 'center' }}>
      <Typography variant="caption" color="textSecondary">
        {titleText}
      </Typography>
      <Typography variant="h6">{heading}</Typography>
    </Container>
  );
};

OurTitleHeading.whyDidYouRender = true;

export default OurTitleHeading;
