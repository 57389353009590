import { RecordType } from '@petconsole/pure-base';
import { mailMessageApiEntity } from '@petconsole/pure-fe-api';
import { MailMessageRecord } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchValue } from '../../../helpers';
import useOurDispatchEntity from '../../../helpers/hooks/useOurDispatchEntity';
import {
  BaseSliceOptions,
  CreateHook,
  FetchByIdHook,
  OurEntityHooks,
  RequiredSliverHooks,
  SliceReducers,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { addMailMessageReducers, addMailMessageThunk } from './addMailMessage';
import { moveMailMessageToTrashReducers, moveMailMessageToTrashThunk } from './moveMailMessageToTrash';
import { readMailMessageReducers, readMailMessageThunk } from './readMailMessage';

type ApiEntityRecord = MailMessageRecord;

const apiEntity = mailMessageApiEntity;
const { name: sliceName, idName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  idName,
  selectId: (header) => header[idName] as string,
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      payloadCreators: null,
      extraReducers: [readMailMessageReducers, addMailMessageReducers],
      sliceReducers: [moveMailMessageToTrashReducers] as unknown as SliceReducers<MailMessageRecord>[],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { sliverHooks } = sliceHookTypes;

export interface OurMailMessageHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  mailMessageHooks: RequiredSliverHooks;
  customHooks: {
    useReadMessage: FetchByIdHook;
    useAddMessage: CreateHook;
    useMoveToTrash: FetchByIdHook;
  };
}

export const ourMailMessageHooks: OurMailMessageHooks = {
  entity: apiEntity,
  mailMessageHooks: sliverHooks(apiEntity, '', hooks) as RequiredSliverHooks,
  customHooks: {
    useReadMessage: () => useOurDispatchValue(readMailMessageThunk),
    useAddMessage: () => useOurDispatchEntity(sliceName, addMailMessageThunk),
    useMoveToTrash: () => useOurDispatchValue(moveMailMessageToTrashThunk),
  },
};

export default reducer;
