import { PropsWithChildren } from 'react';
import { Dialog, DialogProps, DialogTitle, Paper } from '@mui/material';
import useOurWidth from '../../hooks/useOurWidth';
import DraggablePaper from './DraggablePaper';

export interface OurDraggableDialogProps extends PropsWithChildren {
  title?: string
  isOpen: boolean;
  onClose?: DialogProps['onClose'];
  draggable?: boolean;
  fullWidth?: boolean;
  maxWidth?: DialogProps['maxWidth'];
}

const OurDraggableDialog = ({
  title,
  isOpen,
  onClose,
  draggable = true,
  fullWidth,
  maxWidth,
  children,
}: OurDraggableDialogProps) => {
  const viewWidth = useOurWidth();
  const component = draggable === false || viewWidth === 'xs' ? Paper : DraggablePaper;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperComponent={component}
      aria-labelledby="draggable-handle"
      {...(fullWidth && { fullWidth })}
      {...(maxWidth && { maxWidth })}
      PaperProps={{ sx: { margin: viewWidth === 'xs' ? 1 : 4 } }}
    >
      <DialogTitle
        id="draggable-handle"
        display="flex"
        justifyContent="center"
        sx={{
          px: 4,
          py: 1,
          cursor: 'move',
          color: ({ palette }) => palette.primary.contrastText,
          backgroundColor: ({ palette }) => palette.primary.main,
        }}
      >
        {title}
      </DialogTitle>
      {children}
    </Dialog>
  );
};

OurDraggableDialog.whyDidYouRender = true;

export default OurDraggableDialog;
