import { RecordType } from '@petconsole/pure-base';
import { siteApiEntity } from '@petconsole/pure-fe-api';
import { SiteRecord } from '@petconsole/pure-shared';
import { fetchParams, sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  OurEntityHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  ThunkApi,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = SiteRecord;

const apiEntity = siteApiEntity;
const { api, name: sliceName, proper } = apiEntity;
const hasShopsName = `hasShops${proper}`;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  sortProperty: 'site',
  tabs: undefined,
  slivers: [
    {
      prefix: 'hasShops',
      payloadCreators: async (_: unknown, { getState }: ThunkApi) =>
        api.getListByHasShops(fetchParams(getState, sliceName, hasShopsName)),
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks } = sliceHookTypes;

export interface OurSiteHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  siteHooks: RequiredCrudSliverHooks;
  hasShopsSiteHooks: RequiredSliverHooks;
}

export const ourSiteHooks: OurSiteHooks = {
  entity: apiEntity,
  siteHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  hasShopsSiteHooks: sliverHooks(apiEntity, 'HasShops', hooks) as RequiredSliverHooks,
};

export default reducer;
