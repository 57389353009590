import entityAttributes from '../../entity/entityAttributes';
import { entityCategory, entityName } from '../../entity';

export const userEntity = entityAttributes({
  name: entityName.user,
  attributes: {
    category: entityCategory.person,
    idName: 'memberId',
  },
});
