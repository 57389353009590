import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { authSignOut } from '@petconsole/pure-fe-amplify';
import { ourUserHooks, useCurrentMember, WrappedResult } from '@petconsole/pure-fe-state';
// import { ourNowToDisplayDateTime } from '@petconsole/pure-fe-utils';
import { webPath } from '@petconsole/pure-shared';
import useOurAuthenticated from '../auth/useOurAuthenticated';

const useOurCurrentMemberRefresh = (intervalMinutes = 5) => {
  const { member } = useCurrentMember();
  const { authenticated } = useOurAuthenticated();
  const { pathname } = useLocation();

  const fetchCurrentMember = ourUserHooks.customHooks.useFetchCurrentMember();

  const adjustedMinutes = 8;

  const mils = (minutes: number) => minutes * 60 * 1000;
  // const date = () => ourNowToDisplayDateTime();
  const intervalMils = useRef(mils(intervalMinutes));
  const adjustedMils = useRef(mils(adjustedMinutes));
  const currentMils = useRef(mils(intervalMinutes));
  const intervalId = useRef(undefined as NodeJS.Timeout | undefined);
  const skipRefresh = useRef(false);

  const [resetRefresh, setResetRefresh] = useState(false);

  const adjusted = intervalId.current && currentMils.current !== intervalMils.current;

  const refresh = useCallback(async () => {
    try {
      if (!authenticated || skipRefresh.current) {
        // console.log(`Not authenticated. Skipping refresh at ${date()}.`);
        setResetRefresh(true);
        return;
      }
      // console.log(`Refreshing at ${date()}.`);
      const fetchResult = await fetchCurrentMember();

      unwrapResult(fetchResult as WrappedResult);

      if (adjusted) {
        // console.log(`Refreshed at ${date()}. Restoring refresh interval`);

        currentMils.current = intervalMils.current;
      }
    } catch (e) {
      if (e instanceof Error && e.message.includes('403')) {
        // This seems to happen when logging out with username/password. 2nd signOut succeeds
        // console.log('403 - authenticated =', authenticated);
        if (authenticated) await authSignOut();
      } else {
        // e.message === 'Network Error'?
        // console.log(
        //   `Refresh error at ${date()}: ${e.message}.${adjusted ? '' : ' Adjusting refresh interval'}`,
        // );

        // console.log(`Refresh error at ${date()}: ${e.message}.`);
        // console.log('Error:', e);

        if (!adjusted) currentMils.current = adjustedMils.current;
      }
    }
  }, [authenticated, fetchCurrentMember, adjusted]);

  // TODO: Determine whether we really need this first refresh
  // useEffect(() => {
  //   if (!intervalId.current) refresh().then();
  // }, [refresh]);

  useEffect(() => {
    if (resetRefresh) setResetRefresh(false);

    if (intervalId.current) clearInterval(intervalId.current);

    intervalId.current = setInterval(refresh, currentMils.current);

    return function cleanup() {
      clearInterval(intervalId.current);
    };
  }, [resetRefresh, refresh, member]);

  useEffect(() => {
    skipRefresh.current = pathname.startsWith(webPath.profile) || pathname.startsWith(webPath.profileWizard);
  }, [pathname]);

  return { member };
};

export default useOurCurrentMemberRefresh;
