// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { ourCurrencyCode, ourDefaultCurrencyCode } from '../../constants';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import {
  AddressColumns,
  DataEntityType,
  DefaultColumns,
  InitialEntity,
} from '../../types';

export interface PclCustomer extends AddressColumns, DefaultColumns {
  type: DataEntityType;
  pclCustomerId: string;
  memberId: string;
  defaultCurrency: string;
  active: boolean;
}

export const pclCustomerEntity = entityAttributes({
  name: entityName.pclCustomer,
  attributes: {
    category: entityCategory.person,
    columns: { active: true, address: true },
    nameField: undefined,
    test: { invalidData: () => ({ defaultCurrency: {} }), validData: () => ({ active: false }) },
  },
});

const { id, oneOf } = schema;
export const pclCustomerSchema = entitySchema<PclCustomer>(pclCustomerEntity, {
  memberId: id(true),
  defaultCurrency: oneOf(ourCurrencyCode, ourDefaultCurrencyCode),
});

export const pclCustomerDefaults: Partial<PclCustomer> = {
  memberId: '',
  defaultCurrency: ourDefaultCurrencyCode,
};

export interface PclCustomerRecord extends PclCustomer, RecordType {}

export type MaybePclCustomerRecord = MaybeNull<PclCustomerRecord>;
export type PclCustomerRecords = PclCustomerRecord[];

export const initialPclCustomer: InitialEntity<PclCustomer, PclCustomerRecord> = (overrides = {}) =>
  initialEntity<PclCustomer>(pclCustomerEntity, { ...pclCustomerDefaults, ...overrides }) as PclCustomerRecord;
