import { Key, ReactNode, SyntheticEvent } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ourDefaultTabWidth } from '../../constants';
import useOurPhone from '../../hooks/useOurPhone';

interface OurTabsTabsProps {
  tabs: (string | boolean | ReactNode)[][];
  tabValue?: string;
  onChange?: (event: SyntheticEvent, value: string) => void;
  tabWidth?: number;
  scrollButtons?: boolean;
}

const OurTabsTabs = ({ tabs, tabValue, onChange, tabWidth = ourDefaultTabWidth, scrollButtons }: OurTabsTabsProps) => {
  const { palette, spacing } = useTheme();
  const { phone } = useOurPhone();

  return (
    <Tabs
      value={tabValue}
      onChange={onChange}
      indicatorColor="secondary"
      textColor="primary"
      variant="scrollable"
      scrollButtons={scrollButtons || (phone ? true : 'auto')}
      {...(phone && { allowScrollButtonsMobile: true })}
      aria-label="scrollable-auto-tabs"
    >
      {tabs.map(([name = '']) => (
        <Tab
          key={name as Key}
          label={name}
          value={name}
          sx={{
            width: tabWidth,
            minWidth: tabWidth,
            textTransform: 'none',
            fontSize: '1rem',
            '&.Mui-selected': {
              color: 'white',
              bgcolor: palette.primary.main,
              borderRadius: spacing(1.5, 1.5, 0, 0),
            },
            '&:hover': {
              color: 'inherit',
              bgcolor: 'rgba(0, 105, 177, 0.25)',
              borderRadius: spacing(1.5, 1.5, 0, 0),
            },
          }}
        />
      ))}
    </Tabs>
  );
};

OurTabsTabs.whyDidYouRender = true;

export default OurTabsTabs;
