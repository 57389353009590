import { useEffect } from 'react';
import { LoggerMessage, useOurLoggerRemove, useOurSelectLoggerMessages } from '@petconsole/pure-fe-state';
import { useOurRollbarLog } from '@petconsole/pure-fe-utils';

let logged: string[] = [];

const useOurErrorLogger = () => {
  const messages = useOurSelectLoggerMessages() as LoggerMessage[];
  const remove = useOurLoggerRemove();
  const { log, rollbar } = useOurRollbarLog();

  const addLogged = (id: string) => {
    logged = [...logged, id];
  };

  const removeLogged = (id: string) => {
    logged = [...logged.filter((key) => id !== key)];
  };

  useEffect(() => {
    messages.forEach(({ key, message }: LoggerMessage) => {
      // do nothing if message is already being logged
      if (logged.includes(key)) return;

      // track messages that we're logging
      addLogged(key);

      log(message).then(() => {
        // remove this message from redux store and our local tracking
        remove(key);
        removeLogged(key);
      });
    });
  }, [messages, remove, log]);

  return { logger: log, rollbar };
};

export default useOurErrorLogger;
