import { useState } from 'react';
import { ApiDeleteEntityHook } from '@petconsole/pure-fe-state';
import { SetState } from '@petconsole/pure-shared';

interface UseOurDeleteEntityProps {
  entityText: string;
  id: string;
  setOptionsOpen: SetState<boolean>;
  deleteHook: ApiDeleteEntityHook;
}

const useOurDeleteEntity = ({ entityText, id, setOptionsOpen, deleteHook }: UseOurDeleteEntityProps) => {
  const { deleteEntity /*, ...deleteResults */ } = deleteHook();
  const onDelete = () => deleteEntity(id);

  const [deleting, setDeleting] = useState(false);

  const openDelete = () => {
    setOptionsOpen(false);
    setDeleting(true);
  };

  return {
    openDelete,
    deleteProps: { isOpen: deleting, setIsOpen: setDeleting, entityText, onDelete },
  };
};

export default useOurDeleteEntity;
