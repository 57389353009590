import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  ApiEntity,
  cartItemEntity,
  CartItemRecord,
  GetListOptions,
  GetListResults,
} from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = CartItemRecord;

const apiPath = '/cartItems';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi extends RecordType {
  getListByCart: (cartId: string, options?: GetListOptions) => Promise<GetListResults<ApiRecord>>;
}

export type CartItemApi = Api<ApiRecord> & CustomApi;

export const cartItemApi: CartItemApi = {
  ...common,
  getListByValue,
  getListByCart: (cartId, options) => getListByValue('cartId', cartId, options),
};

export const cartItemApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...cartItemEntity, api: cartItemApi };
