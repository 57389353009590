// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface PetTrainer extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  petTrainerId: string;
  name: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const petTrainerEntity = entityAttributes({
  name: entityName.petTrainer,
  attributes: {
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    site: anySite.petConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const petTrainerSchema = entitySchema<PetTrainer>(petTrainerEntity, {
  name: strim().required('Business name is required'),
});

export const petTrainerDefaults: Partial<PetTrainer> = {
  name: '',
};

export interface PetTrainerRecord extends PetTrainer, RecordType {}

export type MaybePetTrainerRecord = MaybeNull<PetTrainerRecord>;
export type PetTrainerRecords = PetTrainerRecord[];

export const initialPetTrainer: InitialEntity<PetTrainer, PetTrainerRecord> = (overrides = {}) =>
  initialEntity<PetTrainer>(petTrainerEntity, { ...petTrainerDefaults, ...overrides }) as PetTrainerRecord;
