import { Cache } from 'aws-amplify/utils';
import { CacheInstance } from '../types';

export let localCache: CacheInstance;

const createCacheInstance = (keyPrefix: string) => {
  const defaultConfig = Cache.configure();

  localCache = Cache.createInstance({ ...defaultConfig, keyPrefix });
};

export default createCacheInstance;