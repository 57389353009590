import { RecordType } from '@petconsole/pure-base';
import { vanSupplyApiEntity } from '@petconsole/pure-fe-api';
import { VanSupplyRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = VanSupplyRecord;

const apiEntity = vanSupplyApiEntity;
const { name: vanSupply, plural: vanSupplies } = apiEntity;
const vanSupplyAdmin = `${vanSupply}Admin`;

const Tab = { vanSupplyAdmin, vanSupply, vanSupplies } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  vanSupplyAdmin: { default: '' },
  vanSupply: { default: '' },
  vanSupplies: { default: 'Newest' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudSliver, sliver, citySearch, property, rating, tabHooks } = sliceHookTypes;

export interface OurVanSupplyHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  vanSupplyHooks: RequiredCrudSliverHooks;
  cityVanSupplyHooks: RequiredSliverHooks;
  newestVanSupplyHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourVanSupplyHooks: OurVanSupplyHooks = {
  entity: apiEntity,
  vanSupplyHooks: crudSliver(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityVanSupplyHooks: sliver(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestVanSupplyHooks: sliver(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearch(apiEntity, hooks),
  propertyHooks: property(apiEntity, hooks),
  ratingHooks: rating(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
