import { Typography, TypographyProps } from '@mui/material';
import { siteMemberText } from '../../constants';

interface OurSiteMemberTextProps extends TypographyProps {
  strong?: boolean;
}

const OurSiteMemberText = ({ strong, ...rest }: OurSiteMemberTextProps) => {
  if (!siteMemberText) return null;

  if (strong) {
    return (
      <Typography {...rest}>
        <strong>{siteMemberText}</strong>
      </Typography>
    );
  }

  return <Typography {...rest}>{siteMemberText}</Typography>;
};

OurSiteMemberText.whyDidYouRender = true;

export default OurSiteMemberText;
