import { useEffect, useState } from 'react';
import { SetState } from '@petconsole/pure-shared';

interface UseOurSingleCommentsProps {
  id?: string;
  openId?: string;
  setOpenId: SetState<string>;
}

const useOurSingleComments = ({ id, openId, setOpenId }: UseOurSingleCommentsProps) => {
  const [commentsOpen, setCommentsOpen] = useState(false);

  useEffect(() => {
    // Close comments if they are opened on another blog post
    if (commentsOpen && openId !== id) setCommentsOpen(false);
  }, [commentsOpen, openId, id]);

  const toggleComments = () => {
    // If we are opening the comments, signal others, so they can close their comments
    if (!commentsOpen) setOpenId(id as string);

    setCommentsOpen(!commentsOpen);
  };

  return { commentsOpen, setCommentsOpen, toggleComments };
};

export default useOurSingleComments;
