import { useCallback } from 'react';
import { CityLocation } from '@petconsole/pure-base';
import { getLocalItem, setLocalItem } from '@petconsole/pure-fe-amplify';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { ourDefaultLocationItemKey } from '../constants';

const useOurDefaultLocation = () => {
  const { memberId, member } = useCurrentMember();
  const { country, province, city } = member || {};

  // Member settings have priority, with guest (default) setting as fallback
  const currentKey = memberId ? `${memberId}:defaultLocation` : ourDefaultLocationItemKey;

  const setDefaultLocation = useCallback(
    (location: CityLocation) => {
      const expiration = new Date(2199, 1, 1);
      const value = JSON.stringify(location);
      const options = { expires: expiration.getTime() };

      setLocalItem(currentKey, value, options);

      // If we set the member key, set the default key to the same value
      if (currentKey !== ourDefaultLocationItemKey) setLocalItem(ourDefaultLocationItemKey, value, options);
    },
    [currentKey],
  );

  const getDefaultLocation = useCallback(async () => {
    const defaultLocation = await getLocalItem(currentKey);

    if (defaultLocation !== null) return JSON.parse(defaultLocation) as CityLocation;

    if (!city) return null;

    const location: CityLocation = { country, province, city };

    // Update local storage for next time
    setDefaultLocation(location);

    return location;
  }, [currentKey, setDefaultLocation, country, province, city]);

  return { getDefaultLocation, setDefaultLocation };
};

export default useOurDefaultLocation;
