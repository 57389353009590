import { ourCommentHooks } from '@petconsole/pure-fe-state';
import { CommentRecord, SetState } from '@petconsole/pure-shared';
import useDoWork from '../../misc/doWork/useDoWork';

export interface SaveCommentProps {
  threadId?: string;
  comment: string;
}

interface UseSaveCommentProps {
  commentOnId: string;
  commentOnType: string;
  onComment?: (id: string) => unknown;
  setBusy: SetState<boolean>;
}

const useSaveComment = ({ commentOnId, commentOnType, onComment, setBusy }: UseSaveCommentProps) => {
  const { doWork, workCatch } = useDoWork();

  const createComment = ourCommentHooks.commentHooks.useCreate();

  const saveComment = ({ threadId, comment }: SaveCommentProps) =>
    doWork({
      tryCallback: async () => {
        const created = await createComment({
          comment: { commentOnId, commentOnType, threadId, text: comment },
        }).unwrap();

        if (onComment) onComment(commentOnId);

        return created as CommentRecord;
      },
      catchCallback: (e) => workCatch(e, 'adding comment'),
      setBusy,
    }) as Promise<CommentRecord>;

  return { saveComment };
};

export default useSaveComment;
