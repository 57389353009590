import { Typography, TypographyProps } from '@mui/material';

export interface OurStrongTextProps {
  text: string;
  typeProps?: TypographyProps;
}

const OurStrongText = ({ text, typeProps }: OurStrongTextProps) => {
  return (
    <Typography {...typeProps}>
      <strong>{text}</strong>
    </Typography>
  );
};

OurStrongText.whyDidYouRender = true;

export default OurStrongText;
