import { RecordType } from '../../types';

const compareNameAndDate =
  (name: string, date: string) =>
  ({ [name]: nameA, [date]: dateA }: RecordType, { [name]: nameB, [date]: dateB }: RecordType) => {
    if (!nameA || !nameB) return 0;

    let result = String(nameA).toLocaleUpperCase().localeCompare(String(nameB).toLocaleUpperCase());

    if (result === 0) result = String(dateA).localeCompare(String(dateB));

    return result;
  };

export default compareNameAndDate;
