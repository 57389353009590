import OurAvatar, { OurAvatarProps } from '../avatar/OurAvatar';
import OurLink from './OurLink';

interface OurAvatarLinkProps extends OurAvatarProps {
  to: string;
  disabled?: boolean;
}

const OurAvatarLink = ({ to, ...rest }: OurAvatarLinkProps) => {
  if (rest.disabled) return <OurAvatar {...rest} />

  return (
    <OurLink to={to} >
      <OurAvatar {...rest} />
    </OurLink>
  );
};

OurAvatarLink.whyDidYouRender = true;

export default OurAvatarLink;
