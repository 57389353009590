import { PayloadAction } from '@reduxjs/toolkit';
import { RecordType } from '@petconsole/pure-base';
import { blogApiEntity } from '@petconsole/pure-fe-api';
import { BlogRecord, GetListResults } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import { ExtraReducers } from '../../types';

const { api, name: sliceName, proper } = blogApiEntity;
type ApiRecord = BlogRecord;
type ListReturns = GetListResults<ApiRecord>;
type Action = PayloadAction<ListReturns>;

export const fetchFirstLast = (fetchFirst: boolean) => async (): Promise<ListReturns> =>
  api.getListByUrlAndCreatedBetween({}, { limit: 1, scanForward: fetchFirst });

export const fetchFirstBlogThunk = appThunk(`${sliceName}/fetchFirst${proper}`, fetchFirstLast(true));

export const fetchLastBlogThunk = appThunk(`${sliceName}/fetchLast${proper}`, fetchFirstLast(false));

export const setFirstLast = (state: RecordType, action: Action, setFirst: boolean) => {
  const { items } = action.payload;

  if (items.length < 1) return;

  const { blogId, feedAt } = items[0];

  if (setFirst) state.blogFirstBlog = { blogId, feedAt };
  else state.blogLastBlog = { blogId, feedAt };
};

export const fetchFirstBlogReducers: ExtraReducers = () => ({
  [`${sliceName}/fetchFirst${proper}/fulfilled`]: (state: RecordType, action: Action) =>
    setFirstLast(state, action, true),
});

export const fetchLastBlogReducers: ExtraReducers = () => ({
  [`${sliceName}/fetchLast${proper}/fulfilled`]: (state: RecordType, action: Action) =>
    setFirstLast(state, action, false),
});
