import { shopItemApiEntity } from '@petconsole/pure-fe-api';
import { apiMethodType, GetByInputMethod, ShopItemRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, getOne, updateOne } from '../../../helpers';
import { MaybeNull } from '@petconsole/pure-base';

type ApiRecord = ShopItemRecord;

const shopItemEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = shopItemApiEntity;

  return {
    createShopItem: createOne(builder, api, tag),
    getShopItem: getOne(builder, api, tag),
    getShopItemByShopAndSku: builder.query<MaybeNull<ApiRecord>, { shopId: string; sku: string }>({
      query: ({ shopId, sku }) => ({
        type: apiMethodType.getByInput,
        method: api.getByShopAndSku as GetByInputMethod<ApiRecord>,
        input: { shopId, sku: sku },
      }),
      providesTags: (_result, input) => [tag, { type: tag, id: JSON.stringify(input) }],
    }),
    updateShopItem: updateOne(builder, api.update, tag),
  };
};

export default shopItemEndpoints;
