import { mailMessageApiEntity } from '@petconsole/pure-fe-api';
import { deleteEntityReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { Slice, ThunkApi } from '../../../types';
import { removeMailHeaderThunk } from '../mailHeader/removeMailHeader';

const { api, name: sliceName, proper } = mailMessageApiEntity;
const typePrefix = `${sliceName}/move${proper}ToTrash`;

export const moveMailMessageToTrashPayloadCreator = async (
  messageId: string,
  { dispatch }: ThunkApi,
) => api.trash(messageId).then(() => dispatch(removeMailHeaderThunk(messageId)));

export const moveMailMessageToTrashThunk = appThunk(
  typePrefix,
  moveMailMessageToTrashPayloadCreator,
);

export const moveMailMessageToTrashReducers = (slice: Slice) =>
  deleteEntityReducers(slice, moveMailMessageToTrashThunk);
