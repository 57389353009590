import { friendApiEntity } from '@petconsole/pure-fe-api';
import { FriendRecord } from '@petconsole/pure-shared';
import {
  DispatchThunkRecordFromStringHook,
  fetchParams,
  sliceHookTypes,
  useDispatchThunkRecordFromString,
  useOurSelectById,
} from '../../helpers';
import { BaseSliceOptions, OurEntityHooks, RecordType, RequiredSliverHooks, ThunkApi } from '../../types';
import BaseEntitySlice from '../base/BaseEntitySlice';
import {
  acceptFriendRequestReducers,
  acceptFriendRequestThunk,
  addFriendRequestReducers,
  addFriendRequestThunk,
  cancelFriendRequestReducers,
  cancelFriendRequestThunk,
  declineFriendRequestReducers,
  declineFriendRequestThunk,
} from './friendRequest';
import { selectByName } from './friendSelectors';
import {
  pauseFriendshipReducers,
  pauseFriendshipThunk,
  resumeFriendshipReducers,
  resumeFriendshipThunk,
} from './friendship';
import useOurDispatchEntity from '../../helpers/hooks/useOurDispatchEntity';

type ApiEntityRecord = FriendRecord;

const apiEntity = friendApiEntity;
const { api, name: sliceName, idName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  idName,
  selectId: ({ friendMemberName }) => friendMemberName as string,
  comparer: ({ friendMemberName: a }, { friendMemberName: b }) => a.toLowerCase().localeCompare(b.toLowerCase()),
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      fetchByValue: true,
      payloadCreator: async (name: string) => api.getByName(name),
      // Note: At this point, we don't expect to be provided a value for memberName
      fetchesByValue: true,
      payloadCreators: async (memberName = '', { getState }: ThunkApi) =>
        api.getListByMember(memberName, fetchParams(getState, sliceName, sliceName)),
      extraReducers: [
        addFriendRequestReducers,
        acceptFriendRequestReducers,
        cancelFriendRequestReducers,
        declineFriendRequestReducers,
        pauseFriendshipReducers,
        resumeFriendshipReducers,
      ],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { sliverHooks } = sliceHookTypes;

export interface OurFriendHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  friendHooks: RequiredSliverHooks;
  customHooks: {
    useFetchByName: () => (name: string) => Promise<unknown>;
    useSelectByName: (name: string) => unknown;
    // useAddFriendRequest: DispatchThunkRecordFromRecordHook;
    useAddFriendRequest: () => ReturnType<typeof useOurDispatchEntity>;
    useAcceptFriendRequest: DispatchThunkRecordFromStringHook;
    useCancelFriendRequest: DispatchThunkRecordFromStringHook;
    useDeclineFriendRequest: DispatchThunkRecordFromStringHook;
    usePauseFriendship: DispatchThunkRecordFromStringHook;
    useResumeFriendship: DispatchThunkRecordFromStringHook;
  };
}

export const ourFriendHooks: OurFriendHooks = {
  entity: apiEntity,
  friendHooks: sliverHooks(apiEntity, '', hooks) as RequiredSliverHooks,
  customHooks: {
    useFetchByName: hooks.useOurFetchFriend as OurFriendHooks['customHooks']['useFetchByName'],
    useSelectByName: (name: string) => useOurSelectById(selectByName, name),
    // useAddFriendRequest: () => useDispatchThunkRecordFromRecord(addFriendRequestThunk),
    useAddFriendRequest: () => useOurDispatchEntity(sliceName, addFriendRequestThunk),
    useAcceptFriendRequest: () => useDispatchThunkRecordFromString(acceptFriendRequestThunk),
    useCancelFriendRequest: () => useDispatchThunkRecordFromString(cancelFriendRequestThunk),
    useDeclineFriendRequest: () => useDispatchThunkRecordFromString(declineFriendRequestThunk),
    usePauseFriendship: () => useDispatchThunkRecordFromString(pauseFriendshipThunk),
    useResumeFriendship: () => useDispatchThunkRecordFromString(resumeFriendshipThunk),
  },
};

export default reducer;
