import { RecordType } from '@petconsole/pure-base';

const ourRtkErrorMessage = (error?: unknown): string => {
  if (!error) return '';

  if (typeof error !== 'object') return JSON.stringify(error);

  const { status, message = '', error: innerError = '', data } = error as RecordType;

  if (status) return message as string || 'Unknown error message';

  if (innerError) return innerError as string || 'Unknown inner error';

  if (data) return JSON.stringify(data);

  return 'Unknown error data';
};

export default ourRtkErrorMessage;
