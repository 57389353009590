import { PropsWithChildren, useMemo } from 'react';
import { newId, RecordType } from '@petconsole/pure-base';
import { UseFormReturn } from 'react-hook-form';

interface FormFragmentProps<T extends RecordType = RecordType> extends PropsWithChildren {
  form: UseFormReturn<T>;
  readOnly?: boolean;
}

const FormFragment = <T extends RecordType = RecordType>({ form, readOnly, children }: FormFragmentProps<T>) => {
  const key = useMemo(() => newId(), []);

  if (!children) return null;
  if (!Array.isArray(children)) return children;

  return (
    <>
      {children.map((Child, index) => {
        if (typeof Child !== 'object') return Child;

        return { ...Child, props: { ...Child.props, key: `${key}-${index}`, form, readOnly: Child.props.readOnly ?? readOnly }};
      })}
    </>
  );
};

FormFragment.whyDidYouRender = true;

export default FormFragment;
