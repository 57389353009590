import { fetchAuthSession } from 'aws-amplify/auth';

export const attemptFetchAuthSession = () => fetchAuthSession();

const authFetchAuthSession = async () => {
  try {
    const result = await attemptFetchAuthSession();

    return { success: true, session: result };
  } catch (error) {
    if (!(error instanceof Error)) {
      // TODO: Log to rollbar
      return { success: false, reason: 'Unknown error', error };
    }

    return { success: false, reason: error.message };
  }
};

export default authFetchAuthSession;
