// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface PetFood extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  petFoodId: string;
  name: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const petFoodEntity = entityAttributes({
  name: entityName.petFood,
  attributes: {
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    site: anySite.petConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const petFoodSchema = entitySchema<PetFood>(petFoodEntity, {
  name: strim().required('Business name is required'),
});

export const petFoodDefaults: Partial<PetFood> = {
  name: '',
};

export interface PetFoodRecord extends PetFood, RecordType {}

export type MaybePetFoodRecord = MaybeNull<PetFoodRecord>;
export type PetFoodRecords = PetFoodRecord[];

export const initialPetFood: InitialEntity<PetFood, PetFoodRecord> = (overrides = {}) =>
  initialEntity<PetFood>(petFoodEntity, { ...petFoodDefaults, ...overrides }) as PetFoodRecord;
