import { RecordType } from '@petconsole/pure-base';
import { ourSaveStatus } from '../../constants';
import { AnyAsyncThunk, Slice, ThunkFulfilledMethod } from '../../types';
import thunkPendingReducer from './thunkPendingReducer';
import thunkRejectedReducer from './thunkRejectedReducer';

const deleteEntityReducers =  <T extends RecordType = RecordType>(slice: Slice<T>, { pending, fulfilled, rejected }: AnyAsyncThunk) => {
  const { name: sliceName, idName } = slice;
  const statusProperty = 'writeStatus';

  const fulfilledReducer: ThunkFulfilledMethod = (state, { payload }) => {
    state[sliceName][statusProperty] = ourSaveStatus.succeeded;

    if (!payload) return;

    slice.slivers.forEach((sliverName) => {
      if (sliverName !== sliceName && !state[sliverName].ids.includes((payload as RecordType)[idName])) return;

      const { adapter } = slice.sliver[sliverName];

      adapter.removeOne(state[sliverName], (payload as RecordType)[idName] as string);
    });
  };

  return {
    [String(pending)]: thunkPendingReducer(sliceName, statusProperty),
    [String(fulfilled)]: fulfilledReducer,
    [String(rejected)]: thunkRejectedReducer(sliceName, statusProperty),
  };
};

export default deleteEntityReducers;
