import { useMemo, useRef } from 'react';
import throttle from 'lodash/throttle';
import { MaybeNull, RecordType } from '@petconsole/pure-base';

// NOTE: function loadScript() was moved to index.html

type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutocompleteService = google.maps.places.AutocompleteService;
type AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;
type PlaceResult = google.maps.places.PlaceResult;
type PlacesService = google.maps.places.PlacesService;

const useGooglePlaces = () => {
  const autocompleteService = useRef<MaybeNull<AutocompleteService>>(null);
  const placesService = useRef<MaybeNull<PlacesService>>(null);
  const sessionToken = useRef<AutocompleteSessionToken>('');

  const requestedDetailFields = [
    'address_component',
    'formatted_address',
    'geometry',
    'place_id',
    'type',
    'utc_offset_minutes',
    'name',
  ];

  if (window.google) {
    if (!autocompleteService.current) autocompleteService.current = new window.google.maps.places.AutocompleteService();

    if (!placesService.current)
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement('div'),
        // document.getElementById('poweredByGoogle'),
      );
  }

  const getPlacePredictions = useMemo(
    () =>
      throttle((request, callback) => {
        if (!sessionToken.current) sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();

        if (request.input)
          (autocompleteService.current as AutocompleteService).getPlacePredictions(
            {...request, sessionToken: sessionToken.current},
            callback,
          ).then();
      }, 200),
    [sessionToken],
  );

  const handleDetails = (place: MaybeNull<PlaceResult> , description: string) => {
    const { name, utc_offset_minutes, geometry, formatted_address, address_components } = place || {};
    const ignoreTypes = ['point_of_interest'];
    const location: RecordType = {
      googlePlaceId: place ? place.place_id : undefined,
      latitude: (geometry && geometry.location?.lat()) || null,
      longitude: (geometry && geometry.location?.lng()) || null,
      // description: (value && value.description) || formatted_address,
      name, // 1052 Delta St / Twin Rivers Animal Hospital
      description,
      types: place ? place.types?.filter((type) => !ignoreTypes.includes(type)) : undefined, // types: ["premise"]
      // eslint-disable-next-line camelcase
      utcOffsetMinutes: utc_offset_minutes, // -420
      // eslint-disable-next-line camelcase
      formattedAddress: formatted_address, // "1052 Delta St, Kamloops, BC V2B 2T6, Canada"
    };

    address_components?.forEach((component) => {
      const { types, long_name, short_name } = component;
      const names = { longName: long_name, shortName: short_name };

      types.forEach((type) => {
        switch (type) {
          case 'subpremise': // Unit C
            location.unit = names;
            break;
          case 'street_number': // 1420
            location.streetNumber = names;
            break;
          case 'route': // Hugh Allan Drive / Hugh Allan Dr
            location.street = names;
            break;
          case 'locality': // Kamloops
            location.city = names;
            break;
          case 'administrative_area_level_1': // British Columbia / BC
            location.province = names;
            break;
          case 'country': // Canada / CA
            location.country = names;
            break;
          case 'postal_code': // V1S 1L8
            location.postal = names;
            break;
          case 'neighborhood': // Aberdeen
            location.neighborhood = names;
            break;
          case 'administrative_area_level_2': // Thompson-Nicola
            location.region = names;
            break;
          default:
        }
      });
    });

    // When we have all that we need, clear the session token:
    sessionToken.current = '';

    return location;
  };

  const getPlaceDetails = ({ place_id: placeId, description }: AutocompletePrediction): Promise<RecordType> => {
    return new Promise((resolve, reject) => {
      (placesService.current as PlacesService).getDetails(
        {
          placeId,
          fields: requestedDetailFields,
          sessionToken: sessionToken.current,
        },
        (place, status) => {
          // The PlacesServiceStatus response object contains the status of the request
          // INVALID_REQUEST: This request was invalid.
          // OK: The response contains a valid result.
          // OVER_QUERY_LIMIT: The webpage has gone over its request quota.
          // REQUEST_DENIED: The webpage is not allowed to use the PlacesService.
          // UNKNOWN_ERROR: The PlacesService request could not be processed due to a server error.
          // The request may succeed if you try again.
          // ZERO_RESULTS: No result was found for this request.
          if (status === window.google.maps.places.PlacesServiceStatus.OK)
            return resolve(handleDetails(place, description));

          // eslint-disable-next-line prefer-promise-reject-errors
          return reject(`Unexpected response from getDetails: ${status}`);
        },
      );
    });
  };

  return {
    autocompleteService: autocompleteService.current,
    placesService: placesService.current,
    getPlacePredictions,
    getPlaceDetails,
  };
};

export default useGooglePlaces;
