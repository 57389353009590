import { reactAppHtmlSiteName } from '@petconsole/pure-shared';
import useOurWidth from '../../../hooks/useOurWidth';
import OurJoinButton from '../../buttons/OurJoinButton';
import OurLoginButton from '../../buttons/OurLoginButton';
import OurAppMenuToolBar from '../appMenu/OurAppMenuToolBar';
import OurAppNameBox from './OurAppNameBox';

const GuestMenuBar = () => {
  const width = useOurWidth();

  return (
    <OurAppMenuToolBar>
      {reactAppHtmlSiteName !== 'Admin' && (
        <OurJoinButton size={width === 'xs' ? 'small' : 'large'} fullWidth={false} />
      )}
      <OurAppNameBox />
      <OurLoginButton size={width === 'xs' ? 'small' : 'large'} fullWidth={false} />
    </OurAppMenuToolBar>
  );
};

GuestMenuBar.whyDidYouRender = true;

export default GuestMenuBar;
