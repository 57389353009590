import { Fragment } from 'react';
import { newId } from '@petconsole/pure-base';

interface OurTextLineProps {
  text?: string;
}

const OurTextLines = ({ text }: OurTextLineProps) => {
  if (!text) return '';
  if (!text.match(/\n/)) return text;

  return text.split(/\n/).map((line, index) => {
    return (
      <Fragment key={newId()}>
        {index !== 0 && <br />}
        {line}
      </Fragment>
    );
  });
};

OurTextLines.whyDidYouRender = true;

export default OurTextLines;
