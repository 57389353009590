import { MaybeRecordType } from '@petconsole/pure-base';
import { ourValidId } from '@petconsole/pure-shared';
import { FetchByUrlOrIdPayloadCreator } from '../../types';

const payloadCreatorByUrlOrIdWithFetch: FetchByUrlOrIdPayloadCreator =
  (idName, api, nextAction) =>
  async (urlOrId, { dispatch }) => {
    const entity = ourValidId(urlOrId)
      ? ((await api.get(urlOrId)) as MaybeRecordType)
      : ((await api.getByUrlName(urlOrId)) as MaybeRecordType);

    if (entity && nextAction) dispatch(nextAction({ [idName]: entity[idName] }));

    return entity;
  } ;

export default payloadCreatorByUrlOrIdWithFetch;
