import { RecordType } from '@petconsole/pure-base';
import { OurEntityHooks, RequiredSliverHooks, SliverHooks } from '@petconsole/pure-fe-state';

interface UseOurEntityTabFetchResetProps<T extends RecordType = RecordType> {
  hooks: OurEntityHooks<T>;
  tabName: string;
  prefix?: string;
  required?: boolean;
  firstFetch?: boolean;
  fetchLocation?: boolean;
}

const useOurEntityTabFetchReset = <T extends RecordType = RecordType>({ hooks, tabName, prefix, required = false, firstFetch, fetchLocation }: UseOurEntityTabFetchResetProps<T>) => {
  const { proper } = hooks.entity;
  const tabHookPrefix = tabName === 'By City' || tabName === 'Map' ? 'city' : tabName.toLowerCase();
  const hookPrefix = prefix || tabHookPrefix;
  const optionalHooks = hooks[`${hookPrefix}${proper}Hooks`] as SliverHooks;

  if (!optionalHooks) {
    if (required) throw new Error(`Missing hooks for ${tabName} tab`);

    return;
  }

  const requiredHooks = optionalHooks as RequiredSliverHooks;

  const reset = requiredHooks.useReset();
  const fetch = requiredHooks.useFetches();

  return { [tabName]: { reset, fetch, firstFetch, fetchLocation } };
};

export default useOurEntityTabFetchReset;
