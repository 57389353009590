import { RecordType } from '@petconsole/pure-base';

const compareCityStat =
  (sortProperty: string) =>
  (
    { [sortProperty]: sortPropertyA, country: countryA, province: provinceA, city: cityA }: RecordType,
    { [sortProperty]: sortPropertyB, country: countryB, province: provinceB, city: cityB }: RecordType,
  ) => {
    // Sort order: sort property desc, CA, US, country, province, city
    if (typeof sortPropertyA === 'number' && typeof sortPropertyB === 'number') {
      if (sortPropertyA > sortPropertyB) return -1;
      if (sortPropertyA < sortPropertyB) return 1;
    } else if (sortPropertyA !== sortPropertyB) return String(sortPropertyB).localeCompare(String(sortPropertyA));

    if (countryA === 'CA' && countryA !== countryB) return -1;
    if (countryB === 'CA' && countryA !== countryB) return 1;

    if (countryA === 'US' && countryA !== countryB) return -1;
    if (countryB === 'US' && countryA !== countryB) return 1;

    if (countryA !== countryB) return String(countryA).localeCompare(String(countryB));
    if (provinceA !== provinceB) return String(provinceA).localeCompare(String(provinceB));

    return String(cityA).toLocaleUpperCase().localeCompare(String(cityB).toLocaleUpperCase());
  };

export default compareCityStat;
