import { ourInsertIntoArray } from '@petconsole/pure-fe-utils';
import { MemberAvatarMenuItem, MemberAvatarMenuItems } from '../../../types';
import useDefaultAppMemberMenuItems from './useDefaultAppMemberMenuItems';

interface UseOurAppMemberMenuItemsProps {
  appItems?: {
    menuItem: MemberAvatarMenuItem;
    condition: (item: MemberAvatarMenuItem) => boolean;
    insertBefore: boolean;
  }[];
}

const useOurAppMemberMenuItems = ({ appItems = [] }: UseOurAppMemberMenuItemsProps = {}) => {
  let menuItems = useDefaultAppMemberMenuItems();

  appItems.forEach(({ menuItem, condition, insertBefore }) => {
    menuItems = ourInsertIntoArray(
      menuItems,
      menuItem,
      condition as (item: unknown) => boolean,
      insertBefore,
    ) as MemberAvatarMenuItems;
  });
  return menuItems;
};

export default useOurAppMemberMenuItems;
