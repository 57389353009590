import { RecordType, properCase } from '@petconsole/pure-base';
import setPropertyReducer from './setPropertyReducer';

const setPropertyReducers = (properties: string[]) => {
  const reducers: RecordType = {};

  properties.forEach((property) => {
    reducers[`set${properCase(property)}`] = setPropertyReducer(property);
  });

  return reducers;
};

export default setPropertyReducers;
