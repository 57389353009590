import { pclServiceApiEntity } from '@petconsole/pure-fe-api';
import { GetListResults } from '@petconsole/pure-shared';
import { fetchReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick } from '../../../types';

const { api, name: sliceName, proper } = pclServiceApiEntity;

const createPclTiersByPclServicePayloadCreator = async (pclServiceId: string) =>
  api
    .createPclTiers(pclServiceId)
    .then((pclTiers) => ({ items: pclTiers, nextKey: null } as GetListResults));

export const createPclTiersByPclServiceThunk = appThunk(
  `${sliceName}/create${proper}sByPclService`,
  createPclTiersByPclServicePayloadCreator,
);

export const createPclTiersByPclServiceReducers = (adapter: EntityAdapterWithPick) =>
  fetchReducers(createPclTiersByPclServiceThunk, sliceName, adapter);
