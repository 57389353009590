import { RecordType } from '@petconsole/pure-base';
import { NextAction, Slice } from '../../types';
import { createEntityThunk, deleteEntityThunk, fetchEntityByUrlOrIdThunk, updateEntityThunk } from '../thunks';

const sliceEntityThunks = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  const { idName, name: sliceName, proper, slivers, thunk, flag } = slice;
  const { canDelete, hasUrlName } = flag;
  const thunks: RecordType = {};

  const api = slice.api.entity;
  const fetch = thunk.fetchRating || thunk.fetchReaction;
  const callbacks: (() => unknown)[] = [];

  slivers.forEach((sliverName) => {
    if (sliverName !== sliceName) callbacks.push(slice.sliver[sliverName].initialState);
  });

  thunks.fetchEntity = slice.sliver[sliceName].fetchOneCreator;
  thunks.createEntity = createEntityThunk(sliceName, proper, idName, api, fetch as NextAction | undefined, callbacks);
  thunks.updateEntity = updateEntityThunk(sliceName, proper, idName, api, fetch as NextAction | undefined);

  if (hasUrlName)
    thunks.fetchEntityByUrlOrId = fetchEntityByUrlOrIdThunk({
      sliceName,
      entityType: proper,
      idName,
      api,
      fetch: fetch as NextAction | undefined,
    });

  if (canDelete) thunks.deleteEntity = deleteEntityThunk(sliceName, proper, idName, api);

  return thunks;
};

export default sliceEntityThunks;
