import { ComponentType, ElementType, MutableRefObject, PropsWithChildren, ReactElement, useMemo } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Box, Card, Grid } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import { RecordType } from '@petconsole/pure-base';
import { EntityAttributes, ExtendedFormik, Images, ReturnsVoid, SetState } from '@petconsole/pure-shared';
import OurBusyBackdrop from '../../components/misc/OurBusyBackdrop';
import { HandleSubmit } from '../../types';

interface EntityMaintenanceContentProps extends PropsWithChildren {
  entity: EntityAttributes;
  formik: ExtendedFormik;
  padding?: number;
  initializeState: ReturnsVoid;
  addressInput: string;
  setAddressInput: SetState<string>;
  avatarEditor: MutableRefObject<AvatarEditor | undefined>,
  avatar: string;
  setAvatar: SetState<string>;
  setAvatarChanged: SetState<boolean>;
  images: Images;
  setImages: SetState<Images>;
  imageZone: ReactElement;
  owned?: boolean;
  isOwner?: boolean;
  isEditing?: boolean;
  readOnly?: boolean;
  directions?: boolean;
  Headings?: ComponentType;
  Main: ElementType;
  mainProps?: RecordType;
  EntityErrorMessages: ComponentType;
  EntityEditMessages: ComponentType;
  onSubmit: HandleSubmit;
  busy?: boolean;
  setBusy: SetState<boolean>;
  setAddIsOpen: SetState<boolean>;
  breakpoints?: RegularBreakpoints;
}

const EntityMaintenanceContent = ({
  entity,
  formik,
  padding = 1,
  initializeState,
  addressInput,
  setAddressInput,
  avatarEditor,
  avatar,
  setAvatar,
  setAvatarChanged,
  images,
  setImages,
  imageZone,
  owned,
  isOwner,
  isEditing,
  readOnly,
  directions,
  Headings,
  Main,
  mainProps,
  EntityErrorMessages,
  EntityEditMessages,
  onSubmit,
  busy,
  setBusy,
  setAddIsOpen,
  breakpoints,
  children,
}: EntityMaintenanceContentProps) => {
  const formSx = useMemo(() => ({ display: 'flex', flexDirection: 'column', width: '100%' }), []);

  return (
    <Grid item id="maintenance-content" width="100%" {...(breakpoints && breakpoints)}>
      <Card raised id="maintenance-content-card">
        <Grid container padding={padding}>
          <Box component="form" noValidate onSubmit={onSubmit} sx={formSx}>
            {Headings ? <Headings /> : null}
            <Main
              entity={entity}
              formik={formik as ExtendedFormik}
              initializeState={initializeState}
              addressInput={addressInput}
              setAddressInput={setAddressInput}
              avatarEditor={avatarEditor}
              avatar={avatar}
              setAvatar={setAvatar}
              setAvatarChanged={setAvatarChanged}
              images={images}
              setImages={setImages}
              imageZone={imageZone}
              isOwned={owned}
              isOwner={isOwner}
              isEditing={isEditing}
              readOnly={readOnly}
              directions={directions}
              setBusy={setBusy}
              setAddIsOpen={setAddIsOpen}
              { ...mainProps }
            />
            <EntityErrorMessages />
            <EntityEditMessages />
            {children}
          </Box>
        </Grid>
      </Card>
      <OurBusyBackdrop busy={!!busy} />
    </Grid>
  );
};

EntityMaintenanceContent.whyDidYouRender = true;

export default EntityMaintenanceContent;
