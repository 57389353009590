import { ReactNode } from 'react';
import { TabPanel } from '@mui/lab';
import { strip } from '@petconsole/pure-base';
import OutlinedGridContent from '../outlinedGrid/OutlinedGridContent';

interface OurTabsPanelProps {
  name: string;
  outline?: boolean;
  label?: boolean | string;
  children: ReactNode;
}

const OurTabsPanel = ({ name, outline = true, label = '', children }: OurTabsPanelProps) => {
  return (
    <TabPanel value={name} sx={{ width: '100%', p: 0 }}>
      {outline && (
        <OutlinedGridContent
          id={`${strip(name)}-outline`}
          label={typeof label === 'boolean' ? (label && name) || '' : label || name}
          sx={{ pt: 0 }}
        >
          {children}
        </OutlinedGridContent>
      )}
      {!outline && children}
    </TabPanel>
  );
};

OurTabsPanel.whyDidYouRender = true;

export default OurTabsPanel;
