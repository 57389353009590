import { MaybeNull } from '@petconsole/pure-base';
import { ReturnsVoid } from '@petconsole/pure-shared';
import OurToolbar from '../components/misc/OurToolbar';
import { FormikSubmit } from '../types';
import { ReactElement } from 'react';

interface OurListEntityToolbarProps {
  saveButton?: ReactElement;
  editing: boolean;
  canEdit: boolean;
  onAdd?: ReturnsVoid;
  onEdit: ReturnsVoid;
  onCancel: ReturnsVoid;
  onPrev?: ReturnsVoid;
  onNext?: ReturnsVoid;
  prevId: MaybeNull<string>;
  nextId: MaybeNull<string>;
  guest: boolean;
  handleSubmit: FormikSubmit;
}

const OurListEntityToolbar = ({
  saveButton,
  editing,
  canEdit,
  onAdd,
  onEdit,
  onCancel,
  onPrev,
  onNext,
  prevId,
  nextId,
  guest,
  handleSubmit,
}: OurListEntityToolbarProps) => {
  return (
    <OurToolbar
      {...{ canEdit, onAdd, onEdit, onCancel, onPrev, onNext, saveButton }}
      prevDisabled={onPrev && !prevId}
      nextDisabled={onNext && !nextId}
      addDisabled={guest}
      editing={editing}
      editDisabled={guest}
      onSave={handleSubmit}
    />
  );
};

OurListEntityToolbar.whyDidYouRender = true;

export default OurListEntityToolbar;
