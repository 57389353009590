import { YouTube } from '@mui/icons-material';
import OurExternalIcon from '../icons/OurExternalIcon';

interface OurYouTubeIconProps {
  href?: string;
  account?: string;
}

const OurYouTubeIcon = ({ href, account = 'petconsole' }: OurYouTubeIconProps) => (
  <OurExternalIcon icon={<YouTube />} href={href || `https://www.youtube.com/channel/${account}`} aria="You Tube" />
);

OurYouTubeIcon.whyDidYouRender = true;

export default OurYouTubeIcon;
