import { PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { MaybeBreakpoints } from '../types';

interface MarginGridItemProps extends PropsWithChildren {
  margin?: MaybeBreakpoints;
}

const MarginGridItem = ({ margin, children }: MarginGridItemProps) => {
  if (!margin) return null;

  return (
    <Grid item {...margin} id="margin-grid-item" display="flex" width="100%" >
      {children}
    </Grid>
  );
};

MarginGridItem.whyDidYouRender = true;

export default MarginGridItem;
