import { RecordType } from '@petconsole/pure-base';
import { ourFetchStatus } from '../../constants';
import { AnyAsyncThunk, Slice, ThunkFulfilledMethod, ThunkPayloadAction } from '../../types';
import thunkPendingReducer from './thunkPendingReducer';
import thunkRejectedReducer from './thunkRejectedReducer';

type FetchEntityReducers = <T extends RecordType = RecordType>(
  slice: Slice<T>,
  { pending, fulfilled, rejected }: AnyAsyncThunk,
) => RecordType;

const fetchEntityReducers: FetchEntityReducers = (slice, { pending, fulfilled, rejected }) => {
  const { name: sliceName } = slice;

  const fulfilledReducer: ThunkFulfilledMethod = (state, action) => {
    const { payload } = action;

    state[sliceName].nextKey = null;
    state[sliceName].readStatus = ourFetchStatus.succeeded;

    if (payload) {
      const { adapter } = slice.sliver[sliceName];

      adapter.upsertOne(state[sliceName], payload);
    }

    if (slice.option.afterFetch) slice.option.afterFetch(state, action as ThunkPayloadAction<RecordType>);
  };

  return {
    [String(pending)]: thunkPendingReducer(sliceName),
    [String(fulfilled)]: fulfilledReducer,
    [String(rejected)]: thunkRejectedReducer(sliceName),
  };
};

export default fetchEntityReducers;
