import { ElementType, MouseEventHandler } from 'react';
import { CardHeader, Grid } from '@mui/material';
import { SetState } from '@petconsole/pure-shared';
import OurExpectedAvatar from '../../avatar/OurExpectedAvatar';
import OurEditOrOptionsButton from '../../buttons/OurEditOrOptionsButton';
import OurLikeCountButton from '../../buttons/OurLikeCountButton';

interface EntityCardHeaderProps {
  preview?: boolean;
  isVisible: boolean;
  avatarSrc: string;
  onAvatar?: MouseEventHandler;
  onEdit?: MouseEventHandler;
  entityTitle: string;
  Title: ElementType;
  subheader?: string;
  owner: string;
  hasLikes: boolean;
  likeCount?: number;
  liked: boolean;
  onLike?: MouseEventHandler;
  optionsOpen: boolean;
  setOptionsOpen: SetState<boolean>;
}

const EntityCardHeader = ({
  preview,
  isVisible,
  avatarSrc,
  onAvatar,
  onEdit,
  entityTitle,
  Title,
  subheader,
  owner,
  hasLikes,
  likeCount,
  liked,
  onLike,
  optionsOpen,
  setOptionsOpen,
}: EntityCardHeaderProps) => {
  return (
    <CardHeader
      sx={{ p: 1, pb: 0, alignItems: 'start' }}
      avatar={
        isVisible && avatarSrc ? (
          <OurExpectedAvatar ariaType="entity avatar image" src={avatarSrc} text={entityTitle} onClick={onAvatar} />
        ) : null
      }
      title={<Title />}
      subheader={subheader}
      action={
        <Grid container width="100%">
          {hasLikes && (
            <Grid item xs={6}>
              <OurLikeCountButton likes={likeCount} liked={liked} onClick={preview ? undefined : onLike} />
            </Grid>
          )}
          <Grid item xs={hasLikes ? 6 : 12}>
            <OurEditOrOptionsButton
              owner={preview ? '' : owner}
              onEdit={onEdit}
              isOpen={optionsOpen}
              setIsOpen={setOptionsOpen}
              disabled={preview}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

EntityCardHeader.whyDidYouRender = true;

export default EntityCardHeader;
