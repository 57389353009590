import { useCallback } from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import { SetState } from '@petconsole/pure-shared';
import OurButton from '../buttons/OurButton';
import OurDraggableDialog, { OurDraggableDialogProps } from './OurDraggableDialog';

interface OurOkDialogProps extends Omit<OurDraggableDialogProps, 'onClose'> {
  isOpen: boolean;
  setOpen: SetState<boolean>;
  okLabel?: string;
}

const OurOkDialog = ({ isOpen, setOpen, title = '', okLabel = 'Ok', children, ...rest }: OurOkDialogProps) => {
  const onOk = useCallback(() => setOpen(false), [setOpen]);

  return (
    <OurDraggableDialog title={title} isOpen={isOpen} onClose={onOk} {...rest}>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <OurButton label={okLabel} onClick={onOk} />
      </DialogActions>
    </OurDraggableDialog>
  );
};

OurOkDialog.whyDidYouRender = true;

export default OurOkDialog;
