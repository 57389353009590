// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import { anySite } from '../../../constants';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity, LayoutGrid, MaybeNull, ReactionColumns } from '../../../types';

export interface Blog extends CrudColumns, ReactionColumns {
  type: DataEntityType;
  blogId: string;
  defId: string;
  feedAt: string;
  title: string;
  layout: LayoutGrid;
}

export const blogEntity = entityAttributes({
  name: entityName.blog,
  attributes: {
    category: entityCategory.item,
    columns: { images: false, reaction: true },
    nameField: 'title',
    loadLimit: 5,
    restrictGet: false,
    site: anySite.anySite,
  },
});

const { array, date, id, strim } = schema;
export const blogSchema = entitySchema<Blog>(blogEntity, {
  defId: id(true),
  feedAt: date(),
  title: strim().required(),
  layout: array(),
});

export const blogDefaults: Partial<Blog> = {
  defId: '',
  feedAt: '',
  title: '',
  layout: [[[]]],
};

export interface BlogRecord extends Blog, RecordType {}

export type MaybeBlogRecord = MaybeNull<BlogRecord>;
export type BlogRecords = BlogRecord[];

export const initialBlog: InitialEntity<Blog, BlogRecord> = (overrides = {}) =>
  initialEntity<Blog>(blogEntity, { ...blogDefaults, ...overrides }) as BlogRecord;
