import React, { useEffect, useRef } from 'react';
import { RecordType } from '@petconsole/pure-base';

interface UseOurEntityFirstFetchProps {
  tabProps?: React.MutableRefObject<RecordType>;
  tabValue?: string;
  cityTab?: string;
  fetch?: () => Promise<unknown>;
}

const useOurEntityFirstFetch = ({ tabProps, tabValue, cityTab, fetch }: UseOurEntityFirstFetchProps) => {
  const fetchedFirst = useRef(false);

  useEffect(() => {
    if (tabProps || fetchedFirst.current || !fetch) return;

    fetch().then();

    fetchedFirst.current = true;
  }, [tabProps, fetch]);

  useEffect(() => {
    if (!tabProps) return;

    const { fetch: fetchTab, firstFetch: firstFetchTab = true } = (tabProps.current[tabValue as string] ||
      {}) as RecordType;

    if (tabValue === cityTab || !fetchTab || !firstFetchTab) return;

    (tabProps.current[tabValue as string] as RecordType).firstFetch = false;

    (fetchTab as () => void)();
  }, [tabValue, cityTab, tabProps]);
};

useOurEntityFirstFetch.whyDidYouRender = true;

export default useOurEntityFirstFetch;
