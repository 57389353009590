import { orderApiEntity } from '@petconsole/pure-fe-api';
import { OrderRecord } from '@petconsole/pure-shared';
import { fetchReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick, ExtraReducers } from '../../../types';

const { api, name: sliceName, proper } = orderApiEntity;
const myShopName = `myShop${proper}`;

export const fetchNextMyShopOrderThunk = appThunk(
  `${sliceName}/fetchNextMyShop${proper}`,
  async ({ shopId, orderId }: OrderRecord) => api.getNextByShopAndOrder(shopId, orderId),
);

export const fetchPrevMyShopOrderThunk = appThunk(
  `${sliceName}/fetchPrevMyShop${proper}`,
  async ({ shopId, orderId }: OrderRecord) => api.getPrevByShopAndOrder(shopId, orderId),
);

export const fetchNextMyShopOrderReducers: ExtraReducers = (adapter: EntityAdapterWithPick) =>
  fetchReducers(fetchNextMyShopOrderThunk, myShopName, adapter);

export const fetchPrevMyShopOrderReducers: ExtraReducers = (adapter: EntityAdapterWithPick) =>
  fetchReducers(fetchPrevMyShopOrderThunk, myShopName, adapter);
