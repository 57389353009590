import { mailHeaderApiEntity } from '@petconsole/pure-fe-api';
import { entityName, mailFolderType } from '@petconsole/pure-shared';
import { fetchParams } from '../../../helpers';
import { ThunkApi } from '../../../types';

const mailFolderName = entityName.mailFolder;
const { api, name: mailHeaderName } = mailHeaderApiEntity;

export const fetchMailHeadersPayloadCreator = async (mailFolderId: string, { getState }: ThunkApi) => {
  const mailFolder = getState()[mailFolderName][mailFolderName].entities[mailFolderId];

  if (!mailFolder) return;

  const params = fetchParams(getState, mailHeaderName, mailHeaderName);

  return mailFolder?.mailFolderType === mailFolderType.Sent
    ? api.getListBySenderFolder(mailFolderId, params)
    : api.getListByRecipientFolder(mailFolderId, params);
};
