import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { MaybeString } from '@petconsole/pure-base';
import getFieldProps from './helpers/getFieldProps';
import { GetFieldProps } from './helpers/getFieldProps';

interface RadioOptions extends Omit<FormControlLabelProps, 'control'> {
  control?: FormControlLabelProps['control'];
}

interface OurRadioGroupProps extends GetFieldProps {
  radioGroupOptions?: RadioGroupProps;
  radioOptions: RadioOptions[];
  label?: MaybeString;
}

const OurRadioGroup = ({ radioGroupOptions = {}, radioOptions, ...rest }: OurRadioGroupProps) => {
  const { name, label, value, onChange } = getFieldProps(rest);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{rest.label == null ? label : rest.label}</FormLabel>
      <RadioGroup aria-label={name} name={name} value={value} onChange={onChange} {...radioGroupOptions}>
        {radioOptions.map(({ label: optionLabel, value: optionValue, control, ...option }) => (
          <FormControlLabel
            key={String(optionValue)}
            value={optionValue}
            control={control || <Radio color="primary" />}
            label={optionLabel}
            {...option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

OurRadioGroup.whyDidYouRender = true;

export default OurRadioGroup;
