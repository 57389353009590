import { Avatar, Box, Typography } from '@mui/material';

interface OurAvatarViewProps {
  image?: string;
  text?: string;
  width?: number;
  height?: number;
}

const OurAvatarView = ({ image, text = '', width = 10, height = 10 }: OurAvatarViewProps) => {
  if (!image && !text) return <div />;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Avatar
        src={image}
        sx={{
          width: (theme) => theme.spacing(width),
          height: (theme) => theme.spacing(height),
          m: 1,
        }}
      >
        {text && <Typography variant="h4">{text}</Typography>}
      </Avatar>
    </Box>
  );
};

OurAvatarView.whyDidYouRender = true;

export default OurAvatarView;
