import { RecordType, RecordTypes } from '@petconsole/pure-base';
import { StateSliver } from '../../types/stateTypes';

interface SelectsByPropertyNameAndValueOuterSelectorProps<V> {
  propertyName: string;
  value: V;
}

const selectsByPropertyNameAndValueOuterSelector =
  <V>({ propertyName, value }: SelectsByPropertyNameAndValueOuterSelectorProps<V>) =>
  ({ ids, entities }: StateSliver<RecordType>) =>
    ids.reduce((records, id) => {
      const record = entities[id];

      if (record[propertyName] !== value) return records;

      return [...records, record];
    }, [] as RecordTypes);

export default selectsByPropertyNameAndValueOuterSelector;
