import { CardContent, Collapse } from '@mui/material';
import { OurLazyLocationMap } from '../../misc/maps';

interface OurDirectionsCardContentProps {
  latitude?: number;
  longitude?: number;
  title?: string;
  subTitle?: string;
  isOpen?: boolean;
}

const OurDirectionsCardContent = ({ latitude, longitude, title, subTitle, isOpen }: OurDirectionsCardContentProps) => (
  <Collapse in={isOpen} timeout="auto" unmountOnExit>
    <CardContent sx={{ p: ({ spacing }) => spacing(1, 2, 0), '&:last-child': { pb: 0 } }}>
      <OurLazyLocationMap latitude={latitude} longitude={longitude} title={title} address={subTitle} />
    </CardContent>
  </Collapse>
);

OurDirectionsCardContent.whyDidYouRender = true;

export default OurDirectionsCardContent;
