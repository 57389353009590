import { useContext, useEffect } from 'react';
import { EntityAppProps } from '@petconsole/pure-shared';
import { EntityAppContext } from './EntityAppContext';

const SetEntityAppContext = ({ entity, hooks, queryHook, schema, values, help }: EntityAppProps) => {
  const { setEntity, setHooks, setQueryHook, setSchema, setValues, setHelp } = useContext(EntityAppContext);

  useEffect(() => {
    setEntity(entity);
    setHooks(hooks);
    setQueryHook(queryHook);
    setSchema(schema);
    setValues(values);
    setHelp(help);
  }, [
    entity,
    hooks,
    queryHook,
    schema,
    values,
    help,
    setEntity,
    setHooks,
    setSchema,
    setValues,
    setHelp,
    setQueryHook,
  ]);

  return null;
};

SetEntityAppContext.whyDidYouRender = true;

export default SetEntityAppContext;
