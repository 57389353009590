import { Api } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import { NextAction } from '../../types';
import { payloadCreatorCreateEntity } from '../payloadCreators';
import { RecordType } from '@petconsole/pure-base';

const createEntityThunk = <T extends RecordType = RecordType>(
  sliceName: string,
  entityType: string,
  idName: string,
  api: Api<T>,
  fetch?: NextAction,
  callbacks: (() => unknown)[] = [],
) => appThunk(`${sliceName}/create${entityType}`, payloadCreatorCreateEntity(sliceName, idName, api, fetch, callbacks));

export default createEntityThunk;
