import useOurRedirect from './useOurRedirect';

export const useOurRedirectPath = ({ path }: { path: string }) => {
  const { redirect } = useOurRedirect();
  const { pathname, search } = redirect || {};

  return `${path}${pathname ? `?redirectTo=${pathname}` : ''}${search ? `&redirectWith=${search}` : ''}`;
};

export default useOurRedirectPath;
