import { Pinterest } from '@mui/icons-material';
import OurExternalIcon from '../icons/OurExternalIcon';

interface OurPinterestIconProps {
  href?: string;
  account?: string;
}

const OurPinterestIcon = ({ href, account = 'petconsole' }: OurPinterestIconProps) => (
  <OurExternalIcon icon={<Pinterest />} href={href || `https://www.pinterest.com/${account}`} aria="Pinterest" />
);

OurPinterestIcon.whyDidYouRender = true;

export default OurPinterestIcon;
