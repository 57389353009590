import { ListPayloadCreator } from '../../types';
import dispatchListItemsNextAction from '../misc/dispatchListItemsNextAction';
import fetchParams from '../misc/fetchParams';

const payloadCreatorOldestWithFetch: ListPayloadCreator =
  (idName, api, slice, sliver, nextAction) =>
  async (_, { getState, dispatch }) =>
    api
      .getListCreatedBetween({}, { ...fetchParams(getState, slice, sliver), scanForward: true })
      .then((data) => dispatchListItemsNextAction({ data, idName, dispatch, nextAction }));

export default payloadCreatorOldestWithFetch;
