import { useCallback, useEffect, useState } from 'react';
import { CityLocation } from '@petconsole/pure-base';
import { ReturnsVoid } from '@petconsole/pure-shared';
import { FetchesByLocation } from '@petconsole/pure-fe-state';

interface UseOurByCityLocationProps {
  location?: CityLocation;
  reset: ReturnsVoid;
  fetch: FetchesByLocation;
}

const useOurByCityLocation = ({ location, reset, fetch }: UseOurByCityLocationProps) => {
  const [lastLocation, setLastLocation] = useState(undefined as CityLocation | undefined);

  const fetchByLocation = useCallback(() => {
    if (location) fetch(location).then();
  }, [fetch, location]);

  const refresh = useCallback(() => {
    reset();
    fetchByLocation();
  }, [reset, fetchByLocation]);

  useEffect(() => {
    // Ignore initial null location, and attempts to clear the location
    if (!location) return;

    // We can get triggered on every keystroke from the Google input with the same location. Ignore
    // it if the location hasn't changed
    if (JSON.stringify(location) === JSON.stringify(lastLocation)) return;

    setLastLocation(location);
    refresh();
  }, [location, lastLocation, refresh]);

  return { fetchByLocation, refresh };
};

export default useOurByCityLocation;
