import React, { ElementType, ReactNode, useEffect } from 'react';
import { CityLocation, MaybeCityLocation, RecordType } from '@petconsole/pure-base';
import { SetStringHook } from '@petconsole/pure-fe-state';
import { PclHeaderRecord, SetState } from '@petconsole/pure-shared';
import useOurEntityFirstFetch from '../../hooks/useOurEntityFirstFetch';
import useOurEntityRefresh from '../../hooks/useOurEntityRefresh';
import OurEntityToolbar from '../misc/OurEntityToolbar';

export interface OurTabsToolbarProps {
  heading: ReactNode;
  helpTitle?: string;
  helpContent?: string;
  pclHeader?: PclHeaderRecord;
  image?: string;
  imageWidth?: string;
  alt?: string;
  cityTab?: string;
  tabProps: React.MutableRefObject<RecordType>;
  tabValue: string;
  setTabValue: ReturnType<SetStringHook>
  city?: string;
  location?: CityLocation;
  changeLocation?: CityLocation;
  setChangeLocation?: SetState<MaybeCityLocation>;
  addPath?: string;
  phone?: boolean;
  AddEntity?: ElementType;
  addEntity?: string;
  addDisabled?: boolean;
  addInstructions?: string;
  addLabel?: string;
  addProperty?: string;
  addAddress?: boolean;
  addPhone?: boolean;
  addDefaultData?: RecordType;
}

const OurTabsToolbar = ({
  heading,
  helpTitle,
  helpContent,
  pclHeader,
  image,
  imageWidth,
  alt,
  cityTab,
  tabProps,
  tabValue,
  setTabValue,
  city,
  location,
  changeLocation,
  setChangeLocation,
  addPath,
  phone,
  AddEntity,
  addEntity,
  addDisabled,
  addInstructions,
  addLabel,
  addProperty,
  addAddress = false,
  addPhone = false,
  addDefaultData,
}: OurTabsToolbarProps) => {
  useOurEntityFirstFetch({ tabProps, tabValue, cityTab });
  const { canRefresh, onRefresh } = useOurEntityRefresh({
    tabProps,
    tabValue,
    cityTab,
    city,
    location,
  });

  useEffect(() => {
    if (!changeLocation) return;

    setTabValue(cityTab as string);
    onRefresh();

    if (setChangeLocation) setChangeLocation(null);
  }, [cityTab, setTabValue, onRefresh, changeLocation, setChangeLocation]);

  return (
    <OurEntityToolbar
      heading={heading}
      helpTitle={helpTitle}
      helpContent={helpContent}
      pclHeader={pclHeader}
      image={image}
      imageWidth={imageWidth}
      alt={alt}
      phone={phone}
      addDisabled={addDisabled}
      onRefresh={onRefresh}
      refreshDisabled={!canRefresh}
      AddEntity={AddEntity}
      addEntity={addEntity} /* eslint-disable-line react/jsx-no-duplicate-props  */
      addProperty={addProperty}
      addInstructions={addInstructions}
      addLabel={addLabel}
      addAddress={addAddress}
      addPhone={addPhone}
      addDefaultData={addDefaultData}
      addPath={addPath}
    />
  );
};

OurTabsToolbar.whyDidYouRender = true;

export default OurTabsToolbar;
