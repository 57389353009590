import { useRef } from 'react';
import { Card } from '@mui/material';
import { newId } from '@petconsole/pure-base';

// Note that pet images are only displayed when browser width is lg or higher.

interface OurUnsplashImageCardProps {
  subject?: string;
}

const OurUnsplashImageCard = ({ subject = 'pets' }: OurUnsplashImageCardProps) => {
  const src = useRef(`https://source.unsplash.com/random/?${subject}&sig=${newId()}${newId()}`);

  return (
    <Card sx={{ p: 0.5 }}>
      <img src={src.current} alt={subject} width="100%" height="auto" />
    </Card>
  );
};

OurUnsplashImageCard.whyDidYouRender = true;

export default OurUnsplashImageCard;
