import { ShopItemVariantCodeRecord, ShopItemVariantCodeRecords } from '@petconsole/pure-shared';
import { SelectByShopItemAndVariantOptionProps, StateSliver } from '../../../types';

const selectsByShopItemAndOptionOuterSelector =
  ({ shopItemId, variantOption }: SelectByShopItemAndVariantOptionProps) =>
  ({ ids, entities }: StateSliver<ShopItemVariantCodeRecord>) =>
    ids.reduce((tempVariantCodes, id) => {
      const variantCode = entities[id];

      if (variantCode.shopItemId !== shopItemId || variantCode.variantOption !== variantOption) return tempVariantCodes;

      return [...tempVariantCodes, variantCode];
    }, [] as ShopItemVariantCodeRecords);

export default selectsByShopItemAndOptionOuterSelector;
