import { CityLocation, MaybeNull, RecordType } from '@petconsole/pure-base';
import { api } from '@petconsole/pure-fe-amplify';
import {
  Api,
  CreateMethod,
  DeleteMethod,
  GetListOptions,
  GetListResults,
  reactAppApiSite,
} from '@petconsole/pure-shared';

type PathParam = (param: string, value?: unknown) => string;
type PathParamsToString = (path: string, params: string) => string;
type ParamsToGetResult<T extends RecordType = RecordType> = (params: string) => Promise<MaybeNull<T>>;
type ParamsToGetListResult<T extends RecordType = RecordType> = (params: string) => Promise<GetListResults<T>>;

const apiName = 'web-api';
const options = { headers: {} };

export const del = <T extends RecordType = RecordType>(path: string) =>
  api.del({ apiName, path, options }) as Promise<T>;
export const get = <T extends RecordType = RecordType>(path: string) =>
  api.get({ apiName, path, options }) as Promise<MaybeNull<T> | GetListResults<T>>;
export const post = <T extends RecordType = RecordType>(path: string, body: T = {} as T) =>
  api.post({
    apiName,
    path,
    options: { ...options, body },
  }) as Promise<T>;
export const update = <T extends RecordType = RecordType>(path: string, body: T = {} as T) =>
  api.patch<T>({ apiName, path, options: { ...options, body } });

export const pathParam: PathParam = (param, value?) =>
  `&${param}${value === undefined ? '' : '='}${value === undefined ? '' : value}`;
export const optionalParam = (param: string, value?: unknown) =>
  value || value === 0 || value === false ? pathParam(param, value) : '';
export const limitParams = (limit?: GetListOptions['limit'], nextKey?: GetListOptions['nextKey']) =>
  `${optionalParam('limit', limit || undefined)}${optionalParam('nextKey', nextKey)}`;
export const locationParams = ({
  city,
  province,
  country,
}: {
  city?: string;
  province?: string;
  country?: string;
} = {}) => `${pathParam('city', city)}${pathParam('province', province)}${pathParam('country', country)}`;
export const createdBetweenParams = (from = '', to = '') =>
  `${pathParam('fromCreatedAt', from)}${pathParam('toCreatedAt', to)}`;
const scanParam = (scanForward?: boolean) => optionalParam('scanForward', scanForward);
export const optionParams = (options: GetListOptions = {}) => {
  const { limit, nextKey, all, scanForward } = options;

  return `${limitParams(limit, nextKey)}${optionalParam('all', all)}${scanParam(scanForward)}`;
};

// Reminder: Only the first occurrence of the substring is replaced
export const fullPath: PathParamsToString = (path, params) => `${path}${params ? params.replace('&', '?') : ''}`;
export const sitePath: PathParamsToString = (path, params) =>
  fullPath(path, `${pathParam('site', reactAppApiSite)}${params}`);

export interface CommonApiProps {
  apiPath: string;
}

export interface CreatedBetweenProps {
  fromCreatedAt?: string;
  toCreatedAt?: string;
}

export const commonApi = <T extends RecordType = RecordType>({ apiPath }: CommonApiProps): Api<T> => {
  const idPath = (id: string) => `${apiPath}/${id}`;
  const getByPath = () => `${apiPath}/getBy`;
  const param = pathParam;

  const fullGetBy = (params: string) => get(fullPath(getByPath(), params)) as Promise<MaybeNull<T>>;
  const siteGet = (params: string) => get<T>(sitePath(apiPath, params)) as Promise<MaybeNull<T>>;
  const siteGetBy: ParamsToGetResult<T> = (params) => get<T>(sitePath(getByPath(), params)) as Promise<MaybeNull<T>>;
  const siteGetList: ParamsToGetListResult<T> = (params) =>
    get(sitePath(apiPath, params)) as Promise<GetListResults<T>>;
  const create: CreateMethod<T> = (body) => post(sitePath(apiPath, ''), body);
  const deletes: DeleteMethod<T> = (id: string) => del(idPath(id));
  const gets = (id: string) => get(idPath(id)) as Promise<MaybeNull<T>>;

  return {
    apiPath,
    create,
    delete: deletes,
    get: gets,
    getWith: siteGet,
    getAll: () => siteGetList(param('all', 'true')),
    getList: (options) => siteGetList(optionParams(options)),
    getListWith: (params, options) => siteGetList(`${params}${optionParams(options)}`),
    update: (id: string, body?: T) => update<T>(idPath(id), body),
    getByName: (value: string) => siteGetBy(param('name', value)),
    getByQuery: (query: string) => fullGetBy(query),
    getByUrlName: (urlName: string) => fullGetBy(param('urlName', urlName)),
    getByValue: (name: string, value: string) => siteGetBy(param(name, value)),
    getListByCity: (cityProps: CityLocation, options) =>
      siteGetList(`${locationParams(cityProps)}${optionParams(options)}`),
    getListCreatedBetween: ({ fromCreatedAt = '', toCreatedAt = '' }: CreatedBetweenProps, options) =>
      siteGetList(`${createdBetweenParams(fromCreatedAt, toCreatedAt)}${optionParams(options)}`),
    getMyList: (options) => siteGetList(`${param('my')}${optionParams(options)}`),
    getListByUrlAndCreatedBetween: (
      {
        urlName = '',
        fromCreatedAt = '',
        toCreatedAt = '',
      }: CreatedBetweenProps & {
        urlName?: string;
      },
      options,
    ) =>
      siteGetList(
        `${param('name', urlName || reactAppApiSite)}${createdBetweenParams(fromCreatedAt, toCreatedAt)}${optionParams(options)}`,
      ),
    getListByValue: (name: string, value: string, options) =>
      siteGetList(`${param(name, value)}${optionParams(options)}`),
    // Don't use sitePath - only one parameter is expected
    react: (id: string, reaction: string, updatedAt?: string) =>
      post(fullPath(idPath(id), param(reaction)), { updatedAt }) as unknown as Promise<T>,
  };
};
