import { skipToken } from '@reduxjs/toolkit/query';
import { postApiEntity } from '@petconsole/pure-fe-api';
import { PostRecord } from '@petconsole/pure-shared';
import {
  useCreatePostMutation,
  useDeletePostMutation,
  useGetPostQuery,
  useUpdatePostMutation,
} from '../../../apiSlice';
import useCreateEntity from '../../../helpers/useCreateEntity';
import useDeleteEntity from '../../../helpers/useDeleteEntity';
import useUpdateEntity from '../../../helpers/useUpdateEntity';

const apiEntity = postApiEntity;
type ApiRecord = PostRecord;

export const useCreatePost = () => useCreateEntity<ApiRecord>({ apiEntity, mutationHook: useCreatePostMutation });
export const useDeletePost = () =>
  useDeleteEntity<ApiRecord>({
    apiEntity,
    mutationHook: useDeletePostMutation,
  });
export const useGetPost = ({ postId }: { postId: string }) => useGetPostQuery(postId || skipToken);
export const useUpdatePost = () => useUpdateEntity<ApiRecord>({ apiEntity, mutationHook: useUpdatePostMutation });
