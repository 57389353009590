import { Grid, GridProps, TypographyProps } from '@mui/material';
import OurStrongText, { OurStrongTextProps } from './OurStrongText';

interface OurStrongTextItemProps extends OurStrongTextProps {
  gridProps?: GridProps;
  typeProps?: TypographyProps;
}

const OurStrongTextItem = ({ gridProps, text, typeProps }: OurStrongTextItemProps) => {
  return (
    <Grid item xs={12} {...gridProps}>
      <OurStrongText text={text} typeProps={typeProps} />
    </Grid>
  );
};

OurStrongTextItem.whyDidYouRender = true;

export default OurStrongTextItem;
