import { Grid } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import OurText from '../components/inputs/OurText';
import { MaintMainProps } from './types';

const MaintMain = <T extends RecordType = RecordType>({
  form,
  entity,
  readOnly,
  Address,
  DirectionsButton,
}: MaintMainProps<T>) => {
  const { addField, addLabel } = entity;

  return (
    <>
      <Grid item xs={12} display="flex" columnGap={1}>
        <OurText {...{ form, readOnly }} id={addField} label={addLabel} required />
        {DirectionsButton && <DirectionsButton />}
      </Grid>
      {Address && <Address />}
    </>
  );
};

MaintMain.whyDidYouRender = true;

export default MaintMain;
