// noinspection ES6PreferShortImport

import { ObjectShape } from 'yup';
import * as yup from 'yup';
import { ulid } from 'ulid';
import { RecordOfString } from '@petconsole/pure-base';
import { genderType, genderTypes, ourCurrencyCode, pclServiceCode, site, ulidRegex } from '../constants';
import ourArrayToEntries from '../misc/ourArrayToEntries';
import { PclServiceCode, RecordOfNamedSets } from '../types';
import { dataEntityTypes } from './constants';

export { Schema as YupSchema } from 'yup';

export const separator = '#';

export const optionalRegex = {
  domain: /^(((?!-)[A-Za-z0–9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6})?$/,
  isoUtc: /^((\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}\.\d{3}Z))?$/,
  memberName: /^([\da-zA-Z][\da-zA-Z._-]{1,29})?$/,
  u2id: /^(([0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26})|(^[a-fA-F\d]{8}-(([a-fA-F\d]{4}-){3})[a-fA-F\d]{12}))?$/,
  ulid: /^([0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26})?$/,
};

export const requiredRegex = {
  domain: /^((?!-)[A-Za-z0–9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/,
  isoUtc: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}\.\d{3}Z)$/,
  memberId: ulidRegex,
  memberName: /^[\da-zA-Z][\da-zA-Z._-]{1,29}$/,
  u2id: /(^[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}$)|(^[a-fA-F\d]{8}-(([a-fA-F\d]{4}-){3})[a-fA-F\d]{12}$)/,
  ulid: ulidRegex,
};

export const regex = {
  optional: { ...optionalRegex },
  required: { ...requiredRegex },
};

export const schemaBase = {
  id: (required = false) =>
    required ? yup.string().required().matches(requiredRegex.ulid) : yup.string().ensure().matches(optionalRegex.ulid),
};

// Google address
export const schemaAddress = (required = true) =>
  required
    ? yup.object().required().noUnknown(false).default({ description: '' })
    : yup.object().noUnknown(false).default({ description: '' });

export const schemaStrim = () => yup.string().ensure().trim();

export const schemaDomain = (required = false) =>
  required
    ? yup.string().required().matches(requiredRegex.domain)
    : yup.string().ensure().matches(optionalRegex.domain);

export const schemaEmail = (required = false) =>
  required ? yup.string().required().email() : yup.string().ensure().email();

export const schemaMemberName = (required = false) =>
  required
    ? yup.string().required().matches(requiredRegex.memberName)
    : yup
        .string()
        .nullable()
        .default(null)
        .test(
          `name-test-${ulid()}`,
          `name must be blank or match the following: "${requiredRegex.memberName.toString()}"`,
          (value) => !value || requiredRegex.memberName.test(value),
        );

const transformNumber = (value: unknown, prev: unknown) => {
  // If we have a valid number, we can return that
  if (!isNaN(value as number)) return value;

  // If there was no previous value, we'll return 0
  if (!prev) return 0;

  // react-number-format returns formatted text strings - we need to strip formatting and ensure we get a number
  if (typeof prev === 'string') return prev.replace(/[^\d.]/g, '');

  // Return the value before yup tried to convert it
  return prev;
};

export const schemaNumber = (initial = 0) =>
  yup
    .number()
    .default(initial)
    // react-number-format returns formatted text strings - we need to strip formatting and ensure we get a number
    .transform(transformNumber);

export const schemaOneOf = (type: RecordOfString = {}, initial = '') =>
  yup.string().oneOf(Object.values(type)).default(initial);

export const schemaOwnerName = (required = false) =>
  required
    ? yup.string().required().matches(requiredRegex.memberName)
    : yup
        .string()
        .ensure()
        .test(
          `owner-name-test-${ulid()}`,
          `name must be blank or match the following: "${requiredRegex.memberName.toString()}"`,
          (value) => !value || requiredRegex.memberName.test(value),
        );

export const schemaPhone = (maxChars = 20) => schemaStrim().max(maxChars);

export const schemaIsoUtc = (required = false) =>
  required
    ? yup.string().required().matches(requiredRegex.isoUtc)
    : yup.string().ensure().matches(optionalRegex.isoUtc);

export const schemaObject = (props: ObjectShape) => yup.object(props).noUnknown(true);

export const schemaImage = () =>
  schemaObject({
    imageId: schemaBase.id(),
    title: yup.string().ensure(),
    contentType: yup.string().ensure(),
    s3Key: yup.string().ensure(),
  });

export const schemaPclServiceCode = () =>
  schemaStrim()
    .required('Service code is required')
    .test(
      'Check service code',
      (value) =>
        value.startsWith(pclServiceCode.unitTest) || Object.values(pclServiceCode).includes(value as PclServiceCode),
    );

// export const yupSchemaSocial = () =>
//   yupSchemaObject({
//     type: yup.string().required().oneOf(Object.values(socialType)).default(socialType.other),
//     url: yupSchemaUrl,
//   });

export const schemaSite = () =>
  schemaStrim()
    .required('Site is required')
    .test(
      'Check site',
      (value) => value.startsWith(site.unitTest) || (Object.values(site) as string[]).includes(value),
    );

export const schemaStars = () => yup.array().default(new Array(10).fill(0));

export const schemaUrl = (url = '') => yup.string().ensure().url().default(url);

export const schemaContactColumns = () => ({
  contactName: schemaStrim(),
  phoneNumber: schemaPhone(),
  email: schemaEmail(),
  website: schemaUrl(),
});

export const schemaValueItem = () =>
  schemaObject({
    valueId: schemaBase.id(),
    value: yup.string().ensure(),
    description: yup.string().ensure(),
  });

export const schemaCurrency = () => schemaOneOf(ourCurrencyCode, ourCurrencyCode.CAD);

export const schema = {
  address: schemaAddress,
  array: () => yup.array().default([]),
  arrayOf: (props: ObjectShape) => yup.array().default([]).of(schemaObject(props)),
  avatar: schemaStrim,
  boolean: (initial = false) => yup.boolean().default(initial),
  businessColumns: () => ({
    avatar: schemaStrim(),
    banner: schemaImage().nullable(),
    tagline: schemaStrim(),
    aboutUs: schemaStrim(),
    ...schemaContactColumns(),
    domainName: schemaDomain(),
    urlName: schemaStrim(),
  }),
  caps: () => yup.string().ensure().trim().uppercase(),
  contactColumns: schemaContactColumns,
  crudColumns: () => ({
    createdBy: schemaMemberName(true), // read-only
    updatedBy: schemaMemberName(true), // read-only
    createdId: schemaBase.id(true),
    updatedId: schemaBase.id(true),
    createdAt: schemaIsoUtc(),
    updatedAt: schemaIsoUtc(),
  }),
  currency: schemaCurrency,
  date: () => yup.string().nullable().default(null),
  // domain: yupSchemaDomain,
  email: schemaEmail,
  gender: () => yup.string().oneOf(genderTypes).default(genderType.Other),
  googleAddressColumns: () => ({
    address: schemaAddress(true),
    country: yup.string().ensure(), // read-only - ISO 2 char
    province: yup.string().ensure(), // read-only
    postal: yup.string().ensure(), // read-only
    city: yup.string().ensure(), // read-only
    neighborhood: yup.string().ensure(), // read-only
  }),
  googleLocation: yup.mixed,
  id: schemaBase.id,
  id2: () => yup.string().required().matches(requiredRegex.u2id),
  image: () => schemaImage().nullable(),
  images: () => yup.array().of(schemaImage()).default([]),
  isoUtc: schemaIsoUtc,
  json: () => yup.object().nullable().noUnknown(false),
  memberColumns: () => ({
    memberId: schemaBase.id(true),
    memberName: schemaMemberName(true),
  }),
  memberName: schemaMemberName,
  nullable: () => yup.string().trim().nullable().default(null),
  number: schemaNumber,
  object: yup.object,
  oneOf: schemaOneOf,
  oneOfSetNames: (type: RecordOfNamedSets = {}, initial = '') =>
    schemaOneOf(ourArrayToEntries(Object.values(type).map(({ name }) => name)), initial),
  // schemaOneOf((Object.values(type)).map(({ name } as NamedSet) => name)),
  ownerColumns: () => ({
    ownerId: schemaBase.id(),
    ownerName: schemaOwnerName(),
  }),
  ownerName: schemaOwnerName,
  pclServiceCode: schemaPclServiceCode,
  phone: schemaPhone,
  pos: (initial = 0) => yup.number().transform(transformNumber).min(0).default(initial),
  // quantityUom: (initial = ourUomQuantity.EA) => yup.string().oneOf(Object.values(ourUomQuantity)).default(initial),
  ratings: schemaStars,
  reactionColumns: () => ({
    likeCount: schemaNumber(),
    commentCount: schemaNumber(),
    shareCount: schemaNumber(),
  }),
  schemaRef: yup.ref,
  schemaObject,
  site: schemaSite,
  siteColumns: () => ({
    site: schemaSite(),
  }),
  socialColumns: () => ({
    facebook: schemaUrl(),
    instagram: schemaUrl(),
    linkedIn: schemaUrl(),
    pinterest: schemaUrl(),
    tikTok: schemaUrl(),
    twitter: schemaUrl(),
    youTube: schemaUrl(),
  }),
  // stars: schemaStars,
  strim: schemaStrim,
  string: (maxChars?: number) => (maxChars ? yup.string().ensure().max(maxChars) : yup.string().ensure()),
  subscribeColumns: () => ({
    ownerId: schemaBase.id(),
    ownerName: schemaOwnerName(),
    pclSubscriptionId: schemaBase.id(),
    pclTierLevel: schemaNumber(),
  }),
  subscriptionColumns: () => ({
    pclSubscriptionId: schemaBase.id(),
    pclTierLevel: schemaNumber(),
  }),
  text: (nullable?: boolean) => (nullable ? yup.string().nullable().default(null) : yup.string().ensure()),
  timestampColumns: () => ({ createdAt: schemaIsoUtc(), updatedAt: schemaIsoUtc() }),
  triple: (initial: boolean | null = false) => yup.boolean().nullable().default(initial),
  type: (type?: string) =>
    yup
      .string()
      .required()
      .min(1)
      .oneOf(type ? [type] : dataEntityTypes)
      .default(type),
  url: (maxChars = 256) => yup.string().ensure().url().max(maxChars),
  // valueItem: yupSchemaValueItem,
  valueItems: () => yup.array().of(schemaValueItem()).default([]),
};
