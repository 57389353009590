import { localCache } from './caches';
import createLocalCache from './createLocalCache';
import { CacheItemOptions } from '../types';

const getLocalItem = async (key: string, options?: CacheItemOptions) => {
  if (!localCache) createLocalCache();

  return localCache.getItem(key, options);
};

export default getLocalItem;
