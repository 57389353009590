import { ElementType } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import Rollbar from 'rollbar';
import { Store } from '@reduxjs/toolkit';
import ourRollbarConfig from '../../../error/ourRollbarConfig';
import OurAppWrapper from './OurAppWrapper';

const rollbar = new Rollbar(ourRollbarConfig);

interface RenderAppProps {
  App: ElementType;
  store: Store;
}

const renderApp = ({ store, App }: RenderAppProps) => {
  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <OurAppWrapper ReduxProvider={ReduxProvider} store={store} rollbar={rollbar}>
      <App />
    </OurAppWrapper>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if ('serviceWorker' in navigator)
  navigator.serviceWorker.ready.then(({ unregister }) => unregister());

export default renderApp;
