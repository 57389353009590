import { useEffect } from 'react';
import { ourPclHeaderHooks } from '@petconsole/pure-fe-state';

const useOurPclHeader = ({ entityType }: { entityType: string }) => {
  const { customHooks: customPclHeaderHooks } = ourPclHeaderHooks;
  const pclHeader = customPclHeaderHooks.useSelectByEntityType(entityType);
  const fetchPclHeader = customPclHeaderHooks.useFetchByEntityType();

  useEffect(() => {
    if (!pclHeader) fetchPclHeader(entityType).then();
  }, [pclHeader, fetchPclHeader, entityType]);

  return { pclHeader };
};

export default useOurPclHeader;
