import { PersonAdd as PersonAddIcon } from '@mui/icons-material';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

const OurNewFriendButton = ({ disabled = false, ...rest }: OurIconButtonProps) => (
  <OurIconButton
    {...rest}
    tip="New Friend"
    disabled={disabled}
    Icon={PersonAddIcon}
  />
);

OurNewFriendButton.whyDidYouRender = true;

export default OurNewFriendButton;
