import { Typography } from '@mui/material';

interface OurCountProps {
  count?: number;
  name?: string;
  single?: string;
  plural?: string;
}

const OurCount = ({ count, name, single, plural }: OurCountProps) => {
  if (count === undefined) return <span />;

  return (
    <Typography variant="body2" color="textSecondary">
      {!count ? name : `${count} ${count === 1 ? single : plural}`}
    </Typography>
  );
};

OurCount.whyDidYouRender = true;

export default OurCount;
