import { MouseEventHandler } from 'react';
import { CancelOutlined } from '@mui/icons-material';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurRemoveImageButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon' | 'onClick'> {
  onRemove: MouseEventHandler;
}

const OurRemoveImageButton = ({ onRemove, ...rest }: OurRemoveImageButtonProps) => (
  <OurIconButton
    {...rest}
    tip="Remove"
    onClick={onRemove}
    Icon={CancelOutlined}
    sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 100,
      cursor: 'pointer',
      color: 'black',
    }}
  />
);

OurRemoveImageButton.whyDidYouRender = true;

export default OurRemoveImageButton;
