import { useMemo } from 'react';
import { OurEntityApp } from '@petconsole/pure-fe-mui';
import { ApiUseGetQuery, ourOrderHooks, OurEntityHooks, useGetOrderQuery } from '@petconsole/pure-fe-state';
import { initialOrder, orderEntity, orderSchema, EntityAppWrapperProps } from '@petconsole/pure-shared';

const OrdersAppWrapper = ({ help, App }: EntityAppWrapperProps) => {
  const props = useMemo(
    () => ({
      entity: orderEntity,
      hooks: ourOrderHooks as unknown as OurEntityHooks,
      queryHook: useGetOrderQuery as ApiUseGetQuery,
      schema: orderSchema,
      values: initialOrder(),
      help,
      App,
    }),
    [help, App],
  );

  return <OurEntityApp {...props} />;
};

OrdersAppWrapper.whyDidYouRender = true;

export default OrdersAppWrapper;
