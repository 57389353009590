import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  ApiEntity,
  GetListOptions,
  GetListResults,
  MaybeReactionRecord,
  reactionEntity,
  ReactionRecord,
} from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = ReactionRecord;
type ListReturns = Promise<GetListResults<ApiRecord>>;

const apiPath = '/reactions';
const { getByValue, getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi extends RecordType {
  getByReactionTo: (reactionToId: string) => Promise<MaybeReactionRecord>;
  getListByMember: ({ memberId }: { memberId: string }, options?: GetListOptions) => ListReturns;
}

export type ReactionApi = Api<ApiRecord> & CustomApi;

export const reactionApi: ReactionApi = {
  ...common,
  getByValue,
  getListByValue,
  getByReactionTo: (reactionToId) => getByValue('reactionToId', reactionToId) as Promise<MaybeReactionRecord>,
  getListByMember: ({ memberId }, options) => getListByValue('memberId', memberId, options),
};

export const reactionApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...reactionEntity, api: reactionApi };
