import { RecordType } from '@petconsole/pure-base';
import { EntityName, ourEntityNameToType } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import ourReactTo from './ourReactTo';

interface OurReactToEntityThunkProps {
  entityName: EntityName;
}

const ourReactToEntityThunk = <T extends RecordType = RecordType>({ entityName }: OurReactToEntityThunkProps) => {
  const type = ourEntityNameToType(entityName);

  return appThunk(`${entityName}/reactToEntity${type}`, ourReactTo<T>);
};

export default ourReactToEntityThunk;