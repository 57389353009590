import { RecordType } from '@petconsole/pure-base';
import { apiMethodType, EntityType, DeleteMethod } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../types';

const deleteOne = <T extends RecordType = RecordType>(
  builder: ApiBuilder<T>,
  method: DeleteMethod<T>,
  tag: EntityType,
) =>
  builder.mutation<T, string>({
    query: (id) => ({ type: apiMethodType.delete, method, id }),
    invalidatesTags: (_result, _error, id) => [tag, { type: tag, id }],
  });

export default deleteOne;
