import { forumApiEntity } from '@petconsole/pure-fe-api';
import { ForumRecord, RecordType } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import { BaseSliceOptions, OurEntityHooks, RequiredCrudSliverHooks, RequiredSliverByHooks } from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { fetchToForumForumsToHierarchyReducers } from './fetchToForum';
import { toForumForumPayloadCreators } from './toForumForum';

type ApiEntityRecord = ForumRecord;

const apiEntity = forumApiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  sortProperty: 'hierarchy',
  tabs: undefined,
  slivers: [
    { prefix: 'byForum', fetchesById: true },
    {
      prefix: 'toForum',
      fetchesById: true,
      payloadCreators: toForumForumPayloadCreators,
      extraReducers: [fetchToForumForumsToHierarchyReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks } = sliceHookTypes;

export interface OurForumHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  forumHooks: RequiredCrudSliverHooks;
  byForumForumHooks: RequiredSliverByHooks;
  toForumForumHooks: RequiredSliverByHooks;
  customHooks: {
    forumRootHierarchy: string;
  };
}

export const ourForumHooks: OurForumHooks = {
  entity: apiEntity,
  forumHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  byForumForumHooks: sliverHooks(apiEntity, 'ByForum', hooks) as RequiredSliverByHooks,
  toForumForumHooks: sliverHooks(apiEntity, 'ToForum', hooks) as RequiredSliverByHooks,
  customHooks: {
    forumRootHierarchy: '00000',
  },
};

export default reducer;
