import { confirmResetPassword, ConfirmResetPasswordInput } from '@aws-amplify/auth';

export const attemptConfirmResetPassword = ({ username, newPassword, confirmationCode }: ConfirmResetPasswordInput) =>
  confirmResetPassword({ username, newPassword, confirmationCode });

interface AuthConfirmResetPasswordProps {
  email: string;
  password: string;
  code: string;
}

const authConfirmResetPassword = async ({ email, password, code }: AuthConfirmResetPasswordProps) => {
  try {
    await attemptConfirmResetPassword({ username: email, newPassword: password, confirmationCode: code });

    return { success: true };
  } catch (e) {
    if (!(e instanceof Error)) {
      // TODO: Log to rollbar
      return { success: false, reason: 'Unknown error' };
    }

    return { success: false, reason: e.message };
  }
};

export default authConfirmResetPassword;
