import { useEffect, useState } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RecordType } from '@petconsole/pure-base';
import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import { Image } from '@petconsole/pure-shared';
import useOurWidth from '../../../hooks/useOurWidth';
import Slide from './Slide';
import LeftSlide from './LeftSlide';
import RightSlide from './RightSlide';

// declare module '@mui/material/styles' {
//   interface TypographyVariants {
//     lobsterTwo: React.CSSProperties;
//   }
//
//   // allow configuration using `createTheme`
//   interface TypographyVariantsOptions {
//     lobsterTwo: React.CSSProperties;
//   }
// }

// Update the Typography's variant prop options
// declare module '@mui/material/Typography' {
//   interface TypographyPropsVariantOverrides {
//     lobsterTwo: true;
//   }
// }

interface OurFilmHeadingProps {
  topText: string;
  bottomText: string;
  images: Image[];
}

const OurFilmHeading = ({ topText, bottomText, images }: OurFilmHeadingProps) => {
  const width = useOurWidth();
  const { palette, typography } = useTheme();

  const [outerContainerHeight, setOuterContainerHeight] = useState(0);
  const [urls, setUrls] = useState([] as string[]);

  const outerFrameContainerId = 'outer-frame-container';
  const outerContainerWidth = document.getElementById(outerFrameContainerId)?.clientWidth || 0;
  const borderWidth =
    (width === 'xs' ? 3 : 0) +
    (width === 'sm' ? 4 : 0) +
    (width === 'md' ? 6 : 0) +
    (width === 'lg' ? 8 : 0) +
    (width === 'xl' ? 8 : 0);
  const borderRadius = width === 'xs' || width === 'sm' ? 2 : 3;
  const innerFilmRadius = ({ spacing }: Theme) => spacing(borderRadius);
  const filmBorder = `solid ${borderWidth}px black`;
  const { lobsterTwo } = typography as unknown as RecordType;

  useEffect(() => {
    const withSrc = images.map((image) => ({
      ...image,
      src: image.src || ourMemberImageUrl(image.s3Key),
    }));
    const padded = [...withSrc, {} as Image, {} as Image, {} as Image, {} as Image].slice(0, 4);

    setUrls(padded.map(({ src = '' }: RecordType) => `url(${src as string})`));
  }, [images]);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = document.getElementById(outerFrameContainerId)?.clientWidth || 0;
      const newHeight = ((newWidth / 16) * 9) / 2;

      setOuterContainerHeight(newHeight);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [outerContainerWidth]);

  return (
    <Grid
      container
      id={outerFrameContainerId}
      item
      xs={12}
      height={outerContainerHeight + 70}
      bgcolor={palette.primary.main}
      justifyContent="center"
    >
      <Grid item xs={12} height={35} maxHeight={35} textAlign="center">
        <Typography
          // variant="lobsterTwo"
          fontFamily={lobsterTwo as string}
          fontStyle="italic"
          variant="h5"
          letterSpacing="0.125em"
          color="black"
        >
          <strong>{topText}</strong>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="row"
        height={outerContainerHeight}
        width="100%"
        bgcolor="black"
        sx={{
          borderTop: filmBorder,
          borderBottom: filmBorder,
        }}
      >
        <LeftSlide borderWidth={borderWidth} borderRadius={borderRadius} urls={urls} />
        <Slide index={1} borderWidth={borderWidth} radius={innerFilmRadius} urls={urls} />
        <Slide index={2} borderWidth={borderWidth} radius={innerFilmRadius} urls={urls} />
        <Slide index={3} borderWidth={borderWidth} radius={innerFilmRadius} urls={urls} />
        <RightSlide borderWidth={borderWidth} borderRadius={borderRadius} urls={urls} />
      </Grid>
      <Grid item xs={12} height={35} maxHeight={35} textAlign="center">
        <Typography
          // variant="lobsterTwo"
          fontFamily={lobsterTwo as string}
          fontStyle="italic"
          variant="h6"
          letterSpacing="0.0625em"
          color="black"
        >
          <strong>{bottomText}</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

OurFilmHeading.whyDidYouRender = true;

export default OurFilmHeading;
// OurFrameHeader.propTypes = {
//   topText: PropTypes.string.isRequired,
//   bottomText: PropTypes.string.isRequired,
//   images: PropTypes.array.isRequired,
// };
