import OurTooltip from '../../components/misc/OurTooltip';
import { Path } from 'react-hook-form';
import { RecordType } from '@petconsole/pure-base';
import GoogleAddress, { GoogleAddressProps } from './components/GoogleAddress';

export interface AddressInputProps<T extends RecordType = RecordType>
  extends Omit<GoogleAddressProps<T>, 'id' | 'isTouched'> {
  id: string;
}

const AddressInput = <T extends RecordType = RecordType>({ tip, ...rest }: AddressInputProps<T>) => {
  const id = (rest.id || 'address') as Path<T>;

  // <Controller
  //   control={rest.form.control}
  //   name={id}
  //   // rules={ required, min, max, minLength, maxLength, pattern, validate }
  //   render={({
  //     field: { onChange, onBlur, value, ref /* , disabled */ },
  //     fieldState: { isTouched, error: fieldError /* invalid, isDirty, */ },
  //   }) => {
  //     const inputProps = { darkMode, onChange, onBlur, ref, isTouched, fieldError };
  //     const props = getInputProps({
  //       ...rest,
  //       ...inputProps,
  //       value: rest.value === undefined ? value || null : rest.value,
  //     }) as GoogleAddressProps;
  //
  //     return <GoogleAddress {...props} />;
  //   }}
  // />

  return (
    <OurTooltip tip={tip}>
      <GoogleAddress {...rest} id={id} />
    </OurTooltip>
  );
};

AddressInput.whyDidYouRender = true;

export default AddressInput;
