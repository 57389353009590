import { SliceChildProps } from '../../types';

const selectEntityByKey = (sliceChild: SliceChildProps, value: string) => {
  const { ids, entities } = sliceChild;

  if (!value) return undefined;

  const index = ids.indexOf(value);

  return index >= 0 ? entities[ids[index]] : undefined;
};

export default selectEntityByKey;
