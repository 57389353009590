// noinspection ES6PreferShortImport

import { MaybeNull, RecordType, MaybeString, nowToString } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity } from '../../types';
import { StripeTaxRateCodes } from './stripeTaxRateCode';

export interface StripeTaxRate extends CrudColumns {
  type: DataEntityType;
  stripeTaxRateId: string;
  stripeId: string;
  taxId: string;
  country: string; // ISO 3166
  province: string; // ISO 3166
  effectiveDate: MaybeString;
  taxRates: StripeTaxRateCodes;
}

export const stripeTaxRateEntity = entityAttributes({
  name: entityName.stripeTaxRate,
  attributes: {
    category: entityCategory.item,
    columns: { images: false, json: false },
    nameField: undefined,
    test: { invalidData: () => ({ effectiveDate: {} }), validData: () => ({ effectiveDate: nowToString() }) },
  },
});

const { arrayOf, date, id, strim } = schema;
export const stripeTaxRateSchema = entitySchema<StripeTaxRate>(stripeTaxRateEntity, {
  stripeId: strim(),
  taxId: id(true),
  country: strim(), // ISO 3166
  province: strim(), // ISO 3166
  effectiveDate: date(),
  taxRates: arrayOf({
    code: strim(),
    stripeTxrId: strim(),
  }).required(),
});

export const stripeTaxRateDefaults: Partial<StripeTaxRate> = {
  stripeId: '',
  taxId: '',
  country: '', // ISO 3166
  province: '', // ISO 3166
  effectiveDate: null,
  taxRates: [],
};

export interface StripeTaxRateRecord extends StripeTaxRate, RecordType {}

export type MaybeStripeTaxRateRecord = MaybeNull<StripeTaxRateRecord>;
export type StripeTaxRateRecords = StripeTaxRateRecord[];

export const initialStripeTaxRate: InitialEntity<StripeTaxRate, StripeTaxRateRecord> = (overrides = {}) =>
  initialEntity<StripeTaxRate>(stripeTaxRateEntity, { ...stripeTaxRateDefaults, ...overrides }) as StripeTaxRateRecord;
