import { createSlice } from '@reduxjs/toolkit';
import { setPropertyReducer } from '../../../helpers';
import slicePropertyHooks from '../../../helpers/slices/slicePropertyHooks';

const slice = {
  name: 'guestMenu',
};

const initialState = {
  guestMenuListingsMenuOpen: false,
  guestMenuGeneralMenuOpen: false,
  guestMenuSitesMenuOpen: false,
};

export const guestMenuSlice = createSlice({
  name: slice.name,
  initialState,
  reducers: {
    setGuestMenuListingsMenuOpen: setPropertyReducer('guestMenuListingsMenuOpen'),
    setGuestMenuGeneralMenuOpen: setPropertyReducer('guestMenuGeneralMenuOpen'),
    setGuestMenuSitesMenuOpen: setPropertyReducer('guestMenuSitesMenuOpen'),
  },
});

const { actions } = guestMenuSlice;

export const { useOurSelectGuestMenuListingsMenuOpen, useOurSetGuestMenuListingsMenuOpen } =
  slicePropertyHooks(slice.name, 'guestMenuListingsMenuOpen', actions);
export const { useOurSelectGuestMenuGeneralMenuOpen, useOurSetGuestMenuGeneralMenuOpen } =
  slicePropertyHooks(slice.name, 'guestMenuGeneralMenuOpen', actions);
export const { useOurSelectGuestMenuSitesMenuOpen, useOurSetGuestMenuSitesMenuOpen } =
  slicePropertyHooks(slice.name, 'guestMenuSitesMenuOpen', actions);

export default guestMenuSlice.reducer;
