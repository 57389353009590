import { pclServiceApiEntity } from '@petconsole/pure-fe-api';
import { PclServiceRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, getOne, getOneBy, updateOne } from '../../../helpers';

type ApiRecord = PclServiceRecord;

const pclServiceEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = pclServiceApiEntity;

  return {
    createPclService: createOne(builder, api, tag),
    getPclService: getOne(builder, api, tag),
    getPclServiceByServiceCode: getOneBy(builder, api.getByServiceCode, tag),
    updatePclService: updateOne(builder, api.update, tag),
  };
};

export default pclServiceEndpoints;
