import reduceFormikParts from './reduceFormikParts';
import { ExtendedFormik } from '@petconsole/pure-shared';

interface GetFormikHelperTextProps {
  formik?: ExtendedFormik;
  id?: string;
}

const getFormikHelperText = ({ formik, id }: GetFormikHelperTextProps) => {
  if (!formik || !id) return '';

  const { errors, touched } = formik;

  if (id.split('.').length === 1) return errors[id] && touched[id] ? errors[id] : '';

  const parts = id.split('.');
  const [error, touch] = reduceFormikParts({ parts, initial: [errors, touched] });

  return error && touch ? error : '';
};

export default getFormikHelperText;
