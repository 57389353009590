import { RecordType } from '@petconsole/pure-base';
import { cognitoPrefix } from '../constants';
import { CacheInstance } from '../types';

interface GetCognitoItemsProps {
  cache: CacheInstance;
}

const getCognitoItems = ({ cache }: GetCognitoItemsProps) => {
  // @ts-expect-error - storage isn't publicly exposed
  const storage = cache.storage;
  const keys = Object.keys(storage).filter((key) => key.startsWith(cognitoPrefix));

  return keys.reduce((prev, key) => ({ ...prev, [key]: storage[key] }), {} as RecordType);
};

export default getCognitoItems;
