import { mailMessageApiEntity } from '@petconsole/pure-fe-api';
import { writeReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick } from '../../../types';

const { api, name: sliceName, proper } = mailMessageApiEntity;
const typePrefix = `${sliceName}/read${proper}`;

export const readMailMessagePayloadCreator = async (messageId: string) => api.read(messageId);

export const readMailMessageThunk = appThunk(typePrefix, readMailMessagePayloadCreator);

export const readMailMessageReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(readMailMessageThunk, sliceName, adapter);
