import { Box, Typography, TypographyProps } from '@mui/material';
import { reactAppHtmlAppName, webPath } from '@petconsole/pure-shared';
import OurLink from '../../links/OurLink';
import OurTooltip from '../../misc/OurTooltip';

interface OurAppNameBoxProps {
  variant?: TypographyProps['variant'];
  lineHeight?: number;
}

const OurAppNameBox = ({ variant = 'h5', lineHeight = 6 }: OurAppNameBoxProps) => (
  <Box display="flex" flex={1} bgcolor={(theme) => theme.palette.primary.main} justifyContent="center">
    <OurLink to={webPath.home} underline="none">
      <OurTooltip tip="Home">
        <Typography
          variant={variant}
          component="span"
          noWrap
          color={(theme) => theme.palette.primary.contrastText}
          lineHeight={(theme) => theme.spacing(lineHeight as number)}
          sx={{ p: 0 }}
        >
          <strong>{reactAppHtmlAppName.toUpperCase()}</strong>
        </Typography>
      </OurTooltip>
    </OurLink>
  </Box>
);

OurAppNameBox.whyDidYouRender = true;

export default OurAppNameBox;
