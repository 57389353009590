import { NavigateBefore } from '@mui/icons-material';
import OurIconLink, { OurIconLinkProps } from './OurIconLink';

interface OurPrevLinkProps extends Omit<OurIconLinkProps, 'tip' | 'Icon'> {
  tip?: OurIconLinkProps['tip'];
  Icon?: OurIconLinkProps['Icon'];
}

const OurPrevLink = ({ tip = 'Previous', Icon = NavigateBefore, fontSize = 'large', ...rest }: OurPrevLinkProps) => (
  <OurIconLink tip={tip} Icon={Icon} fontSize={fontSize} {...rest} />
);

OurPrevLink.whyDidYouRender = true;

export default OurPrevLink;
