import { busSupplyApiEntity } from '@petconsole/pure-fe-api';
import { BusSupplyRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RecordType,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = BusSupplyRecord;

const apiEntity = busSupplyApiEntity;
const { name: busSupply, plural: busSupplies } = apiEntity;
const busSupplyAdmin = `${busSupply}Admin`;

const Tab = { busSupplyAdmin, busSupply, busSupplies } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  busSupplyAdmin: { default: '' },
  busSupply: { default: '' },
  busSupplies: { default: 'Newest' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, citySearchHooks, propertyHooks, ratingHooks, tabHooks } = sliceHookTypes;

export interface OurBusSupplyHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  busSupplyHooks: RequiredCrudSliverHooks;
  cityBusSupplyHooks: RequiredSliverHooks;
  newestBusSupplyHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourBusSupplyHooks: OurBusSupplyHooks = {
  entity: apiEntity,
  busSupplyHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityBusSupplyHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestBusSupplyHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  ratingHooks: ratingHooks(apiEntity, hooks),
  tabHooks: tabHooks(Object.keys(Tab), hooks) as TabHooks,
};

export default reducer;
