import { AuthUser } from 'aws-amplify/auth';
import { Log } from '@petconsole/pure-shared';
import authGetCurrentUser from './authGetCurrentUser';
import authSignOut from './authSignOut';

export interface AuthCheckProps {
  event?: string;
  isMember: () => Promise<boolean>;
  createMember: ({ email }: { email: string }) => Promise<boolean>;
  log: Log;
}

export type AuthCheck = (props: AuthCheckProps) => Promise<void>;

const authCheck: AuthCheck = async ({ event = '', isMember, createMember, log }) => {
  let authUser = undefined as AuthUser | undefined;
  let member = null;
  let action = 'getting';

  // console.log('authCheck event:', event);

  try {
    const { user, email } = await authGetCurrentUser();

    // If the user has been authenticated, are they a member?
    if (user) {
      authUser = user;

      member = await isMember();
    }

    // If we are authenticated, but not a member, create a new member
    if (user && !member && (event === 'signedIn' || event === 'loggingIn')) {
      action = 'creating';

      if (email) await createMember({ email });
    }
  } catch (e) {
    if (!authUser) {
      // It's normally ok if we don't have an authenticated user, but we need to ensure they are
      // not incorrectly assumed to be authenticated, so reset the profile state
      if (e !== 'No current user' && typeof e === 'string' && !e.includes('not authenticated'))
        await log(`authCheck caught unexpected error: ${e}.`);
      // if (e === 'No current user' || e.includes('not authenticated'))
      //   await dispatch(resetProfileState({}));
      // else await log(`authCheck caught unexpected error: ${e}.`);
    } else {
      const message = e instanceof Error ? e.message : 'unknown error';

      await log(`authCheck Error ${action} member: ${message}`);
      await authSignOut();
    }
  }
};

export default authCheck;
