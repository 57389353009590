import { RecordType } from '@petconsole/pure-base';
import { Api, EntityReaction } from '@petconsole/pure-shared';
import { ThunkApi } from '../../types';
import ourStateSliverData from '../state/ourStateSliverData';

export interface GetCreatorReactToEntityProps<T extends RecordType = RecordType> {
  sliceName: string;
  sliverName?: string;
  api: Api<T>;
  reaction?: EntityReaction;
}

export interface CreatorReactToEntityProps {
  id: string;
  reaction?: string;
}

export const getCreatorReactToEntity =
  <T extends RecordType = RecordType>({
    sliceName,
    sliverName,
    api,
    reaction: defaultReaction,
  }: GetCreatorReactToEntityProps<T>) =>
  async ({ id, reaction }: CreatorReactToEntityProps, { getState }: ThunkApi) => {
    const entity = ourStateSliverData(getState(), sliceName, sliverName).entities[id];
    const { updatedAt } = entity || {};

    return api.react(id, reaction || defaultReaction || '', updatedAt || undefined);
  };
