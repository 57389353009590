import { EntityName, ourEntityNameToType, ReactionRecord } from '@petconsole/pure-shared';
import { createAction } from '@reduxjs/toolkit';

interface OurSetEntityReactionCreatorProps {
  entityName: EntityName;
}

const ourSetEntityReactionCreator = ({ entityName }: OurSetEntityReactionCreatorProps) => {
  const type = ourEntityNameToType(entityName);

  return createAction<ReactionRecord>(`${entityName}/set${type}Reaction`);
};

export default ourSetEntityReactionCreator;