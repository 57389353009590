import { RemoveCircle } from '@mui/icons-material';
import { Icon } from '@petconsole/pure-shared';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurMinusButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  tip?: string;
  Icon?: Icon;
}

const OurMinusButton = ({ tip = '', Icon = RemoveCircle, ...rest }: OurMinusButtonProps) => (
  <OurIconButton {...rest} primaryLight tip={tip} Icon={Icon}  />
);

OurMinusButton.whyDidYouRender = true;

export default OurMinusButton;
