import { skipToken } from '@reduxjs/toolkit/query';
import { stripeAccountApiEntity } from '@petconsole/pure-fe-api';
import { StripeAccountRecord } from '@petconsole/pure-shared';
import {
  useCreateStripeAccountMutation,
  useGetStripeAccountByShopQuery,
  useGetStripeAccountQuery,
  useUpdateStripeAccountMutation,
  useUpdateStripeAccountStatusMutation,
} from '../../../apiSlice';
import useCreateEntity from '../../../helpers/useCreateEntity';
import usePatchIdEntity from '../../../helpers/usePatchIdEntity';
import useUpdateEntity from '../../../helpers/useUpdateEntity';

const apiEntity = stripeAccountApiEntity;
type ApiRecord = StripeAccountRecord;

export const useCreateStripeAccount = () =>
  useCreateEntity<ApiRecord>({ apiEntity, mutationHook: useCreateStripeAccountMutation });
export const useGetStripeAccount = ({ stripeAccountId }: { stripeAccountId: string }) =>
  useGetStripeAccountQuery(stripeAccountId || skipToken);
export const useGetStripeAccountByShop = ({ shopId }: { shopId: string }) =>
  useGetStripeAccountByShopQuery(shopId || skipToken);
export const useUpdateStripeAccount = () =>
  useUpdateEntity<ApiRecord>({ apiEntity, mutationHook: useUpdateStripeAccountMutation });
export const useUpdateStripeStatus = () =>
  usePatchIdEntity<ApiRecord>({ apiEntity, mutationHook: useUpdateStripeAccountStatusMutation });
