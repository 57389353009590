// noinspection ES6PreferShortImport

import { MaybeNull, MaybeString, newId, RecordType } from '@petconsole/pure-base';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { DataEntityType, InitialEntity, MemberColumns, TimeColumns } from '../../../types';

export interface MailHeader extends MemberColumns, TimeColumns {
  type: DataEntityType;
  messageId: string;
  senderFolderId: string;
  recipientFolderId: string;
  toMemberId: string;
  toMemberName: string;
  subject: string;
  attachments: string[];
  sentAt: MaybeString;
  readAt: MaybeString;
  reportedAt: MaybeString;
}

export const mailHeaderEntity = entityAttributes({
  name: entityName.mailHeader,
  attributes: {
    category: entityCategory.item,
    columns: { crud: false, images: false, member: true, time: true },
    idName: 'messageId',
    loadLimit: 25,
    nameField: undefined,
    test: { invalidData: () => ({ subject: {} }), validData: () => ({ subject: 'New subject' }) },
  },
});

const { array, date, id, memberName, strim } = schema;
export const mailHeaderSchema = entitySchema<MailHeader>(mailHeaderEntity, {
  messageId: id(true),
  senderFolderId: id(),
  recipientFolderId: id(),
  // threadId: id(), ??
  toMemberId: id(true),
  toMemberName: memberName(true).required('Member name is required'),
  subject: strim().required('Subject is required'),
  attachments: array(),
  sentAt: date(),
  readAt: date(),
  reportedAt: date(),
});

export const mailHeaderDefaults: Partial<MailHeader> = {
  messageId: newId(),
  senderFolderId: '',
  recipientFolderId: '',
  toMemberId: '',
  toMemberName: '',
  subject: '',
  attachments: [],
  sentAt: null,
  readAt: null,
  reportedAt: null,
};

export interface MailHeaderRecord extends MailHeader, RecordType {}

export type MaybeMailHeaderRecord = MaybeNull<MailHeaderRecord>;
export type MailHeaderRecords = MailHeaderRecord[];

export const initialMailHeader: InitialEntity<MailHeader, MailHeaderRecord> = (overrides = {}) =>
  initialEntity<MailHeader>(mailHeaderEntity, { ...mailHeaderDefaults, ...overrides }) as MailHeaderRecord;
