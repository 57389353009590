import { RecordType } from '@petconsole/pure-base';
import { Api, MailMessageRecord, mailMessageEntity, ApiEntity } from '@petconsole/pure-shared';
import { commonApi, fullPath, get, optionalParam, post } from '../common';

type ApiRecord = MailMessageRecord;

const apiPath = '/mail/messages';

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  move: (messageId: string, folderId: string) => Promise<T>;
  read: (messageId: string) => Promise<T>;
  remove: (messageId: string, sent: boolean) => Promise<T>;
  trash: (messageId: string) => Promise<T>;
}

export type MailMessageApi = Api<ApiRecord> & CustomApi;

export const mailMessageApi: MailMessageApi = {
  ...commonApi<ApiRecord>({ apiPath }),
  move: (messageId, folderId) =>
    post(`${apiPath}/move/${messageId}`, { recipientFolderId: folderId } as MailMessageRecord),
  read: (messageId) => get(`${apiPath}/read/${messageId}`) as Promise<MailMessageRecord>,
  remove: (messageId, sent) => post(fullPath(`${apiPath}/remove/${messageId}`, optionalParam('sent', sent))),
  trash: (messageId) => post(`${apiPath}/trash/${messageId}`),
};

export const mailMessageApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...mailMessageEntity, api: mailMessageApi };
