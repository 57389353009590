import { useEffect } from 'react';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { useOurNavigate } from '@petconsole/pure-fe-utils';
import { webPath } from '@petconsole/pure-shared';

interface UseOurEntityAccessCheckProps {
  ownerId?: string;
  adminOk?: boolean;
}

const useOurEntityAccessCheck = ({ ownerId, adminOk = true }: UseOurEntityAccessCheckProps) => {
  const { navigate, pathname} = useOurNavigate();
  const { memberId } = useCurrentMember();

  useEffect(() => {
    if (!ownerId || ownerId === memberId) return;
    if (adminOk && pathname.startsWith('/admin/')) return;

    navigate(webPath.notFound);
  }, [ownerId, memberId, adminOk, pathname, navigate]);
};

export default useOurEntityAccessCheck;
