// noinspection ES6PreferShortImport

import { MaybeNull, nowToString, RecordType } from '@petconsole/pure-base';
import { taxTypes } from '../../constants';
import entityAttributes from '../../entity/entityAttributes';
import { entityCategory, entityName } from '../../entity/constants';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity } from '../../types';
import { TaxRates } from './taxRate';

export interface Tax extends CrudColumns {
  type: DataEntityType;
  taxId: string;
  country: string;
  province: string;
  effectiveDate: string;
  taxes: TaxRates;
  json: RecordType;
}

export const taxEntity = entityAttributes({
  name: entityName.tax,
  attributes: {
    category: entityCategory.shop,
    columns: { images: false },
    memberOwned: true,
    nameField: undefined,
    plural: 'taxes',
    subscribable: true,
    test: { invalidData: () => ({ effectiveDate: {} }), validData: () => ({ effectiveDate: nowToString() }) },
  },
});

const { arrayOf, boolean, date, number, string } = schema;
export const taxSchema = entitySchema<Tax>(taxEntity, {
  country: string(), // ISO 3166
  province: string(), // ISO 3166
  effectiveDate: date(),
  taxes: arrayOf({
    type: string().oneOf(taxTypes),
    code: string().uppercase(),
    rate: number(), // Percentage, ie: 0.05 = 5%
    description: string(),
    taxShipping: boolean(true),
  }).required(),
});

export const taxDefaults: Partial<Tax> = {
  country: '',
  province: '',
  effectiveDate: '',
  taxes: [],
};

export interface TaxRecord extends Tax, RecordType {}

export type MaybeTaxRecord = MaybeNull<TaxRecord>;
export type TaxRecords = TaxRecord[];

export const initialTax: InitialEntity<Tax, TaxRecord> = (overrides = {}) =>
  initialEntity<Tax>(taxEntity, { ...taxDefaults, ...overrides }) as TaxRecord;
