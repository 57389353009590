import { RecordType } from '@petconsole/pure-base';
import { memberApiEntity, notificationApiEntity } from '@petconsole/pure-fe-api';
import { GetListResults, NotificationRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../helpers';
import { BaseSliceOptions, FetchesByHook, OurEntityHooks, RequiredCrudSliverHooks } from '../../types';
import BaseEntitySlice from '../base/BaseEntitySlice';

type ApiEntityRecord = NotificationRecord;

const apiEntity = notificationApiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  canDelete: true,
  comparer: ({ createdAt: a }: NotificationRecord, { createdAt: b }: NotificationRecord) => b?.localeCompare(a),
  tabs: undefined,
  fetchManyByProperty: {
    propertyName: 'memberId',
    apiMethod: 'getListByMember',
    callback: (data) => {
      const { items, nextKey } = data as GetListResults;

      if (!nextKey && items.length > 0) memberApiEntity.api.updateNotifications().then();
    },
  },
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks } = sliceHookTypes;

export interface OurNotificationHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  notificationHooks: RequiredCrudSliverHooks;
  customHooks: {
    byMemberId: {
      useFetches: FetchesByHook<NotificationRecord>;
    };
  };
}

export const ourNotificationHooks: OurNotificationHooks = {
  entity: apiEntity,
  notificationHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  customHooks: {
    byMemberId: {
      useFetches:
        hooks.useOurFetchNotificationsByMemberId as OurNotificationHooks['customHooks']['byMemberId']['useFetches'],
    },
  },
};

export default reducer;
