import { EntityRecord } from '@petconsole/pure-shared';
import { createAppSelector } from '../../store/storeHooks';
import ourStateSliverData from '../state/ourStateSliverData';
import selectsByPropertyNameAndValueOuterSelector from './selectsByPropertyNameAndValueOuterSelector';

interface SelectsByPropertyNameAndValueProps<V> {
  sliceName: string;
  sliverName: string;
  propertyName: string;
  value: V;
}

const selectsByPropertyNameAndValue = <V>({
  sliceName,
  sliverName,
  propertyName,
  value,
}: SelectsByPropertyNameAndValueProps<V>) => {
  const outerSelector = selectsByPropertyNameAndValueOuterSelector({ propertyName, value });

  return createAppSelector(
    [(state) => ourStateSliverData<EntityRecord>(state, sliceName, sliverName)],
    outerSelector,
  );
};

export default selectsByPropertyNameAndValue;
