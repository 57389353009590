import { RecordType, properCase } from '@petconsole/pure-base';
import { topicApiEntity } from '@petconsole/pure-fe-api';
import { TopicRecord } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchValue } from '../../../helpers';
import { payloadCreatorWithFetch } from '../../../helpers/payloadCreators';
import {
  BaseSliceOptions,
  FetchByHook,
  OurEntityHooks,
  ReactionHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
} from '../../../types';
import { BaseEntitySlice } from '../../base';
import { byForumTopicsByForumReducers, byForumTopicsByForumThunk } from './byForumTopicsByForum';

// TODO: Do we really need the fetch when getting a list on a secondary sliver?
//  Doesn't a fetch happen to display the card?

type ApiEntityRecord = TopicRecord;

const apiEntity = topicApiEntity;
const { api, name: sliceName, idName } = apiEntity;
const byForum = `byForum`;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasReaction: true,
  canDelete: true,
  sortProperty: 'order',
  tabs: undefined,
  slivers: [
    {
      prefix: byForum,
      payloadCreator: payloadCreatorWithFetch(idName, api),
      extraReducers: [byForumTopicsByForumReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ sliceName, api, options });
const { crudHooks, sliverHooks, reactionHooks } = sliceHookTypes;

export interface OurTopicHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  topicHooks: RequiredCrudSliverHooks;
  byForumTopicHooks: RequiredSliverHooks;
  reactionHooks: ReactionHooks;
  customHooks: {
    useFetchesByForum: FetchByHook;
  };
}

export const ourTopicHooks: OurTopicHooks = {
  entity: apiEntity,
  topicHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  byForumTopicHooks: sliverHooks(apiEntity, properCase(byForum), hooks) as RequiredSliverHooks,
  reactionHooks: reactionHooks(apiEntity, hooks),
  customHooks: {
    useFetchesByForum: () => useOurDispatchValue(byForumTopicsByForumThunk),
  },
};

export default reducer;
