import { RecordType } from '@petconsole/pure-base';

const removeProperties = <T extends RecordType = RecordType>(input: T, properties: string[]) => {
  const output = { ...input } as T;

  properties.forEach((property) => delete output[property]);

  return output;
};

export default removeProperties;