import { MouseEventHandler } from 'react';
import { ZoomIn } from '@mui/icons-material';
import OurButton, { OurButtonProps } from './OurButton';

interface OurZoomInTextButtonProps extends Omit<OurButtonProps, 'onClick'> {
  onClick: MouseEventHandler;
}

const OurZoomInTextButton = ({ label = 'Zoom In', ...rest }: OurZoomInTextButtonProps) => (
  <OurButton {...rest} label={label} startIcon={<ZoomIn />} fullWidth={false} disableRipple disableTouchRipple disableFocusRipple />
);

export default OurZoomInTextButton;
