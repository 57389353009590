import { reactAppCommonMediaPath, reactAppMediaPath, reactAppMemberImagePath } from '../constants';

export const webPath = {
  adminBusBuilders: '/admin/bus-builders',
  adminBuses: '/admin/buses',
  adminBusinesses: '/admin/businesses',
  adminBusRepairs: '/admin/bus-repairs',
  adminBusSupplies: '/admin/bus-supplies',
  adminDogParks: '/admin/dog-parks',
  adminForums: '/admin/forums',
  adminFoundPets: '/admin/found-pets',
  adminGroomers: '/admin/groomers',
  adminLostPets: '/admin/lost-pets',
  adminMembers: '/admin/members',
  adminOrders: '/admin/orders',
  adminPetFoods: '/admin/pet-foods',
  adminPets: '/admin/pets',
  adminPetTrainers: '/admin/pet-trainers',
  adminPetTypes: '/admin/pet-types',
  adminPetWalkers: '/admin/pet-walkers',
  adminPetWorkplaces: '/admin/pet-workplaces',
  adminPlaces: '/admin/places',
  adminShops: '/admin/shops',
  adminSitters: '/admin/sitters',
  adminVanBuilders: '/admin/van-builders',
  adminVanRepairs: '/admin/van-repairs',
  adminVans: '/admin/vans',
  adminVanSupplies: '/admin/van-supplies',
  adminVets: '/admin/vets',
  about: '/misc/about',
  blog: '/blog',
  busBuilders: '/list/bus-builders',
  busConsole: '/site/busConsole',
  buses: '/list/buses',
  businesses: '/list/businesses',
  busRepairs: '/list/bus-repairs',
  busSupplies: '/list/bus-supplies',
  changeSite: '/changeSite',
  commonMedia: reactAppCommonMediaPath,
  contact: '/misc/contact',
  credits: '/misc/credits',
  dogParks: '/list/dog-parks',
  faq: '/misc/faq',
  features: '/misc/features',
  feed: '/feed',
  forgotPassword: '/auth/forgot-password',
  forum: '/forum',
  forumPost: '/forum/post',
  forumTopic: '/forum/topic',
  foundPets: '/list/found-pets',
  groomers: '/list/groomers',
  home: '/',
  join: '/auth/join',
  login: '/auth/login',
  logout: '/auth/logout',
  lostPets: '/list/lost-pets',
  mailFolders: '/mail/folders',
  mailMessage: '/mail/message',
  mailMessages: '/mail/messages',
  mailNewMessage: '/mail/messages/new',
  media: reactAppMediaPath,
  memberImage: reactAppMemberImagePath,
  members: '/list/members',
  myBuses: '/my/buses',
  myFriends: '/my/friends',
  myNotifications: '/my/notifications',
  myOrders: '/my/orders',
  myPets: '/my/pets',
  myShop: '/my/shop',
  myShopBilling: '/my/shop/billing',
  myShopCustomers: '/my/shop/customers',
  myShopItem: '/my/shop/item',
  myShopItems: '/my/shop/items',
  myShopOrder: '/my/shop/order',
  myShopOrders: '/my/shop/orders',
  myShopPayments: '/my/shop/payments',
  myShopReports: '/my/shop/reports',
  myShopReturns: '/my/shop/returns',
  myShopRewards: '/my/shop/rewards',
  myShops: '/my/shops',
  myShopSettings: '/my/shop/settings',
  myShopShipping: '/my/shop/shipping',
  myShopShop: '/my/shop/shop',
  myShopStaff: '/my/shop/staff',
  myShopStripe: '/my/shop/stripe',
  myShopVariantTemplate: '/my/shop/variant/template',
  myShopVariantTemplates: '/my/shop/variant/templates',
  myVans: '/my/vans',
  notFound: '/misc/notFound',
  pclHeaders: '/pcl/headers',
  pclServices: '/pcl/services',
  pclTierPrices: '/misc/pricing/tier/prices',
  pclTierSubscribe: '/misc/pricing/tier/subscribe',
  petConsole: '/site/petConsole',
  petFoods: '/list/pet-foods',
  pets: '/list/pets',
  petTrainers: '/list/pet-trainers',
  petWalkers: '/list/pet-walkers',
  petWorkplaces: '/list/pet-workplaces',
  places: '/list/places',
  pricing: '/misc/pricing',
  privacy: '/misc/privacy',
  profile: '/my/profile',
  profileWizard: '/my/profile-wizard',
  resetPassword: '/auth/reset-password',
  shops: '/list/shops',
  site: '/site',
  sitters: '/list/sitters',
  team: '/misc/team',
  terms: '/misc/terms',
  vanBuilders: '/list/van-builders',
  vanConsole: '/site/vanConsole',
  vanRepairs: '/list/van-repairs',
  vans: '/list/vans',
  vanSupplies: '/list/van-supplies',
  verifyCode: '/auth/verify-code',
  vets: '/list/vets',
  welcome: '/misc/welcome',
} as const;
