import { RecordType } from '@petconsole/pure-base';
import { Api, ApiEntity, GetListOptions, GetListResults, postEntity, PostRecord } from '@petconsole/pure-shared';
import { commonApi, pathParam } from './common';

type ApiRecord = PostRecord;

const apiPath = '/posts';
const { getListWith, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getListByTopicAndThreadAndFeedBetween: (
    topicId: string,
    threadId?: string,
    startDate?: string,
    endDate?: string,
    options?: GetListOptions,
  ) => Promise<GetListResults<T>>;
}

export type PostApi = Api<ApiRecord> & CustomApi;

export const postApi: PostApi = {
  ...common,
  getListWith,
  getListByTopicAndThreadAndFeedBetween: (topicId, threadId, startDate, endDate, options) =>
    getListWith(
      [
        pathParam('topicId', topicId),
        pathParam('threadId', threadId || ''),
        pathParam('startDate', startDate || ''),
        pathParam('endDate', endDate || ''),
      ].join(''),
      options,
    ),
};

export const postApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...postEntity, api: postApi };
