import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  GetListOptions,
  GetListResults,
  ShopItemVariantRecord,
  shopItemVariantEntity,
  ApiEntity,
} from '@petconsole/pure-shared';
import { commonApi, post } from './common';

type ApiRecord = ShopItemVariantRecord;

const apiPath = '/shopItems/variants';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getListByShopItem: (shopItemId: string, options?: GetListOptions) => Promise<GetListResults<T>>;
  getListByShopItemAndIsAvailable: (shopItemId: string, options?: GetListOptions) => Promise<GetListResults<T>>;
  upsertList: (shopItemId: string, variants: RecordType[], replace?: boolean) => Promise<RecordType[]>;
}

export type ShopItemVariantApi = Api<ApiRecord> & CustomApi;

export const shopItemVariantApi: ShopItemVariantApi = {
  ...common,
  getListByValue,
  getListByShopItem: (shopItemId, options) => getListByValue('shopItemId', shopItemId, options),
  getListByShopItemAndIsAvailable: (shopItemId, options) =>
    getListByValue('shopItemId', shopItemId, { ...options, all: true }),
  upsertList: (shopItemId, variants, replace = true) =>
    post(`${apiPath}/upsertList`, { shopItemId, variants, replace }) as unknown as Promise<RecordType[]>,
};

export const shopItemVariantApiEntity: ApiEntity<ApiRecord, CustomApi> = {
  ...shopItemVariantEntity,
  api: shopItemVariantApi,
};
