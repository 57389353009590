import { properCase } from '@petconsole/pure-base';
import { topicApiEntity } from '@petconsole/pure-fe-api';
import { fetchParams, fetchReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick, RecordType } from '../../../types';

const { api, name: sliceName, proper } = topicApiEntity;
const byForumName = `byForum${proper}`;

export const byForumTopicsByForumPayloadCreators = async (forumId: string, { getState }: RecordType) =>
  api.getListByForum(forumId, fetchParams(getState, sliceName, byForumName));

export const byForumTopicsByForumThunk = appThunk(
  `${sliceName}/fetch${properCase(byForumName)}sByForum`,
  byForumTopicsByForumPayloadCreators,
);

export const byForumTopicsByForumReducers = (adapter: EntityAdapterWithPick) =>
  fetchReducers(byForumTopicsByForumThunk, byForumName, adapter);
