import { useCallback } from 'react';
import { useOurCreateMemberByEmail } from '@petconsole/pure-fe-state';

const useCreateMember = () => {
  const createMemberByEmail = useOurCreateMemberByEmail();

  const createMember = useCallback(
    async ({ email }: { email: string }) => !!(await createMemberByEmail({ email }).unwrap()),
    [createMemberByEmail],
  );

  return { createMember };
};

export default useCreateMember;
