// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { ourUomQuantity } from '../../../constants';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import {
  CrudColumns,
  DataEntityType,
  InitialEntity,
  MaybeImage,
  OurUomQuantity,
} from '../../../types';

export interface CartItem extends CrudColumns {
  type: DataEntityType;
  cartItemId: string;
  cartId: string;
  memberId: string;
  shopId: string;
  shopItemId: string;
  shopItemVariantId: string;
  name: string;
  variantDescription: string;
  sku: string;
  thumbnail: MaybeImage;
  quantity: number;
  quantityUom: OurUomQuantity;
  price: number;
  shipRateSet: boolean;
  baseShipRate: number;
  itemShipRate: number;
  json: RecordType;
}

export const cartItemEntity = entityAttributes({
  name: entityName.cartItem,
  attributes: {
    category: entityCategory.item,
    columns: {images: false},
  },
});

const {boolean, id, image, json, number, strim} = schema;
export const cartItemSchema = entitySchema<CartItem>(cartItemEntity, {
  cartId: id(true),
  memberId: id(true),
  shopId: id(true),
  shopItemId: id(true),
  shopItemVariantId: id(),
  name: strim().required(),
  variantDescription: strim(),
  sku: strim(),
  thumbnail: image(),
  quantity: number(),
  quantityUom: strim(),
  price: number(),
  shipRateSet: boolean(),
  baseShipRate: number(),
  itemShipRate: number(),
  json: json(),
});

export const cartItemDefaults: Partial<CartItem> = {
  cartId: '',
  memberId: '',
  shopId: '',
  shopItemId: '',
  shopItemVariantId: '',
  name: '',
  variantDescription: '',
  sku: '',
  thumbnail: null,
  quantity: 0,
  quantityUom: ourUomQuantity.EA,
  price: 0,
  shipRateSet: false,
  baseShipRate: 0,
  itemShipRate: 0,
  json: {},
};

export interface CartItemRecord extends CartItem, RecordType {
}

export type MaybeCartItemRecord = MaybeNull<CartItemRecord>;
export type CartItemRecords = CartItemRecord[];

export const initialCartItem: InitialEntity<CartItem, CartItemRecord> = (overrides = {}) =>
  initialEntity<CartItem>(cartItemEntity, {...cartItemDefaults, ...overrides}) as CartItemRecord;
