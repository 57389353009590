import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { Api, MaybeTaxRecord, TaxRecord, taxEntity, ApiEntity } from '@petconsole/pure-shared';
import { commonApi, fullPath, get, pathParam } from './common';

type ApiRecord = TaxRecord;

const apiPath = '/taxes';

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getTaxByCountryAndProvince: (country: string, province: string) => Promise<MaybeNull<T>>;
}

export type TaxApi = Api<ApiRecord> & CustomApi;

export const taxApi: TaxApi = {
  ...commonApi<ApiRecord>({ apiPath }),
  getTaxByCountryAndProvince: (country, province) =>
    get(
      fullPath(`${apiPath}/getBy`, `${pathParam('country', country)}${pathParam('province', province)}`),
    ) as Promise<MaybeTaxRecord>,
};

export const taxApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...taxEntity, api: taxApi };
