// noinspection ES6PreferShortImport

import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { MaybeString, RecordType } from '@petconsole/pure-base';
import { OurFetchGrid } from '../components/grids';

export interface UseOurFetchStoreGridProps {
  entity: string;
  entityId?: string;
  activity?: string;
  hasMore: boolean;
  readError: MaybeString;
  readStatus: string;
  reset: (props?: unknown) => unknown;
  fetch: (props?: unknown) => unknown;
  rows: GridValidRowModel[];
  ids?: string[];
  columns: GridColDef<GridValidRowModel>[];
  clickPath?: string;
  openPath?: string;
  resetMinutes?: number;
  initialState?: RecordType;
  gridToolbar?: boolean;
}

const useOurFetchStoreGrid =
  ({
    entity,
    entityId,
    activity,
    hasMore,
    readError,
    readStatus,
    reset,
    fetch,
    rows,
    ids,
    columns,
    clickPath,
    openPath,
    resetMinutes = 0,
    initialState,
    gridToolbar,
  }: UseOurFetchStoreGridProps) =>
  () =>
    (
      <OurFetchGrid
        entity={entity}
        entityId={entityId}
        activity={activity}
        hasMore={hasMore}
        readError={readError}
        readStatus={readStatus}
        reset={reset}
        fetch={fetch}
        rows={rows}
        ids={ids}
        columns={columns}
        clickPath={clickPath ? [clickPath, '{id}'].join('/$') : ''}
        openPath={openPath ? [openPath, '{id}'].join('/$') : ''}
        resetMinutes={resetMinutes}
        initialState={initialState}
        gridToolbar={gridToolbar}
      />
    );

export default useOurFetchStoreGrid;
