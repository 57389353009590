import { useCallback, useRef } from 'react';
import { authCheck } from '@petconsole/pure-fe-amplify';
import useOurError from '../error/useOurError';
import useCreateMember from './helpers/useCreateMember';
import useIsMember from './helpers/useIsMember';

export interface OurAuthCheckProps {
  event?: string;
}

const useOurAuthCheck = () => {
  const { log } = useOurError();
  const { isMember } = useIsMember();
  const { createMember } = useCreateMember();

  const checking = useRef(false);

  const ourAuthCheck = useCallback(async ({ event = '' }: OurAuthCheckProps = {}) => {
    if (checking.current) return new Promise(() => { return }) as ReturnType<typeof authCheck>;

    checking.current = true;

    return authCheck({ event, isMember, createMember, log }).finally(() => (checking.current = false));
  }, [isMember, createMember, log]);

  return { authCheck: ourAuthCheck };
};

export default useOurAuthCheck;
