import { cartApiEntity } from '@petconsole/pure-fe-api';
import { CartRecord } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchValue, useOurSelectById } from '../../../helpers';
import {
  BaseSliceOptions,
  FetchByHook,
  OurEntityHooks,
  RecordType,
  RequiredCrudSliverHooks,
  SelectByHook,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { cartByShopThunk, selectCartByShop } from './cartByShop';

type ApiEntityRecord = CartRecord;

const apiEntity = cartApiEntity;
const { api, name: sliceName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  sortProperty: 'shopName',
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      fetchByValue: true,
      payloadCreator: async (shopId: string) => api.getByShop(shopId),
      // extraReducers: [saveCartReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks } = sliceHookTypes;

export interface OurCartHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  cartHooks: RequiredCrudSliverHooks;
  customHooks: {
    byShop: {
      useFetch: FetchByHook;
      useSelect: SelectByHook;
    };
  };
}

export const ourCartHooks: OurCartHooks = {
  entity: apiEntity,
  cartHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  customHooks: {
    byShop: {
      useFetch: () => useOurDispatchValue(cartByShopThunk),
      useSelect: (id: string) => useOurSelectById(selectCartByShop, id),
    },
  },
};

export default reducer;
