import { NavigateBefore } from '@mui/icons-material';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurPrevButton extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  tip?: OurIconButtonProps['tip'];
  Icon?: OurIconButtonProps['Icon'];
}

const OurPrevButton = ({ tip = 'Previous', Icon = NavigateBefore, fontSize = 'large', ...rest}: OurPrevButton) => (
  <OurIconButton tip={tip} Icon={Icon} fontSize={fontSize} {...rest} />
);

OurPrevButton.whyDidYouRender = true;

export default OurPrevButton;
