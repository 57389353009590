import { useEffect } from 'react';
import { Path, PathValue } from 'react-hook-form';
import { RecordType } from '@petconsole/pure-base';
import { UseMaintDirtyProps } from '../types';

const useMaintDirty = <T extends RecordType = RecordType>({
  form,
  isEditing,
  addressInput,
  initialAddress,
  avatar,
  avatarChanged,
  banner,
  initialBanner,
  images,
  initialImages,
}: UseMaintDirtyProps<T>) => {
  const { formState, resetField, setValue } = form;
  const { dirtyFields } = formState;

  // Manage dirty for address
  useEffect(() => {
    if (!isEditing) return;

    if (addressInput !== initialAddress) {
      setValue('address' as Path<T>, addressInput as PathValue<T, Path<T>>, { shouldDirty: true });
    } else if ((dirtyFields as RecordType)?.address) {
      resetField('address' as Path<T>);
    }
  }, [isEditing, addressInput, initialAddress, dirtyFields, setValue, resetField]);

  // Manage dirty for avatar
  useEffect(() => {
    if (!isEditing) return;

    if (avatarChanged && !(dirtyFields as RecordType)?.avatar) {
      // const value = form.getValues('avatar' as Path<T>);

      setValue('avatar' as Path<T>, avatar as PathValue<T, Path<T>>, { shouldDirty: true });
    }
  }, [isEditing, avatar, avatarChanged, dirtyFields, setValue]);

  // Manage dirty for banner
  useEffect(() => {
    if (!isEditing) return;

    if (JSON.stringify(banner) !== JSON.stringify(initialBanner)) {
      setValue('banner' as Path<T>, banner as PathValue<T, Path<T>>, { shouldDirty: true });
    } else if ((dirtyFields as RecordType)?.banner) {
      resetField('banner' as Path<T>);
    }
  }, [isEditing, banner, initialBanner, dirtyFields, setValue, resetField]);

  // Manage dirty for photos
  useEffect(() => {
    if (!isEditing) return;

    if (JSON.stringify(images) !== JSON.stringify(initialImages)) {
      setValue('images' as Path<T>, images as PathValue<T, Path<T>>, { shouldDirty: true });
    } else if ((dirtyFields as RecordType)?.images) {
      resetField('images' as Path<T>);
    }
  }, [isEditing, images, initialImages, dirtyFields, setValue, resetField]);

  return;
};

export default useMaintDirty;
