import { Grid } from '@mui/material';
import { newId } from '@petconsole/pure-base';
import { EditMessages } from '@petconsole/pure-shared';
import OutlinedGrid from '../../outlinedGrid/OutlinedGrid';
import OurEditMessage from './OurEditMessage';

interface OurEditMessagesProps {
  messages?: EditMessages;
}

const OurEditMessages = ({ messages = [] }: OurEditMessagesProps) => {
  if (messages.length < 1) return null;

  return (
    <OutlinedGrid id="edit-messages" label="Messages">
      <Grid item xs={12}>
        {messages.map((editMessage) => (
          <OurEditMessage key={newId()} {...editMessage} />
        ))}
      </Grid>
    </OutlinedGrid>
  );
};

OurEditMessages.whyDidYouRender = true;

export default OurEditMessages;
