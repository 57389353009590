// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite, visibilityType } from '../../constants';
import { entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface PetPhoto extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  petPhotoId: string;
  petId: string;
  order: string;
  memberId: string;
  caption: string;
  path: string;
  contentType: string;
  likes: number;
  likesHex: string;
  likesOrder: string;
  visibility: string;
}

const { General, Comments, Preview } = entityTabName;
export const petPhotoEntity = entityAttributes({
  name: entityName.petPhoto,
  attributes: {
    site: anySite.petConsole,
    columns: { crud: false, images: false, time: true },
    tabs: [General, Comments, Preview],
    test: { invalidData: () => ({ caption: {} }), validData: () => ({ caption: 'New caption' }) },
  },
});

const { id, number, oneOf, strim } = schema;
export const petPhotoSchema = entitySchema<PetPhoto>(petPhotoEntity, {
  petId: id(true),
  order: strim(),
  memberId: id(true),
  caption: strim(),
  path: strim().required(),
  contentType: strim(),
  likes: number(),
  likesHex: strim(),
  likesOrder: strim(),
  visibility: oneOf(visibilityType, visibilityType.All),
});

export const petPhotoDefaults: Partial<PetPhoto> = {
  petId: '',
  order: '',
  memberId: '',
  caption: '',
  path: '',
  contentType: '',
  likes: 0,
  likesHex: '',
  likesOrder: '',
  visibility: visibilityType.All,
};

export interface PetPhotoRecord extends PetPhoto, RecordType {}

export type MaybePetPhotoRecord = MaybeNull<PetPhotoRecord>;
export type PetPhotoRecords = PetPhotoRecord[];

export const initialPetPhoto: InitialEntity<PetPhoto, PetPhotoRecord> = (overrides = {}) =>
  initialEntity<PetPhoto>(petPhotoEntity, { ...petPhotoDefaults, ...overrides }) as PetPhotoRecord;
