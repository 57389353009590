// noinspection ES6PreferShortImport

import { MouseEventHandler } from 'react';
import { SxProps } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import {
  entityTabName,
  EntityTabName,
  entityTabNames,
  ourArrayToEntries,
  RecordOfEntityTabElements,
  RecordOfEntityTabNames,
  RecordOfEntityTabProps,
} from '@petconsole/pure-shared';
import OurOutlinedComments from '../../comment/OurOutlinedComments';
import useEntityCardReaction, {
  EntityCardReaction,
} from '../../components/cards/ourEntityCards/helpers/useEntityCardReaction';
import OurBannerZone from '../../components/misc/OurBannerZone';
import { ourDefaultMaxCardWidth } from '../../constants';
import ListEntityDetails from '../../entity/components/ListEntityDetails';
import OurListEntityGeneral from '../../entity/OurListEntityGeneral';
import AvatarViewEdit from '../../inputs/avatar/AvatarViewEdit';
import MaintPhotos from '../MaintPhotos';
import MaintPreview from '../MaintPreview';
import { UseMaintTabDefinitionsProps } from '../types';

const useMaintTabDefinitions = <T extends RecordType = RecordType>({
  entity,
  hooks,
  id,
  form,
  data,
  readOnly,
  canEdit,
  isEditing,
  isOwner,
  isGuest,
  tabNames: customTabNames,
  tabElements: customTabElements,
  tabDefinitions,
  tabProps: customTabProps,
  reRead,
  avatar,
  setAvatar,
  setAvatarChanged,
  setAvatarEditor,
  banner: maybeBanner,
  setBanner,
  images: maybeImages,
  Photos: maybePhotos,
}: UseMaintTabDefinitionsProps<T>) => {
  // Reaction
  const { reactionHooks } = hooks;
  const { reaction } = useEntityCardReaction<T>({ reactionHooks, cardId: id, entityRecord: data });

  // Tab props
  const baseProps = { entity, form, isEditing, isOwner, readOnly };
  const banner = maybeBanner ?? undefined;

  const tabProps: RecordOfEntityTabProps = {
    Avatar: { avatar, setAvatar, setChanged: setAvatarChanged, setEditor: setAvatarEditor, heading: '' },
    Banner: { banner, setBanner, id, canEdit: canEdit && isEditing },
    Comments: { /* heading: commentsHeading, */ commentOnId: id, locked: isEditing || isGuest },
    Photos: { images: maybeImages, Photos: maybePhotos },
    Preview: { hooks, image: avatar, banner: maybeBanner, photos: maybeImages },
  };

  entityTabNames.forEach((name) => {
    tabProps[name] = { ...baseProps, ...tabProps[name], ...customTabProps?.[name], ...tabDefinitions?.[name]?.props };
  });

  const tabNames = customTabNames || (ourArrayToEntries(entity.tabs) as RecordOfEntityTabNames);
  const tabElements = { ...customTabElements };

  const customElements: RecordOfEntityTabElements = {
    [entityTabName.Avatar]: AvatarViewEdit,
    [entityTabName.Banner]: OurBannerZone,
    [entityTabName.General]: OurListEntityGeneral,
    [entityTabName.Comments]: OurOutlinedComments,
    [entityTabName.Details]: ListEntityDetails,
    [entityTabName.Photos]: MaintPhotos,
    [entityTabName.Preview]: MaintPreview,
    ...tabElements,
    ...Object.keys(tabDefinitions).map((key) => ({ [key]: tabDefinitions[key as EntityTabName]?.TabElement })),
  };
  const definitions = { tabNames, tabElements, tabDefinitions, tabProps };

  Object.keys(tabNames).forEach((key) => {
    const name = key as EntityTabName;

    definitions.tabElements[name] = customElements[name];
  });

  // Mix in any basic properties that may be required by the various tabs
  const { General, Banner, Comments, Preview } = tabNames;
  const { category, imageKey, path, subscribable, type } = entity;

  if (General) {
    const onLike: MouseEventHandler = (event) => {
      reaction?.onLike && reaction?.onLike(event);
      reRead();
    };
    const reactions: EntityCardReaction = { ...reaction, onLike: reaction?.onLike ? onLike : undefined };

    definitions.tabProps[General] = { reactions, ...definitions.tabProps[General] };
  }

  if (Banner) definitions.tabProps[Banner] = { imageKey, entity: type, ...definitions.tabProps[Banner] };
  if (Comments) definitions.tabProps[Comments] = { commentOnType: type, ...definitions.tabProps[Comments] };
  if (Preview)
    definitions.tabProps[Preview] = {
      category,
      subscribable,
      entityPath: path,
      ...definitions.tabProps[Preview],
      sx: { maxWidth: ourDefaultMaxCardWidth, ...(definitions.tabProps[Preview]?.sx as SxProps) },
    };

  return { ...definitions };
};

export default useMaintTabDefinitions;
