import * as CSS from 'csstype';
import { ChangeEvent } from 'react';
import { Box, FormControlLabel, Switch, SwitchProps } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import { ExtendedFormik } from '@petconsole/pure-shared';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import getFieldProps from './helpers/getFieldProps';
import { GetFieldProps } from './helpers/getFieldProps';

export interface OurSwitchProps extends SwitchProps {
  formik?: ExtendedFormik;
  label?: string;
  align?: CSS.Property.TextAlign;
}

const OurSwitch = ({ align = 'left', ...props }: OurSwitchProps) => {
  const darkMode = useOurDarkMode();
  const fieldProps = getFieldProps({ ...props, darkMode } as GetFieldProps);
  const { id, name, label, value, onChange, InputProps } = fieldProps;
  const { disabled = false } = props;
  const { readOnly } = InputProps as RecordType;

  const onSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly || !onChange) return undefined;

    // onChange({ target: { id, value: e.target.checked } } );
    onChange({ ...e, target: { ...e.target, id: id || '', value: e.target.checked } } as ChangeEvent);
  };

  return (
    <Box textAlign={align} style={{ ...(props.style || {}) }} >
      <FormControlLabel
        control={
          <Switch
            name={(id || name || '') as string}
            disabled={disabled}
            checked={Boolean(value)}
            value={Boolean(value)}
            onChange={onSwitchChange}
            readOnly={readOnly as boolean | undefined}
            color="primary"
          />
        }
        label={label as string}
      />
    </Box>
  );
};

export default OurSwitch;
