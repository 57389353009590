import { PropsWithoutRef, useRef } from 'react';
import Draggable from 'react-draggable';
import { Paper, PaperProps } from '@mui/material';

const DraggablePaper = (props: PropsWithoutRef<PaperProps>) => {
  const nodeRef = useRef(null);

  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-handle"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
};

DraggablePaper.whyDidYouRender = true;

export default DraggablePaper;
