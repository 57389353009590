import { Divider, DividerProps } from '@mui/material';

interface OurDividerProps extends DividerProps {
  spacing?: number;
}

const OurDivider = ({ spacing, sx, ...rest }: OurDividerProps) => {
  return (
    <Divider
      variant="fullWidth"
      sx={{ ...(sx ? sx : {}), ...(spacing ? { pt: spacing, mb: spacing } : {}), width: '100%' }}
      {...rest}
    />
  );
};

OurDivider.whyDidYouRender = true;

export default OurDivider;
