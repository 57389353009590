import { useState } from 'react';
import { Grid } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { CommentRecord, SetState } from '@petconsole/pure-shared';
import OurButton from '../components/buttons/OurButton';
import OurText from '../components/inputs/OurText';
import { SaveCommentProps } from './helpers/useSaveComment';

interface AddCommentProps {
  saveComment: ({threadId, comment}: SaveCommentProps) => Promise<CommentRecord>;
  setIsAdding?: SetState<boolean>;
  threadId?: string;
  locked?: boolean;
  reply?: boolean;
}

const AddComment = ({ saveComment, threadId, setIsAdding, locked, reply }: AddCommentProps) => {
  const [comment, setComment] = useState('');

  const onCommentChange: StandardInputProps['onChange'] = ({ target }) => setComment(target.value as string);

  const onAdd = () => {
    saveComment({ threadId, comment }).then(() => {
      setComment('');

      if (setIsAdding) setIsAdding(false);
    });
  };

  return (
    <Grid container id="add-comment-container" spacing={1} alignItems="center" pb={1}>
      {reply && (
        <Grid item xs={1}>
          <span> </span>
        </Grid>
      )}
      <Grid item xs={reply ? 8 : 9} sm={reply ? 9 : 10} flex={1}>
        <OurText
          id="comment"
          value={comment}
          readOnly={locked}
          onChange={onCommentChange}
          variant="outlined"
          margin="dense"
          multiline
          autoFocus
        />
      </Grid>
      <Grid item xs={3} sm={2}>
        <OurButton label="Add" onClick={onAdd} size="small" disabled={!comment || locked} />
      </Grid>
    </Grid>
  );
};

AddComment.whyDidYouRender = true;

export default AddComment;
