import { Grid } from '@mui/material';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { RecordType } from '@petconsole/pure-base';
import { EntityCategory, memberEntity } from '@petconsole/pure-shared';
import { ourDefaultMaxCardWidth } from '../constants';
import OurEntityCard, { OurEntityCardProps } from '../components/cards/ourEntityCards/OurEntityCard';
import OurLinearProgress from '../components/misc/OurLinearProgress';
import OurTypoTextItem from '../components/misc/OurTypoTextItem';

type OmitProps = 'getEntityQueryHook' | 'preview' | 'entityId' | 'entityIds' | 'defaultAvatar' | 'IsYours' | 'cardSx';

export interface OurEntityPreviewProps<T extends RecordType = RecordType> extends Omit<OurEntityCardProps<T>, OmitProps> {
  entityData: T;
  category: EntityCategory;
}

const OurEntityPreview = <T extends RecordType = RecordType>({
  hooks,
  category,
  entityPath,
  subscribable,
  entityData,
  title,
  titleField,
  previewAvatar,
  previewBanner,
  previewPhotos,
  byCity = true,
  infoField,
  headerOptions,
}: OurEntityPreviewProps<T>) => {
  const { memberName: viewerName } = useCurrentMember();
  const { idName, name: entityName, shared } = hooks.entity;

  if (!entityData[idName]) return <OurLinearProgress />;

  const { ownerName, memberName } = entityData;
  const isOwned = !!ownerName || (!!memberName && memberName === viewerName);
  const isMe = entityName === memberEntity.name;
  const text = `How you${isMe ? '' : `r ${category}`} ${isOwned || shared ? 'will' : 'could'} appear to others`;

  return (
    <Grid container item id="preview-container" justifyContent="center">
      <OurTypoTextItem strong center text={text} typeProps={{ py: 1 }} />
      <OurEntityCard<T>
        hooks={hooks}
        subscribable={subscribable}
        entityData={entityData}
        title={title}
        titleField={titleField}
        previewAvatar={previewAvatar}
        previewBanner={previewBanner}
        previewPhotos={previewPhotos}
        entityPath={entityPath}
        cardSx={{ maxWidth: ourDefaultMaxCardWidth }}
        byCity={byCity}
        infoField={infoField}
        headerOptions={headerOptions}
        preview
      />
    </Grid>
  );
};

OurEntityPreview.whyDidYouRender = true;

export default OurEntityPreview;
