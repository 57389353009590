import { Home } from '@mui/icons-material';
import { List } from '@mui/material';
import { webPath } from '@petconsole/pure-shared';
import OurAppMenuItem from './OurAppMenuItem';

const OurHomeMenuItem = () => {
  return (
    <List disablePadding>
      <OurAppMenuItem item={[Home, 'Home', webPath.home]} />
    </List>
  );
};

OurHomeMenuItem.whyDidYouRender = true;

export default OurHomeMenuItem;
