import { Folder } from '@mui/icons-material';
import { webPath } from '@petconsole/pure-shared';
import OurIconLink from '../links/OurIconLink';

const OurMailFoldersButton = () => (
  <OurIconLink to={webPath.mailFolders} tip="Folders" Icon={Folder} />
);

OurMailFoldersButton.whyDidYouRender = true;

export default OurMailFoldersButton;
