import { Grid } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import MarginGridItem from '../containers/MarginGridItem';
import useGridMargins from '../containers/useGridMargins';
import MaintForm from './MaintForm';
import { MaintAppProps } from './types';

const MaintApp = <T extends RecordType = RecordType>({
  marginColumns,
  margins,
  leftMargins,
  rightMargins,
  LeftContent,
  RightContent,
  ...rest
}: MaintAppProps<T>) => {
  const marginProps = { marginColumns, margins, leftMargins, rightMargins };
  const { leftGridMargins, centerSizes, rightGridMargins } = useGridMargins(marginProps);

  return (
    <Grid container id="maint-app-container">
      {leftGridMargins && <MarginGridItem margin={leftGridMargins}>{LeftContent}</MarginGridItem>}
      <MaintForm<T> {...centerSizes} {...rest} />
      {rightGridMargins && <MarginGridItem margin={rightGridMargins}>{RightContent}</MarginGridItem>}
    </Grid>
  );
};

MaintApp.whyDidYouRender = true;

export default MaintApp;
