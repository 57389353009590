// noinspection ES6PreferShortImport

import { OurOutlinedGridContent } from '../components/outlinedGrid';
import useOurPhone from '../hooks/useOurPhone';
import OurCommentOn from './OurCommentOn';

interface OurOutlinedCommentsProps {
  commentOnId: string;
  commentOnType: string;
  locked?: boolean;
  heading?: string;
}

const OurOutlinedComments = ({
  commentOnId,
  commentOnType,
  locked = false,
  heading = 'Member Comments',
}: OurOutlinedCommentsProps) => {
  const { portraitPhone } = useOurPhone();

  return (
    <OurOutlinedGridContent id="comment-outline" label="Comments" spacing={1} sx={{ pt: 0 }}>
      <OurCommentOn
        commentOnId={commentOnId}
        commentOnType={commentOnType} // entityType
        locked={locked}
        heading={heading}
        sizes={{ xs: portraitPhone ? 12 : 11, sm: 10 }}
      />
    </OurOutlinedGridContent>
  );
};

OurOutlinedComments.whyDidYouRender = true;

export default OurOutlinedComments;
