import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetShopByUrlOrId } from '@petconsole/pure-fe-state';
import { ShopRecord, webPath } from '@petconsole/pure-shared';
import { useOurCurrentMember } from '@petconsole/pure-fe-mui';

const useMyShop = () => {
  const navigate = useNavigate();
  const { memberName } = useOurCurrentMember();
  const { id = '', id1 = '' } = useParams();
  const shopUrlOrId = id || id1;

  const { data, ...getResults } = useGetShopByUrlOrId({ shopUrlOrId });
  const { shopId = '', shortName = '', shopName = '', ownerName } = (data || {}) as ShopRecord;
  const superTitle = shortName || shopName;
  const errorData = getResults.isError ? data : '';

  useEffect(() => {
    if (ownerName && ownerName !== memberName) navigate(webPath.notFound);
  }, [ownerName, memberName, navigate]);

  return { shop: data, shopId, superTitle, errorData, ...getResults };
};

export default useMyShop;
