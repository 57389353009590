import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RecordType } from '@petconsole/pure-base';
import { useOurCognitoCache } from '@petconsole/pure-fe-amplify';
import { changeSiteApiEntity } from '@petconsole/pure-fe-api';
import { reactAppBusConsoleRoot, reactAppPetConsoleRoot, reactAppVanConsoleRoot } from '@petconsole/pure-shared';
import useOurAuthenticated from '../../../auth/useOurAuthenticated';
import useOurError from '../../../error/useOurError';

const useOurExitSite = () => {
  const { log } = useOurError();
  const navigate = useNavigate();
  const { site } = useParams();
  const { authenticated } = useOurAuthenticated();
  const { getItems } = useOurCognitoCache();

  const effectRan = useRef(false);
  const { api } = changeSiteApiEntity;

  useEffect(() => {
    if (effectRan.current) return undefined;

    effectRan.current = true;

    if (!site) {
      log('Attempt to exit site without a target').then();

      navigate(-1);

      return;
    }

    const targets = {
      busConsole: reactAppBusConsoleRoot,
      petConsole: reactAppPetConsoleRoot,
      vanConsole: reactAppVanConsoleRoot,
    } as RecordType;

    const target = site ? targets[site] : '';

    if (authenticated) {
      const items = getItems();

      api.saveItems({ site, items }).then((id) => {
        window.location.replace(`${target}/changeSite/${id}?redirect=${target}`);
      });
    } else {
      // noinspection SpellCheckingInspection
      window.location.replace(`${target}/changeSite/noid?redirect=${target}`);
    }
  }, [authenticated, log, navigate, site, getItems, api]);
};

export default useOurExitSite;
