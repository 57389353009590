import {
  AllInclusive,
  AttachMoney,
  Attribution,
  ContactPage,
  EmojiPeople,
  Gavel,
  Group,
  Policy,
  QuestionMark,
} from '@mui/icons-material';
import { OurHandshakeIcon } from '@petconsole/pure-fe-icon';
import {
  SelectHook,
  SetHook,
  useOurSelectGuestMenuGeneralMenuOpen,
  useOurSelectMemberMenuGeneralMenuOpen,
  useOurSetGuestMenuGeneralMenuOpen,
  useOurSetMemberMenuGeneralMenuOpen,
} from '@petconsole/pure-fe-state';
import { SetState, webPath } from '@petconsole/pure-shared';
import { AppMenuItems } from '../../../types';
import OurAppMenuCollapsable from './OurAppMenuCollapsable';
import ourCombinedAppMenuItems from './ourCombinedAppMenuItems';

interface OurGeneralMenuProps {
  add?: AppMenuItems;
  exclude?: string[];
  guest?: boolean;
}

const OurGeneralMenu = ({ add, exclude, guest }: OurGeneralMenuProps) => {
  const menuItemsOpen = (useOurSelectMemberMenuGeneralMenuOpen as SelectHook<boolean>)();
  const setMenuItemsOpen = (useOurSetMemberMenuGeneralMenuOpen as SetHook<boolean>)();
  const guestItemsOpen = (useOurSelectGuestMenuGeneralMenuOpen as SelectHook<boolean>)();
  const setGuestItemsOpen = (useOurSetGuestMenuGeneralMenuOpen as SetHook<boolean>)();

  const menuItems = [
    [EmojiPeople, 'About Us', webPath.about],
    [ContactPage, 'Contact Us', webPath.contact],
    [Attribution, 'Credits', webPath.credits],
    [QuestionMark, 'FAQ', webPath.faq],
    [Group, 'Our Team', webPath.team],
    [AttachMoney, 'Pricing', webPath.pricing],
    [Policy, 'Privacy Policy', webPath.privacy],
    [Gavel, 'Terms of Use', webPath.terms],
    [OurHandshakeIcon, 'Welcome', webPath.welcome],
  ] as AppMenuItems;

  const open = guest ? guestItemsOpen : menuItemsOpen;
  const setOpen = guest ? (setGuestItemsOpen as SetState<boolean>) : (setMenuItemsOpen as SetState<boolean>);
  const items = ourCombinedAppMenuItems({ items: menuItems, add, exclude });

  return (
    <OurAppMenuCollapsable
      open={open}
      setOpen={setOpen}
      Icon={AllInclusive}
      label="General"
      items={items}
      divider={true}
    />
  );
};

OurGeneralMenu.whyDidYouRender = true;

export default OurGeneralMenu;
