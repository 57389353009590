import { skipToken } from '@reduxjs/toolkit/query';
import { MaybeNull } from '@petconsole/pure-base';
import { blogApiEntity } from '@petconsole/pure-fe-api';
import { ApiUseGetQuery, BlogRecord } from '@petconsole/pure-shared';
import { useCreateBlogMutation, useGetBlogQuery, useUpdateBlogMutation } from '../../../apiSlice';
import useCreateEntity from '../../../helpers/useCreateEntity';
import useGetEntity from '../../../helpers/useGetEntity';
import useUpdateEntity from '../../../helpers/useUpdateEntity';

const apiEntity = blogApiEntity;
type ApiRecord = BlogRecord;
type UseGetBlog = ({ blogId }: { blogId: string }) => ReturnType<ApiUseGetQuery> & { blog?: MaybeNull<ApiRecord> };

export const useCreateBlog = () => useCreateEntity<ApiRecord>({ apiEntity, mutationHook: useCreateBlogMutation });
export const useGetBlog: UseGetBlog = ({ blogId }) =>
  useGetEntity({
    apiEntity,
    id: blogId || skipToken,
    queryHook: useGetBlogQuery,
  });
export const useUpdateBlog = () => useUpdateEntity<ApiRecord>({ apiEntity, mutationHook: useUpdateBlogMutation });
