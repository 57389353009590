import { entityType } from '@petconsole/pure-shared';
import { ThunkApi } from '../../../types';
import fetchPostsBetween from './fetchPostsBetween';

const byTopicName = `byTopic${entityType.Post}`;
const replyName = `reply${entityType.Post}`;

export const fetchByTopicPostsFromPayloadCreator = async (
  { topicId, fromDate }: { topicId: string; fromDate?: string },
  { getState }: ThunkApi,
) => fetchPostsBetween({ sliver: byTopicName, params: [topicId, '', fromDate, '~'], getState, scanForward: true });

export const fetchByTopicPostsToPayloadCreator = async (
  { topicId, toDate }: { topicId: string; toDate?: string },
  { getState }: ThunkApi,
) => fetchPostsBetween({ sliver: byTopicName, params: [topicId, '', ' ', toDate], getState, scanForward: false });

export const fetchReplyPostsSincePayloadCreator = async (
  { topicId, threadId, since }: { topicId: string; threadId: string; since?: string },
  { getState }: ThunkApi,
) => fetchPostsBetween({ sliver: replyName, params: [topicId, threadId, since, '~'], getState, scanForward: true });
