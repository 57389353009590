// noinspection ES6PreferShortImport

import { MaybeNull, MaybeString, RecordType } from '@petconsole/pure-base';
import { friendEvent, friendStatus } from '../../constants';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import {
  FriendStatus,
  GetPartialData,
  InitialEntity,
  MemberColumns,
  TimeColumns,
} from '../../types';

export interface Friend extends MemberColumns, TimeColumns {
  friendId: string;
  friendMemberId: string;
  friendMemberName: string;
  status: FriendStatus;
  message: string;
  reply: string;
  requested: boolean;
  paused: boolean;
  requestedAt: MaybeString;
  acceptedAt: MaybeString;
  cancelledAt: MaybeString;
  declinedAt: MaybeString;
  pausedAt: MaybeString;
  resumedAt: MaybeString;
}

export const friendEntity = entityAttributes({
  name: entityName.friend,
  attributes: {
    category: entityCategory.person,
    columns: { crud: false, images: false, json: false, member: true, time: true },
    eventType: friendEvent,
    loadLimit: 20,
    nameField: 'friendMemberName',
    test: {
      invalidData: (() => ({ friendMemberName: {} })) as GetPartialData,
      validData: () => ({ friendMemberName: 'New-Name' }),
    },
  },
});

const { boolean, date, id, memberName, oneOf, strim } = schema;
export const friendSchema = entitySchema<Friend>(friendEntity, {
  friendMemberId: id(true),
  friendMemberName: memberName(true),
  status: oneOf(friendStatus, friendStatus.Requested).defined(),
  message: strim(),
  reply: strim(),
  requested: boolean(),
  paused: boolean(),
  requestedAt: date(),
  acceptedAt: date(),
  cancelledAt: date(),
  declinedAt: date(),
  pausedAt: date(),
  resumedAt: date(),
});

export const friendDefaults: Partial<Friend> = {
  friendMemberId: '',
  friendMemberName: '',
  status: friendStatus.Requested,
  message: '',
  reply: '',
  requested: true,
  paused: false,
  requestedAt: null,
  acceptedAt: null,
  cancelledAt: null,
  declinedAt: null,
  pausedAt: null,
  resumedAt: null,
};

export interface FriendRecord extends Friend, RecordType {}

export type MaybeFriendRecord = MaybeNull<FriendRecord>;
export type FriendRecords = FriendRecord[];

export const initialFriend: InitialEntity<Friend, FriendRecord> = (overrides = {}) =>
  initialEntity<Friend>(friendEntity, { ...friendDefaults, ...overrides }) as FriendRecord;
