// noinspection ES6PreferShortImport

import { Box, Grid } from '@mui/material';
import { webPath } from '@petconsole/pure-shared';
import OurTextLink from '../../links/OurTextLink';
import OurCopyright from './OurCopyright';
import OurSiteMemberText from '../OurSiteMemberText';
import OurFacebookIcon from '../../social/OurFacebookIcon';
import OurInstagramIcon from '../../social/OurInstagramIcon';
import OurPinterestIcon from '../../social/OurPinterestIcon';
import OurTikTokIcon from '../../social/OurTikTokIcon';
import OurTwitterIcon from '../../social/OurTwitterIcon';
import OurYouTubeIcon from '../../social/OurYouTubeIcon';

const OurFooter = () => (
  <Box m={1} display="flex" flexDirection="column" alignItems="center">
    <Grid container item xs={12} sm={10} md={8} lg={7} xl={6} direction="row" justifyContent="space-between">
      <OurTextLink to={webPath.about} text="About" />
      <OurTextLink to={webPath.faq} text="FAQ" />
      <OurTextLink to={webPath.terms} text="Terms" />
      <OurTextLink to={webPath.privacy} text="Privacy" />
      <OurTextLink to={webPath.contact} text="Contact Us" />
    </Grid>
    <Grid mt={1} textAlign="center" item xs={12}>
      <OurSiteMemberText variant="body2" />
      <OurCopyright />
    </Grid>
    <Grid item xs={12}>
      <OurFacebookIcon />
      <OurInstagramIcon />
      <OurTikTokIcon />
      <OurPinterestIcon />
      <OurTwitterIcon />
      {/* <OurLinkedInIcon /> */}
      <OurYouTubeIcon />
    </Grid>
  </Box>
);

OurFooter.whyDidYouRender = true;

export default OurFooter;
