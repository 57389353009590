import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  ApiEntity,
  GetListOptions,
  GetListResults,
  MaybeOrderRecord,
  orderEntity,
  OrderRecord,
} from '@petconsole/pure-shared';
import { commonApi, get, pathParam, sitePath } from './common';

type ApiRecord = OrderRecord;

const apiPath = '/orders/orders';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getNextByShopAndOrder: (shopId: string, orderId: string) => Promise<MaybeOrderRecord>;
  getPrevByShopAndOrder: (shopId: string, orderId: string) => Promise<MaybeOrderRecord>;
  getListByMember: (memberId: string, options?: GetListOptions) => Promise<GetListResults<T>>;
  getListByShop: (shopId: string, options?: GetListOptions) => Promise<GetListResults<T>>;
}

export type OrderApi = Api<ApiRecord> & CustomApi;

export const orderApi: OrderApi = {
  ...common,
  getListByValue,
  getNextByShopAndOrder: (shopId: string, orderId: string) =>
    get(
      sitePath(`${apiPath}/getNext`, `${pathParam('shopId', shopId)}${pathParam('orderId', orderId)}`),
    ) as Promise<MaybeOrderRecord>,
  getPrevByShopAndOrder: (shopId, orderId) =>
    get(
      sitePath(`${apiPath}/getPrev`, `${pathParam('shopId', shopId)}${pathParam('orderId', orderId)}`),
    ) as Promise<MaybeOrderRecord>,
  getListByMember: (memberId, options) => getListByValue('memberId', memberId, options),
  getListByShop: (shopId, options) => getListByValue('shopId', shopId, options),
};

export const orderApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...orderEntity, api: orderApi };
