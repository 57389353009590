// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface Vet extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  vetId: string;
  practiceName: string;
  appointments: string;
  hours: string;
  established: number;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const vetEntity = entityAttributes({
  name: entityName.vet,
  attributes: {
    category: entityCategory.practice,
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    nameField: 'practiceName',
    site: anySite.petConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { number, strim, url } = schema;
export const vetSchema = entitySchema<Vet>(vetEntity, {
  practiceName: strim().required('Practice name is required'),
  appointments: url(),
  hours: strim(), // multi-line, free-text
  established: number().min(1900, 'Invalid year').max(new Date().getFullYear(), 'Invalid year'),
});

export const vetDefaults: Partial<Vet> = {
  practiceName: '',
  appointments: '',
  hours: '',
  established: 1900,
};

export interface VetRecord extends Vet, RecordType {}

export type MaybeVetRecord = MaybeNull<VetRecord>;
export type VetRecords = VetRecord[];

export const initialVet: InitialEntity<Vet, VetRecord> = (overrides = {}) =>
  initialEntity<Vet>(vetEntity, { ...vetDefaults, ...overrides }) as VetRecord;
