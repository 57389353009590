import { RecordType } from '@petconsole/pure-base';
import { cognitoPrefix } from '../constants';
import { CacheInstance } from '../types';

interface SetCognitoItemsProps {
  cache: CacheInstance;
  items: RecordType;
}

const setCognitoItems = ({ cache, items }: SetCognitoItemsProps) => {
  return Promise.all(Object.entries(items).map(([key, value]) => cache.setItem(key.replace(cognitoPrefix, ''), value)));
};

export default setCognitoItems;
