import { skipToken } from '@reduxjs/toolkit/query';
import { shopApiEntity } from '@petconsole/pure-fe-api';
import { ShopRecord } from '@petconsole/pure-shared';
import {
  useCreateShopMutation,
  useGetShopByUrlOrIdQuery,
  useGetShopQuery,
  useUpdateShopMutation,
} from '../../../apiSlice';
import useCreateEntity from '../../../helpers/useCreateEntity';
import useUpdateEntity from '../../../helpers/useUpdateEntity';

const apiEntity = shopApiEntity;
type ApiRecord = ShopRecord;

export const useCreateShop = () => useCreateEntity<ApiRecord>({ apiEntity, mutationHook: useCreateShopMutation });
export const useGetShop = ({ shopId }: { shopId: string }) => useGetShopQuery(shopId || skipToken);
export const useGetShopByUrlOrId = ({ shopUrlOrId }: { shopUrlOrId: string }) =>
  useGetShopByUrlOrIdQuery(shopUrlOrId || skipToken);
export const useUpdateShop = () => useUpdateEntity<ApiRecord>({ apiEntity, mutationHook: useUpdateShopMutation });
