import { useParams } from 'react-router-dom';
import { MouseEvent } from 'react';
import { List } from '@mui/material';
import { useOurNavigate } from '@petconsole/pure-fe-utils';
import ShopIcon from '@mui/icons-material/Store';
import ItemsIcon from '@mui/icons-material/ListAlt';
// import StaffIcon from '@mui/icons-material/RecentActors';
// import CustomersIcon from '@mui/icons-material/People';
import OrdersIcon from '@mui/icons-material/Assignment';
// import PaymentIcon from '@mui/icons-material/AccountBalance';
import ShippingIcon from '@mui/icons-material/LocalShipping';
// import ReturnsIcon from '@mui/icons-material/KeyboardReturn';
// import RewardsIcon from '@mui/icons-material/Loyalty';
// import ReportsIcon from '@mui/icons-material/ShowChart';
import BillingIcon from '@mui/icons-material/AttachMoney';
import StripeIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import { webPath } from '@petconsole/pure-shared';
import MyShopMenuItem from './MyShopMenuItem';

const MyShopMenuItems = () => {
  const { id = '', id1 = '' } = useParams();
  const { navigate } = useOurNavigate();

  const shopId = id || id1;

  const handleClick = (event: MouseEvent, path: string) => {
    event.preventDefault();
    navigate(`${path}/${shopId}`);
  };

  const onClickShop = (e: MouseEvent) => handleClick(e, webPath.myShop);
  const onClickItems = (e: MouseEvent) => handleClick(e, webPath.myShopItems);
  // const onClickStaff = (e: MouseEvent) => handleClick(e, webPath.myShopStaff);
  // const onClickCustomers = (e: MouseEvent) => handleClick(e, webPath.myShopCustomers);
  const onClickOrders = (e: MouseEvent) => handleClick(e, webPath.myShopOrders);
  // const onClickPayments = (e: MouseEvent) => handleClick(e, webPath.myShopPayments);
  const onClickShipping = (e: MouseEvent) => handleClick(e, webPath.myShopShipping);
  // const onClickReturns = (e: MouseEvent) => handleClick(e, webPath.myShopReturns);
  // const onClickRewards = (e: MouseEvent) => handleClick(e, webPath.myShopRewards);
  // const onClickReports = (e: MouseEvent) => handleClick(e, webPath.myShopReports);
  const onClickBilling = (e: MouseEvent) => handleClick(e, webPath.myShopBilling);
  const onClickStripe = (e: MouseEvent) => handleClick(e, webPath.myShopStripe);
  const onClickSettings = (e: MouseEvent) => handleClick(e, webPath.myShopSettings);

  // TODO: Comments, reviews, ?? abandoned baskets
  return (
    <>
      <List sx={{ borderBottomWidth: 0 }}>
        <MyShopMenuItem Icon={ShopIcon} text="Shop" onClick={onClickShop} />
        <MyShopMenuItem Icon={ItemsIcon} text="Items" onClick={onClickItems} />
        {/*<MyShopMenuItem Icon={StaffIcon} text="Staff" onClick={onClickStaff} />*/}
        {/*<MyShopMenuItem Icon={CustomersIcon} text="Customers" onClick={onClickCustomers} />*/}
        <MyShopMenuItem Icon={OrdersIcon} text="Orders" onClick={onClickOrders} />
        {/*<MyShopMenuItem Icon={PaymentIcon} text="Payments" onClick={onClickPayments} />*/}
        <MyShopMenuItem Icon={ShippingIcon} text="Shipping" onClick={onClickShipping} />
        {/*<MyShopMenuItem Icon={ReturnsIcon} text="Returns" onClick={onClickReturns} />*/}
        {/*<MyShopMenuItem Icon={RewardsIcon} text="Rewards" onClick={onClickRewards} />*/}
        {/*<MyShopMenuItem Icon={ReportsIcon} text="Reports" onClick={onClickReports} />*/}
        <MyShopMenuItem Icon={BillingIcon} text="Billing" onClick={onClickBilling} />
        <MyShopMenuItem Icon={StripeIcon} text="Stripe" onClick={onClickStripe} />
        <MyShopMenuItem Icon={SettingsIcon} text="Settings" onClick={onClickSettings} />
        <MyShopMenuItem Icon={null} text="" />
        <MyShopMenuItem Icon={null} text="" />
      </List>
      {/*<List>{renderMenuItem(BasketIcon, 'Baskets', null)}</List>*/}
    </>
  );
};

MyShopMenuItems.whyDidYouRender = true;

export default MyShopMenuItems;
