import { RecordType } from '@petconsole/pure-base';
import { pclTierPriceApiEntity } from '@petconsole/pure-fe-api';
import { PclTierPriceRecord } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchEntity, useOurDispatchValue } from '../../../helpers';
import { BaseSliceOptions, FetchByHook, OurEntityHooks, RequiredCrudSliverHooks, SelectByHook } from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import {
  fetchPclTierPriceByPclTierAndEffectiveReducers,
  fetchPclTierPriceByPclTierAndEffectiveThunk,
} from './fetchPclTierPriceByPclTierAndEffective';
import { updatePclTierPriceStripeReducers, updatePclTierPriceStripeThunk } from './updatePclTierPriceStripe';

type ApiEntityRecord = PclTierPriceRecord;

const apiEntity = pclTierPriceApiEntity;
const { name: sliceName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  comparer: ({ effectiveDate: dateA, pclTierPriceId: idA }, { effectiveDate: dateB, pclTierPriceId: idB }) =>
    `${dateA}.${idA}`.localeCompare(`${dateB}.${idB}`),
  fetchManyByProperty: { propertyName: 'pclTierId', apiMethod: 'getListByPclTier' },
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      extraReducers: [fetchPclTierPriceByPclTierAndEffectiveReducers, updatePclTierPriceStripeReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks } = sliceHookTypes;

export interface OurPclTierPriceHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  pclTierPriceHooks: RequiredCrudSliverHooks;
  customHooks: {
    useFetchesByPclTier: FetchByHook;
    useSelectsByPclTier: SelectByHook;
    useFetchesByPclTierAndEffective: () => ReturnType<typeof useOurDispatchValue>;
    useUpdateStripe: () => ReturnType<typeof useOurDispatchEntity>;
  };
}

export const ourPclTierPriceHooks: OurPclTierPriceHooks = {
  entity: apiEntity,
  pclTierPriceHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  customHooks: {
    useFetchesByPclTier: hooks.useOurFetchPclTierPricesByPclTierId as OurPclTierPriceHooks['customHooks']['useFetchesByPclTier'],
    useSelectsByPclTier: hooks.useOurSelectPclTierPricesByPclTierId as OurPclTierPriceHooks['customHooks']['useSelectsByPclTier'],
    useFetchesByPclTierAndEffective: () => useOurDispatchValue(fetchPclTierPriceByPclTierAndEffectiveThunk),
    useUpdateStripe: () => useOurDispatchEntity(sliceName, updatePclTierPriceStripeThunk),
  },
};

export default reducer;
