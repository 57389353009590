import { List } from '@mui/material';
import OurJoinMenuItem from './OurJoinMenuItem';
import OurLoginMenuItem from './OurLoginMenuItem';

const OurLoginJoinMenuItems = () => {
  return (
    <List disablePadding>
      <OurJoinMenuItem />
      <OurLoginMenuItem />
    </List>
  );
};

OurLoginJoinMenuItems.whyDidYouRender = true;

export default OurLoginJoinMenuItems;
