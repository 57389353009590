import { Instagram } from '@mui/icons-material';
import OurExternalIcon from '../icons/OurExternalIcon';

interface OurInstagramIconProps {
  href?: string;
  account?: string;
}

const OurInstagramIcon = ({ href, account = 'petconsole' }: OurInstagramIconProps) => (
  <OurExternalIcon icon={<Instagram />} href={href || `https://www.instagram.com/${account}`} aria="Instagram" />
);

OurInstagramIcon.whyDidYouRender = true;

export default OurInstagramIcon;
