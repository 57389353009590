import { MouseEvent } from 'react';
import { Icon, IsOpenProps } from '@petconsole/pure-shared';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

export interface ToggleButtonProps extends IsOpenProps, Omit<OurIconButtonProps, 'Icon'> {
  disabled?: boolean;
  OpenIcon: Icon;
  CloseIcon: Icon;
  propagate?: boolean;
}

const ToggleButton = ({
  isOpen,
  setIsOpen,
  disabled,
  OpenIcon,
  CloseIcon,
  propagate = true,
  ...rest
}: ToggleButtonProps) => {
  const onClick = (e: MouseEvent) => {
    // If the button is on a collapsible header, with click support, we'll need to stop propagation
    if (!propagate) e.stopPropagation();

    if (setIsOpen) setIsOpen(!isOpen);
  };

  return (
    <OurIconButton
      {...rest}
      disabled={disabled || !setIsOpen}
      Icon={isOpen ? CloseIcon : OpenIcon}
      color="primary"
      onClick={onClick}
    />
  );
};

ToggleButton.whyDidYouRender = true;

export default ToggleButton;
