// noinspection ES6PreferShortImport

import { MaybeNull, RecordType, newId } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import {
  DataEntityType,
  DefaultColumns,
  InitialEntity,
  ReactionColumns,
} from '../../types';

export interface Rank extends DefaultColumns, ReactionColumns {
  type: DataEntityType;
  rankId: string;
  parentId?: string;
  leftId?: string;
  rightId?: string;
  size: number;
  memberId: string;
  sourceId: string;
  values: string[];
  json: RecordType;
}

export const rankEntity = entityAttributes({
  name: entityName.rank,
  attributes: {
    category: entityCategory.item,
    columns: {crud: false, images: false, time: true},
    nameField: undefined,
    test: {invalidData: () => ({leftId: {}}), validData: () => ({leftId: newId()})},
  },
});

const {array, id, number} = schema;
export const rankSchema = entitySchema<Rank>(rankEntity, {
  parentId: id(),
  leftId: id(),
  rightId: id(),
  size: number(),
  memberId: id(true),
  sourceId: id(true),
  values: array(),
});

export const rankDefaults: Partial<Rank> = {
  parentId: '',
  leftId: '',
  rightId: '',
  size: 0,
  memberId: '',
  sourceId: '',
  values: [],
};

export interface RankRecord extends Rank, RecordType {
}

export type MaybeRankRecord = MaybeNull<RankRecord>;
export type RankRecords = RankRecord[];

export const initialRank: InitialEntity<Rank, RankRecord> = (overrides = {}) =>
  initialEntity<Rank>(rankEntity, {...rankDefaults, ...overrides}) as RankRecord;
