import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { Api, EntityType, ourValidId } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../types';

const getOneByUrlOrId = <T extends RecordType = RecordType>(builder: ApiBuilder<T>, api: Api<T>, tag: EntityType) =>
  builder.query<MaybeNull<T>, string>({
    query: (id) => ({ method: ourValidId(id) ? api.get : api.getByUrlName, id }),
    providesTags: (_result, _error, id) => [tag, { type: tag, id }],
  });

export default getOneByUrlOrId;
