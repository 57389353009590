import { useAppSelector } from '../../store/storeHooks';
import { AppState } from '../../store';

const useOurSelectSliverProperty = (
  sliceName: string,
  sliverName: string,
  propertyName: string,
) => {
  if (propertyName.split('.').length === 1)
    return useAppSelector((state: AppState) => state[sliceName as keyof AppState][sliverName][propertyName]);

  return useAppSelector((state: AppState) => {
    const parts = propertyName.split('.');
    let i = -1;
    let lastValue = state[sliceName as keyof AppState][sliverName];

    do {
      i += 1;
      lastValue = lastValue[parts[i]];
    } while (i < parts.length - 1 && lastValue && typeof lastValue === 'object');

    if (i < parts.length - 1) lastValue = undefined;

    return lastValue;
  });
};

export default useOurSelectSliverProperty;
