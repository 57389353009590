import { ExtendedFormik } from '@petconsole/pure-shared';

interface GetFormikValueProps {
  formik?: ExtendedFormik;
  id?: string;
}

const getFormikValue = ({ formik, id }: GetFormikValueProps) => {
  if (!formik) return undefined;

  if(!id) throw new Error('getFormikValue: id is required');

  if (id.split('.').length === 1) return formik.values[id];

  return id.split('.').reduce((obj, idPart) => {
    const properties = idPart.split('[');
    const property = properties[0];

    if (properties.length === 1) return obj[property];

    const index = Number(properties[1].split(']')[0]);

    return obj[property][index];
  }, formik.values);
};

export default getFormikValue;
