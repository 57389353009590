import { FetchStatus, KeyIsValue, SaveStatus } from './types';

export const fetchStatuses = ['idle', 'loading', 'succeeded', 'failed'] as const;
export const ourFetchStatus: KeyIsValue<FetchStatus> = {
  idle: 'idle',
  loading: 'loading',
  succeeded: 'succeeded',
  failed: 'failed',
} as const;

export const saveStatuses = ['idle', 'saving', 'succeeded', 'failed'] as const;
export const ourSaveStatus: KeyIsValue<SaveStatus> = {
  idle: 'idle',
  saving: 'saving',
  succeeded: 'succeeded',
  failed: 'failed',
} as const;
