import { memo } from 'react';
import OurToolbar from '../../components/misc/OurToolbar';
import { UseMaintToolbarProps } from '../types';

const useMaintToolbar = ({
  isEditing,
  canEdit,
  onAdd,
  onEdit,
  onCancel,
  onSave,
  onPrev,
  onNext,
  prevId,
  nextId,
  isGuest,
  saveButton,
}: UseMaintToolbarProps) => {
  const Toolbar = memo(() => (
    <OurToolbar
      {...{ canEdit, onAdd, onEdit }}
      onPrev={onPrev ?? undefined}
      onNext={onNext ?? undefined}
      onCancel={onCancel}
      onSave={onSave}
      prevDisabled={!!onPrev && !prevId}
      nextDisabled={!!onNext && !nextId}
      addDisabled={isGuest}
      editing={isEditing}
      editDisabled={isGuest}
      saveButton={saveButton ?? undefined}
    />
  ));

  return { Toolbar };
};

export default useMaintToolbar;
