// noinspection ES6PreferShortImport

import { configureStore, Reducer, ReducersMapObject } from '@reduxjs/toolkit';
import { Optional } from '@petconsole/pure-base';
import { AppState } from '../../store';
import { apiSlice } from '../../api/apiSlice'; // Prevent circular references

export interface ConfigureDataStoreProps {
  reducers: Reducer | ReducersMapObject;
  preloadedState?: Partial<AppState>;
}

const configureDataStore = ({ reducers, preloadedState }: ConfigureDataStoreProps) => {
  return configureStore({
    reducer: reducers,
    preloadedState: preloadedState as Optional<AppState>,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  });
};

export default configureDataStore;
