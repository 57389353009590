import { useEffect } from 'react';
import { webPath } from '@petconsole/pure-shared';
import useOurNavigate from '../router/useOurNavigate';
import useOurSearchGet from '../router/useOurSearchGet';
import useOurRedirect from './useOurRedirect';

interface UseOurLoginRedirectProps {
  authenticated: boolean;
}

const useOurLoginRedirect = ({ authenticated }: UseOurLoginRedirectProps) => {
  const { navigate, pathname } = useOurNavigate();
  const { redirectTo, redirectWith } = useOurSearchGet('redirectTo', 'redirectWith');
  const { redirect, setRedirect } = useOurRedirect();

  const redirectPathname = redirect?.pathname;
  const redirectSearch = redirect?.pathname;

  // If we have a redirect in the URL, capture it in our context state
  useEffect(() => {
    if (authenticated) return;

    if (!redirectTo) return;
    if (redirectPathname === redirectTo && redirectSearch === redirectWith) return;

    setRedirect({ pathname: redirectTo, search: redirectWith || '' }).then();
  }, [authenticated, redirectPathname, redirectSearch, redirectTo, setRedirect, redirectWith]);

  // Handle redirects after member is logged in, and on the landing page
  useEffect(() => {
    if (!authenticated || !redirect || pathname !== webPath.home) return;

    setRedirect(null).then();
    navigate(redirect, { replace: true });
  }, [authenticated, redirect, navigate, pathname, setRedirect]);
};

export default useOurLoginRedirect;
