import { httpStatusCode, Log, logEditConflicts, SetBoolean, WarnWithConflictCheck } from '@petconsole/pure-shared';

interface SaveEntityCatchProps {
  e: unknown;
  setIsSubmitting: SetBoolean;
  warnWithConflictCheck: WarnWithConflictCheck;
  log: Log;
}

const saveEntityCatch = ({ e, setIsSubmitting, warnWithConflictCheck, log }: SaveEntityCatchProps) => {
  if (setIsSubmitting) setIsSubmitting(false);

  warnWithConflictCheck(e);

  if (e instanceof Error) {
    if (logEditConflicts() || !e.message.includes(httpStatusCode.conflict.toString()))
      log(`Error saving: ${e.message}`).then();
  } else log(`Error saving: ${e}`).then();
};

export default saveEntityCatch;
