import { RecordType, properCase } from '@petconsole/pure-base';
import { AnyAsyncThunk } from '../../types/types';
import { useOurDispatchAction, useOurSelectAction } from '../hooks';
import { slicePropertySelector } from '../selectors';

const sliceToggleHooks = (sliceName: string, propertyName: string, actions: RecordType) => {
  const pascalName = properCase(propertyName);
  const hooks: RecordType = {};

  hooks[`useOurSelect${pascalName}`] = () =>
    useOurSelectAction(slicePropertySelector(sliceName, propertyName));
  hooks[`useOurToggle${pascalName}`] = () => useOurDispatchAction(actions[`toggle${pascalName}`] as AnyAsyncThunk);

  return hooks;
};

export default sliceToggleHooks;
