import { RecordType } from '@petconsole/pure-base';
import { RatingRecord } from '@petconsole/pure-shared';
import { ourSaveStatus } from '../../constants';
import { AnyAsyncThunk, StateRatingSliver, ThunkFulfilledMethod, ThunkReducersType } from '../../types';
import thunkPendingReducer from './thunkPendingReducer';
import thunkRejectedReducer from './thunkRejectedReducer';

const ratingWriteReducers: ThunkReducersType = (fetchMethod, stateProperty) => {
  const { pending, fulfilled, rejected } = fetchMethod as AnyAsyncThunk;
  const statusProperty = 'writeStatus';
  const idProperty = `${stateProperty}Id`;
  const fulfilledReducer: ThunkFulfilledMethod<RecordType, RatingRecord> = (topState, { payload }) => {
    const state = (topState[stateProperty] as StateRatingSliver).rating;
    const { ratedId } = payload as RatingRecord;

    state.writeError = null;
    state.writeStatus = ourSaveStatus.succeeded;
    state.ratings[ratedId] = { ...state.ratings[ratedId], ...(<RecordType>payload) };
  };

  return {
    [String(pending)]: thunkPendingReducer(stateProperty, statusProperty, idProperty),
    [String(fulfilled)]: fulfilledReducer,
    [String(rejected)]: thunkRejectedReducer(stateProperty, statusProperty, idProperty),
  };
};

export default ratingWriteReducers;
