import { Grid } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import { ApiUseGetQuery, OurEntityHooks, SliverHooks } from '@petconsole/pure-fe-state';
import { ApiEntity } from '@petconsole/pure-shared';
import OurEntityCards from '../components/cards/ourEntityCards/OurEntityCards';
import OurCardsContainer from '../components/cards/OurCardsContainer';
import OurFetchStatusControl from '../components/misc/OurFetchStatusControl';
import { EntityCardType, EntityCardsType } from '../types';

export interface UseOurSliverCardsProps<T extends RecordType = RecordType> {
  hooks: OurEntityHooks<T>;
  getEntityQueryHook: ApiUseGetQuery;
  subscribable?: boolean;
  defaultAvatar?: string;
  titleField?: string | string[];
  entityPath?: string;
  EntityCard?: EntityCardType;
  EntityCards?: EntityCardsType;
  infoField?: string;
  activity?: string;
  prefix: string;
}

const useOurSliverCards = <T extends RecordType = RecordType>({
  hooks,
  getEntityQueryHook,
  subscribable,
  defaultAvatar,
  titleField,
  entityPath,
  EntityCard,
  EntityCards,
  infoField = '',
  activity = 'Fetching entity list',
  prefix,
}: UseOurSliverCardsProps<T>) => () => {
  const { name: entityName, proper } = hooks.entity as ApiEntity<T>;
  const sliverHooks = hooks[`${prefix}${proper}Hooks`] as Required<SliverHooks>;

  if (!sliverHooks) return null;

  const entities = sliverHooks.useSelects() as T[];
  const ids = sliverHooks.useSelectIds();
  const hasMore = sliverHooks.useSelectHasMore();
  const readError = sliverHooks.useSelectReadError();
  const readStatus = sliverHooks.useSelectReadStatus();
  const reset = sliverHooks.useReset();
  const fetch = sliverHooks.useFetches();
  const resetMinutes = 0;

  return (
    <Grid container rowGap={1}>
      <OurCardsContainer>
        <OurEntityCards<T>
          hooks={hooks}
          getEntityQueryHook={getEntityQueryHook}
          subscribable={subscribable}
          entities={entities}
          ids={ids}
          defaultAvatar={defaultAvatar}
          titleField={titleField}
          entityPath={entityPath}
          EntityCard={EntityCard}
          EntityCards={EntityCards}
          infoField={infoField}
        />
      </OurCardsContainer>
      <OurFetchStatusControl
        activity={activity.replace('entity', entityName)}
        hasMore={hasMore}
        readError={readError}
        readStatus={readStatus}
        reset={reset}
        fetch={fetch}
        resetMinutes={resetMinutes}
      />
    </Grid>
  );
};

export default useOurSliverCards;
