import { createEntityAdapter, Comparer, AsyncThunkPayloadCreator, EntityId } from '@reduxjs/toolkit';
import { RecordType } from '@petconsole/pure-base';
import { GetListByIdMethod } from '@petconsole/pure-shared';
import {
  EntityAdapterWithPick,
  GetInitialStateType,
  Slice,
  SliceSelectors,
  SliverHelpers,
  StateType,
  ThunkApi,
} from '../types';
import { initialReadWriteState } from './sliceHelpers';
import { fetchEntityReducers, fetchReducers } from './thunkReducers';
import { selectEntityByUrlOrId, selectNextEntity, selectPrevEntity } from './selectors';
import { fetchParams } from './misc';
import { appThunk } from '../store/appThunk';

export type SliverHelpersType = <T extends RecordType = RecordType>(
  slice: Slice<T>,
  sliver: string,
  idName: string | ((row: RecordType) => string),
  comparer: false | Comparer<RecordType>,
  state: StateType,
  creator?: (props?: unknown) => Promise<unknown>,
  creators?: (props?: unknown) => Promise<unknown>,
  pick?: string[] | undefined,
) => SliverHelpers;

const sliverHelpers: SliverHelpersType = (slice, sliver, idName, comparer, state, creator, creators, pick) => {
  const { name: sliceName, flag, api } = slice;
  const { proper, fetchesById } = slice.sliver[sliver];
  const { hasNextPrev, hasUrlName } = flag;

  const selector =
    (property: string) =>
    ({ [sliceName]: sliverSlice }: RecordType) =>
      (sliverSlice as StateType)[sliver][property];

  const idSelector = (row: RecordType) => (typeof idName === 'function' ? idName(row) : row[idName] as EntityId);

  const adapter = createEntityAdapter({
    selectId: slice.option.selectId || idSelector,
    sortComparer: comparer,
  }) as EntityAdapterWithPick;

  if (pick) {
    adapter.pickProperties = pick;
  }

  const selectors: SliceSelectors = {
    ...adapter.getSelectors((state: RecordType) => (state[sliceName] as StateType)[sliver]),
    readStatus: selector('readStatus'),
    readError: selector('readError'),
    readAllStatus: selector('readAllStatus'),
    readAllError: selector('readAllError'),
    writeStatus: selector('writeStatus'),
    writeError: selector('writeError'),
    hasMore: ({ [sliceName]: slice }: RecordType) => !!((slice as StateType)[sliver].nextKey),
    ...(hasNextPrev && {
      selectNext: (state, id) => selectNextEntity(state[sliceName][sliver], id),
      selectPrev: (state, id) => selectPrevEntity(state[sliceName][sliver], id),
    }),
    ...(hasUrlName && {
      selectByUrlOrId: (state, value) => selectEntityByUrlOrId(state[sliceName][sliver], value),
    }),
  };

  const getInitialState: GetInitialStateType = () =>
    adapter.getInitialState({ limit: 0, ...initialReadWriteState, ...state });

  const helpers: SliverHelpers = {
    adapter,
    initialState: getInitialState,
    selectors,
  };

  const typePrefix = `${sliceName}/fetch${proper}`;

  if (creator) {
    const fetch = appThunk(typePrefix, creator);

    helpers.fetchOneCreator = fetch;
    helpers.fetchOneReducers =
      sliver === sliceName ? fetchEntityReducers(slice, fetch) : fetchReducers(fetch, sliver, adapter);
  }

  if (creators || fetchesById) {
    const payloadCreator =
      creators || !fetchesById
        ? creators
        : async ({ [slice.idName]: id }: RecordType, { getState }: ThunkApi) => {
            const fetchApi = api.entity;
            const fetchMethod = fetchApi[`getListBy${slice.proper}`] as GetListByIdMethod;

            return fetchMethod(id as string, fetchParams(getState, sliceName, sliver));
          };

    const fetches = appThunk(`${typePrefix}s`, payloadCreator as AsyncThunkPayloadCreator<unknown, unknown>);

    helpers.fetchManyCreator = fetches;
    helpers.fetchManyReducers = fetchReducers(fetches, sliver, adapter);
  }

  return helpers;
};

export default sliverHelpers;
