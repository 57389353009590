// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity, OwnerColumns } from '../../../types';

export interface FeedDef extends CrudColumns, OwnerColumns {
  type: DataEntityType;
  feedDefId: string;
  urlName: string;
  json: RecordType;
}

export const feedDefEntity = entityAttributes({
  name: entityName.feedDef,
  attributes: {
    apiPath: '/feeds/feedDefs',
    category: entityCategory.item,
    columns: { images: false, owner: true },
    nameField: 'urlName',
  },
});

const { strim } = schema;
export const feedDefSchema = entitySchema<FeedDef>(feedDefEntity, {
  urlName: strim().required(),
});

export const feedDefDefaults: Partial<FeedDef> = {
  urlName: '',
};

export interface FeedDefRecord extends FeedDef, RecordType {}

export type MaybeFeedDefRecord = MaybeNull<FeedDefRecord>;
export type FeedDefRecords = FeedDefRecord[];

export const initialFeedDef: InitialEntity<FeedDef, FeedDefRecord> = (overrides = {}) =>
  initialEntity<FeedDef>(feedDefEntity, { ...feedDefDefaults, ...overrides }) as FeedDefRecord;
