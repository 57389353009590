import { RecordType } from '@petconsole/pure-base';
import { shopItemApiEntity } from '@petconsole/pure-fe-api';
import { Api, ShopItemRecord } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchValue, useOurSelectsByPropertyNameAndValue } from '../../../helpers';
import payloadCreatorListByValue from '../../../helpers/payloadCreators/payloadCreatorListByValue';
import {
  BaseSliceOptions,
  BaseSliceTab,
  FetchesSelectsByIdHooks,
  OurEntityHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { byShopItemsByShopThunk, myShopItemsByShopReducers, myShopItemsByShopThunk } from './shopItemsByShop';

type ApiEntityRecord = ShopItemRecord;

const apiEntity = shopItemApiEntity;
const { api, idName, name: sliceName, proper } = apiEntity;
const shopItem = sliceName;
const byName = `by${proper}`;
const myName = `my${proper}`;

const Tab = { shopItem } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  shopItem: { default: '' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasMySliver: true,
  tabs,
  slivers: [
    {
      prefix: 'by',
      fetchesByValue: true,
      payloadCreators: payloadCreatorListByValue(
        sliceName,
        byName,
        idName,
        api as unknown as Api<RecordType>,
        'getListByShopAndAvailable',
      ),
    },
    { prefix: 'my', extraReducers: [myShopItemsByShopReducers] },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, tabHooks } = sliceHookTypes;

export interface OurShopItemHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  shopItemHooks: RequiredCrudSliverHooks;
  byShopItemHooks: RequiredSliverHooks;
  myShopItemHooks: RequiredSliverHooks;
  tabHooks: TabHooks;
  customHooks: {
    byShopItemByShop: FetchesSelectsByIdHooks;
    myShopItemByShop: FetchesSelectsByIdHooks;
  };
}

export const ourShopItemHooks: OurShopItemHooks = {
  entity: apiEntity,
  shopItemHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  byShopItemHooks: sliverHooks(apiEntity, 'By', hooks) as RequiredSliverHooks,
  myShopItemHooks: sliverHooks(apiEntity, 'My', hooks) as RequiredSliverHooks,
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
  customHooks: {
    byShopItemByShop: {
      useFetches: () => useOurDispatchValue(byShopItemsByShopThunk),
      useSelects: (id: string) =>
        useOurSelectsByPropertyNameAndValue({
          sliceName,
          sliverName: byName,
          propertyName: 'shopId',
          value: id,
        }),
    },
    myShopItemByShop: {
      useFetches: () => useOurDispatchValue(myShopItemsByShopThunk),
      useSelects: (id: string) =>
        useOurSelectsByPropertyNameAndValue({
          sliceName,
          sliverName: myName,
          propertyName: 'shopId',
          value: id,
        }),
    },
  },
};

export default reducer;
