import { cartItemApiEntity } from '@petconsole/pure-fe-api';
import { CartItemRecord } from '@petconsole/pure-shared';
import { fetchParams, sliceHookTypes } from '../../../helpers';
import { BaseSliceOptions, OurEntityHooks, RecordType, RequiredSliverByHooks } from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = CartItemRecord;

const apiEntity = cartItemApiEntity;
const { api, name: sliceName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  sortProperty: 'cartItemId',
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      fetchesByValue: true,
      payloadCreators: async (cartItemId: string, { getState }: RecordType) =>
        api.getListByCart(cartItemId, fetchParams(getState, sliceName, sliceName)),
      // extraReducers: [saveCartItemsReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { sliverHooks } = sliceHookTypes;

export interface OurCartItemHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  cartItemHooks: RequiredSliverByHooks;
  customHooks: {
    // useSave: () => ReturnType<typeof useOurDispatchValue>;
  };
}

export const ourCartItemHooks: OurCartItemHooks = {
  entity: apiEntity,
  cartItemHooks: sliverHooks(apiEntity, '', hooks) as RequiredSliverByHooks,
  customHooks: {
    // useSave: () => useOurDispatchValue(saveCartItemsThunk),
  },
};

export default reducer;
