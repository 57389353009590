import { ElementType } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import useOurAuthenticated from '../../../auth/useOurAuthenticated';
import { appType, ourAppMarginTop, ourAuthPaths } from '../../../constants';
import { MemberAvatarMenuItems } from '../../../types';
import OurContainedRoutes from '../routes/OurContainedRoutes';
import OurAuthApp from './OurAuthApp';
import OurGuestApp from './OurGuestApp';
import OurMemberApp from './OurMemberApp';

interface OurAppContentProps {
  AppRoutes: ElementType;
  GuestMenuItems?: ElementType;
  MemberMenuItems?: ElementType;
  avatarMenuItems?: MemberAvatarMenuItems;
}

const OurAppContent = ({ AppRoutes, GuestMenuItems, MemberMenuItems, avatarMenuItems }: OurAppContentProps) => {
  const { authenticated } = useOurAuthenticated();
  const { pathname } = useLocation();

  const authPath = (ourAuthPaths as string[]).includes(pathname);
  const app = authenticated ? appType.Member : authPath ? appType.Auth : appType.Guest;

  return (
    <>
      <Box display="flex" mt={ourAppMarginTop}>
        {app === appType.Auth && <OurAuthApp />}

        {app === appType.Guest && !!GuestMenuItems && <OurGuestApp MenuItems={GuestMenuItems} />}

        {app === appType.Member && !!MemberMenuItems && !!avatarMenuItems && (
          <OurMemberApp MenuItems={MemberMenuItems} avatarMenuItems={avatarMenuItems} />
        )}
      </Box>
      <OurContainedRoutes Routes={AppRoutes} />
    </>
  );
};

OurAppContent.whyDidYouRender = true;

export default OurAppContent;
