import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SetBoolean } from '@petconsole/pure-shared';
import { myShopAppBarHeight } from '../constants';

interface MyShopAppBarProps {
  open: boolean;
  setOpen: SetBoolean;
  openTransition: string;
}

const MyShopAppBar = ({ open, setOpen, openTransition }: MyShopAppBarProps) => {
  const { spacing, zIndex } = useTheme();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: spacing(7),
        top: myShopAppBarHeight,
        zIndex: zIndex.drawer + 1,
        transition: openTransition,
      }}
    >
      <Toolbar disableGutters variant="dense" sx={{ pl: 2.5, ...(open && { display: 'none' }) }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpen(true)}
          edge="start"
          sx={{ ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

MyShopAppBar.whyDidYouRender = true;

export default MyShopAppBar;
