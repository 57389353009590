import { Grid, GridProps } from '@mui/material';
import OurDisplayDate, { OurDisplayDateProps } from './OurDisplayDate';

interface OurGridDisplayDateProps extends OurDisplayDateProps {
  gridProps?: GridProps;
}

const OurGridDisplayDate = ({ gridProps, ...rest }: OurGridDisplayDateProps) => {
  return (
    <Grid item {...gridProps}>
      <OurDisplayDate {...rest} />
    </Grid>
  );
};

OurGridDisplayDate.whyDidYouRender = true;

export default OurGridDisplayDate;
