import { RecordType, properCase } from '@petconsole/pure-base';
import { orderLineApiEntity } from '@petconsole/pure-fe-api';
import { OrderLineRecord } from '@petconsole/pure-shared';
import { fetchParams, sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  OurEntityHooks,
  RequiredCrudSliverByHooks,
  RequiredSliverByHooks,
  ThunkApi,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = OrderLineRecord;

const apiEntity = orderLineApiEntity;
const { api, name: sliceName, proper } = apiEntity;
const myShop = 'myShop';
const myShopName = `${myShop}${proper}`;

// TODO: Order lines should only be for a single order at a time
//  - use setAll rather than upsertMany in fulfilled reducer
//  - resetting an order should reset order lines too
const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasMySliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  comparer: ({ orderLineId: a }: ApiEntityRecord, { orderLineId: b }: ApiEntityRecord) => a.localeCompare(b),
  tabs: undefined,
  slivers: [
    {
      prefix: myShop,
      fetchesByValue: true,
      payloadCreators: async (orderId: string, { getState }: ThunkApi) =>
        api.getListByOrder(orderId, fetchParams(getState, sliceName, myShopName)),
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudByHooks, sliverByHooks } = sliceHookTypes;

export interface OurOrderLineHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  orderLineHooks: RequiredCrudSliverByHooks;
  myShopOrderLineHooks: RequiredSliverByHooks;
}

export const ourOrderLineHooks: OurOrderLineHooks = {
  entity: apiEntity,
  orderLineHooks: crudByHooks(apiEntity, hooks) as RequiredCrudSliverByHooks,
  myShopOrderLineHooks: sliverByHooks(apiEntity, properCase(myShop), hooks) as RequiredSliverByHooks,
};

export default reducer;
