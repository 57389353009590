import { RecordType } from '@petconsole/pure-base';
import { petWalkerApiEntity } from '@petconsole/pure-fe-api';
import { PetWalkerRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = PetWalkerRecord;

const apiEntity = petWalkerApiEntity;
const { name: sliceName, plural } = apiEntity;
const petWalker = sliceName;
const petWalkers = plural;
const petWalkerAdmin = `${sliceName}Admin`;

const Tab = { petWalkerAdmin, petWalker, petWalkers } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  petWalkerAdmin: { default: '' },
  petWalker: { default: '' },
  petWalkers: { default: '' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, ratingHooks, tabHooks } = sliceHookTypes;

export interface OurPetWalkerHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  petWalkerHooks: RequiredCrudSliverHooks;
  cityPetWalkerHooks: RequiredSliverHooks;
  newestPetWalkerHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourPetWalkerHooks: OurPetWalkerHooks = {
  entity: apiEntity,
  petWalkerHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityPetWalkerHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestPetWalkerHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  ratingHooks: ratingHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
