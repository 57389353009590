import { useState } from 'react';
import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { EntityAttributes } from '@petconsole/pure-shared';

export interface UseOurOnEditListEntityProps<T extends RecordType = RecordType> {
  entity: EntityAttributes;
  data: MaybeNull<T>;
  viewOnly?: boolean;
  startEditing?: boolean;
}

const useOurOnEditListEntity = <T extends RecordType = RecordType>({ entity, data, viewOnly, startEditing = false }: UseOurOnEditListEntityProps<T>) => {
  const { memberName: currentMemberName } = useCurrentMember();

  const [editing, setEditing] = useState(startEditing);

  const { memberOwned, ownerIfEditing, shared, subscribable } = entity;
  const { memberName, ownerName } = data || {} as RecordType;

  const entityOwnerName = ((subscribable && ownerName) || (memberOwned && memberName) || '') as string;

  const guest = !currentMemberName;
  const owned = !!entityOwnerName;
  const owner = Boolean(currentMemberName && entityOwnerName?.toLowerCase() === currentMemberName.toLowerCase()) || (ownerIfEditing && editing);
  const canEdit = !viewOnly && (!owned || owner || shared);

  const onEdit = () => setEditing(true);

  return { editing, setEditing, owned, owner, guest, canEdit, onEdit };
};

export default useOurOnEditListEntity;
