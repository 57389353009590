import { ourDateFormat, ourIsoToMonthDayYear } from '@petconsole/pure-fe-utils';
import OurText, { OurTextProps } from '../inputs/OurText';

export interface OurDisplayDateProps extends OurTextProps {
  format?: string;
}

const OurDisplayDate = ({
  value,
  format = ourDateFormat.monthDayYear,
  formik,
  id = '',
  ...rest
}: OurDisplayDateProps) => {
  // TODO: Add support for mare date formats
  if (format !== ourDateFormat.monthDayYear) return <OurText value={ourDateFormat.invalidDate} />;

  const dateAt = value || (formik && formik.values[id] ? formik.values[id] : '');
  const formattedValue = dateAt ? ourIsoToMonthDayYear(dateAt) : '';

  return <OurText value={formattedValue} formik={formik} id={id} {...rest} readOnly />;
};

export default OurDisplayDate;
