// noinspection ES6PreferShortImport

import React, { ElementType, PropsWithChildren } from 'react';
import Rollbar from 'rollbar';
import { SnackbarProvider } from 'notistack';
import { Store } from '@reduxjs/toolkit';
import { Provider as RollbarProvider } from '@rollbar/react';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { configureAmplify } from '@petconsole/pure-fe-amplify';
import { OurRedirectContextProvider } from '@petconsole/pure-fe-utils';
import { reactAppSegmentWriteKey } from '@petconsole/pure-shared';
import { OurAppContextProvider } from '../../../context/OurAppContext';
import { OurDarkModeContextProvider } from '../../../context/OurDarkModeContext';
import OurRollbarErrorBoundary from '../../../error/OurRollbarErrorBoundary';
import OurHelmetMetaData from '../../misc/OurHelmetMetaData';
import OurBrowserRouter from '../routes/OurBrowserRouter';
import { ourDarkThemeOptions, ourLightThemeOptions } from '../themes/ourThemes';
import OurThemeProvider from '../themes/OurThemeProvider';

// If you need to customize the theme for this side, you can override options here
const lightTheme = createTheme(ourLightThemeOptions as ThemeOptions);
const darkTheme = createTheme(ourDarkThemeOptions as ThemeOptions);

type WindowWithAnalytics = typeof window & { analytics: { load: (key: string) => void } };

if ((window as WindowWithAnalytics).analytics) (window as WindowWithAnalytics).analytics.load(reactAppSegmentWriteKey);
// window.LOG_LEVEL = 'DEBUG';

configureAmplify();

interface OurAppWrapperProps extends PropsWithChildren {
  ReduxProvider: ElementType;
  store: Store;
  rollbar: Rollbar;
}

const OurAppWrapper = ({ ReduxProvider, store, rollbar, children }: OurAppWrapperProps) => {
  return (
    <>
      <OurHelmetMetaData />
      {/* Provider instantiates Rollbar client instance handling any uncaught errors or unhandled promises in the browser */}
      <RollbarProvider instance={rollbar}>
        {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
        <OurRollbarErrorBoundary>
          <ReduxProvider store={store}>
            <React.StrictMode>
              <OurRedirectContextProvider>
                <OurBrowserRouter rollbar={rollbar}>
                  <OurAppContextProvider>
                    <OurDarkModeContextProvider>
                      <OurThemeProvider darkTheme={darkTheme} lightTheme={lightTheme}>
                        <SnackbarProvider maxSnack={3} dense>
                          {children}
                        </SnackbarProvider>
                      </OurThemeProvider>
                    </OurDarkModeContextProvider>
                  </OurAppContextProvider>
                </OurBrowserRouter>
              </OurRedirectContextProvider>
            </React.StrictMode>
          </ReduxProvider>
        </OurRollbarErrorBoundary>
      </RollbarProvider>
    </>
  );
};

OurAppWrapper.whyDidYouRender = true;

export default OurAppWrapper;
