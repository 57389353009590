import { Avatar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface AvatarViewProps {
  avatar: string;
  text?: string;
  width?: number;
}

const AvatarView = ({ avatar, text = '', width = 25 }: AvatarViewProps) => {
  const { spacing } = useTheme();

  const length = spacing(width);

  if (!avatar && !text) return null;

  return (
    <Avatar src={avatar} sx={{ width: length, height: length, m: 1 }}>
      {text && <Typography variant="h4">{text}</Typography>}
    </Avatar>
  );
};

AvatarView.whyDidYouRender = true;

export default AvatarView;
