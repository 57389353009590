import { skipToken } from '@reduxjs/toolkit/query';
import { pclTierApiEntity } from '@petconsole/pure-fe-api';
import { PclTierRecord, PclTierRecords } from '@petconsole/pure-shared';
import {
  useCreatePclTierMutation,
  useGetPclTierQuery,
  useGetPclTiersByPclServiceQuery,
  useUpdatePclTierMutation,
} from '../../../apiSlice';
import useCreateEntity from '../../../helpers/useCreateEntity';
import useGetEntityItemsBy from '../../../helpers/useGetEntityItemsBy';
import useUpdateEntity from '../../../helpers/useUpdateEntity';

const apiEntity = pclTierApiEntity;
type ApiRecord = PclTierRecord;

export const useCreatePclTier = () => useCreateEntity<ApiRecord>({ apiEntity, mutationHook: useCreatePclTierMutation });
export const useGetPclTier = ({ pclTierId }: { pclTierId: string }) => useGetPclTierQuery(pclTierId || skipToken);
export const useGetPclTiersByPclService = ({ pclServiceId }: { pclServiceId: string }) =>
  useGetEntityItemsBy<PclTierRecord>({
    apiEntity,
    value: pclServiceId,
    queryHook: useGetPclTiersByPclServiceQuery,
  }) as ReturnType<typeof useGetEntityItemsBy<PclTierRecord>> & { pclTiers: PclTierRecords };
export const useUpdatePclTier = () => useUpdateEntity<ApiRecord>({ apiEntity, mutationHook: useUpdatePclTierMutation });
