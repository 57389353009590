import { Link, Typography } from '@mui/material';
import { reactAppCopyrightHolder, reactAppCopyrightYear, reactAppPetConsoleRoot } from '@petconsole/pure-shared';

const OurCopyright = () => {
  const currentYear = new Date().getFullYear().toString(10);
  const copyrightYear =
    reactAppCopyrightYear === currentYear ? currentYear : `${reactAppCopyrightYear || '2019'}-${currentYear}`;

  return (
    <Typography variant="body2">
      {'© '}
      <Link href={reactAppPetConsoleRoot}>{reactAppCopyrightHolder}</Link>
      {` ${copyrightYear}. All rights reserved.`}
    </Typography>
  );
};

OurCopyright.whyDidYouRender = true;

export default OurCopyright;
