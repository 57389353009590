import { CardContent, Skeleton, Typography } from '@mui/material';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { ourIsoToWeekDisplayDateTime } from '@petconsole/pure-fe-utils';

interface OurPostCardTitleProps {
  title?: string;
  createdAt?: string;
}

const OurPostCardTitle = ({ title, createdAt }: OurPostCardTitleProps) => {
  const { memberName } = useCurrentMember();

  return (
    <CardContent sx={{ py: 0.5 }}>
      <Typography align="center">
        <strong>{title}</strong>
      </Typography>
      {!memberName && (
        <Typography variant="body2" color="textSecondary" align="center">
          {createdAt ? ourIsoToWeekDisplayDateTime(createdAt) : <Skeleton />}
        </Typography>
      )}
    </CardContent>
  );
};

OurPostCardTitle.whyDidYouRender = true;

export default OurPostCardTitle;
