import { MemberAvatarMenuItems } from '../../../types';
import OurAppMemberAvatar from '../appMemberAvatar/OurAppMemberAvatar';
import OurAppMenuToolBar from '../appMenu/OurAppMenuToolBar';
import OurAppNameBox from './OurAppNameBox';

interface MemberMenuBarProps {
  avatarMenuItems: MemberAvatarMenuItems;
}

const MemberMenuBar = ({ avatarMenuItems }: MemberMenuBarProps) => (
  <OurAppMenuToolBar>
    <OurAppNameBox />
    <OurAppMemberAvatar avatarMenuItems={avatarMenuItems} />
  </OurAppMenuToolBar>
);

MemberMenuBar.whyDidYouRender = true;

export default MemberMenuBar;
