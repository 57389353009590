// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, GetPartialData, InitialEntity, LatLongSource, TimeColumns } from '../../types';

export interface CityStat extends TimeColumns {
  type: DataEntityType;
  cityStatId: string;
  country: string;
  province: string;
  city: string;
  latitude: number;
  longitude: number;
  coordSource: LatLongSource;
  members: number;
  updateId: string;
}

export const cityStatEntity = entityAttributes({
  name: entityName.cityStat,
  attributes: {
    category: entityCategory.item,
    columns: { crud: false, images: false, json: false, time: true },
    loadLimit: 100,
    test: { invalidData: (() => ({ city: {} })) as GetPartialData, validData: () => ({ city: 'New City' }) },
  },
});

const { id, number, strim } = schema;
export const cityStatSchema = entitySchema<CityStat>(cityStatEntity, {
  country: strim(),
  province: strim(),
  city: strim(),
  latitude: number(),
  longitude: number(),
  coordSource: strim().oneOf(['Address', 'City', 'Manual']),
  members: number(),
  updateId: id(true),
});

export const cityStatDefaults: Partial<CityStat> = {
  country: '',
  province: '',
  city: '',
  latitude: 0,
  longitude: 0,
  coordSource: 'Manual' as LatLongSource,
  members: 0,
  updateId: '',
};

export interface CityStatRecord extends CityStat, RecordType {}

export type MaybeCityStatRecord = MaybeNull<CityStatRecord>;
export type CityStatRecords = CityStatRecord[];

export const initialCityStat: InitialEntity<CityStat, CityStatRecord> = (overrides = {}) =>
  initialEntity<CityStat>(cityStatEntity, { ...cityStatDefaults, ...overrides }) as CityStatRecord;
