import { skipToken } from '@reduxjs/toolkit/query';
import { RecordType } from '@petconsole/pure-base';
import { ApiEntity } from '@petconsole/pure-shared';
import { ApiUseGetListQuery } from '../../types';

interface UseGetEntityItemsByProps<T extends RecordType = RecordType> {
  apiEntity: ApiEntity<T>;
  value: string | typeof skipToken;
  queryHook: ApiUseGetListQuery<T>;
}

const useGetEntityItemsBy = <T extends RecordType = RecordType>({ apiEntity, value, queryHook }: UseGetEntityItemsByProps<T>) => {
  const queryResults = queryHook(value || skipToken);
  const entityItems = (queryResults.data?.items ?? []) as T[];

  return { [apiEntity.plural]: entityItems, ...queryResults } as ReturnType<ApiUseGetListQuery>;
};

export default useGetEntityItemsBy;
