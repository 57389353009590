import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { newId } from '@petconsole/pure-base';
import { MessageProp, RecordType, StateReducer } from '../../../types';

interface SnackOptions {
  variant: string;
}

interface Snack {
  message: string;
  key: string;
  options: SnackOptions;
  dismissed?: boolean;
}

interface SnackState {
  snacks: Snack[];
}

const sliceName = 'snack';

const slice = {
  name: sliceName,
};

const initialState: SnackState = {
  snacks: [],
};

const getMessage = (message: string, conflictCheck: boolean, logged: boolean) => {
  if (conflictCheck && message.includes('409')) return 'Data changed since you read it. Please refresh and try again';

  if (logged) return `${message} Error is being logged and reported.`;

  return message;
};

type EnqueueReducerType = (variant: string, logged?: boolean, conflictCheck?: boolean) => StateReducer;

const enqueueReducer: EnqueueReducerType =
  (variant, logged = false, conflictCheck = false) =>
  (state, { payload }: RecordType) => {
    const { message } = payload;
    state.snacks = [
      ...state.snacks,
      {
        message: getMessage(message, conflictCheck, logged),
        key: newId(),
        options: { variant },
      },
    ];
  };

//  ValidateSliceCaseReducers<{snacks: never[]}, SliceCaseReducers<{snacks: never[]}>>

// '{ reducer(state: RecordType, { payload }: RecordType): void; }' is not assignable to type
//
// 'CaseReducer{ snacks: never[]; }, { payload: any; type: string; }>
// | CaseReducerWithPrepare{ snacks: never[]; }, PayloadAction >'.

const snackSlice = createSlice({
  name: slice.name,
  initialState,
  reducers: {
    // snackConflictCheck: enqueueReducer('warning', false, true),
    snackConflictCheck: (state, { payload }: PayloadAction<MessageProp>) => {
      const { message } = payload;
      state.snacks = [
        ...state.snacks,
        {
          message: getMessage(message, false, true),
          key: newId(),
          options: { variant: 'warning' },
        },
      ];
    },
    snackError: enqueueReducer('error'),
    snackInfo: enqueueReducer('info'),
    snackSuccess: enqueueReducer('success'),
    snackWarn: enqueueReducer('warning', true),
    // snackClose: (state: StateType, { payload }) => {
    //   state.snacks = state.snacks.map((snack: RecordType) =>
    //     !payload || snack.key === payload ? { ...snack, dismissed: true } : { ...snack },
    //   );
    // },
    // snackRemove: (state: StateType, { payload }) => {
    //   state.snacks = state.snacks.filter((snack: RecordType) => snack.key !== payload);
    // },
  },
});

const { actions, reducer } = snackSlice;

export const snackConflictCheck = actions.snackConflictCheck;

// Action creators are generated for each case reducer function
// export const {
//   snackError,
//   snackInfo,
//   snackSuccess,
//   snackWarn,
//   snackClose,
//   snackRemove,
// } = snackSlice.actions;

// export const selectSnackSnacks = (state: StateType) => state.snack.snacks;

export default reducer as Reducer;
