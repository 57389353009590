import { Images } from '@petconsole/pure-shared';
import ourMemberImageUrl from './ourMemberImageUrl';

const ourAddMemberImageUrls = ({ images = [] }: { images: Images }) =>
  images.map((image) => ({
    ...image,
    src: ourMemberImageUrl(image.s3Key),
  }));

export default ourAddMemberImageUrls;
