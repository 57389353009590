import { ComponentType } from 'react';
import { RecordType } from '@petconsole/pure-base';
import { EntityAppProps } from '@petconsole/pure-shared';
import EntityAppWrapper from './EntityAppWrapper';

interface OurEntityAppProps<T extends RecordType = RecordType> extends EntityAppProps<T> {
  App: ComponentType<EntityAppProps<T>>;
}

const EntityApp = ({ App, ...props }: OurEntityAppProps) => {
  return (
    <EntityAppWrapper {...props}>
      <App {...props} />
    </EntityAppWrapper>
  );
};

EntityApp.whyDidYouRender = true;

export default EntityApp;
