import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  ApiEntity,
  commentEntity,
  CommentRecord,
  DeleteMethod,
  GetListOptions,
  GetListResults,
  GetMethod,
  MaybeCommentRecord,
} from '@petconsole/pure-shared';
import { commonApi, del, get, update, fullPath, optionalParam } from './common';

type ApiRecord = CommentRecord;

const apiPath = '/comments';
const idPath = (id: string) => `${apiPath}/${id}`;
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi extends RecordType {
  delete: DeleteMethod<ApiRecord, ApiRecord>;
  get: GetMethod<ApiRecord, ApiRecord>;
  getListByCommentOnId: (commentOnId: string, options?: GetListOptions) => Promise<GetListResults<ApiRecord>>;
}

export type CommentApi = Api<ApiRecord> & CustomApi;

export const commentApi: CommentApi = {
  ...common,
  getListByValue,
  delete: (id, { commentId, replyId } = {} as ApiRecord) =>
    del(fullPath(idPath(id), `${optionalParam('commentId', commentId)}${optionalParam('replyId', replyId)}`)),
  get: (id, { commentId, replyId }: ApiRecord = {} as ApiRecord) =>
    get(
      fullPath(idPath(id), `${optionalParam('commentId', commentId)}${optionalParam('replyId', replyId)}`),
    ) as Promise<MaybeCommentRecord>,
  update: (_: string, body: ApiRecord) => update(apiPath, body || {}) as Promise<ApiRecord>,
  getListByCommentOnId: (commentOnId, options) => getListByValue('commentOnId', commentOnId, options),
};

export const commentApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...commentEntity, api: commentApi };
