import { RecordType } from '@petconsole/pure-base';
import { ExtendedFormik } from '@petconsole/pure-shared';
import getFormikHelperText from './getFormikHelperText';

interface GetFormikFieldPropsProps {
  formik?: ExtendedFormik;
  id?: string;
  inputProps?: RecordType;
}

const getFormikFieldProps = ({ formik, id, inputProps }: GetFormikFieldPropsProps) => {
  const InputProps = inputProps || {};
  const helperText = getFormikHelperText({ formik, id });

  if (formik) InputProps.readOnly = formik.isReadOnly;

  return { InputProps, helperText };
};

export default getFormikFieldProps;
