import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OurInteger, { OurIntegerProps } from './OurInteger';

interface OurIntegerGridItemProps extends OurIntegerProps, RegularBreakpoints {
  gridProps?: GridProps;
}

const OurIntegerGridItem = ({ gridProps, xs = 12, sm, md, lg, xl, ...rest }: OurIntegerGridItemProps) => {
  return (
    <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
      <OurInteger {...rest} />
    </Grid>
  );
};

OurIntegerGridItem.whyDidYouRender = true;

export default OurIntegerGridItem;
