import { PropsWithChildren } from 'react';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ourDefaultDarkModeBorderColor } from '../../constants';

const OutlinedLegend = ({ children }: PropsWithChildren) => {
  const theme = useTheme();

  return (
    <Box
      component="legend"
      sx={{
        float: 'unset', // Fix conflict with bootstrap
        width: 'auto', // Fix conflict with bootstrap
        overflow: 'hidden', // Fix Horizontal scroll when label too long
        display: 'block', // Fix conflict with normalize.css and sanitize.css
        padding: 0,
        height: 11, // sync with `lineHeight` in `legend` styles
        fontSize: '0.75em',
        visibility: 'hidden',
        whiteSpace: 'nowrap',
        '& > span': {
          paddingLeft: '5px',
          paddingRight: '5px',
          display: 'inline-block',
          opacity: 0,
          visibility: 'visible',
        },
        maxWidth: '100%',
        transition: theme.transitions.create('max-width', {
          duration: 100,
          easing: theme.transitions.easing.easeOut,
          delay: 50,
        }),
        borderColor: ({ palette }: Theme) => (palette.mode === 'dark' ? ourDefaultDarkModeBorderColor : undefined),
      }}
    >
      {children}
    </Box>
  );
};

OutlinedLegend.whyDidYouRender = true;

export default OutlinedLegend;
