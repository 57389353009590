import { Typography } from '@mui/material';
import OurCardImage from '../../cards/OurCardImage';
import OurUnsplashCredit from './OurUnsplashCredit';

interface OurUnsplashImageProps {
  image: string;
  alt: string;
  width?: number;
  height?: number;
  imagePath?: string;
  authorPath?: string;
  authorName?: string;
}

const OurUnsplashImage = ({ image, alt, width, height, imagePath, authorPath, authorName }: OurUnsplashImageProps) => (
  <>
    <OurCardImage {...{ image, alt, width, height }} />
    {(imagePath || authorPath) && (
      <Typography align="center" variant="body2" color="textSecondary" paragraph>
        <OurUnsplashCredit path={imagePath} author={authorPath} text={authorName} />
      </Typography>
    )}
  </>
);

export default OurUnsplashImage;
