import { Backdrop, CircularProgress } from '@mui/material';

interface OurBusyBackdropProps {
  busy: boolean;
}

const OurBusyBackdrop = ({ busy }: OurBusyBackdropProps) => (
  <Backdrop sx={{ zIndex: ({ zIndex }) => zIndex.drawer + 1, color: '#fff' }} open={busy} timeout={0}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

OurBusyBackdrop.whyDidYouRender = true;

export default OurBusyBackdrop;
