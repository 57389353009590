// noinspection ES6PreferShortImport

import { ourShopItemVariantOption } from '../../../constants';
import { entityCategory, entityName } from '../../../entity/constants';
import entityAttributes from '../../../entity/entityAttributes';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import {
  CrudColumns,
  DataEntityType,
  InitialEntity,
  MaybeS3Image,
  OurShopItemVariantOption,
  RecordType,
} from '../../../types';

export interface ShopItemVariantCode extends CrudColumns {
  type: DataEntityType;
  shopItemVariantCodeId: string;
  shopItemId: string;
  variantOption: OurShopItemVariantOption;
  order: number;
  code: string;
  name: string;
  weight?: number;
  length?: number;
  width?: number;
  height?: number;
  cost?: number;
  price?: number;
  description?: string;
  thumbnail: MaybeS3Image;
  json: RecordType;
}

export const shopItemVariantCodeEntity = entityAttributes({
  name: entityName.shopItemVariantCode,
  attributes: {
    category: entityCategory.item,
    columns: { images: false },
  },
});

const { caps, id, image, number, oneOf, pos, strim } = schema;
export const shopItemVariantCodeSchema = entitySchema<ShopItemVariantCode>(shopItemVariantCodeEntity, {
  shopItemId: id(true),
  variantOption: oneOf(ourShopItemVariantOption, ourShopItemVariantOption.Option1),
  order: number().min(1),
  code: caps().required('Variant option code is required'),
  name: strim(),
  weight: pos(),
  length: pos(),
  width: pos(),
  height: pos(),
  cost: pos(),
  price: pos(),
  description: strim(),
  thumbnail: image(),
});

export const shopItemVariantCodeDefaults: Partial<ShopItemVariantCode> = {
  shopItemId: '',
  variantOption: ourShopItemVariantOption.Option1,
  order: 0,
  code: '',
  name: '',
  weight: 0,
  length: 0,
  width: 0,
  height: 0,
  cost: 0,
  price: 0,
  description: '',
  thumbnail: null,
  json: {},
};

export interface ShopItemVariantCodeRecord extends ShopItemVariantCode, RecordType {}

export type MaybeShopItemVariantCodeRecord = ShopItemVariantCodeRecord | null;
export type ShopItemVariantCodeRecords = ShopItemVariantCodeRecord[];

export const initialShopItemVariantCode: InitialEntity<ShopItemVariantCode, ShopItemVariantCodeRecord> = (overrides = {}) =>
  initialEntity<ShopItemVariantCode>(shopItemVariantCodeEntity, { ...shopItemVariantCodeDefaults, ...overrides }) as ShopItemVariantCodeRecord;
