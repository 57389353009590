import { FormEventHandler, MouseEventHandler, ReactElement } from 'react';
import { isMobile, isTablet, withOrientationChange } from 'react-device-detect';
import {
  Add,
  Cancel,
  Delete,
  Edit,
  Email,
  NavigateNext,
  NavigateBefore,
  Print,
  Refresh,
  Save,
} from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import OurToolbarButton from '../buttons/OurToolbarButton';

export interface OurToolbarProps {
  phone?: boolean;
  adding?: boolean;
  editing?: boolean;
  canEdit?: boolean;
  prevButton?: ReactElement;
  nextButton?: ReactElement;
  saveButton?: ReactElement;
  onAdd?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  onEmail?: MouseEventHandler<HTMLButtonElement>;
  onNext?: MouseEventHandler<HTMLButtonElement>;
  onPrev?: MouseEventHandler<HTMLButtonElement>;
  onPrint?: MouseEventHandler<HTMLButtonElement>;
  onSave?: FormEventHandler<HTMLFormElement>;
  onRefresh?: MouseEventHandler<HTMLButtonElement>;
  addDisabled?: boolean;
  cancelDisabled?: boolean;
  editDisabled?: boolean;
  deleteDisabled?: boolean;
  emailDisabled?: boolean;
  nextDisabled?: boolean;
  prevDisabled?: boolean;
  printDisabled?: boolean;
  saveDisabled?: boolean;
  refreshDisabled?: boolean;
  isPortrait?: boolean;
  sx?: RecordType;
}

const OurToolbar = ({
  phone,
  adding = false,
  editing = false,
  canEdit = true,
  prevButton,
  nextButton,
  saveButton,
  onAdd,
  onCancel,
  onEdit,
  onDelete,
  onEmail,
  onNext,
  onPrev,
  onPrint,
  onSave,
  onRefresh,
  addDisabled,
  cancelDisabled,
  editDisabled,
  deleteDisabled,
  emailDisabled,
  nextDisabled,
  prevDisabled,
  printDisabled,
  saveDisabled,
  refreshDisabled,
  isPortrait,
  sx,
}: OurToolbarProps) => {
  const portraitPhone = isMobile && !isTablet && isPortrait;
  if ((phone && !portraitPhone) || (phone === false && portraitPhone)) return null;

  const justifyContent = portraitPhone && phone ? 'center' : 'flex-end';
  const viewOnly = !adding && !editing;
  const editMode = adding || editing;

  return (
    <Box id="toolbar-outer-box" width="100%" sx={sx}>
      {phone && portraitPhone && (
        <Box display="flex" width="100%" py={1}>
          <Divider style={{ width: '100%' }} />
        </Box>
      )}
      <Box display="flex" width="100%" justifyContent={justifyContent}>
        <OurToolbarButton
          Icon={NavigateBefore}
          mode={viewOnly}
          onClick={onPrev}
          disabled={prevDisabled}
          tip="Previous"
          button={prevButton}
        />
        <OurToolbarButton
          Icon={NavigateNext}
          mode={viewOnly}
          onClick={onNext}
          disabled={nextDisabled}
          tip="Next"
          button={nextButton}
        />
        <OurToolbarButton
          Icon={Add}
          mode={viewOnly}
          onClick={onAdd}
          disabled={addDisabled}
          tip="Add"
        />
        <OurToolbarButton
          Icon={Edit}
          mode={viewOnly && canEdit}
          onClick={onEdit}
          disabled={editDisabled}
          tip="Edit"
        />
        <OurToolbarButton
          Icon={Cancel}
          mode={editMode}
          onClick={onCancel}
          disabled={cancelDisabled}
          tip="Cancel"
          size="large" // Cancel and Save don't appear with other buttons
        />
        <OurToolbarButton
          Icon={Save}
          mode={editMode}
          onClick={onSave as unknown as  MouseEventHandler<HTMLButtonElement>}
          disabled={saveDisabled}
          tip="Save"
          size="large" // Cancel and Save don't appear with other buttons
          button={saveButton}
        />
        <OurToolbarButton
          Icon={Delete}
          mode={viewOnly}
          onClick={onDelete}
          disabled={deleteDisabled}
          tip="Delete"
        />
        <OurToolbarButton
          Icon={Email}
          mode={viewOnly}
          onClick={onEmail}
          disabled={emailDisabled}
          tip="Email"
        />
        <OurToolbarButton
          Icon={Print}
          mode={viewOnly}
          onClick={onPrint}
          disabled={printDisabled}
          tip="Print"
        />
        <OurToolbarButton
          Icon={Refresh}
          mode={viewOnly}
          onClick={onRefresh}
          disabled={refreshDisabled}
          tip="Refresh"
        />
      </Box>
    </Box>
  );
};

export default withOrientationChange(OurToolbar);
