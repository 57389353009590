// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { site } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import {
  AddressColumns,
  ContactColumns,
  DataEntityType,
  DefaultColumns,
  GetPartialData,
  InitialEntity,
  RatingColumns,
} from '../../types';

interface Business extends AddressColumns, ContactColumns, DefaultColumns, RatingColumns {
  type: DataEntityType;
  businessId: string;
  businessName: string;
  businessType: string;
  legalName: string;
}

const { General, Details, Photos, Comments, Preview } = entityTabName;
export const businessEntity = entityAttributes({
  name: entityName.business,
  attributes: {
    category: entityCategory.business,
    columns: { address: true, contact: true, rating: true },
    plural: `${entityName.business}es`,
    loadLimit: 20,
    maxImages: 8,
    nameField: `${entityName.business}Name`,
    site: site.petConsole,
    shared: true,
    tabs: [General, Details, Photos, Comments, Preview],
    test: {
      invalidData: (() => ({ contactName: {} })) as GetPartialData,
      validData: () => ({ contactName: 'New contact' }),
    },
  },
});

const { strim } = schema;
export const businessSchema = entitySchema<Business>(businessEntity, {
  businessName: strim().required('Business name is required'),
  businessType: strim(),
  legalName: strim(),
});

export const businessDefaults: Partial<Business> = {
  businessName: '',
  businessType: '',
  legalName: '',
};

export interface BusinessRecord extends Business, RecordType {}

export type MaybeBusinessRecord = MaybeNull<BusinessRecord>;
export type BusinessRecords = BusinessRecord[];

export const initialBusiness: InitialEntity<Business, BusinessRecord> = (overrides = {}) =>
  initialEntity<Business>(businessEntity, { ...businessDefaults, ...overrides }) as BusinessRecord;
