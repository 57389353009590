import { nodeEnv, ReactionRecord } from '@petconsole/pure-shared';
import { FetchReactionPayloadCreator } from '../../types';

const payloadCreatorFetchReaction: FetchReactionPayloadCreator =
  (idName, entityType, api) =>
  async ({ [idName]: id }, { getState }) => {
    const { user } = getState();
    const reactionToId = id;
    const reactionToType = entityType;

    // If we don't have a member logged in, we don't want to look for a reaction record
    if (nodeEnv !== 'test' && !user.user.member)
      return { reactionToId, reactionToType } as ReactionRecord;

    return api.getByReactionTo(id as string).then((reaction) => {
      if (reaction) return reaction;

      const { memberId, memberName } = user.user.member;

      return { memberId, memberName, reactionToId, reactionToType } as ReactionRecord;
    });
  };

export default payloadCreatorFetchReaction;
