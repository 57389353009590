import { CacheInstance } from '../types';

interface ClearCacheItemsProps {
  cache: CacheInstance;
  prefix: string;
}

const clearCacheItems = ({ cache, prefix }: ClearCacheItemsProps) => {
  // @ts-expect-error - storage isn't publicly exposed
  const storage = cache.storage;

  const keys = Object.keys(storage).filter((key) => key.startsWith(prefix));

  return Promise.all(keys.map((key) => cache.removeItem(key)));
};

export default clearCacheItems;
