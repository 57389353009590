import { PropsWithChildren } from 'react';
import { Grid, SxProps } from '@mui/material';
import useOurPhone from '../../hooks/useOurPhone';
import OutlinedGrid from './OutlinedGrid';

export interface OutlinedGridContentProps extends PropsWithChildren {
  id: string;
  outline?: boolean;
  label: string;
  spacing?: number;
  phoneSafe?: boolean;
  sx?: SxProps;
}

const OutlinedGridContent = ({ id, outline = true, label, spacing = 1, phoneSafe = true, sx, children }: OutlinedGridContentProps) => {
  const { portraitPhone } = useOurPhone();

  if (!outline || (phoneSafe && portraitPhone))
    return (
      <Grid container id={id} spacing={spacing} sx={sx}>
        {children}
      </Grid>
    );

  return (
    <OutlinedGrid id={id} label={label} spacing={spacing} sx={sx}>
      {children}
    </OutlinedGrid>
  );
};

OutlinedGridContent.whyDidYouRender = true;

export default OutlinedGridContent;
