import { RecordType } from '@petconsole/pure-base';
import { ApiEntity } from '@petconsole/pure-shared';
import { ApiUseDeleteMutation } from '../../types';

interface UseDeleteEntityProps<T extends RecordType = RecordType> {
  apiEntity: ApiEntity<T>;
  mutationHook: ApiUseDeleteMutation<T>;
}

const useDeleteEntity = <T extends RecordType = RecordType>({ apiEntity, mutationHook }: UseDeleteEntityProps<T>) => {
  // Note: This separation is primarily to facilitate easier testing and mocking
  const [deleteEntityTrigger, mutationResults] = mutationHook();

  const deleteEntity = ({ [apiEntity.idName]: id }: T) =>
    deleteEntityTrigger(id as string).unwrap() as Promise<T>;

  return { [`delete${apiEntity.proper}`]: deleteEntity, ...mutationResults };
};

export default useDeleteEntity;
