import { shopApiEntity } from '@petconsole/pure-fe-api';
import { ShopRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, getOne, getOneByUrlOrId, updateOne } from '../../../helpers';

type ApiRecord = ShopRecord;

const shopEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = shopApiEntity;

  return {
    createShop: createOne(builder, api, tag),
    getShop: getOne(builder, api, tag),
    getShopByUrlOrId: getOneByUrlOrId(builder, api, tag),
    updateShop: updateOne(builder, api.update, tag),
  };
};

export default shopEndpoints;
