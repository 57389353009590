import { PropsWithChildren } from 'react';
import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { EntityFormik, EntityRecord, ExtendedFormik } from '@petconsole/pure-shared';

interface OurFormProps<T extends EntityRecord = EntityRecord> extends PropsWithChildren {
  formik?: EntityFormik<T> | ExtendedFormik;
  onSubmit?: BoxProps['onSubmit'];
  sx?: SxProps<Theme>;
}

const OurForm = <T extends EntityRecord = EntityRecord>({ formik, onSubmit, sx = {}, children, ...rest }: OurFormProps<T>) => {
  return (
    <Box
      component="form"
      // noValidate
      onSubmit={formik ? formik.handleSubmit as unknown as BoxProps['onSubmit'] : onSubmit}
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...(sx || {}) }}
      {...rest}
    >
      {children}
    </Box>
  );
};

OurForm.whyDidYouRender = true;

export default OurForm;
