import { useOurOnClickRedirect } from '@petconsole/pure-fe-utils';
import { webPath } from '@petconsole/pure-shared';
import useOurWidth from '../../hooks/useOurWidth';
import OurButton, { OurButtonProps } from './OurButton';

interface OurLoginButtonProps extends Omit<OurButtonProps, 'label'> {
  label?: string;
}

const OurLoginButton = (props: OurLoginButtonProps) => {
  const width = useOurWidth();
  const { onClick } = useOurOnClickRedirect({ path: webPath.login });

  const label = props.label || `${width === 'xs' ? 'Login' : 'Member Login'}`;

  return <OurButton {...props} onClick={onClick} label={label} />;
};

OurLoginButton.whyDidYouRender = true;

export default OurLoginButton;
