import { useCallback, useState } from 'react';
import { ourMemberImageUrl, ourPutImage, OurPutImageProps } from '@petconsole/pure-fe-api';
import { Image } from '@petconsole/pure-shared';
import useOurError from '../../error/useOurError';

interface UseOurBannerImageProps {
  image?: Image;
}

const useOurBannerImage = ({image}: UseOurBannerImageProps) => {
  const {log} = useOurError();

  const [banner, setBanner] = useState(undefined as Image | undefined);

  const {s3Key} = image || ({} as Image);

  const getBanner = useCallback(
    () => setBanner((s3Key ? {...image, src: ourMemberImageUrl(s3Key)} : {}) as Image),
    [image, s3Key],
  );

  const saveBanner = useCallback(() => {
    return banner?.newImage ? ourPutImage({...banner, log} as OurPutImageProps) : Promise.resolve(null);
  }, [banner, log]);

  const bannerFrom = () => ({
    title: banner?.title,
    s3Key: banner?.s3Key,
    contentType: banner?.contentType,
  } as Partial<Image>);

  return {banner, setBanner, getBanner, saveBanner, bannerFrom};
};

export default useOurBannerImage;
