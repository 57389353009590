import { Button, ButtonProps, SxProps, Theme } from '@mui/material';
import { ourButtonBorderRadius } from '../../constants';

export interface OurButtonProps extends ButtonProps {
  label: string;
  small?: boolean;
  caps?: boolean;
  lime?: boolean;
  sx?: SxProps<Theme>;
}

const OurButton = ({
  label,
  color = 'primary',
  size = 'medium',
  small,
  caps,
  lime,
  sx = {},
  ...rest
}: OurButtonProps) => (
  <Button
    variant="contained"
    color={color}
    fullWidth
    sx={{
      borderRadius: ourButtonBorderRadius,
      ...(lime ? { backgroundColor: 'limegreen' } : {}),
      ...(caps === false ? { textTransform: 'none' } : {}),
      ...sx,
    }}
    size={small ? 'small' : size}
    {...rest}
  >
    {label}
  </Button>
);

OurButton.whyDidYouRender = true;

export default OurButton;
