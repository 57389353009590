// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import {
  defaultStripeTaxCode,
  ourUomQuantity,
  pclAvatar,
  pclCurrencySets,
  pclIntervalSets,
  pclTierSets,
} from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { CrudColumns, DataEntityType, GetPartialData, InitialEntity } from '../../types';

export interface PclService extends CrudColumns {
  type: DataEntityType;
  pclServiceId: string;
  serviceCode: string;
  name: string;
  subscription: boolean;
  updateStripe: boolean;
  pclAvatar: string;
  avatar: string;
  taxCode: string;
  quantityUom: string;
  currencySet: string;
  intervalSet: string;
  tierSet: string;
  active: boolean;
}

const { Preview } = entityTabName;
export const pclServiceEntity = entityAttributes({
  name: entityName.pclService,
  attributes: {
    adminOnly: { create: true },
    category: entityCategory.item,
    columns: { active: true, images: false, json: false },
    loadLimit: 25,
    ownerIfEditing: true,
    restrictGet: false,
    tabs: [Preview],
    test: { invalidData: (() => ({ name: {} })) as GetPartialData, validData: () => ({ name: 'New Name' }) },
  },
});

const { boolean, oneOf, oneOfSetNames, pclServiceCode: schemaServiceCode, strim } = schema;
export const pclServiceSchema = entitySchema<PclService>(pclServiceEntity, {
  serviceCode: schemaServiceCode().required(),
  name: strim().required('Service name is required'),
  subscription: boolean(),
  updateStripe: boolean(),
  pclAvatar: oneOf(pclAvatar, pclAvatar.default),
  avatar: strim(),
  taxCode: strim().default(defaultStripeTaxCode),
  quantityUom: oneOf(ourUomQuantity, ourUomQuantity.EA),
  currencySet: oneOfSetNames(pclCurrencySets, pclCurrencySets.none.name),
  intervalSet: oneOfSetNames(pclIntervalSets, pclIntervalSets.none.name),
  tierSet: oneOfSetNames(pclTierSets, pclTierSets.none.name),
});

export const pclServiceDefaults: Partial<PclService> = {
  serviceCode: '',
  name: '',
  subscription: false,
  updateStripe: true,
  pclAvatar: pclAvatar.default,
  avatar: '',
  taxCode: defaultStripeTaxCode,
  quantityUom: ourUomQuantity.EA,
  currencySet: pclCurrencySets.none.name,
  intervalSet: pclIntervalSets.none.name,
  tierSet: pclTierSets.none.name,
};

export interface PclServiceRecord extends PclService, RecordType {}

export type MaybePclServiceRecord = MaybeNull<PclServiceRecord>;
export type PclServiceRecords = PclServiceRecord[];

export const initialPclService: InitialEntity<PclService, PclServiceRecord> = (overrides = {}) =>
  initialEntity<PclService>(pclServiceEntity, { ...pclServiceDefaults, ...overrides }) as PclServiceRecord;
