import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  GetListOptions,
  GetListResults,
  MaybeShopItemRecord,
  ShopItemRecord,
  shopItemEntity, ApiEntity,
} from '@petconsole/pure-shared';
import { commonApi, pathParam } from './common';

type ApiRecord = ShopItemRecord;
type ListReturns = Promise<GetListResults<ApiRecord>>;

const apiPath = '/shopItems';
const { getByQuery, getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi extends RecordType {
  getByShopAndSku: ({ shopId, sku }: { shopId: string; sku: string }) => Promise<MaybeShopItemRecord>;
  getListByShop: (shopId: string, options?: GetListOptions) => ListReturns;
  getListByShopAndAvailable: (shopId: string, options?: GetListOptions) => ListReturns;
}

export type ShopItemApi = Api<ApiRecord> & CustomApi;

export const shopItemApi: ShopItemApi = {
  ...common,
  getByQuery,
  getListByValue,
  getByShopAndSku: ({ shopId, sku }) =>
    getByQuery(`${pathParam('shopId', shopId)}${pathParam('sku', sku)}`) as Promise<MaybeShopItemRecord>,
  getListByShop: (shopId, options) => getListByValue('shopId', shopId, options),
  getListByShopAndAvailable: async (shopId, options) => {
    const result = await getListByValue('shopId', shopId, options);

    if (!result.items) return result;

    return { ...result, items: result.items.filter((item: RecordType) => item.isActive) };
  },
};

export const shopItemApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...shopItemEntity, api: shopItemApi };
