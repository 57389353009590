import { OurLazyLoad } from '@petconsole/pure-fe-mui';
import { RecordType } from '@petconsole/pure-shared';

const LazyForgotPassword = (props: RecordType) => <OurLazyLoad load={() => import('./ForgotPassword')} {...props} />;
export { LazyForgotPassword as ForgotPassword };

const LazyJoin = (props: RecordType) => <OurLazyLoad load={() => import('./Join')} {...props} />;
export { LazyJoin as Join };

const LazyLogin = (props: RecordType) => <OurLazyLoad load={() => import('./Login')} {...props} />;
export { LazyLogin as Login };

const LazyLogout = (props: RecordType) => <OurLazyLoad load={() => import('./Logout')} {...props} />;
export { LazyLogout as Logout };

const LazyVerifyCode = (props: RecordType) => <OurLazyLoad load={() => import('./VerifyCode')} {...props} />;
export { LazyVerifyCode as VerifyCode };
