import { useEffect } from 'react';
import { MaybeMemberRecord, profileSteps, webPath } from '@petconsole/pure-shared';
import useOurRedirect from './useOurRedirect';
import useOurNavigate from '../router/useOurNavigate';

interface UseOurProfileWizardRedirectProps {
  member: MaybeMemberRecord;
}

const useOurProfileWizardRedirect = ({ member }: UseOurProfileWizardRedirectProps) => {
  const { redirect } = useOurRedirect();
  const { navigate, pathname } = useOurNavigate();

  const { pathname: redirectTo = '', search: redirectWith } = redirect || {};

  // If the member hasn't completed the profile wizard, redirect them
  useEffect(() => {
    if (!member || member.profileStep >= profileSteps) return;

    if (pathname === webPath.profileWizard) return;

    const searchParams = new URLSearchParams({
      ...(redirectTo && { redirectTo }),
      ...(redirectWith && { redirectWith }),
    });

    navigate({ pathname: webPath.profileWizard, search: searchParams.toString() });
  }, [navigate, pathname, member, redirectTo, redirectWith]);
};

export default useOurProfileWizardRedirect;
