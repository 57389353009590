import { stripeAccountApiEntity } from '@petconsole/pure-fe-api';
import { StripeAccountRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, getOne, getOneBy, patchIdOne, updateOne } from '../../../helpers';

type ApiRecord = StripeAccountRecord;

const stripeAccountEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = stripeAccountApiEntity;

  return {
    createStripeAccount: createOne(builder, api, tag),
    getStripeAccount: getOne(builder, api, tag),
    getStripeAccountByShop: getOneBy(builder, api.getByShop, tag),
    updateStripeAccount: updateOne(builder, api.update, tag),
    updateStripeAccountStatus: patchIdOne(builder, api.updateStripeStatus, tag),
  };
};

export default stripeAccountEndpoints;
