import { Grid } from '@mui/material';
import OurCount from '../misc/OurCount';
import OurLikeButton, { OurLikeButtonProps } from './OurLikeButton';

interface OurLikeCountButtonProps extends OurLikeButtonProps {
  likes?: number;
}

const OurLikeCountButton = ({ likes, ...rest }: OurLikeCountButtonProps) => (
  <Grid container flexDirection="column" alignItems="center">
    <OurCount count={likes} name="Like" single="like" plural="likes" />
    <OurLikeButton {...rest} />
  </Grid>
);

OurLikeCountButton.whyDidYouRender = true;

export default OurLikeCountButton;
