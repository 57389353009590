import { MoreHoriz, MoreVert } from '@mui/icons-material';
import { Icon } from '@petconsole/pure-shared';
import ToggleButton, { ToggleButtonProps } from './ToggleButton';

export interface OurOptionsButtonProps extends Omit<ToggleButtonProps, 'tip' | 'OpenIcon' | 'CloseIcon'> {
  tip?: string;
  OpenIcon?: Icon;
  CloseIcon?: Icon;
}

const OurOptionsButton = ({ setIsOpen, disabled, ...rest }: OurOptionsButtonProps) => (
  <ToggleButton
    {...rest}
    tip="Options"
    disabled={disabled || !setIsOpen}
    OpenIcon={MoreHoriz}
    CloseIcon={MoreVert}
    setIsOpen={setIsOpen}
  />
);

OurOptionsButton.whyDidYouRender = true;

export default OurOptionsButton;
