// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface VanBuilder extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  vanBuilderId: string;
  name: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const vanBuilderEntity = entityAttributes({
  name: entityName.vanBuilder,
  attributes: {
    category: entityCategory.business,
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    site: anySite.busConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const vanBuilderSchema = entitySchema<VanBuilder>(vanBuilderEntity, {
  name: strim().required('Business name is required'),
});

export const vanBuilderDefaults: Partial<VanBuilder> = {
  name: '',
};

export interface VanBuilderRecord extends VanBuilder, RecordType {}

export type MaybeVanBuilderRecord = MaybeNull<VanBuilderRecord>;
export type VanBuilderRecords = VanBuilderRecord[];

export const initialVanBuilder: InitialEntity<VanBuilder, VanBuilderRecord> = (overrides = {}) =>
  initialEntity<VanBuilder>(vanBuilderEntity, { ...vanBuilderDefaults, ...overrides }) as VanBuilderRecord;
