import { Box, Typography } from '@mui/material';
import { newId } from '@petconsole/pure-base';

interface OurFormikErrorsProps {
  errors: object;
  heading?: boolean;
}

const OurFormikErrors = ({ errors, heading = true }: OurFormikErrorsProps) => {
  // Called recursively
  const errorList = (items: object) => {
    const list = [] as string[];

    if (!items) return list;

    Object.values(items).forEach((error) => {
      if (typeof error === 'string') list.push(error);
      else list.push(...errorList(error));
    });

    return list;
  };

  const errorEntries = (items: object) => {
    const allErrors = errorList(items);

    const groups = [] as { error: string, count: number }[];

    allErrors.forEach((error) => {
      const index = groups.findIndex((group) => group.error === error);

      if (index < 0) groups.push({ count: 1, error });
      else groups[index].count += 1;
    });

    return groups.map(({ count, error }) => (count > 1 ? `${count} * ${error}` : error));
  };

  if (!errors || JSON.stringify(errors) === '{}') return null;

  return (
    <div>
      {heading && (
        <Typography component="div" align="left">
          Validation Errors
        </Typography>
      )}
      {errorEntries(errors).map((error) => (
        <Box key={`${newId()}-error`} display="flex">
          <Typography color={(theme) => theme.palette.warning.main}>{`Warning: ${error}`}</Typography>
        </Box>
      ))}
    </div>
  );
};

OurFormikErrors.whyDidYouRender = true;

export default OurFormikErrors;
