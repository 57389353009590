import { RequiredCrudSliverHooks } from '@petconsole/pure-fe-state';
import { EntityAttributes } from '@petconsole/pure-shared';
import useOurErrorLogger from '../error/useOurErrorLogger';
import useOurSnacks from '../hooks/useOurSnacks';
import { AddEntityType } from '../types';
import useOurAddListEntity from './helpers/useOurAddListEntity';
import OurEntityTabPage, { OurEntityTabPageProps } from './OurEntityTabPage';

export interface OurListEntityTabPageProps extends Omit<OurEntityTabPageProps, 'helpContent'> {
  entity: EntityAttributes;
  help?: string;
  add?: boolean;
}

const OurListEntityTabPage = ({
  margins = { md: 2, lg: 3 },
  entity,
  hooks,
  help = '',
  add = true,
  addProps,
  ...rest
}: OurListEntityTabPageProps) => {
  useOurErrorLogger();
  useOurSnacks();

  const { name: entityName } = entity;
  const crudHooks = hooks[`${entityName}Hooks`] as RequiredCrudSliverHooks;
  const { AddListEntity } = useOurAddListEntity({ entity, crudHooks, addProps });

  return (
    <OurEntityTabPage
      margins={margins}
      hooks={hooks}
      helpContent={help}
      AddEntity={add ? (AddListEntity as AddEntityType) : undefined}
      {...rest}
    />
  );
};

OurListEntityTabPage.whyDidYouRender = true;

export default OurListEntityTabPage;
