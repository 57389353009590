import { RecordType, dateToString } from '@petconsole/pure-base';

const ourUnadaptedData = (data: RecordType) => ({
  ...Object.entries(data).reduce(
    (adapted, [key, value]) => ({
      ...adapted,
      [key]: key.endsWith('Date') ? dateToString(value as Date) : value,
    }),
    {},
  ),
});

export default ourUnadaptedData;
