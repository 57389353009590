import { MouseEventHandler } from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { SetState } from '@petconsole/pure-shared';
import OurButton from '../../buttons/OurButton';
import OurDraggableDialog from '../../dialogs/OurDraggableDialog';

export interface ClickPricingProps {
  entityId: string;
  businessName: string;
}

export interface SubscribeDialogProps {
  onClickPricing: MouseEventHandler;
  clickPricingProps?: ClickPricingProps
  setClickPricingProps: SetState<ClickPricingProps | undefined>
}

const SubscribeDialog = ({ onClickPricing, clickPricingProps, setClickPricingProps }: SubscribeDialogProps) => {
  const businessName = clickPricingProps?.businessName;
  const onCancel = () => setClickPricingProps(undefined);

  return (
    <OurDraggableDialog title={businessName} isOpen={!!businessName} onClose={onCancel}>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Typography paragraph mt={1}>
          Is <strong>{businessName}</strong> yours?
        </Typography>
        <Typography paragraph>Would you like to manage and customize its content?</Typography>
        <Typography paragraph>
          We have monthly and annual subscription options available.
        </Typography>
        <Typography paragraph>Choose Pricing to review our options for {businessName}.</Typography>
      </DialogContent>
      <DialogActions>
        <OurButton label="Cancel" color="secondary" onClick={onCancel} />
        <OurButton label="Pricing" onClick={onClickPricing} />
      </DialogActions>
    </OurDraggableDialog>
  );
};

SubscribeDialog.whyDidYouRender = true;

export default SubscribeDialog;
