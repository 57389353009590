import { RecordType, stringToDate } from '@petconsole/pure-base';

const ourAdaptedData = (data: RecordType) => ({
  ...Object.entries(data).reduce(
    (adapted, [key, value]) => ({
      ...adapted,
      [key]: key.endsWith('Date') ? stringToDate(value as string) : value,
    }),
    {},
  ),
});

export default ourAdaptedData;
