// noinspection ES6PreferShortImport

import { unwrapResult } from '@reduxjs/toolkit';
import { RecordType } from '@petconsole/pure-base';
import { ourUserHooks } from '../slices/system/user/userSlice';
import { WrappedResult } from '../types';

const useCurrentMember = () => {
  const { userHooks, customHooks } = ourUserHooks;

  const member = customHooks.useMember();
  const avatar = customHooks.useAvatar();
  const updateUser = userHooks.useUpdate();

  const { memberId, memberName, email } = member || {};

  const update = (changes: RecordType) => unwrapResult(updateUser({ user: changes }) as unknown as WrappedResult);

  return { memberId, memberName, member, avatar, email, update };
};

export default useCurrentMember;
