import {
  AnySite,
  ApiMethodType,
  CapitalizeValue,
  EventSource,
  FriendEvent,
  FriendStatus,
  GenderType,
  GsIndex,
  KeyIsValue,
  KeyString,
  LayoutCellType,
  MailFolderType,
  MemberEvent,
  MyShopItemVariantSetOn,
  OrderStatus,
  OrderedByType,
  OurCountryA2,
  OurCurrencyCode,
  OurShopItemType,
  OurShopItemVariantOption,
  OurShopItemVariantSetOn,
  OurUomLength,
  OurUomQuantity,
  OurUomType,
  OurUomWeight,
  PaymentProcessor,
  PclAvatar,
  PclServiceCode,
  PostEventType,
  RatingValues,
  S3Folder,
  ShippingType,
  Site,
  TaxType,
  ValueIsLower,
  VisibilityType,
  RecordOfNamedSets,
  PclTierName,
  EditMessageType,
  OurPetServiceType,
} from './types';

// noinspection JSUnresolvedReference
export const {
  AWS_REGION: awsRegion = 'us-west-2',
  MEMBER_IMAGE_PATH: memberImagePath = 'https://mimage.pcl-dev.com',
  NODE_ENV: nodeEnv = '',
  REACT_APP_API_SITE: reactAppApiSite = '',
  REACT_APP_BUSCONSOLE_ROOT: reactAppBusConsoleRoot = '',
  REACT_APP_COMMON_MEDIA_PATH: reactAppCommonMediaPath = '',
  REACT_APP_COPYRIGHT_HOLDER: reactAppCopyrightHolder = '',
  REACT_APP_COPYRIGHT_YEAR: reactAppCopyrightYear = '',
  REACT_APP_FACEBOOK_LOGIN_APP_ID: reactAppFacebookLoginAppId = '',
  REACT_APP_HTML_APP_NAME: reactAppHtmlAppName = '',
  REACT_APP_HTML_SITE_NAME: reactAppHtmlSiteName = '',
  REACT_APP_MEDIA_PATH: reactAppMediaPath = '',
  REACT_APP_MEMBER_IMAGE_PATH: reactAppMemberImagePath = '',
  REACT_APP_PETCONSOLE_ROOT: reactAppPetConsoleRoot = '',
  REACT_APP_ROOT_PATH: reactAppRootPath = '',
  // REACT_APP_S3_BUCKET: reactAppS3Bucket,
  // REACT_APP_S3_REGION: reactAppS3Region,
  REACT_APP_SEGMENT_WRITE_KEY: reactAppSegmentWriteKey = '',
  REACT_APP_STAGE: reactAppStage = '',
  REACT_APP_STRIPE_PUBLIC_KEY: reactAppStripePublicKey = '',
  REACT_APP_VANCONSOLE_ROOT: reactAppVanConsoleRoot = '',
} = process.env;

// TODO: Find a better way to deal with siteId on entities that require them
// noinspection SpellCheckingInspection
export const petConsoleId = '01GBX3HQ7JM4XKDH1927GDNRYS';

export const defaultAppRate = 0.025; // default application fee as percentage
export const defaultItemPattern = '{PREFIX}-{V1}-{V2}';
export const defaultOrderNumberPattern = 'O-########';
export const defaultRatingValues = new Array(10).fill(0) as RatingValues;
export const defaultStripeTaxCode = '10103000';

export const gsIndexes = ['GS1', 'GS2', 'GS3', 'GS4'] as const;
export const gsIndex: KeyIsValue<GsIndex> = {
  GS1: 'GS1',
  GS2: 'GS2',
  GS3: 'GS3',
  GS4: 'GS4',
};

export const logEditConflicts = () => true; // At some point we can make this admin configurable

export const ourCountryA2s = ['CA', 'US'] as const;
export const ourCountryA2: KeyIsValue<OurCountryA2> = {
  CA: 'CA',
  US: 'US',
} as const;

export const ourCurrencyCodes = ['CAD', 'USD', 'EUR'] as const;
export const ourCurrencyCode: KeyIsValue<OurCurrencyCode> = {
  CAD: 'CAD',
  USD: 'USD',
  EUR: 'EUR',
} as const;
export const ourDefaultCurrencyCode = ourCurrencyCode.CAD;

export const ourPetServiceTypes = [
  'Boarding',
  'DogWalking',
  'Grooming',
  'PetFood',
  'PetSitting',
  'Training',
  'VetCare',
  'Other',
] as const;
export const ourPetServiceType: KeyIsValue<OurPetServiceType> = {
  Boarding: 'Boarding',
  DogWalking: 'DogWalking',
  Grooming: 'Grooming',
  PetFood: 'PetFood',
  PetSitting: 'PetSitting',
  Training: 'Training',
  VetCare: 'VetCare',
  Other: 'Other',
} as const;

export const ourShopItemTypes = ['Product', 'Service'] as const;
export const ourShopItemType: KeyIsValue<OurShopItemType> = {
  Product: 'Product',
  Service: 'Service',
} as const;

export const ourShopItemVariantOptions = ['Option1', 'Option2'] as const;
export const ourShopItemVariantOption: KeyIsValue<OurShopItemVariantOption> = {
  Option1: 'Option1',
  Option2: 'Option2',
} as const;

export const ourShopItemVariantSetOns = ['Item', ...ourShopItemVariantOptions, 'Variant'] as const;
export const ourShopItemVariantSetOn: KeyIsValue<OurShopItemVariantSetOn> = {
  Item: 'Item',
  ...ourShopItemVariantOption,
  Variant: 'Variant',
} as const;

export const ourUomLengths = ['CM', 'IN'] as const;
export const ourUomLength: KeyIsValue<OurUomLength> = {
  CM: 'CM',
  IN: 'IN',
} as const;

export const ourUomQuantities = ['EA', 'HR'] as const;
export const ourUomQuantity: KeyIsValue<OurUomQuantity> = {
  EA: 'EA',
  HR: 'HR',
} as const;

export const ourUomTypes = ['Length', 'Quantity', 'Weight'] as const;
export const ourUomType: KeyIsValue<OurUomType> = {
  Length: 'Length',
  Quantity: 'Quantity',
  Weight: 'Weight',
} as const;

export const ourUomWeights = ['LB', 'KG'] as const;
export const ourUomWeight: KeyIsValue<OurUomWeight> = {
  LB: 'LB',
  KG: 'KG',
} as const;

// TODO: Obsolete
export const myShopItemVariantSetOns = ['Item', 'Option1', 'Option2', 'Variant'] as const;
export const myShopItemVariantSetOn: KeyIsValue<MyShopItemVariantSetOn> = {
  Item: 'Item',
  Option1: 'Option1',
  Option2: 'Option2',
  Variant: 'Variant',
} as const;

export const apiMethodTypes = [
  'create',
  'delete',
  'get',
  'getByInput',
  'getList',
  'getListById',
  'patch',
  'patchId',
  'post',
  'update',
] as const;
export const apiMethodType: KeyIsValue<ApiMethodType> = {
  create: 'create',
  delete: 'delete',
  get: 'get',
  getByInput: 'getByInput',
  getList: 'getList',
  getListById: 'getListById',
  patch: 'patch',
  patchId: 'patchId',
  post: 'post',
  update: 'update',
} as const;

export const eventSources = ['Member', 'System', 'Visitor', 'Stripe'] as const;
export const eventSource: KeyIsValue<EventSource> = {
  Member: 'Member',
  System: 'System',
  Visitor: 'Visitor',
  Stripe: 'Stripe',
} as const;

export const friendEvents = [
  'friendAdded',
  'friendUpdated',
  'friendRequested',
  'friendAccepted',
  'friendCancelled',
  'friendDeclined',
  'friendPaused',
  'friendResumed',
] as const;
export const friendEvent: KeyIsValue<FriendEvent> = {
  friendAdded: 'friendAdded',
  friendUpdated: 'friendUpdated',
  friendRequested: 'friendRequested',
  friendAccepted: 'friendAccepted',
  friendCancelled: 'friendCancelled',
  friendDeclined: 'friendDeclined',
  friendPaused: 'friendPaused',
  friendResumed: 'friendResumed',
} as const;

export const friendStatuses = ['Requested', 'Accepted', 'Cancelled', 'Declined', 'Paused', 'Resumed'] as const;
export const friendStatus: KeyIsValue<FriendStatus> = {
  Requested: 'Requested',
  Accepted: 'Accepted',
  Cancelled: 'Cancelled',
  Declined: 'Declined',
  Paused: 'Paused',
  Resumed: 'Resumed', // Friend status will become Accepted
} as const;

export const genderTypes = ['Male', 'Female', 'Other', 'Unknown'] as const;
export const genderType: KeyIsValue<GenderType> = {
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
  Unknown: 'Unknown',
} as const;

export const layoutCellTypes = ['text', 'image', 'dropZone'] as const;
export const layoutCellType: KeyIsValue<LayoutCellType> = {
  text: 'text',
  image: 'image',
  dropZone: 'dropZone',
} as const;

export const mailFolderTypes = ['Inbox', 'Sent', 'Trash', 'Other'] as const;
export const mailFolderType: KeyIsValue<MailFolderType> = {
  Inbox: 'Inbox',
  Sent: 'Sent',
  Trash: 'Trash',
  Other: 'Other',
} as const;

export const memberEvents = [
  'memberAdded',
  'memberDeleted',
  'loggedIn',
  'loggedOut',
  'profileCompleted',
  'profileUpdated',
  'memberCityChanged',
] as const;
export const memberEvent: KeyIsValue<MemberEvent> = {
  memberAdded: 'memberAdded',
  memberDeleted: 'memberDeleted',
  loggedIn: 'loggedIn',
  loggedOut: 'loggedOut',
  profileCompleted: 'profileCompleted',
  profileUpdated: 'profileUpdated',
  memberCityChanged: 'memberCityChanged',
} as const;

export const orderedByTypes = ['Member', 'Guest'] as const;
export const orderedByType: KeyIsValue<OrderedByType> = {
  Member: 'Member',
  Guest: 'Guest',
};

export const orderStatuses = [
  'Cancelled',
  'Draft',
  'Entered',
  'Invoiced',
  'Ordered',
  'Picked',
  'Packed',
  'Shipped',
  'Completed',
] as const;
export const orderStatus: KeyIsValue<OrderStatus> = {
  Cancelled: 'Cancelled',
  Draft: 'Draft',
  Entered: 'Entered',
  Invoiced: 'Invoiced',
  Ordered: 'Ordered',
  Picked: 'Picked',
  Packed: 'Packed',
  Shipped: 'Shipped',
  Completed: 'Completed',
};

export const paymentProcessors = ['Stripe'] as const;
export const paymentProcessor: KeyIsValue<PaymentProcessor> = {
  Stripe: 'Stripe',
};

export const postEventTypes = ['postAdded', 'postDeleted', 'postUpdated', 'repliesDeleted'] as const;
export const postEventType: KeyIsValue<PostEventType> = {
  postAdded: 'postAdded',
  postDeleted: 'postDeleted',
  postUpdated: 'postUpdated',
  repliesDeleted: 'repliesDeleted',
} as const;

// noinspection SpellCheckingInspection
export const sites = [
  'busConsole',
  'cozyConsole',
  'petConsole',
  'rvConsole',
  'tinyConsole',
  'vanConsole',
  'unitTest',
] as const;
// noinspection SpellCheckingInspection
export const site: ValueIsLower<Site> = {
  busConsole: 'busconsole',
  cozyConsole: 'cozyconsole',
  petConsole: 'petconsole',
  rvConsole: 'rvconsole',
  tinyConsole: 'tinyconsole',
  vanConsole: 'vanconsole',
  unitTest: 'unittest',
} as const;
export const anySites = [...sites, 'anySite'] as const;
// noinspection SpellCheckingInspection
export const anySite: ValueIsLower<AnySite> = {
  ...site,
  anySite: 'anysite',
} as const;

export const s3Folders = ['public', 'private', 'protected'] as const;
export const s3Folder: KeyIsValue<S3Folder> = {
  public: 'public',
  private: 'private',
  protected: 'protected',
} as const;

export const shippingTypes = ['Ship', 'Deliver', 'Pickup', 'Other'] as const;
export const shippingType: KeyIsValue<ShippingType> = {
  Ship: 'Ship',
  Deliver: 'Deliver',
  Pickup: 'Pickup',
  Other: 'Other',
};

export const taxTypes = ['GST', 'PST', 'VAT', 'Other'] as const;
export const taxType: KeyIsValue<TaxType> = {
  GST: 'GST',
  PST: 'PST',
  VAT: 'VAT',
  Other: 'Other',
} as const;

export const pclAvatars = [
  'default',
  'bowl',
  'builder',
  'bus',
  'busBuilder',
  'busRepair',
  'busSupply',
  'petFood',
  'petGroomer',
  'petSitter',
  'petTrainer',
  'petWalker',
  'scissors',
  'sitter',
  'van',
  'vanBuilder',
  'vanRepair',
  'vanSupply',
  'vet',
] as const;
export const pclAvatar: CapitalizeValue<PclAvatar> = {
  default: 'Default',
  bowl: 'Bowl',
  builder: 'Builder',
  bus: 'Bus',
  busBuilder: 'BusBuilder',
  busRepair: 'BusRepair',
  busSupply: 'BusSupply',
  petFood: 'PetFood',
  petGroomer: 'PetGroomer',
  petSitter: 'PetSitter',
  petTrainer: 'PetTrainer',
  petWalker: 'PetWalker',
  scissors: 'Scissors',
  sitter: 'Sitter',
  van: 'Van',
  vanBuilder: 'VanBuilder',
  vanRepair: 'VanRepair',
  vanSupply: 'VanSupply',
  vet: 'Vet',
} as const;

export const pclServiceCodes = [
  'bus',
  'busBuilder',
  'busRepair',
  'busSupply',
  'groomer',
  'pet',
  'petFood',
  'petTrainer',
  'petWalker',
  'sitter',
  'van',
  'vanBuilder',
  'vanRepair',
  'vanSupply',
  'vet',
  'unitTest',
] as const;
export const pclServiceCode: KeyString<PclServiceCode> = {
  bus: 'BUS',
  busBuilder: 'BUS-BUILDER',
  busRepair: 'BUS-REPAIR',
  busSupply: 'BUS-SUPPLY',
  groomer: 'GROOMER',
  pet: 'PET',
  petFood: 'PET-FOOD',
  petTrainer: 'PET-TRAINER',
  petWalker: 'PET-WALKER',
  sitter: 'PET-SITTER',
  van: 'VAN',
  vanBuilder: 'VAN-BUILDER',
  vanRepair: 'VAN-REPAIR',
  vanSupply: 'VAN-SUPPLY',
  vet: 'VET',
  unitTest: 'UNIT-TEST',
} as const;

export const visibilityTypes = ['All', 'Friends', 'None'] as const;
export const visibilityType: KeyString<VisibilityType> = {
  All: 'All',
  Friends: 'Friends',
  None: 'None',
} as const;

export const profileSteps = 4;
export const ulidRegex = /^[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}$/;

const systemName = 'System';
// noinspection SpellCheckingInspection
const systemUlid = '01EPAWGXEY1RMEHNFE1EN111R1';

export const system = {
  id: systemUlid,
  name: systemName,
  crudColumns: {
    createdId: systemUlid,
    updatedId: systemUlid,
    createdBy: systemName,
    updatedBy: systemName,
  },
};

export const unitTest = {
  snsRegion: 'us-west-2',
  snsTopicArn: 'arn:aws:sns:us-west-2:860203843472:unit-test-topic',
};

export const priceIntervals = {
  none: '',
  month: 'Month',
  year: 'Year',
};

export const pclCurrencySets: RecordOfNamedSets = {
  none: {
    name: 'None',
    value: [],
  },
  cad: {
    name: 'CAD',
    value: [ourCurrencyCode.CAD],
  },
  cadUsd: {
    name: 'CAD USD',
    value: [ourCurrencyCode.CAD, ourCurrencyCode.USD],
  },
  cadUsdEur: {
    name: 'CAD USD EUR',
    value: [ourCurrencyCode.CAD, ourCurrencyCode.USD, ourCurrencyCode.EUR],
  },
};

export const pclIntervalSets: RecordOfNamedSets = {
  none: {
    name: 'None',
    value: [],
  },
  monthYear: {
    name: 'Month Year',
    value: [priceIntervals.month, priceIntervals.year],
  },
};

export const pclTierNames = ['Free', 'Standard', 'Enhanced', 'Premium'] as const;
export const pclTierName: KeyIsValue<PclTierName> = {
  Free: 'Free',
  Standard: 'Standard',
  Enhanced: 'Enhanced',
  Premium: 'Premium',
} as const;

export const pclTierSets: RecordOfNamedSets = {
  none: {
    name: 'None',
    value: [],
  },
  freeEnhancedPremium: {
    name: 'Free Enhanced Premium',
    value: [pclTierName.Free, pclTierName.Enhanced, pclTierName.Premium],
  },
  standardEnhancedPremium: {
    name: 'Standard Enhanced Premium',
    value: [pclTierName.Standard, pclTierName.Enhanced, pclTierName.Premium],
  },
};

// TODO: Tier usage:
//  PclProduct: name, productCode, statementDescription - update via maintenance screen? Do we even use this?
//  PclService: tierSet -  update via maintenance screen?
//  PclTier: name -  update via maintenance screen?

// TODO: PCL Service Maintenance
//  Why are tiers not visible after renaming plans?

export const editMessageTypes = ['error', 'hint', 'info', 'tip', 'warning'] as const;
export const editMessageType: KeyIsValue<EditMessageType> = {
  error: 'error',
  hint: 'hint',
  info: 'info',
  tip: 'tip',
  warning: 'warning',
} as const;

export const ourCountryList = [
  { value: '', name: '' },
  { value: 'CA', name: 'Canada' },
];

export const ourProvinceList = [
  { value: '', name: '' },
  { value: 'AB', name: 'Alberta' },
  { value: 'BC', name: 'British Columbia' },
  { value: 'MB', name: 'Manitoba' },
  { value: 'NB', name: 'New Brunswick' },
  { value: 'NL', name: 'Newfoundland and Labrador' },
  { value: 'NT', name: 'Northwest Territories' },
  { value: 'NS', name: 'Nova Scotia' },
  { value: 'NU', name: 'Nunavut' },
  { value: 'ON', name: 'Ontario' },
  { value: 'PE', name: 'Prince Edward Island' },
  { value: 'QC', name: 'Quebec' },
  { value: 'SK', name: 'Saskatchewan' },
  { value: 'YT', name: 'Yukon' },
];
