import { reactionApiEntity } from '@petconsole/pure-fe-api';
import { ReactionRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, getOne, getOneBy, updateOne } from '../../../helpers';

type ApiRecord = ReactionRecord;

const reactionEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = reactionApiEntity;

  return {
    createReaction: createOne(builder, api, tag),
    getReaction: getOne(builder, api, tag),
    getReactionByReactedTo: getOneBy(builder, api.getByReactionTo, tag),
    updateReaction: updateOne(builder, api.update, tag),
  };
};

export default reactionEndpoints;
