import { Close } from '@mui/icons-material';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurCloseButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  tip?: OurIconButtonProps['tip'];
  Icon?: OurIconButtonProps['Icon'];
}

const OurCloseButton = ({ tip = 'Close', Icon = Close, ...rest }: OurCloseButtonProps) => (
  <OurIconButton tip={tip} Icon={Icon} {...rest} />
);

OurCloseButton.whyDidYouRender = true;

export default OurCloseButton;
