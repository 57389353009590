import { localCache } from './caches';
import createLocalCache from './createLocalCache';
import { CacheItemOptions } from '../types';

 const setLocalItem = (key: string, value: unknown, options?: CacheItemOptions) => {
  if (!localCache) createLocalCache();

  localCache.setItem(key, value, options).then();
};

 export default setLocalItem;