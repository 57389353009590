import { NoteAddOutlined } from '@mui/icons-material';
import { webPath } from '@petconsole/pure-shared';
import OurIconLink from '../links/OurIconLink';

const OurNewMailButton = () => (
  <OurIconLink to={webPath.mailNewMessage} tip="New Message" Icon={NoteAddOutlined} />
);

OurNewMailButton.whyDidYouRender = true;

export default OurNewMailButton;
