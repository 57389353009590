import { useCallback } from 'react';
import { useRollbar } from '@rollbar/react';
import { Log, nodeEnv } from '@petconsole/pure-shared';

const useOurRollbarLog = () => {
  const rollbar = useRollbar();

  const log: Log = useCallback(
    async (...args: unknown[]) => {
      if (nodeEnv !== 'test') console.error(...args);

      const newError = new Error(String(args[0]));

      if (nodeEnv === 'test') return newError;

      return new Promise((resolve, reject) => {
        try {
          rollbar.error(`Error: ${args[0]}`);
          resolve(newError);
        } catch (e) {
          const message = e instanceof Error ? e.message : '';

          reject(new Error(`Error reporting to Rollbar: ${message}`));
        }
      });
    },
    [rollbar],
  );

  return { log, rollbar };
};

export default useOurRollbarLog;
