import { ListAlt } from '@mui/icons-material';
import {
  SelectHook,
  SetHook,
  useOurSelectGuestMenuListingsMenuOpen,
  useOurSelectMemberMenuListingsMenuOpen,
  useOurSetGuestMenuListingsMenuOpen,
  useOurSetMemberMenuListingsMenuOpen,
} from '@petconsole/pure-fe-state';
import { AppMenuItems } from '../../../types';
import OurAppMenuCollapsable from './OurAppMenuCollapsable';
import ourCombinedAppMenuItems from './ourCombinedAppMenuItems';
import { SetState } from '@petconsole/pure-shared';

interface OurListingsMenuProps {
  add?: AppMenuItems;
  exclude?: string[];
  guest?: boolean;
}

const OurListingsMenu = ({ add, exclude, guest }: OurListingsMenuProps) => {
  const menuItemsOpen = (useOurSelectMemberMenuListingsMenuOpen as SelectHook<boolean>)();
  const setMenuItemsOpen = (useOurSetMemberMenuListingsMenuOpen as SetHook<boolean>)();
  const guestItemsOpen = (useOurSelectGuestMenuListingsMenuOpen as SelectHook<boolean>)();
  const setGuestItemsOpen = (useOurSetGuestMenuListingsMenuOpen as SetHook<boolean>)();

  const menuItems = [] as AppMenuItems;

  const open = guest ? guestItemsOpen : menuItemsOpen;
  const setOpen = (guest ? setGuestItemsOpen : setMenuItemsOpen) as SetState<boolean>;
  const items = ourCombinedAppMenuItems({ items: menuItems, add, exclude });

  return (
    <OurAppMenuCollapsable open={open} setOpen={setOpen} Icon={ListAlt} label="Listings" items={items} divider={true} />
  );
};

OurListingsMenu.whyDidYouRender = true;

export default OurListingsMenu;
