import { RecordType } from '@petconsole/pure-base';
import { Api, EntityName, EntityReaction } from '@petconsole/pure-shared';
import { ThunkApi } from '../../types';
import ourStateSliverData from '../state/ourStateSliverData';

export interface OurReactToProps<T extends RecordType = RecordType> {
  entityName: EntityName;
  api: Api<T>;
  id: string;
  reaction: EntityReaction;
}

export const ourReactTo = async <T extends RecordType = RecordType>(
  { entityName, api, id, reaction }: OurReactToProps<T>,
  { getState }: ThunkApi,
) => {
  const sliver = ourStateSliverData(getState(), entityName);
  const updatedAt = sliver.entities[id]?.updatedAt || undefined;

  return api.react(id, reaction, updatedAt);
};

export default ourReactTo;
