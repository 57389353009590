import { RecordType } from '@petconsole/pure-base';
import { StateSliver } from '../../types/stateTypes';

interface SelectsPropertyByValueOuterSelectorProps {
  propertyName: string;
  valueName: string;
  value: string | boolean;
}

const selectsPropertyByValueOuterSelector =
  ({ propertyName, valueName, value }: SelectsPropertyByValueOuterSelectorProps) =>
  ({ ids, entities }: StateSliver<RecordType>) =>
    ids.reduce((values, id) => {
      const record = entities[id];

      if (record[valueName] !== value) return values;

      return [...values, record[propertyName]];
    }, [] as unknown[]);

export default selectsPropertyByValueOuterSelector;
