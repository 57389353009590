import { GetPropertyValue, PropertyReducerType } from '../../types';
import { setPropertyValue } from '../misc';

const setPropertyReducer: PropertyReducerType =
  (propertyName, parentName = '') =>
  (state, { payload }) => {
    const getValue = (() => payload) as GetPropertyValue;

    setPropertyValue({ state, propertyName, parentName, getValue });
  };

export default setPropertyReducer;
