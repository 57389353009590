import { AxiosError } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { MaybeNull, RecordType } from '@petconsole/pure-base';
import {
  ApiMethod,
  apiMethodType,
  ApiMethodType,
  CreateMethod,
  DeleteMethod,
  GetByInputMethod,
  GetListByIdMethod,
  GetListMethod,
  GetListOptions,
  GetListResults,
  GetMethod,
  PatchIdMethod,
  PatchMethod,
  PostMethod,
  UpdateMethod,
} from '@petconsole/pure-shared';

// export type BaseQueryFn<Args,Result,Error,DefinitionExtraOptions,Meta>
// Alias for:
// (args: Args, api: BaseQueryApi, extraOptions: DefinitionExtraOptions) =>
//   MaybePromise<QueryReturnValue<Result, Error, Meta>>

interface BaseQueryArgs<T extends RecordType = RecordType> {
  type?: ApiMethodType;
  method: ApiMethod<T>;
  id?: string;
  body?: T;
  input?: RecordType;
  options?: GetListOptions;
}

const apiBaseQuery =
  <T extends RecordType = RecordType>(): BaseQueryFn<BaseQueryArgs<T>, MaybeNull<T | RecordType> | GetListResults<T>> =>
  async ({ type = apiMethodType.get, method, id = '', body, input, options = {} } /* , _api, _extraOptions */) => {
    try {
      // console.log('extraOptions:', extraOptions);

      if (type === apiMethodType.create) return { data: await (method as CreateMethod<T>)(body) };

      if (type === apiMethodType.delete) return { data: await (method as DeleteMethod<T>)(id) };

      if (type === apiMethodType.get) return { data: await (method as GetMethod<T>)(id) };

      if (type === apiMethodType.getByInput)
        return { data: await (method as GetByInputMethod<T>)(input as RecordType) };

      if (type === apiMethodType.getList)
        return { data: await (method as GetListMethod<T>)(<string & GetListOptions>{}) };

      if (type === apiMethodType.getListById) return { data: await (method as GetListByIdMethod<T>)(id, options) };

      if (type === apiMethodType.patch) return { data: await (method as PatchMethod<T>)(body) };

      if (type === apiMethodType.patchId) return { data: await (method as PatchIdMethod<T>)(id) };

      if (type === apiMethodType.post) return { data: await (method as PostMethod)(input) };

      // TODO: Consider error if we try to use update without a body provided
      if (type === apiMethodType.update && body) return { data: await (method as UpdateMethod<T>)(id, body as T) };

      if (id) return { data: await (method as GetMethod<T>)(id) };

      return { data: await (method as GetListMethod<T>)(<string & GetListOptions>{}) };
    } catch (e) {
      if (!(e instanceof Error)) return { error: String(e) };

      if ('isAxiosError' in e) {
        const error = e as AxiosError;

        return {
          error: {
            message: error.message,
            status: error.response?.status,
            statusText: error.response?.statusText,
            data: error.response?.data,
          },
        };
      }

      return { error: e.message };
    }
  };

export default apiBaseQuery;
