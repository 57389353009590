import { ourPutImage } from '@petconsole/pure-fe-api';
import { Image, Log } from '@petconsole/pure-shared';

interface SaveThumbnailProps {
  thumbnail?: Image;
  log?: Log;
}

const saveThumbnail = async ({ thumbnail, log }: SaveThumbnailProps) => {
  if (!thumbnail?.changed) return;

  if (!log) throw new Error('saveThumbnail: log is required');

  const { s3Key, src, contentType } = thumbnail;

  await ourPutImage({
    s3Key,
    src: src as string,
    contentType,
    log,
  });
};

export default saveThumbnail;
