import { ourFetchStatus, ourSaveStatus } from '../../constants';
import { ThunkRejectedMethod } from '../../types';
import thunkReducerValues from './thunkReducerValues';

const thunkRejectedReducer = (
  stateProperty: string,
  statusProperty = 'readStatus',
  idProperty = '',
) => {
  const errorProperty = statusProperty.replace('Status', 'Error');

  const rejectedReducer: ThunkRejectedMethod = (state, action = { error: { message: '' } }) => {
    const { target, id, error } = thunkReducerValues(state, stateProperty, action, idProperty);
    const status = statusProperty.startsWith('write')
      ? ourSaveStatus.failed
      : ourFetchStatus.failed;

    // In the case of updating ratings for an entity, we expect to have a "ratings" object, the status property starts
    // with "write", and the id property is the entity id, ie: if we are dealing with the ratings for a vet, the id
    // property will be vetId. All writes will update the same error and status properties. rather than by id
    const isRatingWrite =
        target?.ratings && statusProperty.startsWith('write') && idProperty === `${stateProperty}Id`;

    if (idProperty && id && !isRatingWrite) {
      target[errorProperty][id] = error?.message;
      target[statusProperty][id] = status;
    } else {
      target[errorProperty] = error?.message;
      target[statusProperty] = status;
    }
  };

  return rejectedReducer;
};

export default thunkRejectedReducer;
