import { IconProps } from '@mui/material';
import { Icon } from '@petconsole/pure-shared';
import OurIconButton from '../buttons/OurIconButton';
import OurLink from './OurLink';

export interface OurIconLinkProps {
  to: string;
  tip: string;
  aria?: string;
  disabled?: boolean;
  Icon: Icon;
  fontSize?: IconProps['fontSize'];
}

const OurIconLink = ({ to, tip, aria, disabled, Icon, fontSize, ...rest }: OurIconLinkProps) => (
  <OurLink to={to} {...rest}>
    <OurIconButton tip={tip} aria={aria} disabled={disabled} Icon={Icon} fontSize={fontSize} />
  </OurLink>
);

OurIconLink.whyDidYouRender = true;

export default OurIconLink;
