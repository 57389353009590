import React, { ChangeEvent, MutableRefObject, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { isMobile } from 'react-device-detect';
import Dropzone, { Accept, DropzoneRootProps } from 'react-dropzone';
import { ImageSearch } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { MaybeNull } from '@petconsole/pure-base';
import { SetState } from '@petconsole/pure-shared';
import OurFileButton from '../buttons/OurFileButton';
import OurRotateTextButton from '../buttons/OurRotateTextButton';
import OurZoomInTextButton from '../buttons/OurZoomInTextButton';
import OurZoomOutTextButton from '../buttons/OurZoomOutTextButton';

export type MaybeAvatarEditor = MaybeNull<AvatarEditor>;
export type MaybeAvatarEditorRef = MutableRefObject<MaybeAvatarEditor>;

interface OurAvatarEditProps {
  avatar: File | string;
  setEditor: SetState<MaybeAvatarEditor>;
  setChanged: SetState<boolean>;
  width?: number;
  height?: number;
  borderRadius?: number;
  accept?: Accept;
}

const OurAvatarEdit = ({
  avatar,
  setEditor,
  setChanged,
  width = 200,
  height = 200,
  borderRadius,
  accept = { 'image/*': ['.jpeg', '.png'] },
}: OurAvatarEditProps) => {
  const [image, setImage] = useState(avatar);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [rotate, setRotate] = useState(0);

  const found = (e: ChangeEvent<HTMLInputElement>) => {
    setImage(e.target.files ? e.target.files[0] : '');
    setChanged(true);
  };

  const handleDrop = (files: File[]) => {
    setImage(files[0]);
    setChanged(true);
  };

  const changePosition = (pos: { x: number; y: number }) => {
    setPosition(pos);
    setChanged(true);
  };

  const zoomIn = () => {
    setScale((c) => Math.min(c + 0.05, 2));
    setChanged(true);
  };

  const zoomOut = () => {
    setScale((c) => Math.max(c - 0.05, -1));
    setChanged(true);
  };

  const turn = () => {
    setRotate(rotate - 90);
    setChanged(true);
  };

  // function logCallback(e) {
  //   // eslint-disable-next-line
  //   console.log('callback', e);
  // }

  const renderEditor = ({
    getRootProps,
  }: {
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  }) => (
    <AvatarEditor
      scale={scale}
      position={position}
      rotate={rotate}
      width={width}
      height={height}
      image={image}
      className="editor-canvas"
      {...getRootProps({ style: { width, height } })}
      ref={setEditor}
      onPositionChange={changePosition}
      color={[0, 105, 177, 0.6]}
      borderRadius={borderRadius == null ? width / 2 : borderRadius}
      // crossOrigin="anonymous"
      // onLoadFailure={() => logCallback('onLoadFailed')}
      // onLoadSuccess={() => logCallback('onLoadSuccess')}
      // onImageReady={() => logCallback('onImageReady')}
    />
  );

  const renderAvatarEditor = () => {
    return (
      <Dropzone onDrop={handleDrop} multiple={false} accept={accept} noClick noKeyboard>
        {({ getRootProps }) => {
          if (isMobile) return renderEditor({ getRootProps });

          return renderEditor({ getRootProps });
          // return (
          //   <Tooltip title="Drop file here or click/drag to move current image" placement="right">
          //     {renderEditor({ getRootProps })}
          //   </Tooltip>
          // );
        }}
      </Dropzone>
    );
  };

  return (
    <Stack spacing={1} maxWidth="400px" alignItems="center">
      <OurFileButton label="Select Photo" onImage={found} startIcon={<ImageSearch />} size="small" />
      {!isMobile && <Typography> or drag and drop a file below</Typography>}
      {renderAvatarEditor()}
      <Typography variant="subtitle2" align="center">{`${
        isMobile ? 'Touch' : 'Click'
      } and drag photo to reposition it or use buttons below to zoom in or out, or to rotate the photo`}</Typography>
      <Stack direction="row" spacing={0.5}>
        <OurZoomInTextButton label="Zoom In" onClick={zoomIn} size="small" />
        <OurZoomOutTextButton label="Zoom Out" onClick={zoomOut} size="small" />
        <OurRotateTextButton label="Turn Photo" onClick={turn} size="small" />
      </Stack>
    </Stack>
  );
};

export default OurAvatarEdit;
