import React, { memo } from 'react';
import { FormState } from 'react-hook-form';
import { Grid } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import OurEditMessages from '../components/misc/editMessage/OurEditMessages';
import useEditMessages from '../components/misc/editMessage/useEditMessages';
import useMaintErrors from './helpers/useMaintErrors';
import MaintMainContent from './MaintMainContent';
import { MaintTopContentProps } from './types';

const MaintTopContent = <T extends RecordType = RecordType>({
  isEditing,
  submitting,
  setSubmitting,
  editMessages,
  ...rest
}: MaintTopContentProps<T>) => {
  const formState = rest.form.formState as FormState<T>;
  const { isDirty, isSubmitting, errors } = formState;

  const errorProps = { isSubmitting, errors, readOnly: rest.readOnly, submitting, setSubmitting };
  const { MaintErrors } = useMaintErrors<T>(errorProps);

  const messagesProps = { isEditing, isDirty, isSaving: isSubmitting, messages: editMessages ?? undefined };
  const { editMessages: editingMessages } = useEditMessages(messagesProps);
  const EditMessages = memo(() => <OurEditMessages messages={editingMessages} />);

  return (
    <Grid container item>
      <MaintMainContent {...rest} />
      {MaintErrors && <MaintErrors />}
      {EditMessages && <EditMessages />}
    </Grid>
  );
};

MaintTopContent.whyDidYouRender = true;

export default MaintTopContent;
