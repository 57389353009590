// noinspection ES6PreferShortImport

import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { FallbackProps } from 'react-error-boundary';
import { Box, Grid, Typography } from '@mui/material';
import { SetState, webPath } from '@petconsole/pure-shared';
import OurButton from '../components/buttons/OurButton';
import OurMarginGridCard from '../components/cards/OurMarginGridCard';
import OurUnsplashImageCard from '../components/misc/unsplash/OurUnsplashImageCard';

interface ErrorBoundaryFallbackProps extends FallbackProps {
  setErrorRefresh: SetState<boolean>;
}

const ErrorBoundaryFallback = ({ setErrorRefresh }: ErrorBoundaryFallbackProps) => {
  const navigate = useNavigate();

  const onHome = (event: MouseEvent) => {
    event.preventDefault();
    navigate(webPath.home);
    setErrorRefresh(true);
  };

  return (
    <OurMarginGridCard spacing={2} padding={1} margins={{ md: 1, lg: 2 }}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h5" paragraph>
          <strong>We are sorry...</strong>
        </Typography>
        <Typography paragraph>We are truly sorry, but we had an unexpected error.</Typography>
        <Typography paragraph>
          This has been reported to us automatically and we will work to prevent the error from
          happening again. Please feel free to continue using the site in the meantime.
        </Typography>
        <Typography>
          Hopefully this pet picture helps make up for the inconvenience. Please click below to
          return to the home page.
        </Typography>
        <Box sx={{ p: 2, xs: 12, sm: 8, md: 6 }}>
          <OurButton label="Return to Home page" onClick={onHome} />
        </Box>
        <OurUnsplashImageCard />
        <Typography variant="body2">Photo courtesy of Unsplash.</Typography>
      </Grid>
    </OurMarginGridCard>
  );
};

ErrorBoundaryFallback.whyDidYouRender = true;

export default ErrorBoundaryFallback;
