import { RecordType, RecordTypes } from '@petconsole/pure-base';
import { ourSaveStatus } from '../../constants';
import { AnyAsyncThunk, StateType, ThunkFulfilledMethod, ThunkReducersType } from '../../types';
import thunkPendingReducer from './thunkPendingReducer';
import thunkRejectedReducer from './thunkRejectedReducer';

const writeReducers: ThunkReducersType = (fetchMethod, stateProperty, adapter) => {
  const { pending, fulfilled, rejected } = fetchMethod as AnyAsyncThunk;
  const statusProperty = 'writeStatus';
  const fulfilledReducer: ThunkFulfilledMethod<StateType, RecordType> = (topState, action) => {
    const state = stateProperty ? topState[stateProperty] : topState;
    const payload = action.payload;

    state.writeStatus = ourSaveStatus.succeeded;

    if (!payload) return;

    if (payload.items) adapter?.upsertMany(state, payload.items as RecordTypes);
    else if (Array.isArray(payload)) adapter?.upsertMany(state, payload);
    else adapter?.upsertOne(state, payload);
  };

  return {
    [String(pending)]: thunkPendingReducer(stateProperty, statusProperty),
    [String(fulfilled)]: fulfilledReducer,
    [String(rejected)]: thunkRejectedReducer(stateProperty, statusProperty),
  };
};

export default writeReducers;
