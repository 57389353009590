import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OurPhoneNumber, { OurPhoneNumberProps } from './OurPhoneNumber';

export interface OurPhoneGridItemProps extends OurPhoneNumberProps, RegularBreakpoints {
  gridProps?: GridProps;
  id?: string;
}

const OurPhoneGridItem = ({ gridProps, xs = 12, sm, md, lg, xl, id = 'phoneNumber', ...rest }: OurPhoneGridItemProps) => {
  return (
    <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
      <OurPhoneNumber id={id} {...rest} />
    </Grid>
  );
};

OurPhoneGridItem.whyDidYouRender = true;

export default OurPhoneGridItem;
