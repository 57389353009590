import { RecordType } from '@petconsole/pure-base';
import { pclTierPriceApiEntity } from '@petconsole/pure-fe-api';
import { PclTierPriceRecord } from '@petconsole/pure-shared';
import { writeReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick } from '../../../types';

const { api, name: sliceName, proper } = pclTierPriceApiEntity;

export const updatePclTierPriceStripePayloadCreator = async ({ pclTierPrice }: RecordType) => {
  const { pclTierPriceId, effectiveDate, json } = pclTierPrice as PclTierPriceRecord;
  const { stripePrice } = json;

  if (stripePrice)
    throw new Error(`Stripe price already exists for ${pclTierPriceId} ${effectiveDate}`);

  return api.createStripe(pclTierPriceId);
};

export const updatePclTierPriceStripeThunk = appThunk(
  `${sliceName}/update${proper}Stripe`,
  updatePclTierPriceStripePayloadCreator,
);

export const updatePclTierPriceStripeReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(updatePclTierPriceStripeThunk, sliceName, adapter);
