import { EntityName, ourEntityNameToType } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import ourUpdateRating from './ourUpdateRating';

interface OurUpdateRatingThunkProps {
  entityName: EntityName;
}

const ourUpdateRatingThunk = ({ entityName }: OurUpdateRatingThunkProps) => {
  const type = ourEntityNameToType(entityName);
  const thunkType = `${entityName}/update${type}Rating`;

  return appThunk(thunkType, ourUpdateRating);
};

export default ourUpdateRatingThunk;