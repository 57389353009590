// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { ourUomQuantity } from '../../../constants';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity, MaybeImage } from '../../../types';

export interface OrderLine extends CrudColumns {
  type: DataEntityType;
  orderLineId: string;
  orderId: string;
  shopItemId: string;
  shopItemVariantId: string;
  variantDescription: string;
  line: number;
  name: string;
  sku: string;
  thumbnail: MaybeImage;
  quantity: number;
  quantityUom: string;
  price: number;
  shipRateSet: boolean;
  baseShipRate: number;
  itemShipRate: number;
}

export const orderLineEntity = entityAttributes({
  name: entityName.orderLine,
  attributes: {
    category: entityCategory.item,
    columns: { images: false, json: false },
    loadLimit: 25,
  },
});

const { boolean, id, image, number, strim } = schema;
export const orderLineSchema = entitySchema<OrderLine>(orderLineEntity, {
  orderId: id(true),
  shopItemId: id(),
  shopItemVariantId: id(),
  variantDescription: strim(),
  line: number(),
  name: strim(),
  sku: strim(),
  thumbnail: image(),
  quantity: number(),
  quantityUom: strim(),
  price: number(),
  shipRateSet: boolean(),
  baseShipRate: number(),
  itemShipRate: number(),
});

export const orderLineDefaults: Partial<OrderLine> = {
  orderId: '',
  shopItemId: '',
  shopItemVariantId: '',
  variantDescription: '',
  line: 0,
  name: '',
  sku: '',
  thumbnail: null,
  quantity: 0,
  quantityUom: ourUomQuantity.EA,
  price: 0,
  shipRateSet: false,
  baseShipRate: 0,
  itemShipRate: 0,
};

export interface OrderLineRecord extends OrderLine, RecordType {}

export type MaybeOrderLineRecord = MaybeNull<OrderLineRecord>;
export type OrderLineRecords = OrderLineRecord[];

export const initialOrderLine: InitialEntity<OrderLine, OrderLineRecord> = (overrides = {}) =>
  initialEntity<OrderLine>(orderLineEntity, { ...orderLineDefaults, ...overrides }) as OrderLineRecord;
