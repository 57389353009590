import { MouseEvent } from 'react';
import { Box, MenuProps } from '@mui/material';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { MemberRecord, SetState } from '@petconsole/pure-shared';
import OurTooltip from '../../misc/OurTooltip';
import MemberAvatar from './MemberAvatar';
import MemberAvatarWithBadge from './MemberAvatarWithBadge';

interface AppMemberAvatarProps {
  setMenuOpen: SetState<boolean>;
  setMenuAnchorEl: SetState<MenuProps['anchorEl']>;
}

const AppMemberAvatar = ({ setMenuOpen, setMenuAnchorEl }: AppMemberAvatarProps) => {
  const { member } = useCurrentMember();

  const { newMessages = 0, newNotifications = 0 } = member as MemberRecord;
  const badgeContent = newMessages + newNotifications;

  const onClick = (event: MouseEvent) => {
    setMenuOpen(true);
    setMenuAnchorEl(event.currentTarget);
  };

  if (badgeContent > 0)
    return (
      <OurTooltip tip="Shortcuts">
        <MemberAvatarWithBadge badgeContent={badgeContent} onClick={onClick} />
      </OurTooltip>
    );

  // Note: Using Box to hold a ref - required by Tooltip
  return (
    <OurTooltip tip="Shortcuts">
      <Box>
        <MemberAvatar onClick={onClick} />
      </Box>
    </OurTooltip>
  );
};

AppMemberAvatar.whyDidYouRender = true;

export default AppMemberAvatar;
