// noinspection ES6PreferShortImport

import { ComponentType, memo, useCallback, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import OurBackButton from '../../components/buttons/OurBackButton';
import OurHelpButton from '../../components/buttons/OurHelpButton';
import { OurMarkdownHelp } from '../../components/dialogs';
import OurHeadings from '../../components/headings/OurHeadings';

interface EntityMaintenanceHeadingsProps {
  helpTitle?: string;
  helpContent?: string;
  Heading?: ComponentType;
  Toolbar: ComponentType;
}

const EntityMaintenanceHeadings = ({
  helpTitle = '',
  helpContent = '',
  Heading,
  Toolbar,
}: EntityMaintenanceHeadingsProps) => {
  const [helpOpen, setHelpOpen] = useState(false);

  const onHelpClick = useCallback(() => setHelpOpen(true), []);

  const LeftContent = memo(() => (
    <Box display="flex">
      <OurBackButton />
      <OurHelpButton onClick={onHelpClick} />
    </Box>
  ));

  const headingContents = useMemo(
    () => [<LeftContent />, Heading ? <Heading /> : undefined, <Toolbar />],
    [LeftContent, Heading, Toolbar],
  );

  return (
    <>
      <Grid item xs={12}>
        <OurHeadings contents={headingContents} />
      </Grid>
      {helpOpen && <OurMarkdownHelp title={helpTitle} content={helpContent} isOpen={helpOpen} setOpen={setHelpOpen} />}
    </>
  );
};

EntityMaintenanceHeadings.whyDidYouRender = true;

export default EntityMaintenanceHeadings;
