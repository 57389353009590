import { Api } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import payloadCreatorDeleteEntity from '../payloadCreators/payloadCreatorDeleteEntity';
import { RecordType } from '@petconsole/pure-base';

const deleteEntityThunk = <T extends RecordType = RecordType>(sliceName: string, entityType: string, idName: string, api: Api<T>) =>
  appThunk(
    `${sliceName}/delete${entityType}`,
    payloadCreatorDeleteEntity(sliceName, idName, api),
  );

export default deleteEntityThunk;
