import { Grid, GridProps } from '@mui/material';
import OurHeading, { OurHeadingProps } from './OurHeading';

interface OurHeadingItemProps extends OurHeadingProps {
  gridProps?: GridProps;
}

const OurHeadingItem = ({ gridProps, ...rest }: OurHeadingItemProps) => {
  return (
    <Grid item xs={12} {...gridProps}>
      <OurHeading {...rest} />
    </Grid>
  );
};

OurHeadingItem.whyDidYouRender = true;

export default OurHeadingItem;
