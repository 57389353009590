import { RecordType } from '@petconsole/pure-base';
import { vanApiEntity } from '@petconsole/pure-fe-api';
import { VanRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  ReactionHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = VanRecord;

const apiEntity = vanApiEntity;
const { name: sliceName, plural, pluralProper } = apiEntity;
const van = sliceName;
const vans = plural;
const myVans = `my${pluralProper}`;
const vanAdmin = `${sliceName}Admin`;

const Tab = { vanAdmin, van, vans, myVans } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  vanAdmin: { default: '' },
  van: { default: '' },
  vans: { default: 'By City' },
  myVans: { default: 'My Vans' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  sortProperty: 'vanName',
  hasCitySliver: true,
  hasNewestSliver: true,
  hasMySliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasReaction: true,
  hasNextPrev: true,
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, tabHooks, citySearchHooks, reactionHooks } = sliceHookTypes;

export interface OurVanHooks<T extends RecordType = VanRecord> extends OurEntityHooks<T> {
  vanHooks: RequiredCrudSliverHooks;
  cityVanHooks: RequiredSliverHooks;
  newestVanHooks: RequiredSliverHooks;
  myVanHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  reactionHooks: ReactionHooks;
  tabHooks: TabHooks;
}

export const ourVanHooks: OurVanHooks = {
  entity: apiEntity,
  vanHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityVanHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestVanHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  myVanHooks: sliverHooks(apiEntity, 'My', hooks) as RequiredSliverHooks,
  propertyHooks: propertyHooks(apiEntity, hooks),
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  reactionHooks: reactionHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
