// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { CrudColumns, DataEntityType,InitialEntity, OwnerColumns } from '../../../types';

export interface ForumDef extends CrudColumns, OwnerColumns {
  type: DataEntityType;
  forumDefId: string;
  urlName: string;
  json: RecordType;
}

export const forumDefEntity = entityAttributes({
  name: entityName.forumDef,
  attributes: {
    category: entityCategory.item,
    nameField: 'urlName',
    columns: { images: false, owner: true },
    restrictGet: false,
  },
});

const { strim } = schema;
export const forumDefSchema = entitySchema<ForumDef>(forumDefEntity, {
  urlName: strim().required(),
});

export const forumDefDefaults: Partial<ForumDef> = {
  urlName: '',
};

export interface ForumDefRecord extends ForumDef, RecordType {}

export type MaybeForumDefRecord = MaybeNull<ForumDefRecord>;
export type ForumDefRecords = ForumDefRecord[];

export const initialForumDef: InitialEntity<ForumDef, ForumDefRecord> = (overrides = {}) =>
  initialEntity<ForumDef>(forumDefEntity, { ...forumDefDefaults, ...overrides }) as ForumDefRecord;
