import { createApi } from '@reduxjs/toolkit/query/react';
import { RecordType } from '@petconsole/pure-base';
import {
  busApiEntity,
  busBuilderApiEntity,
  businessApiEntity,
  busRepairApiEntity,
  busSupplyApiEntity,
  dogParkApiEntity,
  foundPetApiEntity,
  groomerApiEntity,
  lostPetApiEntity,
  mailMessageApi,
  orderApiEntity,
  petApiEntity,
  petFoodApiEntity,
  petTrainerApiEntity,
  petTypeApiEntity,
  petWalkerApiEntity,
  petWorkplaceApiEntity,
  placeApiEntity,
  shopItemApiEntity,
  shopItemVariantApiEntity,
  shopItemVariantCodeApiEntity,
  sitterApiEntity,
  vanApiEntity,
  vanBuilderApiEntity,
  vanRepairApiEntity,
  vanSupplyApiEntity,
  vetApiEntity,
} from '@petconsole/pure-fe-api';
import {
  GetListResults,
  entityType,
  entityTypes,
  ShopItemRecord,
  apiMethodType,
  BlogRecord,
  CartRecord,
} from '@petconsole/pure-shared';
import blogEndpoints from './endpoints/joint/blogs/blogEndpoints';
import cartEndpoints from './endpoints/joint/carts/cartEndpoints';
import cartItemEndpoints from './endpoints/joint/cartItems/cartItemEndpoints';
import memberEndpoints from './endpoints/joint/members/memberEndpoints';
import pclServiceEndpoints from './endpoints/pcl/pclServices/pclServiceEndpoints';
import pclTierEndpoints from './endpoints/pcl/pclTiers/pclTierEndpoints';
import postEndpoints from './endpoints/joint/posts/postEndpoints';
import reactionEndpoints from './endpoints/joint/reactions/reactionEndpoints';
import shopEndpoints from './endpoints/joint/shops/shopEndpoints';
import shopItemEndpoints from './endpoints/joint/shopItems/shopItemEndpoints';
import shopItemOptionTemplateEndpoints from './endpoints/joint/shopItemOptionTemplates/shopItemOptionTemplateEndpoints';
import stripeAccountEndpoints from './endpoints/stripe/stripeAccounts/stripeAccountEndpoints';
import { apiBaseQuery, getOne } from './helpers';
import pclHeaderEndpoints from './endpoints/pcl/pclHeaders/pclHeaderEndpoints';
import { ApiBuilder } from '../types';

const { api: busApi } = busApiEntity;
const { api: busBuilderApi } = busBuilderApiEntity;
const { api: businessApi } = businessApiEntity;
const { api: busRepairApi } = busRepairApiEntity;
const { api: busSupplyApi } = busSupplyApiEntity;
const { api: dogParkApi } = dogParkApiEntity;
const { api: foundPetApi } = foundPetApiEntity;
const { api: groomerApi } = groomerApiEntity;
const { api: lostPetApi } = lostPetApiEntity;
const { api: orderApi } = orderApiEntity;
const { api: petApi } = petApiEntity;
const { api: petFoodApi } = petFoodApiEntity;
const { api: petTrainerApi } = petTrainerApiEntity;
const { api: petTypeApi } = petTypeApiEntity;
const { api: petWalkerApi } = petWalkerApiEntity;
const { api: petWorkplaceApi } = petWorkplaceApiEntity;
const { api: placeApi } = placeApiEntity;
const { api: shopItemApi } = shopItemApiEntity;
const { api: shopItemVariantApi } = shopItemVariantApiEntity;
const { api: shopItemVariantCodeApi } = shopItemVariantCodeApiEntity;
const { api: sitterApi } = sitterApiEntity;
const { api: vanApi } = vanApiEntity;
const { api: vanBuilderApi } = vanBuilderApiEntity;
const { api: vanRepairApi } = vanRepairApiEntity;
const { api: vanSupplyApi } = vanSupplyApiEntity;
const { api: vetApi } = vetApiEntity;

// const apiBaseQuery = (args, { signal, dispatch, getState }, extraOptions) => {...}
// type QuerySuccess = Promise<{ data: unknown }>;
// type QueryError = Promise<{ error: string }>;
// type QueryResult = QuerySuccess | QueryError;
//
// If queryFn is used rather than query, then it must return the following shape for the success case:
// { data: ResultType }
//
//   builder.query<ResultType, QueryArg>
// or:
//   builder.query<RecordType, string | RecordType>({
//     // query: (id) => ({ method, id }),
//     queryFn: (id, queryApi, extraOptions) => {
//       const query = apiBaseQuery();
//       return { data: query({ method, id: typeof id === 'string' ? id : id.id }, queryApi, extraOptions) };
//     },
//     extraOptions,
//     providesTags: (_result, _error, id) => [tag, { type: tag, id: typeof id === 'string' ? id : id.id }],
//   });

const tagTypes = [...entityTypes, 'MyShopItem', 'ShopItemVariantCodeOption1', 'ShopItemVariantCodeOption2'];

export const apiSlice = createApi({
  reducerPath: 'api', // default = 'api'
  baseQuery: apiBaseQuery(),
  tagTypes,
  endpoints: (builder) => ({
    ...blogEndpoints({ builder: builder as ApiBuilder<BlogRecord> }),
    getBus: getOne(builder, busApi, entityType.Bus),
    // getBuses: builder.query({
    //   query: () => ({ method: busApi.getList }),
    //   providesTags: ['Bus'],
    // }),
    getBusBuilder: getOne(builder, busBuilderApi, entityType.BusBuilder),
    getBusBuilders: builder.query<GetListResults, void>({
      query: () => ({ method: busBuilderApi.getList }),
      providesTags: ['BusBuilder'],
    }),
    getBusiness: getOne(builder, businessApi, entityType.Business),
    getBusRepair: getOne(builder, busRepairApi, entityType.BusRepair),
    getBusSupply: getOne(builder, busSupplyApi, entityType.BusSupply),
    ...cartEndpoints({ builder: builder as ApiBuilder<CartRecord> }),
    ...cartItemEndpoints({ builder }),
    getDogPark: getOne(builder, dogParkApi, entityType.DogPark),
    getFoundPet: getOne(builder, foundPetApi, entityType.FoundPet),
    getGroomer: getOne(builder, groomerApi, entityType.Groomer),
    getLostPet: getOne(builder, lostPetApi, entityType.LostPet),
    getMailMessage: getOne(builder, mailMessageApi, entityType.MailMessage),
    ...memberEndpoints({ builder }),
    getOrder: getOne(builder, orderApi, entityType.Order),
    ...pclHeaderEndpoints({ builder }),
    ...pclServiceEndpoints({ builder }),
    ...pclTierEndpoints({ builder }),
    getPet: getOne(builder, petApi, entityType.Pet),
    getPetFood: getOne(builder, petFoodApi, entityType.PetFood),
    getPetTrainer: getOne(builder, petTrainerApi, entityType.PetTrainer),
    getPetType: getOne(builder, petTypeApi, entityType.PetType),
    getPetWalker: getOne(builder, petWalkerApi, entityType.PetWalker),
    getPetWorkplace: getOne(builder, petWorkplaceApi, entityType.PetWorkplace),
    getPlace: getOne(builder, placeApi, entityType.Place),
    ...postEndpoints({ builder }),
    ...reactionEndpoints({ builder }),
    ...shopEndpoints({ builder }),
    getMyShopItems: builder.query<GetListResults<ShopItemRecord>, string>({
      query: (id) => ({ type: apiMethodType.getListById, method: shopItemApi.getListByShop, id }),
      providesTags: ['MyShopItem'],
    }),
    ...shopItemEndpoints({ builder }),
    ...shopItemOptionTemplateEndpoints({ builder }),
    getShopItemVariant: getOne(builder, shopItemVariantApi, entityType.ShopItemVariant),
    getShopItemVariantsByShopItemAndIsAvailable: builder.query<RecordType, string>({
      query: (id) => ({ method: shopItemVariantApi.getListByShopItemAndIsAvailable, id }),
      providesTags: ['ShopItemVariant'],
    }),
    getShopItemVariantCodeOption1sByShopItem: builder.query<RecordType, string>({
      query: (id) => ({ method: shopItemVariantCodeApi.getListByShopItemAndOption1, id }),
      providesTags: ['ShopItemVariantCodeOption1'],
    }),
    getShopItemVariantCodeOption2sByShopItem: builder.query<RecordType, string>({
      query: (id) => ({ method: shopItemVariantCodeApi.getListByShopItemAndOption2, id }),
      providesTags: ['ShopItemVariantCodeOption2'],
    }),
    getSitter: getOne(builder, sitterApi, entityType.Sitter),
    // getShops: builder.query<GetListResults, void>({
    //   query: () => ({ method: shopApi.getList }),
    //   providesTags: ['Shop'],
    // }),
    ...stripeAccountEndpoints({ builder }),
    getVan: getOne(builder, vanApi, entityType.Van),
    getVanBuilder: getOne(builder, vanBuilderApi, entityType.VanBuilder),
    getVanRepair: getOne(builder, vanRepairApi, entityType.VanRepair),
    getVanSupply: getOne(builder, vanSupplyApi, entityType.VanSupply),
    getVet: getOne(builder, vetApi, entityType.Vet),
  }),
});

export const {
  // Blog:
  useCreateBlogMutation,
  useGetBlogQuery,
  useUpdateBlogMutation,

  useGetBusQuery,
  // useGetBusesQuery,
  useGetBusBuilderQuery,
  useGetBusBuildersQuery,
  useGetBusinessQuery,
  useGetBusRepairQuery,
  useGetBusSupplyQuery,

  useCreateCartMutation,
  useCreateShopMutation,
  // useCreateShopItemMutation,
  useGetCartQuery,
  useGetCartByShopQuery,
  useUpdateCartMutation,
  useUpdateCartTotalMutation,
  useUpdateShopMutation,
  // useUpdateShopItemMutation,

  // CartItem:
  useAdjustCartItemsMutation,
  useCreateCartItemMutation,
  useGetCartItemQuery,
  useGetCartItemsByCartQuery,
  useUpdateCartItemMutation,

  // DogPark:
  useGetDogParkQuery,

  // FoundPet:
  useGetFoundPetQuery,

  // Groomer:
  useGetGroomerQuery,

  // LostPet:
  useGetLostPetQuery,

  // MailMessage:
  useGetMailMessageQuery,

  // Member:
  useGetMemberQuery,
  useGetMemberByNameQuery,

  // PclHeader:
  useCreatePclHeaderMutation,
  useGetPclHeaderQuery,
  useGetPclHeaderByEntityTypeQuery,
  useUpdatePclHeaderMutation,

  // PclService:
  useCreatePclServiceMutation,
  useGetPclServiceQuery,
  useGetPclServiceByServiceCodeQuery,
  useUpdatePclServiceMutation,

  // PclTier:
  useCreatePclTierMutation,
  useGetPclTierQuery,
  useGetPclTiersByPclServiceQuery,
  useUpdatePclTierMutation,

  // PetType:
  useGetPetTypeQuery,

  // Post:
  useCreatePostMutation,
  useDeletePostMutation,
  useGetPostQuery,
  useUpdatePostMutation,

  // Reaction:
  useCreateReactionMutation,
  useGetReactionQuery,
  useGetReactionByReactedToQuery,
  useUpdateReactionMutation,

  // useGetMyShopItemsQuery,
  useGetOrderQuery,
  useGetPetQuery,
  useGetPetFoodQuery,
  useGetPetTrainerQuery,
  useGetPetWalkerQuery,
  useGetPetWorkplaceQuery,
  useGetPlaceQuery,

  // Shop:
  useGetShopQuery,
  useGetShopByUrlOrIdQuery,

  // ShopItem:
  useGetShopItemQuery,
  useGetShopItemByShopAndSkuQuery,

  // ShopItemOptionTemplate:
  useCreateShopItemOptionTemplateMutation,
  useGetShopItemOptionTemplateQuery,
  useGetShopItemOptionTemplatesByShopQuery,
  useUpdateShopItemOptionTemplateMutation,

  // ShopItemVariant:
  useGetShopItemVariantQuery,
  useGetShopItemVariantsByShopItemAndIsAvailableQuery,
  useGetShopItemVariantCodeOption1sByShopItemQuery,
  useGetShopItemVariantCodeOption2sByShopItemQuery,
  useGetSitterQuery,
  // useGetShopsQuery,

  // StripeAccount:
  useCreateStripeAccountMutation,
  useGetStripeAccountQuery,
  useGetStripeAccountByShopQuery,
  useUpdateStripeAccountMutation,
  useUpdateStripeAccountStatusMutation,

  useGetVanQuery,
  useGetVanBuilderQuery,
  useGetVanRepairQuery,
  useGetVanSupplyQuery,
  useGetVetQuery,
} = apiSlice;
