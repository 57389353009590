import { AcUnit, AirportShuttle, DirectionsBus, Pets } from '@mui/icons-material';
import {
  SelectHook, SetHook,
  useOurSelectGuestMenuSitesMenuOpen,
  useOurSelectMemberMenuSitesMenuOpen,
  useOurSetGuestMenuSitesMenuOpen,
  useOurSetMemberMenuSitesMenuOpen,
} from '@petconsole/pure-fe-state';
import { SetState, webPath } from '@petconsole/pure-shared';
import { AppMenuItems } from '../../../types';
import OurAppMenuCollapsable from './OurAppMenuCollapsable';
import ourCombinedAppMenuItems from './ourCombinedAppMenuItems';

interface OurSitesMenuProps {
  add?: AppMenuItems;
  exclude?: string[];
  guest?: boolean;
}

const OurSitesMenu = ({ add, exclude, guest }: OurSitesMenuProps) => {
  const menuItemsOpen = (useOurSelectMemberMenuSitesMenuOpen as SelectHook<boolean>)();
  const setMenuItemsOpen = (useOurSetMemberMenuSitesMenuOpen as SetHook<boolean>)();
  const guestItemsOpen = (useOurSelectGuestMenuSitesMenuOpen as SelectHook<boolean>)();
  const setGuestItemsOpen = (useOurSetGuestMenuSitesMenuOpen as SetHook<boolean>)();

  const menuItems = [
    [DirectionsBus, 'Bus Console', webPath.busConsole],
    [Pets, 'Pet Console', webPath.petConsole],
    [AirportShuttle, 'Van Console', webPath.vanConsole],
  ] as AppMenuItems;

  const open = guest ? guestItemsOpen : menuItemsOpen;
  const setOpen = guest ? setGuestItemsOpen as SetState<boolean> : setMenuItemsOpen as SetState<boolean>;
  const items = ourCombinedAppMenuItems({ items: menuItems, add, exclude });

  return (
    <OurAppMenuCollapsable open={open} setOpen={setOpen} Icon={AcUnit} label="Our Sites" items={items} divider={true} />
  );
};

OurSitesMenu.whyDidYouRender = true;

export default OurSitesMenu;
