import { RatingApi } from '@petconsole/pure-fe-api';
import { EntityType } from '@petconsole/pure-shared';
import { appThunk } from '../../store/appThunk';
import { payloadCreatorFetchRating } from '../payloadCreators';

const fetchRatingThunk = (sliceName: string, entityType: string, idName: string, api: RatingApi) =>
  appThunk(
    `${sliceName}/fetch${entityType}Rating`,
    payloadCreatorFetchRating(idName, entityType as EntityType, api),
  );

export default fetchRatingThunk;
