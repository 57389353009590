import { Theme } from '@mui/material';
import Slide from './Slide';

interface RightSlideProps {
  borderWidth: number;
  borderRadius: number;
  urls: string[];
}

const RightSlide = ({ borderWidth, borderRadius, urls }: RightSlideProps) => {
  const rightFilmRadius = ({ spacing }: Theme) => spacing(borderRadius, 0, 0, borderRadius);

  return (
    <Slide
      index={0}
      width="12.5%"
      position="left"
      radius={rightFilmRadius}
      borderWidth={borderWidth}
      borderRight={false}
      urls={urls}
    />
  );
};

RightSlide.whyDidYouRender = true;

export default RightSlide;