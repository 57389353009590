import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  FriendRecord,
  GetListOptions,
  GetListResults,
  friendEntity,
  MaybeFriendRecord,
  ApiEntity,
} from '@petconsole/pure-shared';
import { commonApi, fullPath, get, pathParam, post } from './common';

type ApiRecord = FriendRecord;

const apiPath = '/friends';
const { getByValue, getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  accept: (name: string) => Promise<T>;
  cancel: (name: string) => Promise<T>;
  canRequest: (name: string) => Promise<RecordType>;
  decline: (name: string) => Promise<T>;
  getByName: (name: string) => Promise<T | null>;
  pause: (name: string) => Promise<T>;
  resume: (name: string) => Promise<T>;
  getListByMember: (memberName: string, options: GetListOptions) => Promise<GetListResults<T>>;
  request: ({ name, message }: { name: string; message: string }) => Promise<T>;
}

export type FriendApi = Api<ApiRecord> & CustomApi;

export const friendApi: FriendApi = {
  ...common,
  getByValue,
  getListByValue,
  accept: (name) => post(`${apiPath}/accept`, { name } as unknown as FriendRecord),
  cancel: (name) => post(`${apiPath}/cancel`, { name } as unknown as FriendRecord),
  canRequest: (name) => get(fullPath(`${apiPath}/canRequest`, pathParam('name', name))) as Promise<RecordType>,
  decline: (name) => post(`${apiPath}/decline`, { name } as unknown as FriendRecord),
  getByName: (name) => getByValue('name', name) as Promise<MaybeFriendRecord>,
  pause: (name) => post(`${apiPath}/pause`, { name } as unknown as FriendRecord),
  resume: (name) => post(`${apiPath}/resume`, { name } as unknown as FriendRecord),
  getListByMember: (memberName, options) => getListByValue('name', memberName, options),
  request: ({ name, message }) => post(`${apiPath}/request`, { name, message } as unknown as FriendRecord),
};

export const friendApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...friendEntity, api: friendApi };
