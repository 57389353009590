import { RecordType } from '@petconsole/pure-base';
import { SliverData } from '../../types';

const selectEntityByString = (sliver: SliverData, propertyName: string, value: string) => {
  const { ids, entities } = sliver;

  if (!value) return undefined;

  const searchValue = value.toLocaleLowerCase();
  let found;

  ids.forEach((id) => {
    const entityValue = (entities[id] as RecordType)[propertyName];

    if (entityValue && String(entityValue).toLocaleLowerCase() === searchValue) found = entities[id];
  });

  return found;
};

export default selectEntityByString;
