import { Avatar, AvatarProps, Skeleton } from '@mui/material';

export interface OurAvatarProps extends AvatarProps {
  text?: string;
  expectText?: boolean;
  disabled?: boolean;
  aria?: string;
  ariaType?: string;
  skeleton?: boolean;
}

const OurAvatar = ({
  src,
  text = '',
  expectText = false,
  onClick,
  disabled = false,
  aria = 'avatar',
  ariaType,
  skeleton = true,
  ...rest
}: OurAvatarProps) => {
  if (skeleton && ((expectText && !text) || (!src && !text)))
    return (
      <Skeleton variant="circular">
        <Avatar />
      </Skeleton>
    );

  return (
    <Avatar
      aria-label={ariaType ? `${ariaType} ${aria}` : aria}
      src={src}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      {text?.substring(0, 1) || '?'}
    </Avatar>
  );
};

export default OurAvatar;
