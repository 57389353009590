import { MouseEvent } from 'react';
import { Accept } from 'react-dropzone';
import { RecordType, newId } from '@petconsole/pure-base';
import { Image, ImageKey, SetState } from '@petconsole/pure-shared';
import useOurError from '../../error/useOurError';
import PhotoZone from './ourPhotoZone/PhotoZone';

interface UseOurPhotoZoneProps {
  id?: string;
  title?: string;
  leadText?: string;
  imageKey: ImageKey;
  accept?: Accept;
  canEdit?: boolean;
  photo?: Image;
  setPhoto: SetState<Image>;
  photoBoxProps?: RecordType;
}

const useOurPhotoZone = ({
  id = newId(),
  title = 'Image',
  leadText,
  imageKey,
  accept = { 'image/*': ['.jpeg', '.png'] },
  canEdit = false,
  photo,
  setPhoto,
  photoBoxProps,
}: UseOurPhotoZoneProps) => {
  const { log } = useOurError();

  const onDrop = ({ src = '', contentType }: Image) => {
    setPhoto({
      src,
      title,
      contentType,
      s3Key: imageKey({ id, src }),
      newImage: true,
    });
  };

  const onRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPhoto({} as Image);
  };

  const photoZone = (
    <PhotoZone
      photo={photo}
      photoBoxProps={photoBoxProps}
      canEdit={canEdit}
      onDrop={onDrop}
      onRemove={onRemove}
      accept={accept}
      leadText={leadText}
      log={log}
    />
  );

  return { photoZone };
};

export default useOurPhotoZone;
