import { MouseEventHandler } from 'react';
import { CardMedia, CardMediaProps } from '@mui/material';
import OurRemoveImageButton from '../buttons/OurRemoveImageButton';

interface OurRemovableImageProps extends CardMediaProps {
  src?: string;
  alt?: string;
  disabled?: boolean;
  onRemove: MouseEventHandler;
}

const OurRemovableImage = ({
  src = '',
  alt = 'Image',
  disabled = false,
  onRemove,
  ...props
}: OurRemovableImageProps) => (
  <CardMedia sx={{ position: 'relative' }} {...props}>
    <img src={src} alt={alt} width="100%" height="auto" style={{ zIndex: 1 }} />
    {!disabled && <OurRemoveImageButton onRemove={onRemove} />}
  </CardMedia>
);

OurRemovableImage.whyDidYouRender = true;

export default OurRemovableImage;
