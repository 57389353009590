import { PropsWithChildren } from 'react';
import { Grid, Typography } from '@mui/material';
import OurMarginGridCard from '../components/cards/OurMarginGridCard';
import OurBackHeadings from '../components/headings/OurBackHeadings';
import OurUnsplashImage from '../components/misc/unsplash/OurUnsplashImage';
import { commonMedia } from '../constants';

// noinspection SpellCheckingInspection
const OurFaqPage = ({ children }: PropsWithChildren) => (
  <OurMarginGridCard>
    <OurBackHeadings heading="Frequently Asked Questions" />
    <Grid item xs={12}>
      <OurUnsplashImage
        image={commonMedia.faq}
        alt="FAQ image"
        imagePath="s/photos/questions"
        authorPath="@camylla93"
        authorName="Camylla Battani"
      />
      <Typography gutterBottom>
        <strong>Is it free to join?</strong>
      </Typography>
      <Typography paragraph>It sure is. We do not charge to join, and we have a generous free tier.</Typography>
      <Typography gutterBottom>
        <strong>How can I change my password?</strong>
      </Typography>
      <Typography paragraph>On the login screen, use the "Forgot password?" link to enter a new password.</Typography>
      <Typography gutterBottom>
        <strong>What is the Pet Console "family"?</strong>
      </Typography>
      <Typography paragraph>
        When we decided to develop and support multiple communities, it made sense to combine them under a common
        framework (Pet Console). Using a common framework makes it easy for members to join one community and easily
        enter other communities using the same login credentials.
      </Typography>
      <Typography gutterBottom>
        <strong>How did you come up with the name Pet Console?</strong>
      </Typography>
      <Typography paragraph={!!children} gutterBottom={!children}>
        We use and pronounce the word console as a noun. In very loose terms, console is an abbreviated version of
        consolide, which in turn is an abbreviated version of consolidate or consolidated. When used in this form, you
        can think of Pet Console as a consolidated view of many pet related products and services.
      </Typography>
      {children}
    </Grid>
  </OurMarginGridCard>
);

OurFaqPage.whyDidYouRender = true;

export default OurFaqPage;
