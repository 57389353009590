import { RecordType } from '@petconsole/pure-base';
import { shopItemOptionTemplateApiEntity } from '@petconsole/pure-fe-api';
import { ShopItemOptionTemplateRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import useOurSelectsPropertyByValue from '../../../helpers/hooks/useOurSelectsPropertyByValue';
import {
  BaseSliceOptions,
  BaseSliceTab,
  FetchByIdHook,
  OurEntityHooks,
  RequiredCrudSliverHooks,
  SelectByHook,
  SelectByIdHook,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = ShopItemOptionTemplateRecord;

const apiEntity = shopItemOptionTemplateApiEntity;
const { name: sliceName } = apiEntity;
const shopItemOptionTemplate = sliceName;

const Tab = { shopItemOptionTemplate } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  shopItemOptionTemplate: { default: 'Values' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  canDelete: true,
  tabs,
  fetchManyByProperty: { propertyName: 'shopId', apiMethod: 'getListByShop' },
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, tabHooks } = sliceHookTypes;

export interface OurShopItemOptionTemplateHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  shopItemOptionTemplateHooks: RequiredCrudSliverHooks;
  tabHooks: TabHooks;
  customHooks: {
    useFetchByShop: FetchByIdHook;
    useSelectByShop: SelectByHook;
    useSelectNamesByShop: SelectByIdHook;
  };
}

export const ourShopItemOptionTemplateHooks: OurShopItemOptionTemplateHooks = {
  entity: apiEntity,
  shopItemOptionTemplateHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
  customHooks: {
    useFetchByShop: hooks.useOurFetchShopItemOptionTemplatesByShopId as OurShopItemOptionTemplateHooks['customHooks']['useFetchByShop'],
    useSelectByShop: hooks.useOurSelectShopItemOptionTemplatesByShopId as OurShopItemOptionTemplateHooks['customHooks']['useSelectByShop'],
    useSelectNamesByShop: (shopId: string) =>
      useOurSelectsPropertyByValue({
        sliceName,
        sliverName: sliceName,
        propertyName: 'name',
        valueName: 'shopId',
        value: shopId,
      }),
  },
};

export default reducer;
