import { cityStatApiEntity } from '@petconsole/pure-fe-api';
import { CityStatRecord } from '@petconsole/pure-shared';
import { compareCityStat, fetchParams, sliceHookTypes } from '../../helpers';
import { BaseSliceOptions, OurEntityHooks, RecordType, RequiredSliverHooks } from '../../types';
import BaseEntitySlice from '../base/BaseEntitySlice';

type ApiEntityRecord = CityStatRecord;

const apiEntity = cityStatApiEntity;
const { api, name: sliceName, proper } = apiEntity;
const memberSliver = `member${proper}`;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  sortProperty: 'members',
  tabs: undefined,
  slivers: [
    {
      prefix: 'member',
      comparer: compareCityStat('members'),
      payloadCreators: async (_: unknown, { getState }: RecordType) =>
        api.getListByMembers(fetchParams(getState, sliceName, memberSliver)),
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { sliverHooks } = sliceHookTypes;

export interface OurCityStatHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  memberCityStatHooks: RequiredSliverHooks;
}

export const ourCityStatHooks: OurCityStatHooks = {
  entity: apiEntity,
  memberCityStatHooks: sliverHooks(apiEntity, 'Member', hooks) as RequiredSliverHooks,
};

export default reducer;
