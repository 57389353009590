import { feedApiEntity, feedDefApiEntity } from '@petconsole/pure-fe-api';
import { FeedDefRecord, FeedRecord, reactAppApiSite } from '@petconsole/pure-shared';
import { writeReducers } from '../../helpers';
import { appThunk } from '../../store/appThunk';
import { EntityAdapterWithPick, RecordType } from '../../types';

const { api, name: sliceName, proper } = feedApiEntity;
const feedDefApi = feedDefApiEntity.api;
const typePrefix = `${sliceName}/create${proper}Post`;

export const createFeedPostPayloadCreator = async ({ name, feed }: RecordType) => {
  const feedDef = (await feedDefApi.getByName(name || reactAppApiSite)) as FeedDefRecord;

  return (await api.create({ ...feed, defId: feedDef.feedDefId })) as FeedRecord;
};

export const createFeedPostThunk = appThunk(typePrefix, createFeedPostPayloadCreator);

export const createFeedPostReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(createFeedPostThunk, sliceName, adapter);
