import { PropsWithChildren } from 'react';
import { ErrorBoundary, LEVEL_WARN } from '@rollbar/react';
import RollbarBoundaryFallback from './RollbarBoundaryFallback';

const OurRollbarErrorBoundary = ({ children }: PropsWithChildren) => (
  <ErrorBoundary level={LEVEL_WARN} fallbackUI={RollbarBoundaryFallback}>
    {children}
  </ErrorBoundary>
);

export default OurRollbarErrorBoundary;
