import { blogApiEntity } from '@petconsole/pure-fe-api';
import { BlogQueryMonth, BlogRecord, MaybeBlogRecord } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchAction, useOurDispatchEntity } from '../../helpers';
import fetchParams from '../../helpers/misc/fetchParams';
import {
  BaseSliceOptions,
  FetchSelectHooks,
  OurEntityHooks,
  ReactionHooks,
  RecordType,
  RequiredCrudSliverByHooks,
  SelectSetHooks,
  ThunkApi,
} from '../../types';
import BaseEntitySlice from '../base/BaseEntitySlice';
import { createBlogPostReducers, createBlogPostThunk } from './createBlogPost';
import {
  fetchFirstBlogReducers,
  fetchFirstBlogThunk,
  fetchLastBlogReducers,
  fetchLastBlogThunk,
} from './fetchFirstLast';

type ApiEntityRecord = BlogRecord;

const apiEntity = blogApiEntity;
const { api, name: sliceName, idName } = apiEntity;
const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  hasReaction: true,
  canDelete: true,
  idName,
  comparer: ({ createdAt: a }, { createdAt: b }) => b?.localeCompare(a),
  tabs: undefined,
  sliceProperties: [
    { suffix: 'QueryMonth', default: null },
    { suffix: 'Submitting', default: false },
    { suffix: 'FirstBlog', default: null },
    { suffix: 'LastBlog', default: null },
  ],
  slivers: [
    {
      prefix: sliceName,
      fetchesByValue: true,
      payloadCreators: ({ from = '', to = '', scanForward = false }: RecordType, { getState }: ThunkApi) =>
        api.getListByUrlAndCreatedBetween(
          { fromCreatedAt: from, toCreatedAt: to },
          { ...fetchParams(getState, sliceName, sliceName), scanForward },
        ),
      extraReducers: [createBlogPostReducers, fetchFirstBlogReducers, fetchLastBlogReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudByHooks, reactionHooks } = sliceHookTypes;

export interface OurBlogHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  blogHooks: RequiredCrudSliverByHooks;
  reactionHooks: ReactionHooks;
  customHooks: {
    queryMonth: SelectSetHooks<BlogQueryMonth>;
    submitting: SelectSetHooks<boolean>;
    firstBlog: FetchSelectHooks<MaybeBlogRecord>;
    lastBlog: FetchSelectHooks<MaybeBlogRecord>;
  };
}

export const ourBlogHooks: OurBlogHooks = {
  entity: apiEntity,
  blogHooks: {
    ...crudByHooks(apiEntity, hooks),
    useCreate: () => useOurDispatchEntity(sliceName, createBlogPostThunk),
  } as Required<RequiredCrudSliverByHooks>,
  reactionHooks: reactionHooks(apiEntity, hooks),
  customHooks: {
    queryMonth: {
      useSelect: hooks.useOurSelectBlogQueryMonth as OurBlogHooks['customHooks']['queryMonth']['useSelect'],
      useSet: hooks.useOurSetBlogQueryMonth as OurBlogHooks['customHooks']['queryMonth']['useSet'],
    },
    submitting: {
      useSelect: hooks.useOurSelectBlogSubmitting as OurBlogHooks['customHooks']['submitting']['useSelect'],
      useSet: hooks.useOurSetBlogSubmitting as OurBlogHooks['customHooks']['submitting']['useSet'],
    },
    firstBlog: {
      useFetch: () => useOurDispatchAction(fetchFirstBlogThunk),
      useSelect: hooks.useOurSelectBlogFirstBlog as OurBlogHooks['customHooks']['firstBlog']['useSelect'],
    },
    lastBlog: {
      useFetch: () => useOurDispatchAction(fetchLastBlogThunk),
      useSelect: hooks.useOurSelectBlogLastBlog as OurBlogHooks['customHooks']['lastBlog']['useSelect'],
    },
  },
};

export default reducer;
