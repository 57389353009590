import { RecordType } from '@petconsole/pure-base';
import { Slice } from '../../types';

export const sliceDefaultTabs = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  const { name: sliceName, proper, plural, pluralProper, option } = slice;
  const { sliceTabs } = option;
  const tabs = { ...option.tabs };

  if (option.tabs) {
    Object.keys(tabs).forEach((key) => {
      if (!tabs[key].default) {
        if (key === sliceName) tabs[key].default = 'General';
        else if (key === `my${proper}`) tabs[key].default = 'General';
        else if (key === plural) tabs[key].default = 'By City';
        else if (key === `${sliceName}Admin`) tabs[key].default = 'By City';
      }
    });
  } else if (sliceTabs) {
    sliceTabs.forEach((tab) => {
      if (!tab.default) {
        if (tab.prefix === sliceName) tab.default = 'General';
        else if (tab.prefix === `my${proper}`) tab.default = 'General';
        else if (tab.prefix === plural) tab.default = 'By City';
        else if (tab.prefix === `${sliceName}Admin`) tab.default = 'By City';
      }
    });
  } else if (slice.flag.hasTabValues) {
    slice.tabPrefixes = [`${sliceName}Admin`, sliceName, plural];

    if (slice.flag.hasMySliver)
      slice.tabPrefixes.push(`my${proper}`, `my${pluralProper}`);
  }

  return tabs;
}
