import { Comment, ModeCommentOutlined } from '@mui/icons-material';
import { IsOpenProps } from '@petconsole/pure-shared';
import ActionButton, { ActionButtonProps } from './ActionButton';

interface OurCommentsButtonProps extends IsOpenProps, Omit<ActionButtonProps, 'tip' | 'Icon' | 'onClick'> {
  tip?: ActionButtonProps['tip'];
  commented?: boolean;
}

const OurCommentsButton = ({ tip = 'Comments', isOpen, setIsOpen, commented, ...rest }: OurCommentsButtonProps) => (
  <ActionButton
    tip={tip}
    aria-expanded={isOpen}
    Icon={commented ? Comment : ModeCommentOutlined}
    onClick={() => setIsOpen(!isOpen)}
    {...rest}
  />
);

OurCommentsButton.whyDidYouRender = true;

export default OurCommentsButton;
