import { MouseEvent, useState } from 'react';
import { Accept } from 'react-dropzone';
import { newId } from '@petconsole/pure-base';
import { Image, Images, ReturnsBoolean, ReturnsString, SetNumber, StringReturnsString } from '@petconsole/pure-shared';
import useOurError from '../../error/useOurError';
import ImageZone from './ourImageZone/ImageZone';

interface UseOurImageZoneProps {
  accept?: Accept;
  maxFiles?: number;
  leadText?: string;
  canEdit?: ReturnsBoolean;
  getTitle?: ReturnsString;
  getKey?: StringReturnsString;
  changeCount?: number;
  setChangeCount?: SetNumber;
}

const useOurImageZone = ({
  accept = { 'image/*': ['.jpeg', '.png'] },
  maxFiles = 1,
  leadText,
  canEdit = () => true,
  getTitle = () => 'Image',
  getKey = () => newId(),
  changeCount = 0,
  setChangeCount = () => 0,
}: UseOurImageZoneProps) => {
  const { log } = useOurError();
  const [images, setImages] = useState([] as Images);

  const onDrop = (newImages: Images) => {
    const mappedImages = newImages.map(
      ({ src, contentType }: Image) =>
        ({
          src,
          contentType,
          s3Key: getKey(src as string),
          title: getTitle(),
          newImage: true,
        }) as Image,
    );

    setImages(images.concat(mappedImages));
    setChangeCount(changeCount + 1);
  };

  const onRemove = (event: MouseEvent, s3Key: string) => {
    event.stopPropagation();

    setImages(images.filter((image) => image.s3Key !== s3Key));
    setChangeCount(changeCount + 1);
  };

  const imageZone = (
    <ImageZone
      images={images}
      canEdit={canEdit}
      onDrop={onDrop}
      onRemove={onRemove}
      accept={accept}
      maxFiles={maxFiles}
      leadText={leadText}
      log={log}
    />
  );

  return { images, setImages, imageZone };
};

export default useOurImageZone;
