import { RecordType } from '@petconsole/pure-base';
import { ResetReadWriteStateType } from '../../types';
import { initialReadWriteState } from '../sliceHelpers';

const resetReadWriteState: ResetReadWriteStateType = (stateProperty) => (state) => {
  Object.entries(initialReadWriteState).forEach(([key, value]) => {
    (state[stateProperty] as RecordType)[key] = value;
  });
};

export default resetReadWriteState;
