import { MouseEventHandler } from 'react';
import { Box, Skeleton } from '@mui/material';
import { properCase } from '@petconsole/pure-base';
import OurEntityBannerImage from '../images/OurEntityBannerImage';

interface OurCardBannerImageProps {
  entity: string;
  name?: string;
  image?: string;
  onImage?: MouseEventHandler;
  preview?: boolean;
}

const OurCardBannerImage = ({ entity, name, image, onImage, preview }: OurCardBannerImageProps) => {
  const capped = properCase(entity);
  const componentId = `${capped}BannerImage`;

  if (!image) return null;

  if (!name) return <Skeleton />;

  return (
    <Box
      display="flex"
      width="100%"
      id={componentId}
      onClick={onImage}
      sx={{ cursor: preview ? 'default' : 'pointer' }}
    >
      <OurEntityBannerImage parentComponentId={componentId} image={image} alt={`${capped} Banner`} />
    </Box>
  );
};

OurCardBannerImage.whyDidYouRender = true;

export default OurCardBannerImage;
