// noinspection ES6PreferShortImport

import { MaybeNull, MaybeString, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, MemberColumns, TimeColumns } from '../../types';

export interface Reaction extends MemberColumns, TimeColumns {
  type: DataEntityType;
  reactionId: string;
  reactionToId: string;
  reactionToType: string;
  liked: boolean;
  likedAt: MaybeString;
  commented: boolean;
  commentedAt: MaybeString;
  sent: boolean;
  sentAt: MaybeString;
  shared: boolean;
  sharedAt: MaybeString;
  reported: boolean;
  reportedAt: MaybeString;
  lastFeedAt: MaybeString;
  json: RecordType;
}

export const reactionEntity = entityAttributes({
  name: entityName.reaction,
  attributes: {
    category: entityCategory.item,
    columns: { crud: false, images: false, member: true, time: true },
    nameField: undefined,
    test: { invalidData: () => ({ liked: {} }), validData: () => ({ liked: true }) },
  },
});

const { boolean, date, id2, type } = schema;
export const reactionSchema = entitySchema<Reaction>(reactionEntity, {
  reactionToId: id2().required(),
  reactionToType: type(),
  liked: boolean(),
  likedAt: date(),
  commented: boolean(),
  commentedAt: date(),
  sent: boolean(),
  sentAt: date(),
  shared: boolean(),
  sharedAt: date(),
  reported: boolean(),
  reportedAt: date(),
  lastFeedAt: date(),
});

export const reactionDefaults: Partial<Reaction> = {
  reactionToId: '',
  reactionToType: '',
  liked: false,
  likedAt: null,
  commented: false,
  commentedAt: null,
  sent: false,
  sentAt: null,
  shared: false,
  sharedAt: null,
  reported: false,
  reportedAt: null,
  lastFeedAt: null,
};

export interface ReactionRecord extends Reaction, RecordType {}

export type MaybeReactionRecord = MaybeNull<ReactionRecord>;
export type RecordOfReactionRecords = Record<string, ReactionRecord>;

export const initialReaction: InitialEntity<Reaction, ReactionRecord> = (overrides = {}) =>
  initialEntity<Reaction>(reactionEntity, { ...reactionDefaults, ...overrides }) as ReactionRecord;
