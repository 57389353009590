import { useEffect } from 'react';
import { CityLocation } from '@petconsole/pure-base';
import useOurDefaultLocation from './useOurDefaultLocation';

interface UseOurSetDefaultLocationProps {
  location?: CityLocation;
}

// Whenever the location is changed, we'll want to update the default location for future use
const useOurSetDefaultLocation = ({ location }: UseOurSetDefaultLocationProps) => {
  const { getDefaultLocation, setDefaultLocation } = useOurDefaultLocation();

  useEffect(() => {
    getDefaultLocation().then((oldLocation) => {
      // Update the default location for the next time we enter a screen that requires one
      // Be sure to handle null and undefined as well as changes to the location content
      // We never "clear" the default location, once we have one
      if (location && JSON.stringify(location) !== JSON.stringify(oldLocation)) setDefaultLocation(location || {}); // Can't set to undefined
    });
  }, [location, getDefaultLocation, setDefaultLocation]);
};

export default useOurSetDefaultLocation;
