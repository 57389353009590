import { Reply } from '@mui/icons-material';
import { IsOpenProps } from '@petconsole/pure-shared';
import ActionButton, { ActionButtonProps } from './ActionButton';

export interface OurRepliesButtonProps extends IsOpenProps, Omit<ActionButtonProps, 'tip' | 'Icon' | 'onClick'> {
  tip?: ActionButtonProps['tip'];
  Icon?: ActionButtonProps['Icon'];
}

const OurRepliesButton = ({ tip = 'Replies', Icon = Reply, isOpen, setIsOpen, ...rest }: OurRepliesButtonProps) => (
  <ActionButton tip={tip} aria-expanded={isOpen} Icon={Icon} onClick={() => setIsOpen(!isOpen)} {...rest} />
);

OurRepliesButton.whyDidYouRender = true;

export default OurRepliesButton;
