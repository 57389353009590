import { ReactNode } from 'react';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Box, CardContent, Rating, RatingProps, SxProps, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { UpdateRating } from '@petconsole/pure-fe-state';
import { RatingValues } from '@petconsole/pure-shared';
import OurAverageRating from '../misc/averageRating/OurAverageRating';

interface OurRatingCardContentProps {
  ratings: RatingValues;
  yourRating?: number;
  update: UpdateRating;
  id: string;
  readOnly?: boolean;
  centerContent?: ReactNode;
  sx?: SxProps<Theme>;
}

const OurRatingCardContent = ({
  ratings,
  yourRating,
  update,
  id,
  readOnly,
  centerContent,
  sx = {},
}: OurRatingCardContentProps) => {
  const { palette } = useTheme();

  const onChangeRating: RatingProps['onChange'] = (_event, newValue) => update({ id, rating: newValue || 0 });

  return (
    <CardContent
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 1,
        pt: 0.5,
        '&:last-child': { pb: 1 },
        ...sx,
      }}
    >
      <OurAverageRating ratings={ratings} />
      {centerContent}
      <Box>
        <Typography variant="caption" component="legend">
          Your Rating: {yourRating}
        </Typography>
        <Rating
          aria-label="your-rating"
          value={yourRating}
          readOnly={readOnly}
          onChange={onChangeRating}
          precision={0.5}
          icon={<Favorite fontSize="inherit" />}
          emptyIcon={<FavoriteBorder fontSize="inherit" />}
          style={{ color: palette.secondary.main }}
        />
      </Box>
    </CardContent>
  );
};

OurRatingCardContent.whyDidYouRender = true;

export default OurRatingCardContent;
