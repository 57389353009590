import { ElementType, memo, ReactElement, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { ObjectShape } from 'yup';
import { RecordType } from '@petconsole/pure-base';
import { OurEntityHooks, RequiredCrudSliverHooks, TabValueHooks } from '@petconsole/pure-fe-state';
import {
  EntityAttributes,
  EntityRecord,
  EntityTabName,
  EntityTabNames,
  ourArrayToEntries,
  RecordOfEntityTabDefinitions,
  RecordOfTabValueHooks,
  ReturnsVoid,
  StringReturnsVoid,
  WebPath,
  webPath,
} from '@petconsole/pure-shared';
import OurOutlinedComments from '../../comment/OurOutlinedComments';
import useOurAvatarEditor from '../../components/avatar/useOurAvatarEditor';
import useEntityCardReaction from '../../components/cards/ourEntityCards/helpers/useEntityCardReaction';
import OurTitleHeading from '../../components/headings/OurTitleHeading';
import { defaultAvatar } from '../../constants';
import useOurOnOpen from '../../hooks/useOurOnOpen';
import { AddEntityType, DefaultAvatar } from '../../types';
import ListEntityDetails from '../components/ListEntityDetails';
import { defaultListEntityFormValues } from '../constants';
import OurListEntityGeneral from '../OurListEntityGeneral';
import OurListEntityMain from '../OurListEntityMain';
import OurListEntityToolbar from '../OurListEntityToolbar';
import { OurListEntityAddProps } from '../types';
import useOurAddListEntity from './useOurAddListEntity';
import useOurBannerImage from './useOurBannerImage';
import useOurFormik from './useOurFormik';
import useOurListEntityRead from './useOurListEntityRead';
import useOurListEntityState from './useOurListEntityState';
import useOurListEntitySubmit from './useOurListEntitySubmit';
import useOurListEntityTabs from './useOurListEntityTabs';
import useOurOnEditListEntity from './useOurOnEditListEntity';
import useOurPhotos from './useOurPhotos';

interface UseOurListEntityCardProps<T extends RecordType = RecordType> {
  entity: EntityAttributes;
  hooks: OurEntityHooks<T>;
  idParam?: string;
  stateIds?: string[];
  title?: string;
  heading?: string;
  tabNames?: RecordType;
  tabValues?: string;
  tabHooks?: TabValueHooks;
  tabDefinitions?: RecordOfEntityTabDefinitions;
  tabValue?: string;
  setTabValue?: StringReturnsVoid;
  scrollButtons?: boolean;
  directions?: boolean;
  Main?: ElementType;
  mainProps?: RecordType;
  Add?: AddEntityType;
  General?: ElementType;
  generalProps?: RecordType;
  Details?: ElementType;
  Comments?: ElementType;
  commentsHeading?: string;
  Preview?: ElementType;
  schema: ObjectShape;
  values?: RecordType;
  customData?: RecordType;
  getCustomData?: () => RecordType;
  help?: string;
  helpTitle?: string;
  add?: boolean;
  addProps?: OurListEntityAddProps<T>;
  viewOnly?: boolean;
  saveButton?: ReactElement;
  beforeSubmit?: (values: EntityRecord) => EntityRecord;
  beforeSave?: (values: EntityRecord) => EntityRecord;
  customSave?: (changes: EntityRecord) => Promise<EntityRecord>;
  customCancel?: ReturnsVoid;
  afterSave?: (saved: EntityRecord) => unknown;
  afterSubmit?: (saved: EntityRecord) => void;
  startEditing?: boolean;
}

const useOurListEntityCardProps = <T extends RecordType = RecordType>({
  entity,
  hooks,
  idParam = 'id',
  stateIds,
  title,
  heading,
  tabNames,
  tabValues,
  tabHooks: customTabHooks,
  tabValue: customTabValue,
  setTabValue,
  tabDefinitions,
  scrollButtons,
  directions,
  Main = OurListEntityMain,
  mainProps = {},
  Add,
  General = OurListEntityGeneral,
  generalProps = {},
  Details = ListEntityDetails,
  Comments = OurOutlinedComments,
  commentsHeading,
  Preview,
  schema,
  values = {},
  customData,
  getCustomData,
  help,
  helpTitle,
  add = true,
  addProps,
  viewOnly,
  saveButton,
  beforeSubmit,
  beforeSave,
  customSave,
  customCancel,
  afterSave,
  afterSubmit,
  startEditing,
}: UseOurListEntityCardProps<T>) => {
  const { columns, name: entityName, memberOwned, plural, shared, typeWords, avatarKey, imageKey } = entity;
  const { reactionHooks } = hooks;
  const crudHooks = hooks[`${entityName}Hooks`] as RequiredCrudSliverHooks;
  const tabHooks = customTabHooks || (hooks.tabHooks as RecordOfTabValueHooks)[tabValues || entityName];
  const path = webPath[plural as WebPath];
  const entityAvatar = defaultAvatar[entityName as DefaultAvatar];
  const defaultTabValue = tabHooks.useSelect();
  const tabValue = (customTabValue || defaultTabValue) as EntityTabName;
  const { data, id, onPrev, onNext, prevId, nextId, reRead } = useOurListEntityRead<T>({
    entity,
    hooks,
    idParam,
    stateIds,
    path,
  });
  const { reaction } = useEntityCardReaction({ reactionHooks, cardId: id, entityRecord: data });
  const { isOpen, setIsOpen, onOpen: onAdd } = useOurOnOpen();
  const { editing, setEditing, owned, owner, guest, canEdit, onEdit } = useOurOnEditListEntity({ entity, data, viewOnly, startEditing });
  const avatarEditor = useRef(undefined as AvatarEditor | undefined);
  const { avatar, setAvatar, getAvatar, avatarChanged, setAvatarChanged, setAvatarEditor } = useOurAvatarEditor({
    avatarEditor,
    currentAvatar: data?.avatar,
    defaultAvatar: entityAvatar,
  });
  const { banner, setBanner, getBanner, saveBanner, bannerFrom } = useOurBannerImage({
    image: data?.banner || undefined,
  });
  const { images, setImages, imageZone, Photos } = useOurPhotos({ entity, id, editing, owner });
  const [formValues, setFormValues] = useState({ ...defaultListEntityFormValues, ...values } as RecordType);
  const writeCustomProps = { beforeSubmit, beforeSave, customSave, afterSave, afterSubmit };
  const writeProps = { entity, writeHooks: crudHooks, setEditing, tabValue, setFormValues, ...writeCustomProps };
  const writeAvatarProps = { images, avatar, setAvatar, avatarEditor: avatarEditor.current, avatarChanged, avatarKey };
  const writeImageProps = { ...writeAvatarProps, bannerFrom, saveBanner };
  const { onSubmit, submitting, setSubmitting, busy, setBusy } = useOurListEntitySubmit({
    ...writeProps,
    ...writeImageProps,
  });
  const { formik } = useOurFormik({ formValues, schema, editing, onSubmit });
  const readOnly = formik.isReadOnly || (!owner && !shared);

  const stateProps = {
    formik,
    data,
    customData,
    getCustomData,
    getAvatar,
    getBanner,
    setImages,
    setFormValues,
    setEditing,
    customCancel,
  };
  const { initializeState, addressInput, setAddressInput, onCancel } = useOurListEntityState(stateProps);
  const directionsMap = directions !== undefined ? directions : columns.address;

  const tabs = tabNames || ourArrayToEntries(entity.tabs as EntityTabNames);
  const editProps = {
    entity,
    hooks,
    formik,
    id,
    tabNames: tabs,
    tabHooks,
    path,
    editing,
    owner,
    readOnly,
    guest,
    reaction,
    directions: directionsMap,
    reRead,
  };
  const imageProps = {
    avatar,
    setAvatar,
    setAvatarChanged,
    setAvatarEditor,
    imageKey,
    banner,
    setBanner,
    photos: images,
  };
  const tabsProps = {
    General,
    generalProps,
    Details,
    Photos,
    Comments,
    commentsHeading,
    Preview,
    tabDefinitions,
    tabValue,
    setTabValue,
    scrollButtons,
  };
  const { EntityTabs } = useOurListEntityTabs({ ...tabsProps, ...editProps, ...imageProps });

  const titleHeading = `${editing ? 'Edit' : ''} ${owner && memberOwned ? 'My' : ''} ${heading || typeWords}`.trim();
  const Heading = memo(() => <OurTitleHeading title={title} heading={titleHeading} guestMode />);

  const toolbarProps = {
    saveButton,
    editing,
    canEdit,
    onEdit,
    onCancel,
    onPrev,
    onNext,
    prevId,
    nextId,
    guest,
    ...(add && { onAdd }),
  };
  const Toolbar = memo(() => <OurListEntityToolbar {...toolbarProps} handleSubmit={formik.handleSubmit} />);

  const { AddListEntity } = useOurAddListEntity<T>({ entity, crudHooks, addProps });

  const AddEntity = memo(() => {
    if (Add) return <Add isOpen={isOpen} setIsOpen={setIsOpen} />;
    if (add) return <AddListEntity isOpen={isOpen} setIsOpen={setIsOpen} />;

    return null;
  });

  return {
    entity,
    data,
    formik,
    initializeState,
    submitting,
    setSubmitting,
    busy,
    setBusy,
    owned,
    isOwner: owner,
    isEditing: editing,
    readOnly,
    directions: directionsMap,
    help,
    helpTitle,
    addressInput,
    setAddressInput,
    avatarEditor,
    avatar,
    setAvatar,
    setAvatarChanged,
    images,
    setImages,
    imageZone,
    Heading,
    Toolbar,
    Main,
    mainProps,
    Add: AddEntity,
    addIsOpen: isOpen,
    setAddIsOpen: setIsOpen,
    EntityTabs,
  };
};

export default useOurListEntityCardProps;
