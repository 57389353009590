import { Typography, TypographyProps } from '@mui/material';

interface LighterTextProps extends TypographyProps {
  text: string;
}

const LighterText = ({ text, ...rest }: LighterTextProps) => (
  <Typography variant="body2" style={{ opacity: 0.9 }} {...rest}>
    {text}
  </Typography>
);

LighterText.whyDidYouRender = true;

export default LighterText;
