import { useCallback } from 'react';
import { PayloadActionCreator } from '@reduxjs/toolkit';
import { CityLocation } from '@petconsole/pure-base';
import { useAppDispatch } from '../../store/storeHooks';

export const useOurFetchCityEntities = (action: PayloadActionCreator<CityLocation>) => {
  const dispatch = useAppDispatch();

  const callback = ({ city, province, country }: CityLocation) =>
    dispatch(action({ city, province, country }));

  return useCallback(callback, []);
};
