import { useState } from 'react';
import { Box, MenuProps } from '@mui/material';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { SetState } from '@petconsole/pure-shared';
import { MemberAvatarMenuItems } from '../../../types';
import AppMemberMenu from './AppMemberMenu';
import AppMemberAvatar from './AppMemberAvatar';

interface OurAppMemberAvatarProps {
  avatarMenuItems: MemberAvatarMenuItems;
}

const OurAppMemberAvatar = ({ avatarMenuItems }: OurAppMemberAvatarProps) => {
  const { member, avatar } = useCurrentMember();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null as MenuProps['anchorEl']);

  if (!member || !avatar) return null;

  // NOTE: After upgrade to React 18, the menu stays open after clicking on a path item.
  // Had to wrap the menu with {menuOpen && <Menu... }
  return (
    <Box display="flex">
      <AppMemberAvatar
        setMenuOpen={setMenuOpen}
        setMenuAnchorEl={setMenuAnchorEl as SetState<MenuProps['anchorEl']>}
      />
      {menuOpen && (
        <AppMemberMenu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          menuAnchorEl={menuAnchorEl}
          avatarMenuItems={avatarMenuItems}
        />
      )}
    </Box>
  );
};

OurAppMemberAvatar.whyDidYouRender = true;

export default OurAppMemberAvatar;
