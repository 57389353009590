import { RecordOfString } from '@petconsole/pure-base';
import { friendApiEntity } from '@petconsole/pure-fe-api';
import { writeReducers } from '../../helpers';
import { appThunk } from '../../store/appThunk';
import { EntityAdapterWithPick } from '../../types';

const { api, name: sliceName, proper } = friendApiEntity;

export const addFriendRequestThunk = appThunk(
  `${sliceName}/add${proper}Request`,
  async ({ name, message }: RecordOfString) => api.request({ name, message }),
);

export const acceptFriendRequestThunk = appThunk(
  `${sliceName}/accept${proper}Request`,
  async (name: string) => api.accept(name),
);

export const cancelFriendRequestThunk = appThunk(
  `${sliceName}/cancel${proper}Request`,
  async (name: string) => api.cancel(name),
);

export const declineFriendRequestThunk = appThunk(
  `${sliceName}/decline${proper}Request`,
  async (name: string) => api.decline(name),
);

export const addFriendRequestReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(addFriendRequestThunk, sliceName, adapter);

export const acceptFriendRequestReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(acceptFriendRequestThunk, sliceName, adapter);

export const cancelFriendRequestReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(cancelFriendRequestThunk, sliceName, adapter);

export const declineFriendRequestReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(declineFriendRequestThunk, sliceName, adapter);
