import { useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { properCase } from '@petconsole/pure-base';
import { EditMessageType, editMessageType } from '@petconsole/pure-shared';

interface OurEditMessageProps {
  type: EditMessageType;
  message: string;
}

const OurEditMessage = ({ type, message }: OurEditMessageProps) => {
  const theme = useTheme();

  const typeColor = useCallback(
    (type: EditMessageType) => {
      const { error, hint, info, tip, warning } = editMessageType;
      const { palette } = theme;

      if (type === error) return palette.error.light;
      if (type === hint) return palette.primary.light;
      if (type === info) return palette.info.main;
      if (type === tip) return palette.success.main;
      if (type === warning) return palette.warning.main;
    },
    [theme],
  );

  const color = typeColor(type);

  return (
    <Grid container item columnGap={1}>
      <Typography color={color}>{properCase(type)}:</Typography>
      <Typography color={color}>{message}</Typography>
    </Grid>
  );
};

OurEditMessage.whyDidYouRender = true;

export default OurEditMessage;
