import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import { RecordType } from '@petconsole/pure-base';
import { ourDefaultMaxTextRows } from '../../constants';
import OurText, { OurTextProps } from './OurText';

export interface OurTextGridItemProps<T extends RecordType = RecordType> extends OurTextProps<T>, RegularBreakpoints {
  gridProps?: GridProps;
  box?: boolean;
}

const OurTextGridItem = <T extends RecordType = RecordType>(props: OurTextGridItemProps<T>) => {
  const { gridProps, box, xs = 12, sm, md, lg, xl, ...rest } = props;

  if (box)
    return (
      <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
        <OurText variant="outlined" multiline minRows={1} maxRows={ourDefaultMaxTextRows} {...rest} />
      </Grid>
    );

  return (
    <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
      <OurText {...rest} />
    </Grid>
  );
};

OurTextGridItem.whyDidYouRender = true;

export default OurTextGridItem;
