// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity } from '../../types';

export interface Stripe extends RecordType {
  type: DataEntityType;
  stripeId: string;
}

export const stripeEntity = entityAttributes({
  name: entityName.stripe,
  attributes: {
    eventType: { stripeEvent: 'stripeEvent' },
  },
});

const { strim } = schema;
export const stripeSchema = entitySchema<Stripe>(stripeEntity, {
  stripeId: strim(),
});

export const stripeDefaults: Partial<Stripe> = {};

export interface StripeRecord extends Stripe, RecordType {}

export type MaybeStripeRecord = MaybeNull<StripeRecord>;
export type StripeRecords = StripeRecord[];

export const initialStripe: InitialEntity<Stripe, StripeRecord> = (overrides = {}) =>
  initialEntity<Stripe>(stripeEntity, { ...stripeDefaults, ...overrides }) as StripeRecord;
