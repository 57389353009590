import { RecordType } from '@petconsole/pure-base';
import { Api } from '@petconsole/pure-shared';
import { ActionCreatorFromRecordType, ThunkApi } from '../../types';

const payloadCreatorUpdateEntity =
  <T extends RecordType = RecordType>(sliceName: string, idName: string, api: Api<T>, fetch?: ActionCreatorFromRecordType) =>
  async ({ [sliceName]: entity }: RecordType, { dispatch }: ThunkApi) => {
    const updated = (await api.update((entity as T)[idName] as string, entity as T));

    if (fetch) dispatch(fetch({ [idName]: updated[idName] }));

    return updated;
  };

export default payloadCreatorUpdateEntity;
