import { ReturnsUnknown, ReturnsVoid, SetBoolean, UnknownReturnsVoid } from '@petconsole/pure-shared';

interface DoWorkProps {
  tryCallback: ReturnsUnknown;
  catchCallback: UnknownReturnsVoid;
  finallyCallback?: ReturnsVoid;
  setBusy?: SetBoolean;
}

const doWork = async ({ tryCallback, catchCallback, finallyCallback, setBusy }: DoWorkProps) => {
  if (setBusy) setBusy(true);

  try {
    return await tryCallback();
  } catch (e) {
    catchCallback(e);
  } finally {
    if (finallyCallback) finallyCallback();
    if (setBusy) setBusy(false);
  }

  return undefined;
};

export default doWork;
