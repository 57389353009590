import { OurChevronDoubleDownIcon } from '@petconsole/pure-fe-icon';
import { Icon } from '@petconsole/pure-shared';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurExpandAllButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  tip?: string;
  Icon?: Icon;
}

const OurExpandAllButton = ({
  tip = 'Expand All',
  Icon = OurChevronDoubleDownIcon,
  ...rest
}: OurExpandAllButtonProps) => <OurIconButton tip={tip} Icon={Icon} {...rest} />;

OurExpandAllButton.whyDidYouRender = true;

export default OurExpandAllButton;
