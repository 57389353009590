import { patch } from 'aws-amplify/api';
import { RecordType } from '@petconsole/pure-base';
import { restApiName } from '../constants';
import { PatchInput, PatchInputProps, RestApiOptionsBase, RestInputOptions } from '../types';
import restCatch from './restCatch';

export interface RestPatchInput<T extends RecordType = RecordType> extends Omit<PatchInput, 'apiName' | 'options'> {
  apiName?: string;
  options?: Omit<PatchInput['options'], 'body'> & { body?: T };
}

type PatchResult<T extends RecordType = RecordType> = T;

export const attemptPatch = ({ apiName, path, options }: PatchInputProps) =>
  patch({
    apiName,
    path,
    options: options as RestApiOptionsBase,
  });

const restPatch = async <T extends RecordType = RecordType>({
  apiName = restApiName,
  path,
  options,
}: RestPatchInput<T>) => {
  try {
    const result = attemptPatch({ apiName, path, options: options as RestInputOptions });
    const response = await result.response;
    const body = response.body;

    if (response.headers['content-type'] === 'application/json') return (body.json as () => Promise<PatchResult<T>>)();

    // return response.body.text();
  } catch (error) {
    restCatch('restPatch', error);
  }

  throw new Error(`restPatch received unexpected results from ${path}`);
};

export default restPatch;
