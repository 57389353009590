import { ReactNode, SyntheticEvent, useState } from 'react';
import { TabContext } from '@mui/lab';
import { AppBar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { strip } from '@petconsole/pure-base';
import OurTabsPanel from './OurTabsPanel';
import OurTabsTabs from './OurTabsTabs';

interface OurTabs {
  tabWidth?: number;
  tabs: (string | boolean | ReactNode)[][];
  tabValue?: string;
  setTabValue: (value: string) => unknown;
  scrollButtons?: boolean;
}

const OurTabs = ({ tabs, tabValue, setTabValue, tabWidth, scrollButtons }: OurTabs) => {
  const { spacing } = useTheme();

  const [localTabValue, setLocalTabValue] = useState(tabValue || (tabs[0][0] as string));

  const onChange = (_event: SyntheticEvent, newTabValue: string) => {
    setLocalTabValue(newTabValue);

    if (setTabValue) setTabValue(newTabValue);
  };

  return (
    <TabContext value={(tabValue || localTabValue).toString()}>
      <AppBar position="static" color="inherit" sx={{ mt: 1, mb: 1.5, borderRadius: spacing(1.5, 1.5, 0, 0) }}>
        <OurTabsTabs
          tabs={tabs}
          tabValue={tabValue}
          onChange={onChange}
          tabWidth={tabWidth}
          scrollButtons={scrollButtons}
        />
      </AppBar>
      {tabs.map(([name = '', content, label = '']) => (
        <OurTabsPanel
          key={strip(name as string)}
          name={name as string}
          outline={!!label}
          label={label as string | boolean}
        >
          {content as ReactNode}
        </OurTabsPanel>
      ))}
    </TabContext>
  );
};

OurTabs.whyDidYouRender = true;

export default OurTabs;
