import { EntityRecord } from '@petconsole/pure-shared';

interface UseEntityCardSubscribeProps<T extends EntityRecord = EntityRecord> {
  preview?: boolean;
  subscribable?: boolean;
  isOwned: boolean;
  memberOwned: boolean;
  entityRecord: T;
}

const useEntityCardSubscribe = <T extends EntityRecord = EntityRecord>({
  preview,
  subscribable,
  isOwned,
  memberOwned,
  entityRecord,
}: UseEntityCardSubscribeProps<T>) => {
  const subscribe = {
    show: subscribable && !isOwned && !memberOwned && !preview,
    entityRecord,
    disabled: preview,
  };

  return { subscribe };
};

export default useEntityCardSubscribe;
