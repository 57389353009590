import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OurSelect, { OurSelectProps } from './OurSelect';

interface OurGridDisplayDateProps extends OurSelectProps, RegularBreakpoints {
  gridProps?: GridProps;
}

const OurSelectGridItem = ({ gridProps, xs = 12, sm, md, lg, xl, ...rest }: OurGridDisplayDateProps) => {
  return (
    <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
      <OurSelect {...rest} />
    </Grid>
  );
};

OurSelectGridItem.whyDidYouRender = true;

export default OurSelectGridItem;
