import { MouseEvent } from 'react';
import { Accept } from 'react-dropzone';
import { Grid } from '@mui/material';
import { Images, Log, ReturnsBoolean } from '@petconsole/pure-shared';
import OurImages from '../OurImages';
import ImageDropzone from './ImageDropzone';

interface ImageZoneProps {
  images?: Images;
  canEdit: ReturnsBoolean;
  onDrop: (images: Images) => void;
  onRemove: (e: MouseEvent, s3Key: string) => void;
  accept?: Accept;
  maxFiles?: number;
  leadText?: string;
  log: Log;
}

const ImageZone = ({ images, canEdit, onDrop, onRemove, accept, maxFiles = 1, leadText, log }: ImageZoneProps) => (
  <Grid item xs={12}>
    {images && images.length > 0 && <OurImages images={images} canRemove={canEdit()} onRemove={onRemove} />}
    {canEdit() && (!images || images.length < maxFiles) && (
      <ImageDropzone
        leadText={leadText}
        accept={accept}
        maxFiles={maxFiles - (images?.length || 0)}
        onDrop={onDrop}
        log={log}
      />
    )}
  </Grid>
);

ImageZone.whyDidYouRender = true;

export default ImageZone;
