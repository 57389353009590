import { RecordType } from '@petconsole/pure-base';
import { memberApiEntity } from '@petconsole/pure-fe-api';
import { MemberRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../types';
import BaseEntitySlice from '../base/BaseEntitySlice';

type ApiEntityRecord = MemberRecord;

const apiEntity = memberApiEntity;
const { api, name: member, plural: members } = apiEntity;
const memberAdmin = `${member}Admin`;

const Tab = { memberAdmin, member, members } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  memberAdmin: { default: '' },
  member: { default: '' },
  members: { default: '' },
};

// TODO: Need to handle pending
//     payloadCreator: ({ includePending = false } = {}
//   Member.getListNewest(includePending)
//     payloadCreator: ({ includePending = false } = {}
//    Member.getListOldest(includePending)
const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasOldestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  idName: 'memberName',
  selectId: (member = { memberName: '' } as ApiEntityRecord) => member.memberName as string,
  sortProperty: 'memberName',
  tabs,
  slivers: [
    {
      prefix: 'avatar',
      payloadCreator: async ({ memberName }: { memberName: string }) => api.getAvatarByName(memberName),
    },
    {
      prefix: 'pending',
      payloadCreators: async () => api.getListPending(),
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, tabHooks } = sliceHookTypes;

export interface OurMemberHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  memberHooks: RequiredCrudSliverHooks;
  cityMemberHooks: RequiredSliverHooks;
  newestMemberHooks: RequiredSliverHooks;
  oldestMemberHooks: RequiredSliverHooks;
  avatarMemberHooks: RequiredSliverHooks;
  pendingMemberHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  tabHooks: TabHooks;
}

export const ourMemberHooks: OurMemberHooks = {
  entity: apiEntity,
  memberHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityMemberHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestMemberHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  oldestMemberHooks: sliverHooks(apiEntity, 'Oldest', hooks) as RequiredSliverHooks,
  avatarMemberHooks: sliverHooks(apiEntity, 'Avatar', hooks) as RequiredSliverHooks,
  pendingMemberHooks: sliverHooks(apiEntity, 'Pending', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
