import { entityType } from '@petconsole/pure-shared';
import { fetchReducers } from '../../../helpers';
import { EntityAdapterWithPick, ExtraReducers, } from '../../../types';
import fetchByTopicPostsToThunk from './fetchByTopicPostsToThunk';
import { fetchByTopicPostsFromThunk, fetchReplyPostsSinceThunk } from './fetchByTopicPostsThunks';

const byTopicName = `byTopic${entityType.Post}`;
const replyName = `reply${entityType.Post}`;

export const fetchByTopicPostsFromReducers: ExtraReducers = (adapter: EntityAdapterWithPick) =>
  fetchReducers(fetchByTopicPostsFromThunk, byTopicName, adapter);

export const fetchByTopicPostsToReducers: ExtraReducers = (adapter: EntityAdapterWithPick) =>
  fetchReducers(fetchByTopicPostsToThunk, byTopicName, adapter);

export const fetchReplyPostsSinceReducers: ExtraReducers = (adapter: EntityAdapterWithPick) =>
  fetchReducers(fetchReplyPostsSinceThunk, replyName, adapter);
