// noinspection ES6PreferShortImport

import { MaybeNull, RecordType, nowToString } from '@petconsole/pure-base';
import { ourCurrencyCode, ourDefaultCurrencyCode, priceIntervals } from '../../constants';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, DefaultColumns, InitialEntity, PclPriceRate } from '../../types';

export interface PclPrice extends DefaultColumns {
  type: DataEntityType;
  pclPriceId: string;
  pclProductId: string;
  interval: string;
  effectiveDate: string;
  prices: PclPriceRate[];
  active: boolean;
}

export const pclPriceEntity = entityAttributes({
  name: entityName.pclPrice,
  attributes: {
    category: entityCategory.item,
    columns: { active: true },
    nameField: undefined,
    test: { invalidData: () => ({ effectiveDate: {} }), validData: () => ({ effectiveDate: nowToString() }) },
  },
});

const { arrayOf, id, number, oneOf, strim } = schema;
export const pclPriceSchema = entitySchema<PclPrice>(pclPriceEntity, {
  pclProductId: id(true),
  interval: oneOf(priceIntervals, priceIntervals.month),
  effectiveDate: strim(),
  prices: arrayOf({
    currency: oneOf(ourCurrencyCode, ourDefaultCurrencyCode).required(),
    price: number(), // cents
  }),
});

export const pclPriceDefaults: Partial<PclPrice> = {
  pclProductId: '',
  interval: priceIntervals.none,
  effectiveDate: '',
  prices: [],
};

export interface PclPriceRecord extends PclPrice, RecordType {}

export type MaybePclPriceRecord = MaybeNull<PclPriceRecord>;
export type PclPriceRecords = PclPriceRecord[];

export const initialPclPrice: InitialEntity<PclPrice, PclPriceRecord> = (overrides = {}) =>
  initialEntity<PclPrice>(pclPriceEntity, { ...pclPriceDefaults, ...overrides }) as PclPriceRecord;
