import { RecordType } from '@petconsole/pure-base';
import { Slice } from '../../types';
import { fetchRatingThunk } from '../thunks';

const sliceFetchRatingThunk = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  if (!slice.flag.hasRating) return;

  const { name, proper, idName, api } = slice;

  return fetchRatingThunk(name, proper, idName, api.rating);
};

export default sliceFetchRatingThunk;
