import { EntityName } from '@petconsole/pure-shared';
import { useAppDispatch } from '../../store/storeHooks';
import ourUpdateRatingThunk from './ourUpdateRatingThunk';

interface UseOurUpdateRatingProps {
  entityName: EntityName;
}

const useOurUpdateRating = ({ entityName }: UseOurUpdateRatingProps) => {
  const dispatch = useAppDispatch();

  const updateRatingThunk = ourUpdateRatingThunk({ entityName });

  return ({ id, rating }: { id: string; rating: number }) => dispatch(updateRatingThunk({ entityName, id, rating }));
};

export default useOurUpdateRating;
