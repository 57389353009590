import { Controller, UseFormReturn } from 'react-hook-form';
import { TextField } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import OurTooltip from '../../components/misc/OurTooltip';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import getInputProps, { GetInputProps } from './helpers/getInputProps';

export interface TextInputProps<T extends RecordType = RecordType> extends GetInputProps<T> {
  form: UseFormReturn<T>;
  tip?: string;
}

const TextInput = <T extends RecordType = RecordType>({ form, tip, ...rest }: TextInputProps<T>) => {
  const darkMode = useOurDarkMode();

  const rules = {
    ...(rest.required && { required: true }),
    // min, max, minLength, maxLength, pattern, validate
  };

  return (
    <OurTooltip tip={tip}>
      <Controller
        control={form.control}
        name={rest.id}
        rules={rules}
        render={({
          field: { onChange, onBlur, value, ref /* , disabled */ },
          fieldState: { isTouched, error: fieldError /* invalid, isDirty, */ },
          // formState: {
          //   isDirty,
          //   dirtyFields,
          //   touchedFields,
          //   defaultValues,
          //   isSubmitted,
          //   isSubmitSuccessful,
          //   isSubmitting,
          //   isLoading,
          //   submitCount,
          //   isValid,
          //   isValidating,
          //   errors,
          // },
        }) => {
          // console.log(rest.id, value);
          // const inputs = getInputProps({
          //   ...rest,
          //   darkMode,
          //   onChange,
          //   onBlur,
          //   value: rest.value ?? value,
          //   ref,
          //   isTouched,
          //   fieldError,
          // });

          return (
            <TextField
              {...getInputProps({
                ...rest,
                darkMode,
                onChange,
                onBlur,
                value: rest.value ?? value,
                ref,
                isTouched,
                fieldError,
              })}
            />
          );
        }}
      />
    </OurTooltip>
  );
};

export default TextInput;
