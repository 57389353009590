import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';

const useOurPhone = () => {
  const { isPortrait } = useMobileOrientation();

  const phone = isMobile && !isTablet;
  const portraitPhone = phone && isPortrait;

  return { phone, portraitPhone };
};

export default useOurPhone;
