// noinspection ES6PreferShortImport

import { PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Divider, Drawer, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import OurThemeSwitch from '../themes/OurThemeSwitch';
import { OurAppContext } from '../../../context/OurAppContext';

interface OurAppMenuDrawerProps extends PropsWithChildren {
  drawerWidth: number;
}

const OurAppMenuDrawer = ({ drawerWidth, children }: OurAppMenuDrawerProps) => {
  const { memberId } = useCurrentMember();
  const { menuOpen, setMenuOpen } = useContext(OurAppContext);
  const { breakpoints, spacing, transitions } = useTheme();
  const { duration, easing } = transitions;
  const { enteringScreen, leavingScreen } = duration;
  const { sharp } = easing;
  const ref = useRef(null as HTMLInputElement | null);

  useEffect(() => {
    const onBodyClick = (event: Event) => {
      if (ref.current && (ref.current as HTMLElement).contains(event.target as Node)) {
        return;
      }
      setMenuOpen(false);
    };
    document.body.addEventListener('click', onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener('click', onBodyClick, {
        capture: true,
      });
    };
  }, [setMenuOpen]);

  const sx = {
    drawer: { width: drawerWidth, flexShrink: 0, whiteSpace: 'nowrap' },
    drawerOpen: {
      width: drawerWidth,
      transition: transitions.create('width', { easing: sharp, duration: enteringScreen }),
    },
    drawerClose: {
      width: spacing(7) + 1,
      transition: transitions.create('width', { easing: sharp, duration: leavingScreen }),
      overflowX: 'hidden',
      [breakpoints.up('sm')]: { width: spacing(9) + 1 },
    },
  };

  return (
    <Drawer
      ref={ref}
      variant="persistent"
      anchor="left"
      open={menuOpen}
      sx={{ ...sx.drawer, ...(menuOpen ? sx.drawerOpen : sx.drawerClose) }}
      PaperProps={{
        sx: menuOpen ? sx.drawerOpen : sx.drawerClose,
        // Had to add style when upgrading to React 18. For some reason, the transform added to closed drawers is not
        // getting removed when the drawer is open
        ...(menuOpen && { style: { transform: 'none' } }),
      }}
    >
      <Box display="flex">
        <Box display="flex" flex={1} justifyContent="center" alignItems="center" pl={1}>
          {/* Light */}
          <OurThemeSwitch memberId={memberId} />
          {/* Dark */}
        </Box>
        <div>
          <IconButton onClick={() => setMenuOpen(false)} size="large">
            <ChevronLeft />
          </IconButton>
        </div>
      </Box>
      <Divider />
      {children}
    </Drawer>
  );
};

OurAppMenuDrawer.whyDidYouRender = true;

export default OurAppMenuDrawer;
