import { Typography, TypographyProps } from '@mui/material';

export interface OurTypoTextProps {
  text: string;
  typeProps?: TypographyProps;
  strong?: boolean;
  bold?: boolean;
}

const OurTypoText = ({ text, typeProps, strong, bold }: OurTypoTextProps) => {
  return <Typography {...typeProps}>{strong || bold ? <strong>{text}</strong> : text}</Typography>;
};

OurTypoText.whyDidYouRender = true;

export default OurTypoText;
