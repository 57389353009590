import { useOurRoutes } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import { PclTierCancel, PclTierPrices, PclTierSubscribe, PclTierSuccess } from '../../pcl';

const usePclRoutes = () => {
  const { authRoute, routeTo } = useOurRoutes();

  return [
    routeTo(webPath.pclTierPrices, PclTierPrices),
    authRoute(`${webPath.pclTierSubscribe}/cancel`, PclTierCancel),
    authRoute(`${webPath.pclTierSubscribe}/success`, PclTierSuccess),
    authRoute(`${webPath.pclTierSubscribe}/:id`, PclTierSubscribe),
  ];
};

export default usePclRoutes;
