import { createContext, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { RecordType } from '@petconsole/pure-base';
import { ApiUseGetQuery, EntityAttributes, EntitySchema, OurEntityHooks, SetState } from '@petconsole/pure-shared';

interface EntityAppContextProps<T extends RecordType = RecordType> {
  entity: EntityAttributes;
  setEntity: SetState<EntityAttributes>;
  hooks: OurEntityHooks<T>;
  setHooks: SetState<OurEntityHooks<T>>;
  queryHook: ApiUseGetQuery;
  setQueryHook: SetState<ApiUseGetQuery>;
  schema: EntitySchema;
  setSchema: SetState<EntitySchema>;
  values: T;
  setValues: SetState<T>;
  help: string;
  setHelp: SetState<string>;
  entityId: string;
  setEntityId: SetState<string>;
}

const EntityAppContext = createContext(null as unknown as EntityAppContextProps);

const EntityAppContextProvider = <T extends RecordType = RecordType>({ children }: PropsWithChildren) => {
  const [entity, setEntity] = useState<EntityAttributes>({} as EntityAttributes);
  const [hooks, setHooks] = useState<OurEntityHooks<T>>({} as OurEntityHooks<T>);
  const [schema, setSchema] = useState<EntitySchema>({} as EntitySchema);
  const [values, setValues] = useState<T>({} as T);
  const [help, setHelp] = useState('');
  const [entityId, setEntityId] = useState('');

  // Note: When using useState for queryHook, we were getting the error:
  //   Should have a queue. This is likely a bug in React. Please file an issue.
  const queryHook = useRef<ApiUseGetQuery>(undefined as unknown as ApiUseGetQuery);

  // Note: Without disabling eslint for the following, we were getting the error:
  //   React Hook useCallback received a function whose dependencies are unknown.
  //   Pass an inline function instead  react-hooks/exhaustive-deps

  // eslint-disable-next-line
  const setQueryHook = useCallback(
    ((hook: ApiUseGetQuery) => {
      queryHook.current = hook;
    }) as SetState<ApiUseGetQuery>,
    [],
  );

  const value = useMemo(
    () => ({
      entity,
      setEntity,
      hooks,
      setHooks,
      queryHook: queryHook.current,
      setQueryHook,
      schema,
      setSchema,
      values,
      setValues,
      help,
      setHelp,
      entityId,
      setEntityId,
    } as EntityAppContextProps),
    [
      entity,
      setEntity,
      hooks,
      setHooks,
      queryHook,
      setQueryHook,
      schema,
      setSchema,
      values,
      setValues,
      help,
      setHelp,
      entityId,
      setEntityId,
    ],
  );

  return <EntityAppContext.Provider value={value}>{children}</EntityAppContext.Provider>;
};

export { EntityAppContext, EntityAppContextProvider };
