import { ReactNode } from 'react';
import { IconButton, Link } from '@mui/material';

interface OurExternalIconProps {
  icon: ReactNode;
  href: string;
  aria: string;
}

const OurExternalIcon = ({ icon, href, aria }: OurExternalIconProps) => {
  const props = href && href !== '.' ? { href, target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <Link {...props}>
      <IconButton color="inherit" size="large" aria-label={aria}>
        {icon}
      </IconButton>
    </Link>
  );
};

OurExternalIcon.whyDidYouRender = true;

export default OurExternalIcon;
