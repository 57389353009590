import { RecordType } from '@petconsole/pure-base';
import { RatingApi } from '@petconsole/pure-fe-api';
import { EntityType, MaybeRatingRecord } from '@petconsole/pure-shared';
import { FetchRatingResult } from '../../types';

const payloadCreatorFetchRating =
  (idName: string, entityType: EntityType, api: RatingApi) =>
  async ({ [idName]: id }: RecordType): Promise<FetchRatingResult> =>
    api.getByRated(id as string).then((rating: MaybeRatingRecord) => {
      if (rating) return { [idName]: id, rating } as FetchRatingResult;

      return { [idName]: id, rating: { ratedId: id, ratedType: entityType, rating: 0 } } as FetchRatingResult;
    });

export default payloadCreatorFetchRating;
