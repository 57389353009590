import { useEffect } from 'react';

interface OurExternalPathProps {
  path: string;
}

const OurExternalPath = ({ path }: OurExternalPathProps) => {
  useEffect(() => window.location.replace(path), [path]);

  return null;
}

OurExternalPath.whyDidYouRender = true;

export default OurExternalPath;