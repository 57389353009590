import { useOurRoutes } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import { ForgotPassword, Join, Login, Logout, VerifyCode } from '../../auth';

const useLoginLogoutRoutes = () => {
  const { unauthRoute, routeTo } = useOurRoutes();

  return [
    routeTo(webPath.logout, Logout),
    unauthRoute(webPath.login, Login),
    unauthRoute(webPath.forgotPassword, ForgotPassword),
    unauthRoute(webPath.join, Join),
    unauthRoute(webPath.verifyCode, VerifyCode),
  ];
};

export default useLoginLogoutRoutes;
