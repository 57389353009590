import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import { EntityRecord, Image } from '@petconsole/pure-shared';

interface GetEntityCardBannerSrcProps<T extends EntityRecord = EntityRecord> {
  displaySamples: boolean;
  preview?: boolean;
  previewBanner?: Image;
  sample: T;
  entityRecord: T;
}

const getEntityCardBannerSrc = <T extends EntityRecord = EntityRecord>({
  displaySamples,
  preview,
  previewBanner,
  sample,
  entityRecord,
}: GetEntityCardBannerSrcProps<T>) => {
  if (!preview) return ourMemberImageUrl(entityRecord?.banner?.s3Key);

  if (displaySamples) return sample?.bannerImage as string || '';

  return previewBanner?.src || ourMemberImageUrl(previewBanner?.s3Key) || '';
};

export default getEntityCardBannerSrc;
