// NOTE: This slice only holds variantCodes for one shop item at a time!
import { RecordType } from '@petconsole/pure-base';
import { shopItemVariantCodeApiEntity } from '@petconsole/pure-fe-api';
import {
  ourShopItemVariantOption,
  ShopItemVariantCodeRecord,
  ShopItemVariantCodeRecords,
} from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchValue } from '../../../helpers';
import {
  BaseSliceOptions,
  FetchesByIdHook,
  OurEntityHooks,
  RequiredSliverByHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { saveShopItemVariantCodesReducers, saveShopItemVariantCodesThunk } from './saveShopItemVariantCodes';
import useSelectShopItemVariantCodesByShopItemAndVariantOption from './useSelectShopItemVariantCodesByShopItemAndVariantOption';

type ApiEntityRecord = ShopItemVariantCodeRecord;

const apiEntity = shopItemVariantCodeApiEntity;
const { name: sliceName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  selectId: ({ shopItemId, variantOption, order }: ApiEntityRecord) => [shopItemId, variantOption, order].join('#'),
  comparer: (
    { shopItemId: shopItemIdA, variantOption: variantOptionA, order: orderA }: ApiEntityRecord,
    { shopItemId: shopItemIdB, variantOption: variantOptionB, order: orderB }: ApiEntityRecord,
  ) => {
    let result = shopItemIdA.toLocaleUpperCase().localeCompare(shopItemIdB.toLocaleUpperCase());

    if (result === 0) result = variantOptionA.toLocaleUpperCase().localeCompare(variantOptionB.toLocaleUpperCase());
    if (result === 0) result = orderA - orderB;

    return result;
  },
  payloadCreator: null,
  fetchManyByProperty: { propertyName: 'shopItemId', apiMethod: 'getListByShopItem' },
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      payloadCreator: null,
      payloadCreators: null,
      extraReducers: [saveShopItemVariantCodesReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { sliverHooks } = sliceHookTypes;

export interface OurShopItemVariantCodeHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  shopItemVariantCodeHooks: RequiredSliverByHooks;
  customHooks: {
    variantCodes: {
      useSave: () => ReturnType<typeof useOurDispatchValue>;
    };
    byShopItem: {
      useFetches: FetchesByIdHook<ShopItemVariantCodeRecord>;
      useSelectsOption1: (shopItemId: string) => ShopItemVariantCodeRecords;
      useSelectsOption2: (shopItemId: string) => ShopItemVariantCodeRecords;
    };
  };
}

export const ourShopItemVariantCodeHooks: OurShopItemVariantCodeHooks = {
  entity: apiEntity,
  shopItemVariantCodeHooks: sliverHooks(apiEntity, '', hooks) as RequiredSliverByHooks,
  customHooks: {
    variantCodes: {
      useSave: () => useOurDispatchValue(saveShopItemVariantCodesThunk),
    },
    byShopItem: {
      useFetches:
        hooks.useOurFetchShopItemVariantCodesByShopItemId as OurShopItemVariantCodeHooks['customHooks']['byShopItem']['useFetches'],
      useSelectsOption1: (shopItemId) =>
        useSelectShopItemVariantCodesByShopItemAndVariantOption({
          shopItemId,
          variantOption: ourShopItemVariantOption.Option1,
        }),
      useSelectsOption2: (shopItemId) =>
        useSelectShopItemVariantCodesByShopItemAndVariantOption({
          shopItemId,
          variantOption: ourShopItemVariantOption.Option2,
        }),
    },
  },
};

export default reducer;
