import { memo } from 'react';
import { RecordType } from '@petconsole/pure-base';
import { ourLocationToCity } from '@petconsole/pure-fe-utils';
import { dataEntityType, EntityRecord, PetRecord } from '@petconsole/pure-shared';
import EntityCardTitle from '../EntityCardTitle';
import EntityCardPetTitle from '../EntityCardPetTitle';

interface UseEntityCardHeaderProps<T extends EntityRecord = EntityRecord> {
  preview: boolean;
  entityTitle: string;
  entityIdPath: string;
  entityIds?: string[];
  entityRecord: T;
  byCity?: boolean;
}

const useEntityCardHeader = <T extends EntityRecord = EntityRecord>({
  preview,
  entityTitle,
  entityIdPath,
  entityIds,
  entityRecord,
  byCity,
}: UseEntityCardHeaderProps<T>) => {
  const { address, ownerName, memberName, type } = entityRecord;
  const description = address?.description;
  const streetAddress = description ? description.split(',')[0] : '';
  const locationText = byCity || !streetAddress ? ourLocationToCity(entityRecord) : streetAddress;
  const subheader = type === dataEntityType.Pet ? `Parent: ${memberName} (${locationText})` : locationText;

  const Title = memo(() => {
    if (type === dataEntityType.Pet) return <EntityCardPetTitle pet={entityRecord as RecordType as PetRecord} />;

    return (
      <EntityCardTitle
        preview={preview || type === dataEntityType.Member}
        entityTitle={entityTitle}
        entityIdPath={entityIdPath}
        entityIds={entityIds}
      />
    );
  });

  const header = {
    entityTitle,
    entityIdPath,
    entityIds: entityIds,
    Title,
    subheader,
    owner: ownerName || memberName || '',
  };

  return { header };
};

export default useEntityCardHeader;
