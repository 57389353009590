import { useState } from 'react';
import { useOurSafeEffect } from '@petconsole/pure-fe-utils';

interface UseOurAuthenticatingProps {
  authCheck: ({ event }?: { event?: string }) => Promise<void>;
}

const useOurAuthenticating = ({ authCheck }: UseOurAuthenticatingProps) => {
  const [authenticating, setAuthenticating] = useState(true);

  useOurSafeEffect({
    action: () => authCheck({ event: 'authenticating' }),
    skip: !authenticating,
    final: () => setAuthenticating(false),
  });

  return { authenticating };
};

export default useOurAuthenticating;
