import { RecordType } from '@petconsole/pure-base';
import { Slice, StateType } from '../../types';
import setRatingReducer from '../ratings/setRatingReducer';
import setReactionReducer from '../reactions/setReactionReducer';
import { citySearchReducers, resetReducers, setPropertyReducer, tabValueReducers } from '../reducers';
import baseSlicePropertyReducers from '../reducers/baseSlicePropertyReducers';
import setEntityReducer from '../reducers/setEntityReducer';

const sliceReducers = <T extends RecordType = RecordType>(slice: Slice<T>, initialState: StateType, tabValuePrefixes: string[]) => {
  const { name: sliceName, proper, flag, option } = slice;
  const { hasRating, hasReaction, hasCitySearch, hasTabValues, hasDefaultProperties } = flag;
  const { tabs, sliceTabs, sliceProperties, reducers } = option;

  return {
    ...reducers,
    ...setEntityReducer(sliceName, slice.sliver),
    ...resetReducers(slice.sliver, initialState),
    ...(tabs && tabValueReducers(Object.keys(tabs))),
    ...(sliceTabs && tabValueReducers(sliceTabs.map(({ prefix }) => prefix))),
    ...(!tabs && !sliceTabs && hasTabValues && tabValueReducers(tabValuePrefixes)),
    ...(hasRating && setRatingReducer(sliceName)),
    ...(hasReaction && setReactionReducer(sliceName)),
    ...(hasCitySearch && citySearchReducers(sliceName)),
    ...(sliceProperties && baseSlicePropertyReducers(sliceName, sliceProperties)),
    ...(hasDefaultProperties && {
      [`set${proper}Editing`]: setPropertyReducer(`${sliceName}Editing`),
      [`set${proper}Submitting`]: setPropertyReducer(`${sliceName}Submitting`),
      [`set${proper}AddressInput`]: setPropertyReducer(`${sliceName}AddressInput`),
    }),
  };
};

export default sliceReducers;
