import { busApiEntity } from '@petconsole/pure-fe-api';
import { BusRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  ReactionHooks,
  RecordType,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = BusRecord;

const apiEntity = busApiEntity;
const { name: bus, plural: buses, pluralProper } = apiEntity;
const myBuses = `my${pluralProper}`;
const busAdmin = `${bus}Admin`;

const Tab = { busAdmin, bus, buses, myBuses } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  busAdmin: { default: '' },
  bus: { default: '' },
  buses: { default: 'Newest' },
  myBuses: { default: 'My Buses' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasMySliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasReaction: true,
  sortProperty: 'busName',
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, citySearchHooks, propertyHooks, reactionHooks, tabHooks } = sliceHookTypes;

export interface OurBusHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  busHooks: RequiredCrudSliverHooks;
  cityBusHooks: RequiredSliverHooks;
  newestBusHooks: RequiredSliverHooks;
  myBusHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  reactionHooks: ReactionHooks;
  tabHooks: TabHooks;
}

export const ourBusHooks: OurBusHooks = {
  entity: apiEntity,
  busHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityBusHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestBusHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  myBusHooks: sliverHooks(apiEntity, 'My', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  reactionHooks: reactionHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
