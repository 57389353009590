import { ElementType, useMemo } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { SetState } from '@petconsole/pure-shared';
import { ourDefaultMenuItemIconWidth } from '../../../constants';
import { AppMenuItems } from '../../../types';
import OurAppMenuItem from './OurAppMenuItem';

interface OurAppMenuCollapsableProps {
  open: boolean;
  setOpen: SetState<boolean>;
  Icon: ElementType;
  label: string;
  items?: AppMenuItems;
  divider?: boolean;
}

const OurAppMenuCollapsable = ({
  open,
  setOpen,
  Icon,
  label,
  items = [],
  divider = true,
}: OurAppMenuCollapsableProps) => {
  const menuItemSx = useMemo(() => ({ pl: 4 }), []);
  const appMenuItems = useMemo(
    () =>
      items.map((item) => (
        <OurAppMenuItem
          item={item}
          sx={menuItemSx}
          key={`${item[1]}-${item[2]}`} // key = text-path
        />
      )),
    [items, menuItemSx],
  );

  return (
    <>
      {divider && <Divider />}
      <List disablePadding>
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemIcon sx={{ minWidth: ourDefaultMenuItemIconWidth }}>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={label} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {appMenuItems}
        </Collapse>
      </List>
    </>
  );
};

OurAppMenuCollapsable.whyDidYouRender = true;

export default OurAppMenuCollapsable;
