import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

const OurBackButton = (props: Omit<OurIconButtonProps, 'onClick' | 'tip' | 'Icon'>) => {
  const navigate = useNavigate();

  const onClick = useCallback((() => navigate(-1)), [navigate]);

  // Note: window.history.length isn't all that reliable as it doesn't tell you where in the list
  // you are. For example, after a refresh, I got a value of 35. From where??
  return (
    <OurIconButton
      {...props}
      tip="Back"
      disabled={window?.history?.length <= 1}
      Icon={ArrowBack}
      onClick={onClick}
    />
  );
};

OurBackButton.whyDidYouRender = true;

export default OurBackButton;
