import { useCallback } from 'react';
import { useAppDispatch } from '../../store/storeHooks';
import { AnyAsyncThunk } from '../../types';

const useOurDispatchAction = (action: AnyAsyncThunk) => {
  const dispatch = useAppDispatch();

  return useCallback(() => dispatch(action(undefined)), []);
};

export default useOurDispatchAction;
