import { MouseEventHandler, ReactNode } from 'react';
import { CardActions } from '@mui/material';
import { useCurrentMember } from '@petconsole/pure-fe-state';
import { SetState } from '@petconsole/pure-shared';
import OurCommentsButton from '../buttons/OurCommentsButton';
import OurLikeButton from '../buttons/OurLikeButton';
import OurOptionsButton from '../buttons/OurOptionsButton';
import OurRepliesButton from '../buttons/OurRepliesButton';
import OurSendButton from '../buttons/OurSendButton';
import OurShareButton from '../buttons/OurShareButton';

interface OurPostCardActionsProps {
  liked?: boolean;
  toggleLike?: MouseEventHandler;
  commented?: boolean;
  commentsOpen?: boolean;
  setCommentsOpen?: SetState<boolean>;
  repliesOpen?: boolean;
  setRepliesOpen?: SetState<boolean>;
  optionsTip?: string;
  optionsOpen?: boolean;
  setOptionsOpen?: SetState<boolean>;
  sent?: boolean;
  onSend?: MouseEventHandler;
  shared?: boolean;
  onShare?: MouseEventHandler;
}

const OurPostCardActions = ({
  liked,
  toggleLike,
  commented,
  commentsOpen,
  setCommentsOpen,
  repliesOpen,
  setRepliesOpen,
  optionsTip = 'Options',
  optionsOpen = false,
  setOptionsOpen,
  sent,
  onSend,
  shared,
  onShare,
}: OurPostCardActionsProps) => {
  const { memberName } = useCurrentMember();

  if (!memberName) return null;

  const optionalButton = (display: boolean, content: ReactNode) => (display ? content : <span />);
  return (
    <CardActions
      disableSpacing
      sx={{ flex: 1, p: 0.125, '&:last-child': { pb: 0 }, justifyContent: 'space-between' }}
    >
      {optionalButton(!!toggleLike, <OurLikeButton liked={!!liked} onClick={toggleLike} />)}
      {optionalButton(
        Boolean(setCommentsOpen || setRepliesOpen),
        setCommentsOpen ? (
          <OurCommentsButton
            isOpen={!!commentsOpen}
            setIsOpen={setCommentsOpen}
            commented={commented}
          />
        ) : (
          <OurRepliesButton isOpen={!!repliesOpen} setIsOpen={setRepliesOpen as SetState<boolean>} />
        ),
      )}
      {optionalButton(
        !!setOptionsOpen,
        <OurOptionsButton tip={optionsTip} isOpen={optionsOpen} setIsOpen={setOptionsOpen as SetState<boolean>} />,
      )}
      {optionalButton(!!onSend, <OurSendButton sent={sent} onClick={onSend} />)}
      {optionalButton(!!onShare, <OurShareButton shared={shared} onClick={onShare} />)}
    </CardActions>
  );
};

OurPostCardActions.whyDidYouRender = true;

export default OurPostCardActions;
