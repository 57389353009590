import OurNumber, { OurNumberProps } from './OurNumber';

export type OurDecimalProps = OurNumberProps;

const OurDecimal = ({ decimals = 2, fixed = true, allowLeadingZeros = true, ...rest }: OurDecimalProps) => {
  return <OurNumber decimals={decimals} fixed={fixed} allowLeadingZeros={allowLeadingZeros} {...rest} />;
};

OurDecimal.whyDidYouRender = true;

export default OurDecimal;
