import { RecordOfRecordType, RecordType } from '@petconsole/pure-base';
import { EntityName, EntityRecord, ourEntityNameToType } from '@petconsole/pure-shared';
import { ActionProps, RecordOfSliceSlivers } from '../../types';

const setEntityReducer = <T extends RecordType = RecordType>(sliverName: string, slivers: RecordOfSliceSlivers<T>) => {
  const type = ourEntityNameToType(sliverName as EntityName);

  return {
    [`set${type || sliverName}Entity`]: (state: RecordOfRecordType, { payload }: ActionProps) => {
      const { id, data } = payload as { id: string; data: EntityRecord };

      Object.keys(slivers).forEach((key) => {
        if ((state[key].ids as string[]).includes(id)) (state[key].entities as RecordType)[id] = data;
      });
    },
  };
};

export default setEntityReducer;
