import { memberApi } from '../api';
import entity from './entity';
import {
  blogDefEntity,
  BlogDefRecord,
  busBuilderEntity,
  BusBuilderRecord,
  businessEntity,
  BusinessRecord,
  busRepairEntity,
  BusRepairRecord,
  busSupplyEntity,
  BusSupplyRecord,
  dogParkEntity,
  DogParkRecord,
  feedDefEntity,
  FeedDefRecord,
  feedEntity,
  FeedRecord,
  foundPetEntity,
  FoundPetRecord,
  groomerEntity,
  GroomerRecord,
  lostPetEntity,
  LostPetRecord,
  mailFolderEntity,
  MailFolderRecord,
  MemberRecord,
  petFoodEntity,
  PetFoodRecord,
  petTrainerEntity,
  PetTrainerRecord,
  petTypeEntity,
  PetTypeRecord,
  petWalkerEntity,
  PetWalkerRecord,
  petWorkplaceEntity,
  PetWorkplaceRecord,
  placeEntity,
  PlaceRecord,
  sitterEntity,
  SitterRecord,
  userEntity,
  vanBuilderEntity,
  VanBuilderRecord,
  vanRepairEntity,
  VanRepairRecord,
  vanSupplyEntity,
  VanSupplyRecord,
  vetEntity,
  VetRecord,
} from '@petconsole/pure-shared';

export const blogDefApiEntity = entity<BlogDefRecord>(blogDefEntity);
export const busBuilderApiEntity = entity<BusBuilderRecord>(busBuilderEntity);
export const businessApiEntity = entity<BusinessRecord>(businessEntity);
export const busRepairApiEntity = entity<BusRepairRecord>(busRepairEntity);
export const busSupplyApiEntity = entity<BusSupplyRecord>(busSupplyEntity);
export const dogParkApiEntity = entity<DogParkRecord>(dogParkEntity);
export const feedApiEntity = entity<FeedRecord>(feedEntity);
export const feedDefApiEntity = entity<FeedDefRecord>(feedDefEntity);
export const foundPetApiEntity = entity<FoundPetRecord>(foundPetEntity);
export const groomerApiEntity = entity<GroomerRecord>(groomerEntity);
export const lostPetApiEntity = entity<LostPetRecord>(lostPetEntity);
export const mailFolderApiEntity = entity<MailFolderRecord>(mailFolderEntity);
export const petFoodApiEntity = entity<PetFoodRecord>(petFoodEntity);
export const petTrainerApiEntity = entity<PetTrainerRecord>(petTrainerEntity);
export const petTypeApiEntity = entity<PetTypeRecord>(petTypeEntity);
export const petWalkerApiEntity = entity<PetWalkerRecord>(petWalkerEntity);
export const petWorkplaceApiEntity = entity<PetWorkplaceRecord>(petWorkplaceEntity);
export const placeApiEntity = entity<PlaceRecord>(placeEntity);
export const sitterApiEntity = entity<SitterRecord>(sitterEntity);
export const userApiEntity = entity<MemberRecord>({ ...userEntity, api: memberApi });
export const vanBuilderApiEntity = entity<VanBuilderRecord>(vanBuilderEntity);
export const vanRepairApiEntity = entity<VanRepairRecord>(vanRepairEntity);
export const vanSupplyApiEntity = entity<VanSupplyRecord>(vanSupplyEntity);
export const vetApiEntity = entity<VetRecord>(vetEntity);
