import authSignInWithRedirect from './authSignInWithRedirect';

const authSocialLogins = {
  google: ({ customState }: { customState: string }) =>
    authSignInWithRedirect({ customState, provider: 'Google' }),
  facebook: ({ customState }: { customState: string }) =>
    authSignInWithRedirect({ customState, provider: 'Facebook' }),
  amazon: ({ customState }: { customState: string }) =>
    authSignInWithRedirect({ customState, provider: 'Amazon' }),
};

export default authSocialLogins;
