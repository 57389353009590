// noinspection ES6PreferShortImport

import { camelToWords, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../../entity/constants';
import entityAttributes from '../../../entity/entityAttributes';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity, MaybeImage } from '../../../types';

export interface ShopItemVariant extends CrudColumns {
  type: DataEntityType;
  shopItemVariantId: string;
  shopItemId: string;
  sku: string;
  gtin: string;
  externalId: string;
  shopItemVariantCodeId1: string;
  shopItemVariantCodeId2: string;
  code1: string;
  code2: string;
  weight: number;
  length: number;
  width: number;
  height: number;
  cost: number;
  price: number;
  order: number;
  description: string;
  thumbnail: MaybeImage;
  isUsed: boolean;
  isActive: boolean;
  isAvailable: boolean; // eventually obsolete - needs to be removed
  isHidden: boolean; // eventually obsolete - needs to be removed
}

export const shopItemVariantEntity = entityAttributes({
  name: entityName.shopItemVariant,
  attributes: {
    category: entityCategory.item,
    columns: { images: false, json: false },
    nameField: 'sku',
    test: { invalidData: () => ({ description: {} }), validData: () => ({ description: 'New Description' }) },
  },
});

const { boolean, caps, id, image, number, pos, strim, string } = schema;
export const shopItemVariantSchema = entitySchema<ShopItemVariant>(shopItemVariantEntity, {
  shopItemId: id(true).required('shopItemId is required for variant'),
  sku: caps().test(
      'variant-sku-yup-test',
      ({ path }) => {
        const field = camelToWords(path.split('[')[0]);
        const index = path.split('[')[1].split(']')[0];
        return `Item number/SKU is required for ${field} #${Number(index) + 1}`;
      },
      (value, { parent }) => Boolean(value || !parent.isAvailable || !parent.isActive),
    ),
  gtin: string(14),
  externalId: string(),
  shopItemVariantCodeId1: id(true),
  shopItemVariantCodeId2: id(),
  code1: caps().required(),
  code2: caps(),
  weight: pos(),
  length: pos(),
  width: pos(),
  height: pos(),
  cost: pos().required('Variant cost is required'),
  price: pos().required('Variant price is required'),
  order: number().min(1).required('Variant order is required'),
  description: strim(),
  thumbnail: image(),
  isUsed: boolean(),
  isActive: boolean(true),
  isAvailable: boolean(true), // planned to become obsolete - change to isActive
  isHidden: boolean(), // planned to become obsolete
});

export const shopItemVariantDefaults: Partial<ShopItemVariant> = {
  shopItemId: '',
  sku: '',
  gtin: '',
  externalId: '',
  shopItemVariantCodeId1: '',
  shopItemVariantCodeId2: '',
  code1: '',
  code2: '',
  weight: 0,
  length: 0,
  width: 0,
  height: 0,
  cost: 0,
  price: 0,
  order: 0,
  description: '',
  thumbnail: null,
  isUsed: false,
  isActive: true,
  isAvailable: true,
  isHidden: false,
};

export interface ShopItemVariantRecord extends ShopItemVariant, RecordType {
  expanded?: boolean; // intended to be exposed for UI only
}

export type MaybeShopItemVariantRecord = ShopItemVariantRecord | null;
export type ShopItemVariantRecords = ShopItemVariantRecord[];

export const initialShopItemVariant: InitialEntity<ShopItemVariant, ShopItemVariantRecord> = (overrides = {}) =>
  initialEntity<ShopItemVariant>(shopItemVariantEntity, { ...shopItemVariantDefaults, ...overrides }) as ShopItemVariantRecord;

