import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  GetListOptions,
  GetListResults,
  PclTierPriceRecord,
  pclTierPriceEntity,
  ApiEntity,
} from '@petconsole/pure-shared';
import { commonApi, fullPath, get, optionalParam, pathParam, post } from './common';

type ApiRecord = PclTierPriceRecord;

const apiPath = '/pclTierPrices';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getByPclTierAndEffective: (pclTierId: string, effectiveDate?: string) => Promise<unknown>;
  getListByPclTier: (pclTierId: string, options: GetListOptions) => Promise<GetListResults<T>>;
  createStripe: (pclTierPriceId: string) => Promise<unknown>;
}

export type PclTierPriceApi = Api<ApiRecord> & CustomApi;

export const pclTierPriceApi: PclTierPriceApi = {
  ...common,
  getListByValue,
  getByPclTierAndEffective: (pclTierId, effectiveDate) =>
    get(
      fullPath(`${apiPath}/getBy`, `${pathParam('tierId', pclTierId)}${optionalParam('effectiveDate', effectiveDate)}`),
    ),
  getListByPclTier: (pclTierId, options) => getListByValue('tierId', pclTierId, options),
  createStripe: (pclTierPriceId) => post(`${apiPath}/createStripe/${pclTierPriceId}`, {}),
};

export const pclTierPriceApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...pclTierPriceEntity, api: pclTierPriceApi };
