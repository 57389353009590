// noinspection ES6PreferShortImport

import { MaybeNull, newId, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, TimeColumns } from '../../types';

export interface PclAlert extends TimeColumns {
  type: DataEntityType;
  pclAlertId: string;
  message: string;
}

export const pclAlertEntity = entityAttributes({
  name: entityName.pclAlert,
  attributes: {
    category: entityCategory.item,
    columns: { crud: false, images: false, json: false, time: true },
    nameField: undefined,
    test: { invalidData: () => ({ message: {} }), validData: () => ({ message: newId() }) },
  },
});

const { strim } = schema;
export const pclAlertSchema = entitySchema<PclAlert>(pclAlertEntity, {
  message: strim(),
});

export const pclAlertDefaults: Partial<PclAlert> = {
  message: '',
};

export interface PclAlertRecord extends PclAlert, RecordType {}

export type MaybePclAlertRecord = MaybeNull<PclAlertRecord>;
export type PclAlertRecords = PclAlertRecord[];

export const initialPclAlert: InitialEntity<PclAlert, PclAlertRecord> = (overrides = {}) =>
  initialEntity<PclAlert>(pclAlertEntity, { ...pclAlertDefaults, ...overrides }) as PclAlertRecord;
