import { storagePut } from '@petconsole/pure-fe-amplify';
import { ourImageContentType } from '@petconsole/pure-shared';
import { OurPutImageProps } from '../types';

const ourPutImage = async ({
  s3Key,
  src,
  // acl = 'public-read',
  contentType,
  log,
}: OurPutImageProps) => {
  const image = Buffer.from(src.split(',')[1], 'base64');

  try {
    return await storagePut({
      key: s3Key,
      data: image,
      options: { /* acl, */ contentType: contentType || ourImageContentType({ src }) },
    });
  } catch (e) {
    await log(`ourPutImage error on ${s3Key}:`, e);

    return null;
  }
};

export default ourPutImage;
