import { RecordType } from '@petconsole/pure-base';
import {
  webPath,
  reactAppRootPath,
  Api,
  stripeEntity,
  StripeResultCreateStripeAccount,
  StripeResultCreateCheckoutSession,
  StripeResultGetCheckoutSession, ApiEntity,
} from '@petconsole/pure-shared';
import { commonApi, get, post } from './common';

type ApiRecord = RecordType;

const apiPath = '/stripe';

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  accountsCreate: (body: T) => Promise<StripeResultCreateStripeAccount>;
  accountLinksCreate: ({ shopId, stripeId }: { shopId: string; stripeId: string }) => Promise<T>;
  checkoutSessionsCreate: (shopId: string, returnPath: string) => Promise<StripeResultCreateCheckoutSession>;
  checkoutSessionsSubscribe: ({
    pclTierId,
    entityName,
    entityType,
    entityId,
    interval,
    currency,
    source,
    returnPath,
  }: {
    pclTierId: string;
    entityName: string;
    entityType: string;
    entityId: string;
    interval: string;
    currency: string;
    source: string;
    returnPath: string;
  }) => Promise<T>;
  getAccounts: () => Promise<T>;
  getCheckoutSession: (sessionId: string) => Promise<StripeResultGetCheckoutSession>;
}

export type StripeApi = Api<ApiRecord> & CustomApi;

export const stripeApi: StripeApi = {
  ...commonApi<ApiRecord>({ apiPath }),
  accountsCreate: (body) =>
    post(`${apiPath}/accounts/create`, body || {}) as unknown as Promise<StripeResultCreateStripeAccount>,
  accountLinksCreate: ({ shopId, stripeId }) =>
    post(`${apiPath}/accountLinks/create`, {
      returnPath: `${reactAppRootPath}${webPath.myShopStripe}/${shopId}`,
      stripeId,
    }),
  checkoutSessionsCreate: (shopId, returnPath) =>
    post(`${apiPath}/checkout/sessions/create`, {
      shopId,
      returnPath: `${reactAppRootPath}${returnPath}`,
    }) as unknown as Promise<StripeResultCreateCheckoutSession>,
  checkoutSessionsSubscribe: ({
    pclTierId,
    entityName,
    entityType,
    entityId,
    interval,
    currency,
    source,
    returnPath,
  }) =>
    post(`${apiPath}/checkout/sessions/subscribe`, {
      pclTierId,
      entityName: encodeURIComponent(entityName),
      entityType,
      entityId,
      interval,
      currency,
      source: `${reactAppRootPath}${source}`,
      returnPath: `${reactAppRootPath}${returnPath}`,
    }),
  getAccounts: () => get(`${apiPath}/getAccounts/stripe`) as Promise<RecordType>,
  getCheckoutSession: (sessionId) =>
    get(`${apiPath}/checkout/sessions/${sessionId}`).then((result) => ({
      ...result,
      entityName: decodeURIComponent((result as RecordType).entityName as string),
    })) as Promise<StripeResultGetCheckoutSession>,
};

export const stripeApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...stripeEntity, api: stripeApi };
