/// <reference types="@types/google.maps" />
// noinspection ES6PreferShortImport

import { FocusEventHandler, SyntheticEvent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Path, PathValue } from 'react-hook-form';
import parse from 'autosuggest-highlight/parse';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { CityLocation, MaybeNull, MaybeString, newId, RecordType } from '@petconsole/pure-base';
import { SetState } from '@petconsole/pure-shared';
import useOurDarkMode from '../../../hooks/useOurDarkMode';
import useOurGooglePlaces from '../../../hooks/useOurGooglePlaces';
import getInputProps, { GetInputProps } from '../helpers/getInputProps';
import TextInput, { TextInputProps } from '../TextInput';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type MaybeAutocompletePrediction = MaybeNull<AutocompletePrediction>;

export interface GoogleAddressProps<T extends RecordType = RecordType>
  extends Omit<TextInputProps<T>, 'id' | 'darkMode' | 'isTouched'> {
  id?: string;
  addressValue: string;
  setAddressValue: SetState<string>;
}

const GoogleAddress = <T extends RecordType = RecordType>({
  id = 'address',
  helperText,
  addressValue,
  setAddressValue,
  required = false,
  autoFocus = false,
  placeholder = 'Start typing a city name or address',
  ...restProps
}: GoogleAddressProps<T>) => {
  const idPath = id as Path<T>;
  const inputValue = addressValue;
  const setInputValue = setAddressValue;

  const darkMode = useOurDarkMode();
  const { form, allowLastPass } = restProps;

  const googlePlaces = useOurGooglePlaces();
  const { autocompleteService, placesService, getPlacePredictions, getPlaceDetails } = googlePlaces;

  const fieldProps = getInputProps<T>({ required: true, isTouched: false, label: "Address/City", helperText, darkMode, ...restProps, id: idPath } as GetInputProps<T>);

  const { name, label, variant, error, helperText: helpText, InputProps } = fieldProps;

  const [acValue, setAcValue] = useState<MaybeAutocompletePrediction>(null);
  const [acOptions, setAcOptions] = useState<AutocompletePrediction[]>([]);

  useEffect(() => {
    setInputValue(inputValue);
  }, [inputValue, setInputValue]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService || !placesService) return undefined;

    if (inputValue === '') {
      setAcOptions(acValue ? [acValue] : []);

      return undefined;
    }

    getPlacePredictions({ input: inputValue }, (results: AutocompletePrediction[]) => {
      if (active) setAcOptions([...(acValue ? [acValue] : []), ...(results || [])]);
    });

    return () => {
      active = false;
    };
  }, [acValue, inputValue, autocompleteService, placesService, getPlacePredictions]);

  const handleChange = (value: MaybeNull<CityLocation>) => {
    if (form)
      form.setValue(id as Path<T>, (value === null ? { description: '' } : value) as PathValue<T, Path<T>>, {
        shouldDirty: true,
      });
  };

  const acLocationChange = (newValue: MaybeAutocompletePrediction) => {
    setAcOptions(newValue ? [newValue] : []);
    setAcValue(newValue);

    return newValue ? getPlaceDetails(newValue) : Promise.resolve(null);
  };

  const renderOption = (props: object, option: AutocompletePrediction) => {
    const { structured_formatting } = option;

    const { main_text, secondary_text, main_text_matched_substrings: matches } = structured_formatting;
    const parts = parse(
      main_text,
      matches.map((match) => [match.offset, match.offset + match.length]),
    );

    const primary = parts.map(({ text, highlight }) => (
      <span key={newId()} style={{ fontWeight: highlight ? 700 : 400 }}>
        {text}
      </span>
    ));

    return (
      <ListItem key={newId()} {...props} /* alignItems="flex-start" */>
        <ListItemAvatar sx={{ minWidth: 48, mt: 0 }}>
          <Avatar sx={{ width: 24, height: 24 }}>
            <LocationOnIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary_text} />
      </ListItem>
    );
  };

  const onLocationInputChange = (event: SyntheticEvent, newValue: string) => {
    // This will ensure the initial value doesn't get wiped out
    // if (event) acSetInputValue(newValue);
    if (!event) return;

    event.preventDefault();

    if (event.type === 'blur') return;

    setInputValue(newValue);

    if (!newValue && form?.getValues('address' as Path<T>)) handleChange(null);
  };

  const onLocationChange = async (_event: SyntheticEvent<Element, Event>, newValue: AutocompletePrediction | null) => {
    try {
      const acResults = await acLocationChange(newValue);

      handleChange(acResults as MaybeNull<CityLocation>);
    } catch (e) {
      // Happened when the location was already set, and then I chose the same location again
      return console.log('Location change error:', e);
    }
  };

  // If the value is cleared, this will ensure we update the field on exiting it
  const onLocationBlur: FocusEventHandler<{ value: MaybeString }> = (event) => {
    if (!event?.target?.value && form) handleChange(null);
  };

  const getOptionLabel = (option: MaybeAutocompletePrediction) => option?.description || '';

  // Returning OurText is a workaround as MUI's AutoComplete doesn't support readOnly very well?
  // if (readOnly || isReadOnly) return <OurText<T> value={inputValue} {...rest} />;

  return (
    <Autocomplete
      name={name}
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      options={acOptions}
      // autoComplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      // @ts-expect-error - Type issue
      value={acValue}
      inputValue={inputValue}
      // @ts-expect-error - Type issue
      onChange={onLocationChange}
      onInputChange={onLocationInputChange}
      // @ts-expect-error - Type issue
      onBlur={onLocationBlur}
      {...restProps}
      id={id}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextInput<T>
          {...restProps}
          {...params}
          {...{ label, variant, error, helperText: helpText, required }}
          fullWidth
          autoFocus={autoFocus && !isMobile}
          // @ts-expect-error - Type issue
          inputProps={{
            ...params.inputProps,
            ...InputProps,
            ...(allowLastPass ? {} : { 'data-lpignore': true, autoComplete: 'off' }),
          }}
        />
      )}
    />
  );
};

GoogleAddress.whyDidYouRender = true;

export default GoogleAddress;
