import { camelToWords } from '@petconsole/pure-base';

interface DoWorkCatchProps {
  e: unknown;
  activity: string;
  warn: (message: string, logged?: boolean) => void;
  log: (...args: unknown[]) => Promise<unknown>;
}

const doWorkCatch = ({ e, activity, warn, log }: DoWorkCatchProps) => {
  const message = e instanceof Error ? e.message : '';

  warn(`${camelToWords(activity)} failed: ${message}.`);
  log(`Error ${activity}: ${message}`, e).then();
};

export default doWorkCatch;