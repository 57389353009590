import { Grid, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface SlideProps {
  index: number;
  width?: string | number;
  borderWidth: number;
  borderLeft?: boolean;
  borderRight?: boolean;
  radius: (props: Theme) => string;
  position?: string;
  urls: string[];
}

const Slide = ({
  index,
  width = '25%',
  borderWidth,
  borderLeft = true,
  borderRight = true,
  radius,
  position = 'center',
  urls,
}: SlideProps) => {
  const { palette } = useTheme();

  const filmBorder = `solid ${borderWidth}px black`;

  return (
    <Grid
      item
      width={width}
      height="100%"
      sx={{
        borderRadius: radius,
        backgroundPosition: position,
        backgroundSize: 'cover',
        backgroundImage: urls.length > index ? urls[index] : '',
      }}
      bgcolor={palette.background.paper}
      borderTop={filmBorder}
      borderLeft={borderLeft ? filmBorder : ''}
      borderRight={borderRight ? filmBorder : ''}
      borderBottom={filmBorder}
    />
  );
};

Slide.whyDidYouRender = true;

export default Slide;