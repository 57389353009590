import { DataEntityName } from '@petconsole/pure-shared';
import { AppGetState } from '../../store';
import ourStateSliverData from '../state/ourStateSliverData';

const fetchParams = (getState: AppGetState, slice: string | DataEntityName, sliver: string) => {
  const { limit, nextKey } = ourStateSliverData(getState(), slice, sliver);

  return { limit, nextKey };
};

export default fetchParams;
