import { ComponentType } from 'react';
import { Grid } from '@mui/material';
import { MaybeBreakpoints } from '../../types';

interface EntityMaintenanceMarginProps {
  margin?: MaybeBreakpoints;
  Content?: ComponentType;
}

const EntityMaintenanceMargin = ({ margin, Content }: EntityMaintenanceMarginProps) => {
  return (
    <Grid item id="margin-content" display="flex" width="100%" {...margin}>
      {Content && <Content />}
    </Grid>
  );
};

EntityMaintenanceMargin.whyDidYouRender = true;

export default EntityMaintenanceMargin;
