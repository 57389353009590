import React from 'react';
import { ButtonProps, Grid, SxProps } from '@mui/material';
import OurButton from './OurButton';

export interface GridButtonProps extends ButtonProps {
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

interface OurGridButtonsProps {
  left?: GridButtonProps;
  middle?: GridButtonProps;
  right?: GridButtonProps;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  revertMargin?: boolean;
  size?: ButtonProps['size'];
  sx?: SxProps;
}

function OurGridButtons({ left = {}, middle, right = {}, onCancel, revertMargin = true, size, sx }: OurGridButtonsProps) {
  const { label: leftLabel = 'Cancel', ...leftParams } = left || {};
  const { label: rightLabel = 'Save', ...rightParams } = right || {};
  const items = (left ? 1 : 0) + (middle ? 1 : 0) + (right ? 1 : 0);
  const itemWidth = 12 / items;
  const combinedSx = (revertMargin || sx) && {
    ...(revertMargin && { ml: 0, mt: 0 }),
    ...(sx || {}),
  };

  if (leftLabel === 'Cancel') leftParams.color = 'secondary';
  if (onCancel) leftParams.onClick = onCancel;

  if (rightLabel === 'Save') rightParams.type = 'submit';

  return (
    <Grid container spacing={2} {...(combinedSx && { sx: combinedSx })}>
      {left && (
        <Grid item xs={itemWidth}>
          <OurButton label={leftLabel} size={size} {...leftParams} />
        </Grid>
      )}
      {middle && (
        <Grid item xs={itemWidth}>
          <OurButton label={middle.label || 'Label'} size={size} {...middle} />
        </Grid>
      )}
      {right && (
        <Grid item xs={itemWidth}>
          <OurButton label={rightLabel} size={size} {...rightParams} />
        </Grid>
      )}
    </Grid>
  );
}

OurGridButtons.whyDidYouRender = true;

export default OurGridButtons;
