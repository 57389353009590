import { RecordType } from '@petconsole/pure-base';
import { apiMethodType, EntityType, UpdateMethod } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../types';

const updateOne = <T extends RecordType = RecordType>(builder: ApiBuilder<T>, method: UpdateMethod<T>, tag: EntityType) =>
  builder.mutation<T, { id: string; body: T }>({
    query: ({ id, body }) => ({ type: apiMethodType.update, method, id, body }),
    invalidatesTags: (_result, _error, { id }) => [tag, { type: tag, id }],
  });

export default updateOne;
