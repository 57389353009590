import { MouseEventHandler } from 'react';
import OurAvatar from './OurAvatar';

interface OurExpectedAvatarProps {
  ariaType?: string;
  src?: string;
  text?: string;
  onClick?: MouseEventHandler;
}

const OurExpectedAvatar = ({ ariaType, src, text, onClick, ...rest }: OurExpectedAvatarProps) => (
  <OurAvatar ariaType={ariaType} src={src} text={text} expectText onClick={onClick} {...rest} />
);

OurExpectedAvatar.whyDidYouRender = true;

export default OurExpectedAvatar;
