import { RecordType } from '@petconsole/pure-base';
import { Api, ShopRecord, shopEntity, GetListOptions, GetListResults, ApiEntity } from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = ShopRecord;
type ListReturns = Promise<GetListResults<ApiRecord>>;

const apiPath = '/shops';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi extends RecordType {
  getListByOwner: ({ ownerId }: { ownerId: string }, options?: GetListOptions) => ListReturns;
  getListByShopName: ({ shopName }: { shopName: string }, options?: GetListOptions) => ListReturns;
}

export type ShopApi = Api<ApiRecord> & CustomApi;

export const shopApi: ShopApi = {
  ...common,
  getListByValue,
  // getListByOwner may be deprecated by getMyList
  getListByOwner: async ({ ownerId }, options) => getListByValue('ownerId', ownerId, options),
  getListByShopName: async ({ shopName }, options) => getListByValue('shopName', shopName, options),
};

export const shopApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...shopEntity, api: shopApi };
