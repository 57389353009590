import { ResetPasswordInput, resetPassword } from 'aws-amplify/auth';

export const attemptResetPassword = ({ username }: ResetPasswordInput) => resetPassword({ username });

export interface AuthResetPasswordProps {
  email: string;
}

const authResetPassword = async ({ email }: AuthResetPasswordProps) => {
  try {
    const result = await attemptResetPassword({ username: email });

    return { success: true, email: result.nextStep.codeDeliveryDetails.destination };
  } catch (e) {
    if (!(e instanceof Error)) {
      // TODO: Log to rollbar
      return { success: false, reason: 'Unknown error' };
    }

    return { success: false, reason: e.message };
  }
};

export default authResetPassword;
