import { useState } from 'react';
import { dateToAge, RecordType, stringToMonthYear } from '@petconsole/pure-base';
import { Image, webPath, EntityRecord, entityType } from '@petconsole/pure-shared';
import { defaultAvatar as ourDefaultAvatar } from '../../../../constants';
import { default as ourGetEntityTitle } from '../../../../entity/helpers/ourGetEntityTitle';
import { DefaultAvatar } from '../../../../types';
import { OurEntityCardProps } from '../OurEntityCard';
import useEntityCardAvatar from './useEntityCardAvatar';
import getEntityCardBannerSrc from './getEntityCardBannerSrc';
import useEntityCardDirections from './useEntityCardDirections';
import useEntityCardEntity from './useEntityCardEntity';
import useEntityCardHeader from './useEntityCardHeader';
import useEntityCardRating from './useEntityCardRating';
import useEntityCardReaction from './useEntityCardReaction';
import useEntityCardSubscribe from './useEntityCardSubscribe';

interface UseOurEntityCardDataProps<T extends EntityRecord = EntityRecord>
  extends Omit<OurEntityCardProps<T>, 'headerOptions' | 'cardSx' | 'IsYours'> {
  isVisible: boolean;
  preview: boolean;
  subscribable: boolean;
}

const useOurEntityCardData = <T extends EntityRecord = EntityRecord>({
  isVisible,
  hooks,
  getEntityQueryHook,
  preview,
  subscribable,
  entityId,
  entityIds,
  entityData,
  defaultAvatar,
  title,
  titleField,
  previewAvatar,
  previewBanner,
  previewPhotos,
  entityPath,
  byCity,
  infoField,
}: UseOurEntityCardDataProps<T>) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const { entity: apiEntity, ratingHooks, reactionHooks } = hooks;
  const { columns, memberOwned, name: entityName, path, plural, shared, idName, nameField, type: dataType } = apiEntity;
  const { images: hasImages, socials: hasSocials } = columns;
  const sample = {
    bannerImage: `${webPath.media}/containers/${plural}/Sample-Banner-tiny.png`,
    ratings: [0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
    rating: 5,
    tagline: 'Your tagline/catchphrase appears here',
    facebook: '.',
    instagram: '.',
    twitter: '.',
    linkedIn: '.',
    youTube: '.',
    tikTok: '.',
    aboutUs: 'Let members know why they should choose and trust your business.',
    images: [
      {
        title: 'Introduce your team',
        s3Key: `${webPath.media}/containers/${plural}/Sample-Photo-1-tiny.png`,
      },
      {
        title: 'Announce your promotions',
        s3Key: `${webPath.media}/containers/${plural}/Sample-Photo-2-tiny.png`,
      },
    ] as Image[],
    ...(infoField ? { [infoField]: 'Extra info may appear here' } : {}),
  } as RecordType as T;

  const { cardId, entityRecord } = useEntityCardEntity<T>({ getEntityQueryHook, entityId, entityData, idName });
  const { aboutMe, aboutUs, memberName, ownerName, tagline } = entityRecord;
  const entityIdPath = `${entityPath || path}/${entityRecord?.urlName || cardId}`;

  const isOwned = Boolean((ownerName && subscribable) || (memberName && memberOwned));
  const displaySamples = preview && !isOwned && !memberOwned && !shared;
  const viewEntity = displaySamples ? sample : entityRecord;

  const entityTitle = ourGetEntityTitle<T>({ entityRecord, nameField, title, titleField });
  const { avatar } = useEntityCardAvatar<T>({
    defaultAvatar: defaultAvatar || ourDefaultAvatar[entityName as DefaultAvatar],
    previewAvatar,
    entityRecord,
    entityIdPath,
    entityIds,
  });
  const { header } = useEntityCardHeader<T>({ preview, entityTitle, entityIdPath, entityIds, entityRecord, byCity });
  const { rating } = useEntityCardRating<T>({ ratingHooks, preview, displaySamples, sample, cardId, entityRecord });
  const { reaction } = useEntityCardReaction<T>({ reactionHooks, cardId, entityRecord });
  const { directions } = useEntityCardDirections<T>({ entityRecord, title: entityTitle, rating });
  const { subscribe } = useEntityCardSubscribe<T>({ preview, subscribable, isOwned, memberOwned, entityRecord });
  const bannerSrc = getEntityCardBannerSrc<T>({ displaySamples, preview, previewBanner, sample, entityRecord });

  const banner = { show: (isVisible || preview) && !!bannerSrc, src: bannerSrc, entity: entityName, name: entityTitle };
  const imageBar = {
    show: hasImages && (isVisible || preview) && (displaySamples || isOwned || rating.show),
    images: preview ? previewPhotos : viewEntity?.images,
    keyPrefix: cardId,
  };
  const options = { isOpen: optionsOpen, setIsOpen: setOptionsOpen };

  const memberInfoText =
    dataType === entityType.Member && viewEntity.createdAt
      ? [
          viewEntity.showRealName ? `${viewEntity.firstName} ${viewEntity.lastName}` : '',
          viewEntity.occupation,
          viewEntity.showAge && dateToAge(viewEntity.birthDate as Date || new Date()),
          `Member since ${stringToMonthYear(viewEntity.createdAt as string)}`,
        ]
          .filter((x) => !!x)
          .join(', ')
      : '';
  const petInfoText =
    dataType === entityType.Pet && viewEntity.createdAt
      ? [viewEntity.occupation, viewEntity.age, `Member since ${stringToMonthYear(viewEntity.createdAt as string)}`]
          .filter((x) => !!x)
          .join(', ')
      : '';
  const infoText = memberInfoText
    ? memberInfoText
    : petInfoText
      ? petInfoText
      : !infoField
        ? ''
        : displaySamples
          ? (sample[infoField] as string)
          : ((entityRecord[infoField] || '') as string);

  const show = {
    tagline: displaySamples || (isOwned && tagline),
    social: hasSocials && (displaySamples || isOwned),
    aboutMe: Boolean(isOwned && aboutMe),
    aboutUs: displaySamples || (isOwned && aboutUs),
  };

  return {
    header,
    avatar,
    rating,
    reaction,
    directions,
    banner,
    options,
    infoText,
    viewEntity,
    imageBar,
    subscribe,
    show,
  };
};

export default useOurEntityCardData;
