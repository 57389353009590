import { RecordType } from '@petconsole/pure-base';
import { BaseSliceOptions, SliceOptions } from '../../types';
import compareNameAndDate from '../comparers/compareNameAndDate';

const sliceOverrideOptions = <T extends RecordType = RecordType>(currentOptions: SliceOptions, overrideOptions: BaseSliceOptions<T>) => {
  const options: SliceOptions = { ...currentOptions };

  Object.keys(options).forEach((key) => {
    if (overrideOptions[key] !== undefined) options[key] = overrideOptions[key];
  });

  if (!options.comparer) options.comparer = compareNameAndDate(options.sortProperty, 'createdAt');

  return options;
};

export default sliceOverrideOptions;
