import { GoogleSavedAddress } from './types';

export const defaultGoogleAddress = (): GoogleSavedAddress => ({
  city: '',
  country: '',
  description: '',
  formattedAddress: '',
  googlePlaceId: '',
  latitude: 0,
  longitude: 0,
  name: '',
  province: '',
  region: '',
  types: [],
  utcOffsetMinutes: 0,
  streetNumber: '',
  unit: '',
  street: '',
  postal: '',
  neighborhood: '',
});
