import { useRef } from 'react';
import { ApiUseGetQuery, RequiredCrudSliverHooks } from '@petconsole/pure-fe-state';
import { RecordType } from '@petconsole/pure-base';
import useOurErrorLogger from '../error/useOurErrorLogger';
import useOurSliverCards from '../hooks/useOurSliverCards';
import useOurSnacks from '../hooks/useOurSnacks';
import { AddEntityType } from '../types';
import useOurAddListEntity from './helpers/useOurAddListEntity';
import useOurEntityTabFetchReset from './helpers/useOurEntityFetchReset';
import OurEntityTabPage, { OurBaseEntityTabPageProps } from './OurEntityTabPage';

export interface OurMyEntityTabPageProps extends OurBaseEntityTabPageProps {
  getEntityQueryHook: ApiUseGetQuery;
  extraTabContent?: unknown[][];
  extraTabProps?: RecordType;
  add?: boolean;
}

const OurMyEntityTabPage = ({
  margins = { md: 2, lg: 3 },
  hooks,
  getEntityQueryHook,
  helpContent,
  extraTabContent = [],
  extraTabProps = {},
  tabWidth,
  add = true,
  addProps,
  header,
  headerImage,
}: OurMyEntityTabPageProps) => {
  useOurErrorLogger();
  useOurSnacks();

  const entity = hooks.entity;
  const { name: entityName, pluralProper } =entity;
  const tabName = `My ${pluralProper}`;
  const myTabProps = useOurEntityTabFetchReset({ hooks, tabName, prefix: 'my' });
  const myTab = useOurSliverCards({ hooks, getEntityQueryHook, prefix: 'my' });
  const tabProps = useRef({
    ...myTabProps,
    ...extraTabProps,
  });

  const crudHooks = hooks[`${entityName}Hooks`] as RequiredCrudSliverHooks;
  const { AddListEntity } = useOurAddListEntity({ entity, crudHooks, addProps });

  return (
    <OurEntityTabPage
      margins={margins}
      hooks={hooks}
      header={header}
      headerImage={headerImage}
      helpContent={helpContent}
      myTab={myTab}
      tabContent={extraTabContent}
      tabProps={tabProps}
      tabWidth={tabWidth}
      AddEntity={add ? (AddListEntity as AddEntityType) : undefined}
    />
  );
};

OurMyEntityTabPage.whyDidYouRender = true;

export default OurMyEntityTabPage;
