import { ReactNode } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import { ApiUseGetQuery, OurEntityHooks, UpdateRating } from '@petconsole/pure-fe-state';
import { dataEntityType, EntityRecord, Image, Images } from '@petconsole/pure-shared';
import useOurIsVisible from '../../../hooks/useOurIsVisible';
import OurDirectionsButton from '../../buttons/OurDirectionsButton';
import OurImageTabBar from '../../images/OurImageTabBar';
import OurSocialLinks from '../../links/OurSocialLinks';
import { IsYoursType } from '../../misc/isThisYours';
import OurMoreLessText from '../../misc/OurMoreLessText';
import OurTypoText from '../../misc/OurTypoText';
import OurCardBannerImage from '../OurCardBannerImage';
import OurDirectionsCardContent from '../OurDirectionsCardContent';
import OurRatingCardContent from '../OurRatingCardContent';
import useOurEntityCardData from './helpers/useOurEntityCardData';
import EntityCardHeader from './EntityCardHeader';
import EntityCardYours from './EntityCardYours';
import OurEntityCardOptions from '../OurEntityCardOptions';

export interface OurEntityCardProps<T extends EntityRecord = EntityRecord> {
  hooks: OurEntityHooks<T>;
  getEntityQueryHook?: ApiUseGetQuery;
  preview?: boolean;
  subscribable?: boolean;
  entityId?: string;
  entityIds?: string[];
  entityData?: T;
  title?: string;
  titleField?: string | string[];
  defaultAvatar?: string;
  previewAvatar?: string;
  previewBanner?: Image;
  previewPhotos?: Images;
  entityPath?: string;
  byCity?: boolean;
  infoField?: string;
  headerOptions?: ReactNode;
  IsYours?: IsYoursType<T>;
  cardSx?: RecordType;
}

const OurEntityCard = <T extends EntityRecord = EntityRecord>({
  hooks,
  getEntityQueryHook,
  preview = false,
  subscribable = false,
  entityId,
  entityIds,
  entityData,
  defaultAvatar,
  titleField,
  previewAvatar,
  previewBanner,
  previewPhotos,
  entityPath,
  title,
  byCity,
  infoField,
  headerOptions,
  IsYours,
  cardSx = {},
}: OurEntityCardProps<T>) => {
  const { ref, isVisible } = useOurIsVisible();
  const {
    header,
    avatar,
    rating,
    reaction,
    directions,
    banner,
    options,
    infoText,
    viewEntity,
    imageBar,
    subscribe,
    show,
  } = useOurEntityCardData<T>({
    isVisible,
    hooks,
    getEntityQueryHook,
    preview,
    subscribable,
    entityId,
    entityIds,
    entityData,
    defaultAvatar,
    title,
    titleField,
    previewAvatar,
    previewBanner,
    previewPhotos,
    entityPath,
    byCity,
    infoField,
  });
  const { hasLikes, likeCount, liked, onLike } = reaction;

  return (
    <Card raised={true} ref={ref} sx={{ width: '100%', ...(cardSx && cardSx) }}>
      <EntityCardHeader
        preview={preview}
        isVisible={isVisible}
        avatarSrc={avatar.src}
        onAvatar={
          viewEntity.type === dataEntityType.Pet || viewEntity.type === dataEntityType.Member
            ? undefined
            : avatar.onClick
        }
        onEdit={avatar.onClick}
        entityTitle={header.entityTitle}
        Title={header.Title}
        subheader={header.subheader}
        owner={header.owner}
        hasLikes={hasLikes}
        likeCount={likeCount}
        liked={liked}
        onLike={onLike}
        optionsOpen={options.isOpen}
        setOptionsOpen={options.setIsOpen}
      />
      {options.isOpen && (
        <OurEntityCardOptions isOpen={!!headerOptions && options.isOpen}>{headerOptions}</OurEntityCardOptions>
      )}
      {infoText && <OurTypoText text={infoText} typeProps={{ variant: 'subtitle2', align: 'center', pt: 0.25 }} />}
      {directions.show && (
        <OurDirectionsCardContent
          latitude={directions.latitude}
          longitude={directions.longitude}
          title={directions.title}
          subTitle={directions.subTitle}
          isOpen={directions.isOpen}
        />
      )}
      {rating.show ? (
        <OurRatingCardContent
          ratings={rating.ratings}
          yourRating={rating.yourRating}
          update={rating.update as UpdateRating}
          id={rating.id}
          readOnly={rating.readOnly}
          centerContent={
            directions.show ? <OurDirectionsButton isOpen={directions.isOpen} setIsOpen={directions.setIsOpen} /> : null
          }
          sx={{ ...(banner.show && { pb: 0 }) }}
        />
      ) : directions.show ? (
        <OurDirectionsButton isOpen={directions.isOpen} setIsOpen={directions.setIsOpen} />
      ) : null}
      {banner.show && (
        <OurCardBannerImage
          entity={banner.entity}
          name={banner.name}
          image={banner.src}
          onImage={viewEntity.type === dataEntityType.Pet ? undefined : avatar.onClick}
          preview={preview}
        />
      )}
      <Grid container item justifyContent="center" px={1}>
        {show.tagline && <Typography variant="subtitle2">{viewEntity.tagline as string}</Typography>}
        {show.social && <OurSocialLinks entity={viewEntity} />}
        {show.aboutMe && <OurMoreLessText id="aboutMe" value={viewEntity.aboutMe as string | undefined} />}
        {show.aboutUs && <OurMoreLessText id="aboutUs" value={viewEntity.aboutUs} />}
        {imageBar.show && <OurImageTabBar images={imageBar.images as Images} keyPrefix={imageBar.keyPrefix} />}
        {subscribe.show && IsYours && (
          <EntityCardYours<T> entityRecord={subscribe.entityRecord} disabled={subscribe.disabled} IsYours={IsYours} />
        )}
      </Grid>
    </Card>
  );
};

// TODO:
//  - Consider custom url in paths
//  - no sense showing header options enabled if there aren't any for the entity
//  - handle aboutMe when supporting member cards

// OurEntityCard.whyDidYouRender = true;

export default OurEntityCard;
