import { Grid } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import AddressInput from '../inputs/react-hook-form/AddressInput';
import { MaintAddressProps } from './types';

const MaintAddress = <T extends RecordType = RecordType>({
  addressBreakpoints,
  entity,
  ...rest
}: MaintAddressProps<T>) => {
  if (!entity.columns.address) return null;

  return (
    <Grid item {...(addressBreakpoints ? addressBreakpoints : { xs: 12, sm: 8, md: 9 })}>
      <AddressInput {...rest} />
    </Grid>
  );
};

MaintAddress.whyDidYouRender = true;

export default MaintAddress;
