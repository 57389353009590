import { RecordType } from '@petconsole/pure-base';
import { RecordComparer, Slice } from '../../types';
import { compareCreatedAt } from '../comparers';

const sliceAddComparers = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  const { option, proper } = slice;
  const { comparer } = option;
  const slivers = { ...slice.sliver };

  slice.slivers.forEach((sliverName) => {
    const sliver = slivers[sliverName];

    switch (sliverName) {
      case `newest${proper}`:
        sliver.comparer = sliver.comparer || compareCreatedAt(false);
        break;

      case `oldest${proper}`:
        sliver.comparer = sliver.comparer || compareCreatedAt(true);
        break;

      default:
        sliver.comparer = sliver.comparer || <RecordComparer>comparer;
    }
  });

  return slivers;
};

export default sliceAddComparers;
