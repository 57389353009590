import { MouseEventHandler } from 'react';
import { Divider, ListItemButton, ListItemText } from '@mui/material';

interface OurCardOptionProps {
  text: string;
  action?: MouseEventHandler;
}

const OurCardOption = ({ text, action }: OurCardOptionProps) => {
  return (
    <>
      <Divider variant="middle" component="li" />
      <ListItemButton disabled={!action} onClick={action}>
        <ListItemText primary={text} />
      </ListItemButton>
    </>
  );
};

OurCardOption.whyDidYouRender = true;

export default OurCardOption;
