import { PropsWithChildren, ReactElement } from 'react';
import { isMobile } from 'react-device-detect';
import { Tooltip } from '@mui/material';

interface OurTooltipProps extends PropsWithChildren {
  tip?: string;
}

const OurTooltip = ({ tip, children }: OurTooltipProps) => {
  if (!tip || isMobile) return children;

  return <Tooltip title={tip}>{children as ReactElement}</Tooltip>;
};

OurTooltip.whyDidYouRender = true;

export default OurTooltip;
