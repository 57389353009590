import { cartApiEntity } from '@petconsole/pure-fe-api';
import { CartRecord, PostMethod } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, getOne, getOneBy, postOne, updateOne } from '../../../helpers';

type ApiRecord = CartRecord;

const cartEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = cartApiEntity;

  return {
    createCart: createOne(builder, api, tag),
    getCart: getOne(builder, api, tag),
    getCartByShop: getOneBy(builder, api.getByShop, tag),
    updateCart: updateOne(builder, api.update, tag),
    updateCartTotal: postOne(builder, api.updateTotal as PostMethod<CartRecord>, tag),
  };
};

export default cartEndpoints;
