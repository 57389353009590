import { RecordType } from '@petconsole/pure-base';
import useIsYours from '../../components/misc/isThisYours/useIsYours';
import useMaintDirections from './useMaintDirections';
import { UseMaintMainContentProps } from '../types';

const useMaintMainContent = <T extends RecordType = RecordType>({
  entity,
  data,
  directions: addressDirections,
  readOnly,
  isOwned,
}: UseMaintMainContentProps<T>) => {
  // IsYours
  const { IsYoursGridItem, IsYoursDialog } = useIsYours<T>({ entity, data, readOnly, isOwned });

  // Directions
  const directions = addressDirections ?? entity.columns.address ?? false;
  const { DirectionsButton, DirectionsMap } = useMaintDirections({ entity, data, directions, readOnly });

  return { IsYoursGridItem, IsYoursDialog, DirectionsButton, DirectionsMap };
};

export default useMaintMainContent;
