// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import {
  defaultItemPattern,
  myShopItemVariantSetOn,
  ourShopItemType,
  ourShopItemTypes,
  ourUomLength,
  ourUomLengths,
  ourUomQuantities,
  ourUomQuantity,
  ourUomWeight,
  ourUomWeights,
} from '../../../constants';
import { entityCategory, entityName } from '../../../entity/constants';
import entityAttributes from '../../../entity/entityAttributes';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import {
  DataEntityType,
  DefaultColumns,
  InitialEntity,
  MaybeImage,
  MyShopItemVariantSetOn,
  OurShopItemType,
  OurUomLength,
  OurUomQuantity,
  OurUomWeight,
} from '../../../types';

export interface ShopItem extends DefaultColumns {
  type: DataEntityType;
  shopItemId: string;
  shopId: string;
  name: string;
  thumbnail: MaybeImage;
  itemType: OurShopItemType;
  sku: string;
  itemPrefix: string;
  gtin: string;
  externalId: string;
  serviceUom: OurUomQuantity;
  serviceType: string;
  isActive: boolean;
  description: string;
  quantityUom: OurUomQuantity;
  weightUom: OurUomWeight;
  lengthUom: OurUomLength;
  hasVariants: boolean;
  hasInventory: boolean;
  hasGtins: boolean;
  hasExternalIds: boolean;
  weight: number;
  length: number;
  width: number;
  height: number;
  enterItemNumbers: boolean;
  // enterPrices: boolean; obsolete - needs to be removed
  enterDescriptions: boolean;
  appendDescriptions: boolean;
  useVariantOption2: boolean;
  variantName1: string;
  variantName2: string;
  itemPattern: string;
  cost: number;
  price: number;
  pst: boolean;
  gst: boolean;
  shipRateSet: boolean;
  baseShipRate: number;
  itemShipRate: number;
  setWeightOn: MyShopItemVariantSetOn;
  setDimsOn: MyShopItemVariantSetOn;
  setPriceOn: MyShopItemVariantSetOn;
  setDescriptionOn: MyShopItemVariantSetOn;
  setThumbnailOn: MyShopItemVariantSetOn;
}

export const shopItemEntity = entityAttributes({
  name: entityName.shopItem,
  attributes: {
    category: entityCategory.item,
    loadLimit: 20,
    maxImages: 6,
    ownerIfEditing: true,
  },
});

const {boolean, caps, id, image, oneOf, pos, strim, string} = schema;
export const shopItemSchema = entitySchema<ShopItem>(shopItemEntity, {
  shopId: id(true).required('Shop id is required'),
  name: strim().required('Item name is required'),
  thumbnail: image(),
  itemType: string().required().oneOf(ourShopItemTypes).default(ourShopItemType.Product),
  sku: caps().required('Item number/prefix is required'),
  itemPrefix: strim(), // copy of sku if it has variants
  gtin: strim(),
  externalId: strim(),
  serviceUom: string().required().oneOf(ourUomQuantities).default(ourUomQuantity.HR),
  serviceType: strim(),
  isActive: boolean(),
  description: strim(),
  quantityUom: string().required().oneOf(ourUomQuantities).default(ourUomQuantity.EA),
  weightUom: string().required().oneOf(ourUomWeights).default(ourUomWeight.LB),
  lengthUom: string().required().oneOf(ourUomLengths).default(ourUomLength.IN),
  hasVariants: boolean(),
  hasInventory: boolean(),
  hasGtins: boolean(),
  hasExternalIds: boolean(),
  weight: pos().required('Weight is required'),
  length: pos().required('Length is required'),
  width: pos().required('Width is required'),
  height: pos().required('Height is required'),
  enterItemNumbers: boolean(),
  appendDescriptions: boolean(),
  useVariantOption2: boolean(),
  variantName1: strim().default('Color'),
  variantName2: strim().default('Size'),
  itemPattern: caps().default(defaultItemPattern).test(
    'item-pattern-yup-test',
    'Item pattern is required to create item numbers/SKUs',
    (value, { parent }) => value || !parent.hasVariants || parent.enterItemNumbers,
  ),
  cost: pos().required('Cost is required'),
  price: pos().required('Price is required'),
  pst: boolean(),
  gst: boolean(),
  shipRateSet: boolean(),
  baseShipRate: pos().required('Base ship rate is required'),
  itemShipRate: pos().required('Item ship rate is required'),
  setWeightOn: oneOf(myShopItemVariantSetOn, myShopItemVariantSetOn.Option2),
  setDimsOn: oneOf(myShopItemVariantSetOn, myShopItemVariantSetOn.Option2),
  setPriceOn: oneOf(myShopItemVariantSetOn, myShopItemVariantSetOn.Option2),
  setDescriptionOn: oneOf(myShopItemVariantSetOn, myShopItemVariantSetOn.Item),
  setThumbnailOn: oneOf(myShopItemVariantSetOn, myShopItemVariantSetOn.Option1),
});

export const shopItemDefaults: Partial<ShopItem> = {
  shopId: '',
  name: '',
  thumbnail: null,
  itemType: ourShopItemType.Product,
  sku: '',
  itemPrefix: '',
  gtin: '',
  externalId: '',
  serviceUom: ourUomQuantity.HR,
  serviceType: '',
  isActive: false,
  description: '',
  quantityUom: ourUomQuantity.EA,
  weightUom: ourUomWeight.LB,
  lengthUom: ourUomLength.IN,
  hasVariants: false,
  hasInventory: false,
  hasGtins: false,
  hasExternalIds: false,
  weight: 0,
  length: 0,
  width: 0,
  height: 0,
  enterItemNumbers: false,
  appendDescriptions: false,
  useVariantOption2: false,
  variantName1: 'Color',
  variantName2: 'Size',
  itemPattern: defaultItemPattern,
  cost: 0,
  price: 0,
  pst: false,
  gst: false,
  shipRateSet: false,
  baseShipRate: 0,
  itemShipRate: 0,
  setWeightOn: myShopItemVariantSetOn.Option2,
  setDimsOn: myShopItemVariantSetOn.Option2,
  setPriceOn: myShopItemVariantSetOn.Option2,
  setDescriptionOn: myShopItemVariantSetOn.Item,
  setThumbnailOn: myShopItemVariantSetOn.Option1,
};

export interface ShopItemRecord extends ShopItem, RecordType {
}

export type MaybeShopItemRecord = ShopItemRecord | null;
export type ShopItemRecords = ShopItemRecord[];

export const initialShopItem: InitialEntity<ShopItem, ShopItemRecord> = (overrides = {}) =>
  initialEntity<ShopItem>(shopItemEntity, {...shopItemDefaults, ...overrides}) as ShopItemRecord;
