import { Login } from '@mui/icons-material';
import { webPath } from '@petconsole/pure-shared';
import OurAppMenuItem from './OurAppMenuItem';

const OurLoginMenuItem = () => {
  return <OurAppMenuItem item={[Login, 'Login', webPath.login]} />;
};

OurLoginMenuItem.whyDidYouRender = true;

export default OurLoginMenuItem;
