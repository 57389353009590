import { Store } from '@mui/icons-material';
import { OurMarketplaceHubIcon } from '@petconsole/pure-fe-icon';
import {
  SelectHook, SetHook,
  useOurSelectMemberMenuMarketplaceMenuOpen,
  useOurSetMemberMenuMarketplaceMenuOpen,
} from '@petconsole/pure-fe-state';
import { IsOpenProps, SetState, webPath } from '@petconsole/pure-shared';
import { AppMenuItems } from '../../../types';
import OurAppMenuCollapsable from './OurAppMenuCollapsable';
import ourCombinedAppMenuItems from './ourCombinedAppMenuItems';

interface OurMarketplaceMenuProps extends Partial<IsOpenProps> {
  add?: AppMenuItems;
  exclude?: string[];
}

const OurMarketplaceMenu = ({ isOpen, setIsOpen, add, exclude }: OurMarketplaceMenuProps) => {
  const menuItemsOpen = (useOurSelectMemberMenuMarketplaceMenuOpen as SelectHook<boolean>)();
  const setMenuItemsOpen = (useOurSetMemberMenuMarketplaceMenuOpen as SetHook<boolean>)();

  const menuItems = [[Store, 'Shops', webPath.shops]] as AppMenuItems;

  const open = isOpen != null ? isOpen : menuItemsOpen;
  const setOpen = setIsOpen != null ? setIsOpen : setMenuItemsOpen as SetState<boolean>;
  const items = ourCombinedAppMenuItems({ items: menuItems, add, exclude });

  return (
    <OurAppMenuCollapsable
      open={open}
      setOpen={setOpen}
      Icon={OurMarketplaceHubIcon}
      label="Marketplace"
      items={items}
      divider={true}
    />
  );
};

OurMarketplaceMenu.whyDidYouRender = true;

export default OurMarketplaceMenu;
