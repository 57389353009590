import { Box } from '@mui/material';
import { SetState } from '@petconsole/pure-shared';
import { ourButtonBorderRadius } from '../../constants';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import OurButton from './OurButton';

interface OurSlideButtonsProps {
  leftLabel: string;
  rightLabel: string;
  right: boolean;
  setRight: SetState<boolean>;
}

const OurSlideButtons = ({ leftLabel, rightLabel, right, setRight }: OurSlideButtonsProps) => {
  const darkMode = useOurDarkMode();

  const tabBackColor = darkMode ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.23)';

  const renderButton = (label: string) => (
    <OurButton
      label={label}
      onClick={() => setRight(label === rightLabel)}
      fullWidth={false}
      sx={{ width: 100, textTransform: 'none' }}
    />
  );

  const renderSpan = (label: string) => (
    <span
      role="presentation"
      onClick={() => setRight(label === rightLabel)}
      style={{
        width: 100,
        textAlign: 'center',
        cursor: 'pointer',
        paddingTop: 6,
        paddingBottom: 6,
      }}
    >
      {label}
    </span>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      border="1px solid rgb(255,255,255,0.50)"
      borderRadius={ourButtonBorderRadius}
      sx={{ backgroundColor: tabBackColor }}
    >
      {right ? renderSpan(leftLabel) : renderButton(leftLabel)}
      {right ? renderButton(rightLabel) : renderSpan(rightLabel)}
    </Box>
  );
};

OurSlideButtons.whyDidYouRender = true;

export default OurSlideButtons;
