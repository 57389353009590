// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import entityAttributes from '../entity/entityAttributes';
import entitySchema from '../entity/entitySchema';
import initialEntity from '../entity/initialEntity';
import { EntityType, InitialEntity } from '../types';
import { entityName } from '../entity/constants';
import { schema } from '../entity/schema';

interface SampleTest {
  type: EntityType;
  testId: string;
  id: string;
}

export const testEntity = entityAttributes({
  name: entityName.test,
  attributes: {},
});

const { id } = schema;
export const testSchema = entitySchema<SampleTest>(testEntity, {
  id: id(),
});

export const testDefaults: Partial<SampleTest> = {
  id: '',
};

export interface TestRecord extends SampleTest, RecordType {}

export const initialTestDef: InitialEntity<SampleTest, TestRecord> = (overrides = {}) =>
  initialEntity<SampleTest>(testEntity, { ...testDefaults, ...overrides }) as TestRecord;
