import { properCase } from '@petconsole/pure-base';
import { appThunk } from '../../store/appThunk';
import { ThunkRecordFromRecord } from '../../types';
import { getCreatorUpdateRating, GetCreatorUpdateRatingProps } from '../creators';

export const updateRatingThunk = ({ sliceName, api, idName, fetchEntity, fetchRating }: GetCreatorUpdateRatingProps) => {
  const creator = getCreatorUpdateRating({ sliceName, api, idName, fetchEntity, fetchRating });

  return appThunk(`${sliceName}/update${properCase(sliceName)}Rating`, <ThunkRecordFromRecord>(<unknown>creator));
};
