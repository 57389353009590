import { ActionCreatorWithPayload, AsyncThunk } from '@reduxjs/toolkit';
import { CityLocation, RecordOfRecordType, RecordType, properCase } from '@petconsole/pure-base';
import { Api } from '@petconsole/pure-shared';
import { Slice, StateType, ThunkApiConfig } from '../../types';
import {
  useOurDispatchValue,
  useOurFetchCityEntities,
  useOurSelectById,
  useOurSelectsByPropertyNameAndValue,
} from '../hooks';
import sliceCrudHooks from './sliceCrudHooks';
import slicePropertyHooks from './slicePropertyHooks';
import sliceRatingHooks from './sliceRatingHooks';
import { sliceReactionHooks } from './sliceReactionHooks';
import sliceSliverHooks from './sliceSliverHooks';
import sliceSuffixHooks from './sliceSuffixHooks';
import sliceToggleHooks from './sliceToggleHooks';

export const sliceAssembleHooks = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  const { api, name: sliceName, proper, plural, pluralProper, action, flag, thunk, sliver } = slice;
  const { hasRating, hasReaction, hasCitySearch, hasMySliver, hasCitySliver } = flag;
  const { hasTabValues, hasDefaultProperties } = flag;
  const { fetchOneByProperty: fetchOne, fetchManyByProperty: fetchMany } = slice.option;
  const { tabs, sliceTabs, sliceProperties } = slice.option;
  const fetchOneProper = fetchOne ? properCase(fetchOne.propertyName) : '';
  const fetchManyProper = fetchMany ? properCase(fetchMany.propertyName) : '';

  let sliverHooks = {};

  slice.slivers.forEach((sliverName) => {
    sliverHooks = { ...sliverHooks, ...sliceSliverHooks(slice, sliverName) };
  });

  return {
    ...sliceCrudHooks(sliceName, proper, sliver[sliceName].selectors, thunk),
    ...sliverHooks,
    ...(hasRating && sliceRatingHooks(sliceName, proper, thunk)),
    ...(hasReaction && sliceReactionHooks(sliceName, proper, thunk, api.entity as Api)),
    ...(hasCitySliver && {
      [`useOurFetchCity${pluralProper}`]: () =>
        useOurFetchCityEntities(
          sliver[`city${proper}`].fetchManyCreator as unknown as ActionCreatorWithPayload<CityLocation, string>,
        ),
    }),
    ...(hasCitySearch && {
      ...sliceSuffixHooks(sliceName, 'CitySearchDefaulted', action),
      ...sliceSuffixHooks(sliceName, 'CitySearchInput', action),
      ...sliceSuffixHooks(sliceName, 'CitySearchLocation', action),
      ...sliceSuffixHooks(sliceName, 'CityChangeLocation', action),
    }),
    ...(hasDefaultProperties && {
      ...sliceSuffixHooks(sliceName, 'Editing', action),
      ...sliceSuffixHooks(sliceName, 'Submitting', action),
      ...sliceSuffixHooks(sliceName, 'AddressInput', action),
    }),
    ...(tabs &&
      Object.keys(tabs).reduce(
        (previousValue, tab) => ({
          ...previousValue,
          ...slicePropertyHooks(sliceName, `${tab}TabValue`, action),
        }),
        {},
      )),
    ...(sliceTabs &&
      sliceTabs.reduce(
        (previousValue: RecordType, currentValue) => ({
          ...previousValue,
          ...slicePropertyHooks(sliceName, `${currentValue.prefix}TabValue`, action),
        }),
        {},
      )),
    ...(!tabs &&
      !sliceTabs &&
      hasTabValues && {
        ...sliceSuffixHooks(sliceName, 'AdminTabValue', action),
        ...sliceSuffixHooks(sliceName, 'TabValue', action),
        ...slicePropertyHooks(sliceName, `${plural}TabValue`, action),
        ...(hasMySliver && {
          ...slicePropertyHooks(sliceName, `my${proper}TabValue`, action),
          ...slicePropertyHooks(sliceName, `my${pluralProper}TabValue`, action),
        }),
      }),
    ...(sliceProperties &&
      sliceProperties.reduce(
        (previousValue, { suffix, toggle }) => ({
          ...previousValue,
          ...(toggle
            ? sliceToggleHooks(sliceName, `${sliceName}${suffix}`, action)
            : slicePropertyHooks(sliceName, `${sliceName}${suffix}`, action)),
        }),
        {},
      )),
    ...(fetchOne && {
      [`useOurSelect${proper}By${fetchOneProper}`]: (value: string) =>
        useOurSelectById(
          (state: StateType, propertyValue: string) =>
            Object.values(state[sliceName][sliceName].entities as RecordOfRecordType).find(
              (entity: RecordType) => entity[fetchOne.propertyName] === propertyValue,
            ),
          value,
        ),
      [`useOurFetch${proper}By${fetchOneProper}`]: () =>
        useOurDispatchValue(fetchOne.thunk as AsyncThunk<unknown, unknown, ThunkApiConfig>),
    }),
    ...(fetchMany && {
      [`useOurSelect${pluralProper}By${fetchManyProper}`]: (value: string) =>
        useOurSelectsByPropertyNameAndValue({
          sliceName,
          sliverName: sliceName,
          propertyName: fetchMany.propertyName,
          value,
        }),
      [`useOurFetch${pluralProper}By${fetchManyProper}`]: () =>
        useOurDispatchValue(fetchMany.thunk as AsyncThunk<unknown, unknown, ThunkApiConfig>),
    }),
  };
};
