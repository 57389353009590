import { EntityName } from '@petconsole/pure-shared';
import { GetAppState } from '../../store';
import { StateRatingSliver, StateSlices } from '../../types';

interface GetStateSliceRatingsProps {
  getState: GetAppState,
  sliceName: string | EntityName;
}
const getStateSliceRatings = ({ getState, sliceName }: GetStateSliceRatingsProps) => {
  const slices = getState() as StateSlices;
  const sliver = slices[sliceName][sliceName] as StateRatingSliver;

  return sliver.rating.ratings;
};

export default getStateSliceRatings;
