import { RecordOfRecordType, RecordType, properCase } from '@petconsole/pure-base';
import { Reducers, StateType } from '../../types';
import resetReadWriteState from './resetReadWriteState';
import resetState from './resetState';

const resetReducers = (slivers: RecordType, initialState: StateType) => {
  const reducers: Reducers = {};

  Object.keys(slivers).forEach((sliver) => {
    const prefix = `reset${properCase(sliver)}`;

    reducers[String(`${prefix}State`)] = resetState(
      sliver,
      <RecordOfRecordType>initialState[sliver],
    );
    reducers[String(`${prefix}ReadWriteState`)] = resetReadWriteState(sliver);
  });

  return reducers;
};

export default resetReducers;
