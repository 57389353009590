import { RecordType } from '@petconsole/pure-base';
import { Api, ApiEntity, GetListOptions, GetListResults, topicEntity, TopicRecord } from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = TopicRecord;

const apiPath = '/topics';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getListByForum: (forumId: string, options?: GetListOptions) => Promise<GetListResults<T>>;
}

export type TopicApi = Api<ApiRecord> & CustomApi;

export const topicApi: TopicApi = {
  ...common,
  getListByValue,
  getListByForum: async (forumId, options) => getListByValue('forumId', forumId, options),
};

export const topicApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...topicEntity, api: topicApi };
