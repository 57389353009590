import { ChangeEventHandler, useEffect, useMemo, useRef } from 'react';
import { Grid } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import { ourSiteHooks } from '@petconsole/pure-fe-state';
import { ExtendedFormik, SiteRecords } from '@petconsole/pure-shared';
import OurSelect from './OurSelect';

interface OurSiteSelectProps {
  hasShops?: boolean;
  formik?: ExtendedFormik;
  id?: string;
  label?: string;
  inputProps?: RecordType;
  size?: string;
  value?: string;
  onChange?: (event: { target: { value: string } }) => void;
}

const OurSiteSelect = ({ hasShops, id = 'site', label = 'Site', ...rest }: OurSiteSelectProps) => {
  const { siteHooks, hasShopsSiteHooks } = ourSiteHooks;
  const allFetch = siteHooks.useFetches();
  const shopsFetch = hasShopsSiteHooks.useFetches();
  const allSites = siteHooks.useSelects() as SiteRecords;
  const shopsSites = hasShopsSiteHooks.useSelects() as SiteRecords;

  const fetch = hasShops ? shopsFetch : allFetch;
  const sites = hasShops ? shopsSites : allSites;

  const initialized = useRef(false);

  const siteOptions = useMemo(
    () => sites.map(({ site, siteName }) => ({ value: site, name: siteName })),
    [sites],
  );

  useEffect(() => {
    if (initialized.current) return;

    initialized.current = true;
    fetch().then();
  }, [fetch]);

  const onChangeSite: ChangeEventHandler<{ value: string }> = (e) => {
    const siteId = sites.find((site) => site.site === e.target.value)?.siteId;

    rest.formik?.setFieldValue('site', e.target.value);
    rest.formik?.setFieldValue('siteId', siteId);
  };

  return (
    <Grid container alignSelf="center" maxWidth="300px">
      <OurSelect
        id={id}
        selectOptions={siteOptions}
        {...rest}
        {...(rest.formik ? { onChange: onChangeSite } : {})}
      />
    </Grid>
  );
};

OurSiteSelect.whyDidYouRender = true;

export default OurSiteSelect;
