import { MouseEventHandler } from 'react';
import { Image } from '@petconsole/pure-shared';
import OurRemovableImage from '../OurRemovableImage';

interface PhotoProps {
  photo?: Image;
  canRemove?: boolean;
  onRemove: MouseEventHandler;
}

const Photo = ({ photo, canRemove, onRemove }: PhotoProps) => {
  if (!photo?.src) return null;

  const { src, title } = photo;

  return <OurRemovableImage src={src} alt={title} disabled={!canRemove} onRemove={onRemove} />;
}

Photo.whyDidYouRender = true;

export default Photo;
