import { entityName, ShopItemVariantCodeRecord } from '@petconsole/pure-shared';
import ourStateSliverData from '../../../helpers/state/ourStateSliverData';
import { createAppSelector } from '../../../store/storeHooks';
import { SelectByShopItemAndVariantOptionProps } from '../../../types';
import selectsByShopItemAndOptionOuterSelector from './selectsByShopItemAndOptionOuterSelector';

const selectsByShopItemAndOption = ({ shopItemId, variantOption }: SelectByShopItemAndVariantOptionProps) => {
  const outerSelector = selectsByShopItemAndOptionOuterSelector({ shopItemId, variantOption });

  return createAppSelector(
    [(state) => ourStateSliverData<ShopItemVariantCodeRecord>(state, entityName.shopItemVariantCode)],
    outerSelector,
  );
};

export default selectsByShopItemAndOption;
