import { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecordType } from '@petconsole/pure-base';
import { ourMemberImageUrl } from '@petconsole/pure-fe-api';

interface UseEntityCardAvatarProps<T extends RecordType = RecordType> {
  defaultAvatar?: string;
  previewAvatar?: string;
  entityRecord: T;
  entityIdPath: string;
  entityIds?: string[];
}

const useEntityCardAvatar = <T extends RecordType = RecordType>({
  defaultAvatar,
  previewAvatar,
  entityRecord,
  entityIdPath,
  entityIds,
}: UseEntityCardAvatarProps<T>) => {
  const navigate = useNavigate();

  const avatarSrc =
    previewAvatar || ourMemberImageUrl(entityRecord?.avatar as string | undefined || undefined) || defaultAvatar || '';

  const onClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (previewAvatar) return undefined;

      return navigate(entityIdPath, { state: { entityIds } });
    },
    [previewAvatar, navigate, entityIdPath, entityIds],
  );

  const avatar = { src: avatarSrc, onClick };

  return { avatar };
};

export default useEntityCardAvatar;
