import { useLocation, useNavigate } from 'react-router-dom';

const useOurNavigate = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);

  const setParam = (name: string, value: string) => {
    const existing = params.get(name);

    if (existing === value) return;

    params.set(name, value);

    navigate({ pathname, search: params.toString() }, { replace: true });
  };

  return { navigate, pathname, search, params, setParam };
};

export default useOurNavigate;