import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOurTrashIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M10 2 9 3H4v2h1v15c0 .522.191 1.055.568 1.432S6.478 22 7 22h10c.522 0 1.055-.191 1.432-.568S19 20.522 19 20V5h1V3h-5l-1-1zM7 5h10v15H7zm2 2v11h2V7zm4 0v11h2V7z" />
  </svg>
);
export default SvgOurTrashIcon;
