import { Api, ApiEntity } from '@petconsole/pure-shared';
import { sliceHookTypes, useOurDispatchValue, useOurSelectSliceProperty } from '../../../helpers';
import {
  AnyAsyncThunk,
  BaseSliceOptions,
  BaseSliceTab,
  OurEntityHooks,
  SetSelectBooleanHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

const sliceName = 'feature';
const features = 'features';
const memberManagement = 'memberManagement';
const community = 'community';
const petManagement = 'petManagement';
const marketplace = 'marketplace';
const memberServices = 'memberServices';
const businessServices = 'businessServices';
const entertainment = 'entertainment';
const education = 'education';
const communityServices = 'communityServices';
const listings = 'listings';
const petConsole = 'petConsole';

const Tab = {
  features,
  memberManagement,
  community,
  petManagement,
  marketplace,
  memberServices,
  businessServices,
  entertainment,
  education,
  communityServices,
  listings,
  petConsole,
} as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  features: { default: 'Members' },
  memberManagement: { default: '' },
  community: { default: '' },
  petManagement: { default: '' },
  marketplace: { default: '' },
  memberServices: { default: '' },
  businessServices: { default: '' },
  entertainment: { default: '' },
  education: { default: '' },
  communityServices: { default: '' },
  listings: { default: '' },
  petConsole: { default: '' },
};
const suffixFeaturesGotIt = 'FeaturesGotIt';

const options: BaseSliceOptions = {
  hasCitySliver: false,
  hasNewestSliver: false,
  hasDefaultProperties: false,
  tabs,
  sliceProperties: [{ suffix: suffixFeaturesGotIt, default: false }],
} as unknown as BaseSliceOptions;
const { actions, hooks, reducer } = new BaseEntitySlice({ sliceName, api: {} as Api, options });
const { tabHooks } = sliceHookTypes;

export interface OurFeatureHooks extends OurEntityHooks {
  tabHooks: TabHooks;
  customHooks: {
    featuresGotIt: SetSelectBooleanHooks;
  };
}

export const ourFeatureHooks: OurFeatureHooks = {
  entity: {} as ApiEntity,
  tabHooks: tabHooks(Object.keys(Tab), hooks) as TabHooks,
  customHooks: {
    featuresGotIt: {
      useSet: () => useOurDispatchValue(actions.setFeatureFeaturesGotIt as AnyAsyncThunk),
      useSelect: () => useOurSelectSliceProperty(sliceName, suffixFeaturesGotIt),
    },
  },
};

export default reducer;
