interface OurBackgroundColorProps {
  darkMode: boolean;
  darkAlpha?: number;
  lightAlpha?: number;
}

const ourBackgroundColor = ({ darkMode, darkAlpha = 0.09, lightAlpha = 0.23 }: OurBackgroundColorProps) => {
  return darkMode ? `rgba(255, 255, 255, ${darkAlpha})` : `rgba(0, 0, 0, ${lightAlpha})`;
};

export default ourBackgroundColor;