import { PropsWithChildren } from 'react';
import { CardContent, Collapse, Divider, List, Typography } from '@mui/material';

interface OurPostCardOptionsProps extends PropsWithChildren {
  isOpen?: boolean;
  aria?: string;
  topDivider?: boolean;
  bottomDivider?: boolean;
}

const OurPostCardOptions = ({
  isOpen,
  aria = 'options',
  topDivider = true,
  bottomDivider = true,
  children,
}: OurPostCardOptionsProps) => (
  <Collapse in={isOpen} timeout="auto" unmountOnExit>
    {topDivider && <Divider variant="middle" />}
    <CardContent sx={{ p: ({ spacing }) => spacing(1, 2, 0), '&:last-child': { pb: 0 } }}>
      <Typography gutterBottom align="center">
        <strong>Options</strong>
      </Typography>
      <List dense disablePadding component="nav" aria-label={aria}>
        {children}
      </List>
    </CardContent>
    {bottomDivider && <Divider variant="middle" />}
  </Collapse>
);

OurPostCardOptions.whyDidYouRender = true;

export default OurPostCardOptions;
