import { newId } from '@petconsole/pure-base';
import { EntityAttributes } from '../types';
import {
  initialAddressColumns,
  initialBusinessColumns,
  initialContactColumns,
  initialCrudColumns,
  initialMemberColumns,
  initialOwnerColumns,
  initialRatingColumns,
  initialReactionColumns,
  initialSiteColumns,
  initialSocialColumns,
  initialSubscribeColumns,
  initialSubscriptionColumns,
  initialTimestampColumns,
} from './constants';

const initialEntity = <T>(attributes: EntityAttributes, overrides: Partial<T> = {}) => {
  const { columns, idName, type } = attributes;

  return {
    type,
    [idName]: newId(),
    ...(columns.active && { active: true }),
    ...(columns.address && initialAddressColumns),
    ...(columns.business && initialBusinessColumns),
    ...((columns.business || columns.contact) && initialContactColumns),
    ...(columns.crud && initialCrudColumns),
    ...(columns.images && { images: [] }),
    ...(columns.json && { json: {} }),
    ...(columns.member && initialMemberColumns),
    ...(columns.owner && initialOwnerColumns),
    ...(columns.rating && initialRatingColumns),
    ...(columns.reaction && initialReactionColumns),
    ...(columns.site && initialSiteColumns),
    ...(columns.socials && initialSocialColumns),
    ...(columns.subscribe && initialSubscribeColumns),
    ...(columns.subscription && initialSubscriptionColumns),
    ...(columns.time && initialTimestampColumns),
    ...overrides,
  } as T;
};

export default initialEntity;
