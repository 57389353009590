import { useRef } from 'react';
import useOurIntersectionObserver from './useOurIntersectionObserver';

interface UseOurIsVisibleProps {
  visibleIfWas?: boolean;
}

const useOurIsVisible = ({ visibleIfWas = true }: UseOurIsVisibleProps = {}) => {
  const ref = useRef(null);
  const entry = useOurIntersectionObserver(ref, { freezeOnceVisible: visibleIfWas });
  const isVisible = !!entry?.isIntersecting;

  return { ref, isVisible };
};

export default useOurIsVisible;
