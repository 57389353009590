import { newId } from '@petconsole/pure-base';
import { mailMessageApiEntity, ourPutImages } from '@petconsole/pure-fe-api';
import { Image, Images, Log, MailMessageRecord } from '@petconsole/pure-shared';
import { writeReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick, ThunkApi } from '../../../types';

const { api, name: sliceName, proper } = mailMessageApiEntity;
const typePrefix = `${sliceName}/add${proper}`;

export const addMailMessagePayloadCreator = async (
  { images, message, log }: { images: Images, message: MailMessageRecord, log: Log },
  { rejectWithValue }: ThunkApi,
) => {
  const messageId = newId();
  const namedImages = images.map(({ s3Key, src, ...image }) => ({
    ...image,
    s3Key: s3Key.replace('%id%', messageId),
    src,
  } as Image));

  try {
    await ourPutImages({ images: namedImages, log });
  } catch (e) {
    // TODO: Delete and images that were saved
    // TODO: Capture savedImages and compare [{ key: '' }] to our namedImages s3Keys
    return rejectWithValue(e);
  }

  // Message copy of images does not contain source data
  return api.create({
    ...message,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    images: namedImages.map(({ src, ...image }) => image),
  });
};

export const addMailMessageThunk = appThunk(typePrefix, addMailMessagePayloadCreator);

export const addMailMessageReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(addMailMessageThunk, sliceName, adapter);
