import { MaybeString } from '@petconsole/pure-base';
import { memberApi, userApiEntity } from '@petconsole/pure-fe-api';
import { Image, MaybeMemberRecord, MemberRecord, ReturnsBoolean, UpdateHook } from '@petconsole/pure-shared';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { sliceHookTypes, useOurSelectAction, useOurSelectSliverProperty } from '../../../helpers';
import {
  BaseSliceOptions, CreateHook,
  FetchHook,
  MemberAvatar,
  OurEntityHooks,
  RecordType,
  RequiredCrudSliverHooks,
  SelectHook,
} from '../../../types';
import { afterUserFulfilled } from './afterUserFulfilled';

type ApiEntityRecord = MemberRecord;
export type UserExtraState = { member: MaybeMemberRecord; avatar: Image; lastFetch: MaybeString };

const apiEntity = userApiEntity;
const { name: sliceName } = apiEntity;
const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  sortProperty: 'memberName',
  selectId: (user = { memberId: '' } as ApiEntityRecord) => user.memberId as string,
  tabs: undefined,
  slivers: [
    {
      prefix: 'user',
      extraState: { member: null, avatar: {}, lastFetch: null } as UserExtraState,
      fetchById: false,
      payloadCreator: async () => memberApi.getCurrent(),
      payloadCreators: null,
    },
  ],
  afterCreate: afterUserFulfilled as BaseSliceOptions<ApiEntityRecord>['afterCreate'],
  afterUpdate: afterUserFulfilled as BaseSliceOptions<ApiEntityRecord>['afterUpdate'],
  afterFetch: afterUserFulfilled as BaseSliceOptions<ApiEntityRecord>['afterFetch'],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks } = sliceHookTypes;

export interface OurUserHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  userHooks: RequiredCrudSliverHooks;
  customHooks: {
    useCreateMemberByEmail: CreateHook;
    useUpdateCurrentMember: UpdateHook;
    useFetchCurrentMember: FetchHook;
    useAuthenticated: ReturnsBoolean;
    useAvatar: SelectHook<MemberAvatar>;
    useMember: () => MaybeMemberRecord;
    useEmail: SelectHook;
  };
}

export const ourUserHooks: OurUserHooks = {
  entity: apiEntity,
  userHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  customHooks: {
    useCreateMemberByEmail: hooks.useOurCreateUser as OurUserHooks['customHooks']['useCreateMemberByEmail'],
    useUpdateCurrentMember: hooks.usoOurUpdateUser as OurUserHooks['customHooks']['useUpdateCurrentMember'],
    useFetchCurrentMember: hooks.useOurFetchUser as OurUserHooks['customHooks']['useFetchCurrentMember'],
    useAuthenticated: () => useOurSelectAction((state: RecordType) => !!state.user.user.member) as boolean,
    useAvatar: () => useOurSelectSliverProperty(sliceName, sliceName, 'avatar') as MemberAvatar,
    useMember: () => useOurSelectSliverProperty(sliceName, sliceName, 'member') as MaybeMemberRecord,
    useEmail: () => useOurSelectSliverProperty(sliceName, sliceName, 'member.email'),
  },
};

export const { useCreate: useOurCreateMemberByEmail, useReset: useOurResetUserState } = ourUserHooks.userHooks;

export const { useAuthenticated: useOurSelectUserAuthenticated, useEmail: useOurSelectUserEmail } =
  ourUserHooks.customHooks;

export default reducer;
