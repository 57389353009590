import { OurTrashIcon } from '@petconsole/pure-fe-icon';
import { Icon } from '@petconsole/pure-shared';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurDeleteButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  tip?: string;
  Icon?: Icon;
}

const OurDeleteButton = ({ Icon, tip = 'Delete', ...rest }: OurDeleteButtonProps) => (
  <OurIconButton tip={tip} Icon={Icon || OurTrashIcon} {...rest} />
);

OurDeleteButton.whyDidYouRender = true;

export default OurDeleteButton;
