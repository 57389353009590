import OurLink from '../links/OurLink';
import OurHeadingChildren, { OurHeadingChildrenProps } from './OurHeadingChildren';

export interface OurHeadingLinkProps extends OurHeadingChildrenProps {
  linkTo?: string;
}

const OurHeadingLink = ({ linkTo, ...rest }: OurHeadingLinkProps) => (
  <OurLink to={linkTo as string} color="inherit" underline="none">
    <OurHeadingChildren {...rest} />
  </OurLink>
);

OurHeadingLink.whyDidYouRender = true;

export default OurHeadingLink;
