import { FormProvider } from 'react-hook-form';
import { Box, Card, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { RecordType } from '@petconsole/pure-base';
import OurBusyBackdrop from '../components/misc/OurBusyBackdrop';
import useMaintForm from './helpers/useMaintForm';
import MaintHeader from './MaintHeader';
import MaintTabs from './MaintTabs';
import MaintTopContent from './MaintTopContent';
import { MaintFormProps } from './types';

const MaintForm = <T extends RecordType = RecordType>({
  entity,
  hooks,
  padding,
  helpTitle,
  help,
  heading,
  add,
  Add,
  addProps,
  Main,
  mainOutline,
  mainLabel,
  mainSpacing,
  mainSx,
  mainProps,
  addressBreakpoints,
  directions,
  tabDefinitions,
  tabElements,
  tabNames,
  tabProps,
  setTabValue,
  tabWidth,
  tabScroll,
  editMessages,
  saveButton,
  ...rest
}: MaintFormProps<T>) => {
  const formProps = { entity, hooks, addressBreakpoints, saveButton, ...rest };
  const formResult = useMaintForm<T>(formProps);

  const { form, id, data, readOnly, isOwned, isOwner, isGuest, isEditing, canEdit, onEdit } = formResult; // setEditing
  const { onPrev, onNext, prevId, nextId, reRead, submitting, setSubmitting, onCancel, onSave, busy } = formResult; // setBusy
  const { Address, addressValue, setAddressValue, avatar, setAvatar, setAvatarChanged, setAvatarEditor } = formResult;
  const { banner, setBanner, images, Photos, tabValue, tabHooks } = formResult; // imageZone,

  const headerProps1 = { entity, hooks, canEdit, onEdit, isEditing, isOwner, isGuest, helpTitle, help, heading, data };
  const headerProps2 = { add, Add, addProps, onPrev: onPrev ?? null, onNext: onNext ?? null, prevId, nextId };
  const headerProps = { ...headerProps1, ...headerProps2, onCancel, onSave, saveButton };

  const topProps1 = { entity, form, id, data, readOnly, isOwned, isEditing, Address, addressValue, setAddressValue };
  const topProps2 = { Main, mainOutline, mainLabel, mainSpacing, mainSx, mainProps, addressBreakpoints, directions };
  const topProps = { ...topProps1, ...topProps2, editMessages, submitting, setSubmitting };

  const tabsProps1 = { entity, hooks, form, id, data, readOnly, canEdit, isEditing, isOwner, isGuest, reRead };
  const tabsProps2 = { tabWidth, tabScroll, tabNames, tabElements, tabDefinitions, tabProps, tabValue, setTabValue };
  const tabsProps3 = { avatar, setAvatar, setAvatarChanged, setAvatarEditor, banner, setBanner, images, Photos };
  const tabsProps = { ...tabsProps1, ...tabsProps2, tabHooks, ...tabsProps3 };

  return (
    <Grid item id="maintenance-content" width="100%" /* {...(breakpoints && breakpoints)} */>
      <Card raised id="maintenance-content-card">
        <Grid container padding={padding ?? 1}>
          <MaintHeader<T> {...headerProps} />
          <Box display="flex" flexDirection="column" width="100%">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormProvider {...form}>
                <form onSubmit={onSave}>
                  <MaintTopContent {...topProps} />
                  <MaintTabs<T> {...tabsProps} />
                </form>
                <OurBusyBackdrop busy={Boolean(busy)} />
              </FormProvider>
            </LocalizationProvider>
          </Box>
        </Grid>
      </Card>
    </Grid>
  );
};

MaintForm.whyDidYouRender = true;

export default MaintForm;
