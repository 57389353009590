import setPropertyReducers from "./setPropertyReducers";

const citySearchReducers = (prefix: string) =>
  setPropertyReducers([
    `${prefix}CitySearchInput`,
    `${prefix}CitySearchDefaulted`,
    `${prefix}CitySearchLocation`,
    `${prefix}CityChangeLocation`,
  ]);

export default citySearchReducers;
