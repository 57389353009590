import useOurError from '../error/useOurError';
import useOurSnacks from './useOurSnacks';
import { SetState } from '@petconsole/pure-shared';

export interface WorkProps {
  activity?: string;
  tryCallback: () => unknown;
  catchCallback?: (e: unknown) => unknown;
  finallyCallback?: () => unknown;
  setBusy?: SetState<boolean>;
}

const useOurWork = () => {
  const { log } = useOurError();
  const { warn } = useOurSnacks();

  const work = async ({ activity = '', tryCallback, catchCallback, finallyCallback, setBusy }: WorkProps) => {
    const defaultCatch = (e: unknown) => {
      const message = e instanceof Error ? e.message : '';
      warn(`${activity} failed: ${message}.`);
      log(`${activity.toLowerCase()}: ${message}`, e).then();
    };

    if (setBusy) setBusy(true);

    try {
      return await tryCallback();
    } catch (e) {
      if (catchCallback) catchCallback(e);
      else if (activity) defaultCatch(e);
    } finally {
      if (finallyCallback) finallyCallback();
      if (setBusy) setBusy(false);
    }

    return undefined;
  };

  return { work };
};

export default useOurWork;
