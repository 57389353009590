import { skipToken } from '@reduxjs/toolkit/query';
// import { memberApiEntity } from '@petconsole/pure-fe-api';
// import { MemberRecord } from '@petconsole/pure-shared';
import { useGetMemberByNameQuery, useGetMemberQuery } from '../../../apiSlice';

// const apiEntity = memberApiEntity;
// type ApiRecord = MemberRecord;

export const useGetMember = ({ memberId }: { memberId: string }) => useGetMemberQuery(memberId || skipToken);
export const useGetMemberByName = (memberName: string) => useGetMemberByNameQuery(memberName || skipToken);
