import { RecordType } from '@petconsole/pure-base';
import { Api, EntityName, entityReaction } from '@petconsole/pure-shared';
import { SliceThunk, ThunkRecordFromRecord } from '../../types';
import { useOurDispatchById, useOurDispatchEntity, useOurSelectById } from '../hooks';
import { useDispatchReactToEntity } from '../hooks/useDispatchReactToEntity';
import { useOurToggleLiked } from '../reactions';
import { reactionSelector } from '../selectors';

export const sliceReactionHooks = <T extends RecordType = RecordType>(sliceName: string, entityType: string, thunks: SliceThunk, api: Api<T>) => {
  const { fetchReaction, updateReaction, toggleLike, reactToEntity } = thunks;
  const hooks: RecordType = {};

  hooks[`useOurSelect${entityType}Reaction`] = (id: string) =>
    useOurSelectById(reactionSelector(sliceName, sliceName), id);

  if (fetchReaction) {
    hooks[`useOurFetch${entityType}Reaction`] = () => useOurDispatchById(sliceName, fetchReaction as ThunkRecordFromRecord);
  }

  if (updateReaction)
    hooks[`useOurUpdate${entityType}Reaction`] = () => useOurDispatchEntity('reaction', updateReaction);

  if (toggleLike)
    hooks[`useOurToggleLike${entityType}`] = () => useOurToggleLiked<T>({ entityName: sliceName as EntityName, api });

  if (reactToEntity) {
    hooks[`useOurCommentedOn${entityType}`] = () => useDispatchReactToEntity(sliceName, api, entityReaction.commented);
    hooks[`useOurSent${entityType}`] = () => useDispatchReactToEntity(sliceName, api, entityReaction.sent);
    hooks[`useOurShared${entityType}`] = () => useDispatchReactToEntity(sliceName, api, entityReaction.shared);
  }

  return hooks;
};
