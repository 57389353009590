import { AppBar, Toolbar } from '@mui/material';
import { ourAppBorderWidth } from '../../../constants';
import useOurWidth from '../../../hooks/useOurWidth';
import OurAppNameBox from './OurAppNameBox';

const OurAuthApp = () => {
  const width = useOurWidth();

  return (
    <AppBar component="div"
      position="fixed"
      // width="100%"
      enableColorOnDark
      sx={{ borderBottom: ({ palette }) => `solid ${ourAppBorderWidth} ${palette.secondary.main}` }}
    >
      <Toolbar variant="dense" sx={{ px: width === 'xs' ? 1 : 2 }}>
        <OurAppNameBox />
      </Toolbar>
    </AppBar>
  );
};

export default OurAuthApp;
