import { RecordType } from '@petconsole/pure-base';
import { ActionProps, StateType } from '../../types';

const thunkReducerValues = (
  state: StateType,
  property: string,
  action: ActionProps,
  id?: string,
) => {
  // In the case of reading and updating ratings for an entity, we expect to have a "rating" object, and the id property
  // is the entity id, ie: if we are dealing with the ratings for a vet, the id property will be vetId
  const isRating = property && state[property]?.rating && id === `${property}Id`;

  // In the case of reading and updating ratings for an entity, our target piece of state is the rating object. In other
  // cases, we will look to "property" to determine if we want to target a specific property, or the state itself
  const target = isRating ? state[property].rating : property ? state[property] : state;

  // In the case of reading and updating ratings for an entity, or, we have an id property specified, we'll need the
  // value of the id to be used to further segregate the target state
  const indexId = action?.meta?.arg?.[id ? id : `${property}Id`];

  // In the case of processing a list of entities, there may be a nextKey which will tell us if there is more data to be
  // retrieved, and if so, where to start reading from
  const payload  = typeof action?.payload === 'object' ? <RecordType>(action.payload) : null;
  const nextKey = payload?.items ? payload.nextKey : null;

  return { target, id: indexId, payload: action?.payload, nextKey, error: action?.error };
};

export default thunkReducerValues;
