import { skipToken } from '@reduxjs/toolkit/query';
import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { ApiEntity, ApiUseGetQuery } from '@petconsole/pure-shared';

interface UseGetEntityByProps<T extends RecordType = RecordType> {
  apiEntity: ApiEntity<T>;
  id: string | typeof skipToken;
  queryHook: ApiUseGetQuery<T>;
}

const useGetEntityBy = <T extends RecordType = RecordType>({ apiEntity, id, queryHook }: UseGetEntityByProps<T>) => {
  const queryResults = queryHook(id || skipToken);

  return { [apiEntity.name]: queryResults.data as MaybeNull<T> | undefined, ...queryResults } as ReturnType<ApiUseGetQuery>;
};

export default useGetEntityBy;
