import React, { useCallback } from 'react';
import { CityLocation, RecordType } from '@petconsole/pure-base';
import { FetchByHook, ResetSliverHook } from '@petconsole/pure-fe-state';
import { ourLocationToCity } from '@petconsole/pure-fe-utils';
import { TabProps } from '../types';

interface UseOurEntityRefreshProps {
  tabProps: React.MutableRefObject<RecordType>;
  tabValue: string;
  cityTab?: string;
  city?: string;
  location?: CityLocation;
  reset?: ReturnType<ResetSliverHook>;
  fetch?: ReturnType<FetchByHook>;
}

const useOurEntityRefresh = ({
  tabProps,
  tabValue,
  cityTab,
  city,
  location,
  reset,
  fetch,
}: UseOurEntityRefreshProps) => {
  const onRefresh = useCallback(() => {
    if (tabProps) {
      // If we are handling tabs, reset and fetch is found in the tab information
      const { reset: resetTab, fetch: fetchTab, fetchLocation } = (tabProps.current[tabValue] || {}) as TabProps;

      if (resetTab) resetTab();
      if (fetchTab)
        if (tabValue === cityTab || fetchLocation) {
          if (location) fetchTab(location).then();
        } else fetchTab({}).then();
    } else {
      // If we aren't handling tabs, we would assume reset and fetch are provided
      if (reset) reset();
      if (fetch) fetch(location || {}).then();
    }
  }, [tabProps, tabValue, cityTab, reset, fetch, location]);

  const canRefresh = useCallback(() => {
    const canRefreshCity = location && ourLocationToCity(location) === city;

    if (!tabProps) return canRefreshCity;

    const { refreshOk } = (tabProps.current[tabValue] || {}) as TabProps;

    if (tabValue === cityTab) return canRefreshCity;

    if (refreshOk == null) return true;

    return refreshOk;
  }, [tabProps, tabValue, cityTab, city, location]);

  return { canRefresh, onRefresh };
};

export default useOurEntityRefresh;
