import { useEffect, useState } from 'react';
import { Avatar, Grid, Skeleton, Typography } from '@mui/material';
import { memberApi } from '@petconsole/pure-fe-api';
import { ourIsoToWeekDisplayDateTime } from '@petconsole/pure-fe-utils';
import { CommentRecord, MemberRecord } from '@petconsole/pure-shared';
import useOurAuthenticated from '../auth/useOurAuthenticated';
import OurMemberAvatar from '../components/avatar/OurMemberAvatar';
import OurTextLines from '../components/misc/OurTextLines';
import useOurPhone from '../hooks/useOurPhone';
import AddComment from './AddComment';
import { SaveCommentProps } from './helpers/useSaveComment';

interface CommentItemProps {
  comment: CommentRecord;
  locked?: boolean;
  saveComment: ({ threadId, comment }: SaveCommentProps) => Promise<CommentRecord>;
}

const CommentItem = ({ comment, locked, saveComment }: CommentItemProps) => {
  const { authenticated } = useOurAuthenticated();
  const { portraitPhone } = useOurPhone();
  const { threadId, replyId, text, editedAt, createdAt } = comment;

  const [isAdding, setIsAdding] = useState(false);
  const [commentBy, setCommentBy] = useState('Member');

  useEffect(() => {
    if (!comment || !authenticated) return;

    let mounted = true;

    memberApi.get(comment.memberId).then((member) => {
      if (!mounted) return;

      setCommentBy((member as MemberRecord)?.memberName as string);
    });

    return function cleanUp() {
      mounted = false;
    };
  }, [comment, authenticated]);

  // const onAuthor = null;

  const onReply = () => setIsAdding(!isAdding);

  const authorComment = `${editedAt ? 'Edited ' : ''}${ourIsoToWeekDisplayDateTime(editedAt || createdAt || '')}`;
  const avatarAdjust = portraitPhone ? 1 : 0;
  const replyAdjust = replyId ? 1 : 0;

  return (
    <Grid container id="comment-item-container">
      {replyId && <Grid item xs={1} />}
      <Grid item xs={avatarAdjust + 1}>
        {commentBy ? (
          commentBy === 'Member' ? (
            <Avatar alt="?" />
          ) : (
            <OurMemberAvatar name={commentBy} sx={{ width: 36, height: 36 }} />
          )
        ) : (
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        )}
      </Grid>
      <Grid item xs={11 - replyAdjust - avatarAdjust}>
        <Grid container px={1}>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color={/* null ? 'primary' : */ 'inherit'}
              component="span"
              // {...(onAuthor && !locked ? { onclick: onAuthor } : {})}
            >
              {commentBy}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="span">
              {` ${authorComment}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="inherit">
              <OurTextLines text={text} />
            </Typography>
          </Grid>
          {!locked && (
            <Grid item xs={12}>
              <Typography variant="body2" color="primary" component="span" onClick={onReply} sx={{ cursor: 'pointer' }}>
                Reply
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isAdding && (
        <AddComment
          reply={!!threadId}
          threadId={threadId}
          locked={locked}
          saveComment={saveComment}
          setIsAdding={setIsAdding}
        />
      )}
    </Grid>
  );
};

CommentItem.whyDidYouRender = true;

export default CommentItem;
