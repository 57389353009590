import { createSlice } from '@reduxjs/toolkit';
import { setPropertyReducer } from '../../../helpers';
import slicePropertyHooks from '../../../helpers/slices/slicePropertyHooks';

const slice = {
  name: 'adminMenu',
};

const initialState = {
  adminMenuBusConsoleMenuOpen: false,
  adminMenuPetConsoleMenuOpen: false,
  adminMenuVanConsoleMenuOpen: false,
  adminMenuPclMenuOpen: false,
};

export const adminMenuSlice = createSlice({
  name: slice.name,
  initialState,
  reducers: {
    setAdminMenuBusConsoleMenuOpen: setPropertyReducer('adminMenuBusConsoleMenuOpen'),
    setAdminMenuPetConsoleMenuOpen: setPropertyReducer('adminMenuPetConsoleMenuOpen'),
    setAdminMenuVanConsoleMenuOpen: setPropertyReducer('adminMenuVanConsoleMenuOpen'),
    setAdminMenuPclMenuOpen: setPropertyReducer('adminMenuPclMenuOpen'),
  },
});

const { actions } = adminMenuSlice;

export const { useOurSelectAdminMenuBusConsoleMenuOpen, useOurSetAdminMenuBusConsoleMenuOpen } =
  slicePropertyHooks(slice.name, 'adminMenuBusConsoleMenuOpen', actions);
export const { useOurSelectAdminMenuPetConsoleMenuOpen, useOurSetAdminMenuPetConsoleMenuOpen } =
  slicePropertyHooks(slice.name, 'adminMenuPetConsoleMenuOpen', actions);
export const { useOurSelectAdminMenuVanConsoleMenuOpen, useOurSetAdminMenuVanConsoleMenuOpen } =
  slicePropertyHooks(slice.name, 'adminMenuVanConsoleMenuOpen', actions);
export const { useOurSelectAdminMenuPclMenuOpen, useOurSetAdminMenuPclMenuOpen } =
  slicePropertyHooks(slice.name, 'adminMenuPclMenuOpen', actions);

export default adminMenuSlice.reducer;
