import { MouseEvent } from 'react';
import { Grid } from '@mui/material';
import { Images } from '@petconsole/pure-shared';
import OurRemovableImage from './OurRemovableImage';

interface OurImagesProps {
  images?: Images;
  canRemove?: boolean;
  onRemove: (e: MouseEvent, s3Key: string) => void;
}

const OurImages = ({ images, canRemove, onRemove }: OurImagesProps) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {images?.map(({ src, title, s3Key }) => (
          <Grid item key={s3Key} xs={12} sm={6} md={3}>
            <OurRemovableImage src={src} alt={title} disabled={!canRemove} onRemove={(e) => onRemove(e, s3Key)} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

OurImages.whyDidYouRender = true;

export default OurImages;
