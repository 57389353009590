import { useMemo, useRef } from 'react';
import { EditMessages, editMessageType } from '@petconsole/pure-shared';
import sortByEditMessageType from './sortByEditMessageType';

interface UseEditMessagesProps {
  isEditing: boolean;
  isDirty: boolean;
  isSaving: boolean;
  messages?: EditMessages;
}

const useEditMessages = ({ /* isEditing, */ isDirty, isSaving, messages }: UseEditMessagesProps) => {
  const warning = editMessageType.warning;
  const emptyMessages = useMemo(() => [], []);
  const unsavedChanges = useMemo(() => ({ type: warning, message: 'You have unsaved changes' }), [warning]);
  const unsavedMessages = useMemo(() => [unsavedChanges], [unsavedChanges]);
  const savedMessages = useRef<EditMessages>();

  if (/* !isEditing || */ !isDirty) return { editMessages: emptyMessages };

  if (!messages || messages.length === 0) return { editMessages: isSaving ? emptyMessages : unsavedMessages };

  const tempMessages = messages.slice(0);

  const sortedMessages = sortByEditMessageType(isSaving ? tempMessages : tempMessages.concat(unsavedChanges));

  if (!savedMessages.current || JSON.stringify(sortedMessages) !== JSON.stringify(savedMessages.current))
    savedMessages.current = sortedMessages;

  return { editMessages: savedMessages.current };
};

export default useEditMessages;
