import React, { ElementType, MouseEventHandler, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { newId, RecordType } from '@petconsole/pure-base';
import { CreateEntity, Images } from '@petconsole/pure-shared';
import { OurMarkdownHelp } from '../dialogs';
import OurFilmHeading from '../headings/ourFilmHeading/OurFilmHeading';
import OurBackHeadings from '../headings/OurBackHeadings';
import OurCenterImage from '../images/OurCenterImage';
import OurAddEntity from './OurAddEntity';
import OurToolbar from './OurToolbar';

interface OurEntityToolbarProps {
  heading: ReactNode;
  helpTitle?: string;
  helpContent?: string;
  pclHeader?: RecordType;
  image?: string;
  imageWidth?: string;
  alt?: string;
  phone?: boolean;
  addDisabled?: boolean;
  onRefresh?: MouseEventHandler;
  refreshDisabled?: boolean;
  AddEntity?: ElementType;
  addEntity?: string;
  addProperty?: string;
  addInstructions?: string;
  addLabel?: string;
  addAddress?: boolean;
  addPhone?: boolean;
  addDefaultData?: RecordType;
  addPath?: string;
  create?: CreateEntity;
}

const OurEntityToolbar = ({
  heading,
  helpTitle,
  helpContent,
  pclHeader,
  image,
  imageWidth,
  alt,
  phone,
  addDisabled,
  onRefresh,
  refreshDisabled,
  AddEntity,
  addEntity,
  addProperty,
  addInstructions,
  addLabel,
  addAddress = false,
  addPhone = false,
  addDefaultData,
  addPath,
  create,
}: OurEntityToolbarProps) => {
  const navigate = useNavigate();

  const [addIsOpen, setAddIsOpen] = useState(false);

  const { topText = '', bottomText = '', images = [] } = pclHeader || {};

  const onAdd = () => {
    if (AddEntity || create) {
      setAddIsOpen(true);
      return;
    }

    // eslint-disable-next-line no-template-curly-in-string
    navigate(addPath ? addPath.replace('${id}', newId()) : '');
  };

  return (
    <>
      <OurBackHeadings
        heading={heading}
        help={OurMarkdownHelp}
        helpProps={{ title: helpTitle || `${heading} Help`, content: helpContent }}
        right={
          <OurToolbar
            onAdd={AddEntity || addPath ? onAdd : undefined}
            addDisabled={addDisabled}
            onRefresh={onRefresh}
            refreshDisabled={refreshDisabled}
            phone={phone}
          />
        }
      />
      {AddEntity && <AddEntity isOpen={addIsOpen} setIsOpen={setAddIsOpen} />}
      {create && (
        <OurAddEntity
          entity={addEntity as string}
          isOpen={addIsOpen}
          setIsOpen={setAddIsOpen}
          path={addPath}
          instructions={addInstructions}
          label={addLabel}
          property={addProperty}
          addAddress={addAddress}
          addPhone={addPhone}
          defaultData={addDefaultData}
          create={create}
        />
      )}
      {pclHeader && (
        <OurFilmHeading topText={topText as string} bottomText={bottomText as string} images={images as Images} />
      )}
      {image && <OurCenterImage image={image} alt={alt || 'page image'} width={imageWidth} />}
    </>
  );
};

OurEntityToolbar.whyDidYouRender = true;

export default OurEntityToolbar;
