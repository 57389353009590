import { shopItemOptionTemplateApiEntity } from '@petconsole/pure-fe-api';
import { ShopItemOptionTemplateRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, getListBy, getOne, updateOne } from '../../../helpers';

type ApiRecord = ShopItemOptionTemplateRecord;

const shopItemOptionTemplateEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = shopItemOptionTemplateApiEntity;

  return {
    createShopItemOptionTemplate: createOne(builder, api, tag),
    getShopItemOptionTemplate: getOne(builder, api, tag),
    getShopItemOptionTemplatesByShop: getListBy(builder, api.getListByShop, tag),
    updateShopItemOptionTemplate: updateOne(builder, api.update, tag),
  };
};

export default shopItemOptionTemplateEndpoints;
