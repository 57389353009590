import { useOurRoutes } from '@petconsole/pure-fe-mui';
import { webPath } from '@petconsole/pure-shared';
import { MyShopBilling } from '../myShopBilling';
import { MyShopCustomers } from '../myShopCustomers';
import { MyShopItem, MyShopItems } from '../myShopItem';
import { MyShopOrder } from '../myShopOrder';
import { MyShopOrders } from '../myShopOrders';
import { MyShopPayments } from '../myShopPayments';
import { MyShopReports } from '../myShopReports';
import { MyShopReturns } from '../myShopReturns';
import { MyShopRewards } from '../myShopRewards';
import { MyShopSettings } from '../myShopSettings';
import { MyShopShipping } from '../myShopShipping';
import { MyShopShop } from '../myShopShop';
import { MyShopStaff } from '../myShopStaff';
import { MyShopStripe } from '../myShopStripe';
import { MyShopVariantTemplate } from '../myShopVariantTemplate';
import { MyShopVariantTemplates } from '../myShopVariantTemplates';
import { MyShop } from '../myShop';
import { MyShops } from '../myShops';

const useMyShopRoutes = () => {
  const { authRoute } = useOurRoutes();

  return [
    authRoute(`${webPath.myShops}`, MyShops),
    authRoute(`${webPath.myShop}/:id`, MyShop),
    authRoute(`${webPath.myShopBilling}/:id`, MyShopBilling),
    authRoute(`${webPath.myShopCustomers}/:id`, MyShopCustomers),
    authRoute(`${webPath.myShopItem}/:id1/:id2`, MyShopItem),
    authRoute(`${webPath.myShopItems}/:id`, MyShopItems),
    authRoute(`${webPath.myShopOrder}/:id1/:id2`, MyShopOrder),
    authRoute(`${webPath.myShopOrders}/:id`, MyShopOrders),
    authRoute(`${webPath.myShopPayments}/:id`, MyShopPayments),
    authRoute(`${webPath.myShopReports}/:id`, MyShopReports),
    authRoute(`${webPath.myShopReturns}/:id`, MyShopReturns),
    authRoute(`${webPath.myShopRewards}/:id`, MyShopRewards),
    authRoute(`${webPath.myShopSettings}/:id`, MyShopSettings),
    authRoute(`${webPath.myShopShipping}/:id`, MyShopShipping),
    authRoute(`${webPath.myShopShop}/:id`, MyShopShop),
    authRoute(`${webPath.myShopStaff}/:id`, MyShopStaff),
    authRoute(`${webPath.myShopStripe}/:id`, MyShopStripe),
    authRoute(`${webPath.myShopVariantTemplate}/:id1/:id2`, MyShopVariantTemplate),
    authRoute(`${webPath.myShopVariantTemplates}/:id`, MyShopVariantTemplates),
    // authRoute(paths.myShops, MyShops),
    // authRoute(`${paths.myShops}/:id`, MyShop),
  ];
};

export default useMyShopRoutes;
