import { RecordType } from '@petconsole/pure-base';
import { ApiEntity } from '@petconsole/pure-shared';
import { commonApi } from '../api/common';

const entity = <T extends RecordType = RecordType>(props: Partial<ApiEntity<T>>): ApiEntity<T> => {
  const { api, apiPath, plural } = props;

  return { ...props, api: api || commonApi<T>({ apiPath: apiPath || `/${plural}` }) } as ApiEntity<T>;
};

export default entity;