import { RecordType } from '@petconsole/pure-base';
import { Slice } from '../../types';
import { initialRatingState, initialReactionState } from '../sliceHelpers';
import sliverHelpers from '../sliverHelpers';

const sliceUpdateSlivers = <T extends RecordType = RecordType>(slice: Slice<T>) => {
  const { idName, flag, option } = slice;
  const { hasRating, hasReaction } = flag;
  const slivers = { ...slice.sliver };

  const entitySliver = slice.sliver[slice.slivers[0]];

  entitySliver.defaultState = {
    ...entitySliver.defaultState,
    ...(hasRating && initialRatingState),
    ...(hasReaction && initialReactionState),
  };

  slice.slivers.forEach((sliverName) => {
    const { comparer, defaultState, payloadCreator, payloadCreators } = slivers[sliverName];

    slivers[sliverName] = {
      ...slivers[sliverName],
      ...sliverHelpers<T>(
        slice,
        sliverName,
        option.selectId || idName,
        comparer,
        defaultState,
        payloadCreator ? <(props?: unknown) => Promise<unknown>>payloadCreator : undefined,
        payloadCreators ? <(props?: unknown) => Promise<unknown>>payloadCreators : undefined,
      ),
    };
  });

  return slivers;
};

export default sliceUpdateSlivers;
