// noinspection ES6PreferShortImport

import { MaybeNull, RecordType, newId } from '@petconsole/pure-base';
import { ourDefaultCurrencyCode, site } from '../../../constants';
import { defaultShipTo, entityCategory, entityName } from '../../../entity/constants';
import entityAttributes from '../../../entity/entityAttributes';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity, MemberColumns, OurCurrencyCode, ShipTo } from '../../../types';
import { TaxItems } from '../taxItem';

export interface Cart extends CrudColumns, MemberColumns {
  type: DataEntityType;
  cartId: string;
  shopId: string;
  shopName: string;
  currency: OurCurrencyCode;
  appRate: number;
  appFee: number;
  shipRates: {
    free: boolean;
    minimum: number;
    base: number;
    item: number;
  };
  shipTo: ShipTo;
  paymentCustomer: string; // paymentCustomer s/b assigned once per shop & member, and copied after that
  orderReference: string;
  empty: boolean;
  quantity: number;
  shipping: number;
  subtotal: number;
  taxes: TaxItems; // { amount, description }
  tax: number;
  total: number;
  checkout: string;
  json: RecordType;
}

export const cartEntity = entityAttributes({
  name: entityName.cart,
  attributes: {
    category: entityCategory.item,
    nameField: 'shopName',
    memberOwned: true,
    site: site.busConsole,
    columns: { images: false, member: true },
  },
});

const { array, boolean, currency, id, json, number, schemaObject, strim } = schema;
export const cartSchema = entitySchema<Cart>(cartEntity, {
  shopId: id(true),
  shopName: strim(),
  currency: currency(),
  appRate: number(),
  appFee: number(),
  shipRates: schemaObject({
    free: boolean(),
    minimum: number(),
    base: number(),
    item: number(),
  }),
  shipTo: json().default({}),
  paymentCustomer: strim(),
  orderReference: id(true),
  empty: boolean(),
  quantity: number(),
  shipping: number(),
  subtotal: number(),
  taxes: array(), // { amount, description }
  tax: number(),
  total: number(),
  checkout: strim(),
});

export const cartDefaults: Partial<Cart> = {
  shopId: '',
  shopName: '',
  currency: ourDefaultCurrencyCode,
  appRate: 0,
  appFee: 0,
  shipRates: {
    free: false,
    minimum: 0,
    base: 0,
    item: 0,
  },
  shipTo: defaultShipTo(),
  paymentCustomer: '',
  orderReference: newId(),
  empty: false,
  quantity: 0,
  shipping: 0,
  subtotal: 0,
  taxes: [], // { amount, description }
  tax: 0,
  total: 0,
  checkout: '',
};

export interface CartRecord extends Cart, RecordType {}

export type MaybeCartRecord = MaybeNull<CartRecord>;
export type CartRecords = CartRecord[];

export const initialCart: InitialEntity<Cart, CartRecord> = (overrides = {}) =>
  initialEntity<Cart>(cartEntity, { ...cartDefaults, ...overrides }) as CartRecord;
