import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  ForumRecord,
  MaybeForumRecord,
  forumEntity,
  GetListOptions,
  GetListResults,
  ApiEntity,
} from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = ForumRecord;

const apiPath = '/forums';
const { getByValue, getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getByHierarchy: (hierarchy: string) => Promise<MaybeForumRecord>;
  getListByForum: (forumId: string, options: GetListOptions) => Promise<GetListResults<T>>;
  getListByHierarchy: (hierarchy: string, options: GetListOptions) => Promise<GetListResults<T>>;
}

export type ForumApi = Api<ApiRecord> & CustomApi;

export const forumApi: ForumApi = {
  ...common,
  getByValue,
  getListByValue,
  getByHierarchy: (hierarchy) => getByValue('hierarchy', hierarchy) as Promise<MaybeForumRecord>,
  getListByForum: (forumId, options) => getListByValue('forumId', forumId, options),
  getListByHierarchy: (hierarchy, options) => getListByValue('hierarchy', hierarchy, options),
};

export const forumApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...forumEntity, api: forumApi };
