import { ReactElement } from 'react';

interface OurPhotoZoneViewerProps {
  photoZone: ReactElement;
}

const OurPhotoZoneViewer = ({ photoZone }: OurPhotoZoneViewerProps) => photoZone;

OurPhotoZoneViewer.whyDidYouRender = true;

export default OurPhotoZoneViewer;
