import { isMobile } from 'react-device-detect';

interface PressOrClickProps {
  lower?: boolean;
}

const useOurPressOrClick = () => {
  const pressOrClick = ({ lower }: PressOrClickProps = {}) =>
    isMobile ? (lower ? 'press' : 'Press') : lower ? 'click' : 'Click';

  return { pressOrClick };
};

export default useOurPressOrClick;
