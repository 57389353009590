import { useCallback } from 'react';
import { RecordType } from '@petconsole/pure-base';
import { useAppDispatch } from '../../store/storeHooks';
import { ThunkRecordFromString } from '../../types';

export type DispatchThunkRecordFromStringHook = () => ReturnType<
  typeof useDispatchThunkRecordFromString
>;

export const useDispatchThunkRecordFromString = <T extends RecordType = RecordType>(action: ThunkRecordFromString<T>) => {
  const dispatch = useAppDispatch();
  const callback = (value: string) => dispatch(action(value));

  return useCallback(callback, []);
};
