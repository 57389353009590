// noinspection ES6PreferShortImport

import { MouseEvent } from 'react';
import { camelCase } from '@petconsole/pure-base';
import { ExtendedFormik, ourImageContentType, ourImageKey } from '@petconsole/pure-shared';
import { OurAvatarDialogOnOkProps } from '../dialogs/OurAvatarDialog';

export type OurThumbnailOnOk = (
  e: MouseEvent<HTMLButtonElement>,
  src: string,
  contentType: string,
  changed: boolean,
) => void;

interface UseOurThumbnailOkProps {
  formik?: ExtendedFormik;
  id?: string;
  idName?: string;
  title?: string;
  onOk?: OurThumbnailOnOk;
}

const useOurThumbnailOk = ({ formik, id, idName, title, onOk }: UseOurThumbnailOkProps) => {
  const { values = {}, getFieldProps, setFieldValue } = formik || {};
  const entityIdName = idName || `${camelCase(values.type)}Id`;
  const entityName = entityIdName?.replace(/Id$/, '');
  const entityId = idName ? values[idName] : values[entityIdName] || 'id';
  const thumbnail = getFieldProps ? getFieldProps(id || '').value : undefined;

  const onThumbnailOk = ({ e, src, changed} : OurAvatarDialogOnOkProps) => {
    const contentType = ourImageContentType({ src });

    if (onOk) return onOk(e, src, contentType, changed);

    if (!changed || !setFieldValue) return;

    setFieldValue(id || 'thumbnail', {
      ...thumbnail,
      src,
      contentType,
      title: title || thumbnail?.title || `${values.type} Image`,
      s3Key: ourImageKey(entityName)({ type: entityName, id: entityId, src }),
      changed: true,
    });
  };

  return { onThumbnailOk };
};

export default useOurThumbnailOk;
