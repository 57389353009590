import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecordType } from '@petconsole/pure-base';
import { CreateEntity, EntityRecord, IsOpenProps, SetState } from '@petconsole/pure-shared';
import OurAddEntityDialog, { OurAddEntityOtherInputs } from '../dialogs/OurAddEntityDialog';

export interface OurAddEntityProps<T extends EntityRecord = EntityRecord> extends IsOpenProps {
  entity: string;
  setEntityId?: SetState<string>;
  path?: string;
  title?: string;
  instructions?: string;
  label?: string;
  property?: string | null;
  addAddress?: boolean;
  addressLabel?: string;
  addPhone?: boolean;
  initialValues?: T;
  otherInputs?: OurAddEntityOtherInputs;
  otherValidation?: RecordType;
  defaultData?: Partial<T>;
  create: CreateEntity;
}

const OurAddEntity = <T extends EntityRecord = EntityRecord>({
  entity,
  isOpen,
  setIsOpen,
  setEntityId,
  path = '', // Supply root, ie: /list/sitters, or with id variable, ie: /list/sitters/${id}
  title,
  instructions,
  label,
  property,
  addAddress = false,
  addressLabel,
  addPhone = false,
  initialValues,
  otherValidation,
  otherInputs,
  defaultData,
  create,
}: OurAddEntityProps<T>) => {
  const navigate = useNavigate();

  // If a new entity is added, OurAddEntityDialog calls setAddedId with the new id
  const [addedId, setAddedId] = useState('');

  // If we get a new id, we'll navigate to the new entity
  useEffect(() => {
    if (!addedId) return;

    const pathId = addedId;

    setIsOpen(() => false); // Don't re-render
    setAddedId(() => ''); // Don't re-render

    // eslint-disable-next-line no-template-curly-in-string
    if (path.includes('${id}')) navigate(path.replace('${id}', pathId));
    else if (setEntityId) setEntityId(pathId);
    else navigate(`${path}/${pathId}`);
  }, [navigate, path, setIsOpen, setEntityId, addedId]);

  return (
    <OurAddEntityDialog
      entity={entity}
      title={title}
      instructions={instructions}
      label={label}
      inputProperty={property}
      addAddress={addAddress}
      addressLabel={addressLabel}
      addPhone={addPhone}
      initialValues={initialValues}
      otherInputs={otherInputs}
      otherValidation={otherValidation}
      {...(defaultData && { defaultData })}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setAddedId={setAddedId}
      create={create}
    />
  );
};

OurAddEntity.whyDidYouRender = true;

export default OurAddEntity;
