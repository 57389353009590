// noinspection ES6PreferShortImport

import { MaybeBoolean, MaybeNull, MaybeNumber, MaybeString, RecordType } from '@petconsole/pure-base';
import { genderType, memberEvent } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { AddressColumns, DataEntityType, Images, InitialEntity, MaybeImage, TimeColumns } from '../../types';

interface Member extends AddressColumns, TimeColumns {
  type: DataEntityType;
  memberId: string;
  memberName: MaybeString;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  birthDate: MaybeString;
  ageOfMajority: MaybeBoolean;
  private: boolean;
  wantsEmails: MaybeBoolean;
  showRealName: boolean;
  showAge: boolean;
  darkMode: MaybeBoolean;
  banned: boolean;
  pronouns: string;
  occupation: string;
  tagline: string;
  aboutMe: string;
  gender: string; // deprecate
  timeZone: string; // deprecate
  profileStep: number;
  postCount: number;
  newMessages: number;
  newNotifications: number;
  notificationsReadAt: MaybeString;
  suspendedUntil: MaybeString;
  age: MaybeNumber;
  banner: MaybeImage;
  images: Images;
  shipTos: unknown[];
  shops: unknown[];
  json: {
    cognito?: {
      username?: string;
      sub?: string;
      identities?: unknown;
    };
  };
}

const { General, Details, Avatar, Banner, Photos, Preview } = entityTabName;
export const memberEntity = entityAttributes({
  name: entityName.member,
  attributes: {
    category: entityCategory.person,
    columns: { address: true, crud: false, time: true },
    eventType: memberEvent,
    loadLimit: 20,
    maxImages: 12,
    memberOwned: true,
    nameField: 'memberName',
    selectIdName: 'memberName',
    tabs: [General, Details, Avatar, Banner, Photos, Preview],
    test: { invalidData: () => ({ occupation: {} }), validData: () => ({ occupation: 'New occupation' }) },
  },
});

const { address, array, avatar, boolean, date, email, image, memberName, number, strim, triple } = schema;
export const memberSchema = entitySchema<Member>(memberEntity, {
  memberName: memberName(false),
  email: email(true),
  firstName: strim().test(
    'first-name-test',
    'First name is required',
    (value, { parent }) => !!value || parent.profileStep === 0,
  ),
  lastName: strim().test(
    'last-name-test',
    'Last name is required',
    (value, { parent }) => !!value || parent.profileStep === 0,
  ),
  address: address(false),
  phoneNumber: strim(),
  avatar: avatar(),
  birthDate: date(),
  ageOfMajority: triple(null),
  private: boolean(),
  wantsEmails: triple(null),
  showRealName: boolean(),
  showAge: boolean(),
  darkMode: triple(null),
  banned: boolean(),
  pronouns: strim().default('They/Them'),
  occupation: strim(),
  tagline: strim(),
  aboutMe: strim(),
  gender: strim(), // deprecate
  timeZone: strim(), // deprecate
  profileStep: number(),
  postCount: number(),
  newMessages: number(),
  newNotifications: number(),
  notificationsReadAt: date(),
  suspendedUntil: date(),
  age: number().nullable().default(null),
  banner: image(),
  shipTos: array(),
  shops: array(),
});

export const initialDefaults: Partial<Member> = {
  memberName: null,
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  avatar: '',
  birthDate: null,
  ageOfMajority: null,
  private: false,
  wantsEmails: null,
  showRealName: false,
  showAge: false,
  darkMode: null,
  banned: false,
  pronouns: 'They/Them',
  occupation: '',
  tagline: '',
  aboutMe: '',
  gender: genderType.Other,
  timeZone: '',
  profileStep: 0,
  postCount: 0,
  newMessages: 0,
  newNotifications: 0,
  notificationsReadAt: null,
  suspendedUntil: null,
  age: null,
  banner: null,
  shipTos: [],
  shops: [],
};

export interface MemberRecord extends Member, RecordType {}

export type MaybeMemberRecord = MaybeNull<MemberRecord>;
export type MemberRecords = MemberRecord[];

export const initialMember: InitialEntity<Member, MemberRecord> = (overrides = {}) =>
  initialEntity<Member>(memberEntity, { ...initialDefaults, ...overrides }) as MemberRecord;
