// noinspection ES6PreferShortImport

import React, { FC, MutableRefObject, ReactNode } from 'react';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import { RecordType, camelToWords } from '@petconsole/pure-base';
import { OurEntityHooks } from '@petconsole/pure-fe-state';
import { RecordOfTabValueHooks } from '@petconsole/pure-shared';
import { ourDefaultTabWidthListings } from '../constants';
import { AddEntityType } from '../types';
import { OurListEntityAddProps } from './types';
import OurMarginGridCard from '../components/cards/OurMarginGridCard';
import OurTabs from '../components/tabs/OurTabs';
import OurTabsToolbar from '../components/tabs/OurTabsToolbar';
import OurTitleHeading from '../components/headings/OurTitleHeading';
import useOurAuthenticated from '../auth/useOurAuthenticated';
import useOurPclHeader from '../hooks/useOurPclHeader';

export interface OurBaseEntityTabPageProps<T extends RecordType = RecordType> {
  margins?: RegularBreakpoints;
  hooks: OurEntityHooks<T>;
  entityType?: string;
  header?: boolean;
  headerImage?: string;
  guestMode?: boolean;
  preCaption?: string;
  helpContent: string;
  MapTab?: FC;
  tabWidth?: number;
  AddEntity?: AddEntityType;
  addProps?: OurListEntityAddProps<T>;
}

export interface OurEntityTabPageProps<T extends RecordType = RecordType> extends OurBaseEntityTabPageProps<T> {
  caption?: string;
  helpCaption?: string;
  tabValues?: string;
  byCityTab?: () => unknown;
  city?: string;
  location?: RecordType;
  newestTab?: () => ReactNode;
  myTab?: () => ReactNode;
  tabContent?: unknown[][];
  tabProps: MutableRefObject<RecordType>;
}

const OurEntityTabPage = <T extends RecordType = RecordType>({
  margins = { xl: 1 },
  hooks,
  entityType,
  header,
  headerImage = '',
  guestMode,
  preCaption,
  caption,
  helpCaption,
  helpContent,
  tabValues,
  byCityTab,
  city,
  location,
  MapTab,
  newestTab,
  myTab,
  tabContent = [],
  tabProps,
  tabWidth = ourDefaultTabWidthListings,
  AddEntity,
}: OurEntityTabPageProps<T>) => {
  const { authenticated } = useOurAuthenticated();

  const tabHooks = hooks.tabHooks as RecordOfTabValueHooks;
  const entity = hooks.entity;
  const { plural, pluralProper, proper, typeWords } = entity;
  const { pclHeader } = useOurPclHeader({ entityType: proper });

  const title = preCaption || null;
  const heading = caption || `${myTab ? 'My ' : ''}${entityType || camelToWords(plural)}`;
  const helpTitle = helpCaption || `${title || ''} ${heading} Help`;
  const byCityName = 'By City';
  const map = 'Map';
  const newestName = 'Newest';
  const myName = `My ${typeWords}`;
  const myTabName = `My ${pluralProper}`;

  const tabs = [
    ...(byCityTab ? [[byCityName, byCityTab()]] : []),
    ...(MapTab ? [[map, <MapTab />]] : []),
    ...(newestTab && tabProps?.current[newestName] ? [[newestName, newestTab()]] : []),
    ...(myTab && tabProps?.current[myName] ? [[myName, myTab()]] : []),
    ...(myTab && tabProps?.current[myTabName] ? [[myTabName, myTab()]] : []),
    ...tabContent,
  ] as Array<Array<string | boolean | ReactNode>>;

  const tabNames = tabs.map((tab) => tab[0] as string);
  const defaultTabValue = tabHooks[tabValues || plural].useSelect() as string;
  const tabValue = tabNames.includes(defaultTabValue) ? defaultTabValue : tabNames[0];
  const setTabValue = tabHooks[tabValues || plural].useSet();

  return (
    <OurMarginGridCard margins={margins}>
      <OurTabsToolbar
        heading={<OurTitleHeading title={preCaption} heading={heading} guestMode={guestMode} />}
        pclHeader={header && pclHeader ? pclHeader : undefined}
        image={header && !pclHeader ? headerImage : ''}
        imageWidth="100%"
        alt="header image"
        helpTitle={helpTitle}
        helpContent={helpContent}
        cityTab={byCityTab ? byCityName : undefined}
        tabProps={tabProps}
        tabValue={tabValue}
        setTabValue={setTabValue}
        city={city}
        location={location}
        AddEntity={AddEntity}
        addDisabled={!authenticated}
      />
      <OurTabs tabs={tabs} tabValue={tabValue} setTabValue={setTabValue} tabWidth={tabWidth} />
    </OurMarginGridCard>
  );
};

OurEntityTabPage.whyDidYouRender = true;

export default OurEntityTabPage;
