import { ReactNode } from 'react';
import { Accept, FileRejection } from 'react-dropzone';
import { ourBlobToDataUrl } from '@petconsole/pure-fe-utils';
import { Image, ImageReturnsVoid, Log, ourImageContentType } from '@petconsole/pure-shared';
import useOurSnacks from '../../../hooks/useOurSnacks';
import PhotoZoneContainer from './PhotoZoneContainer';

interface PhotoDropzoneProps {
  accept?: Accept;
  onDrop: ImageReturnsVoid;
  leadText?: ReactNode;
  log: Log;
}

const PhotoDropzone = ({ accept, onDrop, leadText, log }: PhotoDropzoneProps) => {
  const { warn } = useOurSnacks();

  const handleDrop = (blobs: File[]) =>
    ourBlobToDataUrl({ blob: blobs[0], log }).then((src) =>
      onDrop({
        src,
        contentType: ourImageContentType({ src: (src || '') as string }),
      } as Image),
    );

  const handleReject = (rejected: FileRejection[]) => {
    if (rejected.length > 1) warn(`Too many files selected. Only one is permitted.`, false);
  };

  return <PhotoZoneContainer leadText={leadText} accept={accept} onDrop={handleDrop} onReject={handleReject} />;
};

PhotoDropzone.whyDidYouRender = true;

export default PhotoDropzone;
