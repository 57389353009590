import { RecordType } from '@petconsole/pure-base';
import { pclServiceApiEntity } from '@petconsole/pure-fe-api';
import { PclServiceRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  FetchSelectHooks,
  OurEntityHooks,
  RequiredCrudSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = PclServiceRecord;

const apiEntity = pclServiceApiEntity;
const { name: sliceName } = apiEntity;
const pclServiceAdmin = `${sliceName}Admin`;

const Tab = { pclServiceAdmin } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  pclServiceAdmin: { default: 'Preview' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasDefaultProperties: true,
  fetchOneByProperty: {
    propertyName: 'serviceCode',
    apiMethod: 'getByServiceCode',
  },
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, propertyHooks, tabHooks } = sliceHookTypes;

export interface OurPclServiceHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  pclServiceHooks: RequiredCrudSliverHooks;
  propertyHooks: RecordType;
  tabHooks: TabHooks;
  customHooks: {
    byServiceCode: FetchSelectHooks<ApiEntityRecord | undefined>;
  };
}

export const ourPclServiceHooks: OurPclServiceHooks = {
  entity: apiEntity,
  pclServiceHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  propertyHooks: propertyHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
  customHooks: {
    byServiceCode: {
      useFetch:
        hooks.useOurFetchPclServiceByServiceCode as OurPclServiceHooks['customHooks']['byServiceCode']['useFetch'],
      useSelect:
        hooks.useOurSelectPclServiceByServiceCode as OurPclServiceHooks['customHooks']['byServiceCode']['useSelect'],
    },
  },
};

export default reducer;
