import { ApiError } from 'aws-amplify/api';

const restCatch = (method: string, error: unknown) => {
  if (error instanceof ApiError) {
    // if (error.response) return error.response;

    if (error.response) {
      const { statusCode, body } = error.response;

      throw new Error(`${method} received ${statusCode} error response with payload: ${JSON.stringify(body)}`);
    }

    // Handle API errors not caused by HTTP response.
    throw new Error(`${method} ApiError: ${String(error)}`)
  }

  // Handle other errors.
  const message = error instanceof Error ? error.message : String(error);

  throw new Error(`${method} error: ${message}`)
};

export default restCatch;