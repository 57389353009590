import { useEffect } from 'react';
import { CityLocation } from '@petconsole/pure-base';
import { ourLocationToCity } from '@petconsole/pure-fe-utils';
import { SetBoolean, SetLocation, StringReturnsVoid } from '@petconsole/pure-shared';
import useOurDefaultLocation from '../../hooks/useOurDefaultLocation';

interface UseOurByCityDefaultProps {
  defaulted?: boolean;
  setDefaulted: SetBoolean;
  setCity: StringReturnsVoid;
  location?: CityLocation;
  setLocation: SetLocation;
  // reset: PropTypes.func.isRequired,
  // fetch: PropTypes.func.isRequired,
}

const useOurByCityDefault = ({
  defaulted,
  setDefaulted,
  setCity,
  location,
  setLocation,
  // reset,
  // fetch,
}: UseOurByCityDefaultProps) => {
  const { getDefaultLocation } = useOurDefaultLocation();

  useEffect(() => {
    if (defaulted) {
      // After defaulting, we need to reset if they clear the city/location
      // if (!location) reset();

      return;
    }

    if (location) {
      // As soon as we first have a location, we can set defaulted
      setDefaulted(true);
      return;
    }

    // Get the default location now
    getDefaultLocation().then((defaultLocation) => {
      // If we don't have a default location available yet, we still set defaulted to avoid retries
      if (!defaultLocation || JSON.stringify(defaultLocation) === '{}') {
        setDefaulted(true);
        return;
      }

      // Since we now have a default location, set the city and location
      setLocation(defaultLocation);
      setCity(ourLocationToCity(defaultLocation));

      // Fetch based on the default location, and when we are done, set defaulted
      // fetch(defaultLocation).then(() => {
      setDefaulted(true);
      // });
    });
 }, [
    defaulted,
    setDefaulted,
    setLocation,
    setCity,
    location,
    getDefaultLocation /* , reset, fetch */,
  ]);
};

export default useOurByCityDefault;
