import { OurLazyLoad } from '@petconsole/pure-fe-mui';
import { RecordType } from '@petconsole/pure-shared';

const LazyContactUs = (props: RecordType) => <OurLazyLoad load={() => import('./ContactUs')} {...props} />;
export { LazyContactUs as ContactUs };

const LazyCredits = (props: RecordType) => <OurLazyLoad load={() => import('./Credits')} {...props} />;
export { LazyCredits as Credits };

const LazyOurAboutUs = (props: RecordType) => <OurLazyLoad load={() => import('./OurAboutUs')} {...props} />;
export { LazyOurAboutUs as OurAboutUs };

const LazyOurFaq = (props: RecordType) => <OurLazyLoad load={() => import('./OurFaq')} {...props} />;
export { LazyOurFaq as OurFaq };

const LazyOurNotFound = (props: RecordType) => <OurLazyLoad load={() => import('./OurNotFound')} {...props} />;
export { LazyOurNotFound as OurNotFound };

const LazyOurPricing = (props: RecordType) => <OurLazyLoad load={() => import('./OurPricing')} {...props} />;
export { LazyOurPricing as OurPricing };

const LazyOurWelcome = (props: RecordType) => <OurLazyLoad load={() => import('./OurWelcome')} {...props} />;
export { LazyOurWelcome as OurWelcome };

const LazyPrivacy = (props: RecordType) => <OurLazyLoad load={() => import('./Privacy')} {...props} />;
export { LazyPrivacy as Privacy };

const LazyTeam = (props: RecordType) => <OurLazyLoad load={() => import('./Team')} {...props} />;
export { LazyTeam as Team };

const LazyTerms = (props: RecordType) => <OurLazyLoad load={() => import('./Terms')} {...props} />;
export { LazyTerms as Terms };
