import { ReactNode } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';
import { Link, LinkProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RecordType } from '@petconsole/pure-base';

export interface OurLinkProps extends LinkProps {
  to: To;
  state?: RecordType;
  children?: ReactNode;
}

const OurLink = ({ to, state, children, ...rest }: OurLinkProps) => {
  const { mode, primary } = useTheme().palette;

  return (
    <Link
      component={RouterLink}
      to={to}
      color={mode === 'dark' ? primary.light : 'primary'}
      {...(state && { state })}
      {...rest}
    >
      {children}
    </Link>
  );
};

OurLink.whyDidYouRender = true;

export default OurLink;