import { RecordType } from '@petconsole/pure-base';
import { petApiEntity } from '@petconsole/pure-fe-api';
import { PetRecord } from '@petconsole/pure-shared';
import { fetchParams, sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RequiredCrudSliverByHooks,
  RequiredSliverByHooks,
  RequiredSliverHooks,
  SelectSetHooks,
  TabValueHooks,
  ThunkApi,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = PetRecord;

const apiEntity = petApiEntity;
const { api, name: sliceName, proper, plural } = apiEntity;
const pet = sliceName;
const pets = plural;
const myPets = `my${proper}`;
const typeName = `type${proper}`;
const petAdmin = `${sliceName}Admin`;

const Tab = { petAdmin, pet, pets, myPets } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  petAdmin: { default: '' },
  pet: { default: '' },
  pets: { default: '' },
  myPets: { default: 'My Pets' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  sortProperty: 'petName',
  hasCitySliver: true,
  hasNewestSliver: true,
  hasMySliver: true,
  hasNextPrev: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  tabs,
  sliceProperties: [{ suffix: 'sPetType', default: 'Dog' }],
  slivers: [
    {
      prefix: 'type',
      fetchesByValue: true,
      payloadCreators: async (petType: string, { getState }: ThunkApi) =>
        api.getListByType(petType, fetchParams(getState, sliceName, typeName)),
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, tabHooks } = sliceHookTypes;

export interface OurPetHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  petHooks: RequiredCrudSliverByHooks;
  cityPetHooks: RequiredSliverHooks;
  newestPetHooks: RequiredSliverHooks;
  myPetHooks: RequiredSliverHooks;
  typePetHooks: RequiredSliverByHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  tabHooks: TabHooks;
  customHooks: {
    petsPetType: SelectSetHooks<string>;
  };
}

export const ourPetHooks: OurPetHooks = {
  entity: apiEntity,
  petHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverByHooks,
  cityPetHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestPetHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  myPetHooks: sliverHooks(apiEntity, 'My', hooks) as RequiredSliverHooks,
  typePetHooks: sliverHooks(apiEntity, 'Type', hooks) as RequiredSliverByHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  tabHooks: tabHooks(Object.keys(Tab), hooks) as TabHooks,
  customHooks: {
    petsPetType: {
      useSelect: hooks.useOurSelectPetsPetType as OurPetHooks['customHooks']['petsPetType']['useSelect'],
      useSet: hooks.useOurSetPetsPetType as OurPetHooks['customHooks']['petsPetType']['useSet'],
    },
  },
};

export default reducer;
