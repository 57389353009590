import { OurChevronDoubleUpIcon } from '@petconsole/pure-fe-icon';
import { Icon } from '@petconsole/pure-shared';
import OurIconButton, { OurIconButtonProps } from './OurIconButton';

interface OurCollapseAllButtonProps extends Omit<OurIconButtonProps, 'tip' | 'Icon'> {
  tip?: string;
  Icon?: Icon;
}

const OurCollapseAllButton = ({
  tip = 'Collapse All',
  Icon = OurChevronDoubleUpIcon,
  ...rest
}: OurCollapseAllButtonProps) => <OurIconButton tip={tip} Icon={Icon} {...rest} />;

OurCollapseAllButton.whyDidYouRender = true;

export default OurCollapseAllButton;
