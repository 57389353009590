import { pclHeaderApiEntity } from '@petconsole/pure-fe-api';
import { PclHeaderRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, getOne, getOneBy, updateOne } from '../../../helpers';

type ApiRecord = PclHeaderRecord;

const pclHeaderEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = pclHeaderApiEntity;

  return {
    createPclHeader: createOne(builder, api, tag),
    getPclHeader: getOne(builder, api, tag),
    getPclHeaderByEntityType: getOneBy(builder, api.getByEntityType, tag),
    updatePclHeader: updateOne(builder, api.update, tag),
  };
};

export default pclHeaderEndpoints;
