import { PropsWithChildren, useRef } from 'react';
import { InputLabel, Grid, Box, SxProps } from '@mui/material';
// import NotchedOutline from '@mui/material/OutlinedInput/NotchedOutline';
import NotchedOutline from './NotchedOutline';

export interface OutlinedGridProps extends PropsWithChildren {
  id: string;
  label: string;
  spacing?: number;
  width?: string;
  sx?: SxProps;
}

const OutlinedGrid = ({ id, label, spacing = 2, width = '100%', children, sx }: OutlinedGridProps) => {
  const labelRef = useRef(null);

  return (
    <Box sx={{ width, position: 'relative', mt: 1 }}>
      <InputLabel ref={labelRef} htmlFor={id} variant="outlined" shrink sx={{ position: 'absolute', left: 0, top: 0 }}>
        {label}
      </InputLabel>
      <Grid container id={id} p={1} pt={1.25} borderRadius={0.5} spacing={spacing} {...(sx && { sx })}>
        {children}
        <NotchedOutline label={label} />
      </Grid>
    </Box>
  );
};

OutlinedGrid.whyDidYouRender = true;

export default OutlinedGrid;
