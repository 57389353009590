import { useCallback } from 'react';
import { RecordType } from '@petconsole/pure-base';
import { cognitoPrefix } from '../constants';
import { CacheInstance, CacheItemOptions } from '../types';
import clearCacheItems from './clearCacheItems';
import createCacheInstance from './createCacheInstance';
import getCognitoItems from './getCognitoItems';
import setCognitoItems from './setCognitoItems';

let cognitoCache: CacheInstance;

const useOurCognitoCache = () => {
  if (!cognitoCache) cognitoCache = createCacheInstance(cognitoPrefix);

  const clearItems = useCallback(() => clearCacheItems({ cache: cognitoCache, prefix: cognitoPrefix }), []);

  const getItem = (key: string, options?: CacheItemOptions) => cognitoCache.getItem(key, options);

  const setItem = (key: string, value: unknown, options?: CacheItemOptions) =>
    cognitoCache.setItem(key, value, options);

  const getItems = () => getCognitoItems({ cache: cognitoCache });

  const setItems = (items: RecordType) => setCognitoItems({ cache: cognitoCache, items })

  return { clearItems, getItem, setItem, getItems, setItems };
};

export default useOurCognitoCache;
