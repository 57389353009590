import { Grid } from '@mui/material';
import { EntityRecord } from '@petconsole/pure-shared';
import OurFacebookIcon from '../social/OurFacebookIcon';
import OurInstagramIcon from '../social/OurInstagramIcon';
import OurTwitterIcon from '../social/OurTwitterIcon';
import OurLinkedInIcon from '../social/OurLinkedInIcon';
import OurYouTubeIcon from '../social/OurYouTubeIcon';
import OurTikTokIcon from '../social/OurTikTokIcon';

interface OurSocialLinksProps {
  entity: EntityRecord;
}

const OurSocialLinks = ({ entity }: OurSocialLinksProps) => {
  const { facebook = '', instagram = '', twitter = '', linkedIn = '', youTube = '', tikTok = '' } = entity;

  return (
    <Grid container item xs={12} justifyContent="center">
      {facebook && <OurFacebookIcon href={facebook} />}
      {instagram && <OurInstagramIcon href={instagram} />}
      {twitter && <OurTwitterIcon href={twitter} />}
      {linkedIn && <OurLinkedInIcon href={linkedIn} />}
      {youTube && <OurYouTubeIcon href={youTube} />}
      {tikTok && <OurTikTokIcon href={tikTok} />}
    </Grid>
  );
};

OurSocialLinks.whyDidYouRender = true;

export default OurSocialLinks;
