import { useState } from 'react';
import { RecordType } from '@petconsole/pure-base';
import useMaintSave from './useMaintSave';
import { UseMaintSubmitProps } from '../types';

const useMaintSubmit = <T extends RecordType = RecordType>({
  beforeSubmit,
  afterSubmit,
  ...rest
}: UseMaintSubmitProps<T>) => {
  const [submitting, setSubmitting] = useState(false);
  const [busy, setBusy] = useState(false);

  const saveProps = { ...rest, setSubmitting, setBusy };
  const { save } = useMaintSave(saveProps);

  const onSubmit = async () => {
    const values = rest.form.getValues();

    // May want to prune any values we don't want to send over the network when we save the item
    const submitValues = beforeSubmit ? beforeSubmit(values) : values;

    console.log('inner submit images:', rest.images);

    const saved = (await save(submitValues)) as T;

    if (saved && afterSubmit) afterSubmit(saved);

    return saved || undefined;
  };

  return { onSubmit, submitting, setSubmitting, busy, setBusy };
};

export default useMaintSubmit;
