import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { pclHeaderApiEntity } from '@petconsole/pure-fe-api';
import { PclHeaderRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  FetchByHook,
  OurEntityHooks,
  RequiredCrudSliverHooks,
  SelectByHook,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = PclHeaderRecord;

const apiEntity = pclHeaderApiEntity;
const { api, name: sliceName, plural } = apiEntity;
const pclHeaderAdmin = `${sliceName}Admin`;
const pclHeadersAdmin = `${plural}Admin`;

const Tab = { pclHeaderAdmin, pclHeadersAdmin } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  pclHeaderAdmin: { default: 'Preview' },
  pclHeadersAdmin: { default: 'Headers' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasDefaultProperties: true,
  fetchOneByProperty: {
    propertyName: 'entityType',
    apiMethod: 'getByEntityType',
  },
  tabs,
  slivers: [
    {
      prefix: sliceName,
      payloadCreators: async () => api.getAll(),
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, propertyHooks, tabHooks } = sliceHookTypes;

export interface OurPclHeaderHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  pclHeaderHooks: RequiredCrudSliverHooks;
  propertyHooks: RecordType;
  tabHooks: TabHooks;
  customHooks: {
    useFetchByEntityType: FetchByHook<MaybeNull<ApiEntityRecord>>;
    useSelectByEntityType: SelectByHook<ApiEntityRecord | undefined>;
  };
}

export const ourPclHeaderHooks: OurPclHeaderHooks = {
  entity: apiEntity,
  pclHeaderHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  propertyHooks: propertyHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
  customHooks: {
    useFetchByEntityType:
      hooks.useOurFetchPclHeaderByEntityType as OurPclHeaderHooks['customHooks']['useFetchByEntityType'],
    useSelectByEntityType:
      hooks.useOurSelectPclHeaderByEntityType as OurPclHeaderHooks['customHooks']['useSelectByEntityType'],
  },
};

export default reducer;
