import { petWorkplaceApiEntity } from '@petconsole/pure-fe-api';
import { PetWorkplaceRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { RecordType } from '@petconsole/pure-base';

type ApiEntityRecord = PetWorkplaceRecord;

const apiEntity = petWorkplaceApiEntity;
const { name: sliceName, plural } = apiEntity;
const petWorkplace = sliceName;
const petWorkplaces = plural;
const petWorkplaceAdmin = `${sliceName}Admin`;

const Tab = { petWorkplaceAdmin, petWorkplace, petWorkplaces } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  petWorkplaceAdmin: { default: '' },
  petWorkplace: { default: '' },
  petWorkplaces: { default: '' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, ratingHooks, tabHooks } = sliceHookTypes;

export interface OurPetWorkplaceHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  petWorkplaceHooks: RequiredCrudSliverHooks;
  cityPetWorkplaceHooks: RequiredSliverHooks;
  newestPetWorkplaceHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourPetWorkplaceHooks: OurPetWorkplaceHooks = {
  entity: apiEntity,
  petWorkplaceHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityPetWorkplaceHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestPetWorkplaceHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  ratingHooks: ratingHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
