import { newId } from '@petconsole/pure-base';
import { ourPutImages, postApiEntity } from '@petconsole/pure-fe-api';
import { Images, Log, postEntity, PostRecord } from '@petconsole/pure-shared';
import { writeReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick } from '../../../types';

const { api, name: sliceName, proper } = postApiEntity;
const byTopicName = `byTopic${proper}`;
const replyName = `reply${proper}`;

const addPost = async (post: PostRecord, log: Log) => {
  const { images } = post;

  const postId = newId();

  const filteredImages = (images || []).filter(({ src }) => !!src);
  const keyedImages = filteredImages.map(({ src, ...image }) => ({
    ...image,
    src,
    s3Key: postEntity.imageKey({ id: postId, src: src as string }),
  }));

  await ourPutImages({ images: keyedImages, log });

  return api.create({
    ...post,
    postId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    images: (keyedImages as Images).map(({ src, ...image }) => image),
  });
};

export const createPostPayloadCreator = async ({ post: newPost, log }: { post: PostRecord, log: Log }) => addPost(newPost, log);

export const createSlicePostThunk = appThunk(`${sliceName}/createSlice${proper}`, createPostPayloadCreator);

export const createByTopicPostThunk = appThunk(`${sliceName}/createByTopic${proper}`, createPostPayloadCreator);

export const createReplyPostThunk = appThunk(`${sliceName}/createReply${proper}`, createPostPayloadCreator);

export const createSlicePostReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(createSlicePostThunk, sliceName, adapter);

export const createByTopicPostReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(createByTopicPostThunk, byTopicName, adapter);

export const createReplyPostReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(createReplyPostThunk, replyName, adapter);
