import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OurSwitch, { OurSwitchProps } from './OurSwitch';

interface OurSwitchGridItemProps extends OurSwitchProps, RegularBreakpoints {
  gridProps?: GridProps;
}

const OurSwitchGridItem = ({ gridProps, xs = 12, sm, md, lg, xl, ...rest }: OurSwitchGridItemProps) => {
  return (
    <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
      <OurSwitch {...rest} />
    </Grid>
  );
};

OurSwitchGridItem.whyDidYouRender = true;

export default OurSwitchGridItem;
