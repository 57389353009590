import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '../types';
import { AppState } from './store';

// export type AppThunk = ReturnType<typeof appThunk>;

export interface AppThunkApi extends ThunkApi {
  state: AppState;
}

export const appThunk = <Returned, ThunkArg>(
  type: string,
  thunk: AsyncThunkPayloadCreator<Returned, ThunkArg, AppThunkApi>,
): AsyncThunk<Returned, ThunkArg, AppThunkApi> => createAsyncThunk<Returned, ThunkArg, AppThunkApi>(type, thunk);

// export const appThunk: ReturnType<typeof createAsyncThunk.withTypes> = createAsyncThunk.withTypes<{
//   state: AppState;
//   getState: () => AppState;
//   dispatch: AppDispatch;
// }>();

