import { MouseEventHandler } from 'react';
import { Link } from '@mui/material';
import { EntityAttributes } from '@petconsole/pure-shared';

interface IsThisYoursProps {
  entity: EntityAttributes;
  onClick?: MouseEventHandler;
  disabled?: boolean;
}

const IsThisYours = ({ entity, onClick, disabled }: IsThisYoursProps) => {
  return (
    <Link onClick={disabled ? undefined : onClick} sx={{ cursor: disabled ? 'default' : 'pointer' }}>
      Is this your {entity.category}?
    </Link>
  );
};

IsThisYours.whyDidYouRender = true;

export default IsThisYours;
