import { MouseEventHandler, ReactNode } from 'react';
import { Accept } from 'react-dropzone';
import { Box, BoxProps, Grid } from '@mui/material';
import { Image, ImageReturnsVoid, Log } from '@petconsole/pure-shared';
import Photo from './Photo';
import PhotoDropzone from './PhotoDropzone';

interface PhotoZoneProps {
  photo?: Image;
  photoBoxProps?: BoxProps;
  canEdit?: boolean;
  onDrop: ImageReturnsVoid;
  onRemove: MouseEventHandler;
  accept?: Accept;
  leadText?: ReactNode;
  log: Log;
}

const PhotoZone = ({ photo, photoBoxProps = {}, canEdit, onDrop, onRemove, accept, leadText, log }: PhotoZoneProps) => (
  <Grid item xs={12}>
    <Box {...photoBoxProps}>
      <Photo photo={photo} canRemove={canEdit} onRemove={onRemove} />
    </Box>
    {canEdit && !photo?.src && <PhotoDropzone leadText={leadText} accept={accept} onDrop={onDrop} log={log} />}
  </Grid>
);

PhotoZone.whyDidYouRender = true;

export default PhotoZone;
