import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  GetListOptions,
  GetListResults,
  ourShopItemVariantOption,
  shopItemVariantCodeEntity,
  ShopItemVariantCodeRecord,
} from '@petconsole/pure-shared';
import { commonApi, fullPath, get, pathParam, post } from './common';

type ApiRecord = ShopItemVariantCodeRecord;

const apiPath = '/shopItems/variantCodes';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

export interface ShopItemVariantCodeApi<T extends RecordType = ApiRecord> extends Api<T> {
  getListByShopItem: (shopItemId: string, options?: GetListOptions) => Promise<GetListResults<T>>;
  getListByShopItemAndOption1: (shopItemId: string) => Promise<GetListResults<T>>;
  getListByShopItemAndOption2: (shopItemId: string) => Promise<GetListResults<T>>;
  upsertList: (shopItemId: string, variantCodes: RecordType[]) => Promise<RecordType[]>;
}

export const shopItemVariantCodeApi: ShopItemVariantCodeApi = {
  ...common,
  getListByValue,
  getListByShopItem: (shopItemId, options) => getListByValue('shopItemId', shopItemId, options),
  getListByShopItemAndOption1: (shopItemId) =>
    get(
      fullPath(
        apiPath,
        `${pathParam('shopItemId', shopItemId)}${pathParam(
          'variantOption',
          ourShopItemVariantOption.Option1,
        )}${pathParam('all', 'true')}`,
      ),
    ) as Promise<GetListResults<ShopItemVariantCodeRecord>>,
  getListByShopItemAndOption2: (shopItemId) =>
    get(
      fullPath(
        apiPath,
        `${pathParam('shopItemId', shopItemId)}${pathParam(
          'variantOption',
          ourShopItemVariantOption.Option2,
        )}${pathParam('all', 'true')}`,
      ),
    ) as Promise<GetListResults<ShopItemVariantCodeRecord>>,
  upsertList: (shopItemId, variantCodes) =>
    post(`${apiPath}/upsertList`, {
      shopItemId,
      variantCodes,
    }) as unknown as Promise<RecordType[]>,
};

export const shopItemVariantCodeApiEntity /* : ApiEntity<ApiRecord> */ = {
  ...shopItemVariantCodeEntity,
  api: shopItemVariantCodeApi,
};
