import { memberApiEntity } from '@petconsole/pure-fe-api';
import { MemberRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { getOne, getOneBy } from '../../../helpers';

type ApiRecord = MemberRecord;

const memberEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = memberApiEntity;

  return {
    getMember: getOne(builder, api, tag),
    getMemberByName: getOneBy(builder, api.getByName, tag),
  };
};

export default memberEndpoints;
