import { RecordType } from '@petconsole/pure-base';
import { EntityAttributes, EntitySchema } from '../types';
import { schema } from './schema';

const entitySchema = <T extends object = object>(attributes: EntityAttributes, customSchema?: RecordType) => {
  const { boolean, crudColumns, id, images, json, ratings, siteColumns, type } = schema;
  const { businessColumns, contactColumns, googleAddressColumns, memberColumns, ownerColumns } = schema;
  const { reactionColumns, socialColumns, subscribeColumns, subscriptionColumns, timestampColumns } = schema;
  const { idName, columns } = attributes;

  return {
    type: type(attributes.type),
    [idName]: id(true),
    ...(columns.active && { active: boolean(true) }),
    ...(columns.address && googleAddressColumns()),
    ...(columns.business && businessColumns()),
    ...(columns.contact && !columns.business && contactColumns()),
    ...(columns.crud && crudColumns()),
    ...(columns.images && { images: images() }),
    ...(columns.json && { json: json() }),
    ...(columns.member && memberColumns()),
    ...(columns.owner && ownerColumns()),
    ...(columns.rating && { ratings: ratings() }),
    ...(columns.reaction && reactionColumns()),
    ...(columns.site && siteColumns()),
    ...(columns.socials && socialColumns()),
    ...(columns.subscribe && subscribeColumns()),
    ...(columns.subscription && subscriptionColumns()),
    ...(columns.time && timestampColumns()),
    ...customSchema,
  } as EntitySchema<T>;
};

export default entitySchema;
