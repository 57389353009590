import ourPutImage from './ourPutImage';
import { OurPutImagesProps } from "../types";

const ourPutImages = async ({ images, log }: OurPutImagesProps) => {
  if (!images.length) return images;

  // TODO: Consider error if we are missing src?
  const filtered = images.filter(({ src }) => !!src);

  return await Promise.all(
    filtered.map(({ s3Key, src, acl = 'public-read', contentType }) =>
      ourPutImage({ s3Key, src: src as string, acl, contentType, log }),
    ),
  );
};

export default ourPutImages;
