import { feedApiEntity } from '@petconsole/pure-fe-api';
import { FeedRecord } from '@petconsole/pure-shared';
import { fetchParams, sliceHookTypes, useOurDispatchEntity } from '../../helpers';
import { BaseSliceOptions, OurEntityHooks, ReactionHooks, RecordType, RequiredCrudSliverHooks } from '../../types';
import BaseEntitySlice from '../base/BaseEntitySlice';
import { createFeedPostReducers, createFeedPostThunk } from './createFeedPost';

type ApiEntityRecord = FeedRecord;

const apiEntity = feedApiEntity;
const { api, name: sliceName, idName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  hasReaction: true,
  canDelete: true,
  idName,
  comparer: ({ feedAt: a }, { feedAt: b }) => b?.localeCompare(a),
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      extraState: { images: {} },
      fetchesByValue: true,
      payloadCreators: async ({ from, to }: RecordType = {}, { getState }: RecordType) =>
        api.getListByUrlAndCreatedBetween(
          { fromCreatedAt: from, toCreatedAt: to },
          fetchParams(getState, sliceName, sliceName),
        ),
      extraReducers: [createFeedPostReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, reactionHooks } = sliceHookTypes;

export interface OurFeedHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  feedHooks: RequiredCrudSliverHooks;
  reactionHooks: ReactionHooks;
}

export const ourFeedHooks: OurFeedHooks = {
  entity: apiEntity,
  feedHooks: {
    ...crudHooks(apiEntity, hooks),
    useCreate: () => useOurDispatchEntity(sliceName, createFeedPostThunk),
  } as RequiredCrudSliverHooks,
  reactionHooks: reactionHooks(apiEntity, hooks),
};

export default reducer;
