import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OurAverageRating, { OurAverageRatingProps } from './OurAverageRating';

interface OurAverageRatingGridItemProps extends OurAverageRatingProps, RegularBreakpoints {
  gridProps?: GridProps;
}

const OurAverageRatingGridItem = ({ gridProps, xs = 12, sm, md, lg, xl, ...rest }: OurAverageRatingGridItemProps) => {
  return (
    <Grid item {...{ xs, sm, md, lg, xl }} {...gridProps}>
      <OurAverageRating {...rest} />
    </Grid>
  );
};

OurAverageRatingGridItem.whyDidYouRender = true;

export default OurAverageRatingGridItem;
