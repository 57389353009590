import { memo } from 'react';
import { RecordType } from '@petconsole/pure-base';
import OurTitleHeading from '../../components/headings/OurTitleHeading';
import useMaintToolbar from './useMaintToolbar';
import { UseMaintHeaderProps } from '../types';

const useMaintHeader = <T extends RecordType = RecordType>({
  entity,
  data,
  heading,
  isEditing,
  isOwner,
  add,
  onAdd,
  ...rest
}: UseMaintHeaderProps<T>) => {
  // Toolbar
  const toolbarProps = { ...rest, isEditing, onAdd: add ? onAdd : undefined };
  const { Toolbar } = useMaintToolbar(toolbarProps);

  const { addField, memberOwned, typeWords } = entity;

  const title = (addField ? data?.[addField] : '') as string;
  const titleHeading =
    `${isEditing ? 'Edit' : ''} ${isOwner && memberOwned ? 'My' : ''} ${heading || typeWords}`.trim();

  const Heading = memo(() => <OurTitleHeading title={title} heading={titleHeading} guestMode />);

  return { Heading, Toolbar };
};

export default useMaintHeader;
