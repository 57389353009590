import { useMemo } from 'react';
import { RecordTypes } from '@petconsole/pure-base';
import selectsByPropertyNameAndValue from '../selectors/selectsByPropertyNameAndValue';
import useOurSelectByValue from './useOurSelectByValue';

interface UseOurSelectsByPropertyNameAndValueProps<V> {
  sliceName: string;
  sliverName: string;
  propertyName: string;
  value: V;
}

const useOurSelectsByPropertyNameAndValue = <V>({
  sliceName,
  sliverName,
  propertyName,
  value,
}: UseOurSelectsByPropertyNameAndValueProps<V>) => {
  const selector = useMemo(
    () =>
      selectsByPropertyNameAndValue<V>({
        sliceName,
        sliverName,
        propertyName,
        value,
      }),
    [propertyName, value],
  );

  return useOurSelectByValue<RecordTypes, V>(selector, value);
};

export default useOurSelectsByPropertyNameAndValue;
