import { RecordType } from '@petconsole/pure-base';
import { petTypeApiEntity } from '@petconsole/pure-fe-api';
import { PetTypeRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import { BaseSliceOptions, BaseSliceTab, OurEntityHooks, RequiredCrudSliverHooks, TabValueHooks } from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = PetTypeRecord;

const apiEntity = petTypeApiEntity;
const { api, name: sliceName, plural } = apiEntity;
const petType = sliceName;
const petTypes = plural;
const petTypeAdmin = `${sliceName}Admin`;

const Tab = { petTypeAdmin, petType, petTypes } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  petTypeAdmin: { default: 'Type' },
  petType: { default: '' },
  petTypes: { default: '' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasDefaultProperties: true,
  sortProperty: 'petType',
  tabs,
  slivers: [
    {
      prefix: sliceName,
      payloadCreators: async () => api.getAll(),
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, propertyHooks, tabHooks } = sliceHookTypes;

export interface OurPetTypeHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  petTypeHooks: RequiredCrudSliverHooks;
  propertyHooks: RecordType;
  tabHooks: TabHooks;
}

export const ourPetTypeHooks: OurPetTypeHooks = {
  entity: apiEntity,
  petTypeHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  propertyHooks: propertyHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
