// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { mailFolderType } from '../../../constants';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import { DataEntityType, InitialEntity, MailFolderType, TimeColumns } from '../../../types';

export interface MailFolder extends TimeColumns {
  type: DataEntityType;
  mailFolderId: string;
  mailFolderType: MailFolderType;
  memberId: string;
  order: string;
  name: string;
  messageCount: number;
  unreadCount: number;
  permanent: boolean;
}

export const mailFolderEntity = entityAttributes({
  name: entityName.mailFolder,
  attributes: {
    apiPath: '/mail/folders',
    category: entityCategory.item,
    columns: { crud: false, images: false, json: false, time: true },
  },
});

const { boolean, id, number, oneOf, strim } = schema;
export const mailFolderSchema = entitySchema<MailFolder>(mailFolderEntity, {
  mailFolderType: oneOf(mailFolderType, mailFolderType.Other),
  memberId: id(true),
  order: strim(), // use ulid, append an 'a' on a move
  name: strim(),
  messageCount: number().min(0),
  unreadCount: number().min(0),
  permanent: boolean(),
});

export const mailFolderDefaults: Partial<MailFolder> = {
  mailFolderType: mailFolderType.Other,
  memberId: '',
  order: '',
  name: '',
  messageCount: 0,
  unreadCount: 0,
  permanent: false,
};

export interface MailFolderRecord extends MailFolder, RecordType {}

export type MaybeMailFolderRecord = MaybeNull<MailFolderRecord>;
export type MailFolderRecords = MailFolderRecord[];

export const initialMailFolder: InitialEntity<MailFolder, MailFolderRecord> = (overrides = {}) =>
  initialEntity<MailFolder>(mailFolderEntity, { ...mailFolderDefaults, ...overrides }) as MailFolderRecord;
