import { pclTierApiEntity } from '@petconsole/pure-fe-api';
import { PclTierRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, getListBy, getOne, updateOne } from '../../../helpers';

type ApiRecord = PclTierRecord;

const pclTierEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = pclTierApiEntity;

  return {
    createPclTier: createOne(builder, api, tag),
    getPclTier: getOne(builder, api, tag),
    getPclTiersByPclService: getListBy(builder, api.getListByPclService, tag),
    updatePclTier: updateOne(builder, api.update, tag),
  };
};

export default pclTierEndpoints;
