import { useCallback, useState } from 'react';
import { ourGetImage } from '@petconsole/pure-fe-api';
import { useOurLog } from '@petconsole/pure-fe-utils';

interface UseOurAvatarEditorProps {
  currentAvatar?: string;
  defaultAvatar?: string;
}

const useAvatarDrop = ({currentAvatar, defaultAvatar}: UseOurAvatarEditorProps) => {
  const { log } = useOurLog();

  const [avatar, setAvatar] = useState(defaultAvatar || '');
  const [avatarChanged, setAvatarChanged] = useState(false);

  const getAvatar = useCallback(() => {
    ourGetImage({s3Key: currentAvatar || '', log})
      .then((image) => setAvatar((image || defaultAvatar || '') as string))
      .then(() => setAvatarChanged(false));
  }, [currentAvatar, defaultAvatar, log]);

  return {
    avatar,
    setAvatar,
    avatarChanged,
    setAvatarChanged,
    getAvatar,
  };
};

export default useAvatarDrop;
