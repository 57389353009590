import { ChangeEvent, useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Dropzone, { Accept, DropzoneState } from 'react-dropzone';
import ImageSearch from '@mui/icons-material/ImageSearch';
import { Stack, Typography } from '@mui/material';
import { ReturnsVoid } from '@petconsole/pure-shared';
import OurFileButton from '../../components/buttons/OurFileButton';
import OurZoomInTextButton from '../../components/buttons/OurZoomInTextButton';
import OurZoomOutTextButton from '../../components/buttons/OurZoomOutTextButton';
import OurRotateTextButton from '../../components/buttons/OurRotateTextButton';
import AvatarEdit, { AvatarEditProps } from './AvatarEdit';

export interface AvatarDropProps extends Pick<AvatarEditProps, 'width' | 'setEditor' | 'setChanged'> {
  avatar: File | string;
  accept?: Accept;
}

const AvatarDrop = ({ avatar, accept = { 'image/*': ['.jpeg', '.png'] }, setChanged, ...rest }: AvatarDropProps) => {
  const [image, setImage] = useState(avatar);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const makeChange = useCallback(
    (callback: ReturnsVoid) => {
      callback();
      setChanged(true);
    },
    [setChanged],
  );

  const found = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => makeChange(() => setImage(e.target.files ? e.target.files[0] : '')),
    [makeChange],
  );

  const handleDrop = useCallback((files: File[]) => makeChange(() => setImage(files[0])), [makeChange]);

  const zoomIn = useCallback(() => makeChange(() => setScale((c) => Math.min(c + 0.05, 2))), [makeChange]);

  const zoomOut = useCallback(() => makeChange(() => setScale((c) => Math.max(c - 0.05, -1))), [makeChange]);

  const turn = useCallback(() => makeChange(() => setRotate(rotate - 90)), [makeChange, rotate]);

  const avatarEdit = useCallback(
    (state: DropzoneState) => <AvatarEdit {...state} {...{ image, scale, rotate, setChanged }} {...rest} />,
    [image, rest, rotate, scale, setChanged],
  );

  return (
    <Stack spacing={1} maxWidth="400px" alignItems="center">
      <OurFileButton label="Select Photo" onImage={found} startIcon={<ImageSearch />} size="small" />
      {!isMobile && <Typography> or drag and drop a file below</Typography>}
      <Dropzone onDrop={handleDrop} multiple={false} accept={accept} noClick noKeyboard>
        {avatarEdit}
      </Dropzone>
      <Typography variant="subtitle2" align="center">{`${
        isMobile ? 'Touch' : 'Click'
      } and drag photo to reposition it or use buttons below to zoom in or out, or to rotate the photo`}</Typography>
      <Stack direction="row" spacing={0.5}>
        <OurZoomInTextButton label="Zoom In" onClick={zoomIn} size="small" />
        <OurZoomOutTextButton label="Zoom Out" onClick={zoomOut} size="small" />
        <OurRotateTextButton label="Turn Photo" onClick={turn} size="small" />
      </Stack>
    </Stack>
  );
};

AvatarDrop.whyDidYouRender = true;

export default AvatarDrop;
