import { RecordType } from '@petconsole/pure-base';
import { shopApiEntity } from '@petconsole/pure-fe-api';
import { ShopRecord } from '@petconsole/pure-shared';
import { fetchParams, sliceHookTypes, useOurSelectsByPropertyNameAndValue } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  RequiredUrlOrIdHooks,
  SelectHook,
  SelectSetHooks,
  SetHook,
  SetStringHook,
  TabValueHooks,
  ThunkApi,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = ShopRecord;

const apiEntity = shopApiEntity;
const { api, name: sliceName, proper, plural, pluralProper } = apiEntity;
const allShopName = `all${proper}`;
const cityShopName = `city${proper}`;

const shopAdmin = `${sliceName}Admin`;
const shop = sliceName;
const shops = plural;
const myShops = `my${pluralProper}`;

const Tab = { shopAdmin, shop, shops, myShops } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  shopAdmin: { default: 'Shops' },
  shop: { default: '' },
  shops: { default: '' },
  myShops: { default: 'My Shops' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySearch: true,
  hasCitySliver: true,
  hasNewestSliver: false,
  hasMySliver: true,
  hasUrlName: true,
  hasRating: true,
  sortProperty: 'shopName',
  tabs,
  sliceProperties: [
    { suffix: 'SettingsTabValue', default: 'General' },
    { suffix: 'ItemVariantsTabVariantOptionsChanged', default: false },
    { suffix: 'ItemVariantsTabAvailableVariantsIsOpen', default: true, toggle: true },
  ],
  slivers: [
    {
      prefix: 'all',
      payloadCreators: async (_: unknown, { getState }: ThunkApi) =>
        api.getListByShopName({ shopName: '' }, fetchParams(getState, sliceName, allShopName)),
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { urlOrIdHooks, crudHooks, sliverHooks, propertyHooks, citySearchHooks, ratingHooks, tabHooks } = sliceHookTypes;

export interface OurShopHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  urlOrIdHooks: RequiredUrlOrIdHooks;
  shopHooks: RequiredCrudSliverHooks;
  allShopHooks: RequiredSliverHooks;
  cityShopHooks: RequiredSliverHooks;
  myShopHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
  customHooks: {
    shop: {
      settingsTabValue: TabValueHooks;
      itemVariantsTab: {
        variantOptionsChanged: SelectSetHooks;
      };
    };
    allShop: {
      useSelectPublished: SelectHook;
    };
    cityShop: {
      useSelectPublished: SelectHook;
    };
  };
}

export const ourShopHooks: OurShopHooks = {
  entity: apiEntity,
  urlOrIdHooks: urlOrIdHooks(apiEntity, hooks) as RequiredUrlOrIdHooks,
  shopHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  allShopHooks: sliverHooks(apiEntity, 'All', hooks) as RequiredSliverHooks,
  cityShopHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  myShopHooks: sliverHooks(apiEntity, 'My', hooks) as RequiredSliverHooks,
  propertyHooks: propertyHooks(apiEntity, hooks),
  ratingHooks: ratingHooks(apiEntity, hooks),
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
  customHooks: {
    shop: {
      settingsTabValue: {
        useSelect: hooks.useOurSelectShopSettingsTabValue as SelectHook<string>,
        useSet: hooks.useOurSetShopSettingsTabValue as SetStringHook,
      },
      itemVariantsTab: {
        variantOptionsChanged: {
          useSelect: hooks.useOurSelectShopItemVariantsTabVariantOptionsChanged as SelectHook<boolean>,
          useSet: hooks.useOurSetShopItemVariantsTabVariantOptionsChanged as SetHook,
        },
      },
    },
    allShop: {
      useSelectPublished: () =>
        useOurSelectsByPropertyNameAndValue<boolean>({
          sliceName,
          sliverName: allShopName,
          propertyName: 'published',
          value: true,
        }),
    },
    cityShop: {
      useSelectPublished: () =>
        useOurSelectsByPropertyNameAndValue<boolean>({
          sliceName,
          sliverName: cityShopName,
          propertyName: 'published',
          value: true,
        }),
    },
  },
};

export default reducer;
