import OurTextLink from '../../links/OurTextLink';

interface EntityCardTitleProps {
  preview?: boolean;
  entityTitle: string;
  entityIdPath: string;
  entityIds?: string[];
}

const EntityCardTitle = ({ preview, entityTitle, entityIdPath, entityIds }: EntityCardTitleProps) => (
  <OurTextLink to={entityIdPath} text={entityTitle} state={{ entityIds }} readOnly={preview} />
);

EntityCardTitle.whyDidYouRender = true;

export default EntityCardTitle;
