import { properCase, properToWords } from '@petconsole/pure-base';
import { anySite, pclServiceCode, pclServiceCodes } from '../constants';
import { webPath } from '../misc/webPath';
import ourAvatarKey from '../misc/ourAvatarKey';
import ourImageKey from '../misc/ourImageKey';
import {
  AdminOnly,
  DataEntityColumnOptions,
  DataEntityType,
  EntityAttributes,
  EntityName,
  PclServiceCode,
  TestEntityOptions,
  WebPath,
} from '../types';
import { defaultAdminOnly, defaultColumns, defaultTabs, entityCategory } from './constants';

type OverridableAttributes = Pick<
  EntityAttributes,
  | 'plural'
  | 'typeWords'
  | 'category'
  | 'idName'
  | 'selectIdName'
  | 'nameField'
  | 'addField'
  | 'addLabel'
  | 'addTitle'
  | 'addressLabel'
  | 'columns'
  | 'path'
  | 'tabs'
  | 'maxImages'
  | 'site'
  | 'memberOwned'
  | 'ownerIfEditing'
  | 'restrictGet'
  | 'shared'
  | 'subscribable'
  | 'serviceCode'
  | 'apiPath'
  | 'loadLimit'
  | 'addInstructions'
  | 'eventType'
>;

interface AttributesProps extends Partial<Omit<OverridableAttributes, 'columns'>> {
  adminOnly?: Partial<AdminOnly>;
  columns?: Partial<DataEntityColumnOptions>;
  test?: Partial<TestEntityOptions>;
}

interface EntityAttributesProps {
  name: EntityName;
  attributes: AttributesProps;
}

const entityAttributes = ({ name, attributes }: EntityAttributesProps): EntityAttributes => {
  const pclServiceCodeName = name as PclServiceCode;
  const proper = properCase(name) as EntityAttributes['proper'];
  const {
    plural = `${name}s`,
    typeWords = properToWords(proper),
    category = entityCategory.business,
    idName = `${name}Id`,
    nameField = 'name',
    addressLabel = 'City/Address',
    tabs = defaultTabs,
    maxImages = 0,
    site = anySite.anySite,
    memberOwned = false,
    ownerIfEditing = false,
    restrictGet = true,
    shared = false,
    subscribable = false,
    serviceCode = pclServiceCodes.includes(pclServiceCodeName) ? pclServiceCode[pclServiceCodeName] : null,
    loadLimit = 0,
    ...override
  } = attributes;

  const columns = { ...defaultColumns, ...override.columns };

  const {
    selectIdName = idName,
    path = webPath[plural as WebPath] || '',
    apiPath = `/${plural}`,
    addField = nameField,
    addLabel = `${typeWords} Name`,
    addInstructions = columns.address
      ? `Please enter the name of your ${category}, and an address (or city) we can list your ${category} under. Please be sure to choose one of the suggestions Google makes when you start typing the city name.`
      : `Please enter the name of your ${category}.`,
    addTitle = `Add New ${typeWords}`,
  } = override;

  return {
    type: properCase(name) as DataEntityType,
    ...{ name, proper, plural },
    pluralProper: properCase(plural),
    typeWords,
    possessiveText: `${typeWords}'${typeWords.endsWith('s') ? '' : 's'}`,
    category,
    idName,
    selectIdName,
    nameField,
    addressLabel,
    columns,

    ...{ path, tabs, maxImages },
    avatarKey: ourAvatarKey(name),
    imageKey: ourImageKey(name),
    eventType: { added: `${name}Added`, deleted: `${name}Deleted`, updated: `${name}Updated`, ...override.eventType },

    ...{ site, memberOwned, ownerIfEditing, restrictGet, shared, subscribable, serviceCode },

    // api: ApiType;
    ...{ apiPath, loadLimit },

    ...{ addField, addLabel, addInstructions, addTitle },

    adminOnly: { ...defaultAdminOnly, ...override.adminOnly },
    test: {
      invalidData: () => ({ [nameField]: [] }),
      validData: () => ({ [nameField]: 'New Name' }),
      ...override.test,
    },
  };
};

export default entityAttributes;
