// noinspection ES6PreferShortImport

import { MouseEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText, SxProps, Theme } from '@mui/material';
import { ourDefaultMenuItemIconWidth } from '../../../constants';
import { OurAppContext } from '../../../context/OurAppContext';
import { AppMenuItem } from '../../../types';

interface OurAppMenuItemProps {
  item: AppMenuItem;
  sx?: SxProps<Theme>;
}

const OurAppMenuItem = ({ item, sx }: OurAppMenuItemProps) => {
  const navigate = useNavigate();
  const { setMenuOpen } = useContext(OurAppContext);

  const [Icon, text, path, text2] = item;

  const onMenuClick = (event: MouseEvent) => {
    event.preventDefault();
    setMenuOpen(false);
    navigate(path);
  };

  return (
    <ListItemButton onClick={onMenuClick} {...(sx && { sx })}>
      <ListItemIcon sx={{ minWidth: ourDefaultMenuItemIconWidth }}>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={text} secondary={text2} />
    </ListItemButton>
  );
};

OurAppMenuItem.whyDidYouRender = true;

export default OurAppMenuItem;
