import { useCallback, useEffect, useRef } from 'react';
import { ourCommentHooks } from '@petconsole/pure-fe-state';
import { useOurSafeEffect, UseOurSafeEffectProps } from '@petconsole/pure-fe-utils';
import { CommentRecords } from '@petconsole/pure-shared';

interface UseCommentsProps {
  commentOnId: string;
}

const useComments = ({ commentOnId }: UseCommentsProps) => {
  const { commentHooks, customHooks } = ourCommentHooks;
  const comments = customHooks.byCommentOnId.useSelects(commentOnId) as CommentRecords;
  const fetches = customHooks.byCommentOnId.useFetches();
  const reset = commentHooks.useReset();

  const fetch = useCallback(() => fetches(commentOnId), [fetches, commentOnId]);

  const loadedId = useRef('');

  useEffect(() => {
    if (commentOnId) reset();
  }, [commentOnId, reset]);

  useOurSafeEffect({
    action: fetch as UseOurSafeEffectProps['action'],
    args: [commentOnId],
    skip: loadedId.current === commentOnId,
    final: () => loadedId.current = commentOnId,
  });

  return { comments, fetch, reset };
};

export default useComments;
