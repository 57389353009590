import { useMemo } from 'react';
import { ourKeysInclude } from '@petconsole/pure-fe-utils';
import { ExtendedFormik } from '@petconsole/pure-shared';
import OurPhoneNumber from '../../inputs/phoneNumber/OurPhoneNumber';
import OutlinedGridContent from '../outlinedGrid/OutlinedGridContent';
import OurText from './OurText';

interface OurContactItemsProps {
  formik: ExtendedFormik;
  isOwner: boolean;
  readOnly?: boolean;
}

const OurContactItems = ({ formik, isOwner, readOnly }: OurContactItemsProps) => {
  const gridTextItemProps = useMemo(() => ({ rowGap: 1, ml: 0, pr: 2 }), []);

  const appointments = 'appointments';
  const hasAppointments = ourKeysInclude(formik.initialValues, appointments);
  const ownerId = formik.values.ownerName ? 'ownerName' : 'memberName';

  return (
    <OutlinedGridContent id="contacts" label="Contacts" sx={gridTextItemProps}>
      {(isOwner || formik.values.ownerName) && <OurText formik={formik} id={ownerId} label="Owner (Member)" readOnly />}
      <OurText formik={formik} id="contactName" readOnly={readOnly} />
      <OurPhoneNumber formik={formik} autoComplete="off" readOnly={readOnly} />
      <OurText formik={formik} id="email" readOnly={readOnly} />
      <OurText formik={formik} id="website" label="Website" readOnly={readOnly} />
      {hasAppointments && <OurText formik={formik} id={appointments} readOnly={readOnly} />}
    </OutlinedGridContent>
  );
};

OurContactItems.whyDidYouRender = true;

export default OurContactItems;
