import { RecordType } from '@petconsole/pure-base';

interface ReduceFormikPartsProps {
  parts: string[];
  initial: [RecordType, RecordType];
}

type ReduceFormikPartsType = ({ parts, initial }: ReduceFormikPartsProps) => [string, boolean];

type ReducerType = (
  [errors, touched]: readonly [RecordType | string, RecordType | boolean],
  idPart: string,
  currentIndex: number,
) => [RecordType | string, RecordType | boolean];

const reduceFormikParts: ReduceFormikPartsType = ({ parts, initial }) => {
  const reducer: ReducerType = ([errors, touched], idPart: string, currentIndex: number) => {
    const moreParts = currentIndex + 1 < parts.length;
    const properties = idPart.split('[');
    const property = properties[0];

    const errorsIsObject = typeof errors === 'object' && errors !== null;
    const touchedIsObject = typeof touched === 'object' && touched !== null;

    const defaultErrors: RecordType | string = moreParts ? {} : '';
    const defaultTouched: RecordType | boolean = moreParts ? {} : false;

    if (properties.length === 1)
      return [
        errorsIsObject && errors[property] ? (errors[property] as RecordType) : defaultErrors,
        touchedIsObject && touched[property] ? (touched[property] as RecordType) : defaultTouched,
      ];

    const index = Number(properties[1].split(']')[0]);
    const errorsIsArray = errorsIsObject && Array.isArray(errors[property]);
    const touchedIsArray = touchedIsObject && Array.isArray(touched[property]);
    const nextErrors = errorsIsArray ? ((errors[property] as RecordType)[index] as RecordType) : defaultErrors;
    const nextTouched = touchedIsArray ? ((touched[property] as RecordType)[index] as RecordType) : defaultTouched;

    return [nextErrors, nextTouched];
  };

  return parts.reduce(reducer, initial) as [string, boolean];
};

export default reduceFormikParts;
