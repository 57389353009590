import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { GridSize } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import { MaybeBoolean, RecordType } from '@petconsole/pure-base';
import { MaybeBreakpoints } from '../types';
import useOurWidth from './useOurWidth';

interface UseOurGridCardCenterSizesProps {
  marginColumns?: MaybeBoolean;
  leftMargins?: MaybeBreakpoints;
  margins?: MaybeBreakpoints;
  rightMargins?: MaybeBreakpoints;
}

const useOurGridCardCenterSizes = ({ marginColumns, leftMargins, margins, rightMargins }: UseOurGridCardCenterSizesProps) => {
  // By default, there are no margins on phone or tablet,
  // however, caller can explicitly turn on/off margin columns
  const usingMarginColumns = marginColumns != null ? marginColumns : !isMobile;
  const centerSizes = useRef<RegularBreakpoints>();
  const ourWidth = useOurWidth(); // triggers calculation below on any change to screen width

  // (Re)calculate center grid sizes if the provided margins change or browser breakpoint triggered
  useEffect(() => {
    if (!usingMarginColumns) return;

    const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
    const leftSize= (leftMargins || margins || {}) as RecordType;
    const rightSize = (rightMargins || margins || {}) as RecordType;
    const center: RecordType = {};

    let lastLeft = false as boolean | GridSize;
    let lastRight = false as boolean | GridSize;

    sizes.forEach((size) => {
      leftSize[size] = leftSize[size] === false ? leftSize[size] : leftSize[size] || lastLeft;
      lastLeft = leftSize[size] as boolean | GridSize;
      rightSize[size] = rightSize[size] === false ? rightSize[size] : rightSize[size] || lastRight;
      lastRight = rightSize[size] as boolean | GridSize;
      center[size] = 12 - Number(lastLeft || 0) - Number(lastRight || 0);
    });

    centerSizes.current = center;
  }, [ourWidth, usingMarginColumns, leftMargins, margins, rightMargins]);

  return { centerSizes: centerSizes.current, usingMarginColumns };
};

export default useOurGridCardCenterSizes;
