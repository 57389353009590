import { Twitter } from '@mui/icons-material';
import OurExternalIcon from '../icons/OurExternalIcon';

interface OurTwitterIconProps {
  href?: string;
  account?: string;
}

const OurTwitterIcon = ({ href, account = 'petconsole' }: OurTwitterIconProps) => (
  <OurExternalIcon icon={<Twitter />} href={href || `https://www.twitter.com/${account}`} aria="Twitter" />
);

OurTwitterIcon.whyDidYouRender = true;

export default OurTwitterIcon;
