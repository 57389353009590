// NOTE: This slice only holds variants for one shop item at a time!
import { RecordType } from '@petconsole/pure-base';
import { shopItemVariantApiEntity } from '@petconsole/pure-fe-api';
import { ShopItemVariantRecord } from '@petconsole/pure-shared';
import { fetchParams, sliceHookTypes, useOurDispatchValue } from '../../../helpers';
import { BaseSliceOptions, OurEntityHooks, RequiredSliverByHooks, ThunkApi } from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';
import { saveShopItemVariantsReducers, saveShopItemVariantsThunk } from './saveShopItemVariants';

type ApiEntityRecord = ShopItemVariantRecord;

const apiEntity = shopItemVariantApiEntity;
const { api, name: sliceName } = apiEntity;

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: false,
  hasNewestSliver: false,
  hasTabValues: false,
  hasDefaultProperties: false,
  comparer: (a: ApiEntityRecord, b: ApiEntityRecord) => a.order - b.order,
  tabs: undefined,
  slivers: [
    {
      prefix: sliceName,
      fetchesByValue: true,
      payloadCreators: async (shopItemId: string, { getState }: ThunkApi) =>
        api.getListByShopItem(shopItemId, fetchParams(getState, sliceName, sliceName)),
      extraReducers: [saveShopItemVariantsReducers],
    },
  ],
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { sliverHooks } = sliceHookTypes;

export interface OurShopItemVariantHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  shopItemVariantHooks: RequiredSliverByHooks;
  customHooks: {
    useSaveVariants: () => ReturnType<typeof useOurDispatchValue>;
  };
}

export const ourShopItemVariantHooks: OurShopItemVariantHooks = {
  entity: apiEntity,
  shopItemVariantHooks: sliverHooks(apiEntity, '', hooks) as RequiredSliverByHooks,
  customHooks: {
    useSaveVariants: () => useOurDispatchValue(saveShopItemVariantsThunk),
  },
};

export default reducer;
