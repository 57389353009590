// noinspection ES6PreferShortImport

import { FormEvent, MouseEvent, ReactNode, useState } from 'react';
import { DialogActions, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { camelToWords, eMessage, newId, RecordType } from '@petconsole/pure-base';
import {
  CreateEntity,
  EntityFormik,
  EntityRecord,
  ExtendedFormik,
  ReturnsVoid,
  SetState,
  schema,
} from '@petconsole/pure-shared';
import useOurError from '../../error/useOurError';
import useOurSnacks from '../../hooks/useOurSnacks';
import OurPhoneNumber from '../../inputs/phoneNumber/OurPhoneNumber';
import doWork from '../../misc/doWork/doWork';
import OurButton from '../buttons/OurButton';
import OurGoogleAddress from '../google/OurGoogleAddress';
import OurText from '../inputs/OurText';
import OurForm from '../misc/OurForm';
import OurDraggableDialog from './OurDraggableDialog';

export interface OurAddEntityOtherInputsProps<T extends EntityRecord = EntityRecord> {
  formik: EntityFormik<T> | ExtendedFormik;
}

export type OurAddEntityOtherInputs<T extends EntityRecord = EntityRecord> = ReactNode | ((props: OurAddEntityOtherInputsProps<T>) => ReactNode);

interface OurAddEntityDialogProps<T extends EntityRecord = EntityRecord> {
  entity: string;
  entityId?: string;
  title?: string;
  instructions?: string;
  label?: string;
  inputProperty?: string | null;
  addAddress?: boolean;
  addressLabel?: string;
  addPhone?: boolean;
  initialValues?: T;
  otherInputs?: OurAddEntityOtherInputs<T>;
  otherValidation?: RecordType;
  defaultData?: Partial<T>;
  isOpen: boolean;
  setIsOpen: SetState<boolean>;
  setAddedId: SetState<string>;
  create: CreateEntity<T>;
  reset?: ReturnsVoid;
}

const OurAddEntityDialog = <T extends EntityRecord = EntityRecord>({
  entity = 'entity',
  entityId = 'entityId',
  title = 'Add New Entity',
  instructions = 'Please enter the name and address of the entity to add',
  label = 'Entity Name',
  inputProperty = 'name',
  addAddress = false,
  addressLabel = 'Address',
  addPhone = false,
  initialValues,
  otherInputs,
  otherValidation = {},
  defaultData = {},
  isOpen,
  setIsOpen,
  setAddedId,
  create,
  reset,
}: OurAddEntityDialogProps<T>) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const { log } = useOurError();
  const { warn } = useOurSnacks();

  const capitalizedEntity = camelToWords(entity);
  const lowercaseEntity = capitalizedEntity.toLowerCase();
  const directions = instructions.replace('entity', lowercaseEntity);
  const [busy, setBusy] = useState(false);

  const [addressInput, setAddressInput] = useState('');

  const onSubmit = async (values: T) => {
    // const { name, address, phoneNumber } = values;

    return doWork({
      tryCallback: async () => {
        setBusy(true);

        const idName = entityId.replace('entity', entity);
        const id = (values[idName] || newId()) as string;

        const created = await create({
          [idName]: id,
          // [inputProperty]: name,
          // ...(addAddress && { address }),
          // ...(addPhone && { phoneNumber }),
          ...values,
          ...defaultData,
        } as T);

        if (typeof created?.unwrap === 'function') await created.unwrap();

        if (reset) reset();

        setBusy(false);
        setAddedId(id);
      },
      catchCallback: (e) => {
        const message = eMessage(e);

        warn(`Adding ${lowercaseEntity} failed: ${message}.`);
        log(`Error adding ${lowercaseEntity}: ${message}`, e).then();
        setBusy(false);
      },
    });
  };

  const { googleLocation, object: schemaObject, phone, strim } = schema;

  const formik = useFormik<T>({
    initialValues: {
      ...(inputProperty && { [inputProperty]: '' }),
      ...(addAddress && { address: '' }),
      ...(addPhone && { phoneNumber: '' }),
      ...initialValues,
    } as T,
    validationSchema: schemaObject({
      ...(inputProperty && { [inputProperty]: strim().required(`${capitalizedEntity} is required`) }),
      ...(addAddress && { address: googleLocation().required('Address is required') }),
      ...(addPhone && { phoneNumber: phone() }),
      ...otherValidation,
    }),
    onSubmit,
  }) as EntityFormik<T>;

  const onCancel = () => {
    setIsOpen(false);
  };

  const onOk = (event: MouseEvent) => {
    event.preventDefault();
    formik.handleSubmit(event as unknown as FormEvent<HTMLFormElement>);
  };

  const inputs =
    typeof otherInputs === 'function' ? otherInputs({ formik }) : otherInputs ? [otherInputs] : otherInputs;

  const extraInputs: ReactNode[] = Array.isArray(inputs)
    ? inputs.map((input, index) => ({
        ...input,
        key: `xi-${index}`,
        props: { ...input.props, formik },
      }))
    : [];

  return (
    <OurDraggableDialog
      title={title.replace('Entity', capitalizedEntity)}
      isOpen={isOpen}
      onClose={onCancel}
      draggable={!xs}
    >
      <OurForm formik={formik} sx={{ px: 1.5, pb: 1, rowGap: 1 }}>
        <Typography py={1}>{addAddress ? directions : directions.replace('and address ', '')}</Typography>
        {inputProperty && (
          <OurText
            formik={formik as EntityFormik}
            id={inputProperty}
            label={label.replace('Entity', capitalizedEntity)}
            autoFocus
            required
            // allowLastPass={false}
          />
        )}
        {addAddress && (
          <OurGoogleAddress
            formik={formik as EntityFormik}
            id="address"
            label={addressLabel}
            inputValue={addressInput}
            setInputValue={setAddressInput}
            required
          />
        )}
        {addPhone && <OurPhoneNumber formik={formik as EntityFormik} autoComplete="off" />}
        {initialValues && Array.isArray(inputs) && <>{extraInputs.map((extraInput) => extraInput)}</>}
        {busy && <LinearProgress sx={{ py: 1 }} />}
      </OurForm>
      <DialogActions>
        <OurButton label="Cancel" color="secondary" onClick={onCancel} autoFocus disabled={busy} />
        <OurButton label="Ok" type="submit" onClick={onOk} disabled={busy} />
      </DialogActions>
    </OurDraggableDialog>
  );
};

OurAddEntityDialog.whyDidYouRender = true;

export default OurAddEntityDialog;
