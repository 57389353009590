import { createSlice as createReduxSlice, CreateSliceOptions, Reducer, Slice as ReduxSlice } from '@reduxjs/toolkit';
import { properCase, RecordType } from '@petconsole/pure-base';
import { ratingApi, reactionApi } from '@petconsole/pure-fe-api';
import { Api } from '@petconsole/pure-shared';
import {
  sliceAddComparers,
  sliceAddCreators,
  sliceAddReducerCases,
  sliceAddSlivers,
  sliceEntityThunks,
  sliceFetchByPropertyThunks,
  sliceFetchRatingThunk,
  sliceOverrideFlags,
  sliceOverrideOptions,
  sliceReactionThunks,
  sliceReducers,
  sliceUpdateRatingThunk,
  sliceUpdateSlivers,
} from '../../helpers';
import { BaseSliceProps, RecordOfBoolean, Slice } from '../../types';
import { sliceDefaultTabs } from '../../helpers/slices/sliceDefaultTabs';
import { sliceAssembleHooks } from '../../helpers/slices/sliceAssembleHooks';
import { sliceInitialState } from '../../helpers/slices/sliceInitialState';

class BaseEntitySlice<T extends RecordType = RecordType> {
  // private readonly slice: Slice = {
  readonly slice: Slice<T> = {
    name: '',
    proper: '',
    plural: '',
    pluralProper: '',
    idName: '',
    flag: {
      hasMySliver: false,
      hasCitySliver: true,
      hasNewestSliver: true,
      hasOldestSliver: false,
      hasCitySearch: false,
      hasTabValues: true,
      hasDefaultProperties: true,
      hasRating: false,
      hasReaction: false,
      hasNextPrev: false,
      hasUrlName: false,
      canDelete: false,
    },
    option: {
      selectId: undefined,
      sortProperty: 'name',
      comparer: undefined,
      payloadCreator: undefined,
      payloadCreators: undefined,
      entityTabValue: 'General',
      myEntityTabValue: 'General',
      entitiesTabValue: 'By City',
      adminTabValue: 'By City',
      tabs: undefined,
      sliceTabs: undefined,
      sliceProperties: undefined,
      reducers: {},
      fetchOneByProperty: undefined,
      fetchManyByProperty: undefined,
      afterCreate: undefined,
      afterUpdate: undefined,
      afterFetch: undefined,
      createSlice: createReduxSlice,
    },
    tabPrefixes: [],
    slivers: [],
    sliver: {},
    thunk: {
      fetchEntity: undefined,
      createEntity: undefined,
      updateEntity: undefined,
      fetchRating: undefined,
      updateRating: undefined,
      fetchReaction: undefined,
      reactToEntity: undefined,
      toggleLike: undefined,
    },
    action: {},
    api: { limit: 0, entity: {} as Api<T>, rating: ratingApi, reaction: reactionApi },
  };

  private readonly reducers: RecordType = {};
  private entitySlice = {} as ReduxSlice<RecordType>;

  initialState: RecordType = {};
  actions: RecordType = {};
  reducer = {} as Reducer;
  hooks: RecordType = {};

  constructor({ sliceName, pluralName, api, options }: BaseSliceProps<T>) {
    const plural = pluralName || options?.plural || `${sliceName}s`;

    this.slice = {
      ...this.slice,
      name: sliceName || options?.name,
      proper: options?.proper || properCase(sliceName || ''),
      plural,
      pluralProper: options?.pluralProper || properCase(plural),
      // TODO: idName should allow override (selectId or idName? or api.idName. Do we need both?)
      idName: options?.idName || `${sliceName}Id`,
    };

    this.slice.api = {
      ...this.slice.api,
      limit: options?.limit ?? options?.loadLimit ?? this.slice.api.limit,
      entity: api || options.api,
      rating: options.ratingApi || this.slice.api.rating,
      reaction: options.reactionApi || this.slice.api.reaction,
    };

    this.slice.flag = sliceOverrideFlags(<RecordOfBoolean>this.slice.flag, options);
    this.slice.option = sliceOverrideOptions(this.slice.option, options);
    this.slice.tabs = sliceDefaultTabs(this.slice);

    this.slice = { ...this.slice, ...sliceAddSlivers<T>(this.slice, options?.slivers) } as Slice<T>;

    this.slice.thunk.fetchRating = sliceFetchRatingThunk(this.slice);

    this.addFetchByPropertyThunks(this.slice);

    if (this.slice.flag.hasReaction) this.slice.thunk = { ...this.slice.thunk, ...sliceReactionThunks(this.slice) };

    this.slice.sliver = sliceAddComparers(this.slice);
    this.slice.sliver = sliceAddCreators(this.slice);
    this.slice.sliver = sliceUpdateSlivers(this.slice);
    this.slice.thunk = { ...this.slice.thunk, ...sliceEntityThunks(this.slice) };

    // updateRating thunk depends on fetchOneCreator for refreshing changes
    this.slice.thunk.updateRating = sliceUpdateRatingThunk(this.slice);

    this.initialState = sliceInitialState(this.slice);
    this.reducers = sliceReducers(this.slice, this.initialState, this.slice.tabPrefixes);

    this.createEntitySlice();

    this.hooks = sliceAssembleHooks(this.slice);
    //
    // if (this.slice.name === 'user') console.log('user sliver:', this.slice.sliver['user']);
  }

  // Set thunks for custom fetches, if any
  private addFetchByPropertyThunks = <T extends RecordType = RecordType>(slice: Slice<T>) => {
    const { fetchOneByProperty: fetchOne, fetchManyByProperty: fetchMany } = slice.option;
    const { fetchOneThunk, fetchManyThunk } = sliceFetchByPropertyThunks(slice);

    if (fetchOne) fetchOne.thunk = fetchOneThunk;
    if (fetchMany) fetchMany.thunk = fetchManyThunk;
  };

  // Create the slice itself
  private createEntitySlice = () => {
    this.entitySlice = this.slice.option.createSlice({
      name: this.slice.name,
      initialState: this.initialState,
      reducers: this.reducers as CreateSliceOptions['reducers'],
      extraReducers: (builder) => sliceAddReducerCases(builder, this.slice),
    });

    this.reducer = this.entitySlice.reducer;
    this.slice.action = this.entitySlice.actions; // Used internally
    this.actions = this.entitySlice.actions; // For external access
  };
}

export default BaseEntitySlice;
