import { RecordType } from '@petconsole/pure-base';
import { RecordOfBoolean } from '../../types';

export const sliceOverrideFlags = (currentFlags: RecordType, overrideFlags: RecordType) => {
  const flags: RecordOfBoolean = {};

  Object.keys(currentFlags).forEach((key) => {
    const value = currentFlags[key];

    if (typeof value !== 'boolean') return;

    flags[key] = value;

    const overrideValue = overrideFlags[key];

    if (typeof overrideValue === 'boolean') flags[key] = overrideValue;
  });

  return flags;
};
