/* eslint-disable camelcase */
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { CityLocation, MaybeNull } from '@petconsole/pure-base';
import { ReturnsVoid, SetState } from '@petconsole/pure-shared';
import OurGoogleInput from './OurGoogleInput';

type Autocomplete = google.maps.places.Autocomplete;

interface OurGoogleCityProps {
  city?: string;
  setCity: SetState<string>;
  setLocation: SetState<CityLocation | undefined>;
}

const OurGoogleCity = ({ city, setCity, setLocation }: OurGoogleCityProps) => {
  const autoCity = useRef<MaybeNull<Autocomplete>>(null);
  const autoCityRef = useRef<MaybeNull<HTMLInputElement>>(null);
  const onCityChanged = useRef<MaybeNull<ReturnsVoid>>(null);
  const [helperText, setHelperText] = useState('');
  const [retryPlaces, setRetryPlaces] = useState(0);

  const onChangeCity = (event: ChangeEvent<{ value: string }>) => setCity(event.target.value);

  const onClearLocation = () => setLocation(undefined);

  onCityChanged.current = () => {
    // NOTE: Attempt to destructure getPlace out of autoCity.current resulted in: TypeError:
    // this.get is not a function.
    // noinspection JSUnresolvedFunction
    const place = (autoCity.current as Autocomplete).getPlace();

    if (!place?.geometry?.location) {
      // User entered a name not suggested and pressed Enter, or the getPlace request failed.
      setHelperText('Please select one of the Google suggestions');
      onClearLocation();
    } else {
      const { address_components: address, geometry } = place;
      const location: CityLocation = {
        latitude: geometry?.location?.lat(),
        longitude: geometry?.location?.lng(),
      };

      (address as google.maps.GeocoderAddressComponent[]).forEach(({ long_name = '', short_name = '', types }) => {
        switch (types[0]) {
          case 'locality': // Kamloops
            location.city = long_name;
            break;
          case 'administrative_area_level_1': // British Columbia / BC
            location.province = short_name;
            break;
          case 'country': // Canada / CA
            location.country = short_name;
            break;
          default:
        }
      });
      const { city: newCity, province, country } = location;

      setHelperText('');
      setLocation(location);
      // Unfortunately, formatted_address can include postal component(s)
      onChangeCity({
        target: { value: [newCity, province, country].filter((x) => !!x).join(', ') },
      } as ChangeEvent<{ value: string }>);
    }
  };

  useEffect(() => {
    if (autoCity.current) return;

    const options = {
      fields: [
        'place_id',
        'address_components',
        'geometry',
        'formatted_address',
        'name',
        // 'utc_offset_minutes', - using this causes deprecation warnings - Google bug
        // 'type',
      ],
      types: ['(cities)'],
    };

    const { google } = window;
    const { maps } = google || {};
    const { places, event } = maps || {};

    if (!places) {
      setRetryPlaces(retryPlaces + 1);

      return;
    }

    const { Autocomplete } = places;
    const { clearInstanceListeners } = event;

    autoCity.current = new Autocomplete(autoCityRef.current as HTMLInputElement, options);

    const listener = autoCity.current.addListener('place_changed', onCityChanged.current as ReturnsVoid);

    return function cleanUp() {
      clearInstanceListeners(listener);

      const containers = document.querySelectorAll('.pac-container');

      containers.forEach((container) => container.remove());
    };
  }, [retryPlaces]);

  return (
    <OurGoogleInput
      ref={autoCityRef}
      id="city"
      placeholder="Start typing a city name"
      helperText={helperText}
      onChange={onChangeCity}
      value={city}
    />
  );
};

OurGoogleCity.whyDidYouRender = true;

export default OurGoogleCity;
