import { Box, Collapse, Grid, Typography } from '@mui/material';
import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import { Images, SetState } from '@petconsole/pure-shared';
import OurCloseButton from '../buttons/OurCloseButton';
import OurNextButton from '../buttons/OurNextButton';
import OurPrevButton from '../buttons/OurPrevButton';
import OurCardImage from '../cards/OurCardImage';

interface OurImageTabBarViewerProps {
  isOpen: boolean;
  setOpen: SetState<boolean>;
  images: Images;
  tabValue: number;
  setTabValue: SetState<number>;
  tabsId: string;
  variableHeight?: boolean; // Can appear jumpy if enabled in a list of cards or similar
}

const OurImageTabBarViewer = ({
  isOpen,
  setOpen,
  images,
  tabValue,
  setTabValue,
  tabsId,
  variableHeight,
}: OurImageTabBarViewerProps) => {
  const { s3Key } = images[tabValue];
  const { title } = images[tabValue];

  const width = document.getElementById(tabsId)?.clientWidth;

  const onClose = () => setOpen(false);
  const onChangeTab = (n: number) => {
    let newTab = tabValue + n;

    if (newTab > images.length - 1) newTab = 0;
    if (newTab < 0) newTab = images.length - 1;

    setTabValue(newTab);
  };

  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <Typography align="center" py={1}>{`${tabValue + 1} / ${images.length}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography align="center" py={1} flex={1}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <OurCloseButton onClick={onClose} />
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <OurCardImage
          width={variableHeight ? '100%' : 'auto'}
          height={variableHeight ? 'auto' : width || 'auto'}
          image={s3Key.startsWith('http') ? s3Key : ourMemberImageUrl(s3Key)}
          alt={title}
          sx={{ objectFit: 'contain', zIndex: 1 }}
        />
        <OurPrevButton
          tip="Previous"
          onClick={() => onChangeTab(-1)}
          sx={{ position: 'absolute', top: '50%', left: 0, color: 'black' }}
        />
        <OurNextButton
          tip="Next"
          onClick={() => onChangeTab(1)}
          sx={{ position: 'absolute', top: '50%', right: 0, color: 'black' }}
        />
      </Box>
    </Collapse>
  );
};

OurImageTabBarViewer.whyDidYouRender = true;

export default OurImageTabBarViewer;
