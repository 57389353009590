// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import entityAttributes from '../../entity/entityAttributes';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface BusRepair extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  busRepairId: string;
  name: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const busRepairEntity = entityAttributes({
  name: entityName.busRepair,
  attributes: {
    category: entityCategory.business,
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    restrictGet: false,
    site: anySite.busConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const busRepairSchema = entitySchema<BusRepair>(busRepairEntity, {
  name: strim().required('Business name is required'),
});

export const busRepairDefaults: Partial<BusRepair> = {
  name: '',
};

export interface BusRepairRecord extends BusRepair, RecordType {}

export type MaybeBusRepairRecord = MaybeNull<BusRepairRecord>;
export type BusRepairRecords = BusRepairRecord[];

export const initialBusRepair: InitialEntity<BusRepair, BusRepairRecord> = (overrides = {}) =>
  initialEntity<BusRepair>(busRepairEntity, { ...busRepairDefaults, ...overrides }) as BusRepairRecord;
