import { RecordType } from '@petconsole/pure-base';
import { Log } from '@petconsole/pure-shared';
import { authProviders } from '../constants';
import authFetchAuthSession from './authFetchAuthSession';

interface AuthGetProviderProps {
  log: Log;
}

const authGetProvider = async ({ log }: AuthGetProviderProps) => {
  let provider: string | null = null;

  try {
    const { session } = await authFetchAuthSession();

    if (!session?.tokens) return null;

    const identities = session.tokens.idToken?.payload.identities;

    if (!identities) return authProviders.email;

    if (!Array.isArray(identities) || identities[0] === null) return null;

    const identity = identities[0];

    if (typeof identity !== 'object' || !Object.hasOwn(identity, 'providerName')) return null;

    provider = (identity as RecordType).providerName as string;

    const providers = Object.values(authProviders).map((value) => value.toLowerCase());

    if (!providers.find((value) => value === provider?.toLowerCase())) {
      await log(`authGetProvider unexpected provider: ${provider || ''}.`);

      provider = null;
    }
  } catch (e) {
    if (e !== 'No current user' && typeof e === 'string' && !e.includes('not authenticated'))
      await log(`authGetProvider caught unexpected error: ${e}.`);
  }

  return provider;
};

export default authGetProvider;
