import { PropsWithChildren } from 'react';
import { Grid, Typography } from '@mui/material';
import OurMarginGridCard from '../components/cards/OurMarginGridCard';
import OurBackHeadings from '../components/headings/OurBackHeadings';
import OurSiteMemberText from '../components/misc/OurSiteMemberText';
import OurUnsplashImage from '../components/misc/unsplash/OurUnsplashImage';
import { commonMedia } from '../constants';

// noinspection SpellCheckingInspection
const OurAboutUsPage = ({ children }: PropsWithChildren) => (
  <OurMarginGridCard textAlign="center">
    <OurBackHeadings heading="About Us" />
    <Grid item xs={12}>
      <OurUnsplashImage
        image={commonMedia.aboutUs}
        alt="About Us image"
        imagePath="s/photos/about"
        authorPath="@goian"
        authorName="Ian Schneider"
      />
      <OurSiteMemberText paragraph strong />
      <Typography paragraph>
        Pet Console was founded by Sue and Richard Wilbur in 2019. We're based out of Kamloops, in the interior of
        beautiful British Columbia, Canada.
      </Typography>
      <Typography paragraph>
        We're passionate about our pets and knew we weren't the only pet loving people out there. We decided to create
        an online community and marketplace focused on pets, and incorporated Pet Console in the fall of 2019.
      </Typography>
      <Typography paragraph>
        After working on an initial website on a part-time basis, we launched our first version of PETCONSOLE.COM in the
        spring of 2020.
      </Typography>
      <Typography paragraph>
        After Richard was laid off in the summer of 2020, he was accepted into a one year government sponsored
        self-employment program, and has been working on Pet Console full time since then.
      </Typography>
      <Typography paragraph={!!children}>
        As a result, we're able to move faster and release improvements more frequently. Between Richard's extensive
        software development experience, and Sue's natural talent for testing and discovering bugs, we've been able to
        develop a quality site we are proud to share with pet parents worldwide.
      </Typography>
      {children}
    </Grid>
  </OurMarginGridCard>
);

OurAboutUsPage.whyDidYouRender = true;

export default OurAboutUsPage;
