import { useState } from 'react';
import { Image as ImageIcon } from '@mui/icons-material';
import { Avatar, Theme } from '@mui/material';
import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import { ExtendedFormik, ReturnsString } from '@petconsole/pure-shared';
import { ourDefaultImageThumbnail } from '../../constants';
import OurAvatarDialog, { OurAvatarDialogOnOkProps } from '../dialogs/OurAvatarDialog';
import useOurThumbnailOk, { OurThumbnailOnOk } from './useOurThumbnailOk';

export interface OurThumbnailProps {
  formik?: ExtendedFormik;
  id?: string;
  idName?: string;
  image?: string;
  defaultImage?: string;
  title?: string;
  getTitle?: ReturnsString;
  readOnly?: boolean;
  heading?: string;
  onOk?: OurThumbnailOnOk;
}

const OurThumbnail = ({
  formik,
  idName,
  id,
  image,
  defaultImage = ourDefaultImageThumbnail,
  title,
  getTitle,
  readOnly = false,
  heading = 'Thumbnail Editor',
  onOk,
}: OurThumbnailProps) => {
  const imageTitle = title || (getTitle && getTitle()) || '';
  const { onThumbnailOk } = useOurThumbnailOk({ formik, id, idName, title: imageTitle, onOk });

  const { getFieldProps } = formik || {};
  const thumbnail = getFieldProps ? getFieldProps(id || '').value : undefined;
  const isReadOnly = readOnly || formik?.isReadOnly;
  const imageSrc = image
    ? image
    : thumbnail?.src
    ? thumbnail.src
    : thumbnail?.s3Key
    ? ourMemberImageUrl(thumbnail.s3Key)
    : '';
  const onClick = isReadOnly ? undefined : () => setIsDialogOpen(true);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const avatarSx = {
    width: ({ spacing }: Theme) => spacing(7),
    height: ({ spacing }: Theme) => spacing(7),
    cursor: isReadOnly ? 'default' : 'pointer',
  };

  return (
    <>
      {imageSrc ? (
        <Avatar alt="Thumbnail" src={imageSrc} variant="square" onClick={onClick} sx={avatarSx} />
      ) : (
        <Avatar variant="square" onClick={onClick} sx={avatarSx}>
          <ImageIcon fontSize="large" />
        </Avatar>
      )}
      <OurAvatarDialog
        isOpen={isDialogOpen}
        title={heading}
        image={imageSrc || defaultImage}
        borderRadius={0}
        onOk={(onOkProps: OurAvatarDialogOnOkProps) => {
          setIsDialogOpen(false);

          onThumbnailOk(onOkProps);
        }}
        onCancel={() => setIsDialogOpen(false)}
      />
    </>
  );
};

OurThumbnail.whyDidYouRender = true;

export default OurThumbnail;
