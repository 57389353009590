import { useEffect, useState } from 'react';
import Autocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { memberApi } from '@petconsole/pure-fe-api';
import OurTypoText from '../misc/OurTypoText';
import OurText, { OurTextProps } from './OurText';

interface MemberLocationOption {
  memberId: string;
  memberName: string;
  city?: string;
  province?: string;
  country?: string;
}

type MemberOption = Pick<MemberLocationOption, 'memberId' | 'memberName'>;

interface OurMemberLookupProps<T extends RecordType = RecordType> extends OurTextProps<T> {
  id: string;
  value: MaybeNull<MemberOption>;
  maxOptions?: number;
}

const OurMemberLookup = <T extends RecordType = RecordType>({
  value,
  onChange,
  maxOptions = 10,
  ...rest
}: OurMemberLookupProps<T>) => {
  const { /* form, */ readOnly, formik } = rest;

  const [loading, setLoading] = useState(false);
  const [acOptions, setAcOptions] = useState<MemberLocationOption[]>([]);
  const [acInput, setAcInput] = useState('');
  const [debouncedAcInput, setDebouncedAcInput] = useState('');

  const skipLookup =
    !!value && acInput === value.memberName && !!acOptions.find(({ memberName }) => acInput === memberName);

  useEffect(() => {
    if (skipLookup) return;

    const timeoutId = setTimeout(() => setDebouncedAcInput(acInput), 500);

    return () => clearTimeout(timeoutId);
  }, [acInput, value, maxOptions, skipLookup]);

  useEffect(() => {
    if (skipLookup) return undefined;

    let active = true;

    if (!debouncedAcInput) {
      setAcOptions(value ? [value] : []);

      return undefined;
    }

    setLoading(true);

    memberApi
      .getListMemberNameStarts(debouncedAcInput, { limit: maxOptions })
      .then((data) => {
        if (!active) return;

        const options: MemberLocationOption[] = data.items.map(({ memberId, memberName, city, province, country }) => {
          return { memberId, memberName: memberName as string, city, province, country };
        });

        const found = value && options.find(({ memberId }) => memberId === value.memberId);

        setAcOptions([...(found ? [value] : []), ...options]);
      })
      .finally(() => setLoading(false));

    return () => {
      active = false;
    };
  }, [debouncedAcInput, value, maxOptions, skipLookup]);

  // Returning OurText is a workaround as MUI's AutoComplete doesn't support readOnly
  if (readOnly || formik?.isReadOnly /* || form?.readOnly */)
    return <OurText value={value ? value.memberName : ''} {...rest} />;

  const renderOption = (props: object, option: MemberLocationOption, state: AutocompleteRenderOptionState) => {
    const { memberName, city, province, country } = option;
    const { inputValue } = state;
    const match = memberName.toLowerCase().startsWith(inputValue.toLowerCase()) ? inputValue : '';
    const location = city ? `${city}, ${province}, ${country}` : '';

    return (
      <li {...props}>
        <div>
          {match && <span style={{ fontWeight: 700 }}>{memberName.substring(0, match.length)}</span>}
          <span style={{ fontWeight: 400 }}>{memberName.substring(match.length)}</span>

          {location && <OurTypoText typeProps={{ variant: 'body2', color: 'textSecondary' }} text={location} />}
        </div>
      </li>
    );
  };

  const getOptionLabel = (option: MemberOption) => option.memberName as string;

  const isOptionEqualToValue = (
    { memberName: optionMemberName }: MemberOption,
    { memberName: valueMemberName }: MemberOption,
  ) => optionMemberName?.toLowerCase() === valueMemberName?.toLowerCase();

  return (
    <Autocomplete<MemberOption>
      {...{ loading, getOptionLabel, isOptionEqualToValue, onChange, renderOption }}
      options={acOptions}
      // @ts-expect-error - why is it complaining that defaultValue is incompatible?
      defaultValue={null}
      value={value}
      onInputChange={(_event, newValue) => setAcInput(newValue)}
      // onBlur={onBlur}
      {...rest}
      autoComplete={false}
      renderInput={(params) => (
        <OurText<T> placeholder="Enter member name" {...params} fullWidth inputProps={{ ...params.inputProps }} />
      )}
    />
  );
};

OurMemberLookup.whyDidYouRender = true;

export default OurMemberLookup;
