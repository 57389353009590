import { RecordType } from '@petconsole/pure-base';
import { ourSaveStatus } from '../../constants';
import { AnyAsyncThunk, Slice, StateType, ThunkFulfilledMethod } from '../../types';
import thunkPendingReducer from './thunkPendingReducer';
import thunkRejectedReducer from './thunkRejectedReducer';

type UpdateEntityReducers = <T extends RecordType = RecordType>(slice: Slice<T>, { pending, fulfilled, rejected }: AnyAsyncThunk) => RecordType;

const updateEntityReducers: UpdateEntityReducers = (slice, { pending, fulfilled, rejected }) => {
  const { name: sliceName, idName } = slice;
  const statusProperty = 'writeStatus';

  const fulfilledReducer: ThunkFulfilledMethod<StateType, RecordType> = (state, action) => {
    const payload = action.payload;

    state[sliceName][statusProperty] = ourSaveStatus.succeeded;

    if (payload) {
      slice.slivers.forEach((sliverName) => {
        if (sliverName !== sliceName && !state[sliverName].ids.includes(payload[idName])) return;

        const { adapter } = slice.sliver[sliverName];

        adapter.upsertOne(state[sliverName], payload);
      });
    }

    if (slice.option.afterUpdate) slice.option.afterUpdate(state, action);
  };

  return {
    [String(pending)]: thunkPendingReducer(sliceName, statusProperty),
    [String(fulfilled)]: fulfilledReducer,
    [String(rejected)]: thunkRejectedReducer(sliceName, statusProperty),
  };
};

export default updateEntityReducers;
