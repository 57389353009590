import { ComponentType, lazy, LazyExoticComponent, PropsWithChildren, Suspense } from 'react';
import OurLinearProgress from '../components/misc/OurLinearProgress';

// export type LazyProp<T extends object = object> = () => Promise<{ default: ComponentType<PropsWithChildren<T>> }>;
export type LazyProp<T = PropsWithChildren> = () => Promise<{ default: ComponentType<T> }>;

interface OurLazyLoadProps<T = PropsWithChildren> {
  load: LazyProp<T>;
}

const OurLazyLoad = <T = PropsWithChildren,>({ load, ...props }: OurLazyLoadProps<T>) => {
  const LazyComponent = lazy<ComponentType<T>>(load) as LazyExoticComponent<ComponentType<unknown>>;

  return (
    <Suspense fallback={<OurLinearProgress />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

OurLazyLoad.whyDidYouRender = true;

export default OurLazyLoad;
