import { SetBoolean } from '@petconsole/pure-shared';
import useOurError from '../../error/useOurError';
import useOurSnacks from '../../hooks/useOurSnacks';
import saveEntityCatch from './saveEntityCatch';

const useSaveEntityCatch = () => {
  const { log } = useOurError();
  const { warnWithConflictCheck } = useOurSnacks();

  const saveCatch = (e: unknown, setIsSubmitting: SetBoolean) =>
    saveEntityCatch({ e, setIsSubmitting, warnWithConflictCheck, log });

  return { saveEntityCatch: saveCatch };
};

export default useSaveEntityCatch;
