import { businessApiEntity } from '@petconsole/pure-fe-api';
import { BusinessRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RatingHooks,
  RecordType,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = BusinessRecord;

const apiEntity = businessApiEntity;
const { name: sliceName, plural } = apiEntity;
const business = sliceName;
const businesses = plural;
const businessAdmin = `${sliceName}Admin`;

const Tab = { businessAdmin, business, businesses } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  businessAdmin: { default: '' },
  business: { default: '' },
  businesses: { default: '' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  hasRating: true,
  sortProperty: 'businessName',
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, ratingHooks, tabHooks } = sliceHookTypes;

export interface OurBusinessHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  businessHooks: RequiredCrudSliverHooks;
  cityBusinessHooks: RequiredSliverHooks;
  newestBusinessHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  ratingHooks: RatingHooks;
  tabHooks: TabHooks;
}

export const ourBusinessHooks: OurBusinessHooks = {
  entity: apiEntity,
  businessHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityBusinessHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestBusinessHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  ratingHooks: ratingHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
