import { ChangeEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { RecordType } from '@petconsole/pure-base';
import { ExtendedFormik } from '@petconsole/pure-shared';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import CheckboxInput, { CheckboxInputProps } from '../../inputs/react-hook-form/CheckboxInput';
import getFieldProps, { GetFieldProps } from './helpers/getFieldProps';

export interface OurCheckboxProps<T extends RecordType = RecordType> extends Omit<CheckboxProps, 'form'> {
  form?: UseFormReturn<T>;
  formik?: ExtendedFormik;
  label?: string;
}

const OurCheckbox = <T extends RecordType = RecordType>(props: OurCheckboxProps<T>) => {
  const darkMode = useOurDarkMode();

  if (props.form) return <CheckboxInput {...props as CheckboxInputProps<T>} />;

  const fieldProps = getFieldProps({ ...props, darkMode } as GetFieldProps);
  const { id, label, value, InputProps } = fieldProps;
  const onChange = fieldProps.onChange;
  const { disabled = false } = props;
  const { readOnly } = InputProps as RecordType;
  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly || !onChange) return undefined;

    // onChange({ target: { id, value: e.target.checked } } );
    onChange({ ...e, target: { ...e.target, id: id || '', value: e.target.checked } } as ChangeEvent);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={Boolean(value)}
          value={Boolean(value)}
          onChange={onCheckboxChange}
          readOnly={readOnly as boolean | undefined}
          disabled={disabled}
          color="primary"
          sx={{ cursor: readOnly || disabled ? 'default' : 'pointer' }}
        />
      }
      label={label as string | undefined}
      sx={{ cursor: readOnly || disabled ? 'default' : 'pointer', mr: 0 }}
    />
  );
};

export default OurCheckbox;
