import { RecordType, properCase } from '@petconsole/pure-base';
import setPropertyReducer from './setPropertyReducer';
import { BaseSliceSliceProperty } from '../../types';
import togglePropertyReducer from './togglePropertyReducer';

const baseSlicePropertyReducers = (sliceName: string, properties: BaseSliceSliceProperty[]) => {
  const properName = properCase(sliceName);
  const reducers: RecordType = {};

  properties.forEach(({ suffix, toggle }) => {
    const propertyName = `${sliceName}${suffix}`;
    const proper = `${properName}${suffix}`;

    if (toggle) reducers[`toggle${proper}`] = togglePropertyReducer(propertyName);
    else reducers[`set${proper}`] = setPropertyReducer(propertyName);
  });

  return reducers;
};

export default baseSlicePropertyReducers;
