import { useState } from 'react';
import { EntityRecord } from '@petconsole/pure-shared';

interface UseEntityCardDirectionsProps<T extends EntityRecord = EntityRecord> {
  entityRecord: T;
  title: string;
  rating: { show: boolean };
}

const useEntityCardDirections = <T extends EntityRecord = EntityRecord>({ entityRecord, title, rating }: UseEntityCardDirectionsProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const { address } = entityRecord;
  const { description = '', latitude = 0, longitude = 0 } = address || {};
  const streetAddress = description ? description.split(',')[0] : '';

  const directions = {
    show: Boolean(latitude && longitude && rating.show),
    title,
    subTitle: streetAddress,
    latitude,
    longitude,
    isOpen,
    setIsOpen,
  };

  return { directions };
};

export default useEntityCardDirections;
