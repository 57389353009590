import { ReactNode, SyntheticEvent, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import { newId } from '@petconsole/pure-base';
import { ourMemberImageUrl } from '@petconsole/pure-fe-api';
import { Image } from '@petconsole/pure-shared';
import OurCardImage from '../cards/OurCardImage';
import OurImageTabBarViewer from './OurImageTabBarViewer';

interface OurImageTabBarProps {
  keyPrefix: string;
  images?: Image[];
  heading?: ReactNode;
}

const OurImageTabBar = ({ keyPrefix, images = [], heading = 'Photos (click to enlarge)' }: OurImageTabBarProps) => {
  const height = 48;
  const width = 48;
  const tabsId = `${keyPrefix}-tabs`;
  const [tabValue, setTabValue] = useState(0);
  const [viewerOpen, setViewerOpen] = useState(false);

  const onChange = (_event: SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
    setViewerOpen(true);
  };

  const onTabClick = (newTabValue: number) => {
    setTabValue(newTabValue);
    setViewerOpen(true);
  };

  if (!(images?.length > 0)) return null;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography align="left" variant="body2" pl={1}>
          {heading}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          id={tabsId}
          value={tabValue}
          onChange={onChange}
          indicatorColor="secondary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={isMobile && !isTablet ? true : 'auto'}
          {...(isMobile && !isTablet && { allowScrollButtonsMobile: true })}
          aria-label="scrollable-image-list"
          // centered - can't use with variant scrollable
        >
          {images.map(({ title, s3Key, src, newImage }, index) => (
            <Tab
              key={`${keyPrefix}-${newId()}`}
              label={
                <OurCardImage
                  width="auto"
                  height={height}
                  image={s3Key.startsWith('http') ? s3Key : newImage && src ? src : ourMemberImageUrl(s3Key)}
                  alt={title}
                />
              }
              value={index}
              disableRipple
              onClick={() => onTabClick(index)}
              sx={{ height, width, minWidth: width, p: 0.5 }}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <OurImageTabBarViewer
          isOpen={viewerOpen}
          setOpen={setViewerOpen}
          images={images}
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabsId={tabsId}
        />
      </Grid>
    </Grid>
  );
};

OurImageTabBar.whyDidYouRender = true;

export default OurImageTabBar;