import { createContext, PropsWithChildren, useState } from 'react';

const initialState = {
  menuOpen: false,
  setMenuOpen: (_open: boolean) => { return },
};

const OurAppContext = createContext(initialState);

const OurAppContextProvider = ({ children }: PropsWithChildren) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return <OurAppContext.Provider value={{ menuOpen, setMenuOpen }}>{children}</OurAppContext.Provider>;
};

export { OurAppContext, OurAppContextProvider };
