import { Rotate90DegreesCcw } from '@mui/icons-material';
import OurButton, { OurButtonProps } from './OurButton';

const OurRotateTextButton = ({ label = 'Rotate', ...rest }: OurButtonProps) => (
  <OurButton
    {...rest}
    label={label}
    startIcon={<Rotate90DegreesCcw />}
    fullWidth={false}
  />
);

OurRotateTextButton.whyDidYouRender = true;

export default OurRotateTextButton;
