// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { anySite } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { DataEntityType, InitialEntity, RatingBusinessColumns, SubscribeColumns } from '../../types';

interface VanRepair extends RatingBusinessColumns, SubscribeColumns {
  type: DataEntityType;
  vanRepairId: string;
  name: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const vanRepairEntity = entityAttributes({
  name: entityName.vanRepair,
  attributes: {
    category: entityCategory.business,
    columns: { address: true, business: true, rating: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    site: anySite.busConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const vanRepairSchema = entitySchema<VanRepair>(vanRepairEntity, {
  name: strim().required('Business name is required'),
});

export const vanRepairDefaults: Partial<VanRepair> = {
  name: '',
};

export interface VanRepairRecord extends VanRepair, RecordType {}

export type MaybeVanRepairRecord = MaybeNull<VanRepairRecord>;
export type VanRepairRecords = VanRepairRecord[];

export const initialVanRepair: InitialEntity<VanRepair, VanRepairRecord> = (overrides = {}) =>
  initialEntity<VanRepair>(vanRepairEntity, { ...vanRepairDefaults, ...overrides }) as VanRepairRecord;
