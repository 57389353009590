// noinspection ES6PreferShortImport

import { RecordType } from '@petconsole/pure-base';
import { RatingApi, ratingApi } from '@petconsole/pure-fe-api';
import { RatingRecord } from '@petconsole/pure-shared';
import { loggerError } from '../../slices/system/logger/loggerSlice';
import { snackConflictCheck } from '../../slices/system/snack/snackSlice';
import { AnyAsyncThunk, ThunkApi } from '../../types';
import ourStateSliverData from '../state/ourStateSliverData';

export interface GetCreatorUpdateRatingProps {
  sliceName: string;
  api?: RatingApi;
  idName: string;
  fetchEntity: AnyAsyncThunk;
  fetchRating: AnyAsyncThunk;
}

export interface CreatorUpdateRatingProps {
  id: string;
  rating: number;
}

export type GetCreatorUpdateRating = (
  props: GetCreatorUpdateRatingProps,
) => (creatorProps: CreatorUpdateRatingProps, thunkApi: ThunkApi) => Promise<RecordType>;

// TODO: Remove when no longer needed
export const getCreatorUpdateRating: GetCreatorUpdateRating =
  ({ sliceName, api = ratingApi, idName, fetchEntity, fetchRating }) =>
  async ({ id, rating }, { dispatch, getState }) => {

    try {
      const entityRating = { ...ourStateSliverData(getState(), sliceName).rating?.ratings[id], rating } as RatingRecord;
      const { ratingId } = entityRating;
      const { create, update } = api;

      const saved = ratingId ? await update(ratingId, entityRating) : await create(entityRating);

      dispatch(fetchEntity({ [idName]: id })).then();

      return saved;
    } catch (e) {
      if (e instanceof Error) {
        dispatch(snackConflictCheck({ message: e.message }));
        dispatch(loggerError({ message: e.message }));
      }

      dispatch(fetchRating({ [idName]: id })).then();
      throw e;
    }
  };
