// noinspection ES6PreferShortImport

import { MaybeNull, MaybeRecordType, MaybeString, RecordType } from '@petconsole/pure-base';
import { orderedByType, orderStatus, shippingType } from '../../../constants';
import entityAttributes from '../../../entity/entityAttributes';
import { entityCategory, entityName, entityTabName } from '../../../entity/constants';
import entitySchema from '../../../entity/entitySchema';
import initialEntity from '../../../entity/initialEntity';
import { schema } from '../../../entity/schema';
import {
  CrudColumns,
  EntityType,
  InitialEntity,
  MemberColumns,
  OrderedByType,
  OrderStatus,
  OrderTax,
  ShippingType,
  ShipTo,
  ShopShipRates,
} from '../../../types';

export interface Order extends CrudColumns, MemberColumns {
  type: EntityType;
  orderId: string;
  reference: string;
  orderNumber: string;
  invoiceNumber: string;
  status: OrderStatus;
  statusAt: MaybeString;
  invoiceId: string;
  orderedBy: OrderedByType;
  email: string;
  shopId: string;
  shopName: string;
  appRate: number;
  appFee: number;
  shipType: ShippingType;
  shipRates: ShopShipRates;
  shipTo: ShipTo;
  shipInstructions: string;
  payment: MaybeRecordType;
  cartId: string;
  quantity: number;
  ordered: number;
  shipping: number;
  subtotal: number;
  taxes: OrderTax[];
  tax: number;
  total: number;
  paid: boolean;
  paidVia: string;
  fulfilled: boolean;
}

const { General, Details, Comments, Summary } = entityTabName;
export const orderEntity = entityAttributes({
  name: entityName.order,
  attributes: {
    category: entityCategory.order,
    nameField: 'orderNumber',
    columns: { images: false, member: true },
    loadLimit: 25,
    memberOwned: true,
    tabs: [General, Details, Comments, Summary],
  },
});

const { array, date, email, boolean, id, json, memberName, number, oneOf, phone, schemaObject, strim } = schema;
export const orderSchema = entitySchema<Order>(orderEntity, {
  reference: id(true),
  orderNumber: strim(),
  invoiceNumber: strim(),
  status: oneOf(orderStatus, orderStatus.Draft),
  statusAt: date(),
  invoiceId: id(),
  orderedBy: oneOf(orderedByType, orderedByType.Member),
  memberId: id(),
  email: email(),
  memberName: memberName(),
  shopId: id(true),
  shopName: strim(),
  appRate: number(),
  appFee: number(),
  shipType: oneOf(shippingType, shippingType.Other),
  shipRates: schemaObject({
    free: boolean(),
    minimum: number(),
    base: number(),
    item: number(),
  }),
  shipTo: schemaObject({
    name: strim(),
    phoneNumber: phone(),
    address1: strim(),
    address2: strim(),
    city: strim(),
    province: strim(),
    country: strim(),
    postal: strim(),
  }),
  shipInstructions: strim(),
  payment: json(),
  cartId: id(),
  quantity: number(),
  ordered: number(), // total of line items (price * quantity)
  shipping: number(),
  subtotal: number(),
  taxes: array(), // { amount, description }
  tax: number(),
  total: number(),
  paid: boolean(),
  paidVia: strim(),
  fulfilled: boolean(),
});

export const orderDefaults: Partial<Order> = {
  reference: '',
  orderNumber: '',
  invoiceNumber: '',
  status: orderStatus.Draft,
  statusAt: null,
  invoiceId: '',
  orderedBy: orderedByType.Member,
  memberId: '',
  email: '',
  memberName: '',
  shopId: '',
  shopName: '',
  appRate: 0,
  appFee: 0,
  shipType: shippingType.Other,
  shipRates: { free: false, minimum: 0, base: 0, item: 0 },
  shipTo: {
    name: '',
    phoneNumber: '',
    address1: '',
    address2: '',
    city: '',
    province: '',
    country: '',
    postal: '',
  },
  shipInstructions: '',
  payment: null,
  cartId: '',
  quantity: 0,
  ordered: 0, // total of line items (price * quantity)
  shipping: 0,
  subtotal: 0,
  taxes: [], // { amount, description }
  tax: 0,
  total: 0,
  paid: false,
  paidVia: '',
  fulfilled: false,
};

export interface OrderRecord extends Order, RecordType {}

export type MaybeOrderRecord = MaybeNull<OrderRecord>;
export type OrderRecords = OrderRecord[];

export const initialOrder: InitialEntity<Order, OrderRecord> = (overrides = {}) =>
  initialEntity<Order>(orderEntity, { ...orderDefaults, ...overrides }) as OrderRecord;
