import { SignUpInput, signUp } from 'aws-amplify/auth';
import { ConfirmSignUpSignUpStep } from '../types';

export const attemptSignUp = ({ username, password }: SignUpInput) => signUp({ username, password });

interface AuthSignUpProps {
  email: string;
  password: string;
}

const authSignUp = async ({ email, password }: AuthSignUpProps) => {
  try {
    const result = await attemptSignUp({ username: email, password });
    const { signUpStep, codeDeliveryDetails } = result.nextStep as ConfirmSignUpSignUpStep;

    return { success: true, next: signUpStep, email: codeDeliveryDetails.destination };
  } catch (e) {
    if (!(e instanceof Error)) {
      // TODO: Log to rollbar
      return { success: false, reason: 'Unknown error' };
    }

    return { success: false, reason: e.message };
  }
};

export default authSignUp;
