import { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';
import { MaybeRedirectState } from '@petconsole/pure-shared';

export const ourSetRedirectActionType = 'SET_REDIRECT';

interface ContextState {
  redirect: MaybeRedirectState;
}

interface ContextValues {
  state: ContextState;
  dispatch: Dispatch<{type: string, payload: Partial<ContextState>}>;
}

const initialState = { redirect: null } as ContextState;

const ourRedirectContext = createContext({ state: initialState } as ContextValues);
const { Provider } = ourRedirectContext;

const OurRedirectContextProvider = ({ children }: PropsWithChildren) => {
  const reducer = (state: ContextState, action: { type: string, payload: Partial<ContextState>}): ContextState => {
    const { type, payload } = action;

    switch (type) {
      case ourSetRedirectActionType:
        return { ...state, ...payload };

      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { ourRedirectContext, OurRedirectContextProvider };
