import { createAction } from '@reduxjs/toolkit';
import { EntityName, ourEntityNameToType, RatingRecord } from '@petconsole/pure-shared';

interface OurSetEntityRatingCreatorProps {
  entityName: EntityName;
}

const ourSetEntityRatingCreator = ({ entityName }: OurSetEntityRatingCreatorProps) => {
  const type = ourEntityNameToType(entityName);

  return createAction<RatingRecord>(`${entityName}/set${type}Rating`);
};

export default ourSetEntityRatingCreator;