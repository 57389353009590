import { RecordType } from '@petconsole/pure-base';
import {
  Api,
  ApiEntity,
  GetListOptions,
  GetListResults,
  MaybeSiteRecord,
  siteEntity,
  SiteRecord,
} from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = SiteRecord;

const apiPath = '/sites';
const { getByValue, getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

interface CustomApi<T extends RecordType = ApiRecord> extends RecordType {
  getBySite: (site: string) => Promise<MaybeSiteRecord>;
  getListByHasShops: (options?: GetListOptions) => Promise<GetListResults<T>>;
}

export type SiteApi = Api<ApiRecord> & CustomApi;

export const siteApi: SiteApi = {
  ...common,
  getByValue,
  getListByValue,
  getBySite: (site) => <Promise<MaybeSiteRecord>>getByValue('site', site),
  getListByHasShops: (options) => getListByValue('hasShops', 'true', options),
};

export const siteApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...siteEntity, api: siteApi };
