import { SliceChildProps } from '../../types';

const selectPrevEntity = (sliver: SliceChildProps, idValue: string) => {
  const { ids, entities } = sliver;
  const index = ids.indexOf(idValue);

  return index > 0 ? entities[ids[index - 1]] : null;
};

export default selectPrevEntity;
