import { RecordType } from '@petconsole/pure-base';
import { EntityName } from '@petconsole/pure-shared';
import { SliceThunk, ThunkRecordFromRecord } from '../../types/types';
import { useOurDispatchById, useOurSelectById } from '../hooks';
import useOurUpdateRating from '../ratings/useOurUpdateRating';
import { ratingSelector } from '../selectors';

const sliceRatingHooks = (sliceName: string, entityType: string, thunks: SliceThunk) => {
  const { fetchRating, updateRating } = thunks;
  const hooks: RecordType = {};

  hooks[`useOurSelect${entityType}Rating`] = (id: string) =>
    useOurSelectById(ratingSelector(sliceName, sliceName), id);

  if (fetchRating)
    hooks[`useOurFetch${entityType}Rating`] = () => useOurDispatchById(sliceName, fetchRating as ThunkRecordFromRecord);

  if (updateRating)
    hooks[`useOurUpdate${entityType}Rating`] = () => useOurUpdateRating({ entityName: sliceName as EntityName });

  return hooks;
};

export default sliceRatingHooks;
