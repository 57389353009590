import React, { memo } from 'react';
import { Badge, BadgeProps, Icon as MuiIcon, IconButton, IconButtonProps, IconProps, Theme } from '@mui/material';
import { Icon } from '@petconsole/pure-shared';
import OurTooltip from '../misc/OurTooltip';

export interface OurIconButtonProps extends IconButtonProps {
  tip: string;
  aria?: string;
  Icon: Icon;
  fontSize?: IconProps['fontSize'];
  // fontSize?: OverridableStringUnion<'medium' | 'small' | 'large' | 'inherit', IconPropsSizeOverrides>;
  primaryLight?: boolean;
  secondaryLight?: boolean;
  badge?: BadgeProps;
  component?: React.ElementType;
}

const OurIconButton = ({
  tip,
  aria,
  disabled,
  Icon,
  size = 'large',
  fontSize,
  sx,
  primaryLight,
  secondaryLight,
  badge,
  ...rest
}: OurIconButtonProps) => {
  const ariaLabel = aria || `${tip.toLowerCase().replaceAll(' ', '-')}-button`;
  const color =
    primaryLight || secondaryLight
      ? { color: ({ palette }: Theme) => (primaryLight ? palette.primary.light : palette.secondary.light) }
      : {};
  const sxContent = sx ? { ...sx, ...color } : primaryLight || secondaryLight ? color : undefined;
  const tooltip = disabled ? '' : tip;
  const iconButtonProps = { size, disabled, 'aria-label': ariaLabel, sx: sxContent, ...rest };

  const IconButtonChild = memo(() => {
    if (!badge) return <MuiIcon fontSize={fontSize} component={Icon} />;

    return (
      <Badge {...badge}>
        <MuiIcon fontSize={fontSize} component={Icon} />
      </Badge>
    );
  });

  return (
    <OurTooltip tip={tooltip}>
      <IconButton {...iconButtonProps}>
        <IconButtonChild />
      </IconButton>
    </OurTooltip>
  );
};

OurIconButton.whyDidYouRender = true;

export default OurIconButton;
