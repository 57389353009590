import React from 'react';
import { Grid, GridProps } from '@mui/material';
import { RegularBreakpoints } from '@mui/material/Grid/Grid';
import OurCheckbox, { OurCheckboxProps } from './OurCheckbox';
import { RecordType } from '@petconsole/pure-base';

interface OurCheckboxGridItemProps<T extends RecordType = RecordType> extends OurCheckboxProps<T>, RegularBreakpoints {
  gridProps?: GridProps;
  propagate?: boolean;
}

const OurCheckboxGridItem = <T extends RecordType = RecordType>({
  gridProps,
  xs = 12,
  sm,
  md,
  lg,
  xl,
  propagate = true,
  ...rest
}: OurCheckboxGridItemProps<T>) => {
  return (
    <Grid
      item
      {...{
        xs,
        sm,
        md,
        lg,
        xl,
      }}
      {...gridProps}
      {...(propagate ? {} : { onClick: (e: React.UIEvent) => e.stopPropagation() })}
    >
      <OurCheckbox {...rest} />
    </Grid>
  );
};

OurCheckboxGridItem.whyDidYouRender = true;

export default OurCheckboxGridItem;
