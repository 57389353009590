// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { entityCategory, entityName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { CrudColumns, DataEntityType, InitialEntity } from '../../types';

export interface StripeAccount extends CrudColumns {
  type: DataEntityType;
  stripeAccountId: string;
  stripeId: string;
  shopId: string;
  onboard: boolean;
  stripeName: string;
  chargesEnabled: boolean;
  payoutsEnabled: boolean;
  json: RecordType;
}

export const stripeAccountEntity = entityAttributes({
  name: entityName.stripeAccount,
  attributes: {
    category: entityCategory.item,
    columns: { images: false },
    eventType: { stripeEvent: 'stripeEvent' },
    nameField: 'stripeName',
  },
});

const { boolean, id, strim } = schema;
export const stripeAccountSchema = entitySchema<StripeAccount>(stripeAccountEntity, {
  stripeId: strim(),
  shopId: id(true),
  onboard: boolean(),
  stripeName: strim(),
  chargesEnabled: boolean(),
  payoutsEnabled: boolean(),
});

export const stripeAccountDefaults: Partial<StripeAccount> = {
  stripeId: '',
  shopId: '',
  onboard: false,
  stripeName: '',
  chargesEnabled: false,
  payoutsEnabled: false,
};

export interface StripeAccountRecord extends StripeAccount, RecordType {}

export type MaybeStripeAccountRecord = MaybeNull<StripeAccountRecord>;
export type StripeAccountRecords = StripeAccountRecord[];

export const initialStripeAccount: InitialEntity<StripeAccount, StripeAccountRecord> = (overrides = {}) =>
  initialEntity<StripeAccount>(stripeAccountEntity, { ...stripeAccountDefaults, ...overrides }) as StripeAccountRecord;
