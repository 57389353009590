import { Controller, UseFormReturn } from 'react-hook-form';
import { DateField, DateFieldProps } from '@mui/x-date-pickers/DateField';
import { RecordType } from '@petconsole/pure-base';
import OurTooltip from '../../components/misc/OurTooltip';
import useOurDarkMode from '../../hooks/useOurDarkMode';
import getInputProps, { GetInputProps } from './helpers/getInputProps';

export interface DateInputProps<T extends RecordType = RecordType> extends GetInputProps<T> {
  form: UseFormReturn<T>;
  tip?: string;
  openTo?: string;
  minDate?: Date;
  maxDate?: Date;
  disableFuture?: boolean;
  disablePast?: boolean;
  clearable?: boolean;
}

const DateInput = <T extends RecordType = RecordType>({
  form,
  tip,
  minDate = new Date('1900-01-01'),
  maxDate = new Date('2099-12-31'),
  // disableFuture = true, // Needs to be implemented
  disablePast, // Needs to be implemented
  clearable = true,
  ...rest
}: DateInputProps<T>) => {
  const darkMode = useOurDarkMode();

  return (
    <OurTooltip tip={tip}>
      <Controller
        control={form.control}
        name={rest.id}
        // rules={ required, min, max, minLength, maxLength, pattern, validate }
        render={({
          field: { onChange, onBlur, value, ref /* , disabled */ },
          fieldState: { isTouched, error: fieldError /* invalid, isDirty, */ },
          // formState: {
          //   isDirty,
          //   dirtyFields,
          //   touchedFields,
          //   defaultValues,
          //   isSubmitted,
          //   isSubmitSuccessful,
          //   isSubmitting,
          //   isLoading,
          //   submitCount,
          //   isValid,
          //   isValidating,
          //   errors,
          // },
        }) => {
          // console.log(rest.id, value);
          const inputProps = { darkMode, onChange, onBlur, ref, isTouched, fieldError };
          const props = getInputProps({
            ...rest,
            ...inputProps,
            value: rest.value === undefined ? value || null : rest.value,
          }) as DateFieldProps<Date, false>;

          return (
            <DateField
              {...props}
              value={props.value || null}
              {...(props?.InputProps?.readOnly && { readOnly: true })}
              // onError={(reason) => {
              //   switch (reason) {
              //     case 'invalidDate':
              //       formik?.setFieldError(name, 'Invalid date format');
              //       break;
              //
              //     case 'maxDate':
              //       formik?.setFieldError(name, `Date should not be after ${format(maxDate, 'P')}`);
              //       break;
              //
              //     case 'minDate':
              //       formik?.setFieldError(name, `Date should not be before ${format(minDate, 'P')}`);
              //       break;
              //
              //     // case "shouldDisableDate":
              //     //   // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
              //     //   formik?.setFieldError(name, getShouldDisableDateError(value));
              //     //   break;
              //
              //     default:
              //       formik?.setErrors({ ...formik?.errors, [name]: undefined });
              //   }
              // }}
            />
          );
        }}
      />
    </OurTooltip>
  );
};

DateInput.whyDidYouRender = true;

export default DateInput;
