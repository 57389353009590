import { Grid, GridProps } from '@mui/material';
import { ourUomType } from '@petconsole/pure-shared';
import OurUom, { OurUomProps } from './OurUom';

interface OurSelectQuantityUomProps extends Omit<OurUomProps, 'type'> {
  grid?: boolean;
  gridProps?: GridProps;
}

const OurSelectQuantityUom = ({ grid, gridProps, label = 'Quantity UOM', ...rest }: OurSelectQuantityUomProps) => {
  const ourUomProps = { type: ourUomType.Quantity, label: label, ...rest };

  if (!grid && !gridProps) return <OurUom {...ourUomProps} />;

  return (
    <Grid item xs={12} {...gridProps}>
      <OurUom {...ourUomProps} />
    </Grid>
  );
};

OurSelectQuantityUom.whyDidYouRender = true;

export default OurSelectQuantityUom;
