import { RecordType } from '@petconsole/pure-base';
import { Api, BusRecord, GetListOptions, GetListResults, busEntity } from '@petconsole/pure-shared';
import { commonApi } from './common';

type ApiRecord = BusRecord;

const apiPath = '/buses';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

export interface BusApi<T extends RecordType = ApiRecord> extends Api<T> {
  getListByOwner: ({ ownerId }: { ownerId: string }, options?: GetListOptions) => Promise<GetListResults<T>>;
  getListByBusName: ({ busName }: { busName: string }, options?: GetListOptions) => Promise<GetListResults<T>>;
}

export const busApi: BusApi = {
  ...common,
  getListByValue,
  // getListByOwner may be deprecated by getMyList
  getListByOwner: ({ ownerId }, options) => getListByValue('ownerId', ownerId, options),
  getListByBusName: ({ busName }, options) => getListByValue('busName', busName, options),
};

export const busApiEntity /* : ApiEntity<ApiRecord> */ = { ...busEntity, api: busApi };
