import { RecordType } from '@petconsole/pure-base';
import { apiMethodType, EntityType, PostMethod } from '@petconsole/pure-shared';
import { ApiBuilder } from '../../types';

const postOne = <T extends RecordType = RecordType, P extends RecordType = T>(builder: ApiBuilder<T>, method: PostMethod<T>, tag: EntityType) =>
  builder.mutation<T, P>({
    query: (input) => ({ type: apiMethodType.post, method, input }),
    invalidatesTags: (_result, _error, input) => [
      tag,
      ...(input[`${tag}Id`] ? [{ type: tag, id: input[`${tag}Id`] as string }] : []),
    ],
  });

export default postOne;
