// noinspection ES6PreferShortImport

import { MaybeNull, RecordType } from '@petconsole/pure-base';
import { site } from '../../constants';
import { entityCategory, entityName, entityTabName } from '../../entity/constants';
import entityAttributes from '../../entity/entityAttributes';
import entitySchema from '../../entity/entitySchema';
import initialEntity from '../../entity/initialEntity';
import { schema } from '../../entity/schema';
import { InitialEntity, MemberSubscribeColumns, ReactionBusinessColumns, TypeColumn } from '../../types';

interface Bus extends ReactionBusinessColumns, MemberSubscribeColumns, TypeColumn {
  busId: string;
  busName: string;
}

const { General, Details, Avatar, Banner, Photos, Comments, Preview } = entityTabName;
export const busEntity = entityAttributes({
  name: entityName.bus,
  attributes: {
    category: entityCategory.bus,
    columns: { address: true, business: true, member: true, reaction: true, socials: true, subscribe: true },
    loadLimit: 20,
    maxImages: 12,
    memberOwned: true,
    nameField: `${entityName.bus}Name`,
    plural: `${entityName.bus}es`,
    restrictGet: false,
    site: site.busConsole,
    subscribable: true,
    tabs: [General, Details, Avatar, Banner, Photos, Comments, Preview],
  },
});

const { strim } = schema;
export const busSchema = entitySchema<Bus>(busEntity, {
  busName: strim().required('Bus name is required'),
});

export const busDefaults: Partial<Bus> = {
  busName: '',
};

export interface BusRecord extends Bus, RecordType {}

export type MaybeBusRecord = MaybeNull<BusRecord>;
export type BusRecords = BusRecord[];

export const initialBus: InitialEntity<Bus, BusRecord> = (overrides = {}) =>
  initialEntity<Bus>(busEntity, { ...busDefaults, ...overrides }) as BusRecord;
