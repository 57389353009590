import { RecordType } from '@petconsole/pure-base';
import { foundPetApiEntity } from '@petconsole/pure-fe-api';
import { FoundPetRecord } from '@petconsole/pure-shared';
import { sliceHookTypes } from '../../../helpers';
import {
  BaseSliceOptions,
  BaseSliceTab,
  CitySearchHooks,
  OurEntityHooks,
  RequiredCrudSliverHooks,
  RequiredSliverHooks,
  TabValueHooks,
} from '../../../types';
import BaseEntitySlice from '../../base/BaseEntitySlice';

type ApiEntityRecord = FoundPetRecord;

const apiEntity = foundPetApiEntity;
const { name: sliceName, plural } = apiEntity;
const foundPet = sliceName;
const foundPets = plural;
const foundPetAdmin = `${sliceName}Admin`;

const Tab = { foundPetAdmin, foundPet, foundPets } as const;
type Tabs = { [key in keyof typeof Tab]: BaseSliceTab };
type TabHooks = { [key in keyof typeof Tab]: TabValueHooks };
const tabs: Tabs = {
  foundPetAdmin: { default: '' },
  foundPet: { default: '' },
  foundPets: { default: '' },
};

const options: BaseSliceOptions<ApiEntityRecord> = {
  ...apiEntity,
  hasCitySliver: true,
  hasNewestSliver: true,
  hasCitySearch: true,
  hasDefaultProperties: true,
  sortProperty: 'createdAt',
  tabs,
};
const { hooks, reducer } = new BaseEntitySlice<ApiEntityRecord>({ options });
const { crudHooks, sliverHooks, propertyHooks, citySearchHooks, tabHooks } = sliceHookTypes;

export interface OurFoundPetHooks<T extends RecordType = ApiEntityRecord> extends OurEntityHooks<T> {
  foundPetHooks: RequiredCrudSliverHooks;
  cityFoundPetHooks: RequiredSliverHooks;
  newestFoundPetHooks: RequiredSliverHooks;
  propertyHooks: RecordType;
  citySearchHooks: CitySearchHooks;
  tabHooks: TabHooks;
}

export const ourFoundPetHooks: OurFoundPetHooks = {
  entity: apiEntity,
  foundPetHooks: crudHooks(apiEntity, hooks) as RequiredCrudSliverHooks,
  cityFoundPetHooks: sliverHooks(apiEntity, 'City', hooks) as RequiredSliverHooks,
  newestFoundPetHooks: sliverHooks(apiEntity, 'Newest', hooks) as RequiredSliverHooks,
  citySearchHooks: citySearchHooks(apiEntity, hooks),
  propertyHooks: propertyHooks(apiEntity, hooks),
  tabHooks: <TabHooks>tabHooks(Object.keys(Tab), hooks),
};

export default reducer;
