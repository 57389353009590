import { MaybeNull, RecordType } from '@petconsole/pure-base';
import {
  Api,
  CartRecord,
  GetListOptions,
  GetListResults,
  cartEntity,
  CartItemRecords,
  ApiEntity,
  CartItemRecord,
} from '@petconsole/pure-shared';
import { commonApi, fullPath, get, pathParam, post } from './common';

type ApiRecord = CartRecord;

const apiPath = '/carts';
const { getListByValue, ...common } = commonApi<ApiRecord>({ apiPath });

export interface CartAdjustItemsProps
  extends RecordType,
    Pick<CartItemRecord, 'cartId' | 'shopItemId' | 'shopItemVariantId'> {
  adjustment: number;
}

export interface CartAdjustItemsResult extends RecordType {
  cart: ApiRecord;
  cartItems: CartItemRecords;
}

interface CustomApi extends RecordType {
  adjustItems: (props: CartAdjustItemsProps) => Promise<CartAdjustItemsResult>;
  getByShop: (shopId: string) => Promise<MaybeNull<ApiRecord>>;
  getListByOwner: ({ ownerId }: { ownerId: string }, options?: GetListOptions) => Promise<GetListResults<ApiRecord>>;
  getListByMember: ({ memberId }: { memberId: string }, options?: GetListOptions) => Promise<GetListResults<ApiRecord>>;
  updateTotal: ({ cartId }: { cartId: string }) => Promise<ApiRecord>;
}

export type CartApi = Api<ApiRecord> & CustomApi;

export const cartApi: CartApi = {
  ...common,
  getListByValue,
  adjustItems: (props) =>
    post<CartAdjustItemsProps>(`${apiPath}/adjustItems`, props) as unknown as Promise<CartAdjustItemsResult>,
  getByShop: (shopId) =>
    get(fullPath(`${apiPath}/getBy`, pathParam('shopId', shopId))) as Promise<MaybeNull<ApiRecord>>,
  getListByOwner: ({ ownerId }, options) => getListByValue('ownerId', ownerId, options),
  getListByMember: ({ memberId }, options) => getListByValue('memberId', memberId, options),
  updateTotal: ({ cartId }) => post(`${apiPath}/updateTotal`, { cartId }) as Promise<ApiRecord>,
};

export const cartApiEntity: ApiEntity<ApiRecord, CustomApi> = { ...cartEntity, api: cartApi };
