import { Accept } from 'react-dropzone';
import { ourBlobToDataUrl } from '@petconsole/pure-fe-utils';
import { Images, Log, ourImageContentType } from '@petconsole/pure-shared';
import useOurSnacks from '../../../hooks/useOurSnacks';
import ImageZoneContainer from './ImageZoneContainer';

interface ImageDropzoneProps {
  accept?: Accept;
  onDrop: (images: Images) => void;
  leadText?: string;
  maxFiles?: number;
  log: Log;
}

// onDrop will get called with a list of images
const ImageDropzone = ({ accept, onDrop, leadText, maxFiles = 1, log }: ImageDropzoneProps) => {
  const { warn } = useOurSnacks();
  const srcToImage = (src: unknown) => ({ src, contentType: ourImageContentType({ src: src as string }) });

  const handleDrop = (blobs: Blob[]) =>
    Promise.all(blobs.map((blob) => ourBlobToDataUrl({ blob, log }).then(srcToImage))).then((images) =>
      onDrop(images as Images),
    );

  const handleRejected = (rejected: unknown[]) => {
    if (rejected.length > maxFiles) {
      if (maxFiles === 1) warn(`Too many files selected. ${maxFiles} permitted.`, false);
      else warn(`Too many files selected. ${maxFiles} permitted.`, false);
    } else warn(`${rejected.length} files rejected. ${maxFiles} permitted.`, false);
  };

  return (
    <ImageZoneContainer
      leadText={leadText}
      maxFiles={maxFiles}
      accept={accept}
      onDrop={handleDrop}
      onRejected={handleRejected}
    />
  );
};

ImageDropzone.whyDidYouRender = true;

export default ImageDropzone;
