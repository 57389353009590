import { RecordType } from '@petconsole/pure-base';
import { MaintAppProps, UseMaintAppProps } from '../types';

// Provide and return default values for all props
const useMaintApp = <T extends RecordType = RecordType>({
  entity,
  hooks,
  schema,
  values,
  customData = null,
  getCustomData = null,
  marginColumns = false,
  margins = null,
  leftMargins = null,
  rightMargins = null,
  LeftContent = null,
  RightContent = null,
  padding = null,
  idParam = null,
  stateIds = null,
  helpTitle = null,
  help = null,
  heading = null,
  add = true,
  Add = null,
  addProps = null,
  Main = null,
  mainOutline = null,
  mainLabel = null,
  mainSpacing = null,
  mainSx = null,
  mainProps = null,
  addressBreakpoints = null,
  directions = true,
  tabDefinitions = {},
  tabElements = null,
  tabHooks = null,
  tabNames = null,
  tabProps = {},
  tabValues = null,
  tabValue = null,
  setTabValue = null,
  tabWidth = null,
  tabScroll = null,
  editMessages = null,
  beforeSubmit = null,
  beforeSave = null,
  customCancel = null,
  customSave = null,
  afterSave = null,
  afterSubmit = null,
  saveButton = null,
  viewOnly = false,
  startEditing = false,
}: UseMaintAppProps<T>): MaintAppProps<T> => {
  const maintProps = {
    entity,
    hooks,
    schema,
    values,
    customData,
    getCustomData,
    marginColumns,
    margins,
    leftMargins,
    rightMargins,
    LeftContent,
    RightContent,
    padding,
    idParam,
    stateIds,
    helpTitle,
    help,
    heading,
    add,
    Add,
    addProps,
    Main,
    mainOutline,
    mainLabel,
    mainSpacing,
    mainSx,
    mainProps,
    addressBreakpoints,
    directions,
    tabDefinitions,
    tabElements,
    tabHooks,
    tabNames,
    tabProps,
    tabValues,
    tabValue,
    setTabValue,
    tabWidth,
    tabScroll,
    editMessages,
    beforeSubmit,
    beforeSave,
    customCancel,
    customSave,
    afterSave,
    afterSubmit,
    saveButton,
    viewOnly,
    startEditing,
  };

  return { ...maintProps };
};

export default useMaintApp;
