import { RecordType } from '@petconsole/pure-base';
import { pclTierApiEntity } from '@petconsole/pure-fe-api';
import { PclTierRecord } from '@petconsole/pure-shared';
import { writeReducers } from '../../../helpers';
import { appThunk } from '../../../store/appThunk';
import { EntityAdapterWithPick } from '../../../types';

const { api, name: sliceName, proper } = pclTierApiEntity;

export const updatePclTierStripePayloadCreator = async ({ pclTier }: RecordType) => {
  const { pclTierId, json } = pclTier as PclTierRecord;
  const { stripeProduct } = json;

  return stripeProduct ? api.updateStripe(pclTierId) : api.createStripe(pclTierId);
};

export const updatePclTierStripeThunk = appThunk(
  `${sliceName}/update${proper}Stripe`,
  updatePclTierStripePayloadCreator,
);

export const updatePclTierStripeReducers = (adapter: EntityAdapterWithPick) =>
  writeReducers(updatePclTierStripeThunk, sliceName, adapter);
