import { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { FormikHelpers } from 'formik';
import { unwrapResult } from '@reduxjs/toolkit';
import { RecordType } from '@petconsole/pure-base';
import { ourPutImage } from '@petconsole/pure-fe-api';
import { RequiredCrudSliverHooks, WrappedResult } from '@petconsole/pure-fe-state';
import {
  EntityAttributes,
  EntityRecord,
  entityTabName,
  EntityTabName,
  Image,
  ImageKey,
  Images,
  SetState,
} from '@petconsole/pure-shared';
import useOurSaveEntity from '../../data/ourSaveEntity/useOurSaveEntity';

interface SubmitProps {
  values: Partial<EntityRecord>;
  formikHelpers: FormikHelpers<Partial<EntityRecord>>;
}

interface UseOurListEntitySubmitProps {
  entity: EntityAttributes;
  writeHooks: RequiredCrudSliverHooks;
  images?: Images;
  avatar?: string;
  setAvatar?: SetState<string>;
  avatarEditor?: AvatarEditor;
  avatarChanged?: boolean;
  avatarKey: ImageKey;
  bannerFrom: () => Partial<Image>;
  saveBanner: () => ReturnType<typeof ourPutImage>;
  setEditing: SetState<boolean>;
  tabValue: EntityTabName;
  setFormValues: SetState<RecordType>;
  beforeSubmit?: (values: EntityRecord) => EntityRecord;
  beforeSave?: (values: EntityRecord) => EntityRecord;
  customSave?: (changes: EntityRecord) => Promise<EntityRecord>;
  afterSave?: (saved: EntityRecord) => unknown;
  afterSubmit?: (saved: EntityRecord) => void;
}

const useOurListEntitySubmit = ({
  entity,
  writeHooks,
  images,
  avatar,
  setAvatar,
  avatarEditor,
  avatarChanged,
  avatarKey,
  bannerFrom,
  saveBanner,
  setEditing,
  tabValue,
  setFormValues,
  beforeSubmit,
  beforeSave,
  customSave,
  afterSave,
  afterSubmit,
}: UseOurListEntitySubmitProps) => {
  const { ourSaveEntity } = useOurSaveEntity({ entity });
  const update = customSave || writeHooks.useUpdate();
  // Not the same as Formik's isSubmitting/setSubmitting
  const [submitting, setSubmitting] = useState(false);
  const [busy, setBusy] = useState(false);

  const setValues = ((value: EntityRecord) => setFormValues((previous) => ({ ...previous, ...value }))) as SetState<
    Partial<EntityRecord>
  >;

  const { name: entityName, idName } = entity;

  const submit = ({ values, formikHelpers }: SubmitProps) => {
    const { [idName]: id } = values;

    return ourSaveEntity({
      id: id as string,
      values,
      setValues,
      images,
      avatarKey,
      avatarImage: avatar,
      setAvatarImage: setAvatar,
      captureAvatar: tabValue === entityTabName.Avatar,
      avatarChanged,
      avatarEditor,
      bannerFrom,
      saveBanner,
      setEditing,
      setSubmitting,
      setIsSubmitting: formikHelpers.setSubmitting,
      setBusy,
      save: async (changes) => {
        const saveChanges = beforeSave ? beforeSave(changes) : changes;

        const saved = customSave ? await customSave(saveChanges) : unwrapResult(await update({ [entityName]: saveChanges }) as WrappedResult);

        return afterSave ? afterSave(saved) : saved;
      },
    });
  };

  async function onSubmit(values: Partial<EntityRecord>, formikHelpers: FormikHelpers<Partial<EntityRecord>>) {
    // May want to prune any values we don't want to send over the network when we save the item
    const submitValues = beforeSubmit ? beforeSubmit(values) : values;

    const saved = await submit({ values: submitValues, formikHelpers });

    if (saved && afterSubmit) afterSubmit(saved);

    return saved || undefined;
  }

  return { onSubmit, submitting, setSubmitting, busy, setBusy };
};

export default useOurListEntitySubmit;
