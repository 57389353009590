import { RecordType } from '@petconsole/pure-base';
import { AnyAsyncThunk, StateType, ThunkFulfilledMethod, ThunkReducersType } from '../../types';

const writeReactionReducers: ThunkReducersType = (fetchMethod, stateProperty) => {
  const { fulfilled } = fetchMethod as AnyAsyncThunk;

  const fulfilledReducer: ThunkFulfilledMethod<StateType, RecordType> = (state, action) => {
    const payload = action.payload;

    state[stateProperty].reactions[payload?.reactionToId as string] = payload;
  };

  return {
    // [pending]: thunkPendingReducer(stateProperty),
    [String(fulfilled)]: fulfilledReducer,
    // [rejected]: thunkRejectedReducer(stateProperty),
  };
};

export default writeReactionReducers;
