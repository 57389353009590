import { ExtendedFormik } from '@petconsole/pure-shared';
import OurText from './OurText';
import OutlinedGridContent from '../outlinedGrid/OutlinedGridContent';

interface OurSocialMediaItemsProps {
  formik: ExtendedFormik;
  readOnly?: boolean;
}

const OurSocialMediaItems = ({ formik, readOnly }: OurSocialMediaItemsProps) => {
  const gridTextItemProps= { rowGap: 1, ml: 0, pr: 2 };
  const textItemProps = { formik, readOnly };

  return (
    <OutlinedGridContent id="social-media" label="Social Media" sx={gridTextItemProps}>
      <OurText {...textItemProps} id="facebook" /* placeholder="https://facebook.com/yoursite" */ />
      <OurText {...textItemProps} id="instagram" />
      <OurText {...textItemProps} id="twitter" label="X (Twitter)" />
      <OurText {...textItemProps} id="linkedIn" label="LinkedIn" />
      <OurText {...textItemProps} id="youTube" label="YouTube" />
      <OurText {...textItemProps} id="tikTok" label="TikTok" />
    </OutlinedGridContent>
  );
};

OurSocialMediaItems.whyDidYouRender = true;

export default OurSocialMediaItems;
