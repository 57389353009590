import { DynamicFeed, Forum, Group, LocalLibrary } from '@mui/icons-material';
import { OurCommunityIcon } from '@petconsole/pure-fe-icon';
import {
  SelectHook,
  SetHook,
  useOurSelectMemberMenuCommunityMenuOpen,
  useOurSetMemberMenuCommunityMenuOpen,
} from '@petconsole/pure-fe-state';
import { IsOpenProps, SetState, webPath } from '@petconsole/pure-shared';
import { AppMenuItems } from '../../../types';
import OurAppMenuCollapsable from './OurAppMenuCollapsable';
import ourCombinedAppMenuItems from './ourCombinedAppMenuItems';

interface OurCommunityMenuProps extends Partial<IsOpenProps> {
  add?: AppMenuItems;
  exclude?: string[];
}

const OurCommunityMenu = ({ isOpen, setIsOpen, add, exclude }: OurCommunityMenuProps) => {
  const menuItemsOpen = (useOurSelectMemberMenuCommunityMenuOpen as SelectHook<boolean>)();
  const setMenuItemsOpen = (useOurSetMemberMenuCommunityMenuOpen as SetHook<boolean>)();

  const menuItems = [
    [LocalLibrary, 'Blog', webPath.blog],
    [DynamicFeed, 'Feed', webPath.feed],
    [Forum, 'Forums', webPath.forum],
    [Group, 'Members', webPath.members],
  ] as AppMenuItems;

  const open = isOpen != null ? isOpen : menuItemsOpen;
  const setOpen = setIsOpen != null ? setIsOpen : setMenuItemsOpen as SetState<boolean>;
  const items = ourCombinedAppMenuItems({ items: menuItems, add, exclude });

  return (
    <OurAppMenuCollapsable
      open={open}
      setOpen={setOpen}
      Icon={OurCommunityIcon}
      label="Community"
      items={items}
      divider={true}
    />
  );
};

OurCommunityMenu.whyDidYouRender = true;

export default OurCommunityMenu;
