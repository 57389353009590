import { createContext, PropsWithChildren, useReducer } from 'react';
import { RecordType } from '@petconsole/pure-base';
import { ourDefaultDarkModeItemKey } from '../constants';

export const ourDarkModeActionType = 'SET_DARK_MODE';

// useManageDarkMode expects darkMode to be null initially
const initialState = { darkMode: null, itemKey: ourDefaultDarkModeItemKey } as RecordType;

const ourDarkModeContext = createContext(initialState);
const { Provider } = ourDarkModeContext;

const OurDarkModeContextProvider = ({ children }: PropsWithChildren) => {
  const init = (props: RecordType) => ({ ...props });

  const [newState, dispatch] = useReducer(
    (state: RecordType = initialState, action: { type: string, payload: RecordType }) => {
      const { type, payload } = action;

      switch (type) {
        case ourDarkModeActionType:
          if (payload.darkMode === state.darkMode && payload.itemKey === state.itemKey)
            return state;

          return { ...state, darkMode: payload.darkMode, itemKey: payload.itemKey };

        default:
          return state;
      }
    },
    initialState,
    init,
  );

  return <Provider value={{ state: newState, dispatch }}>{children}</Provider>;
};

export { ourDarkModeContext, OurDarkModeContextProvider };
