import { postApiEntity } from '@petconsole/pure-fe-api';
import { PostRecord } from '@petconsole/pure-shared';
import { ApiEndpointsProps } from '../../../../types';
import { createOne, deleteOne, getOne, updateOne } from '../../../helpers';

type ApiRecord = PostRecord;

const postEndpoints = ({ builder }: ApiEndpointsProps<ApiRecord>) => {
  const { api, proper: tag } = postApiEntity;

  return {
    createPost: createOne(builder, api, tag),
    deletePost: deleteOne(builder, api.delete, tag),
    getPost: getOne(builder, api, tag),
    // getPostsByShop: getListBy(builder, api.getListByShop, tag),
    updatePost: updateOne(builder, api.update, tag),
  };
};

export default postEndpoints;
