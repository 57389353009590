import { reactAppMemberImagePath, s3Folder } from '@petconsole/pure-shared';

const ourMemberImageUrl = (image?: string, isPrivate?: boolean) => {
  if (!image) return '';

  if (image.startsWith('http')) return image;

  return `${reactAppMemberImagePath}/${isPrivate ? s3Folder.private : s3Folder.public}/${image}`;
};

export default ourMemberImageUrl;
