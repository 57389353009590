import { ourPutImages } from '@petconsole/pure-fe-api';
import { Images, Log } from '@petconsole/pure-shared';

interface SaveNewImagesProps {
  images?: Images;
  log?: Log;
}

const saveNewImages = async ({ images = [], log }: SaveNewImagesProps) => {
  if (!(images.length > 0)) return;

  if (!log) throw new Error('saveNewImages: log is required');

  const imagesToSave = images.filter((image) => image.newImage);

  if (!(imagesToSave.length > 0)) return;

  await ourPutImages({ images: imagesToSave, log });
};

export default saveNewImages;
